<template>
  <div class="mt-4">
    <div
      v-if="(event?.eventAudits || []).length == 0"
      class="
        flex
        justify-center
        items-center
        w-full
        mt-4
        transition transition-all
      "
    >
      <EmptyState
        image="no-result.svg"
        class="d-block mx-auto"
        :title="$t('global.noResult')"
        :subtitle="$t('global.noData')"
      />
    </div>
    <div v-else>
      <h4 class="mt-3 mb-2 text-left font-semibold text-secondary9">
        Audits ({{ (event?.eventAudits || []).length }})
      </h4>
      <DataTable :columns="auditColumns" :rows="event?.eventAudits || []">
        <template #createdAt="{ row }">
          {{ moment.utc(row.createdAt).format($t('dateFormat')) }}
        </template>
        <template #motive="{ row }">
          <div v-if="row.motive" class="flex flex-wrap">
            <span
              v-for="m in row.motive.split(',')"
              :key="m"
              class="border border-warning text-warning rounded px-1 m-1"
            >
              {{ $t(`audits.labels.${m}`) }}
            </span>
          </div>
          <span v-else>-</span>
        </template>
        <template #comment="{ row }">
          <LongText :text="row.comment" />
        </template>

        <template #processed="{ row }">
          <span
            :class="`border border-${
              row.processed ? 'success' : 'error'
            } text-${row.processed ? 'success' : 'error'} rounded px-1 m-1`"
          >
            {{ $t(`audits.${row.processed ? 'isProcessed' : 'notProcessed'}`) }}
          </span>
        </template>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import moment from 'moment'
  import { Event } from '@/services/api/models'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'

  export default defineComponent({
    components: { DataTable, EmptyState },
    setup() {
      const { t } = useI18n()
      const isLoading = ref(false)
      const store = useStore()
      const route = useRoute()
      const event = ref<Event | null>(null)
      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )
      const auditColumns = [
        {
          label: t(`datatable.column.date`),
          custom: true,
          field: 'createdAt',
          width: '3%'
        },
        { label: t(`datatable.column.motive`), custom: true, field: 'motive' },
        {
          label: t(`datatable.column.comment`),
          custom: true,
          field: 'comment'
        },
        {
          label: t(`datatable.column.processed`),
          custom: true,
          field: 'processed'
        }
      ]

      const getEvent = (eventId = route.params.eventId) => {
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId,
          onSuccess: async (data: Event | null) => {
            event.value = data

            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      onMounted(getEvent)

      return { auditColumns, moment, event }
    }
  })
</script>
