<template>
  <div class="px-4 py-5 w-full">
    <div class="flex justify-between w-full items-center text-xl">
      <h1 class="text-left text-secondary9">
        {{ $t('account.accountInformations.alerts') }}
      </h1>

      <Button
        v-can.any="['admin', 'is-manager', 'write-alerts']"
        :label="$t('account.accountCompanies.buttons.alerts')"
        data-toggle="modal"
        class="flex items-center"
        @click="gotoNewSettings()"
      >
        <i class="bi bi-bell text-xl mr-2"></i>
      </Button>
    </div>

    <div class="mt-5 h-full">
      <div class="" v-if="alerts.length">
        <h3 class="text-2xl text-left text-secondary9">Configuration</h3>
        <div
          class="
            flex
            items-center
            shadow-md
            border border-secondary5
            h-16
            my-3
            px-2
          "
        >
          <div v-for="(setting, index) in alerts" :key="setting.id">
            {{ $t(`AlertTypes.${setting.type}`) }}
            <span class="bg-secondary4 text-secondary9 rounded-full p-2 m-2"
              >{{ setting.daysBefore }}
              {{ $tc('global.days', setting.daysBefore) }}</span
            >
            <span
              class="mx-2 text-secondary9"
              v-if="index !== alerts.length - 1"
              >-</span
            >
          </div>
        </div>
      </div>
      <div v-if="notifications.length" class="mt-4">
        <DataTable
          :has-checkbox="false"
          :columns="columns"
          :pagination="pagination"
          :rows="notifications"
          @on-page-change="onPageChange"
        >
          <template #date="{ row }">
            <div class="">
              <p>{{ moment(row.createdAt).format('L') }}</p>
            </div>
          </template>
          <template #alertType="{ row }">
            <span>{{ $t(`AlertTypes.${row.alertType}`) }}</span>
          </template>

          <template #actions="{ row }">
            <div class="flex justify-end">
              <Button
                size="medium"
                label=""
                variant="secondary"
                class="flex items-center mx-2 rounded"
                @click="gotoEvent(row)"
              >
                <i class="bi bi-lightning text-xl" />
              </Button>
            </div>
          </template>
        </DataTable>
      </div>
      <div
        v-else
        class="
          flex
          text-md
          justify-center
          items-center
          w-full
          h-full
          mt-6
          transition transition-all
        "
      >
        <EmptyState
          image="no-result.svg"
          class="d-block mx-auto"
          :title="$t('global.noAlerts')"
          subtitle=""
        />
      </div>
    </div>
  </div>

  <transition name="modal">
    <Modal
      v-if="newAlertSetting && currentCompany"
      size="w-3/5 xl:w-2/5"
      @close="closeNewAlertModal"
    >
      <template #header>
        <h3 class="text-md font-semibold text-balck">
          {{ $t('account.accountCompanies.newAlert') }}
        </h3>
      </template>

      <template #body>
        <div class="min-h-min pb-5">
          <form>
            <div class="flex flex-col">
              <div class="flex">
                <div class="w-3/6"></div>
                <h4 class="w-2/6 flex justify-start py-3">
                  {{ $t('account.accountCompanies.active') }}
                </h4>
                <h4 class="pl-2 flex justify-end py-3" id="alertDaysBefore">
                  {{ $t('account.accountCompanies.days') }}
                  <el-tooltip
                    tabindex="1"
                    popper-class=" z-index-max"
                    effect="dark"
                    :content="$t('account.alerts.daysBeforeExplain')"
                    placement="left"
                  >
                    <i class="bi bi-info-circle text-lg ml-2"></i
                  ></el-tooltip>
                </h4>
              </div>

              <div
                class="flex items-center py-2"
                v-for="type in Object.values(AlertType)"
                :key="type"
              >
                <div class="w-3/6">
                  <h4 class="text-md text-primary4">
                    {{ $t(`AlertTypes.${type}`) }}
                  </h4>
                </div>

                <div class="w-2/6">
                  <Switch
                    v-model="toggler[type]"
                    :class="toggler[type] ? 'bg-primary4' : 'bg-secondary8'"
                    class="
                      relative
                      inline-flex
                      h-6
                      w-11
                      items-center
                      rounded-full
                    "
                  >
                    <span
                      :class="toggler[type] ? 'translate-x-6' : 'translate-x-1'"
                      class="
                        inline-block
                        h-4
                        w-4
                        transform
                        rounded-full
                        bg-white
                        transition
                      "
                    />
                  </Switch>
                </div>
                <div class="w-1/6 pl-2">
                  <Field
                    v-slot="{ field }"
                    :name="`${type}`"
                    type="number"
                    v-model="setting[type]"
                  >
                    <Input
                      type="number"
                      :no-error="true"
                      :placeholder="
                        $t(
                          'account.accountInformations.form.daysBefore.placeholder'
                        )
                      "
                      :disabled="!toggler[type]"
                      v-model="setting[type]"
                      v-bind="field"
                    />
                  </Field>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end text-xl">
          <Button
            icon="bi-plus"
            variant="custom"
            :label="$t('global.actions.close')"
            data-toggle="modal"
            class="flex items-center bg-error text-white mr-2"
            @click="closeNewAlertModal()"
          />

          <Button
            v-can.any="['admin', 'write-alerts']"
            icon="bi-plus"
            variant="secondary"
            :label="$t('global.actions.save')"
            data-toggle="modal"
            class="flex items-center"
            :disabled="!meta.valid"
            @click="handleAlertSubmit"
          />
        </div> </template
    ></Modal>
  </transition>
</template>
<script lang="ts">
  import { defineComponent, computed, ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { Button, Input } from '@up.law/uplaw-ui'
  import Modal from '@/components/Modal/Modal.vue'
  import { useStore } from 'vuex'
  import { Company, Notification, Alert } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { AlertType } from '@/types/config'
  import { Field, useForm } from 'vee-validate'
  import * as yup from 'yup'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { TYPE } from 'vue-toastification'
  import moment from 'moment'
  import DataTable from '@/components/DataTable/index.vue'
  import { Switch } from '@headlessui/vue'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    components: {
      Button,
      Input,
      Modal,
      Field,
      EmptyState,
      DataTable,
      Switch,
      ElTooltip
    },
    setup() {
      const router = useRouter()
      const { t } = useI18n()
      const store = useStore()
      const newAlertSetting = ref(false)
      const isLoadingNotification = ref(false)
      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )

      const notification = ref<Notification | null>(null)

      const columns = computed(() => [
        {
          label: t(`datatable.column.date`),
          field: 'date',
          custom: true
        },
        {
          label: t(`datatable.column.alertType`),
          field: 'alertType',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          custom: true
        }
      ])

      const alerts = computed<Alert[]>(
        () => store.getters['organizations/alerts']
      )
      const pagination = computed(
        () => store.getters['organizations/notificationsPagination']
      )

      const notifications = computed(
        () => store.getters['organizations/notifications']
      )

      const toggler = ref<any>(
        Object.values(AlertType).reduce(
          (acc, x) =>
            (acc = {
              ...acc,
              [x]: alerts.value.find((a) => a.type === x) !== undefined
            }),
          {}
        )
      )

      const schema = Object.values(AlertType).reduce(
        (acc, type) =>
          (acc = acc.concat(
            yup
              .object()
              .shape({ [type]: yup.number().positive().min(0).max(101) })
          )),
        yup.object({})
      )

      const { resetForm, values, meta } = useForm({
        validationSchema: schema,
        validateOnMount: false
      })
      const setting = ref<any>(
        Object.values(AlertType).reduce(
          (acc, x) => (acc = { ...acc, [x]: 5 }),
          {}
        )
      )
      const gotoNewSettings = () => {
        setting.value = (alerts.value || []).reduce(
          (v: any, x: any) => (v = { ...v, [x.type]: x.daysBefore }),
          {}
        )

        toggler.value = Object.values(AlertType).reduce(
          (acc, x) =>
            (acc = {
              ...acc,
              [x]: alerts.value.find((a) => a.type === x) !== undefined
            }),
          {}
        )
        newAlertSetting.value = true
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_NOTIFICATIONS_PAGE', page)
        getNotifications()
      }

      const initForm = () => {
        Object.values(AlertType).reduce(
          (acc, x) => (acc = { ...acc, [x]: 5 }),
          {}
        )
        resetForm()
      }

      const closeNewAlertModal = () => {
        initForm()
        newAlertSetting.value = false
      }

      const handleAlertSubmit = () => {
        storeAlertSetting()
      }

      const gotoEvent = (row: Notification) => {
        if (!row.seen)
          store.dispatch('organizations/MARK_AS_SEEN', {
            companyId: currentCompany.value?.id,
            notificationId: row.id
          })
        router.push({
          name: 'company.eventDetails',
          params: { eventId: row?.event?.id, id: currentCompany.value?.id }
        })
      }

      const storeAlertSetting = () => {
        const data = Object.keys(values).reduce((acc: any[], key) => {
          if (toggler.value[key]) {
            acc.push({
              daysBefore: parseInt(values[key]),
              type: key
            })
          }
          return acc
        }, [])

        store.dispatch('organizations/CREATE_ALERTS', {
          companyId: currentCompany.value?.id,
          data,
          onSuccess: () => {
            closeNewAlertModal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.alerts.context'),
              message: t(
                `account.alerts.${
                  alerts.value.length > 0 ? 'edit' : 'create'
                }Success`
              )
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.alerts.context'),
              message: t(
                `account.alerts.${
                  alerts.value.length > 0 ? 'edit' : 'create'
                }Error`
              )
            })
          }
        })
      }

      const getNotification = (id: string) => {
        isLoadingNotification.value = true
        store.dispatch('organizations/GET_NOTIFICATION', {
          companyId: currentCompany.value?.id,
          notificationId: id,
          onSuccess: (data: Notification) => {
            notification.value = data
            isLoadingNotification.value = false
          },
          onError: () => {
            notification.value = null
            isLoadingNotification.value = false
          }
        })
      }

      const getAlerts = () => {
        if (!currentCompany.value?.id) return
        store.dispatch('organizations/GET_ALERTS', {
          companyId: currentCompany.value?.id
        })
      }

      const getNotifications = () => {
        store.dispatch('organizations/GET_NOTIFICATIONS', {
          companyId: currentCompany.value?.id
        })
      }

      onMounted(() => {
        getAlerts()
        getNotifications()
      })

      return {
        newAlertSetting,
        setting,
        toggler,
        AlertType,
        onPageChange,
        meta,
        notifications,
        pagination,
        alerts,
        notification,
        currentCompany,
        columns,
        moment,
        getNotification,
        gotoEvent,
        gotoNewSettings,
        closeNewAlertModal,
        handleAlertSubmit
      }
    }
  })
</script>
<style lang="scss">
  .z-index-max {
    z-index: 99999 !important;
  }
</style>
