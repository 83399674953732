import { EventType } from '@/types/event'
import { OrganizationState } from './models'
import { DocumentSubCategory } from '@/services/api/models'

export const GET_TEMPLATES = 'GET_TEMPLATES'
export const SET_TEMPLATES = 'SET_TEMPLATES'
export const TEST_TEMPLATE = 'TEST_TEMPLATE'
export const GET_TEMPLATE = 'GET_TEMPLATE'
export const SET_TEMPLATE = 'SET_TEMPLATE'
export const SAVE_TEMPLATE = 'SAVE_TEMPLATE'
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE'
export const REMOVE_TEMPLATE = 'REMOVE_TEMPLATE'
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE'

export const DOWNLOAD_ENVELOPE = 'DOWNLOAD_ENVELOPE'
export const CANCEL_ENVELOPE = 'CANCEL_ENVELOPE'
export const RESEND_ENVELOPE = 'RESEND_ENVELOPE'

export const ADD_DOCAUTO_TO_OPTIONISSUANCE = 'ADD_DOCAUTO_TO_OPTIONISSUANCE'
export const ADD_DOCAUTO_TO_TRANSFER = 'ADD_DOCAUTO_TO_TRANSFER'
export const ADD_DOCAUTO_TO_OPTION_EXERCISE = 'ADD_DOCAUTO_TO_OPTION_EXERCISE'
export interface DocAutoMutations {
  [SET_TEMPLATES](state: OrganizationState, list: DocAutoTemplate[]): void
  [SET_CURRENT_TEMPLATE](
    state: OrganizationState,
    list: DocAutoTemplate | null
  ): void
}

export enum DateTimeFormatEnum {
  L = 'L',
  LL = 'll',
  LLL = 'LLL',
  LLLL = 'LLLL'
}

export enum CurrencyEnum {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  CHF = 'CHF'
}

export enum FileTypeEnum {
  PDF = 'PDF',
  DOC = 'DOC'
}

export enum OptionExerciseFieldsEnum {
  benefeciaryFullName = 'BENEFECIARY_FULL_NAME',
  benefeciaryFirstName = 'BENEFECIARY_FIRST_NAME',
  benefeciaryLastName = 'BENEFECIARY_FULL_NAME',
  benefeciaryAddress = 'BENEFECIARY_ADDRESS',
  benefeciaryZipCode = 'BENEFECIARY_ZIP_CODE',
  benefeciaryCity = 'BENEFECIARY_CITY',
  benefeciaryEmail = 'BENEFECIARY_EMAIL',
  benefeciaryBirthDate = 'BENEFECIARY_BIRTH_DATE',
  benefeciaryBirthPlace = 'BENEFECIARY_BIRTH_PLACE',
  benefeciaryInternalId1 = 'BENEFECIARY_INTERNAL_ID_1',
  benefeciaryInternalId2 = 'BENEFECIARY_INTERNAL_ID_2',
  benefeciaryNationality = 'BENEFECIARY_NATIONALITY',
  exercisedQuantity = 'EXERCISED_QUANTITY',
  exercisedQuantityInWords = 'EXERCISED_QUANTITY_IN_WORDS',
  strikePrice = 'STRIKE_PRICE',
  strikePriceTotalAmount = 'STRIKE_PRICE_TOTAL_AMOUNT',
  optionName = 'OPTION_NAME',
  subscribedQuantity = 'SUBSCRIBED_QUANTITY',
  subscribedQuantityInWords = 'SUBSCRIBED_QUANTITY_IN_WORDS',
  strikeUnitPriceInWords = 'STRIKE_UNIT_PRICE_IN_WORDS',
  strikePriceTotalAmountInWords = 'STRIKE_PRICE_TOTAL_AMOUNT_IN_WORDS'
}

export enum TransferFieldsEnum {
  sellerFirstName = 'SELLER_FIRST_NAME',
  sellerLastName = 'SELLER_LAST_NAME',
  sellerFullName = 'SELLER_FULL_NAME',
  sellerNationality = 'SELLER_NATIONALITY',
  sellerAddress = 'SELLER_ADDRESS',
  sellerZipCode = 'SELLER_ZIP_CODE',
  sellerCity = 'SELLER_CITY',
  sellerCountry = 'SELLER_COUNTRY',
  sellerEmail = 'SELLER_EMAIL',
  sellerBirthDate = 'SELLER_BIRTH_DATE',
  sellerBirthPlace = 'SELLER_BIRTH_PLACE',
  sellerAssociateAccountNumber = 'SELLER_ASSOCIATE_ACCOUNT_NUMBER',
  sellerInternalId1 = 'SELLER_INTERNAL_ID_1',
  sellerInternalId2 = 'SELLER_INTERNAL_ID_2',
  transfereeFirstName = 'TRANSFEREE_FIRST_NAME',
  transfereeLastName = 'TRANSFEREE_LAST_NAME',
  transfereeFullName = 'TRANSFEREE_FULL_NAME',
  transfereeNationality = 'TRANSFEREE_NATIONALITY',
  transfereeAddress = 'TRANSFEREE_ADDRESS',
  transfereeZipCode = 'TRANSFEREE_ZIP_CODE',
  transfereeCity = 'TRANSFEREE_CITY',
  transfereeCountry = 'TRANSFEREE_COUNTRY',
  transfereeBirthDate = 'TRANSFEREE_BIRTH_DATE',
  transfereeBirthPlace = 'TRANSFEREE_BIRTH_PLACE',
  transfereeEmail = 'TRANSFEREE_EMAIL',
  transfereeAssociateAccountNumber = 'TRANSFEREE_ASSOCIATE_ACCOUNT_NUMBER',
  transfereeInternalId1 = 'TRANSFEREE_INTERNAL_ID_1',
  transfereeInternalId2 = 'TRANSFEREE_INTERNAL_ID_2',
  quantity = 'QUANTITY',
  quantityInWords = 'QUANTITY_IN_WORDS',
  ownershipType = 'OWNERSHIP_TYPE',
  unitPrice = 'UNIT_PRICE',
  unitPriceInWords = 'UNIT_PRICE_IN_WORDS',
  totalAmount = 'TOTAL_AMOUNT',
  totalAmountInWords = 'TOTAL_AMOUNT_IN_WORDS',
  tranferDate = 'TRANSFER_DATE',
  entryDate = 'ENTRY_DATE',
  stockName = 'STOCK_NAME'
}

export enum StockIssuanceFieldsEnum {
  contactFirstName = 'CONTACT_FIRST_NAME',
  contactLastName = 'CONTACT_LAST_NAME',
  conctatFullName = 'CONTACT_FULL_NAME',
  contactAddress = 'CONTACT_ADDRESS',
  contactBirthDate = 'CONTACT_BIRTH_DATE',
  contactBirthPlace = 'CONTACT_BIRTH_PLACE',
  quantity = 'QUANTITY',
  unitPrice = 'UNIT_PRICE',
  totalAmount = 'TOTAL_AMOUNT',
  issuanceDate = 'TRANSFER_DATE',
  entryDate = 'ENTRY_DATE'
}

export enum OptionIssuanceFieldsEnum {
  benefeciaryFullName = 'BENEFECIARY_FULL_NAME',
  benefeciaryAddress = 'BENEFECIARY_ADDRESS',
  benefeciaryEmail = 'BENEFECIARY_EMAIL',
  benefeciaryBirthDate = 'BENEFECIARY_BIRTH_DATE',
  benefeciaryBirthPlace = 'BENEFECIARY_BIRTH_PLACE',
  benefeciaryFirstName = 'BENEFECIARY_FIRST_NAME',
  benefeciaryLastName = 'BENEFECIARY_LAST_NAME',
  benefeciaryZipCode = 'BENEFECIARY_ZIP_CODE',
  benefeciaryCity = 'BENEFECIARY_CITY',
  benefeciaryInternalId1 = 'BENEFECIARY_INTERNAL_ID_1',
  benefeciaryInternalId2 = 'BENEFECIARY_INTERNAL_ID_2',
  benefeciaryNationality = 'BENEFECIARY_NATIONALITY',
  issuanceDate = 'ISSUANCE_DATE',
  quantity = 'QUANTITY',
  quantityInWords = 'QUANTITY_IN_WORDS',
  unitPrice = 'UNIT_PRICE',
  totalAmount = 'TOTAL_AMOUNT',
  totalSubscriptionAmount = 'TOTAL_SUBSCRIPTION_AMOUNT',
  optionName = 'OPTION_NAME',
  expirationDate = 'EXPIRATION_DATE',
  strikePrice = 'STRIKE_PRICE',
  vestingStartDate = 'VESTING_START_DATE',
  totalStockNumber = 'TOTAL_STOCK_NUMBER',
  parity = 'PARITY'
}

// export enum StockIssuanceFieldsEnum {
//   contactFirstName = 'CONTACT_FIRST_NAME',
//   contactLastName = 'CONTACT_LAST_NAME',
//   conctatFullName = 'CONTACT_FULL_NAME',
//   contactAddress = 'CONTACT_ADDRESS',
//   contactBirthDate = 'CONTACT_BIRTH_DATE',
//   contactBirthPlace = 'CONTACT_BIRTH_PLACE',
//   quantity = 'QUANTITY',
//   unitPrice = 'UNIT_PRICE',
//   totalAmount = 'TOTAL_AMOUNT',
//   issuanceDate = 'TRANSFER_DATE',
//   entryDate = 'ENTRY_DATE'
// }

// export enum OptionIssuanceFieldsEnum {
//   benefeciaryFullName = 'BENEFECIARY_FULL_NAME',
//   benefeciaryAddress = 'BENEFECIARY_ADDRESS',
//   benefeciaryEmail = 'BENEFECIARY_EMAIL',
//   benefeciaryBirthDate = 'BENEFECIARY_BIRTH_DATE',
//   benefeciaryBirthPlace = 'BENEFECIARY_BIRTH_PLACE',
//   benefeciaryFirstName = 'BENEFECIARY_FIRST_NAME',
//   benefeciaryLastName = 'BENEFECIARY_LAST_NAME',
//   issuanceDate = 'ISSUANCE_DATE',
//   quantity = 'QUANTITY',
//   unitPrice = 'UNIT_PRICE',
//   totalAmount = 'TOTAL_AMOUNT',
//   optionName = 'OPTION_NAME',
//   expirationDate = 'EXPIRATION_DATE',
//   strikePrice = 'STRIKE_PRICE',
//   vestingStartDate = 'VESTING_START_DATE',
//   totalStockNumber = 'TOTAL_STOCK_NUMBER',
//   parity = 'PARITY'
// }

// export enum OptionExerciseFieldsEnum {
//   contactFirstName = 'CONTACT_FIRST_NAME',
//   contactLastName = 'CONTACT_LAST_NAME',
//   conctatFullName = 'CONTACT_FULL_NAME',
//   contactAddress = 'CONTACT_ADDRESS',
//   contactBirthDate = 'CONTACT_BIRTH_DATE',
//   contactBirthPlace = 'CONTACT_BIRTH_PLACE',
//   quantity = 'QUANTITY',
//   unitPrice = 'UNIT_PRICE',
//   totalAmount = 'TOTAL_AMOUNT',
//   issuanceDate = 'TRANSFER_DATE',
//   entryDate = 'ENTRY_DATE'
// }

export enum NumberFormatEnum {
  fr_FR = 'fr-FR',
  en_US = 'en-US',
  en_GB = 'en-GB',
  de_DE = 'de-DE',
  it_IT = 'it-IT',
  es_ES = 'es-ES',
  pt_PT = 'pt-PT',
  en_IN = 'en-IN',
  ja_JP = 'ja-JP'
}

export enum SignerType {
  transferee = 'TRANSFEREE',
  seller = 'SELLER',
  beneficiary = 'BENEFICIARY',
  knownContact = 'KNOWN_CONTACT'
}

export interface DocAutoTemplateForm {
  label: string
  file: Blob | null
  documentSubcategoryId: string | undefined
  eventType: EventType
  currency: CurrencyEnum
  dateTimeFormat: DateTimeFormatEnum
  numberFormat: NumberFormatEnum
}

export interface DocAutoTemplateField {
  tag: string
  eventField: string
}

export interface DocAutomationSigner {
  knownContactId: string
  signerType: SignerType
  tag: string
}
export interface DocAutoTemplate {
  id: string
  fileType: FileTypeEnum
  label: string
  eventType: EventType
  documentURL: string
  documentIdentifier: string
  currency: CurrencyEnum
  dateTimeFormat: DateTimeFormatEnum
  numberFormat: NumberFormatEnum
  templateFields: DocAutoTemplateField[]
  signers: DocAutomationSigner[]
  documentSubcategory: DocumentSubCategory | null
}
