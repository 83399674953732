
  import { Field } from 'vee-validate'
  import Checkbox from '@/components/Checkbox/index.vue'
  interface DataInputBool {
    name: string
    type: string
    label: string
    id: string
    hint?: string
    isNeeded?: boolean
  }
  import { defineComponent, ref, watch, toRefs, onMounted } from 'vue'
  export default defineComponent({
    name: 'CheckboxGeneric',
    components: { Checkbox, Field },
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputBool,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: '',
          isNeeded: false
        })
      },
      value: {
        type: Boolean,
        default: null
      },
      leftLabel: {
        type: Boolean,
        default: false
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: ''
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value } = toRefs(props)
      const formValue = ref(value.value ?? false)
      watch(
        () => formValue.value,
        () => {
          emit('onUpdated', {
            ...data.value,
            value: formValue.value
          })
        }
      )
      onMounted(() => {
        // formValue.value = props.value
      })
      return { formValue }
    }
  })
