<template>
  <div>
    <div v-if="isDetailPage" :class="`${canEdit ? 'mt-14' : ''}`">
      <EditableHeader
        :fields="headerFields"
        :can-edit="canEditHeader"
        :is-editing="canEdit && !canEditHeader"
        @save="saveEventPrimaryInfo"
        @start-edition="canEditHeader = true"
        @cancel-edition="canEditHeader = false"
      />
    </div>
    <div class="my-4 mt-5 mb-1 flex justify-between items-center">
      <div v-if="isDetailPage" class="w-1/2">
        <div class="w-1/3">
          <dropdown-contacts
            v-model="selectedContacts"
            class="w-full"
            :placeholder="$tc('contactCard.filterPlaceholder')"
            mode="multiple"
            :filter-results="false"
            :can-add-new="false"
            open-direction="bottom"
            @update:modelValue="updateSelected"
          />
        </div>
      </div>
      <div
        v-if="canEdit"
        class="
          bg-primary1
          rounded
          p-2
          text-primary3
          cursor-pointer
          border border-primary2
        "
        @click="addNewLine()"
      >
        <span class="text-sm">{{ $t('events.actionList.newLine') }}</span>
      </div>
    </div>
    <editable
      v-if="!isLoadingParts"
      :grids="
        canEdit
          ? event?.applyDocumentAutomation
            ? 17
            : 13
          : event?.applyDocumentAutomation
          ? 15
          : 11
      "
      :lines="lines"
      :columns="columns"
      :total-line="totalLine"
      :can-delete-line="true"
      :can-edit-line="true"
      :filtered="{ partsId: filteredPartsId }"
      :display-total="!isOverLimit"
      @save-line="saveLine"
      @remove-part="removePart"
      @save-new-part="saveNewLine"
      @remove-new-line="removeNewLine"
      @reset-line="resetLine"
      @docAutoActionsClick="handleDocAutoActions"
    />
    <div v-else class="w-full flex justify-center items-center text-xs">
      <Loader></Loader>
    </div>

    <div v-if="isDetailPage" class="flex justify-end mt-5">
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        class="pr-0"
        :page-size="pagination.limit"
        :total="pagination.total"
        :current-page="pagination.currentPage"
        @current-change="handlePageSelection"
      >
      </el-pagination>
    </div>
    <div v-if="isOverLimit" class="flex justify-end items-end">
      <h5 class="py-3 px-2 text-md text-primary4">
        <router-link
          :to="{
            name: isPortfolio ? 'company.eventDetails' : 'company.eventDetails',
            params: { eventId: event?.id }
          }"
          >...{{ $t('global.seeMoreParts') }}</router-link
        >
      </h5>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onMounted, watch } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  import {
    CompanyModel,
    CoOwnershipType,
    Event,
    EventPartsTotal,
    Transfer,
    TransferReason
  } from '@/services/api/models'
  import { useRoute } from 'vue-router'
  import { Pagination } from '@/store/modules/Organization/models'
  import Editable from './editable/index.vue'
  import { useI18n } from 'vue-i18n'
  import { NumberType, STOCKTYPE, TransferParts } from '@/types'
  import { TYPE } from 'vue-toastification'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import { ElPagination } from 'element-plus'
  import Loader from '@/components/Loader/small.vue'
  import EditableHeader from './editable/header.vue'
  import { DeliveryStatus } from '@/types/vesting'
  import { getSingleCompanyDocuments } from '@/services/api'
  export default defineComponent({
    name: 'TransferCard',
    components: {
      DropdownContacts,
      Editable,
      Loader,
      EditableHeader,
      ElPagination
    },
    props: {
      event: {
        type: Object as () => Event | null,
        required: true
      },
      nominalValue: {
        type: Number,
        default: 0
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      isPortfolio: {
        type: Boolean,
        default: false
      },
      canEdit: { type: Boolean, default: false }
    },
    setup(props) {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()
      const total = ref<EventPartsTotal | null>(null)
      const selectedContacts = ref<string[]>([])
      const isLoadingParts = ref(true)
      const formData = ref<any>({
        transferParts: [],
        transferDate: props.event?.transfer?.transferDate
      })
      const allParts = ref<TransferParts[]>([])

      const newLines = ref<any[]>([])
      const issuanceParts = ref<any[]>(
        props.event?.transfer?.transferParts ?? []
      )
      const limit = computed<number>(
        () => store.getters['config/eventPartsLimit']
      )
      const newSavedHeader = ref<Transfer | null>(null)
      const pagination = ref<Pagination>({
        total: 0,
        currentPage: 1,
        lastPage: 1,
        limit: limit.value
      })
      const currency = computed(() => store.getters['organizations/currency'])

      const company = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const canEditHeader = ref(false)
      const isLocal = ref(false)

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const filteredPartsId = computed<string[]>(() =>
        ((props.filters as any)?.contactsId || []).flatMap((x: string) =>
          issuanceParts.value
            .filter((p) => p.seller.id == x || p.transferee.id == x)
            .map((y) => y.id)
        )
      )

      const handleDocAutoActions = async (a: any, b: any, c: any) => {
        const part = issuanceParts.value.find((x) => x.id == b)

        if (!part?.deliveries) return
        const document = await getSingleCompanyDocuments(
          company.value.id,
          part.deliveries[0]?.documentId
        )

        if (document?.id && document.documentVersions.length > 0) {
          const file = document.documentVersions.find((v) => v.isMaster)

          const link: any = window.document.createElement('a')
          link.href = file?.documentURL
          link.download = document.name
          link.dispatchEvent(new MouseEvent('click'))
        }
      }
      const editedLinesData = ref<any[]>([])

      const totalLine = computed(() => {
        const v = [
          {
            name: 'Total',
            class: 'col-span-6 text-sm',
            key: 'total',
            width: '22.22%'
          },

          {
            name: '',
            key: 'quantity',
            value: formatNumber(total.value?.quantity ?? 0),
            class: 'text-sm',
            width: '11.11%'
          },
          {
            name: '',
            key: 'unitPrice',
            value: '-',
            width: '11.11%',
            class: 'text-sm'
          },
          {
            name: '',
            key: 'salePrice',
            value: `${formatNumber(total.value?.totalAmount ?? 0)}${
              currency.value
            }`,
            width: '11.11%',
            class: 'text-sm'
          },
          {
            name: '',
            key: 'ownershipType',
            value: '-',
            width: '11.11%',
            class: 'text-sm col-span-2'
          }
        ]

        if (props.event?.applyDocumentAutomation) {
          v.push({
            name: t('docAutomation.fields.status'),
            width: '10%',
            class: 'justify-end col-span-2',
            key: 'deliveryStatus',
            value: '-'
          })
          v.push({
            name: t('docAutomation.fields.docautoAction'),
            width: '10%',
            value: '-',
            class: 'justify-end col-span-2',
            key: 'automationActions'
          })
        }

        if (props.canEdit)
          v.push({
            name: '',
            key: 'actions',
            value: '-',
            class: 'col-span-2',
            width: '22.22%'
          })

        return v
      })

      const data = computed(() => {
        const v = [
          {
            name: t('events.elements.sellerId'),
            id: 'seller',
            width: '22.22%',
            class: 'text-left col-span-2',
            component: 'ClickableContactBadge',
            displayComponent: 'contact'
          },
          {
            name: t('events.elements.transfereeId'),
            width: '11.11%',
            id: 'transferee',
            class: 'text-left justify-start col-span-2',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.stock'),
            width: '11.11%',
            id: 'stock',
            class: 'justify-start col-span-2',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.quantity'),
            width: '11.11%',
            class: 'justify-end',
            id: 'quantity',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.unitPrice'),
            width: '11.11%',
            id: 'unitPrice',
            class: 'justify-end',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.salePrice'),
            width: '11.11%',
            class: 'justify-end',
            id: 'totalAmount',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.ownershipType'),
            width: '11.11%',
            class: 'col-span-2',
            id: 'ownershipType',
            component: 'contact-generic'
          }
        ]

        if (props.event?.applyDocumentAutomation) {
          v.push({
            name: t('docAutomation.fields.status'),
            width: '10%',
            class: 'justify-end col-span-2',
            id: 'deliveryStatus',
            component: 'p',
            displayComponent: 'p'
          })
          v.push({
            name: t('docAutomation.fields.docautoAction'),
            width: '10%',
            class: 'justify-end col-span-2',
            id: 'automationActions',
            component: 'actions',
            displayComponent: 'contact'
          })
        }

        if (props.canEdit)
          v.push({
            name: '',
            width: '22.22%',
            class: 'justify-end col-span-2',
            id: 'actions',
            component: 'actions',
            displayComponent: 'contact'
          })

        return v
      })

      const headerFields = computed(() => [
        {
          fieldId: 'transferDate',
          class: 'justify-start',
          colName: t('events.fieldsLabels.transferDate'),
          component: 'p',
          editComponent: 'date-generic',
          componentProps: {
            class: 'text-blackp text-sm text-left',
            value: isLocal.value
              ? moment.utc(newSavedHeader.value?.transferDate).format('l')
              : moment.utc(props.event?.transfer?.transferDate).format('l')
          },
          editComponentProps: {
            classes: 'mt-1.5 w-3/5',
            openDirection: 'bottom',
            value:
              newSavedHeader.value?.transferDate ??
              props.event?.transfer?.transferDate,
            data: { name: 'issuanceDate', placeholder: '' },
            onUpdated: (data: any) => {
              formData.value['transferDate'] = data.value
            }
          }
        },
        {
          fieldId: 'reason',
          class: 'justify-start',
          colName: t('events.elements.motive'),
          component: 'p',
          editComponent: 'dropdown-generic',
          componentProps: {
            class: 'text-blackp text-sm text-left',
            value: isLocal.value
              ? t(`TransferReason.${newSavedHeader.value?.reason}`)
              : t(`TransferReason.${props.event?.transfer?.reason}`)
          },
          editComponentProps: {
            options: [
              ...Object.values(TransferReason).map((x) => ({
                id: x,
                name: t(`TransferReason.${x}`),
                field: 'reason',
                defaultValue: null,
                value: x
              }))
            ],
            classes: 'mt-1.5 w-3/5',
            openDirection: 'bottom',
            value:
              newSavedHeader.value?.reason ?? props.event?.transfer?.reason,
            data: { name: 'reason', placeholder: '' },
            onUpdated: (data: any) => {
              formData.value['reason'] = data.value
            }
          }
        }
      ])

      const updateSelected = (ids: string[]) => {
        selectedContacts.value = ids
        getParts(props.event?.transfer?.id, 1, ids)
        getTotal()
      }
      const addNewLine = (fromPartId?: string) => {
        let newL = issuanceParts.value.find((x) => x.id == fromPartId)
        let index = newLines.value.length + 1
        while (newLines.value.find((x) => x.newIndex === index)) {
          index++
        }
        newLines.value.push(
          newL ?? {
            index,
            id: null,
            contact: null,
            unitPrice: 0,
            totalAmount: 0,
            quantity: 0,
            releasedAmount: 0,
            nominalIncrease: 0,
            totalIssuancePremium: 0,
            issuancePremiumPerAction: 0
          }
        )
      }

      const removeNewLine = (index: number) => {
        newLines.value = newLines.value.filter((x) => x.index != index)
      }

      const getTotal = () => {
        if (!props.event?.transfer?.id) return
        store.dispatch('organizations/GET_TRANSFER_PARTS_TOTAL', {
          companyId: currentCompany.value.id,
          transferId: props.event?.transfer?.id,
          filter: { contactsId: selectedContacts.value },
          onSuccess: (response: EventPartsTotal) => {
            total.value = response
          },
          onError: () => {
            //
          }
        })
      }

      const saveNewLine = (
        index: number,
        arrayIndex: number,
        onSuccess: any,
        onError: any
      ) => {
        const d = editedLinesData.value.find((e) => e.newIndex == index)
        if (!d) {
          onError()
          store.dispatch('toolbox/displayToast', {
            type: TYPE.ERROR,
            context: t('events.context'),
            message: t(`events.apiResponse.lineEditError`)
          })

          return
        }
        const upData = { ...d }
        for (const property in upData) {
          if (!upData[property]) delete upData[property]
        }

        store.dispatch('organizations/ADD_TRANSFER_PART', {
          companyId: currentCompany.value.id,
          transferId: props.event?.transfer?.id,
          data: upData,
          onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response)
            issuanceParts.value.unshift({
              id: response.id,
              seller: response.seller,
              transferee: response.transferee,
              optionAlias: response.optionAlias,
              stockAlias: response.stockAlias,
              compositionAlias: response.compositionAlias,
              unitPrice: response.unitPrice,
              totalAmount: response.totalAmount,
              quantity: response.quantity,
              releasedAmount: response.releasedAmount,
              nominalIncrease: response.nominalIncrease,
              totalIssuancePremium: response.totalIssuancePremium,
              issuancePremiumPerAction: response.issuancePremiumPerAction
            })
            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditSuccess`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditError`)
            })
          }
        })
      }

      const saveEventPrimaryInfo = (onSuccess: any, onError: any) => {
        const data = {
          transfereeId: formData.value?.transferee?.id,
          sellerId: formData.value?.sellerId?.id,
          ...formData.value
        }
        for (const property in data) {
          if (!data[property]) delete data[property]
        }

        store.dispatch('organizations/UPDATE_TRANSFER_PRIMARY_INFO', {
          companyId: currentCompany.value.id,
          transferId: props.event?.transfer?.id,
          data: data,
          onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response)

            newSavedHeader.value = { ...response }
            canEditHeader.value = false
            isLocal.value = true
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.primaryInfoEdited`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.primaryInfoError`)
            })
          }
        })
      }

      const saveLine = (partId: string, onSuccess: any, onError: any) => {
        const line = issuanceParts.value.find((e) => e.id == partId)
        const d = editedLinesData.value.find((e) => e.partId == partId)
        const upData = {
          sellerId: line?.seller?.id ?? d?.sellerId,
          transfereeId: line?.transferee?.id ?? d?.transfereeId,
          stockAliasId: line?.stockAlias?.id ?? d?.stockAliasId,
          optionAliasId: line?.optionAlias?.id ?? d?.optionAliasId,
          compositionAliasId:
            line?.compositionAlias?.id ?? d?.compositionAliasId,
          ...line,
          ...d
        }

        delete upData.commitment
        delete upData.seller
        delete upData.transferee
        delete upData.transfer
        delete upData.stockAlias
        delete upData.optionAlias
        delete upData.compositionAlias
        for (const property in upData) {
          if (!upData[property]) delete upData[property]
        }
        store.dispatch('organizations/UPDATE_TRANSFER_PART', {
          companyId: currentCompany.value.id,
          transferId: props.event?.transfer?.id,
          partId,
          data: upData,
          onSuccess: (response: any) => {
            const idx = issuanceParts.value.findIndex((x) => x.id == partId)
            issuanceParts.value[idx] = { ...response }
            if (onSuccess) onSuccess(response)
            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditSuccess`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditError`)
            })
          }
        })
      }

      const handleFieldEdition = (key: any, value: any, part: any) => {
        let lineIndex = -1
        if (!part?.id) {
          lineIndex = editedLinesData.value.findIndex(
            (e) => e.newIndex == part.index
          )
        } else {
          lineIndex = editedLinesData.value.findIndex(
            (e) => e.partId == part.id
          )
        }

        const elm = {
          ...part,
          [key]: value,
          partId: part.id,
          newIndex: part.index
        }
        if (lineIndex != -1) {
          editedLinesData.value[lineIndex] = { ...elm }
        } else
          editedLinesData.value.push({
            ...elm
          })
      }

      const columns = computed(() =>
        data.value.map((x) => ({
          name: x.name,
          width: x.width,
          class: x.class,
          id: x.id
        }))
      )

      const lines = computed(() =>
        [...newLines.value, ...issuanceParts.value].map((x, index) => ({
          selected:
            contactsId.value.includes(x.seller?.id) ||
            contactsId.value.includes(x.transferee?.id),
          partId: x?.id,
          newIndex: x?.index,
          seller: {
            class: 'justify-start',
            colName: 'transferor',
            component: 'ClickableContactBadge',
            editComponent: 'contact-generic',
            componentProps: {
              company: props.event?.company,
              class: 'p-0 text-xs overflow-hidden',
              details: false,
              type: 'light',
              contact: x.seller
            },
            editComponentProps: {
              classes: ' w-full',
              details: false,
              type: 'light',
              value: x?.seller?.id,
              contact: x.seller,
              data: { name: 'sellerId', placeholder: '' },
              onUpdated: (data: any) => {
                x.sellerId = data.value
                handleFieldEdition(data.name, data.value, x)
              },
              lineData: {
                transfereeIdSelection: x.seller
              }
            },
            displayComponent: '',
            partId: x?.id
          },
          transferee: {
            class: 'justify-start',
            colName: 'contact',
            component: 'ClickableContactBadge',
            editComponent: 'contact-generic',
            componentProps: {
              company: props.event?.company,
              class: 'p-0 text-xs overflow-hidden',
              details: false,
              type: 'light',
              contact: x.transferee
            },
            editComponentProps: {
              classes: ' w-full',
              details: false,
              type: 'light',
              value: x?.transferee?.id,
              contact: x.transferee,
              data: { name: 'transfereeId', placeholder: '' },
              onUpdated: (data: any) => {
                x.transfereeId = data.value
                handleFieldEdition(data.name, data.value, x)
              },
              lineData: {
                sellerIdSelection: x.transferee
              }
            },
            displayComponent: '',
            partId: x?.id
          },
          stock: {
            class: 'justify-start',
            colName: 'stock',
            component: 'tag',
            editComponent: 'dropdown-stocks',
            componentProps: {
              class: 'w-full',
              parentClass: 'text-blackp text-sm text-left',
              withInitials: false,

              value:
                x.stockAlias?.id ?? x.optionAlias?.id ?? x.compositionAlias?.id,
              text:
                x.stockAlias?.alias ??
                x.optionAlias?.alias ??
                x.compositionAlias?.alias
            },
            editComponentProps: {
              classes: 'w-full',
              class: 'w-full',
              openDirection: 'bottom',
              value: `${
                x.stockAlias?.id ?? x.optionAlias?.id ?? x.compositionAlias?.id
              }${x.commitment?.id ? `@_${x.commitment?.id}` : ''}`,
              data: { name: 'stockId', placeholder: '' },
              onUpdated: (data: any) => {
                const [stock, commitmentId] = data.value.split('@_')
                console.log('tetsttss', data.value)

                let key = 'stockAliasId'
                x.compositionAliasId = null
                x.stockAliasId = null
                x.optionAliasId = null
                x['commitmentId'] = commitmentId
                if (STOCKTYPE.COMPOSITION == data.type) {
                  key = 'compositionAliasId'
                } else if (STOCKTYPE.OPTION == data.type) {
                  key = 'optionAliasId'
                }
                x[key] = stock
                formData.value['stockId'] = stock
                formData.value[key] = stock
              },
              index: index,
              allData: formData.value,
              lineData: {
                ...x,
                sellerId: x?.seller?.id,
                stockIdSelection:
                  x.stockAlias ?? x.optionAlias ?? x.compositionAlias
              }
            },
            partId: x?.id
          },
          quantity: {
            class: 'justify-end',
            colName: 'quantity',
            component: 'number',
            editComponent: 'input-generic',
            componentProps: {
              class: 'text-sm text-right',
              value: x.quantity ?? 0,
              mode: NumberType.quantity
            },
            editComponentProps: {
              data: { name: 'quantity', placeholder: '' },
              classes: 'w-full',
              value: x.quantity,
              onUpdated: (data: any) => {
                x['quantity'] = !data.value ? '' : parseInt(data.value)
                x['totalPrice'] = !data.value
                  ? ''
                  : data.value
                  ? parseInt(x.unitPrice) / parseInt(data.value)
                  : 0

                handleFieldEdition(data.name, parseInt(data.value), x)
              }
            },

            displayComponent: '',
            partId: x?.id
          },
          totalAmount: {
            class: 'justify-end',
            colName: 'totalAmount',
            component: 'number',
            componentProps: {
              class: 'text-sm',
              value: x.totalAmount,
              mode: NumberType.price
            },
            editComponent: 'input-generic',
            editComponentProps: {
              data: { name: 'totalAmount', placeholder: '' },
              classes: 'w-full',
              value: x.totalAmount,
              onUpdated: (data: any) => {
                x['unitPrice'] =
                  x.quantity && x.totalAmount && data.value
                    ? parseInt(data.value) / parseInt(x.quantity)
                    : 0
                x['totalAmount'] = !data.value ? '' : parseInt(data.value)

                handleFieldEdition(data.name, parseInt(data.value), x)
              }
            },
            displayComponent: '',
            partId: x.id
          },
          unitPrice: {
            class: 'justify-end',
            colName: 'unitPrice',
            component: 'number',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.unitPrice ?? calculateUnitPrice(x),
              mode: NumberType.price
            },
            editComponentProps: {
              data: { name: 'unitPrice', placeholder: '' },
              classes: 'w-full',
              value:
                x.unitPrice ??
                parseFloat(calculateUnitPrice(x)?.toString()).toFixed(2),
              onUpdated: (data: any) => {
                x['unitPrice'] = !data.value ? '' : parseFloat(data.value)
                x['totalAmount'] = !data.value
                  ? ''
                  : parseFloat(data.value) ?? 0 * x.quantity
                handleFieldEdition(data.name, parseFloat(data.value), x)
              }
            },
            editComponent: 'input-generic',
            displayComponent: '',
            partId: x.id
          },
          ownershipType: {
            fieldId: 'ownershipType',
            class: 'justify-start',
            colName: t('events.elements.ownershipType'),
            component: 'p',
            editComponent: 'dropdown-generic',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: t(
                `CoOwnershipType.${
                  x?.ownershipType ?? CoOwnershipType.fullOwnership
                }`
              )
            },
            editComponentProps: {
              options: [
                ...Object.values(CoOwnershipType).map((x) => ({
                  id: x,
                  name: t(`CoOwnershipType.${x}`),
                  field: 'ownershipType',
                  defaultValue: null,
                  value: x
                }))
              ],
              classes: ' w-full',
              openDirection: 'bottom',
              value: x.ownershipType ?? CoOwnershipType.fullOwnership,
              data: { name: 'ownershipType', placeholder: '' },
              onUpdated: (data: any) => {
                x['ownershipType'] = data.value
                handleFieldEdition(data.name, data.value, x)
              }
            }
          },
          deliveryStatus: {
            class: 'justify-end',
            colName: 'stock',
            component: 'p',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.deliveries
                ? t(`docAutomation.status.${x.deliveries[0]?.status}`)
                : '-'
            },
            editComponent: 'p',
            editComponentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.deliveries
                ? t(`docAutomation.status.${x.deliveries[0]?.status}`)
                : ''
            },
            partId: x.id
          },
          automationActions: {
            class: 'justify-end',
            colName: 'stock',
            component: 'automationActions',
            componentProps: {
              deleveryId: x.deliveries ? x.deliveries[0]?.id : null,
              class: 'text-blackp text-sm text-left',
              disabled:
                !x.deliveries ||
                x.deliveries[0]?.status != DeliveryStatus.completed,
              actions: [
                ...(x.deliveries
                  ? [
                      [DeliveryStatus.completed].includes(
                        x.deliveries[0]?.status
                      )
                        ? 'download'
                        : ''
                    ]
                  : [])
              ].filter((x) => x)
            },
            editComponent: 'p',
            partId: x.id
          },

          actions: {
            class: 'justify-end',
            colName: 'stock',
            component: 'actions',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.vestingCalendar?.name || '-'
            },
            editComponent: 'dropdown-vesting',
            partId: x.id
          }
        }))
      )

      const isDetailPage = computed(() => route.name === 'company.eventDetails')

      const parts = computed<any[]>(
        () => props.event?.transfer?.transferParts || []
      )

      const isOverLimit = computed(
        () => !isDetailPage.value && parts.value.length > 20
      )

      const calculateUnitPrice = (row: any) => {
        if (!row.totalAmount && !row.quantity) return 0

        return row.totalAmount / row.quantity
      }

      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const resetLine = (index: number, partId: string) => {
        const oldLine = allParts.value.find((x) => x.id == partId)
        issuanceParts.value[index] = { ...oldLine }
      }
      const handlePageSelection = (page: number) => {
        getParts(props.event?.transfer?.id, page, [])
      }

      const removePart = (partId: string, onSuccess: any, onError: any) => {
        let id = props.event?.transfer?.id
        store.dispatch(`organizations/REMOVE_TRANSFER_PART`, {
          companyId: company.value?.id,
          transferId: id,
          partId,
          onSuccess: () => {
            if (onSuccess) onSuccess()
            issuanceParts.value = [
              ...issuanceParts.value.filter((x) => x.id !== partId)
            ]

            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineDeleteSuccess`)
            })
          },
          onError: () => {
            if (onError) onError()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineDeleteError`)
            })
          }
        })
      }

      const downloadDocautoFile = (
        id: string | undefined,
        page = 1,
        contactsId: string[]
      ) => {
        if (!id) return

        isLoadingParts.value = true
        store.dispatch('organizations/GET_TRANSFER_PARTS', {
          companyId: company.value?.id,
          transferId: id,
          page,
          filter: { contactsId },
          limit: isDetailPage.value ? 100 : null,
          onSuccess: (response: any, pageLimit = 0) => {
            const { data, metadata } = response
            pagination.value = {
              ...metadata,
              total: metadata?.count,
              limit: pageLimit
            }
            allParts.value = JSON.parse(JSON.stringify(data))

            issuanceParts.value = [...data]
            isLoadingParts.value = false
          },
          onError: () => {
            isLoadingParts.value = false
          }
        })
      }

      const getParts = (
        id: string | undefined,
        page = 1,
        contactsId: string[]
      ) => {
        if (!id) return

        isLoadingParts.value = true
        store.dispatch('organizations/GET_TRANSFER_PARTS', {
          companyId: company.value?.id,
          transferId: id,
          page,
          filter: { contactsId },
          limit: isDetailPage.value ? 100 : null,
          onSuccess: (response: any, pageLimit = 0) => {
            const { data, metadata } = response
            pagination.value = {
              ...metadata,
              total: metadata?.count,
              limit: pageLimit
            }
            allParts.value = JSON.parse(JSON.stringify(data))

            issuanceParts.value = [...data]
            isLoadingParts.value = false
          },
          onError: () => {
            isLoadingParts.value = false
          }
        })
      }

      watch(
        () => props.event,
        () => {
          getParts(props.event?.transfer?.id, 1, [])
          getTotal()
        }
      )

      watch(
        () => props.canEdit,
        (val) => {
          canEditHeader.value = val
        }
      )

      onMounted(() => {
        getParts(props.event?.transfer?.id, 1, [])
        getTotal()
      })

      return {
        lines,
        filteredPartsId,
        canEditHeader,
        totalLine,
        isDetailPage,
        selectedContacts,
        pagination,
        columns,
        headerFields,
        isLoadingParts,
        isOverLimit,
        updateSelected,
        removeNewLine,
        resetLine,
        saveEventPrimaryInfo,
        addNewLine,
        handlePageSelection,
        saveNewLine,
        saveLine,
        handleDocAutoActions,
        removePart
      }
    }
  })
</script>
