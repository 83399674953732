<template>
  <div :id="contact?.id" class="contactBadge">
    <div class="flex flex-row items-center">
      <div :class="[details ? '' : '']">
        <div
          v-if="usePicture"
          :class="[
            '',
            {
              'contactBadge__image--light border border-primary1':
                type === 'light'
            },
            'contactBadge__image m-0'
          ]"
          :style="`width:${size}em ;height:${size};`"
        >
          <p v-if="!url" class="uppercase">
            {{ initials ?? 'up' }}
          </p>
          <div
            v-else-if="isLoading"
            class="flex justify-center items-center w-full h-full"
          >
            <svg
              id="L4"
              style="
                -webkit-transform: translateX(25%);
                -ms-transform: translateX(25%);
                transform: translateX(25%);
              "
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enable-background="new 0 0 0 0"
              xml:space="preserve"
            >
              <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1"
                />
              </circle>
              <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2"
                />
              </circle>
              <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3"
                />
              </circle>
            </svg>
          </div>
          <img v-else :src="url" alt="" class="w-full h-full object-cover" />
        </div>
      </div>
      <div
        v-if="!initialsOnly"
        :class="[
          details ? 'contactBadge__details--full' : '',
          'pl-2 contactBadge__details'
        ]"
      >
        <div class="flex flex-row">
          <div class="pb-0 mb-0 flex justify-start items-center">
            <p
              v-if="name.length <= 75"
              :class="`contactBadge__name capitalize ${
                type === 'light' ? 'text-blackp' : 'text-white'
              }`"
            >
              {{ name }}
            </p>
            <el-tooltip
              v-else
              class="box-item"
              effect="dark"
              :content="name"
              placement="top-start"
            >
              <p
                :class="`contactBadge__name capitalize ${
                  type === 'light' ? 'text-blackp' : 'text-white'
                }`"
              >
                {{ truncateText(name, 75) || 'Uplaw Plateform' }}
              </p>
            </el-tooltip>
          </div>
        </div>
        <div v-if="details" class="flex flex-col">
          <!-- <div
            v-if="isLoadingData"
            class="flex justify-start items-start w-full h-full"
          >
            <svg
              id="L4"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              height="10"
              viewBox="0 0 100 70"
              enable-background="new 0 0 0 0"
              xml:space="preserve"
            >
              <circle fill="#8580f6" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1"
                />
              </circle>
              <circle fill="#8580f6" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2"
                />
              </circle>
              <circle fill="#8580f6" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3"
                />
              </circle>
            </svg>
          </div> -->

          <!-- <div v-else class="flex">
            <div class="pr-0 flex items-center" style="width: 95px">
              <h3 class="contactBadge__details__number text-sm">
                {{ stockCount }}
              </h3>
              <h5 class="text-sm ml-1">{{ $t('stockBook.title') }}</h5>
            </div>
            <div class="pl-1 flex items-center">
              <h3 class="contactBadge__details__number text-sm">
                {{ documentCount }}
              </h3>
              <h5 class="text-sm ml-1">{{ $t('documents.title') }}</h5>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { CompanyModel, ContactCardProps } from '@/services/api/models'
  import { useStore } from '@/store'
  import { abbreviateNumber, createInitials, truncText } from '@/utils'
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onBeforeUnmount,
    ref,
    watch
  } from 'vue'
  import { useRoute } from 'vue-router'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'ContactBadge',
    components: { ElTooltip },
    props: {
      usePicture: {
        type: Boolean,
        default: true
      },
      contact: {
        type: Object as () => ContactCardProps,
        required: false,
        default: () => ({})
      },
      details: {
        type: Boolean,
        required: false,
        default: false
      },
      initialsOnly: {
        type: Boolean,
        required: false,
        default: false
      },
      type: {
        type: String,
        required: false,
        default: 'primary'
      },
      mode: {
        type: String,
        required: false,
        default: 'contact'
      },
      size: {
        type: String,
        required: false,
        default: '45' //size in em
      },
      blockType: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(props) {
      const store = useStore()
      const route = useRoute()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const url = ref()
      const isLoading = ref(false)
      const isLoadingData = ref(props.details)
      const contactsPictures = computed(
        () => store.getters['organizations/contactsPictures']
      )

      const actions: any = {
        contact: 'LOAD_CONTACT_PICTURE',
        company: 'LOAD_COMPANY_LOGO',
        user: 'LOAD_USER_PROFILE_IMAGE'
      }

      const companiesLogos = computed(
        () => store.getters['organizations/companiesLogo']
      )
      const documentCount = ref(
        abbreviateNumber(Math.abs(props.contact?.documentsCount) || 0)
      )
      const stockCount = ref(abbreviateNumber(props.contact?.stocksCount || 0))

      const ucFirst = (text = '') => {
        const value = text
          .split(' ')
          .map(
            (elm) =>
              `${elm.charAt(0).toUpperCase()}${elm.slice(1).toLowerCase()}`
          )
          .join(' ')
        return value
      }
      const loadImage = async () => {
        if (
          props.contact?.id &&
          (contactsPictures.value[props.contact?.id] !== undefined ||
            companiesLogos.value[props.contact?.id] !== undefined)
        ) {
          url.value =
            contactsPictures.value[props.contact?.id] ||
            companiesLogos.value[props.contact?.id]

          return
        }
        isLoading.value = true
        const action: any = actions[props.mode]
        const response = await store.dispatch(`organizations/${action}`, {
          companyId:
            props.mode === 'company'
              ? props.contact?.id
              : currentCompany.value?.id ||
                route.params.id ||
                props.contact?.id,
          contactId: props.contact?.id,
          userId: props.contact?.id
        })
        if (response) url.value = response
        else url.value = ''

        isLoading.value = false
      }

      const loadComputedData = async () => {
        isLoadingData.value = true
        const response = await store.dispatch(
          'organizations/LOAD_CONTACT_COMPUTED_DATA',
          {
            companyId: currentCompany.value.id,
            contactId: props.contact?.id
          }
        )

        documentCount.value = abbreviateNumber(response.documentsCount || 0)
        stockCount.value = abbreviateNumber(response.stocksCount || 0)
        isLoadingData.value = false
      }
      const name = computed(() =>
        props.contact?.name
          ? props.contact?.name
          : `${props.contact?.lastName} ${props.contact?.firstName}`
      )
      const initials = computed(() => createInitials(name.value))

      onBeforeMount(async () => {
        url.value = ''

        if (
          props.mode === 'contact' &&
          props.usePicture &&
          props.contact?.picture &&
          props.contact?.picture.pictureIdentifier
        ) {
          loadImage()
        } else {
          if (props.mode !== 'contact' && props.usePicture) loadImage()
        }
        // if (props.details) await loadComputedData()
      })

      onBeforeUnmount(() => {
        url.value = ''
      })

      watch(
        () => props.contact.id,
        () => {
          url.value = ''
          if (
            props.usePicture &&
            props.mode === 'contact' &&
            props.contact?.picture?.pictureIdentifier
          )
            loadImage()
          else {
            if (props.mode !== 'contact' && props.usePicture) loadImage()
          }
        }
      )

      return {
        initials,
        name,
        documentCount,
        stockCount,
        url,
        isLoadingData,
        isLoading,
        truncateText: truncText
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'contactbadge.scss';
</style>
