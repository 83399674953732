import { OrganizationState, OrganizationGetters } from './models'
import { RootState } from '@/store'
import { GetterTree } from 'vuex'
export const getters: GetterTree<OrganizationState, RootState> &
  OrganizationGetters = {
  currency: (state) => state.currency,
  companies: (state) => state.companies,
  loaderCompanies: (state) => state.loaderCompanies,
  company: (state) => state.company,
  nominalValue: (state) => state.nominalValue,
  nominalValues: (state) => state.nominalValues,
  conversions: (state) => state.conversions,
  delegatedTransactions: (state) => state.delegatedTransactions,
  functions: (state) => state.functions,
  nominations: (state) => state.nominations,
  transfers: (state) => state.transfers,
  isLoading: (state) => state.isLoading,
  contactListPagination: (state) => state.contactListPagination,
  contextCompany: (state) => state.contextCompany,
  isLoadingUserData: (state) => state.loadingUserData,
  isLoadingCompanyData: (state) => state.loadingCompanyData,
  searchedText: (state) => state.searchedText,
  isLoadingStock: (state) => state.isLoadingStock,
  vestings: (state) => state.vestings,
  events: (state) => state.events,
  searchingText: (state) => state.searching,
  loadingDocuments: (state) => state.loadingDocuments,
  gettingDocuments: (state) => state.gettingDocuments,
  loadingEvents: (state) => state.loadingEvents,
  isExporting: (state) => state.downloading,
  vestingSuspension: (state) => state.vestingSuspension,
  vestingAcceleration: (state) => state.vestingAcceleration,
  vestingDelayProrogation: (state) => state.vestingDelayProrogation,
  financialEndings: (state) => state.financialEndings,
  primaryStockEmission: (state) => state.primaryStockEmission,
  primaryOptionsEmission: (state) => state.primaryOptionsEmission,
  primaryCompositionEmission: (state) => state.primaryCompositionEmission,
  companyStocks: (state) => state.companyStocks,
  committees: (state) => state.committees,
  ceilings: (state) => state.ceilings,
  approvals: (state) => state.approvals,
  capitalReductions: (state) => state.capitalReductions,
  delegations: (state) => state.delegations,
  transactions: (state) => state.transactions,
  transactionsPagination: (state) => state.transactionsPagination,
  companyContacts: (state) => state.companyContacts,
  companyUuid: (state) => state.currentCompanyUuid,
  documents: (state) => state.documents,
  documentsPagination: (state) => state.documentsPagination,
  currentDocument: (state) => state.currentDocument,
  documentList: (state) => state.documentList,
  searchResults: (state) => state.documentSearchResult,
  stockAliases: (state) => state.stockAliases,
  optionStockAliases: (state) => state.optionStockAliases,
  compositionStockAlias: (state) => state.compositionStockAliases,
  allStocks: (state) => [
    ...state.stockAliases,
    ...state.optionStockAliases,
    ...state.compositionStockAliases
  ],
  currentStockAlias: (state) => state.currentStock,
  currentCommittee: (state) => state.currentCommittee,
  currentOrganigram: (state) => state.currentOrganigram,
  form: (state) => state.forms,
  vesting: (state) => state.currentVesting,
  isLoadingVesting: (state) => state.isLoadingVesting,
  contacts: (state) => state.contacts,
  contact: (state) => state.currentContact,
  contactsResult: (state) => state.contactsResult,
  contactDetails: (state) => state.contactDetails,
  documentAudits: (state) => state.documentAudits,
  eventAudits: (state) => state.eventAudits,
  contactAudits: (state) => state.contactAudits,
  attachedFiles: (state) => state.attachedFiles,
  auditPagination: (state) => state.auditPagination,
  companyUsers: (state) => state.accounts,
  accountsPagination: (state) => state.accountsPagination,
  captable: (state) => state.captable,
  contactsList: (state) => state.contactsList,
  corporateDocuments: (state) => state.corporateDocuments,
  groups: (state) => state.groups,
  group: (state) => state.group,
  companiesPagination: (state) => state.companiesPagination,
  captablePagination: (state) => state.captablePagination,
  committeesPagination: (state) => state.committeesPagination,
  nominationsPagination: (state) => state.nominationsPagination,
  eventsPagination: (state) => state.eventsPagination,
  searchedEventsPagination: (state) => state.searchedEventsPagination,
  searchedEvents: (state) => state.searchedEvents,
  searchingEvents: (state) => state.searchingEvents,
  stockGroups: (state) => state.stockGroups,
  stockGroup: (state) => state.stockGroup,
  exerciseCalendars: (state) => state.exerciseCalendars,
  exerciseCalendarPagination: (state) => state.exerciseCalendarPagination,
  exerciseCalendarSynthesisPagination: (state) =>
    state.exerciseCalendarSynthesisPagination,
  exerciseCalendarsTotal: (state) => state.exerciseCalendarsTotal,
  vestingSyntheses: (state) => state.vestingSyntheses,
  contactExtraData: (state) => state.contactExtraData,
  event: (state) => state.event,
  bodac: (state) => state.bodac,
  contactsPictures: (state) => state.contactsPicture,
  companiesLogo: (state) => state.companiesLogo,
  usersProfilesImages: (state) => state.usersProfilesImages,
  companyGlobalData: (state) => state.companyGlobalData,
  companyCapitalByGroup: (state) => state.companyCapitalByGroup,
  companyCapitalByStockCategory: (state) => state.companyCapitalByStockCategory,
  companyCapitalByEsopCategory: (state) => state.companyCapitalByEsopCategory,
  esopData: (state) => state.esopData,
  fundingData: (state) => state.fundingData,
  profitSharingEvolution: (state) => state.profitSharingEvolution,
  accountUsers: (state) => state.accountUsers,
  currentPartContactId: (state) => state.currentPartContactId,
  eventPartLinkedDocuments: (state) => state.eventPartLinkedDocuments,
  fundRaisingRounds: (state) => state.fundRaisingRounds,
  fundRaisingRound: (state) => state.currentFundRaisingRound,
  profitSharingPlans: (state) => state.profitSharingPlans,
  profitSharingPlan: (state) => state.currentProfitSharingPlan,
  operations: (state) => state.operations,
  operation: (state) => state.currentOperation,
  alerts: (state) => state.alerts,
  notifications: (state) => state.notifications,
  templates: (state) => state.templates,
  templatesPagination: (state) => state.templatesPagination,
  template: (state) => state.template,
  notificationsPagination: (state) => state.notificationsPagination
}
