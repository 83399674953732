import { createApp, h, computed, vModelRadio } from 'vue'
// import '@/scss/element/index.scss'
import VueClickAway from 'vue3-click-away'
import VTooltip from 'v-tooltip'
import App from './App.vue'
import { Auth0 } from './plugins/auth'
import Swal from './plugins/swal'
import i18n from './services/lang'
import { GlobalConfig } from '@/types'
import conf from '@/config'
import store from './store'
import { access } from './helpers'
import Toast, { POSITION, PluginOptions } from 'vue-toastification'
import VCalendar from 'v-calendar'
import Popper from 'vue3-popper'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2'
import VueMask from '@devindex/vue-mask'

import VueGoogleMaps from '@fawmi/vue-google-maps'
import 'element-plus/dist/index.css'
import '@vueform/multiselect/themes/default.css'
import '@vueform/toggle/themes/default.css'
import '@/scss/_main.scss'
import 'vue-toastification/dist/index.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import simpleAcl from './plugins/acl'
import * as Sentry from '@sentry/vue'
import * as SentryBrowser from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import 'prismjs'
import 'prismjs/themes/prism.css'

const options: PluginOptions = {
  toastClassName: 'upl-toast',
  position: POSITION.BOTTOM_RIGHT
}

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
} from 'chart.js'
import { getFormStorage } from './services/Storage'
import { storageKeys } from './utils/global'

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
)
let vm = null
const init = async () => {
  const module = await import('./router')
  const router = await module.default

  moment.locale(store.getters['config/lang'])

  const config = conf as GlobalConfig

  const AuthPlugin = await Auth0.init({
    domain: config.auth0Options.domain,
    audience: config.auth0Options.audience,
    clientId: config.auth0Options.clientId,
    redirectUri: config.auth0Options.redirectUri,
    locale: store.getters['config/lang'],
    onRedirectCallback: (appState) => {
      return router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.href
      )
    }
  })
  const sweetAlertOptions = {
    // confirmButtonColor: uplTheme.colors.primary,
    // cancelButtonColor: uplTheme.colors.error,
  }

  vm = createApp({ render: () => h(App) })

  const uplawUser = store.getters['auth/profile']
  window.Intercom('boot', {
    api_base: 'https://api-iam.intercom.io',
    app_id: 'exuo2pdv',
    user_id: uplawUser?.id || '', // Id
    name: uplawUser?.firstName + ' ' + uplawUser?.lastName, // Nom complet
    email: uplawUser?.email // Adresse e-mail
  })
  window.Intercom('update')

  const authUser = store.getters['auth/auth']
  const cmpName = getFormStorage(storageKeys.currentCompanyName)
  SentryBrowser.setUser({ ...authUser, company: cmpName })
  Sentry.setUser({ ...authUser, company: cmpName })
  const integrations: any = [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'uplaw.fr', /^\//]
    })
  ]

  if (!window.location.origin.includes('localhost'))
    integrations.push(new Sentry.Replay())
  Sentry.init({
    app: vm,
    dsn: config.urls.sentryDsn,
    release: `web_app-${moment().format('DD_MM_yyyy-HH').toString()}`,
    environment: cmpName || config.environement,
    // debug: config.environement != ENV_TYPE.production,
    debug: false,
    integrations: integrations,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    tracesSampleRate: 1.0
  })

  vm.use(i18n)
    .use(AuthPlugin)
    .use(VueClickAway)
    .use(router)
    .use(Swal)
    .use(VTooltip)
    .use(VCalendar, {})
    .use(VueSweetalert2, sweetAlertOptions)
    .use(store)
    .use(VueMask)
    .use(VueGoogleMaps, {
      load: {
        key: config.googleMap.apiKey,
        libraries: 'places'
      }
    })
    .use(Toast, options)
    .use(simpleAcl)
    .provide('$access', access)
    .component('Popper', Popper)
    .mount('#app')
}

init()

export { vm }
