import { MutationTree } from 'vuex'
import { DocAutoMutations } from './docAutoModel'
import { OrganizationState } from './models'
import { SET_TEMPLATES, SET_CURRENT_TEMPLATE } from './docAutoModel'

export const docAutoMutations: MutationTree<OrganizationState> &
  DocAutoMutations = {
  [SET_TEMPLATES](state, result: any) {
    state.templates = [...result.data]
    state.templatesPagination.currentPage = result.metadata.currentPage
    state.templatesPagination.lastPage = result.metadata.lastPage
    state.templatesPagination.total = result.metadata.count
  },
  [SET_CURRENT_TEMPLATE](state, result) {
    state.template = result
  }
}
