
  import { Delegation } from '@/services/api/models'
  import { defineComponent } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'DelegationCard',
    components: { ElTooltip },

    props: {
      event: {
        type: Object as () => Delegation | undefined,
        default: null
      }
    },
    setup() {
      return { moment, formatNumber }
    }
  })
