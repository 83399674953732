import {
  Appointment,
  Commitment,
  Committee,
  Company,
  Delegation,
  DelegationSunset,
  FinancialEnding,
  NominalValue,
  OptionIssuance,
  StockIssuance,
  Transfer,
  VestingAcceleration,
  VestingDelayProrogation,
  VestingSuspension
} from '@/services/api/models/company'
import { Document } from '@/services/api/models/document'
import {
  CapitalReduction,
  AppointmentTermination,
  Contact,
  OptionStockAlias,
  StockAlias,
  CompositionStockAlias,
  Transaction
} from './company'
import {
  OptionExercise,
  OptionSunsetTypeEnum,
  EndOfContractReasons
} from '@/types/forms'
import { CompositionIssuance, ExerciseCalendar, ParityChange } from './company'
import { OptionSunsetMode } from '../../../types/forms'
import { OptionSunsetType } from '@/types/event'

enum EventType {
  ESTABLISHMENT,
  STOCK_ISSUANCE,
  OPTION_ISSUANCE,
  COMPOSITION_ISSUANCE,
  TRANSFER,
  FINANCIAL_ENDING,
  COMMITTEE,
  APPOINTMENT,
  NOMINAL_VALUE,
  COMMON_CEILING,
  VESTING_SUSPENSION,
  VESTING_ACCELERATION,
  EXERCISE_DELAY_PROROGATION,
  DELEGATION,
  DELEGATION_SUNSET
}
export interface EventSubCategory {
  id: string
  name: string
  description: string
  eventCategory: EventCategory
  events: Event[]
  eventSubCategoryForm: EventSubCategoryForm
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface EventCategory {
  id: string
  name: string
  description: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
  eventSubCategories: EventSubCategory[]
}

export class EventPartsTotal {
  quantity?: number
  issuedQuantity?: number
  totalIssuancePremium?: number
  nominalIncrease?: number
  issuancePremiumPerAction?: number
  totalPrice?: number
  totalAmount?: number
  releasedAmount?: number
  vestedQuantity?: number
  notVestedQuantity?: number
}

export interface Event {
  type?: EventType
  isDraft: boolean
  eventSubCategory?: EventSubCategory
  company: Company
  documents?: Document[]
  eventAudits: EventAudit[]
  accountApproval?: AccountApproval
  optionIssuance?: OptionIssuance
  stockIssuance?: StockIssuance
  compositionIssuance?: CompositionIssuance
  transfer?: Transfer
  financialEnding?: FinancialEnding
  committee?: Committee
  appointment?: Appointment
  appointmentTermination?: AppointmentTermination
  nominalValue?: NominalValue
  commonCeiling?: CommonCeiling
  vestingSuspension?: VestingSuspension
  vestingAcceleration?: VestingAcceleration
  applyDocumentAutomation: boolean
  exerciseDelayProrogation?: VestingDelayProrogation
  capitalReduction?: CapitalReduction
  optionExercise?: OptionExercise
  delegation?: Delegation
  delegationSunset?: DelegationSunset
  parityChange?: ParityChange
  conversion?: Conversion
  optionSunsets?: OptionSunset
  audits: any[]
  id: string
  date: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  version: number
  commitment?: Commitment
}

interface EventSubCategoryForm {
  name: string
  description: string
  eventSubCategoryDataEntries: EventSubCategoryDataEntry[]
  eventForms: EventForm[]
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

interface EventForm {
  eventSubCategoryForm: EventSubCategoryForm
  data: any
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

interface EventSubCategoryDataEntry {
  name: string
  description: string
  eventSubCategoryForm: EventSubCategoryForm
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface EventAudit {
  event: Event
  comment: string
  id: string
  motive: string[]
  processed: boolean
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface CommonCeiling {
  id: string
  date: string
  quantity: number
  type: string
  label: string
  documents?: Document[]
}
export interface OptionSunsetPart {
  id: string
  exerciseCalendar: ExerciseCalendar
  motive: EndOfContractReasons
  exercisableBalanceDate: string
  nonVestedDate: string
  nonVestedQuantity: number
  exercisableQuantity: number
}
export interface OptionSunset {
  id: string
  date: string
  mode: OptionSunsetMode
  type: OptionSunsetType
  nonExercisableSunsetDate: string
  sunsetedExercisablePercentage: string
  exercisableSunsetDate: string
  optionSunsetParts: OptionSunsetPart[]
}

export interface AccountApproval {
  date?: string
  company: Company
  event?: Event
  earningsAllocation?: string
  result?: number
  turnover?: number
  financialEndingDate?: string
  allocationType: string | null
  amount: number
  id: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  version: number
}

export interface ConversionPart {
  id: string
  contact: Contact
  quantity: number
  parity?: number
  fromOptionAlias?: OptionStockAlias
  fromStockAlias?: StockAlias
  fromCompositionAlias?: CompositionStockAlias
  toStockAlias?: StockAlias
  documents?: Document[]
  toOptionAlias?: OptionStockAlias
  toCompositionAlias?: CompositionStockAlias
  numberOfNewStocks: number
  transactions: Transaction[]
  entryDate?: string
}

export interface Conversion {
  id: string
  conversionDate: string
  conversionParts: ConversionPart[]
  createdAt: string
  updatedAt: string
  deletedAt?: string
}

export interface EventSubmitProps {
  entryName: string
  withRedirect: boolean
  eventId?: string | null
  action?: string
  eventCreated: () => void
  onCreationError: (e: any) => void
}
