
  import { defineComponent, computed, toRefs, ref, onMounted } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import DataTable from '@/components/DataTable/index.vue'
  import { CompanyModel, Document, Event } from '@/services/api/models'
  import { Button } from '@up.law/uplaw-ui'
  import { TYPE } from 'vue-toastification'
  import { ElPopconfirm } from 'element-plus'
  import EventPartLinkedDocuments from '@/components/Event/EventPartLinkedDocuments.vue'
  import { Pagination } from '@/store/modules/Organization/models'
  import Loader from '@/components/Loader/index.vue'
  export default defineComponent({
    name: 'EventDocumentsCard',
    components: {
      Button,
      Loader,
      DataTable,
      ElPopconfirm,
      EventPartLinkedDocuments
    },
    props: {
      event: {
        type: Object as () => Event,
        default: null
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },

    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { event } = toRefs(props)
      const { t } = useI18n()
      const isLoading = ref(false)

      const documents = ref<any[]>([])
      const limit = computed<number>(
        () => store.getters['config/eventDocumentsLimit']
      )
      const pagination = ref<Pagination>({
        total: 0,
        currentPage: 1,
        lastPage: 1,
        limit: 40
      })

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const parts = computed(
        () =>
          event.value?.optionIssuance?.optionIssuanceParts ||
          event.value?.stockIssuance?.stockIssuanceParts ||
          event.value?.conversion?.conversionParts ||
          event.value?.compositionIssuance?.compositionIssuanceParts ||
          event.value?.optionExercise?.optionExerciseParts ||
          event.value?.transfer?.transferParts ||
          event.value?.capitalReduction?.capitalReductionParts ||
          []
      )
      const columns = ref([
        {
          label: t('documents.elements.name'),
          field: 'name',
          width: '40%',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          width: '5%',
          custom: true
        }
      ])

      const partsColumns = ref([
        {
          label: t('documents.elements.contact'),
          field: 'contact',
          width: '10%',
          custom: true
        },
        {
          label: t('documents.elements.name'),
          field: 'name',
          width: '40%',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          width: '5%',
          custom: true
        }
      ])

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      const isOverLimit = computed(
        () => pagination.value.currentPage !== pagination.value.lastPage
      )

      const handleDocumentView = async (doc: Document) => {
        if (currentDocument?.value?.id === doc.id) return
        store.commit('ui/DISPLAY_PREVIEW', true)
        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }

      const getEventDocuments = (eventId: string, page = 1) => {
        if (!eventId) return
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT_DOCUMENTS`, {
          companyId: currentCompany.value.id,
          eventId: props.event.id,
          page,
          onSuccess: (response: any) => {
            const { data, metadata } = response
            documents.value = [...data]
            pagination.value = { ...metadata, total: metadata.count }
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const handlePageChange = (page: number) => {
        getEventDocuments(props.event?.id, page)
      }

      const removeDocument = (doc: Document) => {
        store.dispatch('organizations/REMOVE_EVENT_DOCUMENT', {
          companyId: currentCompany.value.id,
          eventId: event.value?.id,
          documentId: doc.id,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }
      onMounted(() => {
        getEventDocuments(props.event?.id, 1)
      })
      return {
        handleDocumentView,
        columns,
        getEventDocuments,
        handlePageChange,
        partsColumns,
        isOverLimit,
        parts,
        isLoading,
        documents,
        pagination,
        removeDocument
      }
    }
  })
