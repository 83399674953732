
  import { Form, Field } from 'vee-validate'
  import {
    defineComponent,
    ref,
    watch,
    computed,
    onMounted,
    onBeforeMount
  } from 'vue'
  import { Input } from '@up.law/uplaw-ui'
  import * as yup from 'yup'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import Multiselect from '../../Select/multiselect.vue'
  import { CompanyModel, Contact, Role } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import LangSelector from '@/components/LangSelector/index.vue'

  interface NewInvitationFormProps {
    email: string
    lastName: string
    firstName: string
    contactId?: string
    lang: string
    companyId: string
    roles: string
    connection: string
  }
  export default defineComponent({
    components: {
      UplInput: Input,
      Form,
      LangSelector,
      Field,
      Multiselect
    },
    props: {
      index: {
        type: Number,
        required: true
      }
    },
    emits: ['handleChange', 'handleRemove'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { t, te } = useI18n()
      const connectionModes = computed(() => [
        {
          id: 'normal',
          name: t('account.accountInformations.form.normal_login')
        },
        { id: 'connectionThroughSSO', name: 'SSO (Google)' },
        { id: 'connectionThroughApple', name: 'Apple' }
      ])
      const currentLocale = computed(() => store.getters['config/lang'])

      const invitee = ref<NewInvitationFormProps>({
        email: '',
        lastName: '',
        firstName: '',
        connection: 'normal',
        lang: currentLocale.value ?? 'fr',
        roles: '',
        contactId: '',
        companyId: ''
      })

      const selectedRolePriority = computed(
        () =>
          allRoles.value.find((x) => x.id === invitee.value.roles)?.priority ||
          0
      )

      const companies = ref<CompanyModel[]>([])

      const highestPriority = computed(() =>
        Math.max(
          ...(store.getters['auth/roles'] || []).map((x: Role) => x.priority)
        )
      )

      const allRoles = computed<Role[]>(() => store.getters['toolbox/roles'])

      const roles = computed(() =>
        [...allRoles.value]
          .filter(
            (x) =>
              x.priority >=
              (![0, 2].includes(highestPriority.value)
                ? highestPriority.value + 1
                : highestPriority.value)
          )
          .sort((a, b) => a.priority - b.priority)
          .map((r) => ({
            ...r,
            name: te(`roles.${r.slug}`) ? t(`roles.${r.slug}`) : r.name
          }))
      )
      const contacts = ref<Contact[]>([])

      const selectectedContacts = []
      const handleSearchCompanies = (text?: string) => {
        getCompanies(text)
      }

      const handleCompany = (data: any, index: number) => {
        // invitee.value.companyId = data
        // invitee.value.contactId = ''
      }

      const schema = yup.object({
        email: yup.string().email().required(),
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        roles: yup.string().required(),
        contactId: yup.string().required(),
        companyId: yup.string().required('companyRequired')
      })

      const onChange = (errors: Record<string, string>) => {
        emit('handleChange', props.index, invitee.value)
      }

      const roleChanged = (e?: string) => {
        const role = allRoles.value.find((r) => r.id === e)
        if ((role?.priority || 2) <= 1) {
          invitee.value.companyId = ''
          invitee.value.contactId = ''
        }
        invitee.value.roles = e || ''
        onChange({})
      }

      const onCompanyUpdated = (id: string) => {
        invitee.value.companyId = id
        contacts.value = []

        if (!id) return
        store.dispatch('auth/GET_CONTACTS', {
          filter: {
            limit: 20,
            offset: 0,
            companyId: id
          },
          onSuccess: (response: any) => {
            contacts.value = [...(response.data ?? [])]
          }
        })
      }

      const contactSearch = (text: string) => {
        store.dispatch('auth/GET_CONTACTS', {
          filter: {
            companyId: invitee.value.companyId,
            limit: 20,
            offset: 0,
            search: text
          },
          onSuccess: (response: any) => {
            contacts.value = [...(response.data ?? [])]
          }
        })
      }

      const getCompanies = (search = '') => {
        store.dispatch('organizations/GET_ACCOUNT_COMPANIES', {
          companyId: route.params.id,
          filter: {
            limit: 20,
            offset: 0,
            search
          },
          onSuccess: (data: CompanyModel[]) => {
            companies.value = [...data]
          }
        })
      }

      const handleRemove = () => {
        emit('handleRemove', props.index)
      }
      const getRoles = () => {
        store.dispatch('toolbox/GET_ROLES', {
          companyId: route.params.id
        })
      }

      const updateSelected = (data: Record<string, string>) => {
        onChange(data)
      }

      watch(
        () => invitee.value,
        (val) => {
          emit('handleChange', props.index, val)
        }
      )

      watch(selectedRolePriority, (val) => {
        if (val && val > 1) invitee.value.companyId = ''
      })

      onBeforeMount(() => {
        getCompanies()
      })

      onMounted(() => {
        getRoles()
      })

      return {
        schema,
        invitee,
        roles,
        connectionModes,
        contacts,
        selectedRolePriority,
        companies,
        contactSearch,
        onCompanyUpdated,
        handleCompany,
        handleSearchCompanies,
        onChange,
        updateSelected,
        handleRemove,
        roleChanged
      }
    }
  })
