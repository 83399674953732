<template>
  <div v-if="isLoading">
    <div class="w-full flex justify-center items-center text-xs">
      <Loader></Loader>
    </div>
  </div>
  <div
    v-else
    class="w-full h-full"
    :class="isModalOpen ? 'modal_opened overflow-hidden h-full' : ''"
  >
    <div ref="toasts" class="layout_toasts" />
    <div class="layout w-full h-full flex flex-col" data-theme="default">
      <!-- HEADER -->
      <Header
        :user="user"
        :environment="canDisplayEnv ? appEnv : undefined"
        :account-actions="[]"
      />
      <!-- MENU -->
      <nav v-if="currentCompany" class="layout_menu pr-5">
        <div class="flex justify-between w-full h-full items-end">
          <SubHeader v-if="!isPortfolioRoute" />
          <PortfolioSubHeader v-else />
          <div
            v-if="!isPortfolioRoute"
            v-click-away="closeCompanySelector"
            class="layout_menu-companyselector transition-all duration-1000"
            :class="{ active: displaySelector }"
          >
            <div
              class="companyselector-current border border-secondary3 flex w-full overflow-hidden justify-between items-center px-2 my-1 py-1 hover:bg-primary5"
              @click.prevent.stop="companySelectorOpen()"
            >
              <ContactBadge
                v-if="currentCompany"
                :use-picture="true"
                class="m-0"
                :contact="currentCompany"
                type="light"
                mode="company"
              />
              <i class="ml-2 text-blackp font-semibold bi-chevron-down" />
            </div>

            <div
              v-if="companies && displaySelector"
              class="companyselector-list overflow-scroll"
            >
              <ul class="transition-all duration-1000">
                <li
                  v-can.any="['admin', 'is-manager']"
                  class="companyselector-listItem p-0 bg-white"
                >
                  <a
                    class="text-lg text-primary3"
                    :href="portfolioLink"
                    rel="noopener noreferrer"
                  >
                    Portfolio <span class="bi bi-arrow-right-circle"></span>
                  </a>
                </li>
                <li class="companyselector-listItem p-0 bg-white">
                  <input
                    ref="selectorInput"
                    v-model="companySearch"
                    autofocus
                    type="search"
                    :placeholder="$t('global.placeholder.search')"
                    @input="handleUserSearch"
                  />
                </li>

                <li
                  v-for="company in companies"
                  :key="company.id"
                  :class="[
                    {
                      'companyselector-listItem--active':
                        company.id === currentCompany.id
                    },
                    'companyselector-listItem flex text-blackp'
                  ]"
                  @click="setCurrentCompany(company)"
                >
                  <ContactBadge
                    :use-picture="true"
                    class="m-0"
                    :contact="{ ...company, documentsCount: 0, stocksCount: 0 }"
                    type="light"
                    mode="company"
                  />
                </li>
                <li v-if="!companies" class="companyselector-listItem">
                  <p>{{ $t('menu.noResult') }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <!-- MAIN -->
      <main class="layout_main">
        <div class="flex h-full w-full overflow-hidden">
          <div class="layout__main__body overflow-scroll">
            <slot class="h-full" />
          </div>
          <transition name="slide-fade" mode="out-in">
            <div
              v-show="
                documentToShow &&
                $route.meta.docPreview === 'integred' &&
                isPreviewDisplayed
              "
              :class="[
                $route.meta.docPreview === 'integred'
                  ? 'layout__docPreview--integred z-10'
                  : 'layout__docPreview--on-top',

                minifyDocPreview
                  ? isPreviewDisplayed && type == 'md'
                    ? 'minify absolute right-0'
                    : 'minify'
                  : isPreviewDisplayed && type == 'md'
                  ? 'unminify absolute w-1/2 right-0 shadow'
                  : 'unminify'
              ]"
            >
              <div
                v-if="$route.meta.docPreview === 'integred'"
                class="upl-docPreview__toggle"
                role="button"
                @click="handleClick"
              >
                <i
                  :class="
                    'bi-chevron-compact-' +
                    (minifyDocPreview ? 'left' : 'right')
                  "
                />
              </div>
              <DocPreview
                v-click-away="autoCloseDocumentPreview"
                :document="documentToShow"
                :display-style="displayStyle"
                @close-preview="closePreView"
              />
            </div>
          </transition>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    ref,
    computed,
    onMounted,
    onErrorCaptured,
    watch
  } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from '@/store'
  import { envName } from '@/config'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import Header from '@/components/Header/index.vue'
  import SubHeader from '@/components/Header/Subnav.vue'
  import PortfolioSubHeader from '@/components/Header/SubnavPortfolio.vue'
  import { createInitials, storageKeys, truncText } from '@/utils'
  import { getFormStorage } from '@/services/Storage'
  import DocPreview from '@/components/DocPreview/index.vue'
  import { CompanyModel, User } from '@/services/api/models'
  import useBreakpoints from '@/plugins/breakpoint'
  import * as Sentry from '@sentry/vue'
  import Loader from '@/components/Loader/small.vue'

  export default defineComponent({
    name: 'Logged',
    components: {
      DocPreview,
      ContactBadge,
      Header,
      SubHeader,
      PortfolioSubHeader,
      Loader
    },

    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const displaySelector = ref(false)
      const companySearch = ref('')
      const isModalOpen = ref(true)

      const isLoading = computed(() => store.getters['auth/loading'])
      const selectorInput = ref<HTMLInputElement | null>(null)
      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )
      const displayStyle = computed<string | undefined>(
        () => route?.meta?.docPreview as string
      )

      const portfolioLink = computed(
        () => `${location.origin}/app/portfolio-view`
      )
      const documentToShow = computed(
        () => store.getters['organizations/currentDocument']
      )
      const { type } = useBreakpoints()
      const isPreviewDisplayed = computed(() => {
        return store.getters['ui/displayDocumentPreview']
      })

      const handleClick = () => {
        minifyDocPreview.value = !minifyDocPreview.value
        store.commit('ui/COLLAPSE_DOC_PREVIEW', !minifyDocPreview.value)
      }

      const loadCompanyInformations = async (companyId: string | null) => {
        store.dispatch(`organizations/GET_SINGLE_COMPANY`, {
          companyId,
          onSuccess: () => {
            store.dispatch(`organizations/INIT_ORGANIZATION`, {
              companyId: route.params?.id?.toString() ?? companyId
            })
          },
          onError: (error: any) => {
            Sentry.captureException(error)
          }
        })
      }

      const getCompanies = async (search = '') => {
        await store.dispatch(`organizations/GET_LOADER_COMPANIES`, {
          filter: { search, limit: 20 }
        })
      }

      const loadCompanyState = async (companyId: string) => {
        if (!companyId) return
      }

      const user = computed<User>(() => store.getters['auth/profile'])

      const companies = computed<CompanyModel[]>(
        () => store.getters['organizations/loaderCompanies']
      )

      const minifyDocPreview = ref(false)

      const currentCompany = computed(
        () => store.getters['organizations/contextCompany']
      )

      onErrorCaptured((err) => {
        console.log(err)
      })

      const handleUserSearch = (event: any) => {
        getCompanies(event.target.value)
      }

      const navigateToDocuments = (e: Event) => {
        e.preventDefault()

        if (currentCompany.value)
          router.push({
            name: 'companyDocuments',
            params: {
              id:
                currentCompany.value.id ||
                getFormStorage(storageKeys.currentCompany)
            }
          })
        else router.push({ name: 'companyOverview' })
      }

      const navigateToContacts = (e: Event) => {
        e.preventDefault()

        if (currentCompany.value)
          router.push({
            name: 'company.contact',
            params: {
              id: currentCompany.value.id
            }
          })
        else router.push({ name: 'companyOverview' })
      }

      const setCurrentCompany = async (company: CompanyModel) => {
        store.commit(`organizations/SET_CONTEXT_COMPANY`, company)

        store.dispatch(`organizations/GET_SINGLE_COMPANY`, {
          companyId: company.id,
          onSuccess: (cmp: CompanyModel) => {
            companySearch.value = ''
            displaySelector.value = false
            location.reload()
          },
          onError: (error: any) => {
            Sentry.captureException(error)
          }
        })
      }

      const closePreView = async () => {
        store.commit(`organizations/SET_COMPANY_SINGLE_DOCUMENT`, null)
        store.commit('ui/DISPLAY_PREVIEW', false)
      }

      const appEnv = ref(envName)
      const canDisplayEnv = appEnv.value !== 'production'

      watch(currentCompany, (newCmp) => {
        loadCompanyState(newCmp?.id || '')
      })

      watch(
        () => documentToShow.value,
        (val) => {
          minifyDocPreview.value = !val
        }
      )

      const autoCloseDocumentPreview = () => {
        if (route.meta?.docPreview !== 'integred') {
          // documentToShow.value = null
        }
      }
      const buildInitials = (value: string) => {
        return createInitials(value)
      }
      const companySelectorOpen = () => {
        displaySelector.value = !displaySelector.value
        setTimeout(() => {
          selectorInput.value?.focus()
        }, 500)
      }

      const closeCompanySelector = () => {
        if (displaySelector.value) {
          displaySelector.value = false
        }
      }

      onMounted(async () => {
        getCompanies()

        setTimeout(() => {
          const cmpId: string | null =
            route?.params?.id?.toString() ||
            getFormStorage(storageKeys.currentCompany) ||
            null
          loadCompanyInformations(cmpId)
        }, 1000)

        await store.dispatch('config/init')
        await store.dispatch('toolbox/getDocumentCategories')
        await store.dispatch('toolbox/GET_STOCK_CATEGORIES')
        await store.dispatch('toolbox/GET_OPTION_CATEGORIES')
        await store.dispatch('toolbox/GET_CONTACT_GROUPS')
      })

      return {
        appEnv,
        isModalOpen,
        displayStyle,
        canDisplayEnv,
        companies,
        user,
        type,
        isLoading,
        documentToShow,
        portfolioLink,
        isPreviewDisplayed,
        currentCompany,
        isPortfolioRoute,
        displaySelector,
        companySearch,
        selectorInput,
        minifyDocPreview,
        handleClick,
        truncateText: truncText,
        closePreView,
        navigateToDocuments,
        navigateToContacts,
        setCurrentCompany,
        closeCompanySelector,
        buildInitials,
        handleUserSearch,
        autoCloseDocumentPreview,
        companySelectorOpen
      }
    }
  })
</script>

<style lang="scss">
@import '@/scss/logged.scss';
</style>
