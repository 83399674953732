
  import { defineComponent, ref, computed, watch, onMounted } from 'vue'
  import { Input } from '@up.law/uplaw-ui'
  import { Form, Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import {
    CompanyModel,
    ContactStatus,
    FamilySituationEnum,
    GenderEnum,
    IndividualContact,
    MaritalAgreementEnum
  } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { CONTACTTYPE, IndividualContactForm } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { individualSchema } from '@/utils/schema'
  import ProfileAvatarForm from './ProfileAvatarForm.vue'
  import AddressInput from '@/components/Address/index.vue'
  import { AddressData } from '@/types/config'
  import { useRoute } from 'vue-router'
  import DropdownContacts from '../../DropDowns/DropdownContacts.vue'
  export default defineComponent({
    components: {
      Form,
      Field,
      Multiselect,
      Input,
      DropdownContacts,
      UplDatePicker,
      FormActions,
      ProfileAvatarForm,
      AddressInput
    },
    props: {
      contact: {
        type: Object as () => IndividualContact | null | undefined,
        required: false,
        default: () => null
      },
      topAlignClass: { type: String, default: '' }
    },
    emits: ['submitForm', 'fileChanged', 'onCancel'],
    setup(props, { emit }) {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()
      const newValues = (): IndividualContactForm => ({
        id: props.contact?.id,
        lastName: props.contact?.lastName || '',
        firstName: props.contact?.firstName || '',
        gender: props.contact?.gender || GenderEnum.mr,
        type: CONTACTTYPE.INDIVIDUAL,
        nationality: props.contact?.nationality || null,
        country: props.contact?.country || null,
        contactGroupsId: props.contact?.contactGroups.map((x) => x.id) || [],
        contactStatusId: null,
        birthDate: props.contact?.birthDate
          ? props.contact?.birthDate.split('T')[0]
          : '' || null,
        birthPlace: props.contact?.birthPlace || null,
        email: props.contact?.email || null,
        phoneNumber: props.contact?.phoneNumber || null,
        address: props.contact?.address || null,
        postalcode: props.contact?.postcode || null,
        website: props.contact?.website || null,
        city: props.contact?.city || null,
        representativeContactId: props.contact?.representativeContact?.id ?? '',
        familyBranch1: props.contact?.familyBranch1,
        familyBranch2: props.contact?.familyBranch2,
        familySituation:
          props.contact?.familySituation || FamilySituationEnum.single,
        maritalAgreement:
          props.contact?.maritalAgreement ||
          MaritalAgreementEnum.separationAsToProperty,
        internalId1: props.contact?.internalId1 || null,
        internalId2: props.contact?.internalId2 || null,
        mappingId: props.contact?.mappingId || null,
        parentsIds:
          (props.contact?.parents ?? [])?.map((x) => x.id ?? '') || [],
        iban: props.contact?.iban || null,
        bic: props.contact?.bic || null
      })

      const modalForm = computed(() => store.getters['organizations/form'])

      const newContact = ref<IndividualContactForm>(newValues())
      const genders = ref(
        Object.values(GenderEnum).map((x) => ({
          name: t(`gender.${x}`),
          id: x
        }))
      )
      const maritalStatus = ref(
        Object.values(FamilySituationEnum)
          .map((x) => ({
            name: t(`familyStatus.${x}`),
            id: x
          }))
          .filter((y) => y.id !== FamilySituationEnum.other)
      )
      maritalStatus.value.push({
        name: t(`familyStatus.${FamilySituationEnum.other}`),
        id: FamilySituationEnum.other
      })

      const maritalAgreements = ref(
        Object.values(MaritalAgreementEnum)
          .map((x) => ({
            name: t(`maritalAgreementEnum.${x}`),
            id: x
          }))
          .filter((y) => y.id !== MaritalAgreementEnum.separationAsToProperty)
      )
      maritalAgreements.value.push({
        name: t(
          `maritalAgreementEnum.${MaritalAgreementEnum.separationAsToProperty}`
        ),
        id: MaritalAgreementEnum.separationAsToProperty
      })

      const allContacts = computed<any[]>(
        () => store.getters['organizations/contacts']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const contacts = computed(() =>
        allContacts.value.filter((x) => x.id !== route.params?.contactId)
      )

      const handleSearch = (e: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            type: CONTACTTYPE.INDIVIDUAL,
            name: e
          }
        })
      }

      const contactStatus = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )

      const groups = computed<ContactStatus[]>(
        () => store.getters['organizations/groups']
      )
      const personnalContactGroups = computed(() =>
        contactStatus.value.filter((x) => x.type === CONTACTTYPE.INDIVIDUAL)
      )
      const schema = computed(() => individualSchema)

      const handleForm = async (values: IndividualContactForm) => {
        const form = {
          ...values,
          contactGroupsId: newContact.value.contactGroupsId,
          email: values.email === '' ? null : values.email,
          type: CONTACTTYPE.INDIVIDUAL,
          parentsIds: newContact.value.parentsIds
        }
        emit('submitForm', form)
      }

      const fileChanged = (file: File) => {
        emit('fileChanged', file)
      }

      const handleReset = () => {
        newContact.value = newValues()
        emit('onCancel')
      }

      const onAddressInput = ($event: AddressData) => {
        newContact.value.postalcode = $event.postalCode
        newContact.value.city = $event.city
        newContact.value.country = $event.country
        newContact.value.address = $event.address
      }

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newContact.value = newValues()
          return
        }
      })

      watch(props, (newProps) => {
        if (newProps.contact) {
          newContact.value = {
            ...newProps.contact,
            gender: newProps.contact.gender,
            contactGroupsId: (newProps.contact.contactGroups || []).map(
              (x) => x.id
            ),
            postalcode: newProps.contact.postcode || '',
            contactStatusId: newProps.contact.status?.id || '',
            internalId1: newProps.contact.internalId1 || '',
            internalId2: newProps.contact.internalId2 || '',
            mappingId: newProps.contact.mappingId || '',
            parentsIds: (newProps.contact.parents || []).map((x) => x.id),
            iban: newProps.contact.iban || '',
            bic: newProps.contact.bic || ''
          }
        } else {
          newContact.value = newValues()
        }
      })

      return {
        newContact,
        personnalContactGroups,
        genders,
        groups,
        maritalStatus,
        schema,
        handleForm,
        handleReset,
        handleSearch,
        onAddressInput,
        fileChanged,
        contacts,
        maritalAgreements
      }
    }
  })
