<template>
  <div>
    <label
      v-if="hasLabel"
      class="capitalize text-xs font-semibold text-secondary8 text-left"
      >{{ data.label }}</label
    >
    <Field
      v-slot="{ field }"
      v-model="category"
      :name="fieldName"
      :placeholder="data.hint"
    >
      <Multiselect
        v-model="category"
        v-bind="field"
        :name="fieldName"
        :value="category"
        class="w-full min-h-full"
        :show-labels="true"
        :mode="'single'"
        group-label="name"
        :options="categories"
        group-options-field="children"
        :placeholder="$t(`global.placeholder.stockCategories`)"
        :groups="true"
        label="name"
        :disabled="disabled"
        :open-direction="openDirection"
        @change="updateValue"
      >
        <template #option="{ option }">
          <span v-if="option?.id != 0" class="w-full text-sm font-normal"
            >{{ option.name }}
          </span>
        </template>

        <template #tag="{ option, handleTagRemove }">
          <div class="">
            <Tag
              :text="option.name.toUpperCase()"
              :with-initials="false"
              :value="option.id"
              @on-remove.prevent.stop="handleTagRemove(option, $event)"
            />
          </div>
        </template>

        <template #noResults>
          <span>{{ $t('global.placeholder.noResult') }}</span>
        </template>
      </Multiselect>
    </Field>
    <slot :name="data.name" :value="category" :type="type" />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { Tag } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { OptionStockCategory, StockCategory } from '@/services/api/models'

  export default defineComponent({
    name: 'DropdownStockCategories',
    components: { Multiselect, Field, Tag },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        default: '',
        type: String
      },
      mode: {
        default: 'single',
        type: String
      },
      openDirection: {
        type: String,
        default: 'top'
      },
      callback: {
        type: Function,
        default: () => ({})
      },
      optionPerPrice: { type: Boolean, default: true },
      validationState: { type: String, default: '' },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      },
      stocksData: {
        type: Object,
        default: () => ({
          value: [],
          contactId: '',
          index: -1
        })
      }
    },
    emits: ['onUpdated'],

    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()
      const { data } = toRefs(props)
      const type = ref<string | null>(null)
      const fieldName =
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name

      const stockCategories = computed<StockCategory[]>(
        () => store.getters['toolbox/stockCategories']
      )
      const optionCategories = computed<OptionStockCategory[]>(
        () => store.getters['toolbox/optionCategories']
      )
      const dataType = computed(() => {
        if (category.value === 'composition') return 'composition'
        else if (
          stockCategories.value.map((x) => x.id).includes(category.value)
        )
          return 'stock'
        else if (
          optionCategories.value.map((x) => x.id).includes(category.value)
        )
          return 'option'
        return null
      })
      const category = ref(props.value)
      const categories = computed(() => [
        {
          id: '1',
          name: t('companyStockType.SIMPLE'),
          label: t('companyStockType.SIMPLE'),
          value: 'stocks',
          children: stockCategories.value
        },
        {
          id: '2',
          name: t('companyStockType.COMPOSED'),
          label: t('companyStockType.COMPOSED'),
          value: 'optionStock',
          children: optionCategories.value
        },
        {
          id: '3',
          name: t('companyStockType.COMBINED'),
          label: t('companyStockType.COMBINED'),
          value: 'compositionStocks',
          children: [{ id: 'composition', name: 'Composition' }]
        }
      ])

      const updateValue = (selected: string) => {
        emit('onUpdated', {
          ...data.value,
          dataType: dataType.value,
          value: selected
        })
      }

      watch(
        () => category.value,
        (val) => {
          const cat: any = [
            ...optionCategories.value,
            ...stockCategories.value
          ].find((x) => x.id == val)

          type.value = optionCategories.value.map((x) => x.id).includes(val)
            ? 'option'
            : stockCategories.value.map((x) => x.id).includes(val)
            ? 'stock'
            : 'composition'
        }
      )

      onMounted(() => {
        type.value = dataType.value
      })

      return { categories, type, category, fieldName, updateValue }
    }
  })
</script>
