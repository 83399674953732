<template>
  <div class="px-4 py-5 w-full">
    <div class="flex justify-between items-center w-full items-start text-xl">
      <h1 class="text-left text-secondary9 text-md">
        {{ $t('account.accountInformations.thirdPartyIntegration') }}
      </h1>

      <div>
        <Button
          :label="$t('account.accountInformations.new_integration')"
          @click="handleDisplay"
        />
      </div>
    </div>

    <div>
      <div
        v-if="availableApps.length"
        class="
          grid
          xl:grid-cols-4
          md:grid-cols-3
          sm:grid-cols-1
          xs:grid-cols-1
          gap-4
        "
      >
        <div
          v-for="(appli, index) in availableApps"
          :key="index"
          class="rounded bg-white p-3 relative overflow-hidden appli-container"
        >
          <div
            class="
              w-full
              h-full
              transition
              bg-blackp
              absolute
              top-0
              flex flex-col
              items-center
              justify-center
              left-0
              appli-actions
            "
          >
            <div class="flex flex-col items-center justify-center h-full">
              <div class="mb-2">
                <Button
                  :label="$t('global.actions.details')"
                  variant="custom"
                  class="bg-primary3 bg-opacity-25 text-primary3"
                  @click="
                    $router.push({
                      name: 'integratedApp',
                      params: { appId: appli.id }
                    })
                  "
                />
              </div>

              <div class="mb-2">
                <Button
                  :label="$t('global.actions.edit')"
                  variant="custom"
                  class="bg-secondary4 bg-opacity-25 text-primary3"
                  @click="startEdition(appli)"
                />
              </div>

              <el-popconfirm
                :confirm-button-text="$t('confirm.yes')"
                :cancel-button-text="$t('confirm.no')"
                :title="$t('global.confirmDelete')"
                @confirm="deleteApp(appli.id)"
              >
                <template #reference>
                  <Button
                    :label="$t('global.actions.delete')"
                    variant="custom"
                    class="bg-error bg-opacity-50 text-white"
                  />
                </template>
              </el-popconfirm>
            </div>
          </div>
          <div class="flex">
            <h3 class="text-md text-secondary9 font-medium">
              {{ appli.name }}
            </h3>
          </div>

          <span
            style="width: fit-content"
            class="
              flex flex-none
              items-center
              mt-3
              bg-success bg-opacity-25
              text-success
              px-2
              py-1
              rounded-full
              w-auto
            "
          >
            <span class="w-3 h-3 rounded-full bg-success"></span>
            <span class="ml-2 text-sm">{{
              $t(`account.applications.status.${appli.status}`)
            }}</span>
          </span>

          <div class="mt-3">
            <h3
              v-if="appli?.owner?.lastName"
              class="text-sm text-secondary7 text-left"
            >
              {{ $t('account.applications.app_created_short') }} :
              {{ appli?.owner?.lastName + ' ' + appli?.owner?.firstName }}
            </h3>
            <p v-else class="text-sm text-secondary7 text-left">-</p>
          </div>

          <div class="mt-3">
            <h3 class="text-sm text-secondary9 text-left">
              {{ appli?.companies?.length ?? 0 }}
              {{
                $t('global.company', { count: appli?.companies?.length ?? 0 })
              }}
            </h3>
          </div>
        </div>
      </div>
      <div
        v-else-if="availableApps.length == 0 && !isLoading"
        class="w-full h-96 flex items-center justify-center"
      >
        <div
          style="width: fit-content"
          class="bg-white rounded-full py-3 px-4 w-fit"
        >
          <h3 class="text-secondary8 text-sm">
            {{ $t('account.accountInformations.no_thirdPartyIntegration') }}
          </h3>
        </div>
      </div>
    </div>

    <transition name="modal">
      <Modal v-if="showModal" size="w-1/5 md:w-1/3" @close="handleDisplay">
        <template #header>
          <h3 class="text-md font-semibold">
            {{ $t('account.accountInformations.new_integration') }}
          </h3>
        </template>

        <template #body>
          <div class>
            <div class="w-full mr-2">
              <label
                class="text-xs text-left text-secondary8 font-semibold mt-0"
                for="siret"
                >{{ $t('contactBook.fields.name') }}</label
              >
              <Field v-slot="{ field }" v-model="newApp.name" name="name">
                <Input
                  v-model="newApp.name"
                  v-bind="field"
                  class
                  :disabled="false"
                  type="text"
                  name="Nom"
                  :placeholder="$t('contactBook.placeholder.name')"
                />
              </Field>
            </div>

            <div class="w-full">
              <label
                class="text-xs text-left text-secondary8 font-semibold mt-0"
                for="companyIds"
                >{{ $t('global.company', { count: 2 }) }}</label
              >
              <Field
                v-slot="{ field }"
                v-model="newApp.companyIds"
                name="companyIds"
              >
                <DropdownCompany
                  mode="multiple"
                  v-bind="field"
                  :value="newApp.companyIds"
                  @on-updated="($event) => (newApp.companyIds = $event)"
                />
              </Field>
            </div>

            <div class="w-full mt-3">
              <label
                class="text-xs text-left text-secondary8 font-semibold mt-0"
                for="description"
                >{{ $t('groupBook.form.description') }}</label
              >
              <Field
                v-slot="{ field }"
                v-model="newApp.description"
                name="description"
              >
                <div class="group-color-block">
                  <textarea
                    v-bind="field"
                    placeholder="Description"
                    class="
                      resize-none
                      focus:border-primary3
                      rounded
                      border-secondary1 border
                      w-full
                      p-3
                    "
                    name="description"
                  ></textarea>
                </div>
              </Field>
            </div>
          </div>
        </template>
        <template #footer>
          <div class="mt-5 flex justify-end">
            <Button
              :label="$t('global.actions.save')"
              variant="primary"
              @click="createApplication"
            /></div
        ></template>
      </Modal>
    </transition>

    <transition name="modal">
      <Modal
        v-if="showEdition"
        size="w-1/5 md:w-1/3"
        @close="handleEditionDisplay"
      >
        <template #header>
          <h3 class="text-md font-semibold">
            {{ $t('account.accountInformations.integration_update') }}
          </h3>
        </template>

        <template #body>
          <div class>
            <div class="w-full mr-2">
              <label
                class="text-xs text-left text-secondary8 font-semibold mt-0"
                for="siret"
                >{{ $t('contactBook.fields.name') }}</label
              >
              <Field v-slot="{ field }" v-model="selectedApp.name" name="name">
                <Input
                  v-model="selectedApp.name"
                  v-bind="field"
                  class
                  :disabled="false"
                  type="text"
                  name="Nom"
                  :placeholder="$t('contactBook.placeholder.name')"
                />
              </Field>
            </div>

            <div class="w-full">
              <label
                class="text-xs text-left text-secondary8 font-semibold mt-0"
                for="companyIds"
                >{{ $t('global.company', { count: 2 }) }}</label
              >
              <Field
                v-slot="{ field }"
                v-model="selectedApp.companyIds"
                name="companyIds"
              >
                <DropdownCompany
                  v-model="selectedApp.companyIds"
                  mode="multiple"
                  v-bind="field"
                  :value="selectedApp.companyIds"
                  @on-updated="($event) => (selectedApp.companyIds = $event)"
                />
              </Field>
            </div>

            <div class="w-full mt-3">
              <label
                class="text-xs text-left text-secondary8 font-semibold mt-0"
                for="description"
                >{{ $t('groupBook.form.description') }}</label
              >
              <Field
                v-slot="{ field }"
                v-model="selectedApp.description"
                name="description"
              >
                <div class="group-color-block">
                  <textarea
                    v-bind="field"
                    placeholder="Description"
                    class="
                      resize-none
                      focus:border-primary3
                      rounded
                      border-secondary1 border
                      w-full
                      p-3
                    "
                    name="description"
                  ></textarea>
                </div>
              </Field>
            </div>
          </div>
        </template>
        <template #footer
          ><div class="mt-5 flex justify-end">
            <Button
              :label="$t('global.actions.save')"
              variant="primary"
              @click="editApplication"
            />
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref } from 'vue'
  import { Button, Input } from '@up.law/uplaw-ui'
  import Modal from '@/components/Modal/Modal.vue'
  import { Field } from 'vee-validate'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'
  import { useStore } from 'vuex'
  import { ThirdPartyApplication } from '@/services/api/models'
  import { ElPopconfirm } from 'element-plus'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    components: {
      DropdownCompany,
      Button,
      Modal,
      Input,
      Field,
      ElPopconfirm
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const showModal = ref(false)
      const newApp = ref({ name: '', companyIds: [], description: '' })
      const availableApps = ref<ThirdPartyApplication[]>([])

      const isLoading = ref(true)
      const showEdition = ref(false)
      const handleDisplay = () => {
        showModal.value = !showModal.value
      }

      const selectedApp = ref<any>(null)

      const handleEditionDisplay = () => {
        showEdition.value = !showEdition.value
      }

      const startEdition = (app: any) => {
        selectedApp.value = {
          ...app,
          companyIds: app.companies.map((x: any) => x.id)
        }

        delete selectedApp.value.companies

        console.log(selectedApp.value)

        handleEditionDisplay()
      }
      const createApplication = () => {
        store.dispatch('auth/CREATE_APPLICATION', {
          data: newApp.value,
          onSuccess: () => {
            handleDisplay()
            getApps()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_creation')
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t('account.applications.error_creation')
            })
          }
        })
      }

      const editApplication = () => {
        store.dispatch('auth/EDIT_APPLICATION', {
          applicationId: selectedApp.value.id,
          data: selectedApp.value,
          onSuccess: () => {
            handleEditionDisplay()
            selectedApp.value = null
            getApps()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_edition')
            })
          },
          onError: (err: any) => {
            let message = 'error_edition'
            if (err.statusCode == 403) message = 'error_403_app_edition'
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t(`account.applications.${message}`)
            })
          }
        })
      }

      const getApps = () => {
        store.dispatch('auth/GET_APPLICATIONS', {
          filter: { companyIds: [] },
          onSuccess: (data: ThirdPartyApplication[]) => {
            isLoading.value = false

            availableApps.value = [...data]
          },
          onError: () => {
            //
          }
        })
      }

      const deleteApp = (appId: string) => {
        store.dispatch('auth/DELETE_APPLICATION', {
          applicationId: appId,
          onSuccess: () => {
            getApps()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_deletion')
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t('account.applications.error_deletion')
            })
          }
        })
      }

      onMounted(() => {
        getApps()
      })

      return {
        showModal,
        newApp,
        availableApps,
        isLoading,
        showEdition,
        selectedApp,
        startEdition,
        createApplication,
        handleDisplay,
        deleteApp,
        editApplication,
        handleEditionDisplay
      }
    }
  })
</script>

<style lang="scss">
  .appli-container {
    .appli-actions {
      display: none;
      transition-property: backdrop-filter;
      transition-duration: 1s;
      transition-delay: 1s;
    }

    &:hover {
      .appli-actions {
        display: flex;
        backdrop-filter: blur(10px);
        background: rgba($color: #162e65, $alpha: 0.3);
      }
    }
  }
</style>
