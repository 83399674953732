<template>
  <div :class="`${classes} h-full`">
    <div class="flex items-center">
      <Field
        v-slot="{ field }"
        :name="data.name"
        :placeholder="data.hint"
        :value="formValue"
      >
        <label
          class="
            capitalize
            text-xs
            font-semibold
            text-secondary8 text-left
            mr-2
            mb-0
          "
          >{{ data.label }}</label
        >
        <Switch
          v-slot="{ checked }"
          v-model="formValue"
          :name="data.name"
          v-bind="field"
          :class="formValue ? 'bg-secondary9' : 'bg-primary7'"
          class="relative inline-flex items-center h-6 rounded-full w-11"
        >
          <span
            :class="checked ? 'translate-x-6' : 'translate-x-1'"
            class="
              inline-block
              w-4
              h-4
              transform
              bg-white
              rounded-full
              transition
            "
          />
        </Switch>
      </Field>
    </div>
    <div class="mt-4">
      <slot :name="data.name" :value="formValue" />
    </div>
  </div>
</template>

<script lang="ts">
  //TODO: Display with flex? column or row? Both?
  import { Field } from 'vee-validate'
  import { Switch } from '@headlessui/vue'
  interface DataInputBool {
    name: string
    type: string
    label: string
    id: ''
  }

  import { defineComponent, ref, watch, toRefs } from 'vue'
  export default defineComponent({
    name: 'BoolGeneric',
    components: { Field, Switch },
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputBool,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: ''
        })
      },
      value: {
        type: Boolean,
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: ''
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value } = toRefs(props)
      const formValue = ref(value.value ?? false)
      watch(formValue, () => {
        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      })
      return { formValue }
    }
  })
</script>
