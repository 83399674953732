<template>
  <div>
    <label
      v-if="hasLabel"
      class="capitalize text-xs font-semibold text-secondary8 text-left"
      >{{ data.label }}</label
    >
    <Field
      v-slot="{ field }"
      v-model="vesting"
      :name="fieldName"
      :placeholder="data.hint"
    >
      <Multiselect
        v-model="vesting"
        :value="vesting"
        v-bind="field"
        :show-labels="true"
        mode="single"
        :options="vestings"
        :placeholder="placeholder || $t('events.elements.selectVesting')"
        :groups="false"
        :filterResults="false"
        class="w-full"
        :open-direction="openDirection"
        @search-change="handleSearch"
        @change="updateValue"
      >
        <template #noOptions>
          <span v-if="false"> {{ $t('events.elements.noVesting') }} </span>
          <span v-else>{{ $t('events.elements.search') }}</span>
        </template>

        <template #noResults>
          <span>{{ $t('global.placeholder.noResult') }}</span>
        </template>
      </Multiselect>
    </Field>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    computed,
    ref,
    toRefs,
    onBeforeMount,
    watch
  } from 'vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { CompanyVesting } from '@/services/api/models'
  import { Field } from 'vee-validate'
  export default defineComponent({
    name: 'DropdownVesting',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      allData: {
        default: () => ({}),
        type: Object
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      placeholder: {
        type: String,
        default: null
      },
      value: {
        default: 'single',
        type: [String, Array]
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      validationState: { type: String, default: '' },
      openDirection: {
        type: String,
        default: 'bottom'
      },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const { data } = toRefs(props)
      // allData will be useful for filter if delegation is selected for example
      const fieldName =
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      const formValue = ref({})
      const searchText = ref('')
      const vesting = ref<any>(props.value)
      const currentCompany = computed(
        () => store.getters['organizations/contextCompany']
      )

      const vestings = computed<CompanyVesting[]>(
        () => store.getters['organizations/vestings']
      )

      const updateValue = (value: any) => {
        vesting.value = value
        emit('onUpdated', {
          ...data.value,
          value
        })
      }

      const getVestings = (name?: string) => {
        const filter: any = {}
        if (name) filter.name = name
        store.dispatch('organizations/GET_COMPANY_VESTINGS', {
          companyId: currentCompany.value.id,
          filter
        })
      }

      const handleSearch = (search: string) => {
        getVestings(search)
      }

      watch(
        () => props.value,
        (val) => {
          vesting.value = val
        }
      )

      onBeforeMount(async () => {
        if (!currentCompany.value?.id) return
        getVestings()
      })

      return {
        formValue,
        vesting,
        handleSearch,
        searchText,
        vestings,
        fieldName,
        updateValue
      }
    }
  })
</script>
