<template>
  <div class="transactions flex flex-col items-start">
    <div class="flex w-full my-3 justify-between">
      <div class="w-2/3 lg:w-3/5 flex">
        <div class="w-2/5 md:w-2/5 h-10 mr-2">
          <UplDatePicker
            :placeholder="$t('global.datePicker.rangePlaceholder')"
            range
            v-model="dateRange"
            :enable-time-picker="false"
            @cleared="dateCleared"
          />
        </div>

        <div class="w-2/5 min-h-10">
          <Field v-slot="{ field }" v-model="selectedStocks" name="stocks">
            <StockDropdown
              :has-label="false"
              :model-value="selectedStocks"
              open-direction="bottom"
              mode="multiple"
              :placeholder="$t('global.placeholder.stocks')"
              :can-add-new="false"
              :elements="[]"
              v-bind="field"
              @onUpdated="updateStockFilter"
            />
          </Field>
        </div>

        <div class="w-2/5 min-h-10 ml-2">
          <Field
            v-slot="{ field }"
            v-model="ownershipType"
            name="ownershipType"
          >
            <DropdownGeneric
              :data="{
                hint: $t('global.placeholder.ownershipType'),
                name: 'ownershipType'
              }"
              :has-label="false"
              :model-value="ownershipType"
              open-direction="bottom"
              mode="single"
              :filterResults="false"
              :options="ownershipOptions"
              v-bind="field"
              @on-updated="updateOwnershipFilter"
            />
          </Field>
        </div>
      </div>
      <div v-if="!isDownloading" class="text-xl">
        <Button
          variant="custom"
          class="
            bg-white
            py-2
            h-10
            rounded-forced
            border
            flex
            justify-center
            items-center
            border-secondary5
            text-center text-lg
          "
          @click="downloadPdf"
          :label="$t('global.actions.associateDownload')"
        >
          <template>
            <i class="bi bi-download" />
          </template>
        </Button>
      </div>
      <el-tooltip
        v-else-if="isDownloading"
        class="box-item"
        effect="dark"
        :content="$t('tooltips.downloading')"
        placement="top"
      >
        <div
          class="
            border
            cursor-not-allowed
            rounded-3
            bg-white
            border-secondary6
            px-3
            flex
            items-center
          "
        >
          <i class="bi bi-arrow-clockwise text-xl text-secondary8 rotating"></i>
        </div>
      </el-tooltip>
    </div>
    <div
      v-if="isLoading"
      class="flex w-full h-full justify-center items-center relative"
    >
      <Loader />
    </div>
    <div v-else class="mt-2 w-full">
      <data-table
        v-if="transactions.length > 0"
        :has-checkbox="false"
        :columns="columns"
        :rows="transactions"
        :pagination="pagination"
        @onPageChange="onPageChange"
      >
        <template #id="{ row }">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="row.id"
            placement="right"
          >
            <span class="text-left">{{
              row.id.substr(row.id.length - 8)
            }}</span>
          </el-tooltip>
        </template>
        <template #date="{ row }">
          <p class="text-left">{{ moment.utc(row.date).format('L') }}</p>
        </template>
        <template #from="{ row }">
          <p v-if="row.transactionType === 'DEBIT'" class="text-left">
            {{ $t('datatable.column.sender') }}
          </p>
          <p v-else class="text-left">{{ $t('datatable.column.receiver') }}</p>
        </template>
        <template #to="{ row }">
          <p
            v-if="
              row.associateAccount &&
              row.associateAccount.contact &&
              row.transactionType === 'CREDIT'
            "
            class="text-left"
          >
            {{ row.associateAccount.contact.name }}
          </p>
          <p v-else class="text-left">-</p>
        </template>
        <template #stock="{ row }">
          <Tag
            v-if="row.stockAlias"
            class="text-left"
            :value="row.stockAlias.id"
            :text="row.stockAlias.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
            :color="rgbToHex(row.stockAlias?.colorCode)"
          />
          <Tag
            v-else-if="row.optionAlias"
            class="text-left"
            :value="row.optionAlias.id"
            :text="row.optionAlias.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
            :color="rgbToHex(row.optionAlias?.colorCode)"
          />
          <Tag
            v-else-if="row.compositionAlias"
            class="text-left"
            :value="row.compositionAlias.id"
            :text="row.compositionAlias.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
            :color="rgbToHex(row.compositionAlias?.colorCode)"
          />
          <p v-else class="text-left">-</p>
        </template>
        <template #quantity="{ row }">
          <p class="text-left">
            {{ formatNumber(row.quantity) }}
          </p>
        </template>
        <template #eventName="{ row }">
          <u
            v-if="getEventName(row)"
            :class="`text-left ${
              $can.any('read-events')
                ? 'cursor-pointer hover:text-primary4'
                : ''
            }`"
            @click="goToEvent(getEventId(row))"
          >
            {{ $t(`events.labels.${getEventName(row)}`) }}
          </u>
          <p v-else class="text-left">-</p>
        </template>
        <template #price="{ row }">
          <p v-if="getUnitPrice(row)" class="text-left">
            {{ formatNumber(getUnitPrice(row)) }} {{ currency }}
          </p>
          <p v-else class="text-left">-</p>
        </template>
        <template #actions="{ row }">
          <Button
            v-if="getEventId(row)"
            variant="custom"
            class="hover:bg-primary2 rounded p-2"
            @click="goToEvent(getEventId(row))"
          >
            <i class="bi bi-lightning text-xl"></i>
          </Button>
          <p v-else class="text-left">-</p>
        </template>
        <template #ownership="{ row }">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="
              $t(
                `CoOwnershipType.${
                  row.ownershipType ?? CoOwnershipType.fullOwnership
                }`
              )
            "
            placement="top"
          >
            <u class="text-left">{{
              $t(
                `CoOwnershipTypeSlugs.${
                  row.ownershipType ?? CoOwnershipType.fullOwnership
                }`
              )
            }}</u>
          </el-tooltip>
        </template>
      </data-table>
      <div v-else class="flex justify-center items-center w-full mt-4">
        <EmptyState
          class="d-block mx-auto datatable"
          :title="$t('global.noData')"
          :subtitle="$t('global.noDataForUser')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { userTransactionsColumns } from '@/utils/data'
  import moment from 'moment'
  import EmptyState from '@/components/EmptyState/index.vue'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { Field } from 'vee-validate'
  import StockDropdown from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import { STOCKTYPE, STOCKTYPESALIAS } from '@/types'
  import { formatNumber, rgbToHex } from '@/utils'
  import { Tag, Button } from '@up.law/uplaw-ui'
  import Loader from '@/components/Loader/index.vue'
  import { ElTooltip } from 'element-plus'
  import { useI18n } from 'vue-i18n'
  import { MAINACTIONS } from '@/types'
  import { useAcl } from 'vue-simple-acl/src'
  import { CoOwnershipType } from '@/services/api/models'
  import DropdownGeneric from '@/components/Forms/DropDowns/DropdownGeneric.vue'
  export default defineComponent({
    components: {
      DataTable,
      EmptyState,
      UplDatePicker,
      StockDropdown,
      Field,
      Loader,
      Tag,
      Button,
      ElTooltip,
      DropdownGeneric
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()
      const selectedStocks = ref([])
      const dateRange = ref([])
      const filter = ref<any>({})
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const ownershipType = ref<string>('')
      const isDownloading = ref(false)
      const ownershipOptions = computed(() => [
        ...Object.values(CoOwnershipType).map((x) => ({
          id: x,
          name: t(`CoOwnershipType.${x}`)
        }))
      ])

      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )
      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const transactions = computed(
        () => store.getters['organizations/transactions']
      )
      const isLoading = ref(false)

      const currency = computed(() => store.getters['organizations/currency'])
      const pagination = computed(
        () => store.getters['organizations/transactionsPagination']
      )

      const currentContact = computed(
        () => store.getters['organizations/contactDetails']
      )

      const columns = computed(() => userTransactionsColumns)

      const acl = useAcl()

      const getStocks = () => {
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: route.params.id
        })
      }
      const getTransactions = (contactId = route.params.contactId) => {
        if (!filter.value.ownershipType) delete filter.value.ownershipType
        store.dispatch('organizations/GET_COMPANY_TRANSACTIONS', {
          companyId: route.params.id,
          filter: { contactsId: [contactId], ...filter.value },
          onSuccess: () => {
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const updateStockFilter = ({ value }: any) => {
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        if (!value) {
          return
        }
        console.log(value)

        value.forEach((v: string) => {
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === v.split('@_')[0])

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(v.split('@_')[0])
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })
        selectedStocks.value = value
        getTransactions()
      }

      const getUnitPrice = (row: any) => {
        return row.stockIssuancePart
          ? row.stockIssuancePart?.unitPrice ||
              row.stockIssuancePart.totalPrice / row.stockIssuancePart.quantity
          : row.optionIssuancePart
          ? row.optionIssuancePart.unitPrice ||
            row.optionIssuancePart.totalPrice / row.optionIssuancePart.quantity
          : row.compositionIssuancePart?.unitPrice ||
            row.compositionIssuancePart?.totalPrice /
              row.compositionIssuancePart?.quantity
      }

      const getEventName = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }
        if (row.compositionIssuancePart) {
          exercise = row.compositionIssuancePart.compositionIssuance
        }

        if (row.transferPart) {
          exercise = row.transferPart.transfer
        }

        if (row.conversionPart) {
          exercise = row.conversionPart.conversion
        }

        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }
        if (row.nominalValue) {
          exercise = row.nominalValue
        }

        if (exercise?.event) return exercise.event.type
        else return row.eventType
      }

      watch(dateRange, (newDateRange) => {
        store.commit('organizations/SET_TRANSACTIONS_PAGE', 1)

        const dateFilter = {
          startDate: newDateRange
            ? moment(newDateRange[0]).startOf('day').toISOString()
            : null,
          endDate: newDateRange
            ? moment(newDateRange[1]).endOf('day').toISOString()
            : null
        }

        filter.value = { ...filter.value, ...dateFilter }
        getTransactions()
      })
      const downloadPdf = () => {
        isDownloading.value = true
        const companyId = route.params.id

        store.dispatch('organizations/EXPORT_ASSOCIATE_RECORD', {
          companyId,
          filter: { contactsId: [route.params.contactId], ...filter.value },
          name: `${t(`global.associateRecord`)}-${
            currentContact.value?.name
          }-${moment().format('L')}`,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const dateCleared = () => {
        const dateFilter = {
          startDate: '',
          endDate: ''
        }
        filter.value = { ...filter.value, ...dateFilter }
        getTransactions()
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_TRANSACTIONS_PAGE', page)
        getTransactions()
      }

      const getEventId = (row: any) => {
        let exercise: any = null
        if (row.optionExercisePart) {
          exercise = row.optionExercisePart.optionExercise
        }

        if (row.optionIssuancePart) {
          exercise = row.optionIssuancePart.optionIssuance
        }
        if (row.compositionIssuancePart) {
          exercise = row.compositionIssuancePart.compositionIssuance
        }

        if (row.transferPart) {
          exercise = row.transferPart.transfer
        }

        if (row.conversionPart) {
          exercise = row.conversionPart.conversion
        }
        if (row.stockIssuancePart) {
          exercise = row.stockIssuancePart.stockIssuance
        }

        if (row.capitalReductionPart) {
          exercise = row.capitalReductionPart.capitalReduction
        }

        if (row?.optionSunsetPart?.optionSunset) {
          exercise = row?.optionSunsetPart?.optionSunset
        }

        if (row.nominalValue) {
          exercise = row.nominalValue
        }

        if (exercise?.event) return exercise.event.id
        else return null
      }

      const updateOwnershipFilter = (data: any) => {
        filter.value.ownershipType = data.value
        getTransactions()
      }

      const goToEvent = (eventId: string) => {
        const canViewEvent = computed(
          () => acl.permission('read-events') || acl.role('write-events')
        )
        if (canViewEvent.value) {
          router.push({
            name: 'company.eventDetails',
            params: { id: route.params.id, eventId }
          })
        }
      }

      const goToStock = (stock: any) => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.STOCK
        })
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          stockType: STOCKTYPE.SAMPLE
        })
        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
        store.dispatch('organizations/GET_SINGLE_STOCK_ALIAS', {
          companyId: route.params.id,
          stockId: stock.id,
          type: STOCKTYPESALIAS.STOCK
        })
      }

      const goToOption = (stock: any) => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.STOCK
        })
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          stockType: STOCKTYPE.OPTION
        })
        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
        store.dispatch('organizations/GET_SINGLE_STOCK_ALIAS', {
          companyId: route.params.id,
          stockId: stock.id,
          type: STOCKTYPESALIAS.OPTION
        })
      }
      const goToComposition = (stock: any) => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.STOCK
        })
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          stockType: stock.type
        })
        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
        store.dispatch('organizations/GET_SINGLE_STOCK_ALIAS', {
          companyId: route.params.id,
          stockId: stock.id,
          type: STOCKTYPESALIAS.COMPOSITION
        })
      }

      watch(
        () => route.params.contactId,
        async (contactId) => {
          getTransactions(contactId)
        }
      )
      onMounted(() => {
        isLoading.value = true
        getStocks()
        getTransactions()
      })

      return {
        transactions,
        columns,
        pagination,
        selectedStocks,
        currency,
        isDownloading,
        isLoading,
        dateRange,
        ownershipType,
        ownershipOptions,
        CoOwnershipType,
        onPageChange,
        updateStockFilter,
        goToEvent,
        updateOwnershipFilter,
        dateCleared,
        moment,
        downloadPdf,
        getEventName,
        getEventId,
        getUnitPrice,
        formatNumber,
        rgbToHex,
        goToStock,
        goToOption,
        goToComposition
      }
    }
  })
</script>
<style lang="scss">
  .transactions {
    .stock,
    .quantity,
    .form,
    .to,
    .id,
    .actions {
      padding: 5px !important;
    }
  }
</style>
