import { RootState } from '@/store'
import { OrganizationState } from './models'
import { ActionContext } from 'vuex'
import {
  editTemplate,
  getTemplate,
  getTemplates,
  removeTemplate,
  saveTemplate,
  testTemplate
} from '@/services/api/docAuto'
import {
  GET_TEMPLATES,
  GET_TEMPLATE,
  SET_TEMPLATES,
  SET_TEMPLATE,
  TEST_TEMPLATE,
  EDIT_TEMPLATE,
  SAVE_TEMPLATE,
  REMOVE_TEMPLATE,
  SET_CURRENT_TEMPLATE,
  DOWNLOAD_ENVELOPE,
  CANCEL_ENVELOPE,
  RESEND_ENVELOPE,
  ADD_DOCAUTO_TO_OPTIONISSUANCE,
  ADD_DOCAUTO_TO_TRANSFER,
  ADD_DOCAUTO_TO_OPTION_EXERCISE
} from './docAutoModel'
import { template } from 'lodash'
import {
  DELETE_INTEGRATED_SERVICE,
  GET_INTEGRATED_SERVICES,
  SAVE_INTEGRATED_SERVICE_DATA
} from '../User/models'
import {
  addDocAutoToOptionExercise,
  addDocautoToOptionIssuance,
  addDocautoToTransfer,
  getIntegratedServices,
  removeIntegratedService,
  saveIntegratedServiceData
} from '@/services/api'

const context = 'DOC_AUTO'

export const doctAutoActions = {
  async [GET_TEMPLATES](
    { commit }: ActionContext<OrganizationState, RootState>,
    { filter, companyId, onSuccess, onError }: any
  ) {
    try {
      const templates = await getTemplates(companyId, filter)

      if (onSuccess) onSuccess(templates)
      commit(SET_TEMPLATES, templates)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },

  async [RESEND_ENVELOPE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { filter, companyId, onSuccess, onError }: any
  ) {
    try {
      const templates = await getTemplates(companyId, filter)

      if (onSuccess) onSuccess(templates)
      commit(SET_TEMPLATES, templates)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },

  async [CANCEL_ENVELOPE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { filter, companyId, onSuccess, onError }: any
  ) {
    try {
      const templates = await getTemplates(companyId, filter)

      if (onSuccess) onSuccess(templates)
      commit(SET_TEMPLATES, templates)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },

  async [DOWNLOAD_ENVELOPE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { filter, companyId, onSuccess, onError }: any
  ) {
    try {
      const templates = await getTemplates(companyId, filter)

      if (onSuccess) onSuccess(templates)
      commit(SET_TEMPLATES, templates)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },

  async [GET_TEMPLATE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { templateId, companyId, onSuccess, onError }: any
  ) {
    try {
      const template = await getTemplate(companyId, templateId)
      if (onSuccess) onSuccess(template)
      commit(SET_CURRENT_TEMPLATE, template)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },

  async [SAVE_TEMPLATE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { data, companyId, onSuccess, onError }: any
  ) {
    try {
      const template = await saveTemplate(companyId, data)
      dispatch(GET_TEMPLATE, { templateId: template.id, companyId })
      dispatch(GET_TEMPLATES, { companyId, filter: {} })
      onSuccess(template)
    } catch (error: any) {
      console.log(context, error)
      onError(error?.data)
    }
  },

  async [EDIT_TEMPLATE](
    { dispatch, commit }: ActionContext<OrganizationState, RootState>,
    { data, templateId, companyId, onSuccess, onError }: any
  ) {
    try {
      const response = await editTemplate(companyId, templateId, data)
      dispatch(GET_TEMPLATES, { companyId, filter: {} })
      onSuccess(response)
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [TEST_TEMPLATE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { data, templateId, companyId, onSuccess, onError }: any
  ) {
    try {
      const response = await testTemplate(companyId, templateId, data)
      onSuccess(response)
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [REMOVE_TEMPLATE](
    { dispatch, commit }: ActionContext<OrganizationState, RootState>,
    { templateId, companyId, onSuccess, onError }: any
  ) {
    try {
      await removeTemplate(companyId, templateId)
      dispatch(GET_TEMPLATES, { companyId, filter: {} })
      commit(SET_CURRENT_TEMPLATE, null)
      onSuccess()
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [SAVE_INTEGRATED_SERVICE_DATA](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, data, onSuccess, onError }: any
  ) {
    try {
      const response = await saveIntegratedServiceData(companyId, data)
      onSuccess(response)
    } catch (error) {
      onError()
      console.log(error)
    }
  },

  async [ADD_DOCAUTO_TO_OPTIONISSUANCE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, optionIssuanceId, data, onSuccess, onError }: any
  ) {
    try {
      const response = await addDocautoToOptionIssuance(
        companyId,
        optionIssuanceId,
        data
      )
      onSuccess(response)
    } catch (error) {
      onError(error)
      console.log(error)
    }
  },

  async [ADD_DOCAUTO_TO_TRANSFER](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, transferId, data, onSuccess, onError }: any
  ) {
    try {
      const response = await addDocautoToTransfer(companyId, transferId, data)
      onSuccess(response)
    } catch (error) {
      onError(error)
      console.log(error)
    }
  },

  async [ADD_DOCAUTO_TO_OPTION_EXERCISE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, optionExerciseId, data, onSuccess, onError }: any
  ) {
    try {
      const response = await addDocAutoToOptionExercise(
        companyId,
        optionExerciseId,
        data
      )
      onSuccess(response)
    } catch (error) {
      onError(error)
      console.log(error)
    }
  },

  async [DELETE_INTEGRATED_SERVICE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, service, onSuccess, onError }: any
  ) {
    try {
      const response = await removeIntegratedService(companyId, service)
      onSuccess(response)
    } catch (error) {
      onError()
      console.log(error)
    }
  },

  async [GET_INTEGRATED_SERVICES](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }: any
  ) {
    try {
      const response = await getIntegratedServices(companyId)
      onSuccess(response)
    } catch (error) {
      onError()
      console.log(error)
    }
  }
}
