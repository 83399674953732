
  import moment from 'moment'
  import * as yup from 'yup'
  import { computed, defineComponent, ref, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { Field, useForm } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useRoute } from 'vue-router'
  import { DocAutoTemplate } from '@/store/modules/Organization/docAutoModel'
  import store from '@/store'
  import { Button } from '@up.law/uplaw-ui'
  import { EventType } from '@/types/event'
  export default defineComponent({
    components: { Multiselect, Button, Field, FormActions },
    props: {
      eventType: { type: String },
      parts: { type: Array },
      templateData: { type: Object as () => DocAutoTemplate | null }
    },
    emits: ['onUpdated', 'removeTemplate', 'updateLink', 'startTest'],
    setup(props, { emit }) {
      const route = useRoute()
      const { t } = useI18n()
      const formData = ref({ eventPartId: '' })
      const schema = yup.object({
        eventPartId: yup.string().required('requiredEventPart')
      })

      const { errors, meta, setFieldError, handleSubmit, resetForm } = useForm({
        initialValues: formData.value,
        validationSchema: schema
      })

      const eventsParts = computed(() => {
        if (EventType.transfer.toString() == props.eventType)
          return (props.parts ?? [])?.map((x: any) => ({
            id: x.id,
            name: t(`docAutomation.events.${props.eventType}`, {
              stock:
                x?.stockAlias?.alias ??
                x?.optionAlias?.alias ??
                x?.compositionAias?.alias,
              seller: x.seller.name,
              transferee: x.transferee.name,
              date: moment(x.transfer.transferDate).format('L'),
              quantity: x.quantity
            })
          }))

        if (EventType.optionIssuance.toString() == props.eventType)
          return (props.parts ?? [])?.map((x: any) => ({
            id: x.id,
            name: t(`docAutomation.events.${props.eventType}`, {
              stock: x?.optionAlias?.alias,
              contact: x.contact.name,
              date: moment(x.optionIssuance.issuanceDate).format('L'),
              quantity: x.quantity
            })
          }))

        if (EventType.stockIssuance.toString() == props.eventType)
          return (props.parts ?? [])?.map((x: any) => ({
            id: x.id,
            name: t(`docAutomation.events.${props.eventType}`, {
              stock: x?.stockAlias?.alias,
              contact: x.contact.name,
              date: moment(x.stockIssuance?.issuanceDate).format('L'),
              quantity: x.quantity
            })
          }))
        if (EventType.optionExercise.toString() == props.eventType)
          return (props.parts ?? [])?.map((x: any) => ({
            id: x.id,
            name: t(`docAutomation.events.${props.eventType}`, {
              stock: x?.stockAlias?.alias,
              name: x.contact.name,
              date: moment(x.optionExercise?.issuanceDate).format('L'),
              quantity: x.quantity
            })
          }))
        return []
      })

      const handleForm = handleSubmit((values) => {
        const companyId = route.params.id
        emit('startTest', true)

        store.dispatch('organizations/TEST_TEMPLATE', {
          companyId,
          templateId: props.templateData?.id,
          data: {
            partId: values.eventPartId
          },
          onSuccess: ({ url }: any) => {
            emit('updateLink', url)
          },
          onError: () => {
            emit('startTest', false)
          }
        })
      })

      const handleReset = () => {
        resetForm()
      }

      const removeTemplate = () => {
        emit('removeTemplate', props.templateData?.id)
      }

      watch(
        () => props.eventType,
        () => {
          resetForm()
        }
      )
      return {
        formData,
        eventsParts,
        errors,
        meta,
        handleReset,
        handleForm,
        removeTemplate
      }
    }
  })
