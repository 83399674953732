<template>
  <li
    class="mt-2 folder-display transition-all transition text-sm"
    @mouseover="handleReloadDisplay(true)"
    @mouseleave="handleReloadDisplay(false)"
  >
    <div
      class="
        flex
        items-center
        upl-treeview__folder
        py-1
        rounded
        transition transition-all
      "
      @click="open = !open"
    >
      <i
        :class="[
          'md transition-all',
          open ? 'bi-caret-down-fill' : 'bi-caret-right',
          { primary: isSelected }
        ]"
      />
      <i
        :class="[
          !open
            ? dropFolder
              ? 'bi-archive-fill'
              : isFilesCompleted
              ? 'bi-folder-check'
              : !isSelected
              ? 'bi-folder-x'
              : 'bi-folder'
            : dropFolder
            ? 'bi-archive-fill'
            : 'bi-folder2-open',
          'ml-1 xl transition-all',
          { primary: isSelected },
          { '': isFilesCompleted },
          { 'text-error': !isFilesCompleted },
          'text-black'
        ]"
      />
      <div class="ml-2 flex justify-between items-center w-full relative">
        <span
          :class="[
            isSelected ? 'selected primary' : '',
            'mt-0 whitespace-nowrap'
          ]"
          >{{
            isDateFormatted && !category
              ? $t('global.dateFormatPlaceHolder')
              : category
          }}
        </span>
        <div class="flex absolute right-0 z-50">
          <Button
            v-if="containsFiles"
            :class="`
              ${display ? 'visible' : 'invisible'}
              flex
              justify-center rounded-full w-5 h-5 bg-white
              items-center text-center
              reload-btn`"
            variant="secondary"
            :disabled="false"
            @click.stop.prevent="loadMore(category)"
          >
            <i class="bi bi-plus"></i>
          </Button>
          <span
            :class="[
              isFilesCompleted
                ? ' bg-lightPurple text-primary8'
                : 'warning bg-error',
              'ml-2 document-count rounded-full  text-center flex justify-center items-center'
            ]"
          >
            {{ containsFiles ? Array.from(data.values()).length : count }}</span
          >
        </div>
      </div>
    </div>
    <ul v-if="open" class="ml-1">
      <template v-if="containsFiles">
        <Document
          v-for="(document, index) of data"
          :key="index"
          class="cursor-pointer"
          :document="document"
          :by-date="isDateFormatted"
          :current-document="currentDocument"
          @selectDocument="onSelect"
          @selectedDocument="childSelected"
        />
      </template>

      <div v-else class="pl-3">
        <Folder
          v-for="([key, value], index) in data.entries()"
          :key="index"
          :data="value"
          :category="key"
          :count="value.length"
          :current-document="currentDocument"
          :can-open="currentDocument?.docSubCategory?.name === key"
          :is-date-formatted="isDateFormatted"
          @selectDocument="onSelect"
          @loadMore="loadMore"
        />
      </div>
    </ul>
  </li>
</template>

<script lang="ts">
  import { Document as DocumentElement } from '@/services/api/models/document'
  import {
    computed,
    defineComponent,
    onErrorCaptured,
    watch,
    ref,
    onMounted
  } from 'vue'
  import Document from './document.vue'
  import { Document as DocumentModel } from '@/services/api/models'
  import { Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  export default defineComponent({
    name: 'UplFolder',
    components: { Document, Button },
    props: {
      category: {
        type: String,
        required: true
      },

      data: {
        type: Object as () => Map<string, DocumentElement[]>,
        required: true
      },
      canOpen: {
        type: Boolean,
        required: false,
        default: false
      },
      isDateFormatted: {
        type: Boolean,
        required: false,
        default: false
      },
      count: {
        type: Number,
        required: false,
        default: 0
      }
    },
    emits: ['selectDocument', 'loadMore'],
    setup(props, { emit }) {
      const store = useStore()
      const open = ref(props.canOpen)
      const display = ref(false)
      const containsFiles = ref(props.data instanceof Array)
      const totalyCompleted = ref(false)
      const currentDocument = computed<DocumentModel | null>(
        () => store.getters['organizations/currentDocument']
      )

      const isFilesCompleted = computed<boolean>(() =>
        Array.from(props.data.values())
          .flat()
          .every((x: any) => !x.isDraft)
      )

      const dropFolder = computed(() => false)
      const isSelected = computed(() => false)
      const onSelect = (document: DocumentModel) => {
        emit('selectDocument', document)
      }
      const handleReloadDisplay = (value: boolean) => {
        display.value = value
      }

      const loadMore = (value: string) => {
        open.value = true
        emit('loadMore', value)
      }

      const childSelected = () => {
        open.value = true
      }

      onErrorCaptured(() => {
        console.warn('Error occured')
      })

      return {
        currentDocument,
        dropFolder,
        isSelected,
        open,
        totalyCompleted,
        containsFiles,
        display,
        isFilesCompleted,
        emit,
        onSelect,
        handleReloadDisplay,
        loadMore,
        childSelected
      }
    },
    computed: {}
  })
</script>

<style lang="scss" scoped>
  .upl-treeview__folder:hover {
    background-color: var(--color-primary-lower);
  }
  .document-count {
    min-width: 1.25rem;
    min-height: 1.25rem;
  }
  .upl-treeview__folder {
    .upl-badge {
      min-width: 60px;
    }
    .selected {
      color: var(--color-primary);
      font-weight: bold;
    }
  }
</style>
