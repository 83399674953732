<template>
  <div class="w-full pb-5">
    <div class="flex mt-3">
      <div class="w-1/5 mr-2 h-10">
        <Field v-slot="{ field }" v-model="search" name="search">
          <Input
            v-bind="field"
            class="text-sm h-full"
            :placeholder="$t('account.accountInformations.searchPlaceholder')"
          >
            <template #left>
              <i class="bi bi-search"></i>
            </template>
          </Input>
        </Field>
      </div>
      <div class="w-1/5 mr-2">
        <DropdownCompany
          class="w-full"
          name="companyId"
          mode="single"
          :disabled="false"
          :value="companyId"
          @on-updated="handleCompanyChange"
        />
      </div>
      <div class="w-1/5 mr-2">
        <Field v-slot="{ field }" v-model="roleId" name="roleId">
          <multiselect
            v-bind="field"
            :options="roles"
            :value="roleId"
            :groups="false"
            name="roleId"
            class="h-10"
            :has-label="false"
            :filter-results="false"
            open-direction="bottom"
            :placeholder="$t('account.accountInformations.rolesPlaceholder')"
            @search-change="roleSearch"
            @change="roleChanged"
          />
        </Field>
      </div>
    </div>
    <div
      v-if="isLoading && !isRefreshing"
      class="flex w-full min-h-full h-full justify-center items-center my-10"
    >
      <Loader />
    </div>
    <div v-else-if="accounts.length" class="mb-5 text-md">
      <div v-can.any="['admin', 'is-manager', 'write-users']" class="mt-8 mb-5">
        <div class="flex justify-between items-center h-10 mt-3 mb-5">
          <div class="flex justify-start items-center h-10">
            <h4 class="mr-2">
              {{ $t('account.accountInformations.form.addLabel') }}
            </h4>
            <Field
              v-slot="{ field }"
              v-model="countInvitees"
              type="number"
              name="countInvitees"
            >
              <Input
                v-model="countInvitees"
                type="number"
                v-bind="field"
                class="w-20 mr-1 px-2"
                :no-error="true"
                placeholder=""
              />
            </Field>
            <h4>
              {{
                $tc('account.accountInformations.invitations', countInvitees)
              }}
            </h4>

            <div class="text-xl">
              <Button
                size="medium"
                :disabled="countInvitees <= 0"
                :label="$t('account.accountInformations.form.add')"
                variant="custom"
                class="flex items-center mx-2 text-xl bg-white text-primary3"
                @click.prevent="addInvitees"
              >
                <i class="bi bi-plus text-xl" />
              </Button>
            </div>
          </div>
          <div class="text-2xl">
            <Button
              :disabled="invitees.length <= 0 || !hasValidInvidations()"
              size="medium"
              :label="$t('account.accountInformations.form.invite')"
              variant="primary"
              class="flex items-center text-xl"
              @click.prevent="handleInvitations"
            >
              <i class="bi bi-send text-xl" />
            </Button>
          </div>
        </div>

        <div class="mb-3">
          <NewInvitationForm
            v-for="(value, index) in invitees"
            :key="index"
            :index="index"
            @handleRemove="removeInvitation"
            @handleChange="handleChange"
          />
        </div>
      </div>
      <DataTable
        v-if="accounts.length"
        :has-checkbox="false"
        :columns="columns"
        :pagination="pagination"
        :rows="accounts"
        :is-filtering="isRefreshing"
        @on-page-change="onPageChange"
      >
        <template #user="{ row }">
          <div class="">
            <p>{{ row.lastName }} {{ row.firstName }} ({{ row.email }})</p>
            <p class="text-secondary9 italic text-xs">
              {{ $t(`accountStatus.${row.status}`) }}
            </p>
          </div>
        </template>
        <template #connection="{ row }">
          <div class="">
            <span v-if="row.connectionThroughSSO">SSO (Google)</span>
            <span v-else-if="row.connectionThroughApple">Apple</span>
            <span v-else>{{
              $t('account.accountInformations.form.normal_login')
            }}</span>
          </div>
        </template>
        <template #roles="{ row }">
          <div class="">
            <span
              v-for="r in row.roles"
              :key="r.id"
              class="px-2 py-1 bg-success text-white rounded-full mr-1 text-sm"
              >{{
                $te(`roles.${r.slug}`) ? $t(`roles.${r.slug}`) : r.name
              }}</span
            >
            <div>
              <p>
                {{
                  getFirstCompany(row.companiesAccounts ?? [])?.company?.name
                }}
                <el-popover
                  v-if="
                    getOtherLinkedCompanyAccounts(
                      row.companiesAccounts,
                      getFirstCompany(row.companiesAccounts)
                    )?.length > 1
                  "
                  placement="left"
                  :width="700"
                  trigger="hover"
                >
                  <template #reference>
                    {{ $t('global.and') }}
                    {{
                      getOtherLinkedCompanyAccounts(
                        row.companiesAccounts,
                        getFirstCompany(row.companiesAccounts)
                      ).length
                    }}
                    {{ $t('global.others') }}
                  </template>
                  <el-table
                    :data="
                      buildLines(
                        getOtherLinkedCompanyAccounts(
                          row.companiesAccounts,
                          getFirstCompany(row.companiesAccounts)
                        )
                      )
                    "
                  >
                    <el-table-column
                      width="300"
                      property="companyName"
                      :label="$t('global.company')"
                    />
                    <el-table-column
                      width="200"
                      property="roleName"
                      :label="$t('global.role')"
                    />
                    <el-table-column
                      width="200"
                      property="contactName"
                      :label="$t('global.contact')"
                    />
                  </el-table>
                </el-popover>
                <span
                  v-else-if="
                    getOtherLinkedCompanyAccounts(
                      row.companiesAccounts,
                      getFirstCompany(row.companiesAccounts)
                    ).length == 1
                  "
                  >,
                  {{
                    getOtherLinkedCompanyAccounts(
                      row.companiesAccounts,
                      getFirstCompany(row.companiesAccounts)
                    )[0]?.company?.name ?? '-'
                  }}
                </span>
              </p>
            </div>
          </div>
        </template>

        <template #actions="{ row }">
          <div
            v-can.any="['admin', 'is-manager', 'write-users']"
            class="flex justify-end"
          >
            <Button
              size="medium"
              label=""
              variant="tertiary"
              class="flex items-center mx-2"
              @click.prevent="() => getUserDetails(row)"
            >
              <i class="bi bi-eye text-xl text-primary4" />
            </Button>

            <MenuDropdown
              class="ml-2"
              :actions="actions"
              caller-class="text-primary3 hover:bg-opacity-20 py-2 px-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              @on-click="($event) => handleAction($event, row)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </MenuDropdown>
          </div>
        </template>
      </DataTable>
    </div>

    <div
      v-else
      class="flex justify-left items-left w-full mt-4 transition transition-all"
    >
      <EmptyState
        image="no-result.svg"
        class="d-block mx-auto"
        title=""
        :subtitle="$t('global.noUserAccount')"
      />
    </div>

    <transition name="modal">
      <Modal v-if="isEditing" size="w-1/3 md:w-1/2" @close="closeModal">
        <template #header>
          <h3 class="text-md font-semibold">
            {{ $t('account.accountInformations.form.editLabel') }}
          </h3>
        </template>

        <template #body>
          <EditInvitationForm
            :account="currentUser"
            @editAccount="handleAccountEdit"
            @on-close="closeModal"
        /></template>
        <template #footer>
          <label for=""></label>
        </template>
      </Modal>
    </transition>

    <transition name="modal">
      <Modal v-if="canRemove" size="w-1/3 md:w-1/2" @close="closeRemoveModal">
        <template #header>
          <div
            class="text-md text-error font-semibold"
            v-html="
              $t('account.accountInformations.accountDeletionTitle', {
                name: `${displayedUser?.firstName} ${displayedUser?.lastName}`
              })
            "
          ></div>
        </template>
        <template #body>
          <div
            v-html="$t('account.accountInformations.accountDeletionText')"
          ></div>
        </template>
        <template #footer>
          <div class="flex text-xl justify-end mt-6 mb-3">
            <Button
              class="flex text-secondary9 rounded mr-2"
              variant="custom"
              :label="$t('account.accountCompanies.deletionModalCancel')"
              @click="canDelete = false"
            />
            <Button
              class="flex text-white bg-error rounded"
              variant="custom"
              :label="$t('account.accountCompanies.deletionModalText')"
              @click.prevent="submitDeletion"
            />
          </div>
        </template>
      </Modal>
    </transition>

    <transition name="modal">
      <Modal v-if="showDetails" size="w-3/5" @close="closeDetailsModal">
        <template #header>
          <h3 class="text-md font-semibold">
            {{ displayedUser?.lastName }} {{ displayedUser?.firstName }}
          </h3>
        </template>

        <template #body>
          <div style="max-height: 80vh">
            <data-table
              :columns="detailsTableColumns"
              :rows="currentUserCompanyAccounts"
            >
              <template #company="{ row }">
                <div class="">
                  <p>{{ row.company?.name }}</p>
                </div>
              </template>
              <template #role="{ row }">
                <div class="">
                  <p>{{ row.role.name }}</p>
                </div>
              </template>

              <template #contact="{ row }">
                <div v-if="row?.contact" class="">
                  <ContactBadge
                    :id="row?.contact?.id"
                    class=""
                    :contact="row?.contact"
                    :use-picture="true"
                    :details="false"
                    type="light"
                  />
                </div>
                <span v-else>-</span>
              </template>
            </data-table>
          </div>
        </template>
        <template #footer>
          <label for=""></label>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import DataTable from '@/components/DataTable/index.vue'
  import NewInvitationForm from '@/components/Forms/Account/invitationForm.vue'
  import EditInvitationForm from '@/components/Forms/Account/edit.vue'
  import { InvitationForm } from '@/types'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import Modal from '@/components/Modal/Modal.vue'
  import { Company, User, UserAccount } from '@/services/api/models'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { CompanyAccount, Role } from '@/services/api/models/user'
  import Loader from '@/components/Loader/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'
  import MenuDropdown from '@/components/MenuDropdown/index.vue'
  import * as yup from 'yup'
  import { ElPopover, ElTableColumn, ElTable } from 'element-plus'

  export default defineComponent({
    components: {
      Field,
      Input,
      Button,
      MenuDropdown,
      DataTable,
      NewInvitationForm,
      ContactBadge,
      Modal,
      ElTableColumn,
      ElTable,
      ElPopover,
      EmptyState,
      EditInvitationForm,
      Loader,
      Multiselect,
      DropdownCompany
    },
    setup() {
      const search = ref('')
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t, te } = useI18n()
      const isEditing = ref(false)
      const isSubmittingEdition = ref(false)
      const showDetails = ref(false)
      const canRemove = ref(false)

      const roleId = ref()
      const companyId = ref()
      const isLoadingUsers = ref(false)
      const currentUserCompanyAccounts = ref<CompanyAccount[]>([])
      const displayedUser = ref<User | null>(null)
      const isRefreshing = ref(false)
      const canDelete = ref(false)
      const isMakingAction = ref(false)
      const tableColumns = computed(() => [
        {
          label: t('datatable.column.user'),
          field: 'user',
          custom: true
        },

        {
          label: t('datatable.column.company'),
          field: 'roles',
          custom: true,
          width: '30%'
        },

        {
          label: 'Connection',
          field: 'connection',
          custom: true,
          width: '20%'
        },

        {
          label: '',
          field: 'actions',
          custom: true,
          width: '3%'
        }
      ])
      const getFirstCompany = (accounts: CompanyAccount[]) => {
        const [first] = accounts.filter((x) => x.company)
        return first
      }
      const getOtherLinkedCompanyAccounts = (
        accounts: CompanyAccount[],
        first: CompanyAccount
      ) => {
        if (first?.id) return accounts.filter((x) => x.id != first.id)
        else return accounts
      }

      const buildLines = (rows: CompanyAccount[]) => {
        return rows.map((x) => ({
          companyName: x.company?.name ?? '-',
          roleName: x.role.name ?? '-',
          contactName: x.contact?.name ?? '-'
        }))
      }

      const detailsTableColumns = computed(() => [
        {
          label: t('datatable.column.company'),
          field: 'company',
          custom: true
        },

        {
          label: t('datatable.column.role'),
          field: 'role',
          custom: true
        },

        {
          label: t('datatable.column.contact'),
          field: 'contact',
          custom: true
        }
      ])

      const actions = computed(() => [
        {
          name: t('global.actions.editUser'),
          icon: 'pencil',
          type: 'list',
          section: 1,
          key: 'edit'
        },
        {
          name: t('global.actions.sendNewInvite'),
          icon: 'envelope-fill',
          type: 'list',
          section: 1,
          key: 'invite'
        },
        {
          name: t('global.actions.deleteUser'),
          icon: 'trash',
          type: 'list',
          section: 2,
          key: 'delete'
        }
      ])
      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData']
      )

      const userRoles = computed<Role[]>(() => store.getters['auth/roles'])

      const roleSearch = (value: string) => {
        store.dispatch('toolbox/GET_ROLES', {
          companyId: route.params.id,
          data: { search: value },
          onSuccess: (data: Role[]) => {
            allRoles.value = [...data]
          },
          onError: () => {
            //
          }
        })
      }

      const highestPriority = computed(() =>
        Math.max(...(userRoles.value || []).map((x) => x.priority))
      )
      const authRole = computed<Role>(() =>
        (userRoles.value || []).reduce((prev: Role, current: Role) =>
          current.priority > prev.priority ? current : prev
        )
      )

      const allRoles = ref<Role[]>([])

      const roles = computed(() =>
        [...allRoles.value]
          .filter(
            (x) =>
              x.priority >=
              (![0, 2].includes(highestPriority.value)
                ? highestPriority.value + 1
                : highestPriority.value)
          )
          .sort((a, b) => a.priority - b.priority)
          .map((r) => ({
            ...r,
            name: te(`roles.${r.slug}`) ? t(`roles.${r.slug}`) : r.name
          }))
      )

      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )
      const auth = computed<UserAccount>(() => store.getters['auth/profile'])
      const accounts = computed(
        () => store.getters['organizations/accountUsers']
      )
      const pagination = computed(
        () => store.getters['organizations/accountsPagination']
      )
      const companies = computed<Company[]>(() => [
        ...store.getters['organizations/loaderCompanies']
      ])

      const schema = yup.object().shape({
        uuid: yup.string().uuid()
      })

      const isValid = computed(() =>
        schema.isValidSync({ uuid: companyId.value })
      )

      const countInvitees = ref(1)
      const columns = ref(tableColumns)
      const invitees = ref<any[]>([])
      const currentUser = ref<InvitationForm>({
        lastName: '',
        firstName: '',
        email: '',
        accounts: [],
        roles: '',
        contactId: ''
      })

      const isSearchingCompany = ref(false)

      const isAllUsersListingPage = computed(
        () => route.name === 'accountUsers'
      )
      const userRemove = (user: User) => {
        displayedUser.value = { ...user }
        canRemove.value = true
      }
      const handleCompanyChange = (id: any) => {
        companyId.value = id

        getUsers(1)
      }

      const roleChanged = (id: string) => {
        roleId.value = id
        getUsers()
      }
      const handleAction = ($event: string, user: User) => {
        if ($event == 'invite') {
          sendInvitationMail(user)
        } else if ($event == 'edit') {
          editUser(user)
        } else if ($event == 'delete') {
          userRemove(user)
        }
      }
      const getUserDetails = (row: User) => {
        displayedUser.value = { ...row }

        currentUserCompanyAccounts.value = row.companiesAccounts
        showDetails.value = true
      }

      const removeInvitation = (index: number) => {
        invitees.value.splice(index, 1)
      }

      const getContact = (row: User) => {
        return row.companiesAccounts.find(
          (x: CompanyAccount) => x.contactId !== null
        )
      }
      const addInvitees = () => {
        for (let index = 0; index < Number(countInvitees.value); index++) {
          invitees.value.push({})
        }
      }
      const handleChange = (index: number, values: any) => {
        invitees.value[index] = values
      }

      const goToContact = async (account: CompanyAccount) => {
        store.commit(`organizations/SET_CONTEXT_COMPANY`, account.company)

        router.push({
          name: 'company.directory',
          params: {
            id: account.company?.id,
            contactId: account.contact?.id
          }
        })
      }
      const getRoles = () => {
        store.dispatch('toolbox/GET_ROLES', {
          companyId: route.params.id,
          onSuccess: (data: Role[]) => {
            allRoles.value = [...data]
          },
          onError: () => {
            //
          }
        })
      }

      const closeRemoveModal = () => {
        canRemove.value = false
      }

      const editUser = (data: User) => {
        const user: any = {}
        user.email = data.email
        user.id = data.id
        user.lang = data.lang
        user.connectionThroughSSO = data.connectionThroughSSO ?? false
        user.firstName = data.firstName
        user.lastName = data.lastName
        user.roles = (data.roles || []).map((x) => x.id || x)
        user.accounts = data.companiesAccounts.map((x) => ({
          id: x.id,
          companyId: x.companyId,
          roleId: x.roleId,
          contactId: x?.contactId
        }))
        currentUser.value = { ...user }
        isSubmittingEdition.value = false

        isEditing.value = true
      }

      const closeModal = () => {
        isRefreshing.value = true
        getUsers()

        isEditing.value = false
      }

      const closeDetailsModal = () => {
        showDetails.value = false
        currentUserCompanyAccounts.value = []
        displayedUser.value = null
      }

      const handleAccountEdit = (values: InvitationForm) => {
        const data: any = { ...values }

        if (!values.contactId) delete data.contactId

        if (!values.companyId) delete data.companyId
        isSubmittingEdition.value = true
        store.dispatch('organizations/EDIT_USER_ACCOUNT', {
          userId: currentUser.value.id,
          form: data,
          companyId: currentCompany.value.id,
          onError: () => {
            isSubmittingEdition.value = false

            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(`account.accountInformations.apiResponse.error`)
            })
          },
          onSuccess: () => {
            getUsers()
            isSubmittingEdition.value = false

            isEditing.value = false

            store.dispatch('toolbox/displayToast', {
              type: TYPE.INFO,
              context: t('account.context'),
              message: t(`account.accountInformations.apiResponse.success`)
            })

            getUsers()
          }
        })
      }

      const submitDeletion = () => {
        removeUser(displayedUser.value)
      }
      const removeUser = (user: any) => {
        store.dispatch('organizations/DELETE_USER_ACCOUNT', {
          userId: user.id,
          companyId: currentCompany.value.id,
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.deletionError`
              )
            })
          },
          onSuccess: () => {
            getUsers()
            closeRemoveModal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.deletionSuccess`
              )
            })
          }
        })
      }
      const hasValidInvidations = () => {
        const fields = ['email', 'lastName', 'firstName', 'roles']

        return invitees.value.every((elm) => {
          return fields.every((f) => elm[f] && elm[f] !== '')
        })
      }

      const canEdit = (roles: Role[]) => {
        const userPriority = !(roles || []).map((x) => x.priority).length
          ? 9
          : Math.max(...(roles || []).map((x) => x.priority))
        const priority = auth.value?.roles
          ? Math.max(...auth.value?.roles?.map((x) => x.priority))
          : 9

        return [0, 2].includes(priority)
          ? priority <= userPriority
          : priority < userPriority
      }

      const sendInvitationMail = (user: User) => {
        store.dispatch('organizations/SEND_INVITATION_MAIL', {
          userId: user.id,
          companyId: currentCompany.value.id,
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(`account.accountInformations.apiResponse.error`)
            })
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.INFO,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.successInvited`
              )
            })
          }
        })
      }

      const sendInvitation = (values: InvitationForm) => {
        const data: any = { ...values }
        data.roles = [values.roles]
        const selectedRole = allRoles.value.find((x) => x.id === values.roles)
        if ((selectedRole?.priority || 0) > 1) {
          data.accounts = [
            {
              contactId: values.contactId,
              companyId: values.companyId,
              roleId: values.roles
            }
          ]

          data.roles = []
          delete data.contactId
          delete data.companyId
        } else {
          delete data.contactId
          if (!data.companyId) delete data.companyId
        }
        isMakingAction.value = true
        store.dispatch('organizations/CREATE_USER_ACCOUNT', {
          form: data,
          companyId: currentCompany.value.id,
          onError: (message: string) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message:
                message ||
                `${values.email}: ${t(
                  `account.accountInformations.apiResponse.creationFailed`
                )}`
            })
            isMakingAction.value = false
          },
          onSuccess: () => {
            invitees.value = invitees.value.filter(
              (x) => x.email !== values.email
            )
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.context'),
              message: `${values.email}: ${t(
                `account.accountInformations.apiResponse.creationSuccess`
              )}`
            })
            isMakingAction.value = false

            getUsers()
          }
        })
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_ACCOUNTS_PAGINATION_PAGE', page)

        isRefreshing.value = true
        getUsers()
      }
      const updateCompany = (value: string) => {
        isSearchingCompany.value = false
        companyId.value = value
      }

      const getUsers = (page = 1) => {
        if (isSearchingCompany.value) return
        const filter: any = { limit: 20, offset: (page - 1) * 20 }
        if (search.value) filter.search = search.value
        if (roleId.value) filter.roleId = roleId.value
        filter.companyIds = companyId.value ? [companyId.value] : []

        store.dispatch('organizations/GET_ACCOUNT_USERS', {
          filter,
          onSuccess: () => {
            isRefreshing.value = false
          },
          onError: () => {
            isRefreshing.value = false
          }
        })
      }

      const handleInvitations = async () => {
        const data = [...invitees.value]
        await Promise.all(
          data.map((user) =>
            sendInvitation({ ...user, [user.connection ?? 'normal']: true })
          )
        )
        getUsers()
      }

      watch([search], () => {
        onPageChange(1)
      })

      onMounted(() => {
        getUsers()
        getRoles()
      })

      return {
        search,
        columns,
        isLoadingUsers,
        roleId,
        canDelete,
        countInvitees,
        invitees,
        accounts,
        isEditing,
        currentUser,
        pagination,
        isRefreshing,
        isLoading,
        actions,
        isMakingAction,
        isSubmittingEdition,
        isAllUsersListingPage,
        editUser,
        roleChanged,
        canEdit,
        buildLines,
        handleAction,
        closeModal,
        getOtherLinkedCompanyAccounts,
        closeDetailsModal,
        goToContact,
        onPageChange,
        getContact,
        addInvitees,
        removeInvitation,
        handleChange,
        userRemove,
        canRemove,
        submitDeletion,
        closeRemoveModal,
        companyId,
        roleSearch,
        handleInvitations,
        companies,
        handleAccountEdit,
        removeUser,
        sendInvitationMail,
        roles,
        displayedUser,
        currentUserCompanyAccounts,
        auth,
        detailsTableColumns,
        getUserDetails,
        showDetails,
        handleCompanyChange,
        updateCompany,
        getFirstCompany,
        hasValidInvidations
      }
    }
  })
</script>
