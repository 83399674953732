export enum VestingMode {
  OPTIONS = 'OPTIONS',
  FREE_SHARES = 'FREE_SHARES'
}

export enum DeliveryStatus {
  created = 'CREATED',
  sent = 'SENT',
  delivered = 'DELIVERED',
  waitingforOthers = 'WAITING_FOR_OTHERS',
  needsToSign = 'NEEDS_TO_SIGN',
  needsToView = 'NEEDS_TO_VIEW',
  correcting = 'CORRECTING',
  voided = 'VOIDED',
  declined = 'DECLINED',
  completed = 'COMPLETED',
  expired = 'EXPIRED',
  deliveryFailure = 'DELIVERY_FAILIURE',
  authenticationFailed = 'AUTHENTICATION_FAILED',
  draft = 'DRAFT'
}
