
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { Field } from 'vee-validate'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DataTable from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import {
    Contact,
    ExercicePeriod,
    ExerciseCalendar,
    OptionStockAlias
  } from '@/services/api/models'
  import { formatNumber, rgbToHex, ucFirst } from '@/utils'
  import { useI18n } from 'vue-i18n'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import UplChart from '@/components/Graphs/UplChart.vue'
  import { ElTooltip } from 'element-plus'
  import Number from '@/components/Number/index.vue'
  import { NumberType } from '@/types'
  import { useAcl } from 'vue-simple-acl/src'
  export default defineComponent({
    components: {
      Field,
      DataTable,
      Tag,
      Number,
      EmptyState,
      Multiselect,
      DatePicker,
      Filter,
      UplChart,
      Button,
      Loader,
      ElTooltip,
      DownloadButton
    },
    setup() {
      const { t } = useI18n()
      const acl = useAcl()
      const store = useStore()
      const route = useRoute()
      const selectedColumns = ref<string[]>([])
      const mode = ref('list')
      const filter = ref({
        contactId: '',
        optionAliasId: '',
        date: new Date().toISOString()
      })
      const displayColumnsSelector = computed(() =>
        acl.anyCan(['write-vestings'])
      )
      const router = useRouter()

      const optionId = ref('')

      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )
      const period = computed<ExerciseCalendar | undefined>(() =>
        exerciceCalendars.value.find(
          (x) => x.optionAlias.id === optionId.value.split('@_')[0]
        )
      )
      const currency = computed(() => store.getters['organizations/currency'])
      const currentContact = computed(
        () => store.getters['organizations/contactDetails']
      )
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )

      const download = () => {
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_EXERCISE_CALENDARS', {
          name: `${currentContact.value.name}-${t('exports.shareholding')} `,
          companyId,
          filter: { contactId: [currentContact.value.id] }
        })
      }

      const isLoading = ref(false)

      const optionAliases = computed(() =>
        exerciceCalendars.value.reduce((acc: OptionStockAlias[], x) => {
          if (!acc.map((y: any) => y.id).includes(x.optionAlias.id)) {
            acc.push({ ...x.optionAlias, name: x.optionAlias.alias })
          }
          return acc
        }, [])
      )

      const contacts = computed<Contact>(() =>
        store.getters['organizations/contacts'].map((x: any) => ({
          ...x,
          name: ucFirst(x.name)
        }))
      )

      const total = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.optionIssuancePart.quantity),
          0
        )
      )

      const exercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += c.vestedQuantity), 0)
      )

      const ceil = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.exercisableBalance),
          0
        )
      )

      const obsoleteQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.numberOfSunsetedOptions),
          0
        )
      )
      const exercisedQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.exercisedQuantity),
          0
        )
      )

      const heldBalance = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.notVestedQuantity ?? 0),
          0
        )
      )

      const exercisePrice = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              c.optionIssuancePart.unitPrice ||
              c.optionIssuancePart.totalPrice / c.optionIssuancePart.quantity),
          0
        )
      )

      const lastStockPrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += c.lastStockPrice), 0)
      )
      const portentialGainAtDate = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.portentialGainAtDate),
          0
        )
      )

      const totalExercisePrice = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              c.optionIssuancePart?.optionIssuance?.exercisePrice *
              c.optionIssuancePart.quantity),
          0
        )
      )
      const potentialGain100Percent = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.potentialGain100Percent),
          0
        )
      )

      const exercisePeriods = computed<ExercicePeriod[]>(() =>
        [...(period.value?.exercisePeriods ?? [])].sort(
          (a, b) => a.vestingPeriod.order - b.vestingPeriod.order
        )
      )

      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }
      const generateData = () => ({
        labels: exercisePeriods.value.map((x) =>
          moment(x.endDate).format(t('dateFormat'))
        ),
        dataset: [
          {
            type: 'line',
            label: 'Vestable quantity',
            data: exercisePeriods.value.map((x) => x.cumulativeQuantity),
            lineTension: 0,
            pointBackgroundColor: '#fff',
            pointBorderColor: 'rgb(24,15,239)',
            pointBorderWidth: 3,
            pointRadius: 5,
            borderColor: 'rgb(24,15,239)',
            borderWidth: 3,
            order: 1,
            fill: false
          }
        ],
        title: '',
        options: {
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          }
        }
      })
      const optionPlanHistory = computed(() => generateData())

      const allColumns = ref([
        {
          label: t('events.fieldsLabels.stock'),
          field: 'stock',
          custom: true,
          order: 2,
          tooltipText: t('events.tooltips.stock'),
          tooltipWidth: '12%',
          hasTooltip: true
        },

        {
          label: t('events.fieldsLabels.vestingStartDate'),
          field: 'vestingStartDate',
          custom: true,
          order: 3,
          tooltipText: t('events.tooltips.vestingStartDate'),
          tooltipWidth: '17%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.vestingEndDate'),
          field: 'vestingEndDate',
          custom: true,
          filterable: true,
          selected: true,
          tooltipText: t('events.tooltips.vestingEndDate'),
          tooltipWidth: '17%',
          hasTooltip: true,
          order: 4
        },
        {
          label: t('events.fieldsLabels.delegation'),
          field: 'delegation',
          custom: true,
          order: 4.5,
          tooltipText: t('events.tooltips.delegation'),
          tooltipWidth: '12%',
          hasTooltip: true,
          filterable: true
        },
        {
          label: t('events.fieldsLabels.assignedQuantity'),
          field: 'allocatedQuantity',
          custom: true,
          width: '2%',
          tooltipText: t('events.tooltips.assignedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true,
          order: 6
        },

        {
          label: t('events.fieldsLabels.exercisableQuantity'),
          field: 'exercisableQuantity',
          custom: true,
          filterable: true,
          order: 9,
          tooltipText: t('events.tooltips.vestedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.balance'),
          field: 'ceil',
          custom: true,
          filterable: true,
          order: 10,
          tooltipText: t('events.tooltips.exercisableBalance'),
          tooltipWidth: '20%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.lapsedQuantity'),
          field: 'lapsedQuantity',
          custom: true,
          filterable: true,
          order: 8,
          tooltipText: t('events.tooltips.lapsedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true,

          selected: true
        },
        {
          label: t('events.fieldsLabels.exercisedQuantity'),
          field: 'exercisedQuantity',
          custom: true,
          filterable: true,
          order: 7,
          tooltipText: t('events.tooltips.exercisedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.heldBalance'),
          field: 'heldBalance',
          custom: true,
          filterable: true,
          order: 8,
          tooltipText: t('events.tooltips.notVestedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.exercisePrice'),
          field: 'exercisePrice',
          custom: true,
          filterable: true,
          order: 5,
          tooltipText: t('events.tooltips.exercisePrice'),
          tooltipWidth: '12%',
          hasTooltip: true,
          selected: true
        },
        {
          label: t('events.fieldsLabels.totalExercisePrice'),
          field: 'totalExercisePrice',
          custom: true,
          filterable: true,
          order: 12.5,
          tooltipText: t('events.tooltips.totalExercisePrice'),
          tooltipWidth: '17%',
          hasTooltip: true,
          selected: false
        },
        {
          label: t('events.fieldsLabels.lastStockPrice'),
          field: 'lastActionPrice',
          custom: true,
          tooltipText: t('events.tooltips.lastStockPrice'),
          tooltipWidth: '20%',
          hasTooltip: true,
          filterable: true,
          order: 12
        },
        {
          label: t('events.fieldsLabels.potentialPv'),
          field: 'pv',
          custom: true,
          tooltipText: t('events.tooltips.potentialPv'),
          tooltipWidth: '17%',
          hasTooltip: true,

          filterable: true,
          order: 13
        },

        {
          label: t('events.fieldsLabels.vestingPlan'),
          field: 'vesting',
          custom: true,
          tooltipText: t('events.tooltips.vestingPlan'),
          tooltipWidth: '17%',
          hasTooltip: true,
          filterable: true,
          order: 15
        },
        {
          label: t('datatable.column.event'),
          field: 'eventName',
          custom: true,
          width: '3%',
          order: 15,
          tooltipText: t('events.tooltips.event'),
          tooltipWidth: '20%',
          hasTooltip: true,
          filterable: true,
          selected: true
        }
      ])

      const existingColumns = ref(allColumns.value.filter((x) => !x.filterable))
      const columns = ref(
        allColumns.value
          .filter((x) => !x.filterable || x.selected)
          .sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable || x.selected)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      selectedColumns.value = allColumns.value
        .filter((x) => x.selected)
        .map((x) => x.field)
      const handleFilterSelected = (value: string[]) => {
        columns.value = [
          ...existingColumns.value,
          ...allColumns.value.filter((x) => value.includes(x.field))
        ].sort((a: any, b: any) => a.order - b.order)

        selectedColumns.value = value
      }

      const getVesting = async () => {
        await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            ...filter.value,
            contactId: route.params.contactId
          },
          onSuccess: () => {
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const resetFilters = () => {
        filter.value = {
          contactId: route.params.contactId?.toString(),
          optionAliasId: '',
          date: new Date().toISOString()
        }
      }

      const getOrder = (field: string) =>
        columns.value.find((x) => x.field === field)?.order || 0

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_EXERCISE_CALENDARS_PAGE', page)
        getVesting()
      }

      onBeforeUnmount(() =>
        store.commit('organizations/SET_EXERCISE_CALENDARS', {
          ...exerciceCalendars,

          data: []
        })
      )
      watch(
        () => route.params.id,
        () => {
          getVesting()
        }
      )

      watch(
        () => filter.value.contactId,
        (val) => {
          filter.value.contactId = val
          getVesting()
        }
      )

      watch(
        () => route.params.contactId,
        (val) => {
          filter.value.contactId = val?.toString()
          getVesting()
        }
      )

      watch(
        () => filter.value.optionAliasId,
        (val) => {
          filter.value.optionAliasId = val
          getVesting()
        }
      )

      watch(optionAliases, ([val]) => {
        optionId.value = val?.id
      })

      watch(
        () => filter.value.date,
        (val) => {
          filter.value.date = val || new Date().toISOString()
          getVesting()
        }
      )

      onMounted(async () => {
        isLoading.value = true

        await store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id,
          filter: {}
        })

        onPageChange(1)
      })
      return {
        currency,
        optionPlanHistory,
        mode,
        contacts,
        columns,
        availableColumns,
        exerciceCalendars,
        exercisableQuantity,
        obsoleteQuantity,
        ceil,
        displayColumnsSelector,
        optionId,
        selectedColumns,
        delegations,
        heldBalance,
        exercisedQuantity,
        lastStockPrice,
        exercisePrice,
        portentialGainAtDate,
        potentialGain100Percent,
        total,
        optionAliases,
        filter,
        isLoading,
        download,
        moment,
        totalExercisePrice,
        resetFilters,
        NumberType,
        formatNumber,
        handleFilterSelected,
        getOrder,
        gotoEvent,
        rgbToHex
      }
    }
  })
