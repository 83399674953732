<template>
  <div id="target" class="h-full p-0 flex justify-between">
    <DocumentTreeview
      ref="documentTreeview"
      :company-id="$route.params.companyId"
    />
    <div class="flex w-full min-h-full">
      <div
        v-if="isLoading"
        class="w-full h-full flex justify-center items-center"
      >
        <Loader />
      </div>
      <div
        v-else-if="event"
        class="
          event-detail
          w-full
          h-full
          min-h-full
          px-12
          pt-8
          pb-10
          overflow-y-scroll
        "
      >
        <div class="flex justify-end">
          <div
            v-if="isPortfolioRoute"
            class="flex items-center text text-blackp text-xl py-8"
          >
            <p class="px-2 font-semibold">{{ companyName }}</p>
            <div
              class="w-10 h-10 rounded-full bg-cover bg-primary1"
              :style="`background-image: url(${
                companyImage ?? require('@/assets/images/profile/company.png')
              })`"
            ></div>
          </div>
          <div
            v-else
            v-can.any="['admin', 'is-manager', 'write-events']"
            class="flex my-3"
          >
            <Button
              v-if="!isNotEditable"
              variant="custom"
              class="text-sm flex items-center mr-2 bg-white"
              @click="editEvent"
            >
              <i class="bi bi-pen text-md"></i>
              <span class="text-sm px-2">{{
                $t('events.actionList.edit')
              }}</span>
            </Button>
            <Button
              variant="custom"
              class="text-sm flex items-center bg-error text-white"
              @click="canDelete = true"
            >
              <i class="bi bi-trash text-md text-white"></i>
              <span class="text-sm px-2">{{
                $t('events.actionList.delete')
              }}</span>
            </Button>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div class="">
            <h3 class="text-left text-secondary9">
              {{ $t(`events.labels.${event?.type}`) }}
              <span class="text-xs text-primary4">{{
                moment.utc(event?.date).format($t('dateFormat'))
              }}</span>
            </h3>
          </div>
          <div class="actions">
            {{ $t('events.fieldsLabels.createdAt') }} :
            <strong class="">{{
              moment.utc(event?.createdAt).locale(lang).format('LLLL')
            }}</strong>
          </div>
        </div>

        <div class="mt-5 h-full">
          <div class="w-full h-full overflow-y-scroll">
            <div class="h-full">
              <Tab :tabs="tabList" @on-updated="eventUpdated" />
            </div>
          </div>
        </div>
        <transition name="modal">
          <Modal v-if="canDelete" size="w-2/5" @close="canDelete = false">
            <template #header>
              <h3 class="text-md font-semibold text-error">
                {{ $t('events.labels.deleteModalTextHeader') }}
              </h3>
            </template>

            <template #body>
              <p
                class="select-none"
                v-html="$t('events.labels.deleteModalText')"
              ></p>
            </template>
            <template #footer>
              <div class="flex text-xl justify-end">
                <Button
                  class="flex text-white bg-primary rounded mr-2"
                  variant="custom"
                  :label="$t('account.accountCompanies.deletionModalCancel')"
                  @click="canDelete = false"
                />
                <Button
                  class="flex text-white bg-error rounded"
                  variant="custom"
                  :label="$t('account.accountCompanies.deletionModalText')"
                  @click.prevent="submitDeletion"
                />
              </div>
            </template>
          </Modal>
        </transition>
      </div>
      <div v-else>-</div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { Button } from '@up.law/uplaw-ui'
  import Loader from '@/components/Loader/index.vue'
  import moment from 'moment'
  import { EventType } from '@/types/event'
  import { useI18n } from 'vue-i18n'
  import { Document, Event } from '@/services/api/models'
  import Modal from '@/components/Modal/Modal.vue'
  import DocumentTreeview from '@/components/DocumentTree/index.vue'
  import EventOperation from './DetailTabs/operation.vue'
  import EventDocuments from './DetailTabs/documents.vue'
  import EventAudits from './DetailTabs/audits.vue'
  import Tab from '@/components/Tab/index.vue'

  export default defineComponent({
    components: {
      Loader,
      DocumentTreeview,
      Tab,
      Button,
      Modal
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()

      const nominalValue = ref(0)
      const event = ref<Event | null>()

      const lang = computed(() => store.getters['config/lang'])
      const companyImage = ref<null | string>()
      const companyName = ref<null | string>()

      const isLoading = ref(false)
      const makeLineEditable = ref(false)

      const canDelete = ref(false)

      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const isNotEditable = computed(
        () =>
          event.value?.type &&
          [
            EventType.optionSunset.toString(),
            EventType.optionExercise.toString(),
            EventType.transfer.toString(),
            EventType.stockIssuance.toString(),
            EventType.optionIssuance.toString(),
            EventType.compositionIssuance.toString()
          ].includes(event.value?.type.toString())
      )

      const documentsCount = ref(0)

      const allParts = computed<any[]>(
        () =>
          event.value?.optionIssuance?.optionIssuanceParts ||
          event.value?.stockIssuance?.stockIssuanceParts ||
          event.value?.conversion?.conversionParts ||
          event.value?.compositionIssuance?.compositionIssuanceParts ||
          event.value?.optionExercise?.optionExerciseParts ||
          event.value?.transfer?.transferParts ||
          event.value?.capitalReduction?.capitalReductionParts ||
          []
      )

      const tabList = computed(() => [
        {
          key: 'operation',
          langKey: 'eventOperation',
          component: EventOperation,
          componentProps: { canEditParts: makeLineEditable.value }
        },
        {
          key: 'documents',
          langKey: 'eventDocuments',
          count:
            documentsCount.value +
            allParts.value.reduce(
              (acc, p) => (acc += p?.documents?.length || 0),
              0
            ),
          component: EventDocuments
        },
        {
          key: 'audits',
          count: event.value?.eventAudits?.length,
          langKey: 'eventAudits',
          component: EventAudits
        }
      ])

      const editEvent = () => {
        router.push({
          name: 'company.eventUpdate',
          params: { id: route.params.id, eventId: route.params.eventId }
        })
      }

      const getEvent = (eventId = route.params.eventId) => {
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'

        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId,
          onSuccess: async (data: Event | null) => {
            event.value = data
            companyName.value = data?.company?.name
            loadImage(data?.company?.id)
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const eventUpdated = () => {
        getEvent()
      }

      const setLineEdit = (value: boolean) => {
        makeLineEditable.value = value
      }

      const submitDeletion = () => {
        removeEvent(event.value?.id)
      }

      const removeEvent = (eventId = route.params.eventId) => {
        store.dispatch('organizations/DELETE_EVENT', {
          companyId: route.params.id,
          eventId,
          onSuccess: () => {
            router.push({
              name: 'company.corporateEvents',
              query: { type: 'event' },
              params: { id: route.params.id }
            })
          }
        })
      }

      const loadImage = async (id?: string) => {
        if (!id) return
        if (store.getters['portfolio/companiesLogos'][id]) {
          companyImage.value = store.getters['portfolio/companiesLogos'][id]
        } else {
          const response = await store.dispatch(
            `organizations/LOAD_COMPANY_LOGO`,
            {
              companyId: id
            }
          )
          if (response) {
            companyImage.value = response
            store.commit('portfolio/SET_COMPANIES_LOGOS', {
              companyId: id,
              value: response
            })
          }
        }
      }

      const getEventDocuments = (eventId = route.params.eventId, page = 1) => {
        if (!eventId) return
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT_DOCUMENTS`, {
          companyId: route.params.id,
          eventId,
          page,
          onSuccess: (response: any) => {
            const { metadata } = response
            documentsCount.value = metadata.count
          },
          onError: () => {
            // isLoading.value = false
          }
        })
      }
      const documents = computed<Document[]>(
        () => store.getters['organizations/attachedFiles']
      )

      onMounted(async () => {
        getEvent()
        getEventDocuments()
        store.commit('organizations/ATTACH_FILES_TO_EVENT', { files: [] })
      })

      onBeforeMount(() => {
        window.addEventListener('beforeunload', (e) => {
          console.log('beforeunload', e)
          const answer = window.confirm(
            'Do you really want to leave? you have unsaved changes!'
          )
          // cancel the navigation and stay on the same page
          if (!answer) return false
          // if (!this.isEditing) return
          e?.preventDefault()
          // Chrome requires returnValue to be set.
          e.returnValue = ''
        })
      })

      onBeforeRouteLeave((to, from) => {
        if (!makeLineEditable.value) return
        const answer = window.confirm(
          'Do you really want to leave? you have unsaved changes!'
        )
        // cancel the navigation and stay on the same page
        if (!answer) return false
      })

      watch(
        () => route.params.id,
        (val) => {
          if (val && !isPortfolioRoute.value)
            router.push({
              name: 'company.corporateEvents',
              params: { id: val },
              query: { type: 'event' }
            })
        }
      )

      watch(
        () => route.params.eventId,
        (val) => {
          if (val) getEvent(val)
        }
      )

      return {
        editEvent,
        event,
        tabList,
        EventType,
        isLoading,
        moment,
        makeLineEditable,
        lang,
        canDelete,
        documents,
        currentDocument,
        companyName,
        nominalValue,
        isPortfolioRoute,
        companyImage,
        isNotEditable,
        eventUpdated,
        getEvent,
        setLineEdit,
        submitDeletion
      }
    }
  })
</script>

<style lang="scss" scoped>
  .event-detail {
    .actions {
      padding: 5px !important;
      .document-btn {
        @apply p-1 w-8 h-8 mx-1 border border-secondary5 rounded flex justify-center bg-white cursor-pointer items-center;
      }
    }
  }
</style>
