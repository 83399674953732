<template>
  <div class="h-full">
    <div class="h-full relative flex flex-col justify-between">
      <!-- <Field v-slot="{ field }" v-model="search" type="text" name="search">
        <Input
          v-bind="field"
          class="h-10"
          :disabled="false"
          :placeholder="$t('global.search')"
        >
        </Input>
      </Field> -->

      <div class="alias-list h-4/5 overflow-scroll">
        <div
          v-for="[item, templates] in groupedTemplates"
          :key="item"
          class="flex flex-col justify-start mb-2"
        >
          <div class="">
            <p class="uppercase text-secondary7 font-semibold mt-2 text-sm">
              {{ $t(`events.labels.${item}`) }}
            </p>
          </div>
          <div class="pl-2 w-full">
            <h3
              v-for="template in templates"
              :key="template.id"
              :class="`
              ${
                currentTemplate && template.id === currentTemplate.id
                  ? 'bg-secondary5'
                  : ''
              }
                text-blackp
                font-medium
                capitalize
                text-sm
                my-2
                hover:bg-secondary5
                rounded
                px-2
                py-1
                cursor-pointer
                transition`"
              @click="handleSelect(template.id)"
            >
              {{ template.label }}
            </h3>
          </div>
        </div>
      </div>
      <div v-if="canDo" class="bottom-container bg-white text-xl">
        <Button
          :disabled="false"
          :label="$t('docAutomation.form.new')"
          size="medium"
          variant="secondary"
          class="w-full h-10 flex items-center justify-center text-xl"
          @click="initStockForm"
        >
          <i class="bi bi-plus text-md"></i>
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { DocAutoTemplate } from '@/store/modules/Organization/docAutoModel'
  import { useStore } from 'vuex'
  import { useAcl } from 'vue-simple-acl/src'
  import { groupItemBy, sortObjectByKeys } from '@/utils'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: { Button },
    setup() {
      const search = ref('')
      const store = useStore()
      const acl = useAcl()
      const route = useRoute()
      const currentTemplate = computed<DocAutoTemplate | null>(
        () => store.getters['organizations/template']
      )
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts'))
      )

      const templates = computed<DocAutoTemplate[]>(
        () => store.getters['organizations/templates']
      )

      const groupedTemplates = computed<Map<string, any>>(() =>
        sortObjectByKeys(
          groupItemBy(
            templates.value,
            (item: DocAutoTemplate) => item.eventType
          )
        )
      )

      const initStockForm = () => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true
        })
        store.commit('organizations/SET_CURRENT_TEMPLATE', null)
      }

      const handleSelect = (id: string) => {
        store.dispatch('organizations/GET_TEMPLATE', {
          companyId: route.params.id,
          templateId: id,
          onSuccess: () => {
            console.log()
          },
          onError: () => {
            console.log()
          }
        })
      }

      const getTemplates = () => {
        store.dispatch('organizations/GET_TEMPLATES', {
          companyId: route.params.id,
          onSuccess: () => {
            console.log()
          },
          onError: () => {
            console.log()
          }
        })
      }

      onMounted(() => {
        getTemplates()
      })

      return {
        search,
        groupedTemplates,
        canDo,
        currentTemplate,
        initStockForm,
        handleSelect
      }
    }
  })
</script>
