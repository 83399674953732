<template>
  <div class="w-full h-full ownership flex flex-col">
    <div class="flex justify-between my-5">
      <div class="flex w-1/2 xl:w-1/3 lg:w-1/3 h-10 mr-2">
        <DatePicker
          v-model="filter.date"
          class="max-w-xs rounded text-xs h-10 mr-2"
          placeholder="--/--/----"
        />
        <Field
          v-slot="{ field }"
          v-model="filter.optionAliasId"
          name="optionAliasId"
        >
          <Multiselect
            :can-add-new="false"
            open-direction="bottom"
            :has-label="false"
            mode="single"
            :options="optionAliases"
            :placeholder="$t('global.placeholder.option')"
            name="optionAliasId"
            :groups="false"
            v-bind="field"
          />
        </Field>
      </div>

      <div v-if="!isDownloading">
        <DownloadButton class="mr-2" :on-click="download"></DownloadButton>
      </div>

      <el-tooltip
        v-else-if="isDownloading"
        class="box-item"
        effect="dark"
        :content="$t('tooltips.downloading')"
        placement="top"
      >
        <div
          class="
            border
            cursor-not-allowed
            rounded-3
            bg-white
            border-secondary6
            px-3
            flex
            items-center
          "
        >
          <i class="bi bi-arrow-clockwise text-xl text-secondary8 rotating"></i>
        </div>
      </el-tooltip>
    </div>

    <div class="mb-5 mt-2 text-sm flex flex-col h-full w-full">
      <div v-if="isLoading" class="flex h-full justify-center items-center">
        <Loader />
      </div>
      <DataTable
        v-else-if="data.length > 0"
        :columns="columns"
        :is-filtering="isFiltering"
        :rows="data"
        thead-class="sticky  top-0"
        :pagination="pagination"
        @onPageChange="onPageChange"
        @on-filtered="handleFiltering"
      >
        <template #stock="{ row }">
          <Tag
            :value="row.id"
            :text="row.alias.toUpperCase()"
            :with-initials="false"
            :with-remove="false"
            :color="rgbToHex(row?.colorCode)"
          />
        </template>

        <template #allocatedQuantity="{ row }">
          <Number :value="row.assignedQuantity" :mode="NumberType.quantity" />
        </template>

        <template #exercisableQuantity="{ row }">
          <Number :value="row.vestedQuantity" :mode="NumberType.quantity" />
        </template>
        <template #ceil="{ row }">
          <Number
            :value="row.exercisableQuantity"
            :mode="NumberType.quantity"
          />
        </template>
        <template #lapsedQuantity="{ row }">
          <Number
            :value="row.numberOfSunsetedOptions"
            :mode="NumberType.quantity"
          />
        </template>
        <template #exercisedQuantity="{ row }">
          <Number :value="row.exercisedQuantity" :mode="NumberType.quantity" />
        </template>
        <template #heldBalance="{ row }">
          <Number
            :value="
              row.assignedAmount - row.vestedQuantity - row.exercisedQuantity
            "
            :mode="NumberType.quantity"
          />
        </template>
        <template #exercisePrice="{ row }">
          <Number :value="row.exercisePrice" :mode="NumberType.price" />
        </template>

        <template #lastActionPrice="{ row }">
          <Number :value="row.lastStockPrice" :mode="NumberType.price" />
        </template>
        <template #pv="{ row }">
          <Number
            :zero-presents="row.potentialGain100Percent !== undefined ? 0 : '-'"
            :value="
              row.potentialGain100Percent < 0 ? 0 : row.potentialGain100Percent
            "
            :mode="NumberType.price"
          />
        </template>

        <template #total>
          <tr>
            <td colspan="1">
              <p class="text-right font-bold py-3 px-2 text-primary8">Total</p>
            </td>
            <td></td>
            <td>
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="total"
                :mode="NumberType.quantity"
              />
            </td>
            <td>
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="exercisedQuantity"
                :mode="NumberType.quantity"
              />
            </td>
            <td>
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="obsoleteQuantity"
                :mode="NumberType.quantity"
              />
            </td>
            <td>
              <Number
                :classes="`text-left font-bold py-3 px-2 ${
                  vestedQuantity < 0 ? 'text-error' : 'text-primary8'
                }`"
                :value="vestedQuantity"
                :mode="NumberType.quantity"
              />
            </td>

            <td>
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="ceil"
                :mode="NumberType.quantity"
              />
            </td>
          </tr>
        </template>
      </DataTable>
      <div
        v-else
        class="
          flex
          justify-left
          items-left
          w-full
          mt-4
          transition transition-all
        "
      >
        <EmptyState
          image="no-result.svg"
          class="d-block mx-auto"
          :title="$t('global.noResult')"
          :subtitle="$t('global.noData')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Tag } from '@up.law/uplaw-ui'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import { VestingSynthesis } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { formatNumber, rgbToHex } from '@/utils'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import Number from '@/components/Number/index.vue'
  import { NumberType } from '@/types'
  import { ElTooltip } from 'element-plus'
  export default defineComponent({
    components: {
      Field,
      DataTable,
      Tag,
      EmptyState,
      Multiselect,
      Loader,
      ElTooltip,
      DownloadButton,
      DatePicker,
      Number
    },
    setup() {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()
      const currency = computed(() => store.getters['organizations/currency'])

      const pagination = computed(
        () => store.getters['organizations/exerciseCalendarSynthesisPagination']
      )

      const filter = ref({
        optionAliasId: '',
        date: new Date().toISOString()
      })
      const columns = computed(() => [
        {
          label: t('events.fieldsLabels.stock'),
          field: 'stock',
          tooltipText: t('events.tooltips.stock'),
          tooltipWidth: '12%',
          hasTooltip: true,
          custom: true
        },
        {
          label: t('events.fieldsLabels.exercisePrice'),
          field: 'exercisePrice',
          custom: true,
          tooltipText: t('events.tooltips.exercisePrice'),
          tooltipWidth: '12%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.assignedQuantity'),
          field: 'allocatedQuantity',
          custom: true,
          width: '2%',
          tooltipText: t('events.tooltips.assignedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.exercisedQuantity'),
          field: 'exercisedQuantity',
          custom: true,
          tooltipText: t('events.tooltips.exercisedQuantity'),
          tooltipWidth: '12%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.lapsedQuantity'),
          field: 'lapsedQuantity',
          custom: true,
          tooltipText: t('events.tooltips.lapsedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.exercisableQuantity'),
          field: 'exercisableQuantity',
          custom: true,
          tooltipText: t('events.tooltips.vestedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true
        },
        {
          label: t('events.fieldsLabels.balance'),
          field: 'ceil',
          custom: true,
          tooltipText: t('events.tooltips.exercisableBalance'),
          tooltipWidth: '20%',
          hasTooltip: true
        }
      ])

      const data = computed<VestingSynthesis[]>(
        () => store.getters['organizations/vestingSyntheses']
      )

      const optionAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )
      const isDownloading = ref(false)

      const total = computed(() =>
        data.value.reduce((acc, c) => (acc += c?.assignedQuantity), 0)
      )

      const vestedQuantity = computed(() =>
        data.value.reduce((acc, c) => (acc += c.vestedQuantity), 0)
      )

      const ceil = computed(() =>
        data.value.reduce((acc, row) => (acc += row.exercisableQuantity), 0)
      )

      const obsoleteQuantity = computed(() =>
        data.value.reduce((acc, c) => (acc += c.numberOfSunsetedOptions), 0)
      )
      const exercisedQuantity = computed(() =>
        data.value.reduce((acc, c) => (acc += c.exercisedQuantity), 0)
      )

      const heldBalance = computed(() => total.value - vestedQuantity.value)

      const exercisePrice = computed(() =>
        data.value.reduce((acc, c) => (acc += c.exercisePrice), 0)
      )

      const potentialGain100Percent = computed(() =>
        data.value.reduce(
          (acc, row) =>
            (acc +=
              row.potentialGain100Percent < 0
                ? 0
                : row.potentialGain100Percent),
          0
        )
      )

      const lastStockPrice = computed(() =>
        data.value.reduce((acc, c) => (acc += c.lastStockPrice), 0)
      )
      const isLoading = ref(false)
      const isFiltering = ref(false)
      const handleFiltering = (value: boolean) => {
        isFiltering.value = value
      }
      const onPageChange = (page: number) => {
        getVesting(page)
      }

      const getVesting = async (page = 0) => {
        await store.dispatch('organizations/GET_VESTING_SYNTHESIS', {
          companyId: route.params.id,
          filter: {
            ...filter.value,
            page
          },
          onSuccess: () => {
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }
      const download = () => {
        isDownloading.value = true
        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_EXERCISE_CALENDARS_BY_OPTION', {
          name: `${t('exports.shareholding')} `,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          }
        })
      }

      watch(
        () => route.params.id,
        () => {
          getVesting()
        }
      )

      watch(
        () => filter.value.optionAliasId,
        (val) => {
          isFiltering.value = true
          filter.value.optionAliasId = val
          getVesting()
        }
      )

      watch(
        () => filter.value.date,
        (val) => {
          isFiltering.value = true

          filter.value.date = val
          getVesting()
        }
      )

      onMounted(() => {
        isLoading.value = true
        getVesting()
      })

      return {
        filter,
        data,
        pagination,
        optionAliases,
        currency,
        exercisePrice,
        lastStockPrice,
        heldBalance,
        handleFiltering,
        isFiltering,
        onPageChange,
        exercisedQuantity,
        total,
        ceil,
        isDownloading,
        potentialGain100Percent,
        NumberType,
        obsoleteQuantity,
        columns,
        vestedQuantity,
        isLoading,
        formatNumber,
        download,
        rgbToHex
      }
    }
  })
</script>
<style lang="scss">
  .sticky {
    background: #f5f7fa;
  }
  .ownership {
    .stock {
      padding: 5px !important;
    }
    .user {
      padding: 5px !important;
    }
  }
</style>
