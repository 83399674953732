
  import { IntegratedService, IntegrationStatus } from '@/services/api/models'
  import { GlobalConfig, IntegrationType } from '@/types/config'
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { useStore } from 'vuex'
  import conf from '@/config'

  export default defineComponent({
    components: {},
    setup() {
      const config = conf as GlobalConfig
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const { t } = useI18n()
      const company = computed(
        () => store.getters['organizations/contextCompany']
      )

      const docuSign = ref<IntegratedService | null>()
      const docusignStep = ref(0)
      const docuSignCompaniesToLink = computed(() => [
        route.params?.id ?? company.value?.id
      ])
      const docusignConsentLink = computed(
        () =>
          `${
            config.docuSign.host
          }?response_type=code&state=${docuSignCompaniesToLink.value.toString()}&scope=openid&client_id=${
            config.docuSign.clientId
          }&redirect_uri=${config.docuSign.redirectUri}`
      )

      const get = () => {
        if (!route.params?.id && !company.value?.id) return
        store.dispatch('organizations/GET_INTEGRATED_SERVICES', {
          companyId: route.params?.id ?? company.value?.id,
          onSuccess: (response: IntegratedService[]) => {
            docuSign.value = response.find(
              (x) => x.integrationType == IntegrationType.docusign
            )
          },
          onError: () => {
            ///
          }
        })
      }

      const save = () => {
        if (route.fullPath.split('?next').length <= 1) return
        store.dispatch('organizations/SAVE_INTEGRATED_SERVICE_DATA', {
          companyId:
            route.query.state?.toString() ??
            route.query?.state ??
            route.params?.id ??
            company.value?.id,
          data: {
            integrationType: IntegrationType.docusign,
            authorizationCode: route.query.code?.toString()
          },
          onSuccess: () => {
            get()
            const q = { ...route.query }
            delete q.state
            delete q.code

            const p: any = { id: q.state }

            router.push({
              name: route.name ?? '',
              params: { ...p },
              query: { ...q }
            })
          },
          onError: () => {
            //
          }
        })
      }

      const deleteService = () => {
        store.dispatch('organizations/DELETE_INTEGRATED_SERVICE', {
          companyId: company.value.id,
          service: IntegrationType.docusign,

          onSuccess: () => {
            get()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('thirdPartyServices.form.title'),
              message: t(`thirdPartyServices.apiResponse.service_deleted`)
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('thirdPartyServices.form.title'),
              message: t(
                `thirdPartyServices.apiResponse.service_deletion_error`
              )
            })
          }
        })
      }

      onMounted(() => {
        get()
        save()
      })

      return {
        company,
        IntegrationStatus,
        docusignStep,
        docuSign,
        docusignConsentLink,
        docuSignCompaniesToLink,
        deleteService
      }
    }
  })
