<template>
  <div :class="[{ 'contactCard--extended': noPadding }, ' contactCard']">
    <div class="contactCard__visual">
      <div
        v-if="isLoadingImage"
        class="flex justify-center items-center w-full h-full"
      >
        <svg
          id="L4"
          style="
            -webkit-transform: translateX(25%);
            -ms-transform: translateX(25%);
            transform: translateX(25%);
          "
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enable-background="new 0 0 0 0"
          xml:space="preserve"
        >
          <circle fill="#fff" stroke="none" cx="6" cy="50" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle fill="#fff" stroke="none" cx="26" cy="50" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle fill="#fff" stroke="none" cx="46" cy="50" r="6">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </div>
      <img class="object-cover w-full h-full" v-else :src="imageUrl" />
    </div>

    <div class="contactCard__content">
      <div class="contactCard__content__information_lvl-1">
        <div class="flex flex-col items-start">
          <h2 class="contactCard__content__information_lvl-1__name capitalize">
            {{ individualContact.name }}
            <span class="text-sm text-primary7 font-thin" v-if="isMinor"
              >({{
                $t('contactBook.fields.minor', {
                  date: dateOfMajority,
                  suffix: [GenderEnum.mr, GenderEnum.other].includes(
                    individualContact.gender
                  )
                    ? ''
                    : 'e'
                })
              }})</span
            >

            <el-tooltip
              class="box-item"
              effect="dark"
              :content="$t('tooltips.contactInfo')"
              placement="top"
            >
              <span class="ml-2">
                <i
                  :class="`bi bi-${
                    display ? 'info-circle' : 'info-circle-fill'
                  } text-xl text-primary4`"
                  @click="display = !display"
                ></i>
              </span>
            </el-tooltip>
          </h2>
          <div
            v-if="individualContact.group"
            class="contactCard__content__information_lvl-1__role"
          >
            {{ individualContact.group.name }}
          </div>
        </div>
        <ActionList
          v-can.any="['admin', 'write-contacts', 'employee-writeContact']"
          class="right h-1/4 p-2"
          :actions="actionsListItems"
          @on-click="handleAction"
          iconClass="bi-three-dots text-md text-primary3"
        />
      </div>

      <transition name="collapse">
        <div v-show="display" class="contactCard__content__information_lvl-2">
          <div
            class="
              row
              contactCard__content__information_lvl-2__infos
              flex
              justify-between
              w-full
            "
          >
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.nationality') }}</label>
              <p v-if="individualContact.nationality">
                {{ individualContact.nationality }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.birthDate') }}</label>
              <p v-if="individualContact.birthDate" class="text">
                {{ moment.utc(individualContact.birthDate).format('LL') }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.birthPlace') }}</label>
              <p v-if="individualContact.birthPlace">
                {{ individualContact.birthPlace }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.address') }}</label>
              <p v-if="individualContact.address">
                {{ individualContact.address }} -
                {{ individualContact.postcode }}
                {{ individualContact.city }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div
              v-if="individualContact.familyBranch1"
              class="col-md-2 form-group flex flex-col items-start"
            >
              <label>{{ $t('contactBook.fields.familyBranch1') }}</label>
              {{ individualContact.familyBranch1 }}
            </div>
            <div
              v-if="individualContact.familyBranch2"
              class="col-md-2 form-group flex flex-col items-start"
            >
              <label>{{ $t('contactBook.fields.familyBranch2') }}</label>
              {{ individualContact.familyBranch2 }}
            </div>
          </div>

          <div
            class="
              row
              contactCard__content__information_lvl-2__infos
              contactCard__content__information_lvl-2__infos
              flex
              justify-between
              w-full
            "
          >
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.maritalStatus') }}</label>
              <p v-if="individualContact.familySituation">
                {{ $t(`contactCard.${individualContact.familySituation}`) }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col-md-2 form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.phoneNumber') }}</label>
              <p v-if="individualContact.phoneNumber">
                {{ individualContact.phoneNumber }}
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col form-group flex flex-col items-start">
              <label>{{ $t('contactBook.fields.email') }}</label>
              <p v-if="individualContact.email">
                <a :href="`mailto:${individualContact.email}`">{{
                  individualContact.email
                }}</a>
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div
              v-if="individualContact?.parents?.length > 0"
              class="col form-group flex flex-col items-start"
            >
              <label>{{ $t('contactBook.fields.parents') }}</label>
              <p v-if="individualContact.parents">
                <a
                  v-for="parent in individualContact.parents"
                  :key="parent.id"
                  class="cursor-pointer contact px-1"
                  @click="goToContact(parent.id)"
                >
                  {{ parent.name }}
                  ,
                </a>
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div
              v-if="individualContact?.children?.length > 0"
              class="
                cursor-pointer
                col
                form-group
                flex flex-col
                items-start
                contact
              "
            >
              <label>{{ $t('contactBook.fields.children') }}</label>
              <p v-if="individualContact.children">
                <a
                  v-for="child in individualContact.children"
                  :key="child.id"
                  class="px-1"
                  @click="goToContact(child.id)"
                >
                  {{ child.name }}</a
                >
              </p>
              <i v-else class="bi-dash" />
            </div>
            <div class="col form-group flex flex-col items-start">
              <label>{{
                $t('contactBook.fields.representativeContact')
              }}</label>
              <p v-if="individualContact.representativeContact">
                <router-link
                  v-if="individualContact.representativeContact"
                  :to="{
                    name: 'company.directory',
                    params: {
                      id: currentCompany.id,
                      contactId: individualContact.representativeContact.id
                    }
                  }"
                >
                  <ContactBadge
                    :contact="individualContact.representativeContact"
                    class="flat light"
                    type="light"
                  />
                </router-link>
              </p>
              <i v-else class="bi-dash" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
  import { useStore } from 'vuex'
  import ActionList from '@/components/ActionList/index.vue'
  import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'
  import { CompanyModel, GenderEnum } from '@/services/api/models'
  import { useRoute, useRouter } from 'vue-router'
  import { ActionListItem, MAINACTIONS } from '@/types'
  import { useAcl } from 'vue-simple-acl/src'
  import { ElTooltip } from 'element-plus'
  import ContactBadge from '@/components/ContactBadge/index.vue'

  export default defineComponent({
    name: 'ContactCard',
    components: {
      ElTooltip,
      ActionList,
      ContactBadge
    },
    directives: {},
    props: {
      contact: {
        type: Object,
        required: true
      },
      noPadding: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    emits: ['delete-contact'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const isMinor = computed(() =>
        props.contact.birthDate
          ? moment().diff(
              moment(props.contact.birthDate).startOf('day'),
              'years',
              true
            ) < 18
          : false
      )
      const dateOfMajority = computed(() =>
        isMinor.value
          ? moment(props.contact.birthDate).add(18, 'year').format('LL')
          : '-'
      )
      const individualContact = ref(props.contact)
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const isLoadingImage = ref(false)
      const url = ref()
      const imageUrl = computed(
        () => `${url.value || require('@/assets/images/profile/user.png')}`
      )
      const display = ref(true)
      const acl = useAcl()
      const canDelete = computed(
        () =>
          acl.role('admin') ||
          acl.permission('is-manager') ||
          acl.permission('write-contacts')
      )
      const actionsListItems = ref<ActionListItem[]>([
        {
          name: t('global.actions.edit'),
          action: 'edit',
          children: [],
          icon: '',
          key: MAINACTIONS.EDIT_CONTACT,
          type: ''
        }
      ])

      if (canDelete.value) {
        actionsListItems.value.push({
          name: t('global.actions.delete'),
          action: 'delete',
          children: [],
          icon: '',
          key: MAINACTIONS.DELETE_CONTACT,
          type: ''
        })
      }

      const goToContact = (parentId: string) => {
        router.push({
          name: 'company.directory',
          params: { id: currentCompany.value.id, contactId: parentId }
        })
      }

      const openContactModal = () => {
        router.push({
          name: 'company.contact',
          params: {
            id: currentCompany.value.id,
            contactId: route.params.contactId
          }
        })
      }
      const handleAction = (key: MAINACTIONS) => {
        if (key === MAINACTIONS.EDIT_CONTACT) {
          openContactModal()
        } else if (key === MAINACTIONS.DELETE_CONTACT) {
          deleteContact()
        }
      }
      const loadImage = async () => {
        url.value = ''
        isLoadingImage.value = true
        const response = await store.dispatch(
          'organizations/LOAD_CONTACT_PICTURE',
          {
            companyId: currentCompany.value.id,
            contactId: props.contact.id
          }
        )
        if (response) url.value = response
        else url.value = ''
        isLoadingImage.value = false
      }

      const deleteContact = () => {
        emit('delete-contact', props.contact.id)
      }
      watch(
        () => props.contact,
        async () => {
          await loadImage()
        }
      )
      watch(props, (newProps) => {
        individualContact.value = newProps.contact
      })

      onBeforeMount(async () => {
        await loadImage()
      })
      return {
        moment,
        url,
        dateOfMajority,
        imageUrl,
        currentCompany,
        isLoadingImage,
        actionsListItems,
        display,
        GenderEnum,
        isMinor,
        handleAction,
        goToContact,
        individualContact
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'contactCard.scss';

  .contact {
    &:hover {
      color: var(--color-primary);
    }
  }
</style>
