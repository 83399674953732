
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    ref,
    watch
  } from 'vue'
  import { Chart } from 'chart.js'
  import { useI18n } from 'vue-i18n'
  import { formatNumericalValue } from '@/utils/global'
  import Loader from '@/components/Loader/index.vue'

  type ChartType =
    | 'bar'
    | 'line'
    | 'scatter'
    | 'bubble'
    | 'pie'
    | 'doughnut'
    | 'polarArea'
    | 'radar'

  export default defineComponent({
    name: 'UplChart',
    components: { Loader },
    props: {
      id: {
        type: String,
        required: true
      },
      labels: {
        type: Array as PropType<string[]>,
        required: true
      },
      dataset: {
        type: Object as any,
        required: true
      },
      options: {
        type: Object as any,
        default: () => ({})
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        default: () => undefined,
        required: false
      },
      type: {
        type: String as PropType<ChartType>,
        required: true
      },
      legend: {
        type: Boolean,
        default: true
      },
      chartHeight: {
        type: Number,
        default: 300
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const { t } = useI18n()
      let chart = ref<HTMLCanvasElement | any>(null)
      const myChart = ref()

      const canDisplayChart = computed(() => {
        const [elm] = props.dataset
        return elm?.data?.length > 0
      })

      const noData = computed(() => {
        const [elm] = props.dataset

        return elm?.data?.length == 0
      })
      const backgroundColor = (data: number[], index: number): string => {
        const opacity = ((100 / data.length) * index) / 100

        if (index === 0) {
          return 'rgba(24,15,239, 0.1)'
        } else {
          return `rgba(24,15,239, ${opacity})`
        }
      }

      const datasets = props.dataset.reduce((acc: any, item: any) => {
        const el = item
        if (!el.backgroundColor) {
          el.backgroundColor = (context: any): string => {
            return backgroundColor(context.dataset.data, context.dataIndex)
          }
        }
        acc.push(el)
        return acc
      }, [])

      const generateChart = () => {
        myChart.value = new Chart(chart.value.getContext('2d'), {
          type: props.type,
          data: {
            labels: props.labels,
            datasets
          },
          options: {
            plugins: {
              legend: {
                display: false
              }
            },
            ...props.options
          }
        })
      }

      onMounted(() => {
        generateChart()

        console.log('IslLoading 4', props.isLoading)
      })

      watch(
        () => props.dataset,
        (val: any) => {
          console.log('proppspsps', props.dataset, props.labels)

          myChart.value.data.datasets = val
          Chart?.getChart(props.id)?.update()
        }
      )

      watch(
        () => props.labels,
        (val: any) => {
          myChart.value.data.labels = val
          Chart?.getChart(props.id)?.update()
          // isLoading.value = false
        }
      )

      watch(
        () => props.isLoading,
        (val: any) => {
          console.log('IslLoading', val)
        }
      )

      return {
        chart,
        canDisplayChart,
        noData,
        formatNumericalValue,
        backgroundColor
      }
    }
  })
