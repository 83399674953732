export enum EventType {
  establishment = 'ESTABLISHMENT', // constitution
  stockIssuance = 'STOCK_ISSUANCE', // emission primaire d'actions
  optionIssuance = 'OPTION_ISSUANCE', // emission primaire d'option
  compositionIssuance = 'COMPOSITION_ISSUANCE', // emission primaire de compositions
  transfer = 'TRANSFER', // transferts de titres
  financialEnding = 'FINANCIAL_ENDING', // clôture de l'exercice fiscal
  committee = 'COMMITTEE', // comités
  appointment = 'APPOINTMENT', // nomination
  nominalValue = 'NOMINAL_VALUE', // valeur nominale
  commongCeiling = 'COMMON_CEILING', // plafond commun
  vestingSuspension = 'VESTING_SUSPENSION', // suspension de vesting
  vestingAcceleration = 'VESTING_ACCELERATION', // accélération de vesting
  exerciseDelayProrogation = 'EXERCISE_DELAY_PROROGATION', // Prorogation du délais d'exercice
  delegation = 'DELEGATION', // délégation
  accountApproval = 'ACCOUNT_APPROVAL',
  capitalReduction = 'CAPITAL_REDUCTION',
  optionExercise = 'OPTION_EXERCISE',
  optionLapse = 'OPTION_LAPSE',
  appointmentTermination = 'APPOINTMENT_TERMINATION',
  conversion = 'CONVERSION',
  detachment = 'DETACHMENT',
  parityChange = 'PARITY_CHANGE',
  optionSunset = 'OPTION_SUNSET',
  delegationSunset = 'DELEGATION_SUNSET',
  commitment = 'COMMITMENT'
}
export enum DelegationStatus {
  pending = 'PENDING',
  expired = 'EXPIRED',
  canceled = 'CANCELED',
  sold_out = 'SOLD_OUT'
}

export enum OptionSunsetType {
  Quantity = 'QUANTITY',
  Percent = 'PERCENT'
}
export interface FieldExcludesData {
  value: string[]
  index: number
}
export interface FieldAllExcludesData {
  value: string[]
  currentIdex: number
}

export interface FieldWithChoice {
  id: string
  name: string
  value: string
  field: string
  defaultValue: string
}
export interface EventFormPrimitive {
  fieldId: string
  name: string
  label: string
  component: string
  type: string
  hint: string
  isMandatory: boolean
  isNullable?: boolean
  grid: string
  needs: string
  props?: {
    value?: any
    initialValue?: any
    label?: boolean
    categoryId?: string | null
    classes?: string
    choices?: FieldWithChoice[]
    disabled: boolean
    excludesId?: FieldExcludesData
    allExcludesId?: FieldAllExcludesData
    callback?: (field: any) => void
  }
  initChildrenOnChange?: boolean
  typeField?: string
  labelInfo?: string
  table?: boolean
  isUI?: boolean
  classes?: string
  isNeeded?: boolean
  needsValue?: any
  requiredIf?: any[]
  tableId?: string
  rows?: EventFormPrimitive[]
  custom: boolean
  customDefault: boolean
  isCalculated: boolean
}
export interface EventFormDefinition {
  eventId: string
  name: string
  primitives: EventFormPrimitive[]
}
