<template>
  <div
    class="
      flex flex-col
      items-start
      px-12
      w-full
      h-full
      overflow-y-scroll
      relative
    "
  >
    <div
      v-if="isLoading"
      class="flex w-full mt-10 justify-center items-center relative"
    >
      <Loader />
    </div>
    <div v-else-if="captable" id="captable-table" class="captable-table mt-2">
      <div class="flex items-center justify-start">
        <h3 class="text text-blackp text-2xl pt-8">
          {{ $t('menu.directory') }}
        </h3>
      </div>
      <div class="captable-filters my-5">
        <div class="filters md:w-5/6 lg:w-3/4 xl:w-2/3">
          <div class="filter">
            <multiselect
              v-model="selectedContact"
              :options="contacts"
              :value="selectedContact"
              :groups="false"
              name="contacts"
              class="h-10"
              elementName="contactBook.contacts"
              selectionText="global.actions.selected1"
              mode="multiple"
              :has-label="false"
              :filter-results="false"
              open-direction="bottom"
              placeholder="Contacts"
              @search-change="handleContactSearch"
              @change="updateContact"
            />
          </div>
          <div class="filter">
            <multiselect
              v-model="selectedGroup"
              :options="companyGroups"
              :groups="false"
              name="groups"
              :has-label="false"
              :value="selectedGroup"
              :placeholder="$t('contactSearch.group')"
              open-direction="bottom"
              classes="h-10"
              @change="updateGroup"
            />
          </div>
          <div class="filter">
            <dropdown-stocks
              v-model="selectedStocks"
              :data="{ name: 'stocks' }"
              :has-label="false"
              :can-add-new="false"
              open-direction="bottom"
              mode="multiple"
              class="min-h-10"
              :placeholder="$t('global.placeholder.stocks')"
              :value="selectedStocks"
              :option-per-price="false"
              @onUpdated="updateStock"
            />
          </div>
          <div class="filter">
            <multiselect
              v-model="selectedCategories"
              :value="selectedCategories"
              mode="multiple"
              class="min-h-full"
              open-direction="bottom"
              :tag-with-initials="false"
              elementName="documents.categories"
              selectionText="global.actions.selected2"
              :options="documentCategories"
              :groups="true"
              group-options-field="docSubCategories"
              :placeholder="$t('documents.types')"
              @change="updateDocumentType"
            />
          </div>
        </div>
        <div class="filters-date flex-1 flex justify-between">
          <div class="flex justify-end w-full">
            <div class="h-10 flex w-full justify-end">
              <!--filter columns-->
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="$t('tooltips.columns')"
                placement="top"
              >
                <Filter
                  class="relative w-14 mx-2 captable-filter"
                  :options="availableColumns"
                  :value="selectedColumns"
                  @on-change="handleFilterSelected"
                >
                  <template #option="{ option, checked }">
                    <Checkbox
                      size="w-5 h-5 mr-2"
                      :checked="checked"
                      :name="option.id"
                      @click.prevent.stop
                    />
                  </template>
                  <i class="bi bi-gear"></i>
                </Filter>
              </el-tooltip>
              <!-- export buttons -->
              <DownloadButton
                v-if="!isDownloading"
                class="mr-2 h-full"
                :has-children="false"
                :on-click="download"
              ></DownloadButton>

              <el-tooltip
                v-else-if="isDownloading"
                class="box-item"
                effect="dark"
                :content="$t('tooltips.downloading')"
                placement="top"
              >
                <div
                  class="
                    border
                    cursor-not-allowed
                    rounded-3
                    bg-white
                    border-secondary6
                    px-3
                    flex
                    items-center
                  "
                >
                  <i
                    class="
                      bi bi-arrow-clockwise
                      text-xl text-secondary8
                      rotating
                    "
                  ></i>
                </div>
              </el-tooltip>

              <!-- reset filter -->
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="$t('tooltips.resetFilter')"
                placement="top"
                ><Button
                  variant="custom"
                  class="rounded-sm dwnld-btn h-10 ml-2 bg-white"
                  @click="resetFilters"
                >
                  <i class="bi bi-filter text-2xl"></i> </Button
              ></el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <DataTable
        :rows="contactList"
        thead-class="sticky top-0"
        :columns="columns"
        text-size="text-sm"
        :has-checkbox="false"
        container-class="w-full"
        cell-padding="p-0"
        :pagination="pagination"
        @onPageChange="onPageChange"
      >
        <template #date="{ row }">
          <p class="text-left pl-1">
            {{ row.creationDate }}
          </p>
        </template>
        <template #associates="{ row }">
          <div class="flex items-center justify-start">
            <router-link
              class="cursor-pointer"
              :to="{
                name: 'company.directory',
                params: { id: $route.params.id, contactId: row.contactId }
              }"
            >
              <ContactBadge
                :use-picture="true"
                :contact="{
                  id: row.contactId,
                  name: row.contactName,
                  picture: row.picture,
                  stocksCount: 0,
                  documentsCount: 0
                }"
                class="flat light"
                type="light"
              />
            </router-link>
          </div>
        </template>
        <template #type="{ row }">
          <p class="text-left pl-1">
            {{ row.type }}
          </p>
        </template>
        <template #groups="{ row }">
          <p
            v-for="(group, index) in row.groups"
            :key="index"
            class="text-left pl-1"
          >
            {{ group }}
          </p>
        </template>
        <template #email="{ row }">
          <p class="text-left pl-1">
            {{ row.email }}
          </p>
        </template>
        <template #birthDate="{ row }">
          <p class="text-left pl-1">
            {{ row.birthDate }}
          </p>
        </template>
        <template #phoneNumber="{ row }">
          <p class="text-left pl-1">
            {{ row.phoneNumber }}
          </p>
        </template>
        <template #address="{ row }">
          <p class="text-left pl-1">
            {{ row.address }}
          </p>
        </template>
        <template #stocks="{ row }">
          <div class="flex flex-wrap">
            <div
              v-for="(stock, sIndex) in row.stocks"
              :key="sIndex"
              class="px-1"
            >
              <el-tooltip
                v-if="stock.name"
                class="box-item text-xs"
                effect="dark"
                placement="top"
              >
                <template #content>
                  <div
                    v-if="stock.ownershipType != CoOwnershipType.usufruct"
                    class="p-1 text-white"
                  >
                    <h3 class="text-white text-sm">
                      NFD:
                      <span class="text-sm"
                        >({{ formatNumber(stock.quantityNFD) }})
                        {{ formatNumber(stock.percentageNFD) }} %</span
                      >
                    </h3>
                    <h3 class="text-white text-sm">
                      FD:
                      <span class="text-sm"
                        >({{ formatNumber(stock.quantityFD) }})
                        {{ formatNumber(stock.percentageFD) }} %</span
                      >
                    </h3>
                  </div>
                  <div v-else>
                    <h3 class="text-white text-sm">
                      {{ $t('captableTag.quantity') }}:
                      <span class="text-sm"
                        >{{ formatNumber(stock.nonDilutiveQuantity) }}
                      </span>
                    </h3>
                  </div>
                </template>
                <Tag
                  v-if="stock.name"
                  :class="`${
                    stock.quantityFD == 0 &&
                    stock.quantityNFD == 0 &&
                    (stock.nonDilutiveQuantity == 0 ||
                      !stock.nonDilutiveQuantity)
                      ? 'dashed-border bg-white'
                      : ''
                  }`"
                  :id="stock.id"
                  :value="stock.name"
                  :text="`${stock.name.toUpperCase()}${
                    stock.ownershipType &&
                    stock.ownershipType !== CoOwnershipType.fullOwnership
                      ? ` (${$t(`CoOwnershipType.${stock.ownershipType}`)})`
                      : ``
                  }`"
                  :with-initials="false"
                  :with-remove="false"
                  :color="`${
                    stock.quantityFD == 0 &&
                    stock.quantityNFD == 0 &&
                    (stock.nonDilutiveQuantity == undefined ||
                      stock.nonDilutiveQuantity == 0)
                      ? ``
                      : rgbToHex(stock.colorCode)
                  }`"
                />
              </el-tooltip>
              <p v-else class="text-left">-</p>
            </div>
          </div>
        </template>
      </DataTable>
      <div class="py-3 px-2 flex justify-start">
        <p class="">
          {{ $t('global.lineCount', { count: pagination.total }) }}
        </p>
      </div>
    </div>
    <div v-else class="flex justify-center items-center w-full mt-4">
      <EmptyState
        class="d-block mx-auto datatable"
        :title="$t('global.noResult')"
        :subtitle="$t('global.selectFilters')"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute, useRouter } from 'vue-router'
  import Multiselect from '@/components/Select/multiselect.vue'
  import {
    CoOwnershipType,
    Contact,
    ContactStatus,
    User
  } from '@/services/api/models'
  import EmptyState from '@/components/EmptyState/index.vue'
  import moment from 'moment'
  import { formatNumber, rgbToHex } from '@/utils/global'
  import { useI18n } from 'vue-i18n'
  import { STOCKTYPE, NumberType } from '@/types'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { ElTooltip } from 'element-plus'
  import { CompanyModel } from '@/services/api/models'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    name: 'ContactList',
    components: {
      DataTable,
      Multiselect,
      Tag,
      DownloadButton,
      Button,
      EmptyState,
      ContactBadge,
      DropdownStocks,
      Loader,
      ElTooltip,
      Filter,
      Checkbox
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const route = useRoute()
      const selectedContact = ref<string[]>([])
      const selectedGroup = ref('')
      const selectedStocks = ref<string[]>([])
      const selectedCategories = ref<string[]>([])
      const picked = ref<any>(new Date())
      const selectedColumns = ref<string[]>([])
      const isDownloading = ref(false)
      const acl = useAcl()
      const router = useRouter()

      const captableColumns = computed(() => [
        {
          label: t(`datatable.column.creationDate`),
          field: 'date',
          custom: true,
          order: 1,
          width: '10%'
        },
        {
          label: t(`datatable.column.associates`),
          field: 'associates',
          custom: true,
          order: 2,
          selected: true,
          sortBy: 'contactName',
          type: 'text',
          width: '15%'
        },
        {
          label: t(`datatable.column.groups`),
          field: 'groups',
          custom: true,
          filterable: false,
          order: 3,
          selected: true,
          sortable: true,
          type: 'text',
          width: '10%'
        },
        {
          label: t(`datatable.column.type`),
          field: 'type',
          custom: true,
          filterable: true,
          order: 4,
          selected: true,
          sortable: true,
          type: 'text',
          width: '10%'
        },

        {
          label: t(`datatable.column.email`),
          field: 'email',
          custom: true,
          filterable: false,
          order: 5,
          selected: true,
          sortable: true,
          type: 'text',
          width: '15%'
        },
        {
          label: t(`datatable.column.birthDate`),
          field: 'birthDate',
          custom: true,
          filterable: true,
          order: 6,
          selected: true,
          sortable: true,
          type: 'text',
          width: '10%'
        },
        {
          label: t(`datatable.column.phoneNumber`),
          field: 'phoneNumber',
          custom: true,
          filterable: true,
          order: 7,
          selected: true,
          sortable: true,
          type: 'text',
          width: '10%'
        },
        {
          label: t(`datatable.column.address`),
          field: 'address',
          custom: true,
          filterable: true,
          order: 8,
          selected: true,
          sortable: true,
          type: 'text',
          width: '15%'
        },
        {
          label: t(`datatable.column.stocks`),
          field: 'stocks',
          custom: true,
          selected: true,
          order: 9,
          sortable: true,
          type: 'text',
          width: '30%'
        }
      ])
      const filter = ref<any>({
        compositionAliasesId: [],
        optionAliasesId: [],
        stockAliasesId: []
      })

      const includeDelegationsAvailableBalance = ref(false)

      const isFiltered = computed(
        () =>
          selectedStocks.value.length > 0 ||
          selectedContact.value ||
          selectedGroup.value ||
          selectedCategories.value
      )

      const documentCategories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const user = computed<User>(() => store.getters['auth/profile'])

      const contacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const isLoading = ref(false)
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const pagination = computed(
        () => store.getters['organizations/contactListPagination']
      )
      const onPageChange = (page: number) => {
        getContactsList(page)
      }
      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )

      const captable = computed(
        () => store.getters['organizations/contactsList']
      )
      const contactList = computed<any[]>(() =>
        captable.value.lines.map((x: any) => ({
          ...x,
          type: t(`contactType.${x.type}`)
        }))
      )

      const groups = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )

      const allColumns = computed(() =>
        isFiltered.value
          ? captableColumns.value
          : captableColumns.value.filter((c) => c.selected)
      )
      const existingColumns = computed(() =>
        allColumns.value.filter((x) => !x.filterable)
      )

      selectedColumns.value = []
      const columns = computed(() =>
        [
          ...allColumns.value.filter((x) =>
            selectedColumns.value.includes(x.field)
          ),
          ...existingColumns.value
        ].sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      const handleFilterSelected = (value: string[]) => {
        selectedColumns.value = value
      }

      const resetFilters = () => {
        filter.value = {
          compositionAliasesId: [],
          optionAliasesId: [],
          stockAliasesId: [],
          contactId: []
        }
        picked.value = new Date().toISOString()
        selectedStocks.value = []
        selectedContact.value = []
        selectedGroup.value = ''
        selectedCategories.value = []
      }

      const updateDocumentType = (documentTypeId: string[]) => {
        selectedCategories.value = documentTypeId
        filter.value.documentTypeId = documentTypeId
      }

      const updateStock = ({ value }: any) => {
        if (!value) {
          delete filter.value.optionAliasesId
          delete filter.value.stockAliasesId
          delete filter.value.compositionAliasesId
          getContactsList()
          return
        }
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        value.forEach((v: string) => {
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === v)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })

        selectedStocks.value = value
      }
      const updateGroup = (group: any) => {
        if (group === '') delete filter.value.contactGroupId
        else filter.value.contactGroupId = group

        selectedGroup.value = group
      }
      const updateContact = (contactId: string[]) => {
        selectedContact.value = contactId
        filter.value.contactId = contactId
      }

      const download = () => {
        isDownloading.value = true
        const companyId = route.params.id
        const data: any = { ...filter.value, date: picked.value }
        store.dispatch('organizations/EXPORT_ALL_CONTACTS', {
          companyId,
          filter: data,
          name: 'Contacts',
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const getStocks = (search?: string) => {
        const filter: any = {}
        if (search) filter.name = search
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: route.params.id,
          filter
        })
      }

      const handleContactSearch = (search: string) => {
        getContacts(search)
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 30,
            offset: 0,
            name: search
          }
        })
      }

      const getContactsList = async (page = 1) => {
        const data: any = { ...filter.value, date: picked.value }
        await store.dispatch('organizations/GET_CONTACTS_LIST', {
          companyId: currentCompany.value?.id ?? route.params?.id,
          filter: data,
          page,
          onSuccess: () => {
            isLoading.value = false
          }
        })
      }

      const autoRedirect = () => {
        if (acl.notCan('read-contacts') && acl.can('self-contact')) {
          const account = user.value.companiesAccounts.find(
            (x) => x.companyId == route.params?.id
          )
          router.push({
            name: 'company.directory',
            params: {
              id: route.params.id,
              contactId: account?.contactId
            }
          })
        }
      }

      onBeforeMount(async () => {
        getStocks()
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 100,
            offset: 0
          }
        })
      })

      watch(
        [
          selectedStocks,
          selectedGroup,
          selectedCategories,
          () => selectedContact.value
        ],
        () => {
          store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)
          getContactsList()
        }
      )

      watch(picked, (val: any) => {
        if (val) filter.value.date = moment(val).toISOString()
        else delete filter.value.date
        getContactsList()
      })

      watch(
        () => route.params.id,
        (val) => {
          getContactsList(1)
        }
      )

      onMounted(() => {
        autoRedirect()
        isLoading.value = true
        getContactsList()
      })

      return {
        picked,
        captable,
        groups,
        isDownloading,
        selectedStocks,
        selectedContact,
        selectedGroup,
        selectedCategories,
        contacts,
        columns,
        isLoading,
        pagination,
        isFiltered,
        companyGroups,
        handleContactSearch,
        updateStock,
        updateContact,
        updateGroup,
        updateDocumentType,
        formatNumber,
        onPageChange,
        download,
        includeDelegationsAvailableBalance,
        moment,
        CoOwnershipType,
        availableColumns,
        handleFilterSelected,
        selectedColumns,
        resetFilters,
        currentCompany,
        NumberType,
        rgbToHex,
        contactList,
        documentCategories
      }
    }
  })
</script>

<style lang="scss">
  .captable-filters {
    @apply flex justify-between items-start w-full;

    .filters-date {
      .button-filter {
        &:hover img {
          filter: brightness(0) invert(1);
          -webkit-filter: brightness(0) invert(1);
        }
      }
    }

    .filters {
      @apply flex items-start;

      .filter {
        @apply w-1/4 mr-2 flex flex-col;
      }
    }
  }

  .captable-table {
    @apply flex flex-col w-full;

    .associates {
      color: black;
    }

    .contact-name {
      color: black;
    }

    .nfd,
    .fd {
      background-color: var(--color-primary-lower) !important;
    }

    .stocks {
      width: 30%;
    }

    .dashed-border {
      border-style: dashed !important;
      background-color: unset !important;
      color: #aaaeb2 !important;
    }
  }

  .sticky {
    background: #f5f7fa;
  }
</style>
