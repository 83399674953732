import { ActionContext, ActionTree } from 'vuex'
import { RootState } from '@/store'
import {
  GET_COMPANIES,
  GET_COMPANY_COMMITTEE,
  SET_CURRENT_COMMITTEE,
  REMOVE_COMPANY_COMMITTEE,
  EDIT_COMPANY_COMMITTEE,
  CREATE_COMPANY_COMMITTEE,
  GET_COMPANY_SINGLE_DOCUMENT,
  SET_COMPANY_SINGLE_DOCUMENT,
  SET_DOC_SEARCH_RESULT,
  INIT_MODAL_FORM,
  SET_MODAL_FORM,
  GET_COMPANY_UID,
  GET_COMPANY_CONTACTS,
  GET_COMPANY_CONTACT,
  SET_CURRENT_CONTACT,
  CREATE_AUDIT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  GET_SINGLE_STOCK_ALIAS,
  SET_CURRENT_STOCK,
  CREATE_STOCK,
  GET_COMPANY_VESTING,
  SET_CURRENT_VESTING,
  REMOVE_COMPANY_VESTING,
  GET_COMPANY_VESTINGS,
  EDIT_STOCK_ALIAS,
  REMOVE_STOCK_ALIAS,
  SET_STOCK_ALIAS,
  GET_OPTION_STOCK_ALIAS,
  GET_COMPOSITION_STOCK_ALIAS,
  SET_OPTIONS_STOCK_ALIAS,
  SET_COMPOSITION_STOCK_ALIAS,
  GET_ALL_STOCK,
  CREATE_VESTING,
  GET_CONTACT_AUDITS,
  CREATE_COMMITTEE,
  GET_COMMITTEES,
  GET_CEILINGS,
  SET_CEILINGS,
  CREATE_CEILING,
  GET_DELEGATIONS,
  SET_DELEGATIONS,
  GET_FUNCTIONS,
  SET_FUNCTIONS,
  CREATE_DELEGATIONS,
  CREATE_PRIMARY_STOCK,
  CREATE_PRIMARY_OPTIONS,
  CREATE_NOMINATION,
  GET_NOMINATIONS,
  SET_NOMINATIONS,
  CREATE_TRANSFER,
  GET_TRANSFER,
  SET_TRANSFER,
  CREATE_VESTING_SUSPENSION,
  GET_VESTING_SUSPENSION,
  SET_VESTING_SUSPENSION,
  CREATE_VESTING_ACCELERATION,
  GET_VESTING_ACCELERATION,
  SET_VESTING_ACCELERATION,
  CREATE_VESTING_PROROGATION,
  GET_VESTING_PROROGATION,
  SET_VESTING_PROROGATION,
  GET_EVENTS,
  SET_EVENTS,
  GET_PRIMARY_STOCK,
  SET_PRIMARY_STOCK,
  GET_PRIMARY_OPTIONS,
  SET_PRIMARY_OPTIONS,
  GET_PRIMARY_COMPOSITION,
  SET_PRIMARY_COMPOSITION,
  CREATE_FINANCIAL_ENDING,
  SET_FINANCIAL_ENDING,
  CREATE_APPROVAL,
  GET_APPROVAL,
  SET_APPROVAL,
  CREATE_CONSTITUTION,
  EDIT_COMPANY_STOCK_GROUP,
  GET_VESTING_SYNTHESIS,
  EXPORT_COMPANY_TRANSACTIONS,
  SET_COMPANY_STOCKS_DATA,
  EDIT_OPTION_SUNSETS,
  SEND_INVITATION_MAIL,
  GET_COMPANY_CAPITAL_BY_STOCK_CATEGORY,
  GET_COMPANY_CAPITAL_BY_GROUP,
  SET_COMPANY_CAPITAL_BY_GROUP,
  SET_COMPANY_CAPITAL_BY_STOCK_CATEGORY,
  UPDATE_VESTING,
  IS_LOADING_VESTING,
  GET_COMPANY_CAPITAL_BY_ESOP_CATEGORY,
  SET_COMPANY_CAPITAL_BY_ESOP_CATEGORY,
  GET_NOTIFICATIONS,
  CREATE_COMPANY_ORGANIGRAM,
  SET_CURRENT_ORGANIGRAM,
  LOAD_COMPANY_CONTACTS,
  GET_ACCOUNT_COMPANIES,
  SEARCH_EVENTS,
  SET_SEARCHED_EVENTS,
  SET_EVENTS_SEARCHING,
  GET_CONTACTS_LIST,
  SET_CONTACTS_LIST,
  EXPORT_ALL_CONTACTS,
  GET_CAPTABLE_DELEGATIONS,
  SET_CAPTABLE_DELEGATIONS,
  GET_ACCOUNT_USER,
  EXPORT_COMPANY_GENERAL_MEETINGS_PDF,
  EXPORT_COMPANY_VOTING_RIGHT_PDF,
  CREATE_COMMITMENT,
  GET_COMMITMENT,
  SET_COMMITMENT,
  GET_COMMITMENTS,
  EDIT_COMMITMENT,
  EXPORT_COMPANY_COMMITMENTS_EXCEL,
  UPDATE_STOCK_ISSUANCE_PART,
  ADD_STOCK_ISSUANCE_PART,
  UPDATE_STOCK_ISSUANCE_PRIMARY_INFO,
  GET_OPTION_ISSUANCE_PARTS,
  ADD_OPTION_ISSUANCE_PART,
  UPDATE_OPTION_ISSUANCE_PRIMARY_INFO,
  UPDATE_OPTION_ISSUANCE_PART,
  GET_COMPOSITION_ISSUANCE_PARTS,
  REMOVE_COMPOSITION_ISSUANCE_PART,
  ADD_COMPOSITION_ISSUANCE_PART,
  UPDATE_COMPOSITION_ISSUANCE_PART,
  UPDATE_COMPOSITION_ISSUANCE_PRIMARY_INFO,
  GET_TRANSFER_PARTS,
  REMOVE_TRANSFER_PART,
  UPDATE_TRANSFER_PART,
  ADD_TRANSFER_PART,
  UPDATE_TRANSFER_PRIMARY_INFO,
  GET_TRANSFER_PARTS_TOTAL,
  GET_STOCK_ISSUANCE_PARTS_TOTAL,
  GET_OPTION_ISSUANCE_PARTS_TOTAL,
  DELETE_OPTION_EXERCISE_PART,
  GET_EVENTS_PARTS,
  ADD_OPTION_EXERCISE_PART,
  GET_OPTION_EXERCISE_PARTS,
  GET_OPTION_EXERCISE_PARTS_TOTAL,
  GET_COMPOSITION_ISSUANCE_PARTS_TOTAL,
  UPDATE_OPTION_EXERCISE_PRIMARY_INFO,
  GET_OPTION_SUNSET_PARTS_TOTAL,
  ADD_OPTION_SUNSET_PART,
  DELETE_OPTION_SUNSET_PART,
  UPDATE_OPTION_EXERCISE_PART,
  UPDATE_OPTION_SUNSET_PRIMARY_INFO,
  GET_OPTION_SUNSET_PARTS,
  UPDATE_OPTION_SUNSET_PART,
  CREATE_DELEGATION_SUNSETS,
  GET_DELEGATION_SUNSETS,
  SET_DELEGATION_SUNSETS,
  EDIT_DELEGATION_SUNSETS,
  PaginatedData
} from './models'
import { STOCKTYPESALIAS, AuditGetType } from '@/types/forms'
import {
  GET_COMPANY,
  GET_COMPANY_DOCUMENTS,
  GET_STOCK_ALIAS,
  INIT_ORGANIZATION,
  ONBOARD_COMPANY,
  OrganizationState,
  SEARCH_IN_DOCUMENTS,
  SET_COMPANIES,
  SET_COMPANY,
  SET_COMPANY_CONTACTS,
  SET_COMPANY_DOCUMENTS,
  SET_COMPANY_COMMITTEES,
  GET_COMPANY_COMMITTEES,
  SET_COMPANY_VESTINGS,
  SET_LOADING,
  SET_COMMITTEES,
  UPLOAD_COMPANY_FILES
} from './models'
import {
  getCompanyInformationsBySiret,
  onboardingCompany,
  uploadFiles,
  getCompanies,
  getCompanyDocuments,
  getSingleCompanyDocuments,
  searchInDocuments,
  createStock,
  getStockAlias,
  editStock,
  deleteStock,
  getSingleStockAlias,
  createVesting,
  getVestings,
  getVesting,
  removeVesting,
  getContacts,
  getContact,
  createContact,
  updateContact,
  deleteContact,
  createAudit,
  getAudits,
  editDocument,
  attachContactToDocument,
  editAudit,
  createCommittee,
  getCompanyCommittes,
  getCompanyCeilings,
  createNewCompany,
  deleteCompany,
  updateCompany,
  createUserAccount,
  getCompanyUserAccounts,
  editUserAccount,
  removeUserAccount,
  createCeiling,
  getDelegations,
  createDelegation,
  createPrimaryStockEmission,
  createPrimaryOptionsEmission,
  getCompanyFunctions,
  getCompanyAppointments,
  createAppointment,
  createTransfer,
  getCompanyTransfers,
  createVestingSuspension,
  getVestingSuspension,
  createVestingAcceleration,
  getVestingAcceleration,
  createVestingProrogation,
  getVestingProrogation,
  addNominalValue,
  getNominalValue,
  getCompany,
  getTransactions,
  createPrimaryCompositionEmission,
  deleteDocument,
  getEvents,
  getPrimaryStockEmission,
  getPrimaryOptionsEmission,
  getPrimaryCompositionEmission,
  getCaptable,
  createFinancialEnding,
  createGroup,
  getGroups,
  editGroup,
  getGroup,
  deleteGroup,
  getApprovals,
  createApproval,
  createConstitution,
  getStockGroups,
  createStockGroup,
  editStockGroup,
  deleteStockGroup,
  getStockGroup,
  createCapitalReduction,
  getCapitalReductions,
  replaceDocument,
  createOptionExercise,
  getCommittee,
  deleteCompanyCommittee,
  updateCompanyCommittee,
  createCompanyCommittee,
  terminateAppointment,
  getContactVesting,
  getVestingSynthesis,
  getEvent,
  removeEventDocument,
  changeImage,
  deleteEvent,
  editAuditStatus,
  getDelegatedTransactions,
  getBodac,
  getContactImage,
  getContactComputedData,
  exportTransactions,
  createConversion,
  getConversions,
  updateEventDocuments,
  getCapitalData,
  getAccountUsers,
  attachDocumentsToParts,
  detachDocumentsFromPart,
  getFundRaisingRounds,
  getFundRaisingRound,
  createFundRaisingRound,
  editFundRaisingRound,
  deleteFundRaisingRound,
  getProfitSharingPlans,
  getProfitSharingPlan,
  createProfitSharingPlan,
  editProfitSharingPlan,
  deleteProfitSharingPlan,
  changeCompanyImage,
  getCompanyLogo,
  getUserProfileImage,
  changeUserprofileImage,
  exportCaptable,
  createOptionSunset,
  getOptionSunsets,
  createParityChange,
  editParityChange,
  editAppointment,
  editVestingSuspension,
  editVestingAcceleration,
  editVestingProrogation,
  editFinancialEnding,
  editOptionSunset,
  editOptionExercise,
  editAppointmentTermination,
  editNominalValue,
  editConversion,
  editCapitalReduction,
  editCeiling,
  editDelegation,
  editTransfer,
  editApproval,
  editPrimaryStockEmission,
  editPrimaryOptionsEmission,
  editPrimaryCompositionEmission,
  removeUserCompanyAccount,
  allAssociatesRecord,
  sendInvitationMail,
  getCapitalByGroup,
  getCapitalByStockCategory,
  updateVesting,
  getCapitalByEsopCategory,
  getProfitSharingEvolution,
  getEsopInfo,
  getFundingData,
  getBodacPdfUrl,
  getCompanyAppointment,
  getExerciseCalendar,
  getExerciseCalendarsTotal,
  getDelegation,
  getStockIssuanceParts,
  getEventDocuments,
  removeStockIssuancePart,
  removeOptionIssuancePart,
  createCompanyOrganigram,
  verifyStockAlias,
  getLoaderCompanies,
  searchEvents,
  getAccountUser,
  createCommitment,
  getCommitment,
  getCommitments,
  editCommitment,
  editStockIssuancePart,
  addStockIssuancePart,
  editStockIssuancePrimaryInfo,
  getOptionIssuanceParts,
  addOptionIssuancePart,
  editOptionIssuancePrimaryInfo,
  editOptionIssuancePart,
  getCompositionIssuanceParts,
  removeCompositionIssuancePart,
  addCompositionIssuancePart,
  editCompositionIssuancePart,
  editCompositionIssuancePrimaryInfo,
  getTransferParts,
  removeTransferPart,
  editTransferPart,
  addTransferPart,
  editTransferPrimaryInfo,
  getTransferPartsTotal,
  getStockIssuancePartsTotal,
  getOptionIssuancePartsTotal,
  getCompositionIssuancePartsTotal,
  getEventsParts,
  getOptionExerciseParts,
  getOptionExercisePartsTotal,
  editOptionExercisePart,
  editOptionExercisePrimaryInfo,
  addOptionExercisePart,
  removeOptionExercisePart,
  getOptionSunsetPartsTotal,
  getOptionSunsetParts,
  editOptionSunsetPart,
  removeOptionSunsetPart,
  addOptionSunsetPart,
  editOptionSunsetPrimaryInfo,
  createDelegationSunset,
  getDelegationSunsets,
  editDelegationSunset
} from '@/services/api'
import { log } from 'loglevel'
import { getFormStorage, addToStorage } from '@/services/Storage'
import { storageKeys } from '@/utils/global'

import {
  SET_CONTACT_AUDITS,
  GET_EVENT_AUDITS,
  SET_EVENT_AUDITS,
  GET_DOCUMENT_AUDITS,
  SET_DOCUMENT_AUDITS
} from './models'
import {
  EDIT_COMPANY_DOCUMENT,
  ATTACH_CONTACTS_TO_DOCUMENT,
  UPDATE_AUDIT
} from './models'
import { TYPE } from 'vue-toastification'
import i18n from '@/services/lang'
import {
  GET_COMPANY_TRANSACTIONS,
  SET_COMPANY_TRANSACTIONS,
  CREATE_PRIMARY_COMPOSITION
} from './models'
import {
  GET_NOMINAL_VALUE,
  SET_NOMINAL_VALUE,
  GET_SINGLE_COMPANY
} from './models'
import {
  EDIT_USER_ACCOUNT,
  DELETE_USER_ACCOUNT,
  CREATE_APPOINTMENT_TERMINATION
} from './models'
import {
  LOAD_COMPANY_DOCUMENTS,
  CREATE_NEW_COMPANY,
  REMOVE_COMPANY,
  UPDATE_COMPANY,
  CREATE_USER_ACCOUNT,
  GET_COMPANY_USERS,
  SET_COMPANY_USERS
} from './models'
import {
  LOAD_COMPANY_EVENTS,
  CREATE_CAPITAL_REDUCTION,
  GET_CAPITAL_REDUCTION,
  SET_CAPITAL_REDUCTION
} from './models'
import moment from 'moment'
import { NominalValue } from '@/services/api/models/company'
import { AuditType } from '@/types/forms'
import { csvExport, excelExport } from '@/utils/file'
import {
  SET_CONTACT_CAPTABLE,
  UPDATE_EVENT_DOCUMENTS,
  LOAD_COMPANY
} from './models'
import { Company } from '@/services/api/models/company'
import {
  EXPORT_EXERCISE_CALENDARS,
  EXPORT_EXERCISE_CALENDARS_BY_OPTION
} from './models'
import {
  SET_ACCOUNT_USERS,
  ATTACH_DOCUMENTS_TO_EVENT_PART,
  DETACH_DOCUMENTS_TO_EVENT_PART
} from './models'
import {
  INIT_CONTACTS_PICTURE,
  GET_COMPANY_STOCKS_DATA,
  GET_ACCOUNT_USERS
} from './models'
import {
  SET_CONTEXT_COMPANY,
  SET_DOCUMENTS_GETTING,
  SET_CONTACT_PICTURE
} from './models'
import {
  COMPUTE_NOMINAL_VALUE,
  SET_NOMINAL_VALUES,
  CREATE_CONVERSION,
  GET_CONVERSIONS,
  SET_CONVERSIONS,
  IS_EXPORTING
} from './models'
import {
  GET_BODAC,
  SET_BODAC,
  LOAD_CONTACT_PICTURE,
  LOAD_CONTACT_COMPUTED_DATA
} from './models'
import {
  GET_DELEGATIONS_TRANSACTIONS,
  SET_DELEGATED_TRANSACTIONS
} from './models'
import {
  LOADING_COMPANY_DATA,
  LOADING_USER_DATA,
  UPDATE_AUDIT_STATUS
} from './models'
import {
  UPDATE_CONTACT_PROFILE_IMAGE,
  IS_DOCUMENT_SEARCHING,
  DELETE_EVENT
} from './models'
import {
  SET_VESTING_SYNTHESES,
  GET_EVENT,
  SET_EVENT,
  SET_DOCUMENTS_LOADING
} from './models'
import {
  GET_EXERCISE_CALENDARS,
  SET_EXERCISE_CALENDARS,
  GET_CONTACT_EXERCISE_CALENDARS,
  SET_CONTACT_EXERCISE_CALENDARS
} from './models'
import {
  CREATE_OPTIONS_EXERCISE,
  SET_EVENTS_LOADING,
  EDIT_PARITY_CHANGES
} from './models'
import {
  SET_DOCUMENTS_PAGINATION_PAGE,
  REPLACE_COMPANY_DOCUMENT
} from './models'
import {
  SET_CURRENT_CONTACT_GROUP,
  GET_CONTACT_GROUP,
  SET_CURRENT_STOCK_GROUP
} from './models'
import {
  CREATE_COMPANY_STOCK_GROUP,
  DELETE_COMPANY_STOCKS_GROUP
} from './models'
import {
  GET_LOADER_COMPANIES,
  SET_LOADER_COMPANIES,
  GET_COMPANY_STOCKS_GROUP,
  GET_COMPANY_STOCKS_GROUPS,
  SET_COMPANY_STOCKS_GROUPS
} from './models'
import {
  DELETE_COMPANY_GROUP,
  SET_CONTACT_DETAILS,
  FILTER_COMPANY_CONTACTS,
  SET_FILTERED_CONTACTS
} from './models'

import {
  GET_COMPANY_GROUPS,
  SET_COMPANY_GROUPS,
  EDIT_COMPANY_GROUP,
  DELETE_COMPANY_DOCUMENT,
  REMOVE_EVENT_DOCUMENT,
  GET_CAPTABLE,
  SET_CAPTABLE,
  CREATE_COMPANY_GROUP
} from './models'
import {
  getFile,
  getFileByUrl,
  getPdfFile
} from '@/services/api/company/xlsExport'
import { company, exportFiles } from '@/services/api/endpoints'
import { canMakeAction } from '../../../middlewares/canSee'
import { RolePermission } from '../../../utils/permissions'
import {
  PUSH_COMPANY_CONTACTS,
  SET_EXERCISE_CALENDARS_TOTAL,
  GET_EXERCISE_CALENDARS_TOTAL
} from './models'
import {
  GET_BODAC_PDF_URL,
  GET_NOMINATION,
  GET_EXERCISE_CALENDAR
} from './models'
import {
  GET_ESOP_DATA,
  SET_ESOP_DATA,
  GET_FUNDING_DATA,
  SET_FUNDING_DATA
} from './models'
import {
  GET_PROFIT_SHARING_EVOLUTION,
  SET_PROFIT_SHARING_EVOLUTION
} from './models'
import {
  EXPORT_COMPANY_TRANSACTIONS_PDF,
  DELETE_USER_COMPANY_PROFILE
} from './models'
import {
  EDIT_PRIMARY_STOCK,
  EDIT_PRIMARY_OPTIONS,
  EDIT_PRIMARY_COMPOSITION
} from './models'
import {
  EDIT_CEILING,
  EDIT_DELEGATIONS,
  EDIT_TRANSFER,
  EDIT_APPROVAL
} from './models'
import {
  EDIT_NOMINAL_VALUE,
  EDIT_CONVERSION,
  EDIT_CAPITAL_REDUCTION
} from './models'
import {
  EDIT_FINANCIAL_ENDING,
  EDIT_OPTIONS_EXERCISE,
  EDIT_APPOINTMENT_TERMINATION
} from './models'
import {
  EDIT_VESTING_SUSPENSION,
  EDIT_VESTING_ACCELERATION,
  EDIT_VESTING_PROROGATION
} from './models'
import {
  CREATE_PARITY_CHANGES,
  CREATE_NOMINAL_VALUE,
  EDIT_NOMINATION
} from './models'
import {
  EXPORT_COMPANY_CAPTABLE,
  EXPORT_ASSOCIATE_RECORD,
  EXPORT_ALL_ASSOCIATES_RECORD,
  GET_USERS_WITH_OPTIONS,
  CREATE_OPTION_SUNSETS,
  GET_OPTION_SUNSETS,
  SET_OPTION_SUNSETS
} from './models'
import {
  LOAD_USER_PROFILE_IMAGE,
  SET_USER_PROFILE_IMAGE,
  UPDATE_USER_PROFILE_IMAGE
} from './models'
import {
  UPDATE_COMPANY_LOGO,
  SET_COMPANY_LOGO,
  LOAD_COMPANY_LOGO
} from './models'
import {
  CREATE_PROFIT_SHARING_PLAN,
  EDIT_PROFIT_SHARING_PLAN,
  REMOVE_PROFIT_SHARING_PLAN
} from './models'
import {
  GET_PROFIT_SHARING_PLANS,
  SET_PROFIT_SHARING_PLANS,
  GET_PROFIT_SHARING_PLAN,
  SET_PROFIT_SHARING_PLAN
} from './models'
import {
  CREATE_FUND_RAISING_ROUND,
  EDIT_FUND_RAISING_ROUND,
  REMOVE_FUND_RAISING_ROUND
} from './models'
import {
  CREATE_OPERATION,
  EDIT_OPERATION,
  REMOVE_OPERATION,
  EXPORT_COMPANY_CAPTABLE_PDF
} from './models'
import {
  EXPORT_CAPTABLE,
  GET_FUND_RAISING_ROUNDS,
  SET_FUND_RAISING_ROUNDS,
  GET_FUND_RAISING_ROUND,
  SET_FUND_RAISING_ROUND,
  EXPORT_DELEGATIONS,
  EXPORT_APPOINTMENTS,
  EXPORT_DELEGATIONS_BY_HOLDER
} from './models'
import {
  GET_OPERATIONS,
  SET_OPERATIONS,
  GET_OPERATION,
  SET_OPERATION
} from './models'

import {
  createOperation,
  deleteOperation,
  editOperation,
  getOperation,
  getOperations
} from '@/services/api/company/operation'
import { alertActions } from './alertActions'
import { doctAutoActions } from './docAutoActions'
import { IS_LOADING_SINGLE_STOCK, VERIFY_STOCK } from './models'
import {
  GET_EVENT_DOCUMENTS,
  REMOVE_STOCK_ISSUANCE_PART,
  REMOVE_OPTION_ISSUANCE_PART
} from './models'
import {
  GET_DELEGATION,
  BUILD_DEFAULT_COMPANY,
  GET_STOCK_ISSUANCE_PARTS
} from './models'
import { getContactsList } from '@/services/api/company'

const context = '[ORGANIZATION]:'

export const actions: ActionTree<OrganizationState, RootState> = {
  async [CREATE_NEW_COMPANY](
    { commit, dispatch }: ActionContext<OrganizationState, RootState>,
    { data, onSuccess, onError }
  ) {
    try {
      commit(SET_LOADING, true)
      const company = await createNewCompany(data)
      commit(SET_LOADING, false)
      await dispatch(GET_COMPANIES, {})
      onSuccess(company)
    } catch (error: any) {
      commit(SET_LOADING, false)
      onError(error.data.message)
    }
  },
  async [REMOVE_COMPANY](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      await deleteCompany(companyId)
      await dispatch(GET_COMPANIES, {})

      onSuccess()
    } catch (error) {
      console.log(context, error)
      onError()
    }
  },

  async [UPDATE_COMPANY](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data, onSuccess, onError }
  ) {
    try {
      await updateCompany(companyId, data)
      await dispatch(GET_COMPANIES, {})
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
    }
  },

  async [CREATE_NOMINAL_VALUE](
    context: ActionContext<OrganizationState, RootState>,
    { companyId, data, onSuccess, onError }
  ) {
    try {
      await addNominalValue(companyId, data)
      context.dispatch(GET_NOMINAL_VALUE, { companyId })
      if (onSuccess) onSuccess()
    } catch (error) {
      console.log(context, error)

      throw error
    }
  },

  async [EDIT_NOMINAL_VALUE](
    context: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data, onSuccess, onError }
  ) {
    try {
      await editNominalValue(companyId, eventId, data)
      context.dispatch(GET_NOMINAL_VALUE, { companyId })
      if (onSuccess) onSuccess()
    } catch (error) {
      console.log(context, error)

      throw error
    }
  },

  async [GET_NOMINAL_VALUE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    if (!canMakeAction(RolePermission.writeEvents)) return

    try {
      const nominalValues: NominalValue[] = await getNominalValue(companyId)

      commit(
        SET_NOMINAL_VALUES,
        nominalValues.sort(
          (a, b) => moment(b.date).unix() - moment(a.date).unix()
        )
      )
      const [nominalValue] = nominalValues.sort(
        (a, b) => moment(b.date).unix() - moment(a.date).unix()
      )
      commit(SET_NOMINAL_VALUE, nominalValue)
    } catch (error) {
      console.log(context, error)
    }
  },
  async [GET_COMPANY_TRANSACTIONS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    commit(filter.contactsId ? LOADING_USER_DATA : LOADING_COMPANY_DATA, true)

    try {
      const data = await getTransactions(
        companyId,
        filter,
        state.transactionsPagination.currentPage,
        state.transactionsPagination.limit
      )

      commit(SET_COMPANY_TRANSACTIONS, data)
      if (onSuccess) onSuccess()
    } catch (error) {
      console.log(context, error)
      if (onError) onError()
    } finally {
      commit(
        filter.contactsId ? LOADING_USER_DATA : LOADING_COMPANY_DATA,
        false
      )
    }
  },
  async [EXPORT_COMPANY_TRANSACTIONS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    commit(IS_EXPORTING, true)

    try {
      const data = await exportTransactions(
        companyId,
        filter,
        state.transactionsPagination.currentPage,
        state.transactionsPagination.total
      )
      excelExport(data, `Export-transactions`, onSuccess, onError)
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_ALL_CONTACTS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    commit(IS_EXPORTING, true)

    try {
      getFile(
        exportFiles.allContacts(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },
  async [EXPORT_COMPANY_GENERAL_MEETINGS_PDF](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    commit(IS_EXPORTING, true)

    try {
      await getPdfFile(
        exportFiles.generalMeetingsPdfExport(companyId),
        `${name}_${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },
  async [EXPORT_COMPANY_TRANSACTIONS_PDF](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    commit(IS_EXPORTING, true)

    try {
      await getPdfFile(
        exportFiles.transactionsExportPdf(companyId),
        `${name}_${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_COMPANY_CAPTABLE](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    commit(IS_EXPORTING, true)

    try {
      getFile(
        exportFiles.captableExport(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_COMPANY_CAPTABLE_PDF](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    commit(IS_EXPORTING, true)

    try {
      await getPdfFile(
        exportFiles.captablePDFExport(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_COMPANY_VOTING_RIGHT_PDF](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    commit(IS_EXPORTING, true)

    try {
      getFile(
        exportFiles.votingReport(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_COMPANY_COMMITMENTS_EXCEL](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    commit(IS_EXPORTING, true)

    try {
      getFile(
        exportFiles.commitmentReport(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_ASSOCIATE_RECORD](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    commit(IS_EXPORTING, true)

    try {
      await getFileByUrl(
        exportFiles.associateRecord(companyId),
        `${name}_${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_ALL_ASSOCIATES_RECORD](
    context: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      await allAssociatesRecord(companyId)
      context.dispatch(GET_COMPANY_DOCUMENTS, { companyId })
      if (onSuccess) onSuccess()
    } catch (error) {
      console.log(context, error)
      if (onError) onError()
    }
  },

  async [EXPORT_EXERCISE_CALENDARS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    commit(IS_EXPORTING, true)

    try {
      getFileByUrl(
        exportFiles.exerciseCalendars(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
      if (onError) onError()
    } finally {
      commit(IS_EXPORTING, false)
    }
  },
  async exportHotjar(
    //to remove
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    commit(IS_EXPORTING, true)

    try {
      getFile(
        company.exportHotjar(companyId),
        `${name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
      if (onError) onError()
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_EXERCISE_CALENDARS_BY_OPTION](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    commit(IS_EXPORTING, true)

    try {
      getFileByUrl(
        exportFiles.exerciseCalendarsByOption(companyId),
        `${name} ${state.company?.name} - Options`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_DELEGATIONS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    commit(IS_EXPORTING, true)

    try {
      getFile(
        exportFiles.delegations(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },
  async [EXPORT_DELEGATIONS_BY_HOLDER](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    commit(IS_EXPORTING, true)

    try {
      getFile(
        exportFiles.delegationByHolder(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [EXPORT_APPOINTMENTS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    commit(IS_EXPORTING, true)

    try {
      getFile(
        exportFiles.appointments(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },
  async [EXPORT_CAPTABLE](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, name, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    commit(IS_EXPORTING, true)

    try {
      getFile(
        exportFiles.captable(companyId),
        `${name} ${state.company?.name}`,
        filter,
        onSuccess,
        onError
      )
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(IS_EXPORTING, false)
    }
  },

  async [GET_COMPANIES](
    { commit, dispatch, state }: ActionContext<OrganizationState, RootState>,
    { filter, companyId = null }
  ) {
    try {
      let cmpId = companyId || getFormStorage(storageKeys.currentCompany)
      commit(SET_LOADING, true)

      const result = await getCompanies(
        filter,
        (state.companiesPagination.currentPage - 1) *
          state.companiesPagination.limit
      )

      commit(SET_COMPANIES, result)

      if (!cmpId || !result.data.map((x: Company) => x.id).includes(cmpId)) {
        const [company] = [...result.data]
        cmpId = company.id

        addToStorage(storageKeys.currentCompany, cmpId || '')
        addToStorage(storageKeys.currentCompanyName, company.name || '')
      }

      await dispatch(GET_SINGLE_COMPANY, { companyId: cmpId })
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(SET_LOADING, false)
    }
  },

  async [GET_LOADER_COMPANIES](
    { commit }: ActionContext<OrganizationState, RootState>,
    { filter, onSuccess }
  ) {
    try {
      commit(SET_LOADING, true)
      const { data, metadata }: PaginatedData = await getLoaderCompanies(filter)

      commit(SET_LOADER_COMPANIES, data)
      commit(SET_LOADING, false)
      if (onSuccess) onSuccess(data, metadata)
    } catch (error) {
      console.log(context, error)
    } finally {
      commit(SET_LOADING, false)
    }
  },

  async [GET_ACCOUNT_COMPANIES](
    { commit }: ActionContext<OrganizationState, RootState>,
    { filter, onSuccess }
  ) {
    try {
      const { data } = await getCompanies(filter)

      if (onSuccess) onSuccess(data)
    } catch (error) {
      console.log(context, error)
    }
  },

  async [GET_COMPANY](
    { commit, dispatch }: ActionContext<OrganizationState, RootState>,
    { siret, onSuccess, onError }
  ) {
    try {
      const company = await getCompanyInformationsBySiret(siret)
      commit(SET_COMPANY, company)
      await dispatch(GET_NOMINAL_VALUE, { companyId: company.id })
      onSuccess(company)
    } catch (error) {
      onError(error)
      console.log(context, 'GETTING COMPANY')
    }
  },
  async [GET_SINGLE_COMPANY](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    if (!companyId) return
    try {
      const company = await getCompany(companyId)
      commit(SET_COMPANY, company)
      if (onSuccess) onSuccess(company)
    } catch (error) {
      if (onError) onError(error)
      console.log(context, 'GETTING COMPANY')
    }
  },

  async [LOAD_COMPANY](
    { commit, dispatch, state }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    try {
      if (!companyId) {
        dispatch(BUILD_DEFAULT_COMPANY)
        return
      }
      const company = await getCompany(companyId)
      await dispatch(GET_NOMINAL_VALUE, { companyId: company.id })
      commit(SET_CONTEXT_COMPANY, company)
    } catch (error: any) {
      if (error?.status === 404) {
        dispatch(BUILD_DEFAULT_COMPANY)
      }
      console.log(context, 'GETTING COMPANY 1', error)
    }
  },

  async [BUILD_DEFAULT_COMPANY]({
    dispatch
  }: ActionContext<OrganizationState, RootState>) {
    try {
      dispatch(GET_LOADER_COMPANIES, {
        onSuccess: (data: Company[]) => {
          const [cmp] = data
          dispatch(LOAD_COMPANY, { companyId: cmp.id })
        }
      })
    } catch (error) {
      console.log(context, 'BUILD_DEFAULT_COMPANY COMPANY 1', error)
    }
  },
  async [GET_COMPANY_DOCUMENTS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, getting, onSuccess, onError }
  ) {
    if (
      !canMakeAction(RolePermission.readDocuments) &&
      canMakeAction(RolePermission.selfReadDocuments) &&
      filter?.contactsId?.length > 1
    )
      return

    commit(SET_DOCUMENTS_LOADING, true)
    commit(SET_DOCUMENTS_GETTING, getting || false)
    for (const key in filter) {
      if (filter[key] === '') {
        delete filter[key]
      }
    }

    try {
      const params = {
        limit: state.documentsPagination.limit,
        offset:
          (state.documentsPagination.currentPage - 1) *
          state.documentsPagination.limit
      }

      const documents = await getCompanyDocuments(companyId, {
        ...params,
        ...filter
      })
      commit(SET_COMPANY_DOCUMENTS, {
        list: documents,
        update: false
      })

      if (onSuccess) onSuccess(documents)
    } catch (error) {
      if (onError) onError(error)
    } finally {
      commit(SET_DOCUMENTS_LOADING, false)
      commit(SET_DOCUMENTS_GETTING, false)
    }
  },

  async [LOAD_COMPANY_DOCUMENTS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    try {
      if (
        state.documentsPagination.total > 0 &&
        state.documentsPagination.currentPage >=
          state.documentsPagination.lastPage
      ) {
        return
      }
      commit(SET_DOCUMENTS_GETTING, true)
      const params = {
        limit: state.documentsPagination.limit,
        offset:
          (state.documentsPagination.currentPage - 1) *
          state.documentsPagination.limit
      }

      const documents = await getCompanyDocuments(companyId, {
        ...params,
        ...filter
      })
      commit(SET_COMPANY_DOCUMENTS, { list: documents, update: true })
      if (onSuccess) onSuccess(documents)

      return documents
    } catch (error) {
      if (onError) onError(error)
    } finally {
      commit(SET_DOCUMENTS_GETTING, false)
    }
  },

  async [GET_COMMITMENTS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    try {
      const response = await getCommitments(companyId, filter)
      onSuccess(response)
    } catch (error) {
      onError(error)
      console.log(context, error)
    }
  },

  async [GET_EVENTS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, offset = 0, limit, filter = {}, onSuccess }
  ) {
    commit(SET_EVENTS_LOADING, true)
    try {
      const events = await getEvents(
        companyId,
        filter,
        offset,
        (limit = state.eventsPagination.limit)
      )
      commit(SET_EVENTS, { list: events, update: false })
      if (onSuccess) onSuccess(events)
      return events
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      commit(SET_EVENTS_LOADING, false)
    }
  },
  async [GET_EVENTS_PARTS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {}, onSuccess, onError }
  ) {
    commit(SET_EVENTS_LOADING, true)
    try {
      const events = await getEventsParts(companyId, filter)
      if (onSuccess) onSuccess(events)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [SEARCH_EVENTS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, offset = 0, limit, filter = {}, onSuccess }
  ) {
    commit(SET_EVENTS_SEARCHING, true)
    try {
      const events = await searchEvents(
        companyId,
        filter,
        offset,
        (limit = state.eventsPagination.limit)
      )
      commit(SET_SEARCHED_EVENTS, { list: events, update: false })
      if (onSuccess) onSuccess(events)
      return events
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      commit(SET_EVENTS_SEARCHING, false)
    }
  },
  async [GET_EVENT](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, type, onSuccess, onError }
  ) {
    try {
      const event = await getEvent(companyId, eventId, type)
      if (onSuccess) onSuccess(event)
      else {
        commit(SET_EVENT, event)
        return event
      }
    } catch (error: any) {
      console.log(error)
      if (onError) onError(error?.response)
      else throw error
    } finally {
      // commit(SET_EVENTS_LOADING, false)
    }
  },

  async [DELETE_EVENT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, onSuccess }
  ) {
    try {
      await deleteEvent(companyId, eventId)
      onSuccess()
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [LOAD_CONTACT_PICTURE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, contactId }
  ) {
    if (!companyId || !contactId) return
    try {
      const response = getContactImage(companyId, contactId)
      commit(SET_CONTACT_PICTURE, { contactId, url: await response })
      return await response
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [LOAD_COMPANY_LOGO](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    if (!companyId) return

    try {
      const response = getCompanyLogo(companyId)
      commit(SET_COMPANY_LOGO, { companyId, url: await response })
      return await response
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [LOAD_USER_PROFILE_IMAGE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, userId }
  ) {
    try {
      const response = getUserProfileImage(companyId, userId)
      commit(SET_USER_PROFILE_IMAGE, { userId, url: await response })
      return await response
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [LOAD_CONTACT_COMPUTED_DATA](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, contactId, onSuccess }
  ) {
    try {
      const response = await getContactComputedData(companyId, contactId)

      if (onSuccess) onSuccess(response)
      else return response
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [COMPUTE_NOMINAL_VALUE](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { date, setAsGlobal = false }
  ) {
    try {
      const values = state.nominalValues
        .filter(
          (x) => moment(x.date).unix() <= moment(date).endOf('day').unix()
        )
        .sort((a, b) => moment(b.date).unix() - moment(a.date).unix())
      if (setAsGlobal) commit(SET_NOMINAL_VALUE, values[0])
      if (values) return values[0]
      return null
      // return response
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [LOAD_COMPANY_EVENTS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {}, offset = 0, limit = 30 }
  ) {
    if (state.eventsPagination.currentPage >= state.eventsPagination.lastPage)
      return
    commit(SET_LOADING, true)
    try {
      const events = await getEvents(
        companyId,
        filter,
        offset,
        (limit = state.eventsPagination.limit)
      )
      // commit(SET_EVENTS, { list: events, update: true })
      return events
    } catch (error) {
      console.log(error)
      throw error
    } finally {
      commit(SET_LOADING, false)
    }
  },

  async [GET_COMPANY_COMMITTEES](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      const committees = await getCompanyCommittes(companyId)

      commit(SET_COMPANY_COMMITTEES, committees)
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [CREATE_COMMITTEE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createCommittee(companyId, data)
      await dispatch(GET_COMMITTEES, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  //TODO: whats up after we create this? do we call events?
  async [CREATE_CONSTITUTION](
    context: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createConstitution(companyId, data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_COMMITTEES](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError, filter = {} }
  ) {
    try {
      const page = state.committeesPagination.currentPage
      const data = await getCompanyCommittes(companyId, filter, page)
      commit(SET_COMMITTEES, data)
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [CREATE_APPROVAL](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createApproval(companyId, data)
      await dispatch(GET_APPROVAL, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_APPROVAL](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editApproval(companyId, eventId, data)
      await dispatch(GET_APPROVAL, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_APPROVAL](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      const committees = await getApprovals(companyId)
      commit(SET_APPROVAL, committees)
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [CREATE_COMMITMENT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createCommitment(companyId, data)
      await dispatch(GET_COMMITMENT, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_COMMITMENT](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      const commitments = await getCommitment(companyId)
      commit(SET_COMMITMENT, commitments)
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [EDIT_COMMITMENT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editCommitment(companyId, eventId, data)
      await dispatch(GET_COMMITMENT, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_VESTING_SUSPENSION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createVestingSuspension(companyId, data)
      await dispatch(GET_VESTING_SUSPENSION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_VESTING_SUSPENSION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editVestingSuspension(companyId, eventId, data)
      await dispatch(GET_VESTING_SUSPENSION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_CONVERSION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createConversion(companyId, data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_CONVERSION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editConversion(companyId, eventId, data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_VESTING_SUSPENSION](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      const suspensions = await getVestingSuspension(companyId)
      commit(SET_VESTING_SUSPENSION, suspensions)
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [GET_CONVERSIONS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      const response = await getConversions(companyId)
      commit(SET_CONVERSIONS, response)
      if (onSuccess) onSuccess(response.data)
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [CREATE_VESTING_ACCELERATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createVestingAcceleration(companyId, data)
      await dispatch(GET_VESTING_ACCELERATION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_VESTING_ACCELERATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editVestingAcceleration(companyId, eventId, data)
      await dispatch(GET_VESTING_ACCELERATION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_VESTING_ACCELERATION](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      const accelerations = await getVestingAcceleration(companyId)
      commit(SET_VESTING_ACCELERATION, accelerations)
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [CREATE_VESTING_PROROGATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createVestingProrogation(companyId, data)
      await dispatch(GET_VESTING_PROROGATION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_VESTING_PROROGATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editVestingProrogation(companyId, eventId, data)
      await dispatch(GET_VESTING_PROROGATION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_VESTING_PROROGATION](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      const prorogations = await getVestingProrogation(companyId)
      commit(SET_VESTING_PROROGATION, prorogations)
      if (onSuccess) onSuccess()
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [GET_CEILINGS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    try {
      const ceilings = await getCompanyCeilings(companyId)
      commit(SET_CEILINGS, ceilings)
    } catch (error) {
      console.log('Error', error)
    }
  },
  async [GET_FUNCTIONS]({
    commit
  }: ActionContext<OrganizationState, RootState>) {
    try {
      const functions = await getCompanyFunctions()
      commit(SET_FUNCTIONS, functions)
    } catch (error) {
      console.log('Error', error)
    }
  },

  async [CREATE_NOMINATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createAppointment(companyId, data)
      await dispatch(GET_NOMINATIONS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_NOMINATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editAppointment(companyId, eventId, data)
      await dispatch(GET_NOMINATIONS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_APPOINTMENT_TERMINATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await terminateAppointment(companyId, data)
      await dispatch(GET_NOMINATIONS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_APPOINTMENT_TERMINATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editAppointmentTermination(companyId, eventId, data)
      await dispatch(GET_NOMINATIONS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async [GET_EXERCISE_CALENDARS_TOTAL](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }
    try {
      const total = await getExerciseCalendarsTotal(companyId, {
        ...filter,
        offset: 0,
        limit: 1
      })
      commit(SET_EXERCISE_CALENDARS_TOTAL, total)

      if (onSuccess) onSuccess(total)
    } catch (error) {
      if (onError) onError(error)
    }
  },
  async [GET_TRANSFER_PARTS_TOTAL](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, transferId, filter, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    try {
      const total = await getTransferPartsTotal(companyId, transferId, {
        ...filter
      })

      if (onSuccess) onSuccess(total)
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [UPDATE_OPTION_SUNSET_PRIMARY_INFO](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionSunsetId, data, onSuccess, onError }
  ) {
    try {
      const response = await editOptionSunsetPrimaryInfo(
        companyId,
        optionSunsetId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [GET_OPTION_SUNSET_PARTS](
    { rootState }: ActionContext<OrganizationState, RootState>,
    { companyId, optionSunsetId, page, filter, limit, onSuccess, onError }
  ) {
    try {
      const limitValue = limit ? limit : rootState.config.eventPartsLimit

      const offset = (page - 1) * limitValue

      if (filter?.contactsId && filter?.contactsId?.length == 0) {
        delete filter?.contactsId
      }
      const response = await getOptionSunsetParts(
        companyId,
        optionSunsetId,
        offset,
        limitValue,
        filter
      )
      onSuccess(response, limitValue)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [UPDATE_OPTION_SUNSET_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionSunsetId, partId, data, onSuccess, onError }
  ) {
    try {
      const response = await editOptionSunsetPart(
        companyId,
        optionSunsetId,
        partId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [GET_OPTION_SUNSET_PARTS_TOTAL](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, optionSunsetId, filter, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    try {
      const total = await getOptionSunsetPartsTotal(companyId, optionSunsetId, {
        ...filter
      })

      if (onSuccess) onSuccess(total)
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [ADD_OPTION_SUNSET_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionSunsetId, data, onSuccess, onError }
  ) {
    try {
      const response = await addOptionSunsetPart(
        companyId,
        optionSunsetId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [DELETE_OPTION_SUNSET_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionSunsetId, partId, onSuccess, onError }
  ) {
    try {
      const response = await removeOptionSunsetPart(
        companyId,
        optionSunsetId,
        partId
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [GET_OPTION_EXERCISE_PARTS_TOTAL](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, optionExerciseId, filter, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    try {
      const total = await getOptionExercisePartsTotal(
        companyId,
        optionExerciseId,
        {
          ...filter
        }
      )

      if (onSuccess) onSuccess(total)
    } catch (error) {
      if (onError) onError(error)
    }
  },

  async [GET_STOCK_ISSUANCE_PARTS_TOTAL](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, issuanceId, filter, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }
    try {
      const total = await getStockIssuancePartsTotal(companyId, issuanceId, {
        ...filter
      })

      if (onSuccess) onSuccess(total)
    } catch (error) {
      if (onError) onError(error)
    }
  },
  async [GET_OPTION_ISSUANCE_PARTS_TOTAL](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, issuanceId, filter, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }
    try {
      const total = await getOptionIssuancePartsTotal(companyId, issuanceId, {
        ...filter
      })

      if (onSuccess) onSuccess(total)
    } catch (error) {
      if (onError) onError(error)
    }
  },
  async [GET_COMPOSITION_ISSUANCE_PARTS_TOTAL](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, issuanceId, filter, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }
    try {
      const total = await getCompositionIssuancePartsTotal(
        companyId,
        issuanceId,
        {
          ...filter
        }
      )

      if (onSuccess) onSuccess(total)
    } catch (error) {
      if (onError) onError(error)
    }
  },
  async [GET_EXERCISE_CALENDARS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }
    commit(filter.contactId ? LOADING_USER_DATA : LOADING_COMPANY_DATA, true)
    const offset =
      (state.exerciseCalendarPagination.currentPage - 1) *
      state.exerciseCalendarPagination.limit

    try {
      const response = await getContactVesting(companyId, {
        ...filter,
        offset,
        limit: state.exerciseCalendarPagination.limit
      })
      commit(SET_EXERCISE_CALENDARS, response)
      if (onSuccess) onSuccess(response)
    } catch (error) {
      console.log(error)
      if (onError) onError()
      else throw error
    } finally {
      commit(filter.contactId ? LOADING_USER_DATA : LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_EXERCISE_CALENDAR](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, exerciseCalendarId, filter }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }
    try {
      const { data } = await getContactVesting(companyId, filter)

      return (data || []).find((x: any) => x.id === exerciseCalendarId)
    } catch (error) {
      console.log(error)
    }
  },

  async [GET_USERS_WITH_OPTIONS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }

    try {
      const response = await getContactVesting(companyId, filter)
      if (onSuccess) onSuccess(response)
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_VESTING_SYNTHESIS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {}, onSuccess, onError }
  ) {
    for (const key in filter) {
      if (filter[key] === '' || !filter[key]) {
        delete filter[key]
      }
    }
    commit(filter.contactId ? LOADING_USER_DATA : LOADING_COMPANY_DATA, true)

    if (filter?.page) {
      filter.offset =
        state.exerciseCalendarSynthesisPagination.limit * (filter?.page - 1)
      filter.limit = state.exerciseCalendarSynthesisPagination.limit
      delete filter?.page
    }

    try {
      const response = await getVestingSynthesis(
        companyId,
        filter,
        filter?.offset,
        filter?.limit ?? 20
      )
      commit(SET_VESTING_SYNTHESES, response)
      if (onSuccess) onSuccess(response)
    } catch (error) {
      if (onError) onError()
      else throw error
    } finally {
      commit(filter.contactId ? LOADING_USER_DATA : LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_CONTACT_EXERCISE_CALENDARS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }
  ) {
    try {
      const response = await getContactVesting(companyId, filter)
      commit(SET_CONTACT_EXERCISE_CALENDARS, {
        list: response.data,
        contactId: filter.contactId
      })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_NOMINATIONS](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {} }
  ) {
    try {
      const offset =
        (state.nominationsPagination.currentPage - 1) *
        state.nominationsPagination.limit
      const nominations = await getCompanyAppointments(
        companyId,
        filter,
        offset
      )
      commit(SET_NOMINATIONS, nominations)
    } catch (error) {
      console.log('Error', error)
    }
  },

  async [GET_NOMINATION](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, appointmentId, onSuccess, onError }
  ) {
    try {
      const nomination = await getCompanyAppointment(companyId, appointmentId)
      onSuccess(nomination)
    } catch (error) {
      console.log('Error', error)
      onError(error)
    }
  },
  async [CREATE_TRANSFER](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createTransfer(companyId, data)
      await dispatch(GET_TRANSFER, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_TRANSFER](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editTransfer(companyId, eventId, data)
      await dispatch(GET_TRANSFER, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_OPTION_SUNSETS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createOptionSunset(companyId, data)
      await dispatch(GET_OPTION_SUNSETS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_OPTION_SUNSETS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editOptionSunset(companyId, eventId, data)
      await dispatch(GET_OPTION_SUNSETS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_OPTION_SUNSETS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }
  ) {
    try {
      const sunsets = await getOptionSunsets(companyId, { ...filter })
      commit(SET_OPTION_SUNSETS, sunsets)
    } catch (error) {
      console.log('Error', error)
    }
  },

  async [CREATE_PARITY_CHANGES](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createParityChange(companyId, data)
    } catch (error) {
      console.log('Error', error)
      throw error
    }
  },

  async [EDIT_PARITY_CHANGES](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editParityChange(companyId, eventId, data)
    } catch (error) {
      console.log('Error', error)
      throw error
    }
  },

  async [GET_TRANSFER](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    try {
      const transfers = await getCompanyTransfers(companyId)
      commit(SET_TRANSFER, transfers)
    } catch (error) {
      console.log('Error', error)
    }
  },

  async [CREATE_CEILING](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createCeiling(companyId, data)
      await dispatch(GET_CEILINGS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_CEILING](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editCeiling(companyId, eventId, data)
      await dispatch(GET_CEILINGS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_CAPITAL_REDUCTION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createCapitalReduction(companyId, data)
      await dispatch(GET_CAPITAL_REDUCTION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_CAPITAL_REDUCTION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editCapitalReduction(companyId, eventId, data)
      await dispatch(GET_CAPITAL_REDUCTION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_CAPITAL_REDUCTION](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    try {
      const reductions = await getCapitalReductions(companyId)
      commit(SET_CAPITAL_REDUCTION, reductions)
    } catch (error) {
      console.log('Error', error)
    }
  },

  async [GET_DELEGATIONS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    try {
      if (
        !canMakeAction(RolePermission.readDelegations) &&
        !canMakeAction(RolePermission.writeDelegations)
      )
        return

      if (filter?.usableAtDate === '') delete filter.usableAtDate
      if (filter?.commonCeilingId === '') delete filter.commonCeilingId
      if (filter?.status === '') delete filter.status
      const offset =
        (state.delegationsPagination.currentPage - 1) *
        state.delegationsPagination.limit

      if (!filter?.offset) filter.offset = offset
      if (!filter?.limit) filter.limit = state.delegationsPagination.limit

      const delegations = await getDelegations(companyId, filter)
      commit(SET_DELEGATIONS, delegations)

      if (onSuccess) onSuccess(delegations)
    } catch (error) {
      console.log('Error', error)
      if (onError) onError()
    }
  },

  async [GET_DELEGATION_SUNSETS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    try {
      if (
        !canMakeAction(RolePermission.readDelegations) &&
        !canMakeAction(RolePermission.writeDelegations)
      )
        return

      if (filter?.usableAtDate === '') delete filter.usableAtDate
      if (filter?.commonCeilingId === '') delete filter.commonCeilingId
      if (filter?.status === '') delete filter.status
      const offset =
        (state.delegationsPagination.currentPage - 1) *
        state.delegationsPagination.limit

      if (!filter?.offset) filter.offset = offset
      if (!filter?.limit) filter.limit = state.delegationsPagination.limit

      const sunsets = await getDelegationSunsets(companyId, filter)
      commit(SET_DELEGATION_SUNSETS, sunsets)

      if (onSuccess) onSuccess(sunsets)
    } catch (error) {
      console.log('Error', error)
      if (onError) onError()
    }
  },

  async [GET_CAPTABLE_DELEGATIONS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    try {
      if (
        !canMakeAction(RolePermission.readDelegations) &&
        !canMakeAction(RolePermission.writeDelegations)
      )
        return

      if (filter?.usableAtDate === '') delete filter.usableAtDate
      if (filter?.commonCeilingId === '') delete filter.commonCeilingId
      const offset =
        (state.delegationsPagination.currentPage - 1) *
        state.delegationsPagination.limit

      if (!filter?.offset) filter.offset = offset
      if (!filter?.limit) filter.limit = state.delegationsPagination.limit
      filter.status = 'PENDING'

      const delegations = await getDelegations(companyId, filter)
      commit(SET_CAPTABLE_DELEGATIONS, delegations)

      if (onSuccess) onSuccess(delegations)
    } catch (error) {
      console.log('Error', error)
      if (onError) onError()
    }
  },

  async [GET_DELEGATION](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, delegationId, onSuccess, onError }
  ) {
    try {
      if (!canMakeAction(RolePermission.readDelegations)) return

      const delegation = await getDelegation(companyId, delegationId)
      if (onSuccess) onSuccess(delegation)
      else commit(SET_DELEGATIONS, [...state.delegations, delegation])
    } catch (error) {
      onError(error)
      console.log('Error', error)
    }
  },
  async [GET_DELEGATIONS_TRANSACTIONS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }
  ) {
    try {
      const offset =
        (state.delegTransactionsPagination.currentPage - 1) *
        state.delegTransactionsPagination.limit
      const transactions = await getDelegatedTransactions(
        companyId,
        filter,
        offset,
        state.delegTransactionsPagination.limit
      )

      commit(SET_DELEGATED_TRANSACTIONS, transactions)
    } catch (error) {
      console.log('Error', error)
    }
  },

  async [CREATE_DELEGATIONS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createDelegation(companyId, data)
      await dispatch(GET_DELEGATIONS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_DELEGATION_SUNSETS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      if (!canMakeAction(RolePermission.writeDelegations)) return

      await createDelegationSunset(companyId, data)
      await dispatch(GET_DELEGATION_SUNSETS, { companyId, filter: {} })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_DELEGATIONS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editDelegation(companyId, eventId, data)
      await dispatch(GET_DELEGATIONS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_DELEGATION_SUNSETS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editDelegationSunset(companyId, eventId, data)
      await dispatch(GET_DELEGATION_SUNSETS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_FINANCIAL_ENDING](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createFinancialEnding(companyId, data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_FINANCIAL_ENDING](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editFinancialEnding(companyId, eventId, data)
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async [GET_STOCK_ISSUANCE_PARTS](
    { rootState }: ActionContext<OrganizationState, RootState>,
    { companyId, stockIssuanceId, page, filter, limit, onSuccess, onError }
  ) {
    try {
      const limitValue = limit ? limit : rootState.config.eventPartsLimit

      const offset = (page - 1) * limitValue

      if (filter?.contactsId && filter?.contactsId?.length == 0) {
        delete filter?.contactsId
      }
      const response = await getStockIssuanceParts(
        companyId,
        stockIssuanceId,
        offset,
        limitValue,
        filter
      )
      onSuccess(response, limitValue)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [GET_OPTION_ISSUANCE_PARTS](
    { rootState }: ActionContext<OrganizationState, RootState>,
    { companyId, optionIssuanceId, page, filter, limit, onSuccess, onError }
  ) {
    try {
      const limitValue = limit ? limit : rootState.config.eventPartsLimit

      const offset = (page - 1) * limitValue

      if (filter?.contactsId && filter?.contactsId?.length == 0) {
        delete filter?.contactsId
      }
      const response = await getOptionIssuanceParts(
        companyId,
        optionIssuanceId,
        offset,
        limitValue,
        filter
      )
      onSuccess(response, limitValue)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [GET_COMPOSITION_ISSUANCE_PARTS](
    { rootState }: ActionContext<OrganizationState, RootState>,
    {
      companyId,
      compositionIssuanceId,
      page,
      filter,
      limit,
      onSuccess,
      onError
    }
  ) {
    try {
      const limitValue = limit ? limit : rootState.config.eventPartsLimit

      const offset = (page - 1) * limitValue

      if (filter?.contactsId && filter?.contactsId?.length == 0) {
        delete filter?.contactsId
      }
      const response = await getCompositionIssuanceParts(
        companyId,
        compositionIssuanceId,
        offset,
        limitValue,
        filter
      )
      onSuccess(response, limitValue)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [GET_OPTION_EXERCISE_PARTS](
    { rootState }: ActionContext<OrganizationState, RootState>,
    { companyId, optionExerciseId, page, filter, limit, onSuccess, onError }
  ) {
    try {
      const limitValue = limit ? limit : rootState.config.eventPartsLimit

      const offset = (page - 1) * limitValue

      if (filter?.contactsId && filter?.contactsId?.length == 0) {
        delete filter?.contactsId
      }
      const response = await getOptionExerciseParts(
        companyId,
        optionExerciseId,
        offset,
        limitValue,
        filter
      )
      onSuccess(response, limitValue)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [GET_TRANSFER_PARTS](
    { rootState }: ActionContext<OrganizationState, RootState>,
    { companyId, transferId, page, filter, limit, onSuccess, onError }
  ) {
    try {
      const limitValue = limit ? limit : rootState.config.eventPartsLimit

      const offset = (page - 1) * limitValue

      if (filter?.contactsId && filter?.contactsId?.length == 0) {
        delete filter?.contactsId
      }
      const response = await getTransferParts(
        companyId,
        transferId,
        offset,
        limitValue,
        filter
      )
      onSuccess(response, limitValue)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [REMOVE_STOCK_ISSUANCE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, stockIssuanceId, partId, onSuccess, onError }
  ) {
    try {
      const response = await removeStockIssuancePart(
        companyId,
        stockIssuanceId,
        partId
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [UPDATE_STOCK_ISSUANCE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, stockIssuanceId, partId, data, onSuccess, onError }
  ) {
    try {
      const response = await editStockIssuancePart(
        companyId,
        stockIssuanceId,
        partId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [UPDATE_STOCK_ISSUANCE_PRIMARY_INFO](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, stockIssuanceId, partId, data, onSuccess, onError }
  ) {
    try {
      const response = await editStockIssuancePrimaryInfo(
        companyId,
        stockIssuanceId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [ADD_STOCK_ISSUANCE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, stockIssuanceId, data, onSuccess, onError }
  ) {
    try {
      const response = await addStockIssuancePart(
        companyId,
        stockIssuanceId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [ADD_OPTION_EXERCISE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionExerciseId, data, onSuccess, onError }
  ) {
    try {
      const response = await addOptionExercisePart(
        companyId,
        optionExerciseId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [UPDATE_OPTION_EXERCISE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionExerciseId, partId, data, onSuccess, onError }
  ) {
    try {
      const response = await editOptionExercisePart(
        companyId,
        optionExerciseId,
        partId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [UPDATE_OPTION_EXERCISE_PRIMARY_INFO](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionExerciseId, data, onSuccess, onError }
  ) {
    try {
      const response = await editOptionExercisePrimaryInfo(
        companyId,
        optionExerciseId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [UPDATE_OPTION_ISSUANCE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionIssuanceId, partId, data, onSuccess, onError }
  ) {
    try {
      const response = await editOptionIssuancePart(
        companyId,
        optionIssuanceId,
        partId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [UPDATE_COMPOSITION_ISSUANCE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, compositionIssuanceId, partId, data, onSuccess, onError }
  ) {
    try {
      const response = await editCompositionIssuancePart(
        companyId,
        compositionIssuanceId,
        partId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [UPDATE_TRANSFER_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, transferId, partId, data, onSuccess, onError }
  ) {
    try {
      const response = await editTransferPart(
        companyId,
        transferId,
        partId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [UPDATE_OPTION_ISSUANCE_PRIMARY_INFO](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionIssuanceId, data, onSuccess, onError }
  ) {
    try {
      const response = await editOptionIssuancePrimaryInfo(
        companyId,
        optionIssuanceId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [UPDATE_COMPOSITION_ISSUANCE_PRIMARY_INFO](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, compositionIssuanceId, data, onSuccess, onError }
  ) {
    try {
      const response = await editCompositionIssuancePrimaryInfo(
        companyId,
        compositionIssuanceId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [UPDATE_TRANSFER_PRIMARY_INFO](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, transferId, data, onSuccess, onError }
  ) {
    try {
      const response = await editTransferPrimaryInfo(
        companyId,
        transferId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [ADD_OPTION_ISSUANCE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionIssuanceId, data, onSuccess, onError }
  ) {
    try {
      const response = await addOptionIssuancePart(
        companyId,
        optionIssuanceId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [ADD_COMPOSITION_ISSUANCE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, compositionIssuanceId, data, onSuccess, onError }
  ) {
    try {
      const response = await addCompositionIssuancePart(
        companyId,
        compositionIssuanceId,
        data
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [ADD_TRANSFER_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, transferId, data, onSuccess, onError }
  ) {
    try {
      const response = await addTransferPart(companyId, transferId, data)
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [REMOVE_OPTION_ISSUANCE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionIssuanceId, partId, onSuccess, onError }
  ) {
    try {
      const response = await removeOptionIssuancePart(
        companyId,
        optionIssuanceId,
        partId
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [DELETE_OPTION_EXERCISE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, optionExerciseId, partId, onSuccess, onError }
  ) {
    try {
      const response = await removeOptionExercisePart(
        companyId,
        optionExerciseId,
        partId
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [REMOVE_TRANSFER_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, transferId, partId, onSuccess, onError }
  ) {
    try {
      const response = await removeTransferPart(companyId, transferId, partId)
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [REMOVE_COMPOSITION_ISSUANCE_PART](
    { state }: ActionContext<OrganizationState, RootState>,
    { companyId, compositionIssuanceId, partId, onSuccess, onError }
  ) {
    try {
      const response = await removeCompositionIssuancePart(
        companyId,
        compositionIssuanceId,
        partId
      )
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },

  async [GET_EVENT_DOCUMENTS](
    { rootState }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, page, limit, onSuccess, onError }
  ) {
    try {
      const lm = limit || rootState.config.eventDocumentsLimit

      const offset = ((page || 1) - 1) * lm
      const response = await getEventDocuments(companyId, eventId, offset, lm)
      onSuccess(response)
    } catch (error) {
      console.log(error)
      onError(error)
    }
  },
  async [CREATE_PRIMARY_STOCK](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createPrimaryStockEmission(companyId, data)
      await dispatch(GET_PRIMARY_STOCK, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_PRIMARY_STOCK](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editPrimaryStockEmission(companyId, eventId, data)
      await dispatch(GET_PRIMARY_STOCK, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_PRIMARY_OPTIONS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createPrimaryOptionsEmission(companyId, data)
      await dispatch(GET_PRIMARY_OPTIONS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  async [EDIT_PRIMARY_OPTIONS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editPrimaryOptionsEmission(companyId, eventId, data)
      await dispatch(GET_PRIMARY_OPTIONS, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [CREATE_PRIMARY_COMPOSITION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createPrimaryCompositionEmission(companyId, data)
      await dispatch(GET_PRIMARY_COMPOSITION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [EDIT_PRIMARY_COMPOSITION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editPrimaryCompositionEmission(companyId, eventId, data)
      await dispatch(GET_PRIMARY_COMPOSITION, { companyId })
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_PRIMARY_STOCK](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    try {
      const primary = await getPrimaryStockEmission(companyId)
      commit(SET_PRIMARY_STOCK, primary)
    } catch (error) {
      console.log(error)
      throw error
    }
  },

  async [GET_PRIMARY_OPTIONS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    try {
      const primary = await getPrimaryOptionsEmission(companyId)
      commit(SET_PRIMARY_OPTIONS, primary)
    } catch (error) {
      console.log(error)
    }
  },

  async [GET_PRIMARY_COMPOSITION](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    try {
      const primary = await getPrimaryCompositionEmission(companyId)
      commit(SET_PRIMARY_COMPOSITION, primary)
    } catch (error) {
      console.log(error)
    }
  },

  async [GET_COMPANY_COMMITTEE](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, committeeId }
  ) {
    try {
      const committe = await getCommittee(companyId, committeeId)
      commit(SET_CURRENT_COMMITTEE, committe)
    } catch (error) {
      console.log('Error', error)
    }
  },

  async [REMOVE_COMPANY_COMMITTEE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, committeeId, onSuccess, onError }
  ) {
    try {
      await deleteCompanyCommittee(companyId, committeeId)
      await dispatch(GET_COMPANY_COMMITTEES, { companyId })
      onSuccess()
    } catch (error: any) {
      console.log('Error', error)
      onError(error.data.message)
    }
  },

  async [EDIT_COMPANY_COMMITTEE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, committeeId, data, onSuccess, onError }
  ) {
    try {
      await updateCompanyCommittee(companyId, committeeId, data)
      await dispatch(GET_COMPANY_COMMITTEES, { companyId })
      onSuccess()
    } catch (error: any) {
      console.log('Error', error)
      onError(error.data.message)
    }
  },

  async [CREATE_COMPANY_COMMITTEE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data, onSuccess, onError }
  ) {
    try {
      await createCompanyCommittee(companyId, data)
      await dispatch(GET_COMPANY_COMMITTEES, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
    }
  },

  async [GET_COMPANY_SINGLE_DOCUMENT](
    { commit, dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, documentId }
  ) {
    try {
      const document = await getSingleCompanyDocuments(companyId, documentId)

      commit(SET_COMPANY_SINGLE_DOCUMENT, document)
    } catch (error) {
      await dispatch(
        'toolbox/displayToast',
        {
          type: TYPE.ERROR,
          context: i18n.global.t('documents.context'),
          message: i18n.global.t('documents.error500')
        },
        { root: true }
      )
      console.log(context, error)
    }
  },

  async [ONBOARD_COMPANY](
    context: ActionContext<OrganizationState, RootState>,
    { data, onSuccess, onError }
  ) {
    try {
      const { status } = await onboardingCompany(data)
      onSuccess(status)
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [SEARCH_IN_DOCUMENTS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, query, onSuccess, onError }
  ) {
    commit(IS_DOCUMENT_SEARCHING, true)
    try {
      const response = await searchInDocuments(companyId, query)
      commit(SET_DOC_SEARCH_RESULT, response)

      if (onSuccess) onSuccess()
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    } finally {
      commit(IS_DOCUMENT_SEARCHING, false)
    }
  },

  async [INIT_MODAL_FORM](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    form
  ) {
    commit(SET_MODAL_FORM, form)
    setTimeout(() => {
      commit(SET_MODAL_FORM, { ...state.forms, modalInit: false })
    }, 500)
  },

  async [GET_COMPANY_UID](
    context: ActionContext<OrganizationState, RootState>,
    { data, onSuccess, onError }
  ) {
    try {
      const { status } = await onboardingCompany(data)
      onSuccess(status)
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [UPDATE_CONTACT_PROFILE_IMAGE](
    context: ActionContext<OrganizationState, RootState>,
    { companyId, form, contactId, onSuccess, onError, onProgress }
  ) {
    try {
      const { status } = await changeImage(
        companyId,
        form,
        contactId,
        onProgress
      )
      onSuccess(status)
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [UPDATE_COMPANY_LOGO](
    context: ActionContext<OrganizationState, RootState>,
    { companyId, form, onSuccess, onError, onProgress }
  ) {
    try {
      const { status } = await changeCompanyImage(companyId, form, onProgress)
      onSuccess(status)
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [UPDATE_USER_PROFILE_IMAGE](
    context: ActionContext<OrganizationState, RootState>,
    { companyId, userId, form, onSuccess, onError, onProgress }
  ) {
    try {
      const { status } = await changeUserprofileImage(
        companyId,
        userId,
        form,
        onProgress
      )
      onSuccess(status)
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [GET_COMPANY_CONTACTS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = { contactGroupId: '', type: '' }, onSuccess, onError }
  ) {
    try {
      if (filter?.contactStatusId === '') delete filter.contactStatusId
      if (filter?.contactGroupId === '') delete filter.contactGroupId
      if (filter?.type === '') delete filter.type
      if (filter?.name === '') delete filter.name

      const contacts = await getContacts(companyId, {
        ...filter,
        limit: filter.limit || 10,
        offset: filter.offset || 0
      })

      if (onSuccess) {
        onSuccess(contacts)
      } else commit(SET_COMPANY_CONTACTS, contacts)
    } catch (error) {
      console.error(`${context} GET_COMPANY_CONTACTS`, error)
      if (onError) onError(error)
    }
  },

  async [FILTER_COMPANY_CONTACTS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = { contactGroupId: '', type: '' } }
  ) {
    try {
      if (filter.contactStatusId === '') delete filter.contactStatusId
      if (filter.contactGroupId === '') delete filter.contactGroupId
      if (filter.type === '') delete filter.type
      if (filter.name === '') delete filter.name

      const contacts = await getContacts(companyId, {
        ...filter,
        limit: filter.limit || 100,
        offset: filter.offset || 0
      })
      commit(SET_FILTERED_CONTACTS, contacts)
    } catch (error) {
      console.log(context, error)
    }
  },

  async [GET_COMPANY_CONTACT](
    { state, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, contactId, onSuccess, onError }
  ) {
    try {
      const contact = await getContact(companyId, contactId)
      if (!state.contacts.map((x) => x.id).includes(contact.id)) {
        commit(PUSH_COMPANY_CONTACTS, contact)
      }
      commit(SET_CURRENT_CONTACT, contact)
      commit(SET_CONTACT_DETAILS, contact)
      if (onSuccess) onSuccess(contact)
    } catch (error: any) {
      console.log(context, error)
      if (onError) onError(error?.data)
    }
  },

  async [CREATE_AUDIT](
    context: ActionContext<OrganizationState, RootState>,
    { companyId, type, typeDataId, formData, onSuccess, onError }
  ) {
    try {
      const response = (
        await createAudit(companyId, type, typeDataId, formData)
      ).data
      onSuccess(response?.audit)
    } catch (error: any) {
      console.log(error)

      onError(error?.response?.message)
    }
  },

  async [UPDATE_AUDIT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, type, typeDataId, auditId, formData, onSuccess, onError }
  ) {
    try {
      await editAudit(companyId, type, typeDataId, auditId, formData)
      if (type == AuditType.DOCUMENT)
        await dispatch(GET_DOCUMENT_AUDITS, { companyId })
      if (type == AuditType.CONTACT)
        await dispatch(GET_CONTACT_AUDITS, { companyId })
      if (type == AuditType.EVENT)
        await dispatch(GET_EVENT_AUDITS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context, error)
      onError()
    }
  },

  async [UPDATE_AUDIT_STATUS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, type, data, onSuccess, onError }
  ) {
    try {
      await editAuditStatus(companyId, type, data)
      if (type == AuditGetType.DOCUMENT)
        await dispatch(GET_DOCUMENT_AUDITS, { companyId })
      if (type == AuditGetType.CONTACT)
        await dispatch(GET_CONTACT_AUDITS, { companyId })
      if (type == AuditGetType.EVENT)
        await dispatch(GET_EVENT_AUDITS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context, error)
      onError()
    }
  },

  async [CREATE_CONTACT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, formData, onSuccess, onError }
  ) {
    try {
      const createdContact = await createContact(companyId, formData)
      await dispatch(GET_COMPANY_CONTACTS, { companyId })
      onSuccess(createdContact.id)
    } catch (error) {
      console.log(context, error)
      onError()
    }
  },

  async [UPDATE_CONTACT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, contactId, formData, onSuccess, onError }
  ) {
    try {
      await updateContact(companyId, contactId, formData)
      await dispatch(GET_COMPANY_CONTACTS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context, error)
      onError()
    }
  },

  async [DELETE_CONTACT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, contactId, onSuccess, onError }
  ) {
    try {
      await deleteContact(companyId, contactId)
      await dispatch(GET_COMPANY_CONTACTS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context, error)
      onError()
    }
  },

  async [UPLOAD_COMPANY_FILES](
    context: ActionContext<OrganizationState, RootState>,
    { form, companyId, progress, onSuccess, onError }
  ) {
    try {
      const resp: any = await uploadFiles(form, companyId, progress)
      onSuccess(resp.createdAt !== null)
      context.dispatch(GET_COMPANY_DOCUMENTS, { companyId })
    } catch (error: any) {
      console.log('context', error)
      onError(error)
    }
  },

  async [REPLACE_COMPANY_DOCUMENT](
    { dispatch, state }: ActionContext<OrganizationState, RootState>,
    { form, documentId, companyId, progress, onSuccess, onError }
  ) {
    try {
      await replaceDocument(companyId, documentId, form, progress)
      const currentPage = state.documentsPagination.currentPage
      await dispatch(GET_COMPANY_DOCUMENTS, { companyId })
      if (currentPage > 1) {
        for (let index = 1; index <= currentPage; index++) {
          dispatch(LOAD_COMPANY_DOCUMENTS, {
            companyId,
            filter: {},
            offset: 10 * index
          })
        }
      }
      dispatch(GET_COMPANY_SINGLE_DOCUMENT, { companyId, documentId })
      onSuccess()
    } catch (error: any) {
      console.log('context', error)
      onError(error.data.response)
    }
  },

  async [EDIT_COMPANY_DOCUMENT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { form, companyId, documentId, onSuccess, onError }
  ) {
    try {
      await editDocument(companyId, documentId, form)
      await dispatch(GET_COMPANY_DOCUMENTS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [DELETE_COMPANY_DOCUMENT](
    { dispatch, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, documentId, onSuccess, onError }
  ) {
    try {
      await deleteDocument(companyId, documentId)
      commit(SET_COMPANY_SINGLE_DOCUMENT, null)

      await dispatch(GET_COMPANY_DOCUMENTS, { companyId })
      onSuccess()
    } catch (error: any) {
      console.log(context, error)
      onError(error?.data?.message)
    }
  },

  async [REMOVE_EVENT_DOCUMENT](
    { dispatch, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, documentId, onSuccess, onError }
  ) {
    try {
      await removeEventDocument(companyId, eventId, documentId)
      commit(SET_COMPANY_SINGLE_DOCUMENT, null)
      dispatch(GET_EVENTS, { companyId })
      onSuccess()
    } catch (error: any) {
      console.log(context, error)
      onError(error.data.message)
    }
  },
  async [UPDATE_EVENT_DOCUMENTS](
    { dispatch, commit }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data, onSuccess, onError }
  ) {
    try {
      await updateEventDocuments(companyId, eventId, data)
      dispatch(GET_EVENT, { companyId, eventId })
      onSuccess()
    } catch (error: any) {
      console.log(context, error)
      onError(error.data.message)
    }
  },

  async [ATTACH_CONTACTS_TO_DOCUMENT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { form, companyId, documentId, onSuccess, onError }
  ) {
    try {
      const { status } = await attachContactToDocument(
        companyId,
        documentId,
        form
      )
      await dispatch(GET_COMPANY_DOCUMENTS, { companyId })
      onSuccess(status)
    } catch (error) {
      console.log(context, error)
      onError(error)
    }
  },

  async [GET_SINGLE_STOCK_ALIAS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, type, stockId, onSuccess, onError }
  ) {
    commit(IS_LOADING_SINGLE_STOCK, true)
    try {
      const data = await getSingleStockAlias(companyId, type, stockId)
      commit(SET_CURRENT_STOCK, { data, type })
      commit(IS_LOADING_SINGLE_STOCK, false)

      if (onSuccess) onSuccess(data, type)
    } catch (error) {
      commit(IS_LOADING_SINGLE_STOCK, false)

      if (onError) onError(error)
      console.log(context, 'Getting single')
    }
  },

  async [CREATE_STOCK](
    context: ActionContext<OrganizationState, RootState>,
    { form, companyId, type, onSuccess, onError }
  ) {
    try {
      const { status } = await createStock(form, companyId, type)
      onSuccess(status)
    } catch (error: any) {
      onError(error.data.message)
    }
  },

  async [GET_COMPANY_VESTING](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, vestingId }
  ) {
    commit(IS_LOADING_VESTING, true)
    try {
      const vesting = await getVesting(companyId, vestingId)
      commit(SET_CURRENT_VESTING, vesting)
      commit(IS_LOADING_VESTING, false)
    } catch (error) {
      commit(IS_LOADING_VESTING, false)

      console.log(context)
    }
  },

  async [REMOVE_COMPANY_VESTING](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, vestingId, onSuccess, onError }
  ) {
    try {
      await removeVesting(companyId, vestingId)
      await dispatch(GET_COMPANY_VESTINGS, { companyId })
      onSuccess()
    } catch (error) {
      console.log(context)
      onError()
    }
  },

  async [EDIT_STOCK_ALIAS](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { form, companyId, type, stockId, onSuccess, onError }
  ) {
    try {
      await editStock(form, companyId, type, stockId)
      await dispatch(GET_SINGLE_STOCK_ALIAS, { companyId, type, stockId })
      onSuccess()
    } catch (error: any) {
      console.log(error)

      onError(error.data.message)
    }
  },

  async [REMOVE_STOCK_ALIAS](
    context: ActionContext<OrganizationState, RootState>,
    { companyId, type, stockId, onSuccess, onError }
  ) {
    try {
      await deleteStock(companyId, type, stockId)
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
    }
  },
  async [GET_STOCK_ALIAS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {}, onSuccess }
  ) {
    try {
      if (!canMakeAction(RolePermission.readStock)) return
      const data = await getStockAlias(companyId, STOCKTYPESALIAS.STOCK, filter)

      if (onSuccess) onSuccess(data)
      else commit(SET_STOCK_ALIAS, data)
    } catch (error) {
      log(error)
    }
  },

  async [GET_OPTION_STOCK_ALIAS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {}, onSuccess }
  ) {
    try {
      if (!canMakeAction(RolePermission.readStock)) return
      const data = await getStockAlias(
        companyId,
        STOCKTYPESALIAS.OPTION,
        filter
      )
      if (onSuccess) onSuccess(data)
      else commit(SET_OPTIONS_STOCK_ALIAS, data)
    } catch (error) {
      log(error)
    }
  },

  async [GET_COMPOSITION_STOCK_ALIAS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {}, onSuccess }
  ) {
    try {
      if (!canMakeAction(RolePermission.readStock)) return
      const data = await getStockAlias(
        companyId,
        STOCKTYPESALIAS.COMPOSITION,
        filter
      )
      if (onSuccess) onSuccess(data)
      else commit(SET_COMPOSITION_STOCK_ALIAS, data)
    } catch (error) {
      log(error)
    }
  },

  async [GET_ALL_STOCK](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {} }
  ) {
    try {
      await dispatch(GET_STOCK_ALIAS, { companyId, filter })
      await dispatch(GET_OPTION_STOCK_ALIAS, { companyId, filter })
      await dispatch(GET_COMPOSITION_STOCK_ALIAS, { companyId, filter })
    } catch (error) {
      log(error)
    }
  },

  async [VERIFY_STOCK](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, stockId }
  ) {
    try {
      const data = verifyStockAlias(companyId, stockId)
      return data
    } catch (error) {
      return null
    }
  },

  async [INIT_ORGANIZATION](
    { dispatch, commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    try {
      dispatch('toolbox/getDocumentCategories', {}, { root: true })
      dispatch('toolbox/GET_STOCK_CATEGORIES', {}, { root: true })
      dispatch('toolbox/GET_OPTION_CATEGORIES', {}, { root: true })
      dispatch('toolbox/GET_CONTACT_GROUPS', {}, { root: true })
      commit(SET_DOCUMENTS_PAGINATION_PAGE, 1)
      const currentCompanyId = getFormStorage(storageKeys.currentCompany)

      await dispatch(LOAD_COMPANY, {
        companyId: companyId || currentCompanyId
      })

      dispatch(GET_NOMINAL_VALUE, {
        companyId: companyId || currentCompanyId
      })

      dispatch(GET_ALL_STOCK, { companyId: companyId || currentCompanyId })

      dispatch(GET_COMPANY_VESTINGS, {
        companyId: companyId || currentCompanyId
      })
      dispatch(FILTER_COMPANY_CONTACTS, {
        companyId: companyId || currentCompanyId
      })
      dispatch(GET_COMPANY_GROUPS, {
        companyId: companyId || currentCompanyId
      })

      dispatch(GET_FUND_RAISING_ROUNDS, {
        companyId: companyId || currentCompanyId
      })
      dispatch(GET_PROFIT_SHARING_PLANS, {
        companyId: companyId || currentCompanyId
      })
      dispatch(GET_OPERATIONS, {
        companyId: companyId || currentCompanyId
      })
      dispatch(GET_NOTIFICATIONS, { companyId: companyId || currentCompanyId })
      commit(INIT_CONTACTS_PICTURE)
      dispatch(
        'auth/GET_ROLES',
        { companyId: companyId || currentCompanyId },
        { root: true }
      )
    } catch (error) {
      console.log(context, error)
    }
  },

  async [CREATE_VESTING](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { form, companyId, onError, onSuccess }
  ) {
    try {
      await createVesting(companyId, form)
      await dispatch(GET_COMPANY_VESTINGS, { companyId })
      onSuccess()
    } catch (error: any) {
      console.log(context, 'CREATE_VESTING', error.data)
      onError(error.data.message)
    }
  },

  async [UPDATE_VESTING](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { form, vestingId, companyId, onError, onSuccess }
  ) {
    try {
      await updateVesting(companyId, vestingId, form)
      await dispatch(GET_COMPANY_VESTINGS, { companyId })
      onSuccess()
    } catch (error: any) {
      console.log(context, 'UPDATE_VESTING', error.data)
      onError(error.data.message)
    }
  },

  async [CREATE_USER_ACCOUNT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { form, companyId, onError, onSuccess }
  ) {
    try {
      const response: any = await createUserAccount(companyId, form)

      if (response.success && response.data.id) onSuccess()
      else onError(response.data.message)
    } catch (error: any) {
      console.log(context, 'CREATE_USER_ACCOUNT', error)
      onError(error.data.message)
    }
  },

  async [SEND_INVITATION_MAIL](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { userId, companyId, onError, onSuccess }
  ) {
    try {
      await sendInvitationMail(companyId, userId)
      onSuccess()
    } catch (error: any) {
      console.log(context, 'SEND_INVITATION_MAIL', error)
      onError(error?.data?.message)
    }
  },
  async [EDIT_USER_ACCOUNT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { form, userId, companyId, onError, onSuccess }
  ) {
    try {
      await editUserAccount(companyId, userId, form)
      onSuccess()
    } catch (error: any) {
      console.log(context, 'EDIT_USER_ACCOUNT', error)
      onError(error.data.message)
    }
  },

  async [DELETE_USER_ACCOUNT](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, userId, onError, onSuccess }
  ) {
    try {
      await removeUserAccount(companyId, userId)
      onSuccess()
    } catch (error: any) {
      console.log(context, 'DELETE_USER_ACCOUNT', error)
      onError(error.data.message)
    }
  },
  async [DELETE_USER_COMPANY_PROFILE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, userId, companyAccountId, onError, onSuccess }
  ) {
    try {
      await removeUserCompanyAccount(companyId, userId, companyAccountId)
      onSuccess()
    } catch (error: any) {
      console.log(context, 'DELETE_USER_COMPANY_PROFILE', error)
      onError(error.data.message)
    }
  },

  async [GET_COMPANY_USERS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }
  ) {
    try {
      const response = await getCompanyUserAccounts(companyId, filter)
      commit(SET_COMPANY_USERS, response)
    } catch (error) {
      console.log(context, 'GET_COMPANY_USERS', error)
    }
  },

  async [GET_ACCOUNT_USERS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { filter, onSuccess, onError }
  ) {
    commit(LOADING_COMPANY_DATA, true)

    try {
      const response = await getAccountUsers(
        filter,
        (state.accountsPagination.currentPage - 1) *
          state.accountsPagination.limit
      )
      commit(SET_ACCOUNT_USERS, response)
      if (onSuccess) onSuccess(response)
    } catch (error) {
      if (onError) onError(error)
      console.log(context, 'GET_ACCOUNT_USERS', error)
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },
  async [GET_ACCOUNT_USER](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, userId, onSuccess, onError }
  ) {
    try {
      const response = await getAccountUser(companyId, userId)

      onSuccess(response)
    } catch (error) {
      if (onError) onError(error)
      console.log(context, 'GET_ACCOUNT_USER', error)
    }
  },

  async [GET_COMPANY_VESTINGS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {} }
  ) {
    try {
      const { data } = await getVestings(companyId, filter)

      commit(SET_COMPANY_VESTINGS, data)
    } catch (error) {
      console.log(context, 'CREATE_VESTING')
    }
  },

  async [CREATE_OPTIONS_EXERCISE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data }
  ) {
    try {
      await createOptionExercise(companyId, data)
      dispatch(GET_EVENTS, { companyId })
    } catch (error) {
      console.log(context, 'CREATE_OPTIONS_EXERCISE')
      throw error
    }
  },

  async [EDIT_OPTIONS_EXERCISE](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, eventId, data }
  ) {
    try {
      await editOptionExercise(companyId, eventId, data)
    } catch (error) {
      console.log(context, 'EDIT_OPTIONS_EXERCISE')
      throw error
    }
  },

  async [GET_CONTACT_AUDITS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    try {
      const { data } = await getAudits(
        companyId,
        AuditGetType.CONTACT,
        state.auditPagination,
        filter
      )
      commit(SET_CONTACT_AUDITS, data)
      if (onSuccess) onSuccess(data)
    } catch (error) {
      console.log(context, 'GET_CONTACT_AUDITS')
      if (onError) onError()
    }
  },

  async [GET_EVENT_AUDITS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    try {
      const response = await getAudits(
        companyId,
        AuditGetType.EVENT,
        state.auditPagination
      )

      commit(SET_EVENT_AUDITS, response)
      if (onSuccess) onSuccess(response)
    } catch (error) {
      console.log(context, 'GET_EVENT_AUDITS')
      if (onError) onError()
    }
  },

  async [GET_DOCUMENT_AUDITS](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, onSuccess, onError }
  ) {
    try {
      const { data } = await getAudits(
        companyId,
        AuditGetType.DOCUMENT,
        state.auditPagination,
        filter
      )
      commit(SET_DOCUMENT_AUDITS, data)

      if (onSuccess) onSuccess(data)
    } catch (error) {
      console.log(context, 'GET_DOCUMENT_AUDITS')
      if (onError) onError()
    }
  },
  async [GET_CAPTABLE](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, page, onSuccess, onError }
  ) {
    commit(filter.contactId ? LOADING_USER_DATA : LOADING_COMPANY_DATA, true)
    try {
      const offset = (page ? page - 1 : 0) * state.captablePagination.limit
      const result = await getCaptable(
        companyId,
        filter,
        offset,
        state.captablePagination.limit
      )

      if (Array.isArray(filter.contactId) && filter.contactId.length) {
        filter.contactId.forEach((cId: string) => {
          commit(SET_CONTACT_CAPTABLE, {
            data: (result.data.lines || []).filter(
              (x: any) => x.contactId === cId
            ),
            contactId: cId
          })
        })
      }
      commit(SET_CAPTABLE, result)
      if (onSuccess) onSuccess(result.data)
    } catch (error: any) {
      console.log(context, 'GET_CAPTABLE_ERROR', error)
      if (onError) onError()
    } finally {
      commit(filter.contactId ? LOADING_USER_DATA : LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_CONTACTS_LIST](
    { commit, state }: ActionContext<OrganizationState, RootState>,
    { companyId, filter, page, onSuccess, onError }
  ) {
    commit(filter.contactId ? LOADING_USER_DATA : LOADING_COMPANY_DATA, true)
    try {
      const result = await getContactsList(
        companyId,
        filter,
        state.contactListPagination.limit * ((page || 1) - 1),
        state.contactListPagination.limit
      )

      commit(SET_CONTACTS_LIST, result)
      if (onSuccess) onSuccess(result)
    } catch (error: any) {
      console.log(context, 'GET_CAPTABLE_ERROR', error)
      if (onError) onError()
    } finally {
      commit(filter.contactId ? LOADING_USER_DATA : LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_COMPANY_GROUPS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter = {} }
  ) {
    try {
      const data = await getGroups(companyId, filter)

      commit(SET_COMPANY_GROUPS, data)
    } catch (error: any) {
      console.log(context, 'GET_COMPANY_GROUPS')
    }
  },

  async [CREATE_COMPANY_GROUP](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { data, companyId, onSuccess, onError }
  ) {
    try {
      await createGroup(companyId, data)
      dispatch(GET_COMPANY_GROUPS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
    }
  },

  async [EDIT_COMPANY_GROUP](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { data, companyId, groupId, onSuccess, onError }
  ) {
    try {
      await editGroup(companyId, groupId, data)
      dispatch(GET_COMPANY_GROUPS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
    }
  },
  async [GET_CONTACT_GROUP](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, groupId }
  ) {
    try {
      const group = await getGroup(companyId, groupId)
      commit(SET_CURRENT_CONTACT_GROUP, group)
    } catch (error: any) {
      console.log('ERROOR', error)
    }
  },

  async [DELETE_COMPANY_GROUP](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, groupId, onSuccess, onError }
  ) {
    try {
      await deleteGroup(companyId, groupId)
      dispatch(GET_COMPANY_GROUPS, { companyId })
      onSuccess()
    } catch (error: any) {
      console.log('ERROOR', error)
      onError(error.data.message)
    }
  },

  async [GET_COMPANY_STOCKS_GROUPS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }
  ) {
    try {
      const data = await getStockGroups(companyId, filter)
      commit(SET_COMPANY_STOCKS_GROUPS, data)
    } catch (error: any) {
      console.log(context, 'GET_COMPANY_GROUPS')
    }
  },

  async [GET_COMPANY_STOCKS_GROUP](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, groupId }
  ) {
    try {
      const data = await getStockGroup(companyId, groupId)

      commit(SET_CURRENT_STOCK_GROUP, data)
    } catch (error: any) {
      console.log(context, 'GET_COMPANY_GROUPS')
    }
  },

  async [GET_FUND_RAISING_ROUNDS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }
  ) {
    if (!canMakeAction(RolePermission.writeFundRaisingRound)) return
    try {
      const data = await getFundRaisingRounds(companyId, filter)

      commit(SET_FUND_RAISING_ROUNDS, data)
    } catch (error: any) {
      console.log(context, 'GET_FUND_RAISING_ROUNDS')
    }
  },

  async [GET_FUND_RAISING_ROUND](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, id }
  ) {
    try {
      // if (!canMakeAction(RolePermission.readFundRaisingRound)) return

      const data = await getFundRaisingRound(companyId, id)

      commit(SET_FUND_RAISING_ROUND, data)
    } catch (error: any) {
      console.log(context, 'GET_FUND_RAISING_ROUND')
    }
  },

  async [CREATE_FUND_RAISING_ROUND](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data, onSuccess, onError }
  ) {
    try {
      await createFundRaisingRound(companyId, data)
      dispatch(GET_FUND_RAISING_ROUNDS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
      console.log(context, 'CREATE_FUND_RAISING_ROUND')
    }
  },

  async [EDIT_FUND_RAISING_ROUND](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data, fundId, onSuccess, onError }
  ) {
    try {
      await editFundRaisingRound(companyId, fundId, data)
      dispatch(GET_FUND_RAISING_ROUNDS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
      console.log(context, 'EDIT_FUND_RAISING_ROUND')
    }
  },
  async [REMOVE_FUND_RAISING_ROUND](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, id, onSuccess, onError }
  ) {
    try {
      await deleteFundRaisingRound(companyId, id)
      dispatch(GET_FUND_RAISING_ROUNDS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
      console.log(context, 'REMOVE_FUND_RAISING_ROUND')
    }
  },

  async [GET_PROFIT_SHARING_PLANS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }
  ) {
    if (
      !canMakeAction(RolePermission.writeProfitSharingPlan) &&
      !canMakeAction(RolePermission.readProfitSharingPlan)
    )
      return
    try {
      const data = await getProfitSharingPlans(companyId, filter)
      commit(SET_PROFIT_SHARING_PLANS, data)
    } catch (error: any) {
      console.log(context, 'GET_PROFIT_SHARING_PLANS')
    }
  },

  async [GET_PROFIT_SHARING_PLAN](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, id }
  ) {
    try {
      if (
        !canMakeAction(RolePermission.writeProfitSharingPlan) &&
        !canMakeAction(RolePermission.readProfitSharingPlan)
      )
        return

      const data = await getProfitSharingPlan(companyId, id)
      commit(SET_PROFIT_SHARING_PLAN, data)
    } catch (error: any) {
      console.log(context, 'GET_PROFIT_SHARING_PLAN')
    }
  },

  async [CREATE_PROFIT_SHARING_PLAN](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data, onSuccess, onError }
  ) {
    try {
      await createProfitSharingPlan(companyId, data)
      dispatch(GET_PROFIT_SHARING_PLANS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError()
      console.log(context, 'GET_PROFIT_SHARING_PLANS')
    }
  },

  async [EDIT_PROFIT_SHARING_PLAN](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data, planId, onSuccess, onError }
  ) {
    try {
      await editProfitSharingPlan(companyId, planId, data)
      dispatch(GET_PROFIT_SHARING_PLANS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError()
      console.log(context, 'EDIT_PROFIT_SHARING_PLAN')
    }
  },

  async [REMOVE_PROFIT_SHARING_PLAN](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, id, onSuccess, onError }
  ) {
    try {
      await deleteProfitSharingPlan(companyId, id)
      dispatch(GET_PROFIT_SHARING_PLANS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
      console.log(context, 'REMOVE_PROFIT_SHARING_PLAN')
    }
  },

  async [GET_OPERATIONS](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }
  ) {
    if (
      !canMakeAction(RolePermission.readOperations) ||
      !canMakeAction(RolePermission.writeOperations)
    )
      return

    try {
      const data = await getOperations(companyId, filter)
      commit(SET_OPERATIONS, data)
    } catch (error: any) {
      console.log(context, 'GET_OPERATIONS')
    }
  },

  async [GET_OPERATION](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, id }
  ) {
    try {
      const data = await getOperation(companyId, id)

      commit(SET_OPERATION, data)
    } catch (error: any) {
      console.log(context, 'GET_OPERATION')
    }
  },

  async [CREATE_OPERATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data, onSuccess, onError }
  ) {
    try {
      await createOperation(companyId, data)
      dispatch(GET_OPERATIONS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
      console.log(context, 'CREATE_OPERATION')
    }
  },

  async [EDIT_OPERATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, data, operationId, onSuccess, onError }
  ) {
    try {
      await editOperation(companyId, operationId, data)
      dispatch(GET_OPERATIONS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
      console.log(context, 'EDIT_OPERATION')
    }
  },

  async [REMOVE_OPERATION](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, id, onSuccess, onError }
  ) {
    try {
      await deleteOperation(companyId, id)
      dispatch(GET_OPERATIONS, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
      console.log(context, 'REMOVE_OPERATION')
    }
  },

  async [CREATE_COMPANY_STOCK_GROUP](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { data, companyId, onSuccess, onError }
  ) {
    try {
      await createStockGroup(companyId, data)
      dispatch(GET_COMPANY_STOCKS_GROUPS, { companyId })
      dispatch(GET_ALL_STOCK, { companyId })
      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
    }
  },

  async [EDIT_COMPANY_STOCK_GROUP](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { data, companyId, groupId, onSuccess, onError }
  ) {
    try {
      await editStockGroup(companyId, groupId, data)
      dispatch(GET_COMPANY_STOCKS_GROUPS, { companyId })
      dispatch(GET_ALL_STOCK, { companyId })

      onSuccess()
    } catch (error: any) {
      onError(error.data.message)
    }
  },

  async [DELETE_COMPANY_STOCKS_GROUP](
    { dispatch }: ActionContext<OrganizationState, RootState>,
    { companyId, groupId, onSuccess, onError }
  ) {
    try {
      await deleteStockGroup(companyId, groupId)
      dispatch(GET_COMPANY_STOCKS_GROUPS, { companyId })
      dispatch(GET_ALL_STOCK, { companyId })

      onSuccess()
    } catch (error: any) {
      console.log('ERROOR', error)
      onError(error.data.message)
    }
  },

  async [GET_BODAC](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId }
  ) {
    commit(LOADING_COMPANY_DATA, true)
    try {
      const response = await getBodac(companyId)
      commit(SET_BODAC, response)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_BODAC_PDF_URL](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, filter }
  ) {
    try {
      const response = await getBodacPdfUrl(companyId, filter)
      return response
    } catch (error: any) {
      return error
    }
  },

  async [GET_COMPANY_STOCKS_DATA](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    commit(LOADING_COMPANY_DATA, true)
    try {
      const response = await getCapitalData(companyId)
      commit(SET_COMPANY_STOCKS_DATA, response)

      if (onSuccess) onSuccess(response)
    } catch (error: any) {
      if (onError) onError()
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_COMPANY_CAPITAL_BY_GROUP](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    commit(LOADING_COMPANY_DATA, true)
    try {
      const response = await getCapitalByGroup(companyId)
      commit(SET_COMPANY_CAPITAL_BY_GROUP, response)
      onSuccess()
    } catch (error: any) {
      onError()
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_COMPANY_CAPITAL_BY_STOCK_CATEGORY](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    commit(LOADING_COMPANY_DATA, true)
    try {
      const response = await getCapitalByStockCategory(companyId)
      commit(SET_COMPANY_CAPITAL_BY_STOCK_CATEGORY, response)
      onSuccess()
    } catch (error: any) {
      onError()
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_COMPANY_CAPITAL_BY_ESOP_CATEGORY](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    commit(LOADING_COMPANY_DATA, true)
    try {
      const response = await getCapitalByEsopCategory(companyId)
      commit(SET_COMPANY_CAPITAL_BY_ESOP_CATEGORY, response)

      onSuccess()
    } catch (error: any) {
      onError()
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_PROFIT_SHARING_EVOLUTION](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    commit(LOADING_COMPANY_DATA, true)
    try {
      const response = await getProfitSharingEvolution(companyId)
      commit(SET_PROFIT_SHARING_EVOLUTION, response)
      onSuccess()
    } catch (error: any) {
      onError()
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_ESOP_DATA](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    commit(LOADING_COMPANY_DATA, true)
    try {
      const response = await getEsopInfo(companyId)
      commit(SET_ESOP_DATA, response)
      if (onSuccess) onSuccess(response)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },

  async [GET_FUNDING_DATA](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, onSuccess, onError }
  ) {
    commit(LOADING_COMPANY_DATA, true)
    try {
      const response = await getFundingData(companyId)
      commit(SET_FUNDING_DATA, response)

      if (onSuccess) onSuccess(response)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },

  async [CREATE_COMPANY_ORGANIGRAM](
    { commit }: ActionContext<OrganizationState, RootState>,
    { companyId, data, onSuccess, onError }
  ) {
    try {
      const result = await createCompanyOrganigram(companyId, data)
      commit(SET_CURRENT_ORGANIGRAM, result)
      onSuccess(result)
    } catch (error: any) {
      onError(error?.data?.message)
    }
  },

  async [ATTACH_DOCUMENTS_TO_EVENT_PART](
    { commit }: ActionContext<OrganizationState, RootState>,
    { type, companyId, eventTypeId, partId, data, onSuccess, onError }
  ) {
    commit(LOADING_COMPANY_DATA, true)
    try {
      await attachDocumentsToParts(type, companyId, eventTypeId, partId, data)
      onSuccess()
    } catch (error: any) {
      onError()
      throw new Error(error)
    } finally {
      commit(LOADING_COMPANY_DATA, false)
    }
  },
  async [DETACH_DOCUMENTS_TO_EVENT_PART](
    { commit }: ActionContext<OrganizationState, RootState>,
    { type, companyId, eventTypeId, partId, documentId, onSuccess, onError }
  ) {
    try {
      await detachDocumentsFromPart(
        type,
        companyId,
        eventTypeId,
        partId,
        documentId
      )
      onSuccess()
    } catch (error: any) {
      onError()
      throw new Error(error)
    }
  },

  ...alertActions,
  ...doctAutoActions
}
