<template>
  <div class="w-full h-full">
    <slot name="filters" />
    <div
      v-if="isLoading && loading"
      class="mt-10 flex w-full min-h-full h-full justify-center items-center"
    >
      <Loader />
    </div>
    <div v-else class="mt-10">
      <div
        v-if="documentsList.length === 0"
        class="flex justify-center items-center"
      >
        <EmptyState
          image="no-result.svg"
          :title="$t('global.noResult')"
          :subtitle="$t('global.selectFilters')"
        />
      </div>
      <div v-else class="pb-5">
        <div
          v-for="(item, index) in documentsList"
          :key="index"
          class="document cursor-pointer relative"
          @click.stop="handleDocumentClick(item)"
        >
          <div
            v-if="currentDocument?.id === item.id"
            class="
              absolute
              transition transition-all
              selected-doc-card
              -mx-4
              rounded
              z-50
            "
          ></div>
          <document-card :document="item" @select-card="selectCard" />
        </div>
        <VueEternalLoading :load="load">
          <template #loading>
            <p v-if="!noMore" class="text-primary">
              {{ $t('global.loading') }}
            </p>
            <p v-else></p>
          </template>

          <template #no-more>
            <p class="text-primary">
              {{ $t('global.noMore') }}
            </p>
          </template>

          <template #no-results>
            <EmptyState
              image="no-result.svg"
              :title="$t('global.noResult')"
              :subtitle="$t('global.selectFilters')"
            />
          </template>
        </VueEternalLoading>
      </div>
    </div>
  </div>
  <transition name="modal">
    <Modal v-if="canDelete" size="w-2/5" @close="canDelete = false">
      <template #header>
        <h3 class="text-md font-semibold text-error">
          {{ $t('documents.groupDeleteModalTextHeader') }}
        </h3>
      </template>

      <template #body>
        <p
          class="select-none"
          v-html="
            $tc('documents.groupDeleteModalText', {
              size: selectedCards.length
            })
          "
        ></p>
      </template>
      <template #footer>
        <div class="flex text-xl justify-end">
          <Button
            class="flex text-white bg-primary rounded mr-2"
            variant="custom"
            :label="$t('account.accountCompanies.deletionModalCancel')"
            @click="canDelete = false"
          />
          <Button
            class="flex text-white bg-error rounded"
            variant="custom"
            :label="$t('account.accountCompanies.deletionModalText')"
            @click.prevent="submitDeletion"
          />
        </div>
      </template>
    </Modal>
  </transition>
</template>

<script lang="ts">
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DocumentCard from '@/components/Event/DocumentCard.vue'
  import { Pagination } from '@/store/modules/Organization/models'
  import { DocumentSearchProps } from '@/types'
  import { Document } from '@/services/api/models'
  import Loader from '@/components/Loader/index.vue'
  import { VueEternalLoading, LoadAction } from '@ts-pro/vue-eternal-loading'
  import { Button } from '@up.law/uplaw-ui'
  import Modal from '@/components/Modal/Modal.vue'

  export default defineComponent({
    name: 'CorporateDocumentTab',
    components: {
      EmptyState,
      DocumentCard,
      VueEternalLoading,
      Loader,
      Button,
      Modal
    },
    props: {
      filters: {
        default: () => ({}),
        type: Object
      }
    },
    emits: ['updateCount'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { filters } = toRefs(props)
      const selectedCards = ref<string[]>([])
      const canDelete = ref(false)
      const currentFilters = ref<DocumentSearchProps>(filters.value)
      const loading = ref(true)
      const items = computed<Document[]>(
        () => store.getters['organizations/documents']
      )

      const documentsList = ref<Document[]>(
        store.getters['organizations/documents']
      )
      const isLoading = computed(
        () => store.getters['organizations/gettingDocuments']
      )

      const pagination = computed<Pagination>(
        () => store.getters['organizations/documentsPagination']
      )
      const noMore = computed(
        () => pagination.value.currentPage === pagination.value.lastPage
      )

      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      const load = async ({ loaded }: LoadAction) => {
        const data = { ...filters.value }

        if (data.draft?.length === 2) delete data.draft
        else data.isDraft = data.draft?.length == 1 ? data.draft[0] : null
        delete data.draft
        store.dispatch('organizations/LOAD_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: { ...data, offset: documentsList.value.length, limit: 10 },
          onSuccess: (response: any) => {
            if (response && response.data) {
              documentsList.value.push(...response.data)
              loaded(response.data.length, pagination.value.currentPage)
            }
          },
          onError: () => {
            //
          }
        })
      }

      const handleDocumentClick = async (doc: Document) => {
        if (currentDocument?.value?.id === doc.id) return
        store.commit('ui/DISPLAY_PREVIEW', true)
        store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }

      const getDocuments = async () => {
        const data = { ...filters.value }

        if (data.draft?.length === 2) delete data.draft
        else data.isDraft = data.draft?.length == 1 ? data.draft[0] : null
        delete data.draft

        loading.value = true
        await store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: { ...data, limit: 20, offset: 0 },
          getting: true
        })
        loading.value = false
      }

      const selectCard = (data: { check: boolean; id: string }) => {
        const { check, id } = data
        if (check) {
          selectedCards.value.push(id)
        } else {
          selectedCards.value.splice(
            selectedCards.value.findIndex((item: string) => item === id),
            1
          )
        }
      }
      const removeDocument = (id: string) => {
        store.dispatch('organizations/DELETE_COMPANY_DOCUMENT', {
          companyId: route.params.id,
          documentId: id
        })
      }
      const submitDeletion = () => {
        Promise.all(selectedCards.value.map((x) => removeDocument(x))).then(
          () => {
            getDocuments()
            canDelete.value = false
            selectedCards.value = []
          }
        )
      }

      watch(items, (val) => {
        documentsList.value = [...val]
        emit('updateCount', pagination.value.total)
      })

      watch(
        () => route.params.id,
        () => {
          filters.value = {}
          getDocuments()
        }
      )

      onMounted(async () => {
        filters.value = { limit: 10, offset: 0 }
        // call api with filter here
        getDocuments()
      })

      return {
        loading,
        noMore,
        documentsList,
        currentDocument,
        isLoading,
        selectedCards,
        canDelete,
        load,
        selectCard,
        submitDeletion,
        handleDocumentClick
      }
    }
  })
</script>
<style lang="scss" scoped>
  @import './style.scss';
</style>
