
  import { ref, defineComponent, watch, computed, onMounted } from 'vue'
  import { TabPanel, TabPanels, TabGroup, TabList, Tab } from '@headlessui/vue'
  import { UplTab } from '@/types'
  export default defineComponent({
    name: 'UplTab',
    components: { TabPanel, TabPanels, TabGroup, TabList, Tab },
    props: {
      tabs: {
        type: Object as () => UplTab[],
        required: true
      },
      defaultSelected: {
        type: String,
        required: false,
        default: null
      }
    },
    emits: ['on-selected', 'on-updated'],
    setup(props, { emit }) {
      const list = computed(() => props.tabs)
      const selectedTab = ref(0)

      const handleParentUpdate = (param1: any, param2: any, param3: any) => {
        emit('on-updated', param1, param2, param3)
      }

      const selectTab = (key: string) => {
        selectedTab.value = props.tabs.map((x) => x.key).indexOf(key)
        emit('on-selected', key)
      }
      watch(props, (val) => {
        if (selectedTab.value >= val.tabs.length) selectedTab.value = 0
      })

      onMounted(() => {
        if (props.defaultSelected) {
          selectTab(props.defaultSelected)
        }
      })

      return { selectedTab, list, handleParentUpdate, selectTab }
    }
  })
