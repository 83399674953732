<template>
  <div v-if="event">
    <div class="event-bg w-auto mb-4 flex">
      <div class="p-3 border-r border-secondary6 w-1/3">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.label') }}
        </h1>
        <p class="text-blackp text-sm text-left">
          {{ event.label }}
        </p>
      </div>
      <div class="p-3 border-r border-secondary6 w-1/3">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.date') }}
        </h1>
        <p class="text-blackp text-sm text-left">
          {{ moment.utc(event.date).format('L') }}
        </p>
      </div>
      <div class="p-3 border-r border-secondary6 w-1/3">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.endDate') }}
        </h1>
        <p class="text-blackp text-sm text-left">
          {{ moment.utc(event.endDate).format('L') }}
        </p>
      </div>
      <div class="p-3 border-r border-secondary6 w-1/3">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.type') }}
        </h1>
        <p class="text-blackp text-sm text-left">
          {{ $t(`CommitmentTypeEnum.${event.type}`) }}
        </p>
      </div>
      <div class="p-3 w-1/3">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.commitmentMode') }}
        </h1>
        <p v-if="event.mode" class="text-blackp text-sm text-left">
          {{ $t(`CommitmentMode.${event.mode}`) }}
        </p>
        <p v-else class="text-blackp text-sm text-left">-</p>
      </div>
    </div>
    <div class="flex overflow-scroll grid grid-cols-5">
      <div class="col-span-2 p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.contact') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.stock') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.quantity') }}
        </h1>
      </div>
      <div class="p-3 flex items-end">
        <h1 class="text-left text-secondary8 text-xs font-light">
          {{ $t('events.elements.ownershipType') }}
        </h1>
      </div>
    </div>
    <div class="event-bg">
      <div
        v-for="(part, index) in parts"
        :key="part.id"
        :class="`w-full flex-wrap flex grid grid-cols-5 ${
          contactsId.includes(part.contact?.id) ? 'bg-active' : ''
        } ${index > 0 ? 'border-t border-secondary6 ' : ''}`"
      >
        <div
          class="
            p-2
            border-r border-secondary6
            col-span-2
            flex
            items-center
            justify-start
          "
        >
          <p v-if="part.contact" class="text-blackp text-sm text-left">
            <router-link
              :target="`${company?.id ? '_blank' : ''}`"
              :to="{
                name: 'company.directory',
                params: {
                  id: company?.id || $route.params.id,
                  contactId: part.contact.id
                }
              }"
            >
              <ContactBadge
                class="p-0 text-xs"
                :contact="part.contact"
                :details="false"
                type="light"
              />
            </router-link>
          </p>
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>
        <div class="p-3 border-r border-secondary6">
          <Tag
            v-if="part.compositionAlias"
            :text="part.compositionAlias.alias.toUpperCase()"
            :value="part.compositionAlias.id"
            :with-initials="false"
            :with-remove="false"
          />

          <Tag
            v-else-if="part.stockAlias"
            :text="part.stockAlias.alias.toUpperCase()"
            :value="part.stockAlias.id"
            :with-initials="false"
            :with-remove="false"
          />
          <p v-else class="text-blackp text-sm text-left">-</p>
        </div>

        <div
          class="p-3 border-r border-secondary6 flex items-center justify-end"
        >
          <p class="text-blackp text-sm text-left">
            {{ formatNumber(part.quantity) }}
          </p>
        </div>

        <div class="p-3 flex items-center justify-end">
          <p class="text-blackp text-sm text-left">
            {{ $t(`CoOwnershipType.${part.ownershipType}`) }}
          </p>
        </div>
      </div>
      <div
        :class="`grid grid-cols-5 ${
          parts.length > 0 ? 'border-t border-secondary6 ' : ''
        } h-10 flex`"
      >
        <div
          class="
            col-span-3
            border-r border-secondary6
            h-10
            flex
            items-center
            justify-end
          "
        >
          <p class="text-right px-2 font-semibold">Total</p>
        </div>

        <div
          class="border-r border-secondary6 h-10 flex items-center justify-end"
        >
          <p class="text-right px-2 font-semibold">
            {{ formatNumber(totalQuantity) }}
          </p>
        </div>
        <div class="h-10 flex items-center justify-end">
          <p class="text-right px-2 font-semibold">-</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Commitment, Company } from '@/services/api/models'
  import { computed, defineComponent, onMounted } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { Tag } from '@up.law/uplaw-ui'
  import ContactBadge from '@/components/ContactBadge/index.vue'

  export default defineComponent({
    name: 'CommitmentCard',
    components: {
      Tag,
      ContactBadge
    },
    props: {
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      event: {
        type: Object as () => Commitment | undefined,
        default: null
      }
    },
    setup(props) {
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const totalQuantity = computed(() => {
        return (props.event?.commitmentParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity),
          0
        )
      })
      const parts = computed(() => props.event?.commitmentParts || [])

      return { moment, parts, contactsId, totalQuantity, formatNumber }
    }
  })
</script>

<style lang="scss" scoped>
  .event-bg {
    background: var(--color-contrast-10);
    @apply border border-secondary6 rounded;
  }
</style>
