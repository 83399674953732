<template>
  <div :class="` relative flex overflow-scroll flex grid grid-cols-${grids}`">
    <div
      v-for="col in columnsData"
      :key="col.id"
      :class="`p-3 flex w-full items-end  ${col.class}`"
    >
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ col.name }}
      </h1>
    </div>
  </div>
  <div class="border border-secondary6 rounded">
    <table-line
      v-for="(line, index) in lines"
      :key="index"
      :grids="grids"
      :can-delete="canDeleteLine"
      :columns="columns"
      :with-actions="true"
      :index="index"
      :line="line"
      :is-selected="line?.partId && filteredParts.indexOf(line?.partId) != -1"
      :can-edit="canEditLine"
      :part-id="line['partId']?.toString()"
      :is-editing="editingParts.includes(line['partId']?.toString())"
      @start-edition="editLine"
      @validate-edition="validateLine"
      @cancel-edition="cancelEdition"
      @remove-line="removeLine"
      @save-new-line="validateNewLine"
      @remove-new-line="cancelLine"
      @docAutoActionsClick="handleActions"
    />

    <div v-show="displayTotal" :class="`flex grid grid-cols-${grids}`">
      <div
        v-for="(line, idx) in totalLine"
        :key="idx"
        :class="`border-t ${
          idx == (totalLine?.length ?? 1) - 1 ? '' : 'border-r'
        } ${line.class} border-secondary6 flex items-center justify-end p-3 `"
      >
        <p v-if="line.value" class="text-right px-2">
          {{ line.value }}
        </p>
        <p v-else class="text-right px-2 font-semibold">
          {{ line.name }}
        </p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'

  import TableLine from './line.vue'
  export interface EditableLineData {
    partId: string
    newIndex?: number
    class: string
    colName: string
    componentProps: any
    component: string
    editComponent: string
    editComponentProps: any
  }

  export interface EditableColumn {
    name: string
    class: string
    width: string
    id: string
  }

  export interface EditableTotalLine {
    name?: string
    value?: any
    class: string
    key: string
    width?: string
  }

  export interface EditableLine {
    [key: string]: EditableLineData | any
  }

  export default defineComponent({
    components: {
      TableLine
    },
    props: {
      filtered: { type: Object },
      lines: {
        type: Object as () => EditableLine[]
      },
      totalLine: {
        type: Object as () => EditableTotalLine[]
      },
      columns: {
        type: Object as () => EditableColumn[]
      },
      displayTotal: { type: Boolean, default: true },
      grids: { type: Number, required: true },
      canEditLine: { type: Boolean, default: false },
      canDeleteLine: { type: Boolean, default: false }
    },
    emits: [
      'removePart',
      'removeNewLine',
      'saveLine',
      'saveNewPart',
      'resetLine',
      'startLineEdition',
      'docAutoActionsClick'
    ],
    setup(props, { emit }) {
      const initialLines = ref<EditableLine[]>([])
      const filteredParts = computed(() => props.filtered?.partsId ?? [])
      const columnsData = computed(() =>
        (props.columns ?? []).map((x) => ({
          name: x.name,
          w: x.width,
          class: x.class,
          id: x.id
        }))
      )

      const editingParts = ref<string[]>([])
      const cancelEdition = (partId: string) => {
        const index = props.lines?.findIndex((x) => x.partId == partId) ?? -1
        editingParts.value.splice(editingParts.value.indexOf(partId), 1)
        emit('resetLine', index, partId) // reset old values
      }

      const handleActions = (a: any, b: any, c: any) => {
        emit('docAutoActionsClick', a, b, c)
      }

      const cancelLine = (index: number) => {
        emit('removeNewLine', index)
      }
      const removeLine = (partId: any, index: number, handleResponse: any) => {
        emit(
          'removePart',
          partId,
          (partId: string, data: any) => {
            handleResponse(true)
          },
          (partId: string, data: any) => {
            onError(partId, data, handleResponse)
          }
        )
      }
      const onLineSaved = (partId: string, data: any, handleResponse: any) => {
        editingParts.value.splice(editingParts.value.indexOf(partId), 1)
        handleResponse(true)
      }

      const onError = (partId: string, data: any, handleResponse: any) => {
        handleResponse(true)
      }

      const validateLine = (
        partId: string,
        index: number,
        handleResponse: any
      ) => {
        emit(
          'saveLine',
          partId,
          (partId: string, data: any) => {
            onLineSaved(partId, data, handleResponse)
          },
          (partId: string, data: any) => {
            onError(partId, data, handleResponse)
          }
        )
      }

      const validateNewLine = (
        index: number,
        arrayIndex: number,
        handleResponse: any
      ) => {
        emit(
          'saveNewPart',
          index,
          arrayIndex,
          (partId: string, data: any) => {
            onLineSaved(partId, data, handleResponse)
            cancelLine(index)
          },
          (partId: string, data: any) => {
            onError(partId, data, handleResponse)
          }
        )
      }

      const editLine = (partId: string, index: number) => {
        editingParts.value.push(partId)
        emit('startLineEdition', partId, index)
      }

      onMounted(() => {
        initialLines.value = [...(props.lines ?? [])]
      })

      return {
        columnsData,
        editingParts,
        filteredParts,
        initialLines,
        cancelEdition,
        validateLine,
        removeLine,
        validateNewLine,
        editLine,
        handleActions,
        cancelLine
      }
    }
  })
</script>
