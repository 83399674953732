import {
  STOCKTYPESALIAS,
  SampleStockTypeFormData,
  CompositeStockTypeFormData,
  OptionStockTypeFormData
} from '@/types/forms'
import {
  StockAlias,
  OptionStockAlias,
  CompositionStockAlias,
  StockIssuance,
  OptionIssuance
} from '../models/company'
import { company } from '../endpoints'
import axios from '../axios'
import { OptionIssuanceFormData, StockIssuanceFormData } from '@/types/forms'

const createStock = async (
  formData: any,
  companyId: string,
  type: STOCKTYPESALIAS
) => {
  return await axios.post(company.stockCreation(companyId, type), formData)
}

const editStock = async (
  formData:
    | SampleStockTypeFormData
    | CompositeStockTypeFormData
    | OptionStockTypeFormData,
  companyId: string,
  type: STOCKTYPESALIAS,
  stockId: string
) => {
  return await axios.put(
    company.singleStockAlias(companyId, type, stockId),
    formData
  )
}

const deleteStock = async (
  companyId: string,
  type: STOCKTYPESALIAS,
  stockId: string
) => {
  return await axios.delete(company.singleStockAlias(companyId, type, stockId))
}

const getStockAlias = async (
  companyId: string,
  type: STOCKTYPESALIAS,
  filter = {}
): Promise<StockAlias[]> => {
  const { data } = await axios.get(company.getStockAlias(companyId, type), {
    params: { limit: 30, offset: 0, ...filter }
  })
  return data
}

const verifyStockAlias = async (
  companyId: string,
  stockId: string
): Promise<any> => {
  const { data } = await axios.patch(company.verifyStock(companyId, stockId))
  return data
}

const getSingleStockAlias = async (
  companyId: string,
  type: STOCKTYPESALIAS,
  stockId: string
): Promise<StockAlias | OptionStockAlias | CompositionStockAlias> => {
  const { data } = await axios.get(
    company.singleStockAlias(companyId, type, stockId),
    { params: { limit: 30, offset: 0 } }
  )
  return data
}

const createPrimaryStockEmission = async (
  companyId: string,
  data: StockIssuanceFormData
): Promise<StockIssuance> => {
  return await axios.post(company.getPrimaryStocks(companyId), data)
}

const editPrimaryStockEmission = async (
  companyId: string,
  eventId: string,
  data: StockIssuanceFormData
): Promise<StockIssuance> => {
  return await axios.put(company.singlePrimaryStock(companyId, eventId), data)
}
const createPrimaryOptionsEmission = async (
  companyId: string,
  data: OptionIssuanceFormData
): Promise<OptionIssuance> => {
  return await axios.post(company.getPrimaryOptions(companyId), data)
}

const getStockIssuanceParts = async (
  companyId: string,
  stockIssuanceId: string,
  offset = 0,
  limit = 10,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(
    company.getStockIssuanceParts(companyId, stockIssuanceId),
    { params: { offset, limit, ...filter } }
  )
}

const getStockIssuancePartsTotal = async (
  companyId: string,
  stockIssuanceId: string,

  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(
    company.getStockIssuancePartsTotal(companyId, stockIssuanceId),
    { params: { ...filter } }
  )
}
const getOptionIssuanceParts = async (
  companyId: string,
  optionIssuanceId: string,
  offset = 0,
  limit = 10,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(
    company.getOptionIssuanceParts(companyId, optionIssuanceId),
    { params: { offset, limit, ...filter } }
  )
}
const getOptionIssuancePartsTotal = async (
  companyId: string,
  optionIssuanceId: string,

  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(
    company.getOptionIssuancePartsTotal(companyId, optionIssuanceId),
    { params: { ...filter } }
  )
}
const getCompositionIssuanceParts = async (
  companyId: string,
  compositionIssuanceId: string,
  offset = 0,
  limit = 10,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(
    company.getCompositionIssuanceParts(companyId, compositionIssuanceId),
    { params: { offset, limit, ...filter } }
  )
}

const getCompositionIssuancePartsTotal = async (
  companyId: string,
  compositionIssuanceId: string,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(
    company.getCompositionIssuancePartsTotal(companyId, compositionIssuanceId),
    { params: { ...filter } }
  )
}

const getTransferParts = async (
  companyId: string,
  transferId: string,
  offset = 0,
  limit = 10,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(company.transferParts(companyId, transferId), {
    params: { offset, limit, ...filter }
  })
}

const getOptionExerciseParts = async (
  companyId: string,
  optionExerciseId: string,
  offset = 0,
  limit = 10,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(
    company.optionExerciseParts(companyId, optionExerciseId),
    {
      params: { offset, limit, ...filter }
    }
  )
}

const getOptionSunsetParts = async (
  companyId: string,
  optionSunsetId: string,
  offset = 0,
  limit = 10,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(company.optionSunsetParts(companyId, optionSunsetId), {
    params: { offset, limit, ...filter }
  })
}

const getOptionExercisePartsTotal = async (
  companyId: string,
  optionExerciseId: string,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(
    company.optionExercisePartsTotal(companyId, optionExerciseId),
    {
      params: { ...filter }
    }
  )
}

const getOptionSunsetPartsTotal = async (
  companyId: string,
  optionSunsetId: string,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(
    company.optionSunsetPartsTotal(companyId, optionSunsetId),
    {
      params: { ...filter }
    }
  )
}
const getTransferPartsTotal = async (
  companyId: string,
  transferId: string,
  filter: any
): Promise<OptionIssuance> => {
  return await axios.get(company.transferPartsTotal(companyId, transferId), {
    params: { ...filter }
  })
}
const editPrimaryOptionsEmission = async (
  companyId: string,
  eventId: string,
  data: OptionIssuanceFormData
): Promise<OptionIssuance> => {
  return await axios.put(company.singlePrimaryOption(companyId, eventId), data)
}
const createPrimaryCompositionEmission = async (
  companyId: string,
  data: OptionIssuanceFormData
): Promise<any> => {
  return await axios.post(company.getPrimaryComposition(companyId), data)
}
const editPrimaryCompositionEmission = async (
  companyId: string,
  eventId: string,
  data: OptionIssuanceFormData
): Promise<any> => {
  return await axios.put(
    company.singlePrimaryComposition(companyId, eventId),
    data
  )
}
const getPrimaryStockEmission = async (
  companyId: string
): Promise<StockIssuance[]> => {
  return await axios.get(company.getPrimaryStocks(companyId))
}
const getPrimaryOptionsEmission = async (
  companyId: string
): Promise<OptionIssuance[]> => {
  return await axios.get(company.getPrimaryOptions(companyId))
}
const getPrimaryCompositionEmission = async (
  companyId: string
): Promise<any> => {
  return await axios.get(company.getPrimaryComposition(companyId))
}

const removeStockIssuancePart = async (
  companyId: string,
  stockIssuanceId: string,
  partId: string
) => {
  return await axios.delete(
    company.getStockIssuancePart(companyId, stockIssuanceId, partId)
  )
}
const removeCompositionIssuancePart = async (
  companyId: string,
  compositionIssuanceId: string,
  partId: string
) => {
  return await axios.delete(
    company.getCompositionIssuancePart(companyId, compositionIssuanceId, partId)
  )
}
const removeTransferPart = async (
  companyId: string,
  transferId: string,
  partId: string
) => {
  return await axios.delete(company.transferPart(companyId, transferId, partId))
}

const removeOptionExercisePart = async (
  companyId: string,
  optionExerciseId: string,
  partId: string
) => {
  return await axios.delete(
    company.optionExercisePart(companyId, optionExerciseId, partId)
  )
}

const removeOptionSunsetPart = async (
  companyId: string,
  optionSunsetId: string,
  partId: string
) => {
  return await axios.delete(
    company.optionSunsetPart(companyId, optionSunsetId, partId)
  )
}

const editStockIssuancePart = async (
  companyId: string,
  stockIssuanceId: string,
  partId: string,
  data: any
) => {
  return await axios.put(
    company.getStockIssuancePart(companyId, stockIssuanceId, partId),
    data
  )
}

const editOptionIssuancePart = async (
  companyId: string,
  optionIssuanceId: string,
  partId: string,
  data: any
) => {
  return await axios.put(
    company.getOptionIssuancePart(companyId, optionIssuanceId, partId),
    data
  )
}

const editCompositionIssuancePart = async (
  companyId: string,
  compositionIssuanceId: string,
  partId: string,
  data: any
) => {
  return await axios.put(
    company.getCompositionIssuancePart(
      companyId,
      compositionIssuanceId,
      partId
    ),
    data
  )
}
const editTransferPart = async (
  companyId: string,
  transferId: string,
  partId: string,
  data: any
) => {
  return await axios.put(
    company.transferPart(companyId, transferId, partId),
    data
  )
}

const addOptionExercisePart = async (
  companyId: string,
  optionExerciseId: string,
  data: any
) => {
  return await axios.post(
    company.optionExerciseParts(companyId, optionExerciseId),
    data
  )
}

const addOptionSunsetPart = async (
  companyId: string,
  optionSunsetId: string,
  data: any
) => {
  return await axios.post(
    company.optionSunsetParts(companyId, optionSunsetId),
    data
  )
}

const editOptionExercisePart = async (
  companyId: string,
  optionExerciseId: string,
  partId: string,
  data: any
) => {
  return await axios.put(
    company.optionExercisePart(companyId, optionExerciseId, partId),
    data
  )
}

const editOptionSunsetPart = async (
  companyId: string,
  optionSunsetId: string,
  partId: string,
  data: any
) => {
  return await axios.put(
    company.optionSunsetPart(companyId, optionSunsetId, partId),
    data
  )
}

const editStockIssuancePrimaryInfo = async (
  companyId: string,
  stockIssuanceId: string,
  data: any
) => {
  return await axios.put(
    company.getStockIssuancePrimaryInfo(companyId, stockIssuanceId),
    data
  )
}
const editOptionSunsetPrimaryInfo = async (
  companyId: string,
  optionSunsetId: string,
  data: any
) => {
  return await axios.put(
    company.optionSunsetPrimaryInfo(companyId, optionSunsetId),
    data
  )
}

const editOptionIssuancePrimaryInfo = async (
  companyId: string,
  optionIssuanceId: string,
  data: any
) => {
  return await axios.put(
    company.getOptionIssuancePrimaryInfo(companyId, optionIssuanceId),
    data
  )
}

const editOptionExercisePrimaryInfo = async (
  companyId: string,
  optionExerciseId: string,
  data: any
) => {
  return await axios.put(
    company.optionExercisePrimaryInfo(companyId, optionExerciseId),
    data
  )
}

const editCompositionIssuancePrimaryInfo = async (
  companyId: string,
  compositionIssuanceId: string,
  data: any
) => {
  return await axios.put(
    company.getCompositionIssuancePrimaryInfo(companyId, compositionIssuanceId),
    data
  )
}

const editTransferPrimaryInfo = async (
  companyId: string,
  transferId: string,
  data: any
) => {
  return await axios.put(
    company.getTransferPrimaryInfo(companyId, transferId),
    data
  )
}

const addStockIssuancePart = async (
  companyId: string,
  stockIssuanceId: string,
  data: any
) => {
  return await axios.post(
    company.getStockIssuanceParts(companyId, stockIssuanceId),
    data
  )
}

const addOptionIssuancePart = async (
  companyId: string,
  optionIssuanceId: string,
  data: any
) => {
  return await axios.post(
    company.getOptionIssuanceParts(companyId, optionIssuanceId),
    data
  )
}

const addCompositionIssuancePart = async (
  companyId: string,
  compositionIssuanceId: string,
  data: any
) => {
  return await axios.post(
    company.getCompositionIssuanceParts(companyId, compositionIssuanceId),
    data
  )
}
const addTransferPart = async (
  companyId: string,
  transferId: string,
  data: any
) => {
  return await axios.post(company.transferParts(companyId, transferId), data)
}
const removeOptionIssuancePart = async (
  companyId: string,
  optionIssuanceId: string,
  partId: string
) => {
  return await axios.delete(
    company.getOptionIssuancePart(companyId, optionIssuanceId, partId)
  )
}

export {
  getSingleStockAlias,
  getStockAlias,
  deleteStock,
  editStock,
  createStock,
  createPrimaryCompositionEmission,
  getPrimaryCompositionEmission,
  getPrimaryOptionsEmission,
  getPrimaryStockEmission,
  createPrimaryOptionsEmission,
  createPrimaryStockEmission,
  editPrimaryCompositionEmission,
  editPrimaryStockEmission,
  editPrimaryOptionsEmission,
  getStockIssuanceParts,
  getOptionIssuanceParts,
  editOptionIssuancePart,
  editCompositionIssuancePart,
  editStockIssuancePrimaryInfo,
  editOptionIssuancePrimaryInfo,
  removeCompositionIssuancePart,
  removeStockIssuancePart,
  getCompositionIssuanceParts,
  editStockIssuancePart,
  addCompositionIssuancePart,
  editCompositionIssuancePrimaryInfo,
  addStockIssuancePart,
  addOptionIssuancePart,
  getTransferParts,
  getOptionIssuancePartsTotal,
  getTransferPartsTotal,
  removeTransferPart,
  editTransferPrimaryInfo,
  editTransferPart,
  getStockIssuancePartsTotal,
  addTransferPart,
  getCompositionIssuancePartsTotal,
  removeOptionIssuancePart,
  verifyStockAlias,
  getOptionExerciseParts,
  addOptionExercisePart,
  removeOptionExercisePart,
  getOptionExercisePartsTotal,
  removeOptionSunsetPart,
  editOptionExercisePrimaryInfo,
  getOptionSunsetPartsTotal,
  getOptionSunsetParts,
  editOptionExercisePart,
  editOptionSunsetPrimaryInfo,
  editOptionSunsetPart,
  addOptionSunsetPart
}
