<template>
  <div
    :class="`
      relative
      grid grid-cols-${fields.length}
      border border-secondary6
      rounded
      event-editable-header`"
  >
    <div
      class="absolute flex"
      style="top: 0; right: 0; margin-top: -30px"
      v-if="canEdit || isEditing"
    >
      <div
        v-if="isValidating"
        class="ml-2 w-10 h-10 loader flex justify-center items-center"
      >
        <svg viewBox="25 25 50 50">
          <circle r="20" cy="50" cx="50"></circle>
        </svg>
      </div>
      <el-tooltip
        v-if="!isValidating && isEditing"
        class="box-item"
        effect="dark"
        :content="$t('events.tooltips.lineEdit')"
        placement="top"
      >
        <Button
          variant="custom"
          size="medium"
          class="p-1 rounded-xl bg-secondary text-white"
          :label="$t('global.actions.editLine')"
          @click="start()"
        >
          <i class="bi bi-pen text-md mr-1"></i>
        </Button>
      </el-tooltip>
      <div v-if="!isValidating && canEdit" class="mr-2">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('events.tooltips.cancelLine')"
          placement="top"
        >
          <Button
            variant="custom"
            size="medium"
            :label="$t('global.actions.cancelLineEdition')"
            class="p-1 rounded-md action-btn bg-error text-white"
            @click="cancelLine()"
          >
            <i class="bi bi-x text-md"></i>
          </Button>
        </el-tooltip>
      </div>
      <el-tooltip
        v-if="!isValidating && canEdit"
        class="box-item"
        effect="dark"
        :content="$t('events.tooltips.saveLine')"
        placement="top"
      >
        <Button
          variant="custom"
          size="medium"
          :label="$t('global.actions.validateLine')"
          class="p-1 rounded-md action-btn bg-success text-white"
          @click="edit()"
        >
          <i class="bi bi-check text-md"></i>
        </Button>
      </el-tooltip>
    </div>
    <div
      :class="`p-3 ${
        index < fields?.length - 1 ? 'border-r' : ''
      } border-secondary6`"
      v-for="(field, index) in fields"
      :key="index"
    >
      <h1 class="text-left text-secondary8 text-xs font-light">
        {{ field.colName }}
      </h1>
      <p
        v-if="!canEdit && field.component == 'p'"
        v-bind="field?.componentProps"
      >
        {{ field.componentProps.value }}
      </p>
      <p :class="field.componentProps.parentClass ?? ''">
        <component
          :is="canEdit ? field.editComponent : field.component"
          v-bind="canEdit ? field.editComponentProps : field.componentProps"
          @onUpdated="field?.editComponentProps?.onUpdated"
        />
      </p>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue'
  import { Tag, Button } from '@up.law/uplaw-ui'
  import DropdownProfitSharingPlans from '@/components/Forms/DropDowns/DropdownProfitSharingPlans.vue'
  import DropdownActions from '@/components/Forms/DropDowns/DropdownActions.vue'
  import DropdownDelegations from '@/components/Forms/DropDowns/DropdownDelegations.vue'
  import DropdownFundRaisingRounds from '@/components/Forms/DropDowns/DropdownFundRaisingRounds.vue'
  import { ElTooltip } from 'element-plus'
  import DateGeneric from '@/components/Forms/Event/Generic/DateGeneric.vue'
  import DropdownOptions from '@/components/Forms/DropDowns/DropdownOptions.vue'
  import DropdownComposition from '@/components/Forms/DropDowns/DropdownComposition.vue'
  import DropdownGeneric from '@/components/Forms/DropDowns/DropdownGeneric.vue'
  export interface EditableHeaderData {
    fieldId: string
    class: string
    colName: string
    componentProps: any
    component: string
    editComponent: string
    editComponentProps: any
  }
  export default defineComponent({
    props: {
      canEdit: { type: Boolean, default: false },
      isEditing: { type: Boolean, default: false },
      fields: {
        type: Array as () => EditableHeaderData[],
        required: true
      },
      columnsPerLine: {
        type: Number,
        default: 4
      }
    },
    emits: ['save', 'startEdition', 'cancelEdition'],
    components: {
      Tag,
      Button,
      DateGeneric,
      ElTooltip,
      DropdownGeneric,
      DropdownProfitSharingPlans,
      DropdownFundRaisingRounds,
      DropdownDelegations,
      DropdownActions,
      DropdownComposition,
      DropdownOptions
    },
    setup(props, { emit }) {
      const isValidating = ref(false)
      const dataSaved = ref(false)

      const onSuccess = () => {
        //
        isValidating.value = false
      }
      const onError = () => {
        isValidating.value = false
      }
      const start = () => {
        emit('startEdition')
      }

      const cancelLine = () => {
        emit('cancelEdition', true)
      }

      const edit = () => {
        isValidating.value = true
        emit('save', onSuccess, onError)
      }

      return { isValidating, edit, start, cancelLine }
    }
  })
</script>

<style lang="scss" scoped>
  .event-editable-header {
    .loader {
      svg {
        width: 2.25em;
        transform-origin: center;
        animation: rotate4 2s linear infinite;
      }

      circle {
        fill: none;
        stroke: hsl(214, 97%, 59%);
        stroke-width: 2;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        animation: dash4 1.5s ease-in-out infinite;
      }

      @keyframes rotate4 {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes dash4 {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }

        50% {
          stroke-dasharray: 90, 200;
          stroke-dashoffset: -35px;
        }

        100% {
          stroke-dashoffset: -125px;
        }
      }
    }
  }
</style>
