<template>
  <div class="px-4 py-5 w-full">
    <div>
      <div class="bg-white rounded w-96">
        <div class="p-5">
          <div>
            <img src="/imgs/services/docusign.svg" alt="" class="h-8" />
          </div>
          <div class="mt-4">
            <h3 class="text-md font-medium text-left">
              {{ $t(`thirdPartyServices.form.name`) }}- {{ company?.name }}
            </h3>

            <div class="mt-3">
              <p class="text-secondary text-left">
                {{ $t(`thirdPartyServices.form.docuSignDescription`) }}
              </p>
            </div>
          </div>
        </div>

        <div class="border-t border-secondary1 py-4 px-5">
          <div class="text-center font-medium">
            <div class="">
              <a
                v-if="docuSign?.status != IntegrationStatus.linked"
                :href="docusignConsentLink"
                class="
                  text-primary3 text-center
                  uppercase
                  font-medium
                  cursor-pointer
                  mt-3
                "
              >
                {{ $t(`thirdPartyServices.form.link`) }}
              </a>
              <div v-else class="flex justify-between">
                <span class="text-primary3">
                  {{ $t(`thirdPartyServices.status.${docuSign?.status}`) }}
                </span>
                <span class="" @click.prevent="deleteService">
                  <i class="bi bi-dash-circle text-error"></i
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { IntegratedService, IntegrationStatus } from '@/services/api/models'
  import { GlobalConfig, IntegrationType } from '@/types/config'
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { useStore } from 'vuex'
  import conf from '@/config'

  export default defineComponent({
    components: {},
    setup() {
      const config = conf as GlobalConfig
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const { t } = useI18n()
      const company = computed(
        () => store.getters['organizations/contextCompany']
      )

      const docuSign = ref<IntegratedService | null>()
      const docusignStep = ref(0)
      const docuSignCompaniesToLink = computed(() => [
        route.params?.id ?? company.value?.id
      ])
      const docusignConsentLink = computed(
        () =>
          `${
            config.docuSign.host
          }?response_type=code&state=${docuSignCompaniesToLink.value.toString()}&scope=openid&client_id=${
            config.docuSign.clientId
          }&redirect_uri=${config.docuSign.redirectUri}`
      )

      const get = () => {
        if (!route.params?.id && !company.value?.id) return
        store.dispatch('organizations/GET_INTEGRATED_SERVICES', {
          companyId: route.params?.id ?? company.value?.id,
          onSuccess: (response: IntegratedService[]) => {
            docuSign.value = response.find(
              (x) => x.integrationType == IntegrationType.docusign
            )
          },
          onError: () => {
            ///
          }
        })
      }

      const save = () => {
        if (route.fullPath.split('?next').length <= 1) return
        store.dispatch('organizations/SAVE_INTEGRATED_SERVICE_DATA', {
          companyId:
            route.query.state?.toString() ??
            route.query?.state ??
            route.params?.id ??
            company.value?.id,
          data: {
            integrationType: IntegrationType.docusign,
            authorizationCode: route.query.code?.toString()
          },
          onSuccess: () => {
            get()
            const q = { ...route.query }
            delete q.state
            delete q.code

            const p: any = { id: q.state }

            router.push({
              name: route.name ?? '',
              params: { ...p },
              query: { ...q }
            })
          },
          onError: () => {
            //
          }
        })
      }

      const deleteService = () => {
        store.dispatch('organizations/DELETE_INTEGRATED_SERVICE', {
          companyId: company.value.id,
          service: IntegrationType.docusign,

          onSuccess: () => {
            get()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('thirdPartyServices.form.title'),
              message: t(`thirdPartyServices.apiResponse.service_deleted`)
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('thirdPartyServices.form.title'),
              message: t(
                `thirdPartyServices.apiResponse.service_deletion_error`
              )
            })
          }
        })
      }

      onMounted(() => {
        get()
        save()
      })

      return {
        company,
        IntegrationStatus,
        docusignStep,
        docuSign,
        docusignConsentLink,
        docuSignCompaniesToLink,
        deleteService
      }
    }
  })
</script>

<style></style>
