
  import { defineComponent, computed, ref, onMounted, watch } from 'vue'
  import { CompanyModel, ExerciseCalendar } from '@/services/api/models/company'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Input } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { useStore } from 'vuex'
  import { Captable, NumberType } from '@/types'
  import Number from '@/components/Number/index.vue'
  import { useRoute } from 'vue-router'
  import { formatNumber } from '@/utils'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'

  export default defineComponent({
    name: 'SimulationTab',
    components: { Multiselect, Field, Input, Number, Loader },
    setup() {
      const route = useRoute()
      const optionId = ref([])
      const pricePerAction = ref()
      const companyValorisationAmount = ref<any>()
      const store = useStore()
      const isLoadingCaptable = ref(false)
      const isLoadingCalendar = ref(false)
      const isLoading = computed(
        () => isLoadingCaptable.value || isLoadingCalendar.value
      )
      const exerciceCalendars = computed(
        () => store.getters['organizations/exerciseCalendars']
      )
      const captable = computed<Captable>(
        () => store.getters['organizations/captable']
      )
      const currency = computed(() => store.getters['organizations/currency'])

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const focusedField = ref('')
      const selectedCalendars = computed<ExerciseCalendar[]>(() =>
        optionId.value.map((op: string) => {
          const [exerciseId] = op?.split('@_')
          return exerciseId
            ? exerciceCalendars.value.find(
                (x: ExerciseCalendar) => x.id === exerciseId
              )
            : null
        })
      )

      const savedLastStockPrice = computed<number>(() =>
        selectedCalendars.value.length
          ? selectedCalendars.value[0].lastStockPrice
          : 0
      )

      const simulationLastStockPrice = computed<number>(() =>
        currentCompany.value.populateSimulationForm
          ? savedLastStockPrice.value
          : 0
      )

      const potentialFullAddedValue = computed(() =>
        parseInt(
          selectedCalendars.value
            .reduce(
              (acc, exerciceCalendar) =>
                (acc +=
                  (parseFloat(pricePerAction.value) -
                    (exerciceCalendar?.optionIssuancePart.optionIssuance
                      .exercisePrice || 0)) *
                  ((exerciceCalendar?.optionIssuancePart?.quantity || 0) -
                    (exerciceCalendar?.numberOfSunsetedOptions ?? 0))),
              0
            )
            .toString()
        )
      )

      const potentialVestedAddedValue = computed(() =>
        parseInt(
          selectedCalendars.value
            .reduce(
              (acc, exerciceCalendar) =>
                (acc +=
                  (parseFloat(pricePerAction.value) -
                    (exerciceCalendar?.optionIssuancePart.optionIssuance
                      .exercisePrice || 0)) *
                  (exerciceCalendar?.vestedQuantity || 0)),
              0
            )
            .toString()
        )
      )

      const options = computed(() =>
        exerciceCalendars.value.map((x: ExerciseCalendar) => ({
          ...x.optionAlias,
          name: `${x.optionAlias.alias} ${
            x.optionIssuancePart?.vestingCalendar
              ? `(${x.optionIssuancePart?.vestingCalendar?.name})`
              : ''
          }`,
          id: `${x.id}@_${x.optionAlias.id}`,
          exerciseId: x.id
        }))
      )
      const calculate = () => {
        const [computedValorisationValue] = formatNumber(
          (simulationLastStockPrice.value || 0) * (captable.value?.allNFD || 0)
        )
          ?.toString()
          .replaceAll('.', ' ')
          ?.split(',')
        pricePerAction.value = (simulationLastStockPrice.value || 0).toFixed(2)
        companyValorisationAmount.value = computedValorisationValue
      }

      const handleFocus = (field: string) => {
        focusedField.value = field
      }

      const getCaptable = () => {
        store.dispatch('organizations/GET_CAPTABLE', {
          companyId: route.params.id,
          filter: {
            date: moment().toISOString(),
            contactId: [route.params.contactId]
          },
          onSuccess: () => {
            isLoadingCaptable.value = false
          },
          onError() {
            isLoadingCaptable.value = false
          }
        })
      }

      const getVesting = async () => {
        await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            limit: 100,
            offset: 0,
            date: moment().toISOString(),
            contactId: route.params.contactId
          },
          onSuccess: () => {
            isLoadingCalendar.value = false
          }
        })
      }

      watch(
        () => optionId.value,
        () => {
          focusedField.value = ''
          calculate()
        }
      )

      watch(
        () => pricePerAction.value,
        (value) => {
          const entered =
            !value || isNaN(value?.toString()?.replace(',', '.'))
              ? 0
              : value?.toString()?.replace(',', '.')
          if (focusedField.value == 'pricePerAction') {
            const calcul =
              (parseFloat(entered?.toString()?.replace(',', '.')) <= 0
                ? 0
                : parseFloat(entered?.toString()?.replace(',', '.'))) *
              (captable.value?.allNFD || 1)

            const [computedValue] = formatNumber(calcul)
              ?.toString()
              .replaceAll('.', ' ')
              ?.split(',')

            companyValorisationAmount.value = computedValue
          }

          pricePerAction.value = value
        }
      )

      watch(
        () => companyValorisationAmount.value,
        (value) => {
          const entered =
            !value ||
            isNaN(value?.toString()?.replaceAll(' ', '')?.replaceAll('.', ''))
              ? 0
              : value?.toString()?.replaceAll(' ', '')?.replaceAll('.', '')
          const parsed = parseFloat(entered?.toString()?.replace(',', '.'))
          if (focusedField.value == 'companyValorisationAmount') {
            const calcul = parseFloat(
              `${(parsed <= 0 ? 0 : parsed) / (captable.value?.allNFD || 1)}`
            )?.toFixed(2)

            pricePerAction.value = calcul
          }
          const [computedValue] = formatNumber(parsed)
            ?.toString()
            .replaceAll('.', ' ')
            ?.split(',')

          companyValorisationAmount.value = computedValue
        }
      )

      watch(
        () => route.params.contactId,
        () => {
          getCaptable()
        }
      )

      watch(
        () => captable.value?.allNFD,
        (totalNFD) => {
          companyValorisationAmount.value = formatNumber(
            (simulationLastStockPrice.value || 0) * (totalNFD || 0)
          )
        }
      )

      onMounted(() => {
        isLoadingCaptable.value = true
        isLoadingCalendar.value = true
        getCaptable()
        getVesting()
        if (simulationLastStockPrice.value) {
          calculate()
        }
        const v = selectedCalendars.value.reduce(
          (acc, exerciceCalendar) =>
            (acc +=
              (parseFloat(pricePerAction.value) -
                (exerciceCalendar?.optionIssuancePart.optionIssuance
                  .exercisePrice || 0)) *
              (exerciceCalendar?.vestedQuantity || 0)),
          0
        )

        optionId.value = options.value.map((x: any) => x.id)
      })
      return {
        optionId,
        currency,
        options,
        isLoading,
        pricePerAction,
        NumberType,
        companyValorisationAmount,
        potentialVestedAddedValue,
        potentialFullAddedValue,
        handleFocus
      }
    }
  })
