import { Auth0Config, DocusignConfig, UrlConfig } from '@/types/config'
import { envName } from '../index'

const auth0Options: Auth0Config = {
  redirectUri: 'http://localhost:8080/redirecting',
  audience: 'https://uplaw-dev.us.auth0.com/api/v2/',
  clientId: 'bTgYWpEhneSyKbuiQLvx6WbLx2tO7uaI',
  domain: 'auth.uplaw.fr'
}

const docuSign: DocusignConfig = {
  host: 'https://account-d.docusign.com/oauth/auth',
  clientId: 'a7063687-3725-4d9a-899e-7ba9e9f93dbd',
  redirectUri: 'http://localhost:8080?next'
}

const urls: UrlConfig = {
  apiHost: 'https://prod.uplaw-api.com',
  // apiHost: `http://localhost:4000`,
  appDomain: 'https://up.law',
  sentryDsn:
    'https://d6ffd14534104bda8bd93cb86856cc40@o4504679368818688.ingest.sentry.io/450472395944755',
  diagramExternalLink:
    'https://up-law.github.io/UPLAW_DIAGRAMS/src/main/webapp/'
}

const googleMap = {
  apiKey: 'AIzaSyDj3F7iUyFg45_rjD_V7_EDA3kIFi5c6wg'
}

const environement = `developpement`

export { environement, auth0Options, urls, googleMap, docuSign }
