import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { TYPE } from 'vue-toastification'
import { Document, EventSubmitProps } from '@/services/api/models'
import { parseInt } from 'lodash'
import moment from 'moment'
import { eventEntryNameMapping } from '@/utils/eventDefault'
import { CompanyModel } from '../../../services/api/models1'
import { OptionSunsetMode, OptionSunsetTypeEnum } from '@/types'

export function formDataManipulation(formData: any) {
  const store = useStore()
  const route = useRoute()
  const router = useRouter()
  const { t, te } = useI18n()
  const currentCompany = computed<CompanyModel>(
    () => store.getters['organizations/contextCompany']
  )
  const optionStockAliases = computed(
    () => store.getters['organizations/optionStockAliases']
  )

  const compositionStockAliases = computed(
    () => store.getters['organizations/compositionStockAlias']
  )

  const attachedFiles = computed(
    () => store.getters['organizations/attachedFiles']
  )

  const isOption = async (id: string) => {
    const result = await store.dispatch('organizations/VERIFY_STOCK', {
      companyId: currentCompany.value.id,
      stockId: id
    })

    return result.optionCategory !== undefined
  }

  const isComposition = async (id: string) => {
    const result = await store.dispatch('organizations/VERIFY_STOCK', {
      companyId: currentCompany.value.id,
      stockId: id
    })

    return result?.isComposition || false
  }

  const mapping = eventEntryNameMapping

  const vestings = ['suspension', 'acceleration', 'prorogation']

  const allocations = ['RETAINED_EARNINGS', 'DIVIDENDS', 'RESERVE']

  const submitForm = async ({
    entryName,
    withRedirect,
    action = 'CREATE',
    eventId = null,
    eventCreated,
    onCreationError
  }: EventSubmitProps) => {
    // The payload final
    console.log(formData.value, 'entryName', entryName)
    try {
      let data = {
        ...formData.value,
        isDraft: formData.value.isDraft ?? false
      }
      if (data.issanceDate === null) {
        data.issuanceDate = moment().toDate()
      }

      if (!data.committeeId) {
        delete data.committeeId
      }
      const documentsId = attachedFiles.value.reduce(
        (acc: string[], item: Document) => {
          acc.push(item.id)
          return acc
        },
        []
      )
      if (documentsId.length > 0) data.documentsId = documentsId
      else delete data.documentsId
      switch (entryName) {
        case 'nomination': {
          data.endDate = data.isUnlimited
            ? null
            : moment(data.endDate).toISOString()
          break
        }

        case 'officeTermination': {
          data = {
            id: data.id,
            appointmentId: data.appointmentId,
            documentsId: data.documentsId,
            reason: data.reason,
            isDraft: data.isDraft ?? false,
            endDate: data.endDate
          }
          break
        }
        case 'cancelled': {
          data = {
            id: data.id,
            documentsId: data.documentsId,
            issuanceDate: data.cancelDate,
            reason: data.reason,
            isDraft: data.isDraft ?? false,
            capitalReductionParts: await Promise.all(
              data['table-cancelled'].map(async (x: any) => {
                const [stockId] = x.titles.split('@_')
                const stockField =
                  (await isOption(stockId)) ||
                  entryName?.toString() === 'options'
                    ? 'optionAliasId'
                    : (await isComposition(stockId))
                    ? 'compositionAliasId'
                    : 'stockAliasId'

                return {
                  contactId: x.subscriber,
                  [stockField]: stockId,
                  quantity: parseInt(x.quantity),
                  totalPrice: parseFloat(x.totalPrice),
                  entryDate: x.entryDate || data.issuanceDate
                }
              })
            )
          }
          break
        }
        case 'commonCeiling':
          break
        case 'nominal':
          data.value = parseFloat(data.price)
          delete data.price
          break
        case 'parityChange':
          data.parity = parseFloat(data.parity)
          break
        case 'delegation':
          if (data.quantity) data.quantity = parseInt(data.quantity)
          else delete data.quantity
          if (!data.commonCeilingId) delete data.commonCeilingId
          delete data.category

          break
        case 'delegationSunset':
          data.delegationId = data.delegationId?.toString()
          delete data.initDelegationId
          break
        case 'commitment':
          data.commitmentParts = await Promise.all(
            [...data['table-commitment']].map(async (x) => ({
              quantity: parseInt(x.quantity),
              contactId: x.contactId,
              ownershipType: x.ownershipType,
              [(await isOption(x.titles))
                ? 'optionAliasId'
                : (await isComposition(x.titles))
                ? 'compositionAliasId'
                : 'stockAliasId']: x.titles
            }))
          )
          delete data['table-commitment']
          break
        case 'exercise':
          data.optionExerciseParts = [...data['table-exercise']].map((x) => ({
            ...x,
            quantity: parseInt(x.quantity),
            issuedQuantity: parseInt(x.issuedQuantity),
            totalPrice: parseFloat(x.totalPrice),
            nominalIncrease: parseFloat(x.nominalIncrease),
            totalIssuancePremium: parseFloat(x.totalIssuancePremium),
            issuancePremiumPerAction: parseFloat(x.issuancePremiumPerAction),
            optionAliasId: x.optionAliasId?.split('@_')[0],
            entryDate: x.entryDate || data.issuanceDate
          }))
          delete data['table-exercise']
          break

        case 'options':
        case 'composition':
        case 'primary': {
          if (data.delegationId === null) {
            delete data.delegationId
          }
          const [stockId, exercisePrice] = formData.value.titles.split('@_')

          if (data.financingRoundId == '') delete data.financingRoundId
          if (data.profitSharingPlanId == '') delete data.profitSharingPlanId
          data[
            (await isOption(stockId)) || entryName?.toString() === 'options'
              ? 'optionIssuanceParts'
              : (await isComposition(stockId))
              ? 'compositionIssuanceParts'
              : 'stockIssuanceParts'
          ] = formData.value[`table-${entryName}`].reduce(
            (acc: any, item: any) => {
              const el = {
                ...item,
                quantity: parseInt(item.quantity),
                exercisePrice:
                  parseFloat(exercisePrice || data.exercisePrice || '0') *
                  parseInt(item.quantity),
                releasedAmount: parseFloat(item.releasedAmount),
                totalPrice: parseFloat(item.totalPrice),
                entryDate: item.entryDate ?? data.issuanceDate
              }
              delete el.unitPrice
              delete el.issuancePremiumPerAction
              delete el.totalIssuancePremium
              delete el.nominalIncrease
              acc.push(el)
              return acc
            },
            []
          )
          data[
            (await isOption(stockId)) || entryName?.toString() === 'options'
              ? 'optionAliasId'
              : (await isComposition(stockId))
              ? 'compositionAliasId'
              : 'stockAliasId'
          ] = stockId

          if (
            (await isOption(stockId)) ||
            entryName?.toString() === 'options'
          ) {
            data.exercisePrice = +parseFloat(
              data.exercisePrice?.replaceAll(',', '.') ||
                exercisePrice?.replaceAll(',', '.')
            )
          }

          data.isDraft = formData.value.isDraft ?? false
          delete data.delegationBool
          delete data.financingRoundBool
          delete data.profitSharingPlanBool
          delete data.titles
          delete data.startVesting
          delete data.vesting
          delete data['table-primary']
          delete data['table-options']
          delete data['table-composition']
          break
        }
        case 'transfer':
          data.transferParts = await formData.value['table-transfer'].reduce(
            async (accPromises: any, item: any) => {
              const acc = await accPromises
              const [stockId, commitmentId] = item.stockAliasId?.split('@_')
              const isOptionStock = await isOption(stockId)

              const stockField = isOptionStock
                ? 'optionAliasId'
                : (await isComposition(stockId))
                ? 'compositionAliasId'
                : 'stockAliasId'

              const el = {
                ...item,
                commitmentId: commitmentId ?? item?.commitmentId,
                unitPrice: item.unitPrice ? parseInt(item.unitPrice) : null,
                quantity: parseInt(item.quantity),
                totalAmount: parseFloat(item.totalPrice),
                entryDate: item.entryDate ?? data.transferDate
              }

              delete el.stockAliasId

              el[stockField] = stockId
              delete el.commitment
              delete el.seller
              delete el.transferee
              delete el.stockAlias
              delete el.optionAlias
              delete el.compositionAlias
              acc.push(el)

              return acc
            },
            Promise.resolve([])
          )
          delete data['table-transfer']
          break
        case 'constitution':
          data.firstClosure = `${data.firstClosure.split('-')[2]}-${
            data.firstClosure.split('-')[1]
          }`
          data.issuanceEvent = {
            issuanceDate: new Date(),
            stockAliasId: data.stockAliasId,
            stockIssuanceParts: data.stockIssuanceParts.map((x: any) => ({
              ...x,
              unitPrice: parseFloat(x.unitPrice),
              releasedAmount: parseFloat(x.releasedAmount),
              totalPrice: parseFloat(x.totalPrice),
              quantity: parseInt(x.quantity)
            }))
          }
          data.firstNominalValue = {
            value: data.nominalValue,
            date: new Date()
          }
          data.firstFinancialEnding = {
            date: data.firstExerciseClosure
          }
          delete data.stockIssuanceParts
          break
        case 'socialAppro':
          break
        case 'lapseOptions':
          {
            data.mode =
              data.mode == OptionSunsetMode.partial ? data.mode : data.type
            data.type = OptionSunsetTypeEnum.byContact
            data.optionSunsetParts = data['table-lapseOptions'].map(
              (x: any) => ({
                exercisableQuantity: parseInt(x.sunsetVestedQuantity),
                nonVestedQuantity: parseInt(x.sunsetNotVestedQuantity),
                exerciseCalendarId:
                  x.exerciseCalendarId || x.oldExerciseCalendarId,
                exercisableBalanceDate: x.exercisableBalanceDate,
                nonVestedDate: x.nonVestedDate,
                id: x.id || '',
                motive: x.motive
              })
            )

            delete data['table-lapseOptions']
          }
          break
        case 'conversion':
          {
            data.conversionParts = await formData.value[
              'table-conversion'
            ].reduce(async (accPromises: any, item: any) => {
              const acc = await accPromises
              const fromStock =
                (await isOption(item.fromTitles)) ||
                entryName?.toString() === 'options'
                  ? 'fromOptionAliasId'
                  : (await isComposition(item.fromTitles))
                  ? 'fromCompositionAliasId'
                  : 'fromStockAliasId'
              const toStock =
                (await isOption(item.toTitles)) ||
                entryName?.toString() === 'options'
                  ? 'toOptionAliasId'
                  : (await isComposition(item.toTitles))
                  ? 'toCompositionAliasId'
                  : 'toStockAliasId'
              const el = {
                contactId: item.contactId,
                [toStock]: item.toTitles,
                [fromStock]: item.fromTitles,
                quantity: parseInt(item.quantity),
                parity: parseInt(item.parity),
                entryDate: item.entryDate ?? data.conversionDate
              }
              acc.push(el)
              return acc
            }, [])
            delete data['table-conversion']
          }
          break
        default:
          if (vestings.some((item: string) => item === entryName)) {
            if (data.choice === 1) {
              delete data.contactsId
            } else {
              delete data.optionAliasId
            }
            data.timeUnit = data.timeUnit?.slice(0, -1).toUpperCase()
            delete data.choice
          }
          break
      }
      console.log('Event =>', `organizations/${action}_${mapping[entryName]}`)

      await store.dispatch(`organizations/${action}_${mapping[entryName]}`, {
        companyId: route.params.id,
        eventId: data['id'] || eventId,
        data
      })

      await store.dispatch('toolbox/displayToast', {
        type: TYPE.SUCCESS,
        context: t(`events.categories.${entryName.toLowerCase()}`),
        message: t(
          `events.apiResponse.${
            action == 'EDIT' ? 'successEdit' : 'successCreate'
          }`
        )
      })

      if (withRedirect) {
        store.commit('organizations/ATTACH_FILES_TO_EVENT', { files: [] })
      }

      if (action === 'EDIT')
        router.push({
          name: 'company.corporateEvents',
          params: { id: route.params.id },
          query: { type: 'event' }
        })
      else eventCreated()
    } catch (e: any) {
      console.log(e, 'Error Creating Event')
      onCreationError(e)
      let message = e?.data?.message || t('events.apiResponse.errorCreate')

      if (te(`events.apiResponse.${e?.data?.message}`)) {
        message = t(`events.apiResponse.${e?.data?.message}`)
      }
      await store.dispatch('toolbox/displayToast', {
        type: TYPE.ERROR,
        context: t(`events.categories.${entryName}`),
        message
      })
    }
  }
  return {
    isOption,
    isComposition,
    submitForm
  }
}

export function tableFormManipulation(formData: any) {
  const tableKey = ref(0)
  const updateTable = (data: any) => {
    // console.log('data', data.value)

    formData[data.tableId] = data.value
    // Updated object to send to the api
    // console.log('Final Payload', formData)
  }

  const addRows = (e: any) => {
    const countPerRefresh = 10
    const lastEntry = formData[e.tableId][formData[e.tableId].length - 1]
    const row = { ...lastEntry }
    if (row.sellerId) row.sellerId = null
    if (row.contactId) row.contactId = null
    if (row.transfereeId) row.transfereeId = null
    if (row.subscriber) row.subscriber = null
    for (let i = 0; i < e.nb; i++) {
      // Clone the entry to kill the ref, otherwise all entries will have
      // the same value and be reactive as one
      formData[e.tableId].push({ ...row })
      if (i % countPerRefresh == 0) tableKey.value++
    }

    tableKey.value++
  }

  const removeRow = (e: any) => {
    formData[e.tableId].splice(e.rowIndex, 1)
    tableKey.value++
  }

  const insert = (arr: any[], index: number, data: any) => {
    arr.splice(index, 0, data)
  }

  const duplicateRow = (e: any) => {
    const countPerRefresh = 10

    for (let i = 0; i < parseInt(e.times); i++) {
      const start = parseInt(e.rowIndex) + i + 1
      insert(formData[e.tableId], start, { ...e.row })
      if (i % countPerRefresh == 0) tableKey.value++
    }

    tableKey.value++
  }

  return {
    tableKey,
    updateTable,
    addRows,
    removeRow,
    duplicateRow
  }
}
