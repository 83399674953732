<template>
  <div class="px-12">
    <div class="flex py-6">
      <h3 class="text text-blackp text-xl">
        {{ $t('company.vote_title') }}
      </h3>
    </div>
    <div class="flex py-3">
      <div class="w-1/4 pr-2">
        <label class="text-left font-semibold text-secondary9" for="">{{
          $t('global.vote_date')
        }}</label>
        <UplDatePicker
          v-model="datePicked"
          class="rounded h-10 text-xs mr-2"
          placeholder="--/--/----"
        />
      </div>

      <div class="w-1/4 pr-2">
        <label class="text-left font-semibold text-secondary9" for="">{{
          $t('global.assembly_type')
        }}</label>
        <multiselect
          v-model="selectedtype"
          :options="generalMeetingTypes"
          :value="selectedtype"
          :groups="false"
          name="contacts"
          class="h-10"
          elementName="contactBook.contacts"
          selectionText="global.actions.selected1"
          mode="single"
          :has-label="false"
          :filter-results="false"
          open-direction="bottom"
          placeholder="Types"
          @change="selectedtype = $event"
        />
      </div>
    </div>
    <div class="flex text-2xl py-3">
      <el-tooltip
        v-if="isDownloading"
        class="box-item"
        effect="dark"
        :content="$t('tooltips.downloading')"
        placement="top"
      >
        <div
          class="
            border
            cursor-not-allowed
            rounded-3
            bg-white
            border-secondary6
            px-3
            mr-2
            h-10
            flex
            items-center
          "
        >
          <i class="bi bi-arrow-clockwise text-xl text-secondary8 rotating"></i>
        </div>
      </el-tooltip>
      <Button
        :disabled="isDownloading"
        variant="primary"
        class="danger sm error"
        icon="bi-trash xs"
        :label="$t('global.actions.download')"
        @click="download"
      />
    </div>
    <div class="relative">
      <div
        v-if="isLoading"
        class="
          flex
          w-full
          h-full
          justify-center
          items-center
          relative
          shadow-md
          mt-5
          p-5
        "
      >
        <Loader />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from 'vue'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'
  import { useStore } from 'vuex'
  import { CompanyModel, GeneralMeetingType } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { ElTooltip } from 'element-plus'
  import Multiselect from '@/components/Select/multiselect.vue'
  export default defineComponent({
    components: { UplDatePicker, Button, ElTooltip, Multiselect, Loader },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const datePicked = ref(moment.utc().toISOString())
      const isLoading = ref(false)
      const isDownloading = ref(false)
      const selectedtype = ref()
      const generalMeetingTypes = computed(() =>
        Object.values(GeneralMeetingType).map((x) => ({
          id: x,
          name: t(`GeneralMeetingType.${x}`)
        }))
      )

      const download = () => {
        isDownloading.value = true
        store.dispatch('organizations/EXPORT_COMPANY_VOTING_RIGHT_PDF', {
          companyId: currentCompany.value?.id,
          filter: {
            date: datePicked.value,
            generalMeetingType: selectedtype.value
          },
          name: t('exports.vote'),
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      return {
        datePicked,
        selectedtype,
        isDownloading,
        isLoading,
        generalMeetingTypes,
        download
      }
    }
  })
</script>
