
  import { computed, defineComponent, onMounted, ref, toRefs } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import { CompanyModel, Event } from '@/services/api/models'
  import { AuditForm, AuditType, EventAuditAnomalies } from '@/types/forms'
  import moment from 'moment'
  import { Form } from 'vee-validate'
  import UplAuditForm from '@/components/Forms/Audit/index.vue'
  import { useStore } from 'vuex'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { CardType } from '@/types/config'
  import Modal from '@/components/Modal/Modal.vue'

  import {
    ElTabs,
    ElTabPane,
    ElTableColumn,
    ElTable,
    ElPopover
  } from 'element-plus'
  import MoreActions from '@/components/ActionList/new.vue'
  import { EventType } from '@/types/event'
  export default defineComponent({
    name: 'EventActions',
    components: {
      Form,
      UplAuditForm,
      Button,
      MoreActions,
      ElTabs,
      ElTabPane,
      ElTableColumn,
      ElTable,
      Modal,
      ElPopover
    },
    props: {
      item: {
        type: Object as () => Event,
        required: true
      },
      side: {
        type: Number,
        default: CardType.EVENT
      },
      audit: {
        type: Boolean,
        default: false
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    emits: ['toggle-side', 'select-card', 'updated', 'removed'],
    setup(props, { emit }) {
      const { side, audit, item } = toRefs(props)
      const router = useRouter()
      const route = useRoute()
      const canDelete = ref(false)
      const itemId = computed(() => item.value.id)
      const event = computed(() => item.value)
      const currentSide = ref(side.value)
      const toggleAudit = ref(audit.value)
      const eventAudit = ref<AuditForm>()
      const store = useStore()
      const { t } = useI18n()

      const menuActions = computed(() => [
        {
          section: 1,
          name: t('global.edit'),
          icon: 'bi bi-pen',
          key: 'edit',
          type: ''
        },
        {
          section: 2,
          name: t('global.delete'),
          icon: 'bi bi-trash',
          iconClass: 'text-error',
          textClass: 'text-error',
          key: 'delete',
          type: ''
        }
      ])

      const companyImage = ref<null | string>()

      const currentTab = computed<string>(() =>
        (item.value?.eventAudits || [])?.length === 0 ? 'newAudit' : 'audits'
      )
      const isNotEditable = computed(
        () =>
          props.item?.type &&
          [
            EventType.optionSunset.toString(),
            EventType.optionExercise.toString(),
            EventType.transfer.toString(),
            EventType.stockIssuance.toString(),
            EventType.optionIssuance.toString(),
            EventType.compositionIssuance.toString()
          ].includes(props.item?.type.toString())
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const countDocuments = ref(0)

      const handleAction = (key: string) => {
        if (!props.item.id) return
        if (key === 'edit') editEvent(props.item.id)
        else if (key === 'delete') canDelete.value = true
      }
      const toggleSide = (what: CardType) => {
        currentSide.value = what
        emit('toggle-side', currentSide.value)
      }

      const selectCard = (e: boolean) => {
        emit('select-card', {
          check: e,
          id: itemId.value
        })
      }
      const removeEvent = (eventId: string) => {
        store.dispatch('organizations/DELETE_EVENT', {
          companyId: route.params.id,
          eventId,
          onSuccess: () => {
            canDelete.value = false
            emit('removed', eventId)
          }
        })
      }
      const editEvent = (eventId: string) => {
        let name = 'company.eventUpdate'
        if (isNotEditable.value) {
          name = 'company.eventDetails'
        }
        router.push({
          name,
          params: { id: route.params.id, eventId: eventId }
        })
      }

      const removeDocument = (e: any) => {
        store.dispatch('organizations/DELETE_EVENT', {
          companyId: currentCompany.value.id,
          eventId: event.value.id,
          onError: (error = null) => {
            const message = error || t(`events.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message
            })
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.deleteSuccess`)
            })
          }
        })
      }

      const handleAuditFormChange = (values: AuditForm) => {
        eventAudit.value = values
      }

      const submitDeletion = () => {
        removeEvent(props.item.id)
      }

      const navigateToDocumentForm = (id: string) => {
        router.push({
          name: props.isPortfolio
            ? 'portfolioView.event'
            : 'company.eventDetails',
          params: { id: route.params.id, eventId: id }
        })
      }

      const getEventDocuments = (eventId: string, page = 1) => {
        if (!eventId) return
        let module = 'organizations'
        if (props.isPortfolio) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT_DOCUMENTS`, {
          companyId: currentCompany.value?.id || route.params.id,
          eventId,
          page,
          limit: 1,
          onSuccess: (response: any) => {
            const { metadata } = response
            countDocuments.value = metadata.count
          },
          onError: () => {
            // isLoading.value = false
          }
        })
      }

      const handleAuditForm = () => {
        const formData = {
          motive: eventAudit.value?.motives?.join(','),
          processed: false,
          comment: eventAudit.value?.comment
        }
        store.dispatch('organizations/CREATE_AUDIT', {
          companyId: currentCompany.value.id,
          type: AuditType.EVENT,
          typeDataId: event.value.id,
          formData,
          onSuccess: () => {
            store.dispatch('organizations/GET_EVENTS', {
              companyId: currentCompany.value.id,
              filter: {}
            })
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('audits.events'),
              message: t(`audits.createSuccess`)
            })
          },
          onError: (error: string) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('audits.events'),
              message: error || t(`audits.createError`)
            })
          }
        })
      }

      const loadImage = async () => {
        if (!props.item?.company?.id) return
        if (store.getters['portfolio/companiesLogos'][props.item?.company?.id]) {
          companyImage.value =
            store.getters['portfolio/companiesLogos'][props.item?.company?.id]
        } else {
          const response = await store.dispatch(
            `organizations/LOAD_COMPANY_LOGO`,
            {
              companyId: props.item.company?.id
            }
          )
          if (response) {
            companyImage.value = response
            store.commit('portfolio/SET_COMPANIES_LOGOS', {
              companyId: props.item.company?.id,
              value: response
            })
          }
        }
      }

      onMounted(() => {
        if (props.isPortfolio) loadImage()
        getEventDocuments(props.item.id)
      })

      return {
        itemId,
        currentSide,
        toggleAudit,
        event,
        canDelete,
        EventAuditAnomalies,
        currentTab,
        CardType,
        menuActions,
        AuditType,
        handleAction,
        companyImage,
        countDocuments,
        toggleSide,
        editEvent,
        selectCard,
        submitDeletion,
        removeDocument,
        handleAuditFormChange,
        handleAuditForm,
        navigateToDocumentForm,
        moment
      }
    }
  })
