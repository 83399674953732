<template>
  <div
    class="
      w-full
      h-full
      chart-box
      flex-1
      p-8
      flex flex-col
      items-start
      border border-secondary5
      rounded-lg
      bg-white
    "
  >
    <h3>
      {{ $t(title) }}
    </h3>
    <h5 v-if="subtitle">
      {{ $t(subtitle) }}
    </h5>
    <div class="w-full mt-2 flex space-x-4">
      <div :class="`${legend && canDisplayChart ? 'w-2/4' : 'w-full'}`">
        <div class="w-full h-full" :style="`max-height: ${chartHeight}px`">
          <canvas
            v-show="canDisplayChart"
            :id="id"
            ref="chart"
            width="100"
            height="100"
            :style="`max-height: ${chartHeight}px`"
          />

          <div
            v-if="isLoading"
            class="flex justify-center items-center h-full w-full"
            :style="`height: ${chartHeight}px`"
          >
            <Loader />
          </div>

          <div
            v-else-if="noData"
            class="flex justify-center items-center h-full w-full"
            :style="`height: ${chartHeight}px`"
          >
            <p>{{ $t('charts.empty') }}</p>
          </div>
        </div>
      </div>
      <div v-if="legend && canDisplayChart" class="chartLegend">
        <div v-for="(label, index) in labels" :key="index" class="">
          <div class="flex content-center relative border-b border-secondary7">
            <span class="h-4 w-4 mt-1 mr-4 rounded-full" />
            <p class="text-left">
              {{ label }}
            </p>
            <p class="absolute right-0">
              {{
                formatNumericalValue(dataset[0].data[index] / 100, '', {
                  style: 'percent'
                })
              }}
            </p>
          </div>
        </div>
      </div>
      <slot name="legend" :data="dataset"></slot>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    ref,
    watch
  } from 'vue'
  import { Chart } from 'chart.js'
  import { useI18n } from 'vue-i18n'
  import { formatNumericalValue } from '@/utils/global'
  import Loader from '@/components/Loader/index.vue'

  type ChartType =
    | 'bar'
    | 'line'
    | 'scatter'
    | 'bubble'
    | 'pie'
    | 'doughnut'
    | 'polarArea'
    | 'radar'

  export default defineComponent({
    name: 'UplChart',
    components: { Loader },
    props: {
      id: {
        type: String,
        required: true
      },
      labels: {
        type: Array as PropType<string[]>,
        required: true
      },
      dataset: {
        type: Object as any,
        required: true
      },
      options: {
        type: Object as any,
        default: () => ({})
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        default: () => undefined,
        required: false
      },
      type: {
        type: String as PropType<ChartType>,
        required: true
      },
      legend: {
        type: Boolean,
        default: true
      },
      chartHeight: {
        type: Number,
        default: 300
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    setup(props) {
      const { t } = useI18n()
      let chart = ref<HTMLCanvasElement | any>(null)
      const myChart = ref()

      const canDisplayChart = computed(() => {
        const [elm] = props.dataset
        return elm?.data?.length > 0
      })

      const noData = computed(() => {
        const [elm] = props.dataset

        return elm?.data?.length == 0
      })
      const backgroundColor = (data: number[], index: number): string => {
        const opacity = ((100 / data.length) * index) / 100

        if (index === 0) {
          return 'rgba(24,15,239, 0.1)'
        } else {
          return `rgba(24,15,239, ${opacity})`
        }
      }

      const datasets = props.dataset.reduce((acc: any, item: any) => {
        const el = item
        if (!el.backgroundColor) {
          el.backgroundColor = (context: any): string => {
            return backgroundColor(context.dataset.data, context.dataIndex)
          }
        }
        acc.push(el)
        return acc
      }, [])

      const generateChart = () => {
        myChart.value = new Chart(chart.value.getContext('2d'), {
          type: props.type,
          data: {
            labels: props.labels,
            datasets
          },
          options: {
            plugins: {
              legend: {
                display: false
              }
            },
            ...props.options
          }
        })
      }

      onMounted(() => {
        generateChart()

        console.log('IslLoading 4', props.isLoading)
      })

      watch(
        () => props.dataset,
        (val: any) => {
          console.log('proppspsps', props.dataset, props.labels)

          myChart.value.data.datasets = val
          Chart?.getChart(props.id)?.update()
        }
      )

      watch(
        () => props.labels,
        (val: any) => {
          myChart.value.data.labels = val
          Chart?.getChart(props.id)?.update()
          // isLoading.value = false
        }
      )

      watch(
        () => props.isLoading,
        (val: any) => {
          console.log('IslLoading', val)
        }
      )

      return {
        chart,
        canDisplayChart,
        noData,
        formatNumericalValue,
        backgroundColor
      }
    }
  })
</script>

<style lang="scss" scoped>
  .chart-box {
  }
  .chartLegend {
    @apply w-2/4 h-full flex flex-col justify-center space-y-2;
  }
</style>
