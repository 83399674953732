import { CancelTokenSource } from 'axios'
import { docAuto } from './endpoints'
import axios from './axios'

let templateCancelToken: CancelTokenSource | null = null

const getTemplates = async (
  companyId: string,
  filter = {},
  offset = 0,
  limit = 20
) => {
  if (typeof templateCancelToken != undefined) {
    templateCancelToken?.cancel('GetTemplates canceled due to new request.')
  }
  templateCancelToken = axios.CancelToken.source()
  const response = await axios.get(docAuto.templates(companyId), {
    cancelToken: templateCancelToken.token,
    params: { offset, limit, ...filter }
  })

  return response
}

const getTemplate = async (
  companyId: string,
  templateId: string
): Promise<any> => {
  const response = await axios.get(docAuto.template(companyId, templateId))
  return response
}

const saveTemplate = async (companyId: string, data = {}): Promise<any> => {
  const response = await axios.post(docAuto.templates(companyId), data)

  return response
}

const editTemplate = async (
  companyId: string,
  templateId: string,
  data = {}
): Promise<any> => {
  const response = await axios.put(
    docAuto.template(companyId, templateId),
    data
  )
  return response
}

const testTemplate = async (
  companyId: string,
  templateId: string,
  data = {}
): Promise<any> => {
  const response = await axios.post(
    docAuto.testTemplate(companyId, templateId),
    data
  )

  return response
}

const removeTemplate = async (
  companyId: string,
  templateId: string
): Promise<any> => {
  const response = await axios.delete(docAuto.template(companyId, templateId))
  return response.data
}

export {
  getTemplates,
  getTemplate,
  saveTemplate,
  editTemplate,
  removeTemplate,
  testTemplate
}
