
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import DataBoxes from '@/components/CompanyOverview/DataBoxes.vue'
  import { CompanyOverviewExample } from '@/utils/test'
  import { useStore } from 'vuex'
  import {
    Appointment,
    CompanyGlobalStockData,
    CompanyModel
  } from '@/services/api/models'
  import { useRoute, useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import DataTable from '@/components/DataTable/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import LongText from '@/components/LongText/index.vue'
  import { dashboardSubCategoryList } from '@/utils'

  export default defineComponent({
    name: 'CompanyInfosTab',
    components: { DataBoxes, DataTable, Button, LongText },
    props: {},
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()
      const importantDocuments = ref<any>([])
      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData']
      )
      const loadingData = ref(false)

      const data = computed(() => CompanyOverviewExample['capital'])
      const companyGlobalData = computed<CompanyGlobalStockData | null>(
        () => store.getters['organizations/companyGlobalData']
      )

      const company = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const getMandates = () => {
        const filter: any = {}
        store.dispatch('organizations/GET_NOMINATIONS', {
          companyId: route.params.id,
          filter
        })
      }

      const mandates = computed<Appointment[]>(
        () => store.getters['organizations/nominations']
      )
      const president = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.president') &&
              !x?.appointmentTermination
          )[0]
      )
      const generalDirector = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.generalDirector') &&
              !x?.appointmentTermination
          )[0]
      )
      const manager = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.manager') &&
              !x?.appointmentTermination
          )[0]
      )
      const externalAuditor = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.externalAuditor') &&
              !x?.appointmentTermination
          )[0]
      )
      const alternateAuditor = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.alternateAuditor') &&
              !x?.appointmentTermination
          )[0]
      )

      const columns = computed(() => [
        {
          label: t('documents.elements.name'),
          field: 'name',
          width: '40%',
          custom: true
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '5%',
          custom: true
        }
      ])

      const getImportantDocuments = async () => {
        dashboardSubCategoryList.map(async (subCategoryId: string) => {
          await store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
            companyId: route.params.id,
            filter: {
              documentSubCategoriesId: [subCategoryId]
            },
            onSuccess: (result: any) => {
              if (result.data.length)
                importantDocuments.value.push(result?.data[0])
            }
          })
        })
      }

      const handleClick = (data: any) => {
        router.push({
          name: 'companyDocuments',
          params: {
            id: store.getters['organizations/contextCompany'].id,
            documentId: data.id
          }
        })
      }

      const informations = computed(() => ({
        socialCapital: {
          value: companyGlobalData.value?.shareCapital || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingData.value
        },
        nominalValue: {
          value: companyGlobalData.value?.nominalValue || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingData.value
        },
        nbNFD: {
          value: companyGlobalData.value?.totalNFD || 0,
          type: 'number',
          isLoading: loadingData.value
        },
        nbFD: {
          value: companyGlobalData.value?.totalFD || 0,
          type: 'number',
          isLoading: loadingData.value
        },
        emitPrime: {
          value: companyGlobalData.value?.totalIssuancePrimes || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingData.value
        },
        nbShareholders: {
          value: companyGlobalData.value?.numberOfShareHolders || 0,
          type: 'number',
          isLoading: loadingData.value
        }
      }))

      const dataBoxes = [
        {
          icon: 'bi-cash-stack',
          key: 'socialCapital'
        },
        {
          icon: 'bi-cash-stack',
          key: 'nominalValue'
        },
        {
          icon: 'bi-pie-chart-fill',
          key: 'nbNFD'
        },
        {
          icon: 'bi-pie-chart-fill',
          key: 'nbFD'
        },
        // {
        //   icon: 'bi-cash-stack',
        //   key: 'emitPrime'
        // },
        {
          icon: 'bi-people-fill',
          key: 'nbShareholders'
        }
      ]

      const getInformations = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_COMPANY_STOCKS_DATA', {
          companyId,
          onSuccess: () => {
            loadingData.value = false
          }
        })
      }

      const getData = (companyId = route.params.id) => {
        loadingData.value = true
        getInformations(companyId)
      }
      watch(
        () => route.params.id,
        (val) => {
          getData(val)
          getMandates()
          getImportantDocuments()
        }
      )

      onMounted(() => {
        getData()
        getMandates()
        getImportantDocuments()
      })

      return {
        data,
        loadingData,
        informations,
        dataBoxes,
        isLoading,
        company,
        president,
        generalDirector,
        manager,
        externalAuditor,
        alternateAuditor,
        columns,
        importantDocuments,
        handleClick
      }
    }
  })
