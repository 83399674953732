
  import { defineComponent } from 'vue'
  import PortfolioUsers from './tabs/portfolioUsers.vue'

  export default defineComponent({
    components: {
      PortfolioUsers
    },
    setup() {
      return {}
    }
  })
