import axios from '../axios'
import { app, company } from '../endpoints'

const saveIntegratedServiceData = async (companyId: string, data: any) => {
  return await axios.post(company.integratedServiceData(companyId), data)
}

const removeIntegratedService = async (companyId: string, service: string) => {
  return await axios.delete(company.integratedService(companyId, service))
}
const getIntegratedServices = async (companyId: string) => {
  return await axios.get(company.integratedServiceData(companyId))
}

const addDocautoToOptionIssuance = async (
  companyId: string,
  issuanceId: string,
  data: any
) => {
  return await axios.post(
    company.addDocAutoToOptionIssuance(companyId, issuanceId),
    data
  )
}

const addDocautoToTransfer = async (
  companyId: string,
  issuanceId: string,
  data: any
) => {
  return await axios.post(
    company.addDocAutoToTransfer(companyId, issuanceId),
    data
  )
}

const addDocAutoToOptionExercise = async (
  companyId: string,
  exerciseId: string,
  data: any
) => {
  return await axios.post(
    company.addDocAutoToOptionExercise(companyId, exerciseId),
    data
  )
}

const createApplication = async (data: any) => {
  return await axios.post(app.create(), data)
}

const editApplication = async (id: string, data: any) => {
  return await axios.put(app.one(id), data)
}

const getApplications = async (data: any) => {
  return await axios.get(app.create(), { params: data })
}

const getApplication = async (id: string) => {
  return await axios.get(app.one(id))
}

const deleteApplication = async (id: string) => {
  return await axios.delete(app.one(id))
}
export {
  saveIntegratedServiceData,
  getIntegratedServices,
  addDocautoToOptionIssuance,
  addDocAutoToOptionExercise,
  removeIntegratedService,
  addDocautoToTransfer,
  createApplication,
  getApplications,
  deleteApplication,
  editApplication,
  getApplication
}
