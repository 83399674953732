
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { StockToggleFields, STOCKTYPE } from '@/types'
  import { useStore } from 'vuex'
  import Checkbox from '@/components/Checkbox/index.vue'

  export default defineComponent({
    components: { Field, Checkbox },
    props: {
      stockType: {
        type: Number as () => STOCKTYPE,
        required: true
      },
      values: {
        type: Object as () => StockToggleFields,
        required: true
      },
      disabled: {
        type: Object as () => StockToggleFields,
        required: true
      }
    },
    emits: ['onChange'],
    setup(props, { emit }) {
      const store = useStore()
      const disabledFields = computed(() => props.disabled)
      const resetFields = () => ({
        inESOP: false,
        inFD: true,
        inNFD: true,
        inValuation: true,
        notInTransactionsRegister: false
      })
      const fields = ref<StockToggleFields>(props.values)

      const mustInitForm = computed(
        () => store.getters['organizations/form'].initCurrentForm
      )

      watch(mustInitForm, (val) => {
        if (val) fields.value = resetFields()
      })

      watch(props, (newProps) => {
        fields.value = { ...newProps.values }
      })

      onMounted(() => {
        emit('onChange', props.values)
      })

      const handleToggle = (value: boolean, field: string) => {
        ;(fields.value as any)[field] = value
        emit('onChange', fields.value)
      }

      return { fields, disabledFields, STOCKTYPE, handleToggle }
    }
  })
