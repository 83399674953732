
  import { defineComponent, ref, computed, watch } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Form, Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import {
    CompanyModel,
    ContactStatus,
    CoOwnerShipContact,
    FamilySituationEnum,
    GenderEnum
  } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { CONTACTTYPE, CoOwnershipContactForm } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { coOwnershipSchema } from '@/utils/schema'
  import ProfileAvatarForm from './ProfileAvatarForm.vue'
  import { useRoute } from 'vue-router'
  import DropdownContacts from '../../DropDowns/DropdownContacts.vue'
  export default defineComponent({
    components: {
      Form,
      Field,
      Multiselect,
      Input,
      Button,
      DropdownContacts,
      UplDatePicker,
      FormActions,
      ProfileAvatarForm
    },
    props: {
      contact: {
        type: Object as () => CoOwnerShipContact | null | undefined,
        required: false,
        default: () => null
      },
      topAlignClass: { type: String, default: '' }
    },
    emits: ['submitForm', 'fileChanged', 'onCancel'],
    setup(props, { emit }) {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()

      const newValues = (): CoOwnershipContactForm => ({
        id: props.contact?.id,
        name: props.contact?.name || '',
        type: CONTACTTYPE.CO_OWNERSHIP,
        contactGroupsId:
          (props.contact?.contactGroups ?? []).map((x) => x.id) || [],
        contactStatusId: null,
        coOwnershipParts: (props.contact?.coOwnershipParts ?? []).map((x) => ({
          contactId: x.coheir?.id ?? '',
          fraction: x.fraction
        })),
        birthDate: props.contact?.birthDate
          ? props.contact?.birthDate.split('T')[0]
          : '' || null,
        endDate: props.contact?.endDate || null,
        representativeContactId: props.contact?.representativeContact?.id ?? '',
        parentsIds: props.contact?.parents?.map((x) => x.id) || []
      })

      const modalForm = computed(() => store.getters['organizations/form'])

      const newContact = ref<CoOwnershipContactForm>(newValues())
      const genders = ref(
        Object.values(GenderEnum).map((x) => ({
          name: t(`gender.${x}`),
          id: x
        }))
      )

      const allContacts = computed<any[]>(
        () => store.getters['organizations/contacts']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const contacts = computed(() =>
        allContacts.value.filter((x) => x.id !== route.params?.contactId)
      )
      const updateContact = (index: number, data: any) => {
        newContact.value.coOwnershipParts[index].contactId = data
      }
      const handleSearch = (e: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            type: CONTACTTYPE.INDIVIDUAL,
            name: e
          }
        })
      }

      const groups = computed<ContactStatus[]>(
        () => store.getters['organizations/groups']
      )

      const schema = computed(() => coOwnershipSchema)

      const handleForm = async (values: CoOwnershipContactForm) => {
        const form = {
          ...values,
          contactGroupsId: newContact.value.contactGroupsId,
          type: CONTACTTYPE.CO_OWNERSHIP,
          parentsIds: newContact.value.parentsIds
        }

        form.coOwnershipParts.map((x) => ({
          ...x,
          fraction: parseInt(x.fraction?.toString())
        }))
        emit('submitForm', form)
      }

      const addCoOwnership = () => {
        newContact.value.coOwnershipParts.push({ contactId: '', fraction: 0 })
      }

      const removeCoOwnership = (index: number) => {
        newContact.value.coOwnershipParts.splice(index, 1)

        console.log(newContact.value.coOwnershipParts)
      }
      const fileChanged = (file: File) => {
        emit('fileChanged', file)
      }

      const handleReset = () => {
        newContact.value = newValues()
        emit('onCancel')
      }

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newContact.value = newValues()
          return
        }
      })

      watch(props, (newProps) => {
        if (newProps.contact) {
          newContact.value = {
            ...newProps.contact,
            contactGroupsId: (newProps.contact.contactGroups || []).map(
              (x) => x.id
            ),
            coOwnershipParts: (newProps.contact?.coOwnershipParts ?? []).map(
              (x) => ({ contactId: x.coheir?.id ?? '', fraction: x.fraction })
            ),
            representativeContactId:
              newProps.contact?.representativeContact?.id ?? '',
            contactStatusId: newProps.contact.status?.id || '',
            parentsIds: (newProps.contact.parents || []).map((x) => x.id)
          }
        } else {
          newContact.value = newValues()
        }
      })

      return {
        newContact,
        genders,
        groups,
        schema,
        removeCoOwnership,
        handleForm,
        handleReset,
        handleSearch,
        updateContact,
        fileChanged,
        addCoOwnership,
        contacts
      }
    }
  })
