
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import { Button } from '@up.law/uplaw-ui'
  import Loader from '@/components/Loader/index.vue'
  import moment from 'moment'
  import { EventType } from '@/types/event'
  import { useI18n } from 'vue-i18n'
  import { Document, Event } from '@/services/api/models'
  import Modal from '@/components/Modal/Modal.vue'
  import DocumentTreeview from '@/components/DocumentTree/index.vue'
  import EventOperation from './DetailTabs/operation.vue'
  import EventDocuments from './DetailTabs/documents.vue'
  import EventAudits from './DetailTabs/audits.vue'
  import Tab from '@/components/Tab/index.vue'

  export default defineComponent({
    components: {
      Loader,
      DocumentTreeview,
      Tab,
      Button,
      Modal
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()

      const nominalValue = ref(0)
      const event = ref<Event | null>()

      const lang = computed(() => store.getters['config/lang'])
      const companyImage = ref<null | string>()
      const companyName = ref<null | string>()

      const isLoading = ref(false)
      const makeLineEditable = ref(false)

      const canDelete = ref(false)

      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const isNotEditable = computed(
        () =>
          event.value?.type &&
          [
            EventType.optionSunset.toString(),
            EventType.optionExercise.toString(),
            EventType.transfer.toString(),
            EventType.stockIssuance.toString(),
            EventType.optionIssuance.toString(),
            EventType.compositionIssuance.toString()
          ].includes(event.value?.type.toString())
      )

      const documentsCount = ref(0)

      const allParts = computed<any[]>(
        () =>
          event.value?.optionIssuance?.optionIssuanceParts ||
          event.value?.stockIssuance?.stockIssuanceParts ||
          event.value?.conversion?.conversionParts ||
          event.value?.compositionIssuance?.compositionIssuanceParts ||
          event.value?.optionExercise?.optionExerciseParts ||
          event.value?.transfer?.transferParts ||
          event.value?.capitalReduction?.capitalReductionParts ||
          []
      )

      const tabList = computed(() => [
        {
          key: 'operation',
          langKey: 'eventOperation',
          component: EventOperation,
          componentProps: { canEditParts: makeLineEditable.value }
        },
        {
          key: 'documents',
          langKey: 'eventDocuments',
          count:
            documentsCount.value +
            allParts.value.reduce(
              (acc, p) => (acc += p?.documents?.length || 0),
              0
            ),
          component: EventDocuments
        },
        {
          key: 'audits',
          count: event.value?.eventAudits?.length,
          langKey: 'eventAudits',
          component: EventAudits
        }
      ])

      const editEvent = () => {
        router.push({
          name: 'company.eventUpdate',
          params: { id: route.params.id, eventId: route.params.eventId }
        })
      }

      const getEvent = (eventId = route.params.eventId) => {
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'

        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId,
          onSuccess: async (data: Event | null) => {
            event.value = data
            companyName.value = data?.company?.name
            loadImage(data?.company?.id)
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const eventUpdated = () => {
        getEvent()
      }

      const setLineEdit = (value: boolean) => {
        makeLineEditable.value = value
      }

      const submitDeletion = () => {
        removeEvent(event.value?.id)
      }

      const removeEvent = (eventId = route.params.eventId) => {
        store.dispatch('organizations/DELETE_EVENT', {
          companyId: route.params.id,
          eventId,
          onSuccess: () => {
            router.push({
              name: 'company.corporateEvents',
              query: { type: 'event' },
              params: { id: route.params.id }
            })
          }
        })
      }

      const loadImage = async (id?: string) => {
        if (!id) return
        if (store.getters['portfolio/companiesLogos'][id]) {
          companyImage.value = store.getters['portfolio/companiesLogos'][id]
        } else {
          const response = await store.dispatch(
            `organizations/LOAD_COMPANY_LOGO`,
            {
              companyId: id
            }
          )
          if (response) {
            companyImage.value = response
            store.commit('portfolio/SET_COMPANIES_LOGOS', {
              companyId: id,
              value: response
            })
          }
        }
      }

      const getEventDocuments = (eventId = route.params.eventId, page = 1) => {
        if (!eventId) return
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT_DOCUMENTS`, {
          companyId: route.params.id,
          eventId,
          page,
          onSuccess: (response: any) => {
            const { metadata } = response
            documentsCount.value = metadata.count
          },
          onError: () => {
            // isLoading.value = false
          }
        })
      }
      const documents = computed<Document[]>(
        () => store.getters['organizations/attachedFiles']
      )

      onMounted(async () => {
        getEvent()
        getEventDocuments()
        store.commit('organizations/ATTACH_FILES_TO_EVENT', { files: [] })
      })

      onBeforeMount(() => {
        window.addEventListener('beforeunload', (e) => {
          console.log('beforeunload', e)
          const answer = window.confirm(
            'Do you really want to leave? you have unsaved changes!'
          )
          // cancel the navigation and stay on the same page
          if (!answer) return false
          // if (!this.isEditing) return
          e?.preventDefault()
          // Chrome requires returnValue to be set.
          e.returnValue = ''
        })
      })

      onBeforeRouteLeave((to, from) => {
        if (!makeLineEditable.value) return
        const answer = window.confirm(
          'Do you really want to leave? you have unsaved changes!'
        )
        // cancel the navigation and stay on the same page
        if (!answer) return false
      })

      watch(
        () => route.params.id,
        (val) => {
          if (val && !isPortfolioRoute.value)
            router.push({
              name: 'company.corporateEvents',
              params: { id: val },
              query: { type: 'event' }
            })
        }
      )

      watch(
        () => route.params.eventId,
        (val) => {
          if (val) getEvent(val)
        }
      )

      return {
        editEvent,
        event,
        tabList,
        EventType,
        isLoading,
        moment,
        makeLineEditable,
        lang,
        canDelete,
        documents,
        currentDocument,
        companyName,
        nominalValue,
        isPortfolioRoute,
        companyImage,
        isNotEditable,
        eventUpdated,
        getEvent,
        setLineEdit,
        submitDeletion
      }
    }
  })
