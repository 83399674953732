<template>
  <div
    class="
      content_footer
      w-full
      px-4
      py-5
      right-0
      bottom-0
      z-0
      h-20
      flex
      items-center
      justify-between
      text-xl
      bg-white
    "
    :class="position"
  >
    <div class="border-none border-r-0">
      <li
        v-for="(error, index) in errors"
        :key="index"
        :class="index"
        class="text-xs text-error text-left"
      >
        {{ $t(`errors.${error}`) }}
      </li>
    </div>
    <div class="flex items-center justify-end">
      <Button
        variant="secondary"
        type="reset"
        :disabled="false"
        :label="$t('global.actions.cancel')"
        @click="initStockForm"
      />
      <Button
        class="ml-4"
        type="submit"
        variant="primary"
        :disabled="!meta?.valid"
        :label="$t('global.actions.save')"
        @click.prevent="$emit('onSubmit')"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  export default defineComponent({
    components: { Button },
    props: {
      errors: {
        type: Object as () => Partial<Record<string, string | undefined>>,
        required: true
      },
      meta: {
        type: Object as () => Record<string, boolean>,
        required: true
      },
      position: { type: String, default: 'absolute' }
    },
    emits: ['resetForm', 'onSubmit'],
    setup(props, { emit }) {
      const store = useStore()

      const initStockForm = () => {
        emit('resetForm')
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true
        })

        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
        store.commit('organizations/SET_CURRENT_VESTING', null)
      }
      return { initStockForm }
    }
  })
</script>
