
  import { DocAutoTemplate } from '@/store/modules/Organization/docAutoModel'
  import { EventType } from '@/types/event'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import Checkbox from '@/components/Checkbox/index.vue'
  import moment from 'moment'
  import { IntegrationType } from '@/types/config'
  import { IntegratedService } from '@/services/api/models'

  export default defineComponent({
    components: {
      Multiselect,
      Field,
      Checkbox,
      Input
      // Button, Checkbox
    },
    props: {
      eventType: { type: String as () => EventType }
    },
    emits: ['updateData'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const templates = ref<any[]>()
      const docuSign = ref<IntegratedService | null>()

      const mailMotive = ref('')
      const mailBody = ref('')
      const expireAfter = ref('30')
      const expireWarn = ref('20')
      const reminderDelay = ref('3')
      const reminderFrequency = ref('5')
      const selectedTemplates = ref([])
      const sendDraftEnvelopesToDocusign = ref(true)
      const reminderEnabled = ref(true)
      const updateValue = (value: any) => {
        emit('updateData', 'templatesId', value)
      }
      const company = computed(
        () => store.getters['organizations/contextCompany']
      )

      const isDocusignExpired = computed(() =>
        moment().isSameOrAfter(moment(docuSign.value?.expirationTimestamp))
      )

      const isDocusignExpireSoon = computed(() =>
        moment()
          .add(3, 'hours')
          .isAfter(moment(docuSign.value?.expirationTimestamp))
      )
      const handleSearch = () => {
        //
      }
      const getTemplates = () => {
        store.dispatch('organizations/GET_TEMPLATES', {
          companyId: route.params.id,
          filter: { eventTypes: [props.eventType] },
          onSuccess: (resp: any) => {
            const temps: DocAutoTemplate[] = resp.data

            const formatted = (temps ?? []).map((e: any) => ({
              ...e,
              name: e.label
            }))

            templates.value = [...formatted]
          },
          onError: () => {
            console.log()
          }
        })
      }

      const handleDraft = (value: any) => {
        emit('updateData', 'sendDraftEnvelopesToDocusign', value)
      }

      const handleReminderEnable = (value: any) => {
        reminderEnabled.value = value
        emit('updateData', 'reminderEnabled', reminderEnabled.value)
      }

      const updateExpireAfter = (value: any) => {
        expireAfter.value = value
        emit('updateData', 'expireAfter', expireAfter.value)
      }

      const updateExpireWarn = (value: any) => {
        expireWarn.value = value
        emit('updateData', 'expireWarn', expireWarn.value)
      }

      const updateReminderDelay = (value: any) => {
        reminderDelay.value = value
        emit('updateData', 'reminderDelay', reminderDelay.value)
      }

      const updateReminderFrequecy = (value: any) => {
        reminderFrequency.value = value
        emit('updateData', 'reminderFrequency', reminderFrequency.value)
      }

      const getServices = () => {
        if (!route.params?.id && !company.value?.id) return
        store.dispatch('organizations/GET_INTEGRATED_SERVICES', {
          companyId: route.params?.id ?? company.value?.id,
          onSuccess: (response: IntegratedService[]) => {
            docuSign.value = response.find(
              (x) => x.integrationType == IntegrationType.docusign
            )
          },
          onError: () => {
            ///
          }
        })
      }

      watch(
        () => props.eventType,
        () => {
          getTemplates()
          selectedTemplates.value = []
        }
      )

      watch(
        () => mailMotive.value,
        (value) => {
          emit('updateData', 'docAutomationEmailSubject', value)
        }
      )

      watch(
        () => mailBody.value,
        (value) => {
          emit('updateData', 'docAutomationEmailBody', value)
        }
      )

      watch(
        () => expireAfter.value,
        (value) => {
          updateExpireAfter(value)
        }
      )

      watch(
        () => expireWarn.value,
        (value) => {
          updateExpireWarn(value)
        }
      )

      watch(
        () => reminderDelay.value,
        (value) => {
          updateReminderDelay(value)
        }
      )

      watch(
        () => reminderFrequency.value,
        (value) => {
          updateReminderFrequecy(value)
        }
      )

      onMounted(() => {
        getTemplates()
        getServices()
        emit('updateData', 'sendDraftEnvelopesToDocusign', false)
        emit('updateData', 'reminderEnabled', reminderEnabled.value)
        emit('updateData', 'expireAfter', expireAfter.value)
        emit('updateData', 'expireWarn', expireWarn.value)
        emit('updateData', 'reminderDelay', reminderDelay.value)
        emit('updateData', 'reminderFrequency', reminderFrequency.value)
      })

      return {
        templates,
        mailMotive,
        expireWarn,
        isDocusignExpired,
        mailBody,
        reminderEnabled,
        expireAfter,
        reminderDelay,
        reminderFrequency,
        handleDraft,
        handleReminderEnable,
        sendDraftEnvelopesToDocusign,
        selectedTemplates,
        handleSearch,
        isDocusignExpireSoon,
        getServices,
        updateExpireAfter,
        updateReminderFrequecy,
        updateReminderDelay,
        updateExpireWarn,
        updateValue
      }
    }
  })
