<template>
  <div class="mt-5">
    <div
      v-if="isLoading"
      class="w-full h-full flex justify-center items-center"
    >
      <Loader />
    </div>
    <div v-else-if="event">
      <delegation-sunset
        v-if="event.type?.toString() === EventType.delegationSunset"
        :event="event.delegationSunset"
      />
      <stock-issuance
        v-if="event.type?.toString() === EventType.stockIssuance"
        :nominal-value="nominalValue"
        :can-edit="true"
        :event="event"
        :type="event.type"
      />
      <option-issuance
        v-if="event.type?.toString() === EventType.optionIssuance"
        :nominal-value="nominalValue"
        :can-edit="true"
        :event="event"
        :type="event.type"
      />
      <composition-issuance
        v-else-if="event.type?.toString() === EventType.compositionIssuance"
        :nominal-value="nominalValue"
        :event="event"
        :can-edit="true"
        :type="event.type"
      />

      <Delegation
        v-else-if="event?.type?.toString() === EventType.delegation"
        :event="event.delegation"
      />
      <sunset
        v-else-if="event?.type?.toString() === EventType.optionSunset"
        :can-edit="true"
        :event="event"
      />
      <Ceiling
        v-else-if="event?.type?.toString() === EventType.commongCeiling"
        :event="event.commonCeiling"
      />
      <parity-change
        v-if="event?.type?.toString() === EventType.parityChange"
        :event="event.parityChange"
      />
      <Transfer
        v-else-if="event?.type?.toString() === EventType.transfer"
        :can-edit="true"
        :event="event"
      />
      <Termination
        v-if="event?.type?.toString() === EventType.appointmentTermination"
        :event="event.appointmentTermination"
      />

      <Nomination
        v-if="event?.type?.toString() === EventType.appointment"
        :event="event.appointment"
      />
      <Exercise
        v-if="event?.type?.toString() === EventType.optionExercise"
        :can-edit="true"
        :event="event"
      />
      <nominal-value
        v-if="event?.type?.toString() === EventType.nominalValue"
        :event="event.nominalValue"
      />
      <financial-ending
        v-if="event?.type?.toString() === EventType.financialEnding"
        :event="event.financialEnding"
      />
      <Committee
        v-else-if="event?.type?.toString() === EventType.committee"
        :event="event.committee"
      />
      <vesting
        v-else-if="
          event?.type?.toString() === EventType.exerciseDelayProrogation
        "
        :event="event.exerciseDelayProrogation"
      />
      <approval
        v-else-if="event?.type?.toString() === EventType.accountApproval"
        :event="event.accountApproval"
      />
      <Reduction
        v-else-if="event?.type?.toString() === EventType.capitalReduction"
        :event="event.capitalReduction"
      />
      <vesting
        v-else-if="event?.type?.toString() === EventType.vestingSuspension"
        :event="event.vestingSuspension"
      />
      <conversion
        v-else-if="event?.type?.toString() === EventType.conversion"
        :event="event.conversion"
      />
      <Commitment
        v-else-if="event?.type?.toString() === EventType.commitment"
        :event="event.commitment"
      />
      <div
        v-else-if="event?.type?.toString() === EventType.vestingAcceleration"
        class="flex justify-start"
      >
        <span class="font-bold text-lg text-primary border p-2 rounded"
          >{{ event?.vestingAcceleration?.percent }}%</span
        >
      </div>

      <div v-if="canMakeDocAuto" class="mt-5">
        <div class="flex justify-start items-center w-full mb-4">
          <div class="flex justify-start items-start">
            <Field
              v-model="formData.applyDocumentAutomation"
              type="checkbox"
              :value="true"
              name="applyDocumentAutomation"
            >
              <Checkbox
                name="applyDocumentAutomation"
                :checked="formData.applyDocumentAutomation"
                @onChange="formData.applyDocumentAutomation = $event"
              />
            </Field>
          </div>
          <label class="text-md text-secondary9 ml-2 mb-0">{{
            $t('events.fieldsLabels.applyDocumentAutomation')
          }}</label>
        </div>
        <div
          v-if="formData.applyDocumentAutomation"
          class="py-5 border-t border-secondary"
        >
          <DocAutoApplyForm
            :event-type="event.type"
            @updateData="(field, value) => (formData[field] = value)"
          />

          <div class="mt-4">
            <Button
              variant="custom"
              :disabled="isValidDocauto"
              class="text-sm flex items-center mr-2 bg-white"
              @click="saveDocAuto"
            >
              <!-- <i class="bi bi-pen text-md"></i> -->
              <span class="text-sm px-2">{{
                $t('events.actionList.saveDocAuto')
              }}</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import Loader from '@/components/Loader/index.vue'
  import Delegation from '@/components/Event/types/delegation.vue'
  import Exercise from '@/components/Event/types/exercise.vue'
  import Ceiling from '@/components/Event/types/ceiling.vue'
  import Transfer from '@/components/Event/types/transfer.vue'
  import Conversion from '@/components/Event/types/conversion.vue'
  import Committee from '@/components/Event/types/committee.vue'
  import Nomination from '@/components/Event/types/nomination.vue'
  import Termination from '@/components/Event/types/appointmentTermination.vue'
  import NominalValue from '@/components/Event/types/nominalValue.vue'
  import Reduction from '@/components/Event/types/reduction.vue'
  import Vesting from '@/components/Event/types/vesting.vue'
  import Approval from '@/components/Event/types/approval.vue'
  import Sunset from '@/components/Event/types/sunset.vue'
  import FinancialEnding from '@/components/Event/types/finacialEnding.vue'
  import ParityChange from '@/components/Event/types/parityChange.vue'
  import Commitment from '@/components/Event/types/commitment.vue'
  import StockIssuance from '@/components/Event/types/stockIssuance.vue'
  import OptionIssuance from '@/components/Event/types/optionIssuance.vue'
  import CompositionIssuance from '@/components/Event/types/compositionIssuance.vue'
  import { EventType } from '@/types/event'
  import { Company, Event } from '@/services/api/models'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import DelegationSunset from '@/components/Event/types/delegationSunset.vue'
  import DocAutoApplyForm from '@/components/Forms/Event/docAutoApplyForm.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { Field } from 'vee-validate'
  import { Button } from '@up.law/uplaw-ui'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    name: 'EventOperation',
    components: {
      Loader,
      StockIssuance,
      Sunset,
      Button,
      DocAutoApplyForm,
      Checkbox,
      OptionIssuance,
      Field,
      Delegation,
      Termination,
      Ceiling,
      CompositionIssuance,
      Transfer,
      Nomination,
      NominalValue,
      Reduction,
      Exercise,
      Vesting,
      FinancialEnding,
      Committee,
      Conversion,
      ParityChange,
      Approval,
      Commitment,
      DelegationSunset
    },
    props: { canEditParts: { type: Boolean, default: false, required: false } },
    emits: ['on-updated'],
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      const isLoading = ref(false)
      const route = useRoute()
      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )

      const nominalValues = computed(
        () => store.getters['organizations/nominalValues']
      )

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const nominalValue = ref(0)
      const event = ref<Event | null>(null)
      const formData = ref<any>({})
      const isValidDocauto = computed(
        () =>
          formData.value?.templatesId?.length == 0 ||
          !formData.value?.docAutomationEmailSubject ||
          !formData.value?.docAutomationEmailBody
      )

      const canMakeDocAuto = computed(() =>
        [
          EventType.transfer.toString(),
          EventType.optionExercise.toString(),
          EventType.optionIssuance.toString()
        ].includes(event.value?.type?.toString() ?? '')
      )
      const calculateNominalValue = async (date: string) => {
        const value = await store.dispatch(
          'organizations/COMPUTE_NOMINAL_VALUE',
          { date, setAsGlobal: false }
        )

        if (value?.nominalValue) nominalValue.value = value?.nominalValue
      }

      const getEvent = (eventId = route.params.eventId) => {
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId,
          onSuccess: async (data: Event | null) => {
            event.value = data
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const saveDocAuto = () => {
        let action = 'ADD_DOCAUTO_TO_OPTIONISSUANCE'
        if (event.value?.type?.toString() == EventType.transfer) {
          action = 'ADD_DOCAUTO_TO_TRANSFER'
        }
        if (event.value?.type?.toString() == EventType.optionExercise) {
          action = 'ADD_DOCAUTO_TO_OPTION_EXERCISE'
        }

        store.dispatch(`organizations/${action}`, {
          companyId: currentCompany.value.id ?? route.params.id,
          optionIssuanceId: event.value?.optionIssuance?.id,
          transferId: event.value?.transfer?.id,
          optionExerciseId: event.value?.optionExercise?.id,
          data: formData.value,
          onSuccess: () => {
            formData.value = {}
            getEvent()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('docAutomation.context'),
              message: t(`docAutomation.apiResponse.doc_auto_added`)
            })
          },
          onError: (error: any) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('docAutomation.context'),
              message:
                error?.data?.message ??
                t(`docAutomation.apiResponse.doc_auto_add_error`)
            })
          }
        })
      }

      watch([() => event.value, nominalValues], ([val, vals]) => {
        if (val?.date) calculateNominalValue(val?.date || '')
      })

      onMounted(getEvent)
      return {
        EventType,
        event,
        formData,
        saveDocAuto,
        isValidDocauto,
        nominalValue,
        isLoading,
        canMakeDocAuto
      }
    }
  })
</script>
