
  import { useForm, Field } from 'vee-validate'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import * as yup from 'yup'
  import {
    DocAutomationSigner,
    DocAutoTemplate,
    DocAutoTemplateField,
    OptionIssuanceFieldsEnum,
    SignerType,
    TransferFieldsEnum,
    OptionExerciseFieldsEnum
  } from '@/store/modules/Organization/docAutoModel'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { TYPE } from 'vue-toastification'
  import { useRoute } from 'vue-router'
  import { EventType } from '@/types/event'

  interface FieldsTemplateForm {
    templateFields: DocAutoTemplateField[]
    globalField: string
    signers: DocAutomationSigner[]
  }
  export default defineComponent({
    components: { FormActions, Multiselect, Field, DropdownContacts },
    props: { templateData: { type: Object as () => DocAutoTemplate } },
    emits: ['onUpdated', 'removeTemplate'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()

      const selectedTemplate = computed<DocAutoTemplate | null>(
        () => store.getters['organizations/template']
      )
      const updatedTemplate = ref<DocAutoTemplate | null>()

      const schema = computed(() => ({
        templateFields: yup.array().of(
          yup.object({
            tag: yup.string().required('tagRequired'),
            eventField: yup.string().nullable().required('eventFieldRequired')
          })
        ),
        globalField: yup.string().optional()
      }))
      const fields = computed(() =>
        Object.values(
          selectedTemplate.value?.eventType == EventType.transfer
            ? TransferFieldsEnum
            : selectedTemplate.value?.eventType == EventType.optionIssuance
            ? OptionIssuanceFieldsEnum
            : OptionExerciseFieldsEnum
        ).map((x) => ({
          id: x,
          name: t(`docAutomation.eventFields.${x}`)
        }))
      )

      const types = computed(() =>
        Object.values(SignerType).map((x) => ({
          id: x,
          name: t(`docAutomation.signerType.${x}`)
        }))
      )
      const formData = ref<FieldsTemplateForm>({
        templateFields: [],
        signers: [],
        globalField: ''
      })

      const { errors, meta, setValues, handleSubmit, resetForm } = useForm({
        initialValues: formData.value,
        validationSchema: schema.value
      })

      const typeChanged = (value: string | null, index: number) => {
        formData.value.signers[index].signerType == value
        formData.value.signers[index].knownContactId == null
      }

      const handleForm = handleSubmit((values) => {
        const companyId = route.params.id

        const isSameMappedField = values.templateFields.some(
          (x: any) =>
            values.templateFields.filter(
              (y: any) => y.eventField == x.eventField
            ).length > 1
        )

        const data = {
          ...selectedTemplate.value,
          templateFields: values.templateFields,
          signers: values.signers
        }

        store.dispatch('organizations/EDIT_TEMPLATE', {
          data,
          templateId: selectedTemplate.value?.id,
          companyId: companyId,
          onSuccess: (response: any) => {
            updatedTemplate.value = response
            updateFields(response)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.success_edit`)
            })
            emit('onUpdated', response)
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.error_edit`)
            })
          }
        })
      })

      const handleReset = () => {
        resetForm()
      }

      const removeTemplate = () => {
        emit('removeTemplate', selectedTemplate.value?.id)
      }

      const handleContactSelect = (value: any, index: number) => {
        formData.value.signers[index].knownContactId = value

        setValues(formData.value)
      }

      const updateFields = (templ: DocAutoTemplate | null) => {
        templ?.templateFields.forEach((x) =>
          formData.value.templateFields.push({
            tag: x.tag,
            eventField: x.eventField
          })
        )
        templ?.signers.forEach((x) =>
          formData.value.signers.push({
            tag: x.tag,
            signerType: x.signerType,
            knownContactId: x.knownContactId
          })
        )
      }
      watch(
        () => selectedTemplate.value,
        (templ) => {
          formData.value.signers = []
          formData.value.templateFields = []
          updateFields(templ)
        }
      )

      onMounted(() => {
        formData.value.signers = []

        formData.value.templateFields = []
        updateFields(props.templateData ?? selectedTemplate.value)
      })

      return {
        errors,
        meta,
        types,
        formData,
        SignerType,
        selectedTemplate,
        removeTemplate,
        fields,
        handleContactSelect,
        typeChanged,
        handleReset,
        handleForm
      }
    }
  })
