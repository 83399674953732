
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { ElStep, ElSteps } from 'element-plus'
  import TemplateImport from './importTemplate.vue'
  import TemplateConfig from './config.vue'
  import Test from './testTemplate.vue'
  import { DocAutoTemplate } from '@/store/modules/Organization/docAutoModel'
  import { useStore } from 'vuex'
  import PDFViewer from '@/components/PDFViewer/express.vue'
  import { useRoute } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import Loader from '@/components/Loader/index.vue'

  export default defineComponent({
    components: {
      PDFViewer,
      ElStep,
      TemplateImport,
      TemplateConfig,
      Test,
      Loader,
      ElSteps
    },
    setup() {
      const { t } = useI18n()
      const selectedFile = ref(null)
      const docLink = ref<string | undefined>('')
      const parts = ref<any[]>([])
      const store = useStore()
      const route = useRoute()
      const displayMessage = ref(true)
      const isLoadingFile = ref(false)

      const currentStep = ref(0)

      const updatedTemplate = ref<DocAutoTemplate | null>()

      const selectedTemplate = computed<DocAutoTemplate | null>(
        () => store.getters['organizations/template']
      )

      const startTest = (value: boolean) => {
        isLoadingFile.value = value
      }

      const handleEvent = (param: string) => {
        removeTemplate(param)
      }

      const updatePdfLink = (link: any) => {
        docLink.value = link
        displayMessage.value = false
        isLoadingFile.value = false
      }

      const onUpdated = (newTemplate: any) => {
        updatedTemplate.value = newTemplate
        currentStep.value = 2
        displayMessage.value = true
      }

      const removeTemplate = (id: string) => {
        store.dispatch('organizations/REMOVE_TEMPLATE', {
          companyId: route.params.id,
          templateId: id,
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.success_delete`)
            })

            currentStep.value = 0
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.error_delete`)
            })
          }
        })
      }

      const handleConfigStep = () => {
        if (selectedTemplate.value?.id) currentStep.value = 1
      }

      const getEvents = () => {
        if (!selectedTemplate.value) return
        store.dispatch('organizations/GET_EVENTS_PARTS', {
          companyId: route.params.id,
          filter: { eventTypes: [selectedTemplate.value?.eventType] },
          onSuccess: (response: any) => {
            console.log(response)

            parts.value = [...(response ?? [])]
          }
        })
      }

      watch(
        () => selectedTemplate.value,
        (val) => {
          getEvents()

          if (val?.templateFields.some((x) => x.eventField == null))
            currentStep.value = 1
          else if (!val) currentStep.value = 0
          else currentStep.value = 2
          updatedTemplate.value = null
          docLink.value = val?.documentURL
        }
      )

      return {
        selectedFile,
        currentStep,
        parts,
        docLink,
        updatedTemplate,
        displayMessage,
        isLoadingFile,
        selectedTemplate,
        removeTemplate,
        handleEvent,
        startTest,
        onUpdated,
        handleConfigStep,
        updatePdfLink
      }
    }
  })
