import { createI18n } from 'vue-i18n'
import store from '@/store'
import { getFormStorage } from '@/services/Storage'
import { storageKeys } from '../../utils/global'

const supportedLocales = ['en', 'fr']

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  let messages = {}
  locales.keys().forEach((key: string) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      const data = locales(key)
      messages = { ...messages, [locale]: data }
    }
  })

  return messages
}

let [browserLang] = navigator.language.split('-')
browserLang = supportedLocales.includes(browserLang) ? browserLang : 'en'

const lang =
  getFormStorage(storageKeys.userLang) || supportedLocales.includes(browserLang)
    ? getFormStorage(storageKeys.userLang) || browserLang
    : 'en'

const i18n = createI18n({
  warnHtmlInMessage: 'off',
  locale: store?.getters['config/lang'] ? store?.getters['config/lang'] : lang,
  fallbackLocale: store?.getters['config/lang']
    ? store.getters['config/lang']
    : lang,
  enableLegacy: true,
  messages: loadLocaleMessages()
})

export default i18n
