
  import { CompanyModel } from '@/services/api/models1'
  import { computed, defineComponent } from 'vue'
  import { useRoute } from 'vue-router'
  import { useAcl } from 'vue-simple-acl/src'
  import { useStore } from 'vuex'

  export default defineComponent({
    inject: ['$access'],

    setup() {
      const acl = useAcl()
      const store = useStore()
      const route = useRoute()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const isInRegisterMenu = computed(
        () =>
          route.name?.toString() !== 'register.stockMovement' &&
          route.name?.toString().split('.').indexOf('register') == 0
      )

      const isInReportMenu = computed(
        () =>
          route.name?.toString() !== 'reports.vote' &&
          route.name?.toString().split('.').indexOf('reports') == 0
      )

      return { currentCompany, isInReportMenu, isInRegisterMenu, acl }
    }
  })
