<template>
  <div class="w-full h-full overflow-y-scroll">
    <div class="flex px-12">
      <h3 class="text text-blackp text-2xl py-8">
        {{ $t('company.shareholding_title') }}
      </h3>
    </div>

    <div class="px-12 h-full">
      <Tab :tabs="tabList" />
    </div>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, onBeforeUnmount, ref } from 'vue'
  import Tab from '@/components/Tab/index.vue'
  import OptionTab from './tabs/OptionsTab.vue'
  import HolderTab from './tabs/HolderTab.vue'
  import { useStore } from 'vuex'
  import { ExerciseCalendar } from '@/services/api/models'
  export default defineComponent({
    components: { Tab },
    setup() {
      const store = useStore()
      const tabList = [
        { key: 'holder', langKey: 'byHolder', component: HolderTab },
        { key: 'stock', langKey: 'byStock', component: OptionTab }
      ]
      const tabData = ref({
        stock: []
      })
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )

      onBeforeUnmount(() =>
        store.commit('organizations/SET_EXERCISE_CALENDARS', {
          ...exerciceCalendars,
          data: []
        })
      )
      return { tabData, tabList }
    }
  })
</script>
