<template>
  <div class="w-full h-full ownership flex flex-col">
    <div class="flex my-5 flex justify-between">
      <div class="md:w-4/5 lg:w-3/4 xl:w-1/2 flex justify-start">
        <div class="w-1/5 lg:w-1/3 xl:w-2/5 h-10 mr-2">
          <DatePicker
            v-model="filter.date"
            class="max-w-xs rounded text-xs h-10 mr-2"
            placeholder="--/--/----"
          />
        </div>
        <div class="w-1/5 lg:w-1/3 xl:w-1/3 h-10 mr-2">
          <multiselect
            v-model="filter.contactGroupId"
            :options="companyGroups"
            :groups="false"
            :has-label="false"
            :value="filter.contactGroupId"
            :placeholder="$t('contactSearch.group')"
            open-direction="bottom"
            classes="h-10"
            @change="handleOptionChange($event, 'contactGroupId')"
          />
        </div>
        <div class="w-1/5 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <Multiselect
            v-model="filter.contactId"
            :can-add-new="false"
            elementName="contactBook.contacts"
            selectionText="global.actions.selected1"
            open-direction="bottom"
            :has-label="false"
            :value="filter.contactId"
            mode="multiple"
            :filter-results="false"
            :options="contacts"
            :placeholder="$t('global.placeholder.contact')"
            name="contactId"
            :groups="false"
            @search-change="handleContactSearch"
            @change="handleOptionChange($event, 'contactId')"
          />
        </div>
        <div class="w-1/4 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <Multiselect
            v-model="filter.delegationId"
            :value="filter.delegationId"
            :options="delegations"
            mode="multiple"
            elementName="global.delegations"
            selectionText="global.actions.selected2"
            :placeholder="$t('global.placeholder.delegation')"
            name="delegationId"
            @change="handleOptionChange($event, 'delegationId')"
            @search-change="handleDelegationSearch"
          />
        </div>

        <div class="w-1/4 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <DropdownOptions
            v-model="filter.optionAliasId"
            :can-add-new="false"
            :option-data="{ index: -1 }"
            :value="filter.optionAliasId"
            open-direction="bottom"
            :has-label="false"
            mode="multiple"
            :options="optionAliases"
            :placeholder="$t('global.placeholder.option')"
            name="optionAliasId"
            :groups="false"
            :option-per-price="false"
            :filter-results="false"
            @on-option-updated="handleOptionChange($event, 'optionAliasId')"
          />
        </div>
        <div class="w-1/4 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <DropdownVesting
            v-model="filter.vestingId"
            :can-add-new="false"
            :value="filter.vestingId"
            open-direction="bottom"
            :has-label="false"
            mode="single"
            :placeholder="$t('global.placeholder.vesting')"
            name="vestingId"
            :groups="false"
            :filter-results="false"
            @on-updated="handleOptionChange($event?.value, 'vestingId')"
          />
        </div>
      </div>
      <div class="filters-date flex justify-between">
        <div class="h-10 flex">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('tooltips.columns')"
            placement="top"
          >
            <Filter
              class="relative mx-2"
              :options="availableColumns"
              :value="selectedColumns"
              @on-change="handleFilterSelected"
            >
              <template #option="{ option, checked }">
                <div
                  :id="option.id"
                  class="
                    rounded
                    border-2 border-primary4
                    w-5
                    h-5
                    flex
                    items-center
                    justify-center
                    mr-2
                  "
                >
                  <i
                    v-if="checked"
                    class="bi bi-check text-primary4 text-md"
                  ></i>
                </div>
              </template>
              <i class="bi bi-gear"></i>
            </Filter>
          </el-tooltip>

          <!-- <DownloadButton
            class="mr-2"
            :on-click="downloadHotjar"
          ></DownloadButton> -->
          <DownloadButton
            v-if="!isDownloading"
            class="mr-2"
            :on-click="download"
          ></DownloadButton>

          <el-tooltip
            v-else-if="isDownloading"
            class="box-item"
            effect="dark"
            :content="$t('tooltips.downloading')"
            placement="top"
          >
            <div
              class="
                border
                cursor-not-allowed
                rounded-3
                bg-white
                border-secondary6
                px-3
                flex
                items-center
              "
            >
              <i
                class="bi bi-arrow-clockwise text-xl text-secondary8 rotating"
              ></i>
            </div>
          </el-tooltip>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="$t('tooltips.resetFilter')"
            placement="top"
            ><Button
              variant="custom"
              class="rounded-sm dwnld-btn h-10 ml-2 bg-white"
              @click="resetFilters"
            >
              <i class="bi bi-filter text-2xl"></i> </Button
          ></el-tooltip>
        </div>
      </div>
    </div>

    <div class="mb-5 mt-2 text-sm flex flex-col h-full w-full">
      <div
        v-if="isLoading || loading"
        class="flex h-full justify-center items-center"
      >
        <Loader />
      </div>
      <div v-else-if="exerciceCalendars.length > 0" class="pb-5">
        <DataTable
          thead-class="sticky top-0"
          :has-checkbox="false"
          :columns="columns"
          :rows="exerciceCalendars"
          :pagination="pagination"
          :is-filtering="isFiltering"
          @onPageChange="onPageChange"
          @on-filtered="updateFIltering"
        >
          <template #stock="{ row }">
            <Tag
              :value="row.optionAlias.id"
              :text="row.optionAlias.alias.toUpperCase()"
              :with-initials="false"
              :with-remove="false"
              :color="rgbToHex(row.optionAlias?.colorCode)"
            />
          </template>
          <template #user="{ row }">
            <div class="flex justify-start items-center">
              <router-link
                :to="{
                  name: 'company.directory',
                  params: {
                    id: $route.params.id,
                    contactId: row.contact.id
                  }
                }"
              >
                <ContactBadge
                  :id="row.contact.id"
                  class="p-0 text-xs"
                  :contact="row.contact"
                  :details="false"
                  type="light"
                />
              </router-link>
            </div>
          </template>
          <template #vestingStartDate="{ row }">
            <p v-if="row.exercisePeriods[0]?.startDate" class="break-initial">
              {{
                moment
                  .utc(row.exercisePeriods[0].startDate)
                  .format($t('dateFormat'))
              }}
            </p>
            <p v-else>-</p>
          </template>
          <template #allocatedQuantity="{ row }">
            <Number
              :value="row.optionIssuancePart.quantity"
              :mode="NumberType.quantity"
            />
          </template>
          <template #vestingEndDate="{ row }">
            <p
              class="break-initial"
              v-if="
                row.exercisePeriods[row.exercisePeriods.length - 1]?.endDate
              "
            >
              {{
                moment
                  .utc(
                    row.exercisePeriods[row.exercisePeriods.length - 1].endDate
                  )
                  .format($t('dateFormat'))
              }}
            </p>
            <p v-else>-</p>
          </template>
          <template #exercisableQuantity="{ row }">
            <Number :value="row.vestedQuantity" :mode="NumberType.quantity" />
          </template>
          <template #ceil="{ row }">
            <Number
              :value="row.exercisableBalance"
              :mode="NumberType.quantity"
            />
          </template>
          <template #lapsedQuantity="{ row }">
            <Number
              zero-presents="-"
              :value="row.numberOfSunsetedOptions"
              :mode="NumberType.quantity"
            />
          </template>
          <template #exercisedQuantity="{ row }">
            <Number
              zero-presents="-"
              :value="row.exercisedQuantity"
              :mode="NumberType.quantity"
            />
          </template>
          <template #heldBalance="{ row }">
            <Number
              :value="row.notVestedQuantity"
              :mode="NumberType.quantity"
            />
          </template>
          <template #exercisePrice="{ row }">
            <Number
              :value="row.optionIssuancePart?.optionIssuance.exercisePrice"
              :mode="NumberType.price"
            />
          </template>
          <template #totalExercisePrice="{ row }">
            <Number
              :value="
                row.optionIssuancePart?.optionIssuance?.exercisePrice *
                row.optionIssuancePart.quantity
              "
              :mode="NumberType.price"
            />
          </template>
          <template #lastActionPrice="{ row }">
            <Number
              zero-presents="-"
              :value="row.lastStockPrice"
              :mode="NumberType.price"
            />
          </template>
          <template #pv="{ row }">
            <Number
              :zero-presents="
                row.potentialGain100Percent === undefined ? '-' : 0
              "
              :value="
                row.potentialGain100Percent < 0
                  ? 0
                  : row.potentialGain100Percent
              "
              :mode="NumberType.price"
            />
          </template>
          <!-- <template #pv_vested="{ row }">
          <Number
            :zero-presents="row.portentialGainAtDate === undefined ? '-' : 0"
            :value="row.portentialGainAtDate < 0 ? 0 : row.portentialGainAtDate"
            :mode="NumberType.price"
          />
        </template> -->
          <template #vesting="{ row }">
            <div>
              {{ row.vestingCalendar?.name || '-' }}
            </div>
          </template>
          <template #delegation="{ row }">
            <p>
              {{ row.delegation?.label || '-' }}
            </p>
          </template>
          <template #eventName="{ row }">
            <div
              v-if="row.optionIssuancePart?.optionIssuance?.event?.id"
              class="flex justify-start"
            >
              <div
                class="
                  text-left
                  cursor-pointer
                  bg-primary1
                  rounded
                  w-8
                  h-8
                  flex
                  justify-center
                  items-center
                  hover:bg-primary2
                "
                @click="
                  gotoEvent(row.optionIssuancePart?.optionIssuance?.event?.id)
                "
              >
                <i class="bi bi-lightning text-xl text-primary4"></i>
              </div>
            </div>
            <p v-else>-</p>
          </template>
          <template #total>
            <tr v-if="isFiltered">
              <td :colspan="`${getOrder('delegation') > 0 ? '5' : '4'}`">
                <p class="text-right font-bold py-3 px-2 text-primary8">
                  Total
                </p>
              </td>
              <td></td>

              <td v-if="getOrder('allocatedQuantity') > 0">
                <Number
                  :classes="`text-left font-bold py-3 px-2 text-${
                    total >= 0 ? 'primary8' : 'error'
                  }`"
                  :value="exerciceCalendarsTotal?.assignedAmount || 0"
                  :mode="NumberType.quantity"
                />
              </td>
              <td v-if="getOrder('exercisedQuantity') > 0">
                <Number
                  classes="text-left font-bold py-3 px-2 text-primary8"
                  :value="exerciceCalendarsTotal?.exercisedQuantity || 0"
                  :mode="NumberType.quantity"
                />
              </td>
              <td v-if="getOrder('lapsedQuantity') > 0">
                <Number
                  classes="text-left font-bold py-3 px-2 text-primary8"
                  :value="exerciceCalendarsTotal?.numberOfSunsetedOptions || 0"
                  :mode="NumberType.quantity"
                />
              </td>
              <td v-if="getOrder('heldBalance') > 0">
                <Number
                  classes="text-left font-bold py-3 px-2 text-primary8"
                  :value="exerciceCalendarsTotal?.nonVestedQuantity || 0"
                  :mode="NumberType.quantity"
                />
              </td>
              <td v-if="selectedColumns.includes('exercisableQuantity')">
                <Number
                  :classes="`text-left font-bold py-3 px-2 text-${
                    exerciceCalendarsTotal?.vestedQuantity >= 0
                      ? 'primary8'
                      : 'error'
                  }`"
                  :value="exerciceCalendarsTotal?.vestedQuantity || 0"
                  :mode="NumberType.quantity"
                />
              </td>

              <td v-if="getOrder('ceil') > 0">
                <Number
                  classes="text-left font-bold py-3 px-2 text-primary8"
                  :value="exerciceCalendarsTotal?.exercisableQuantity || 0"
                  :mode="NumberType.quantity"
                />
              </td>

              <td v-if="getOrder('lastActionPrice') > 0">-</td>

              <td v-if="getOrder('totalExercisePrice') > 0">
                <p class="text-left font-bold py-3 px-2 text-primary8"></p>
              </td>

              <td v-if="getOrder('pv') > 0">-</td>
              <td></td>
              <!-- <td v-if="getOrder('pv_vested') > 0">-</td> -->
            </tr>
          </template>
        </DataTable>
        <div class="py-3 px-2 flex justify-start">
          <p class="">
            {{ $t('global.lineCount', { count: pagination.total }) }}
          </p>
        </div>
      </div>
      <div
        v-else
        class="
          flex
          justify-center
          items-center
          w-full
          mt-4
          transition transition-all
        "
      >
        <EmptyState
          image="no-result.svg"
          class="d-block mx-auto"
          :title="$t('global.noResult')"
          :subtitle="$t('global.noData')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DataTable from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import {
    Contact,
    ExerciseCalendar,
    OptionStockAlias,
    VestingSynthesis
  } from '@/services/api/models'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { formatNumber, rgbToHex, ucFirst } from '@/utils'
  import { useI18n } from 'vue-i18n'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import Number from '@/components/Number/index.vue'
  import { NumberType } from '@/types'
  import { ElTooltip } from 'element-plus'
  import DropdownOptions from '@/components/Forms/DropDowns/DropdownOptions.vue'
  import DropdownVesting from '@/components/Forms/DropDowns/DropdownVesting.vue'
  export default defineComponent({
    components: {
      DataTable,
      Tag,
      ContactBadge,
      EmptyState,
      Multiselect,
      DatePicker,
      Filter,
      Button,
      ElTooltip,
      DropdownOptions,
      Loader,
      DownloadButton,
      Number,
      DropdownVesting
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const selectedColumns = ref<string[]>([])
      const isFiltering = ref(false)
      const filter = ref<any>({
        vestingId: '',
        contactId: [],
        delegationId: [],
        optionAliasId: [],
        contactGroupId: '',
        date: new Date().toISOString()
      })

      const isFiltered = computed(
        () =>
          filter.value?.vestingId ||
          filter.value?.delegationId?.length ||
          filter.value?.contactId?.length ||
          filter.value?.contactGroupId ||
          filter.value?.optionAliasId?.length ||
          moment.utc(filter.value.date).endOf('day').unix() !==
            moment.utc().endOf('day').unix()
      )
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )
      const pagination = computed(
        () => store.getters['organizations/exerciseCalendarPagination']
      )
      const exerciceCalendarsTotal = computed<VestingSynthesis>(
        () => store.getters['organizations/exerciseCalendarsTotal']
      )
      const currency = computed(() => store.getters['organizations/currency'])
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )

      const loading = ref(true)

      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )

      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData'] && loading
      )

      const optionAliases = ref<OptionStockAlias[]>([])

      const contacts = computed<Contact[]>(() =>
        store.getters['organizations/contacts'].map((x: any) => ({
          ...x,
          name: ucFirst(x.name)
        }))
      )

      const total = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.optionIssuancePart.quantity),
          0
        )
      )

      const exercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += c.vestedQuantity), 0)
      )

      const ceil = computed(() => {
        return exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.exercisableBalance),
          0
        )
      })

      const obsoleteQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.numberOfSunsetedOptions),
          0
        )
      )
      const exercisedQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.exercisedQuantity),
          0
        )
      )

      const heldBalance = computed(
        () => total.value - exercisableQuantity.value
      )

      const exercisePrice = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              c.optionIssuancePart.unitPrice ||
              c.optionIssuancePart.totalPrice / c.optionIssuancePart.quantity),
          0
        )
      )

      const lastStockPrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += c.lastStockPrice), 0)
      )
      const portentialGainAtDate = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc += c.portentialGainAtDate < 0 ? 0 : c.portentialGainAtDate),
          0
        )
      )

      const totalExercisePrice = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              (c.optionIssuancePart.unitPrice ||
                c.optionIssuancePart.totalPrice /
                  c.optionIssuancePart.quantity) *
              c.optionIssuancePart.quantity),
          0
        )
      )
      const potentialGain100Percent = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              c.potentialGain100Percent < 0 ? 0 : c.potentialGain100Percent),
          0
        )
      )

      const allColumns = ref([
        {
          label: t('events.fieldsLabels.stock'),
          field: 'stock',
          custom: true,
          tooltipText: t('events.tooltips.stock'),
          tooltipWidth: '12%',
          hasTooltip: true,
          order: 2
        },
        {
          label: t('events.fieldsLabels.contact'),
          field: 'user',
          custom: true,
          order: 1,
          sortBy: 'contact',
          tooltipText: t('events.tooltips.contact'),
          tooltipWidth: '12%',
          hasTooltip: true,
          sortFunction: (a: any, b: any) => {
            return a?.contact.name.localeCompare(b.contact.name)
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.vestingStartDate'),
          field: 'vestingStartDate',
          custom: true,
          order: 3,
          tooltipText: t('events.tooltips.vestingStartDate'),
          tooltipWidth: '17%',
          hasTooltip: true,
          sortBy: 'vestingStartDate',
          sortFunction: (a: any, b: any) => {
            return (
              moment
                .utc(a.exercisePeriods[a.exercisePeriods.length - 1].startDate)
                .unix() -
              moment
                .utc(b.exercisePeriods[b.exercisePeriods.length - 1].startDate)
                .unix()
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.vestingEndDate'),
          field: 'vestingEndDate',
          custom: true,
          filterable: true,
          selected: true,
          order: 4,
          tooltipText: t('events.tooltips.vestingEndDate'),
          tooltipWidth: '17%',
          hasTooltip: true,
          sortBy: 'vestingEndDate',
          sortFunction: (a: any, b: any) => {
            return (
              moment
                .utc(a.exercisePeriods[a.exercisePeriods.length - 1].endDate)
                .unix() -
              moment
                .utc(b.exercisePeriods[b.exercisePeriods.length - 1].endDate)
                .unix()
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.delegation'),
          field: 'delegation',
          custom: true,
          tooltipText: t('events.tooltips.delegation'),
          tooltipWidth: '12%',
          hasTooltip: true,
          order: 4.5,
          filterable: true
        },
        {
          label: t('events.fieldsLabels.assignedQuantity'),
          field: 'allocatedQuantity',
          custom: true,
          order: 6,
          sortBy: 'allocatedQuantity',
          tooltipText: t('events.tooltips.assignedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true,
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart.quantity - b?.optionIssuancePart.quantity
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.exercisableQuantity'),
          field: 'exercisableQuantity',
          custom: true,
          filterable: true,
          order: 9,
          selected: false,
          tooltipText: t('events.tooltips.vestedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          sortBy: 'exercisableQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.exercisableQuantity - b?.exercisableQuantity
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.balance'),
          field: 'ceil',
          custom: true,
          filterable: true,
          order: 10,
          selected: true,
          sortBy: 'ceil',
          tooltipText: t('events.tooltips.exercisableBalance'),
          tooltipWidth: '20%',
          hasTooltip: true,
          sortFunction: (a: any, b: any) => {
            return a?.exercisableBalance - b?.exercisableBalance
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.lapsedQuantity'),
          field: 'lapsedQuantity',
          custom: true,
          filterable: true,
          order: 8,
          selected: true,
          tooltipText: t('events.tooltips.lapsedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true,
          sortBy: 'lapsedQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.numberOfSunsetedOptions - b?.numberOfSunsetedOptions
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.exercisedQuantity'),
          field: 'exercisedQuantity',
          custom: true,
          filterable: true,
          order: 7,
          selected: true,
          tooltipText: t('events.tooltips.exercisedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          sortBy: 'exercisedQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.exercisedQuantity - b?.exercisedQuantity
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.heldBalance'),
          field: 'heldBalance',
          custom: true,
          filterable: true,
          order: 8,
          selected: false,
          tooltipText: t('events.tooltips.notVestedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          sortBy: 'heldBalance',
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart.quantity -
              a?.vestedQuantity -
              (b?.optionIssuancePart.quantity - b?.vestedQuantity)
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.exercisePrice'),
          field: 'exercisePrice',
          custom: true,
          filterable: true,
          order: 5,
          selected: true,
          tooltipText: t('events.tooltips.exercisePrice'),
          tooltipWidth: '12%',
          hasTooltip: true,
          sortBy: 'exercisePrice',
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart?.optionIssuance?.exercisePrice -
              b?.optionIssuancePart?.optionIssuance?.exercisePrice
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.totalExercisePrice'),
          field: 'totalExercisePrice',
          custom: true,
          filterable: true,
          order: 12.5,
          tooltipText: t('events.tooltips.totalExercisePrice'),
          tooltipWidth: '17%',
          hasTooltip: true,
          selected: false
        },
        {
          label: t('events.fieldsLabels.lastStockPrice'),
          field: 'lastActionPrice',
          custom: true,
          filterable: true,
          tooltipText: t('events.tooltips.lastStockPrice'),
          tooltipWidth: '20%',
          hasTooltip: true,
          order: 12
        },
        {
          label: t('events.fieldsLabels.potentialPv'),
          field: 'pv',
          custom: true,
          filterable: true,
          tooltipText: t('events.tooltips.potentialPv'),
          tooltipWidth: '17%',
          hasTooltip: true,
          order: 13
        },
        // {
        //   label: t('events.fieldsLabels.vestedPv'),
        //   field: 'pv_vested',
        //   custom: true,
        //   filterable: true,
        //   order: 14
        // },
        {
          label: t('events.fieldsLabels.vestingPlan'),
          field: 'vesting',
          custom: true,
          filterable: true,
          tooltipText: t('events.tooltips.vestingPlan'),
          tooltipWidth: '17%',
          hasTooltip: true,
          order: 15
        },
        {
          label: t('datatable.column.event'),
          field: 'eventName',
          tooltipText: t('events.tooltips.event'),
          tooltipWidth: '20%',
          hasTooltip: true,
          custom: true,

          order: 15,
          filterable: true,
          selected: true
        }
      ])
      const existingColumns = ref(allColumns.value.filter((x) => !x.filterable))
      const columns = ref(
        allColumns.value
          .filter((x) => !x.filterable || x.selected)
          .sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable || x.selected)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      selectedColumns.value = allColumns.value
        .filter((x) => x.selected)
        .map((x) => x.field)

      const handleFilterSelected = (value: string[]) => {
        columns.value = [
          ...existingColumns.value,
          ...allColumns.value.filter((x) => value.includes(x.field))
        ].sort((a: any, b: any) => a.order - b.order)

        selectedColumns.value = value
      }
      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }
      const getVesting = async () => {
        await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            esopOnly: true,
            ...filter.value
          }
        })

        loading.value = false
      }

      const getTotal = () => {
        if (!isFiltered.value) return
        store.dispatch('organizations/GET_EXERCISE_CALENDARS_TOTAL', {
          companyId: route.params.id,
          filter: {
            esopOnly: true,
            ...filter.value
          }
        })
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_EXERCISE_CALENDARS_PAGE', page)
        getVesting()
      }

      const isDownloading = ref(false)
      const resetFilters = () => {
        filter.value = {
          vestingId: '',
          contactId: [],
          delegationId: [],
          optionAliasId: [],
          contactGroupId: '',
          date: new Date().toISOString()
        }
      }

      const downloadHotjar = () => {
        const companyId = route.params.id
        store.dispatch('organizations/exportHotjar', {
          name: `Caducités`,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }
      const download = () => {
        isDownloading.value = true

        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_EXERCISE_CALENDARS', {
          name: `${t('exports.shareholding')} `,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }
      const getOrder = (field: string) =>
        columns.value.find((x) => x.field === field)?.order || 0

      const getStocks = (search?: string) => {
        const filter: any = {}
        if (search) filter.name = search
        store.dispatch('organizations/GET_OPTION_STOCK_ALIAS', {
          companyId: route.params.id,
          filter,
          onSuccess: (data: OptionStockAlias[]) => {
            optionAliases.value = [...data].map((x) => ({
              ...x,
              name: x.alias
            }))
          }
        })
      }

      const updateFIltering = (value: boolean) => {
        isFiltering.value = value
      }
      const handleOptionChange = (data: any, field: string) => {
        isFiltering.value = true
        filter.value[field] = data
        getTotal()
        onPageChange(1)
      }

      watch(
        () => route.params.id,
        () => {
          getTotal()
          onPageChange(1)
        }
      )

      watch(
        () => filter.value.date,
        (val) => {
          onPageChange(1)
          filter.value.date = val || new Date().toISOString()
          getTotal()
        }
      )

      const handleContactSearch = (search: string) => {
        getContacts(search)
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0,
            name: search
          }
        })
      }

      const handleDelegationSearch = (search = '') => {
        store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)
        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id,
          filter: { search }
        })
      }
      const getDelegations = () => {
        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id,
          filter: {}
        })
      }

      onMounted(async () => {
        store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)

        getDelegations()
        getTotal()

        onPageChange(1)

        getStocks()

        getContacts()
      })

      onBeforeMount(() => (loading.value = true))

      return {
        currency,
        contacts,
        columns,
        isFiltered,
        availableColumns,
        exerciceCalendars,
        exercisableQuantity,
        obsoleteQuantity,
        ceil,
        pagination,
        selectedColumns,
        delegations,
        heldBalance,
        exercisedQuantity,
        lastStockPrice,
        exercisePrice,
        portentialGainAtDate,
        potentialGain100Percent,
        total,
        downloadHotjar,
        isDownloading,
        optionAliases,
        filter,
        isLoading,
        loading,
        moment,
        companyGroups,
        totalExercisePrice,
        NumberType,
        exerciceCalendarsTotal,
        isFiltering,
        resetFilters,
        getStocks,
        formatNumber,
        download,
        onPageChange,
        handleFilterSelected,
        getOrder,
        gotoEvent,
        handleOptionChange,
        updateFIltering,
        handleDelegationSearch,
        handleContactSearch,
        rgbToHex
      }
    }
  })
</script>

<style lang="scss">
  .ownership {
    .rounded-3 {
      border-radius: 3px;
    }
    .filters-date {
      .button-filter {
        @apply h-full flex px-2 justify-center items-center border border-inputBorder rounded-sm hover:bg-primary5 hover:text-white;

        &:hover img {
          filter: brightness(0) invert(1);
          -webkit-filter: brightness(0) invert(1);
        }
      }
    }

    .allocatedQuantity {
      width: 12%;
    }

    .stock {
      padding: 5px !important;
    }

    .user {
      padding: 5px !important;
    }
  }

  .sticky {
    background: #f5f7fa;
  }
</style>
