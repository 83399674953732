
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import Loader from '@/components/Loader/index.vue'
  import Delegation from '@/components/Event/types/delegation.vue'
  import Exercise from '@/components/Event/types/exercise.vue'
  import Ceiling from '@/components/Event/types/ceiling.vue'
  import Transfer from '@/components/Event/types/transfer.vue'
  import Conversion from '@/components/Event/types/conversion.vue'
  import Committee from '@/components/Event/types/committee.vue'
  import Nomination from '@/components/Event/types/nomination.vue'
  import Termination from '@/components/Event/types/appointmentTermination.vue'
  import NominalValue from '@/components/Event/types/nominalValue.vue'
  import Reduction from '@/components/Event/types/reduction.vue'
  import Vesting from '@/components/Event/types/vesting.vue'
  import Approval from '@/components/Event/types/approval.vue'
  import Sunset from '@/components/Event/types/sunset.vue'
  import FinancialEnding from '@/components/Event/types/finacialEnding.vue'
  import ParityChange from '@/components/Event/types/parityChange.vue'
  import Commitment from '@/components/Event/types/commitment.vue'
  import StockIssuance from '@/components/Event/types/stockIssuance.vue'
  import OptionIssuance from '@/components/Event/types/optionIssuance.vue'
  import CompositionIssuance from '@/components/Event/types/compositionIssuance.vue'
  import { EventType } from '@/types/event'
  import { Company, Event } from '@/services/api/models'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import DelegationSunset from '@/components/Event/types/delegationSunset.vue'
  import DocAutoApplyForm from '@/components/Forms/Event/docAutoApplyForm.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { Field } from 'vee-validate'
  import { Button } from '@up.law/uplaw-ui'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    name: 'EventOperation',
    components: {
      Loader,
      StockIssuance,
      Sunset,
      Button,
      DocAutoApplyForm,
      Checkbox,
      OptionIssuance,
      Field,
      Delegation,
      Termination,
      Ceiling,
      CompositionIssuance,
      Transfer,
      Nomination,
      NominalValue,
      Reduction,
      Exercise,
      Vesting,
      FinancialEnding,
      Committee,
      Conversion,
      ParityChange,
      Approval,
      Commitment,
      DelegationSunset
    },
    props: { canEditParts: { type: Boolean, default: false, required: false } },
    emits: ['on-updated'],
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      const isLoading = ref(false)
      const route = useRoute()
      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )

      const nominalValues = computed(
        () => store.getters['organizations/nominalValues']
      )

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const nominalValue = ref(0)
      const event = ref<Event | null>(null)
      const formData = ref<any>({})
      const isValidDocauto = computed(
        () =>
          formData.value?.templatesId?.length == 0 ||
          !formData.value?.docAutomationEmailSubject ||
          !formData.value?.docAutomationEmailBody
      )

      const canMakeDocAuto = computed(() =>
        [
          EventType.transfer.toString(),
          EventType.optionExercise.toString(),
          EventType.optionIssuance.toString()
        ].includes(event.value?.type?.toString() ?? '')
      )
      const calculateNominalValue = async (date: string) => {
        const value = await store.dispatch(
          'organizations/COMPUTE_NOMINAL_VALUE',
          { date, setAsGlobal: false }
        )

        if (value?.nominalValue) nominalValue.value = value?.nominalValue
      }

      const getEvent = (eventId = route.params.eventId) => {
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId,
          onSuccess: async (data: Event | null) => {
            event.value = data
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const saveDocAuto = () => {
        let action = 'ADD_DOCAUTO_TO_OPTIONISSUANCE'
        if (event.value?.type?.toString() == EventType.transfer) {
          action = 'ADD_DOCAUTO_TO_TRANSFER'
        }
        if (event.value?.type?.toString() == EventType.optionExercise) {
          action = 'ADD_DOCAUTO_TO_OPTION_EXERCISE'
        }

        store.dispatch(`organizations/${action}`, {
          companyId: currentCompany.value.id ?? route.params.id,
          optionIssuanceId: event.value?.optionIssuance?.id,
          transferId: event.value?.transfer?.id,
          optionExerciseId: event.value?.optionExercise?.id,
          data: formData.value,
          onSuccess: () => {
            formData.value = {}
            getEvent()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('docAutomation.context'),
              message: t(`docAutomation.apiResponse.doc_auto_added`)
            })
          },
          onError: (error: any) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('docAutomation.context'),
              message:
                error?.data?.message ??
                t(`docAutomation.apiResponse.doc_auto_add_error`)
            })
          }
        })
      }

      watch([() => event.value, nominalValues], ([val, vals]) => {
        if (val?.date) calculateNominalValue(val?.date || '')
      })

      onMounted(getEvent)
      return {
        EventType,
        event,
        formData,
        saveDocAuto,
        isValidDocauto,
        nominalValue,
        isLoading,
        canMakeDocAuto
      }
    }
  })
