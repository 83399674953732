
  import { ref, computed, onMounted, watch } from 'vue'
  import { useStore } from 'vuex'
  import DocumentTreeview from '@/components/DocumentTree/index.vue'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { useRoute, useRouter } from 'vue-router'
  import { defineComponent } from 'vue'
  import EventCard from '@/components/Event/EventCard.vue'
  import { Event } from '@/services/api/models'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'
  import { Pagination } from '@/store/modules/Organization/models'
  import EmptyState from '@/components/EmptyState/index.vue'
  export default defineComponent({
    name: 'EventList',
    components: {
      DocumentTreeview,
      // Button,
      UplInput: Input,
      EventCard,
      Loader,
      EmptyState
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const searchQuery = ref('')
      const loading = ref(false)

      const isLoading = computed(
        () => store.getters['organizations/loadingEvents']
      )

      const events = computed<Event[]>(() =>
        store.getters['organizations/events'].filter((item: Event) => item.type)
      )

      const pagination = computed<Pagination>(
        () => store.getters['organizations/eventsPagination']
      )
      const noMore = computed(
        () => pagination.value.currentPage === pagination.value.lastPage
      )

      const selectedCards = ref<string[]>([])

      const getEventCategories = async () => {
        await store.dispatch('toolbox/getEventCategories')
      }

      const load = async () => {
        loading.value = true
        store.commit(
          'organizations/SET_EVENTS_PAGINATION_PAGE',
          pagination.value.currentPage + 1
        )
        await store.dispatch('organizations/LOAD_COMPANY_EVENTS', {
          companyId: route.params.id,
          filter: { orderBy: 'updatedAt' },
          offset: events.value.length
        })
        loading.value = false
      }

      const loadEvents = async (companyId: string) => {
        await store.dispatch('organizations/GET_EVENTS', {
          companyId: companyId,
          isDraft: false,
          startDate: '1970-01-01',
          filter: { orderBy: 'updatedAt' },
          endDate: moment().format('YYYY-MM-DD')
        })
      }

      const currentCompany = computed(
        () => store.getters['organizations/contextCompany']
      )

      const goToCreate = async () => {
        await router.push({
          name: 'createEvent',
          params: { id: currentCompany.value.id }
        })
      }

      const selectCard = (data: { check: boolean; id: string }) => {
        const { check, id } = data
        if (check) {
          selectedCards.value.push(id)
        } else {
          selectedCards.value.splice(
            selectedCards.value.findIndex((item: string) => item === id),
            1
          )
        }
      }

      onMounted(async () => {
        await getEventCategories()
        await loadEvents(route.params.id as string)
      })

      watch(currentCompany, (val) => {
        loadEvents(val.id)
      })

      return {
        searchQuery,
        events,
        loading,
        noMore,
        goToCreate,
        selectCard,
        isLoading,
        load
      }
    }
  })
