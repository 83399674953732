
  import {
    computed,
    ref,
    defineAsyncComponent,
    defineComponent,
    watch,
    onMounted
  } from 'vue'
  import i18n from '@/services/lang'
  import Datepicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'

  const ActionRow = defineAsyncComponent(() => import('./actionRow.vue'))

  export default defineComponent({
    components: { Datepicker },
    props: {
      format: {
        type: String,
        default: i18n.global.t('format')
      },
      value: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: 'date'
      },
      mask: {
        type: String,
        default: i18n.global.t('global.dateMask')
      }
    },
    emits: ['onSelect'],
    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const date = ref(props.value ? props.value?.toString().split('T')[0] : '')
      const actionRow = computed(() => ActionRow)
      const currentLocale = computed(() => store.getters['config/lang'])
      const dateFormat = computed(() => t('format'))
      const handleDate = (modelData: any) => {
        date.value = moment(modelData).set('hour', 13).toISOString()

        emit('onSelect', date.value)
      }
      watch(
        () => props.value,
        (val: string) => {
          date.value = val ? val?.toString().split('T')[0] : ''
        }
      )

      return {
        date,
        dateFormat,
        currentLocale,
        actionRow,
        handleDate
      }
    }
  })
