
  import {
    computed,
    defineComponent,
    onErrorCaptured,
    onMounted,
    reactive,
    ref,
    watch
  } from 'vue'
  import { Tag } from '@up.law/uplaw-ui'
  import Multiselect from '@vueform/multiselect'
  import { MULTISELECTMODE } from '@/types'

  export default defineComponent({
    name: 'UplMultiSelect',
    components: { Multiselect, Tag },
    props: {
      error: { type: String, required: false, default: null },
      options: {
        type: Object as () => Array<any>,
        required: true
      },
      groupOptionsField: { type: String, required: false, default: 'children' },
      groupLabel: { type: String, required: false, default: 'name' },
      label: { type: String, required: false, default: 'name' },
      elementName: {
        type: String,
        required: false,
        default: 'global.categorySelected'
      },
      selectionText: {
        type: String,
        required: false,
        default: 'global.actions.selected'
      },
      classes: { type: String, required: false, default: '' },
      placeholder: { type: String, required: false, default: 'Select' },
      openDirection: { type: String, required: false, default: 'bottom' },
      value: {
        type: [String, Object as () => Array<string>, Number],
        default: null
      },
      filterResults: { type: Boolean, required: false, default: true },
      tagWithInitials: { type: Boolean, required: false, default: true },
      groups: { type: Boolean, required: false, default: false },
      disabled: { type: Boolean, required: false, default: false },
      mode: { type: String, required: false, default: MULTISELECTMODE.SINGLE }
    },
    emits: ['change', 'open', 'search-change'],
    setup(props, { emit }) {
      const root = ref<HTMLDivElement | null>(null)
      props = reactive(props)

      const addActionSlot = computed(() =>
        props.mode === 'top' ? 'afterlist' : 'beforelist'
      )

      const isSearching = ref(false)

      onErrorCaptured((err) => {
        console.log(err.message)
      })

      const rootWidth = computed(() => root.value?.clientWidth)
      const searchStart = ref(false)
      const hasError = computed((): boolean => props.error !== null)
      const selectedValue = ref<string | string[] | number>(props.value)
      const onOptionChanged = () => {
        emit('change', props.mode === MULTISELECTMODE.SINGLE ? '' : [])
      }
      const valueChanged = (value: any) => {
        emit(
          'change',
          value || (props.mode === MULTISELECTMODE.SINGLE ? '' : [])
        )
      }
      const handleSearch = (value: string) => {
        isSearching.value = true
        // if (!value) return
        emit('search-change', value, true)
      }

      const onBlur = () => {
        isSearching.value = false
      }

      watch(props, (newProps) => {
        selectedValue.value = newProps.value
      })

      return {
        hasError,
        selectedValue,
        root,
        isSearching,
        searchStart,
        rootWidth,
        addActionSlot,
        onBlur,
        onOptionChanged,
        valueChanged,
        handleSearch
      }
    }
  })
