
  import Multiselect from '@/components/Select/multiselect.vue'
  import { defineComponent, onMounted, ref, toRefs, watch, computed } from 'vue'
  import { Field } from 'vee-validate'
  import { MULTISELECTMODE } from '@/types'
  export default defineComponent({
    name: 'DropdownGeneric',
    components: { Multiselect, Field },
    inheritAttrs: false,
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      lineData: {
        default: () => ({}),
        type: Object
      },
      value: {
        type: [Array, Number, String, Object as () => any],
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: ''
      },
      group: {
        default: false,
        type: Boolean
      },

      groupBy: {
        type: String,
        required: false,
        default: ''
      },
      mode: {
        type: String,
        default: 'single'
      },
      name: {
        type: String,
        default: 'select-box'
      },
      placeholder: {
        type: String,
        default: ''
      },
      options: {
        default: () => [],
        type: Array
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      index: {
        default: -1,
        type: Number
      },
      tableId: {
        type: String,
        default: null
      }
    },
    emits: ['onUpdated', 'search-change'],
    setup(props, { emit }) {
      const { data } = toRefs(props)

      const formValue = ref<string | string[] | null>(props.value)
      const fieldName = computed(() =>
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      )

      const updateValue = (value: any) => {
        formValue.value = value
        // emit('onUpdated', value, props.index)
        emit(
          'onUpdated',
          {
            ...data.value,
            value
          },
          props.index
        )
      }

      const handleSearch = (e: string) => {
        emit('search-change', e, props.index)
      }

      watch(
        () => props.value,
        (value) => {
          formValue.value = value
        }
      )

      onMounted(() => {
        updateValue(props.value)
      })
      return { formValue, fieldName, updateValue, handleSearch }
    }
  })
