
  import { Button, Input } from '@up.law/uplaw-ui'
  import { defineComponent, ref } from 'vue'
  import { useField, Form, Field } from 'vee-validate'
  import * as yup from 'yup'
  import { useStore } from '@/store'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { AxiosError } from 'axios'
  import { useRouter } from 'vue-router'
  export default defineComponent({
    name: 'AccountAuthentifications',
    components: { Btn: Button, UplInput: Input, Form, Field },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const schema = yup.object({
        password: yup.string().min(5),
        oldPassword: yup.string().min(5),
        passwordConfirmation: yup.string().min(4)
      })
      const password = ref({ new: '', confirm: '' })
      const {
        value: authCode,
        meta: authMeta,
        errorMessage: authCodeError
      } = useField('authCode', yup.string().length(4).required(), {
        validateOnValueUpdate: true
      })
      const updatePassword = (values: any) => {
        store.dispatch(`auth/UPDATE_USER_PASSWORD`, {
          data: values,
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.DEFAULT,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.successPasswordEdition`
              )
            })

            router.replace('/connect')
          },
          onError: (err: AxiosError) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(`auth0.${err.message}`)
            })
          }
        })
      }
      return {
        schema,
        authCode,
        authCodeError,
        authMeta,
        password,
        updatePassword
      }
    }
  })
