<template>
  <div
    :class="`relative flex part-line ${
      isSelected ? 'bg-active font-bold' : ''
    } ${isEditing || partId == null ? 'bg-white' : ''} ${
      index == 0 ? '' : ''
    } hover:bg-white grid grid-cols-${grids}`"
    :key="index"
  >
    <div
      :class="`relative p-3 flex items-center ${col.class}  ${
        index != 0 ? 'border-t' : ''
      } ${
        i != (columnsData?.length ?? 0) - 1 ? 'border-r' : ''
      } border-secondary6`"
      v-for="(col, i) in columnsData"
      :key="i"
    >
      <p
        v-if="!isEditing && line.partId && line[col.id].component == 'p'"
        style="white-space: nowrap"
        v-bind="line[col.id]?.componentProps"
      >
        {{ line[col.id]?.componentProps.value }}
      </p>
      <div v-else-if="line[col.id].component == 'automationActions'">
        <div class="">
          <Button
            v-bind="line[col.id]?.componentProps"
            variant="secondary"
            size="medium"
            class="
              p-1
              py-1
              rounded-md
              action-btn
              bg-primary3
              text-white text-lg
            "
            :label="$t('docAutomation.fields.download')"
            @click="() => onActionClick(col.id, 'download')"
          >
          </Button>
        </div>
      </div>
      <div v-else-if="line[col.id].component == 'actions'">
        <div class="h-full flex items-center px-1">
          <div
            v-if="isSubmittingLine"
            class="mx-2 w-10 h-10 loader flex justify-center items-center"
          >
            <svg viewBox="25 25 50 50">
              <circle r="20" cy="50" cx="50"></circle>
            </svg>
          </div>
          <div v-else class="flex">
            <div v-if="canEdit" class="mr-2">
              <el-tooltip
                v-if="!isEditing && line.partId"
                class="box-item"
                effect="dark"
                :content="$t('events.tooltips.lineEdit')"
                placement="top"
              >
                <Button
                  variant="custom"
                  size="medium"
                  class="p-1 rounded-xl bg-secondary text-white"
                  :label="$t('global.actions.editLine')"
                  @click="start()"
                >
                </Button>
              </el-tooltip>
            </div>
            <div
              v-if="canEdit && (isEditing || line.partId == null)"
              class="mr-2"
            >
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="$t('events.tooltips.cancelLine')"
                placement="top"
              >
                <Button
                  variant="custom"
                  size="medium"
                  class="p-1 rounded-md action-btn bg-error text-white"
                  :label="$t('global.actions.cancelLineEdition')"
                  @click="cancelLine()"
                >
                </Button>
              </el-tooltip>
            </div>
            <div
              v-if="canEdit && (isEditing || line.partId == null)"
              class="mr-2"
            >
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="$t('events.tooltips.validateLine')"
                placement="top"
              >
                <Button
                  variant="custom"
                  size="medium"
                  class="p-1 rounded-md action-btn bg-success text-white"
                  :label="$t('global.actions.validateLine')"
                  @click="edit()"
                >
                </Button>
              </el-tooltip>
            </div>
            <div v-if="canDelete" class="flex justify-end items-center">
              <el-popconfirm
                :confirm-button-text="$t('confirm.yes')"
                :cancel-button-text="$t('confirm.no')"
                :title="$t('global.confirmDelete')"
                @confirm="remove()"
              >
                <template #reference>
                  <Button
                    variant="custom"
                    size="medium"
                    class="
                      rounded-md
                      action-btn
                      text-error
                      hover:bg-error hover:text-white
                    "
                  >
                    <i class="bi bi-trash text-md"></i>
                  </Button>
                </template>
              </el-popconfirm>
            </div>
          </div>
        </div>
      </div>
      <component
        :is="
          (isEditing && line[col.id]?.editComponent) ||
          (line.partId == null && line[col.id]?.editComponent)
            ? line[col.id]?.editComponent
            : line[col.id]?.component
        "
        v-else
        v-bind="
          (isEditing && line[col.id]?.editComponent) ||
          (line.partId == null && line[col.id]?.editComponent)
            ? { ...line[col.id]?.editComponentProps, index: index }
            : { ...line[col.id]?.componentProps, index: index }
        "
        @onUpdated="line[col.id]?.editComponentProps?.onUpdated"
      ></component>
    </div>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, ref } from 'vue'
  import { EditableColumn, EditableLine } from './index.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import NumberCmp from '@/components/Number/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import { ElPopconfirm, ElTooltip } from 'element-plus'
  import InputGeneric from '@/components/Forms/Event/Generic/InputGeneric.vue'
  import DateGeneric from '@/components/Forms/Event/Generic/DateGeneric.vue'
  import DropdownVesting from '@/components/Forms/DropDowns/DropdownVesting.vue'
  import ContactGeneric from '@/components/Forms/Event/Generic/ContactGeneric.vue'
  import Loader from '@/components/Loader/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import DropdownGeneric from '@/components/Forms/DropDowns/DropdownGeneric.vue'
  import ClickableContactBadge from '@/components/ContactBadge/clickable.vue'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import DropdownOptions from '@/components/Forms/DropDowns/DropdownOptions.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      ContactBadge,
      number: NumberCmp,
      ContactGeneric,
      DateGeneric,
      ClickableContactBadge,
      DropdownGeneric,
      DropdownOptions,
      InputGeneric,
      Button,
      Tag,
      DropdownStocks,
      Loader,
      ElTooltip,
      DropdownVesting,
      ElPopconfirm
    },
    props: {
      line: {
        type: Object as () => EditableLine,
        required: true
      },
      columns: {
        type: Object as () => EditableColumn[]
      },
      withActions: { type: Boolean, required: true, default: true },
      index: { type: Number, required: true },
      partId: { type: String, required: true },
      grids: { type: Number, required: true },
      isEditing: { type: Boolean, required: true },
      canDelete: { type: Boolean, required: true, default: false },
      canEdit: { type: Boolean, required: true, default: false },
      isSelected: { type: Boolean, required: false, default: false }
    },
    emits: [
      'removeLine',
      'validateEdition',
      'startEdition',
      'cancelEdition',
      'saveNewLine',
      'removeNewLine',
      'docAutoActionsClick'
    ],
    setup(props, { emit }) {
      const data = computed<any>(() => props.line)

      const isSubmittingLine = ref(false)
      const columnsData = computed(() =>
        (props.columns ?? []).map((x) => ({
          name: x.name,
          w: x.width,
          class: x.class,
          id: x.id
        }))
      )

      const onActionClick = (colId: string, eventName: string) => {
        emit(
          'docAutoActionsClick',
          props.line[colId]?.componentProps.deleveryId,
          props.line.partId,
          eventName
        )
      }

      const cancelLine = () => {
        if (props.partId) emit('cancelEdition', props.partId, props.index)
        else emit('removeNewLine', props.line.newIndex)
      }
      const remove = () => {
        isSubmittingLine.value = true
        emit('removeLine', props.partId, props.index, afterRemove)
      }
      const afterRemove = () => {
        isSubmittingLine.value = false
      }
      const onResponse = (value: boolean) => {
        isSubmittingLine.value = !value
      }
      const edit = () => {
        isSubmittingLine.value = true
        if (props.partId)
          emit('validateEdition', props.partId, props.index, onResponse)
        else emit('saveNewLine', props.line.newIndex, props.index, onResponse)
      }
      const start = () => {
        emit('startEdition', props.partId, props.index)
      }

      return {
        columnsData,
        data,
        isSubmittingLine,
        cancelLine,
        start,
        onActionClick,
        remove,
        edit
      }
    }
  })
</script>
<style lang="scss">
  .part-line {
    .loader {
      svg {
        width: 2.25em;
        transform-origin: center;
        animation: rotate4 2s linear infinite;
      }

      circle {
        fill: none;
        stroke: hsl(214, 97%, 59%);
        stroke-width: 2;
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
        animation: dash4 1.5s ease-in-out infinite;
      }

      @keyframes rotate4 {
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes dash4 {
        0% {
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
        }

        50% {
          stroke-dasharray: 90, 200;
          stroke-dashoffset: -35px;
        }

        100% {
          stroke-dashoffset: -125px;
        }
      }
    }
    .actions {
      transition: 0.3s all;
      // display: none;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
        -23px 0 20px -23px rgba(0, 0, 0, 0.8),
        23px 0 20px -23px rgba(0, 0, 0, 0.8), inset 0 0 40px rgba(0, 0, 0, 0.1);
    }
    &:hover {
      .actions {
        display: block;
      }
    }
  }
</style>
