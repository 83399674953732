
  import ContactBadge from './index.vue'
  import { defineComponent, computed, onMounted } from 'vue'
  import { Company, ContactCardProps } from '@/services/api/models'

  export default defineComponent({
    components: { ContactBadge },
    props: {
      usePicture: {
        type: Boolean,
        default: true
      },
      contact: {
        type: Object as () => ContactCardProps,
        required: false,
        default: () => ({})
      },
      company: {
        type: Object as () => Company,
        required: false,
        default: () => ({})
      },
      details: {
        type: Boolean,
        required: false,
        default: false
      },
      initialsOnly: {
        type: Boolean,
        required: false,
        default: false
      },
      type: {
        type: String,
        required: false,
        default: 'primary'
      },
      mode: {
        type: String,
        required: false,
        default: 'contact'
      },
      size: {
        type: String,
        required: false,
        default: '45' //size in em
      },
      blockType: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup() {
      return {}
    }
  })
