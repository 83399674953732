<template>
  <div class="grid-cols-6 grid gap-4 w-full">
    <div :class="`col-span-${6} h-full`">
      <div class="flex h-full">
        <div :class="`w-${displayPriceSelector ? '3/5' : 'full'} h-full`">
          <label
            v-if="hasLabel"
            class="capitalize text-xs font-semibold text-secondary8 text-left"
            >{{ data.label }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="option"
            :name="fieldName"
            :placeholder="data.hint"
          >
            <Multiselect
              v-bind="field"
              :name="fieldName"
              v-model="option"
              :value="option"
              class="w-full"
              :show-labels="true"
              :mode="mode"
              :filterResults="filterResults"
              :options="selectableOptions"
              elementName="stockBook.options"
              selectionText="global.actions.selected2"
              :placeholder="
                $t(
                  `events.elements.${mode === 'single' ? 'option' : 'options'}`
                )
              "
              :groups="false"
              label="alias"
              :disabled="disabled"
              :open-direction="openDirection"
              @search-change="handleSearch"
              @change="updateValue"
            >
              <template #[addActionSlot]>
                <div v-if="canAddNew" class="">
                  <div
                    class="
                      flex
                      w-full
                      h-12
                      justify-start
                      items-center
                      text-md
                      px-2
                      text-secondary9
                      hover:bg-primary1
                    "
                  >
                    <i class="bi bi-plus text-xl text-secondary9"></i>
                    <h4
                      class="
                        w-full
                        h-full
                        text-secondary9
                        flex
                        justify-start
                        items-center
                        text-md
                      "
                      @click="openModal"
                    >
                      {{ $t('events.elements.newStock') }}
                    </h4>
                  </div>
                </div>
              </template>
              <template #option="{ option: opt }">
                <span v-if="opt?.id != 0" class="w-full text-sm"
                  >{{ opt.name }}
                </span>
              </template>

              <template #noResults>
                <span>{{ $t('global.placeholder.noResult') }}</span>
              </template>
            </Multiselect>
          </Field>
        </div>

        <div v-show="displayPriceSelector" class="w-2/5 ml-1">
          <GenericExercisePrice
            :has-label="hasLabel"
            :data="{
              name: 'exercisePrice',
              label: $t('global.placeholder.exercisePrice')
            }"
            :disabled="(selectedOption?.exercisePrices || []).length == 1"
            :value="option?.toString()?.split('@_')[1]"
            :options="selectedOption?.exercisePrices || []"
            @on-updated="priceChanged"
          />
        </div>
      </div>
      <slot :name="data.name" :value="true" />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    CompanyModel,
    ContactAvailableData,
    OptionStockAlias
  } from '@/services/api/models'
  import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    ref,
    toRefs,
    watch
  } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { MAINACTIONS, MULTISELECTMODE, STOCKTYPESALIAS } from '@/types'
  import { formatNumber } from '@/utils'
  import GenericExercisePrice from '../Event/Generic/GenericExercisePrice.vue'
  import * as yup from 'yup'
  export default defineComponent({
    name: 'DropdownOptions',
    components: { Multiselect, Field, GenericExercisePrice },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      lineData: {
        default: () => ({}),
        type: Object
      },
      filterResults: { type: Boolean, default: false },
      classItem: {
        type: String,
        default: 'w-full'
      },
      displayAll: {
        default: false,
        type: Boolean
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        required: true,
        type: [String, Array]
      },
      mode: {
        default: 'single',
        type: String
      },
      openDirection: {
        type: String,
        default: 'top'
      },
      callback: {
        type: Function,
        default: () => ({})
      },
      canAddNew: { type: Boolean, default: true },
      categoryId: { type: String, default: null },
      labelName: { type: String, default: 'name' },
      validationState: { type: String, default: '' },
      optionPerPrice: { type: Boolean, default: true },
      dataFromExtra: { type: Boolean, default: false }, //if data is get from contacTExtraData or not
      optionData: {
        type: Object,
        default: () => ({
          value: [],
          index: -1
        })
      },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: -1
      }
    },
    emits: ['onUpdated', 'onOptionUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const route = useRoute()
      const { data } = toRefs(props)
      const searchText = ref('')
      const paramsId = computed(() =>
        props.optionData.index === props.index
          ? props.optionData.value
          : props.lineData.contactId
      )
      const schema = yup.object().shape({
        uuid: yup.string().uuid()
      })
      const tempOptionId = ref<any>()
      const defaultOptions = ref<OptionStockAlias[]>([])
      const definedOptions = ref<OptionStockAlias[]>([])

      const ids = computed<any>(() =>
        props.mode === MULTISELECTMODE.SINGLE
          ? props.value
            ? [props.value]
            : []
          : props.value || []
      )
      const defaultStocks = ref<OptionStockAlias[]>([])
      const displayPriceSelector = computed<boolean>(
        () =>
          props.optionPerPrice &&
          option.value &&
          isValid.value &&
          option.value?.toString().split('@_').length == 1
      )
      const selectedOption = computed(() =>
        aliases.value.find(
          (x) => x.id === option.value?.toString().split('@_')[0]
        )
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const fieldName = computed(() =>
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      )
      const extraContactData = computed<ContactAvailableData>(
        () => store.getters[`organizations/contactExtraData`]
      )
      const addActionSlot = computed(() =>
        props.mode === 'top' ? 'afterlist' : 'beforelist'
      )

      const aliases = computed<OptionStockAlias[]>(() =>
        [
          ...store.getters[`organizations/optionStockAliases`],
          ...defaultOptions.value
        ].reduce((acc, stock) => {
          if (!acc.map((x: OptionStockAlias) => x.id).includes(stock.id))
            acc.push(stock)
          return acc
        }, [])
      )

      const listedOptions = computed(() =>
        (!props.displayAll && props.dataFromExtra
          ? extraContactData.value[paramsId.value]?.options ||
            definedOptions.value ||
            []
          : aliases.value
        ).reduce((acc: OptionStockAlias[], opt) => {
          delete opt.group
          if (props.optionPerPrice) {
            opt.exercisePrices.forEach((price: number) => {
              const id = `${opt.id}@_${formatNumber(price)}@_${
                opt?.exerciseId || ''
              }`
              // if (!id.includes(tempOptionId.value))
              acc.push({
                ...opt,
                id,
                name: `${opt.alias} (${formatNumber(price)}${currency.value})`
              })
            })
          } else acc.push({ ...opt, name: opt.alias })
          if (
            props.optionPerPrice &&
            tempOptionId.value?.split('@_').length > 0 &&
            opt.id === tempOptionId.value
          ) {
            acc.push({ ...opt, name: opt.alias })
          }
          return acc
        }, [])
      )

      const selectableOptions = computed<any[]>(() =>
        (props.categoryId
          ? [...listedOptions.value, ...defaultStocks.value].filter(
              (x) => x.optionCategory?.id === props.categoryId
            )
          : [...listedOptions.value, ...defaultStocks.value]
        ).map((x) => {
          const v = { ...x }

          delete v.group
          return v
        })
      )

      const currency = computed(() => store.getters['organizations/currency'])

      const option = ref<any>(props.value)
      const isValid = computed(() => schema.isValidSync({ uuid: option.value }))

      const openModal = () => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.STOCK
        })
      }

      const updateValue = (selected: any, boolean = false) => {
        option.value = selected
        let params = {}
        if (Array.isArray(selected)) {
          params = { ...data.value, index: props.index, value: selected }
        } else {
          const [stockId] = selected?.split('@_')
          params = {
            ...data.value,
            value: selected,
            index: props.index,
            parity: aliases.value.find((x) => x.id === stockId)?.parity || 0
          }
        }

        emit('onUpdated', { ...params })
        emit('onOptionUpdated', selected)
      }

      const handleOptionDisplay = () => {
        const getIds = (ids.value || [])?.map((element: string) => {
          if (!selectableOptions.value.map((c) => c.id).includes(element))
            return element
        })
        Promise.all(getIds.map((cId: string) => getOption(cId)))
      }
      const handleSearch = (search: string) => {
        if (props.displayAll || !props.dataFromExtra) getOptions(search)
      }

      const getOptions = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_OPTION_STOCK_ALIAS', {
          companyId: currentCompany.value?.id || route.params.id,
          filter
        })
      }

      const getSelectedOption = async (id: string) => {
        if (!id || !selectableOptions.value.map((c) => c.id).includes(id))
          return

        const [stock, price, exerciseId] = id?.split('@_')
        store.dispatch('organizations/GET_SINGLE_STOCK_ALIAS', {
          companyId: currentCompany.value?.id || route.params.id,
          stockId: stock,
          type: STOCKTYPESALIAS.OPTION,
          onSuccess: (data: OptionStockAlias) => {
            let stockId = `${data.id}`
            if (price) stockId += `@_${price}`
            if (exerciseId) stockId += `@_${exerciseId}`
            if (!selectableOptions.value.map((c) => c.id).includes(stockId)) {
              definedOptions.value = [{ ...data, id: stockId }]
            }
          },
          onError: (err: any) => {
            console.log('err0=>', err)
          }
        })
      }

      const getOption = async (id: string, prepend = '') => {
        if (!id) return

        const [stock, price, exerciseId] = id?.split('@_')
        store.dispatch('organizations/GET_SINGLE_STOCK_ALIAS', {
          companyId: currentCompany.value?.id || route.params.id,
          stockId: stock,
          type: STOCKTYPESALIAS.OPTION,
          onSuccess: (data: OptionStockAlias) => {
            let stockId = `${data.id}`
            if (price) stockId += `@_${price}${prepend}`
            if (exerciseId) stockId += `@_${exerciseId}`
            if (
              !defaultStocks.value.map((c) => c.id).includes(data?.id) &&
              !listedOptions.value.map((c) => c.id).includes(data?.id)
            )
              defaultStocks.value.push({
                ...data,
                id: stockId,
                name: data?.alias
              })
            if (tempOptionId.value === stockId) {
              option.value = stockId

              // updateValue(stockId, true)
            }
          },
          onError: (err: any) => {
            console.log('err0=>', err)
          }
        })
      }

      const priceChanged = (fieldName: string, value: number) => {
        let price = '0'
        if (!Array.isArray(option.value)) {
          const [, optPrice] = (option.value || '')?.split('@_')
          price = optPrice
        }

        const currentPrice = value || price
        const values = { ...data.value }
        values.component = 'dropdown-exercisePrice'
        values.name = fieldName
        emit('onUpdated', {
          ...values,
          value: currentPrice
        })
      }

      watch(props, (prop) => {
        if (prop.index === prop.optionData.index) {
          if (
            !selectableOptions.value
              .map((x) => x.id)
              .includes(prop.value?.toString())
          ) {
            handleOptionDisplay()
          }
        }

        tempOptionId.value = prop.value?.toString()
      })

      watch(
        () => props.optionData,
        (value) => {
          if (value.index == props.index)
            option.value = props.mode === MULTISELECTMODE.SINGLE ? '' : []
        }
      )

      watch(
        () => props.value,
        (value) => {
          option.value = value
        }
      )

      onBeforeUnmount(() => {
        option.value = ''
      })

      onMounted(() => {
        getOptions()

        tempOptionId.value = props.value

        if (props.value) updateValue(props.value)

        if (props.dataFromExtra && props.value)
          getSelectedOption(props.value.toString())
        if (
          props.index === -1 &&
          props.mode === MULTISELECTMODE.SINGLE &&
          props.value
        ) {
          if (
            !selectableOptions.value.map((x) => x.id).includes(props.value) &&
            !props.dataFromExtra
          ) {
            getOption(props.value.toString(), '@_')
          }
        }
      })
      return {
        displayPriceSelector,
        selectableOptions,
        option,
        fieldName,
        addActionSlot,
        searchText,
        selectedOption,
        updateValue,
        handleSearch,
        priceChanged,
        openModal
      }
    }
  })
</script>
