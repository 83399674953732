<template>
  <div class="w-full flex flex-col">
    <div class="my-4 text-warning text-left">
      <p v-if="isDocusignExpired">
        {{ $t('docAutomation.form.link_expired') }}
      </p>
      <p v-else-if="isDocusignExpireSoon">
        {{ $t('docAutomation.form.link_expire_soon') }}
      </p>
    </div>
    <div class="w-1/2">
      <label
        class="capitalize text-xs font-semibold text-secondary8 text-left"
        for="templates"
      >
        {{ $t('docAutomation.placeholder.templates') }}
      </label>

      <Field v-slot="{ field }" v-model="selectedTemplates" name="templates">
        <Multiselect
          v-model="selectedTemplates"
          v-bind="field"
          :value="selectedTemplates"
          name="templates"
          class="w-full min-h-full"
          :show-labels="true"
          mode="tags"
          group-label="name"
          :options="templates"
          elementName="doc.stocks"
          selectionText="global.actions.selected1"
          group-options-field="children"
          :placeholder="$t(`docAutomation.placeholder.templates`)"
          :filterResults="false"
          :groups="false"
          label="name"
          open-direction="bottom"
          @search-change="handleSearch"
          @change="updateValue"
      /></Field>
    </div>

    <div class="mt-3 w-1/2">
      <label
        class="capitalize text-xs font-semibold text-secondary8 text-left"
        for="subject"
      >
        {{ $t('docAutomation.placeholder.mailMotive') }}
      </label>
      <Field
        v-slot="{ field }"
        v-model="mailMotive"
        type="text"
        name="mailMotive"
      >
        <Input
          v-bind="field"
          :disabled="false"
          v-model="mailMotive"
          :placeholder="$t('docAutomation.placeholder.mailMotive')"
        >
        </Input>
      </Field>
    </div>

    <div class="w-1/2 flex flex-col">
      <label
        class="capitalize text-xs font-semibold text-secondary8 text-left"
        for="subject"
      >
        {{ $t('docAutomation.placeholder.mailBody') }}
      </label>
      <Field v-slot="{ field }" v-model="mailBody" type="text" name="mailBody">
        <textarea
          v-bind="field"
          v-model="mailBody"
          :placeholder="$t('docAutomation.placeholder.mailBody')"
          class="
            resize-none
            focus:border-primary3
            rounded
            border-secondary1 border
            w-full
            p-3
          "
        ></textarea>
      </Field>
    </div>

    <!-- <div class="mt-4 text-xl">
      <Button
        variant="custom"
        class="danger md bg-primary3 text-white"
        icon="bi-trash xs"
        :label="$t('events.labels.testAllDocuments')"
        @click="testAllDocuments()"
      />
    </div>

    <div class="flex justify-start items-center w-full mt-4">
      <div class="flex justify-start items-start">
        <Field
          v-model="sendDraftEnvelopesToDocusign"
          type="checkbox"
          :value="true"
          name="sendDraftEnvelopesToDocusign"
        >
          <Checkbox
            name="sendDraftEnvelopesToDocusign"
            :checked="sendDraftEnvelopesToDocusign"
            @onChange="handleDraft"
          />
        </Field>
      </div>

      <h4 class="text-sm text-secondary9 ml-2 mb-0">
        {{ $t('events.fieldsLabels.sendDraftOnDocusign') }}
      </h4>
    </div> -->

    <div class="mt-4 flex">
      <div class="w-1/2 mr-4">
        <label
          class="capitalize text-xs font-semibold text-secondary8 text-left"
          for="subject"
        >
          {{ $t('docAutomation.placeholder.expireAfter') }}
        </label>
        <Field
          v-slot="{ field }"
          v-model="expireAfter"
          type="text"
          name="expireAfter"
        >
          <Input
            v-bind="field"
            :disabled="false"
            v-model="expireAfter"
            :placeholder="$t('docAutomation.placeholder.expireAfter')"
          >
          </Input>
        </Field>
      </div>

      <div class="w-1/2 mr-4">
        <label
          class="capitalize text-xs font-semibold text-secondary8 text-left"
          for="subject"
        >
          {{ $t('docAutomation.placeholder.expireWarn') }}
        </label>
        <Field
          v-slot="{ field }"
          v-model="expireWarn"
          type="text"
          name="expireWarn"
        >
          <Input
            v-bind="field"
            :disabled="false"
            v-model="expireWarn"
            :placeholder="$t('docAutomation.placeholder.expireWarn')"
          >
          </Input>
        </Field>
      </div>

      <div class="w-1/2 mr-4">
        <label
          class="capitalize text-xs font-semibold text-secondary8 text-left"
          for="subject"
        >
          {{ $t('docAutomation.placeholder.reminderDelay') }}
        </label>
        <Field
          v-slot="{ field }"
          v-model="reminderDelay"
          type="text"
          name="reminderDelay"
        >
          <Input
            v-bind="field"
            :disabled="false"
            v-model="reminderDelay"
            :placeholder="$t('docAutomation.placeholder.reminderDelay')"
          >
          </Input>
        </Field>
      </div>
      <div class="w-1/2">
        <label
          class="capitalize text-xs font-semibold text-secondary8 text-left"
          for="subject"
        >
          {{ $t('docAutomation.placeholder.reminderFrequency') }}
        </label>
        <Field
          v-slot="{ field }"
          v-model="reminderFrequency"
          type="text"
          name="reminderFrequency"
        >
          <Input
            v-bind="field"
            :disabled="false"
            v-model="reminderFrequency"
            :placeholder="$t('docAutomation.placeholder.reminderFrequency')"
          >
          </Input>
        </Field>
      </div>
    </div>

    <div class="flex justify-start items-center w-full mb-4">
      <div class="flex justify-start items-start">
        <Field
          v-model="reminderEnabled"
          type="checkbox"
          :value="true"
          name="reminderEnabled"
        >
          <Checkbox
            name="reminderEnabled"
            :checked="reminderEnabled"
            @onChange="handleReminderEnable"
          />
        </Field>
      </div>
      <label class="text-md text-secondary9 ml-2 mb-0">{{
        $t('docAutomation.placeholder.reminderEnabled')
      }}</label>
    </div>
  </div>
</template>

<script lang="ts">
  import { DocAutoTemplate } from '@/store/modules/Organization/docAutoModel'
  import { EventType } from '@/types/event'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import Checkbox from '@/components/Checkbox/index.vue'
  import moment from 'moment'
  import { IntegrationType } from '@/types/config'
  import { IntegratedService } from '@/services/api/models'

  export default defineComponent({
    components: {
      Multiselect,
      Field,
      Checkbox,
      Input
      // Button, Checkbox
    },
    props: {
      eventType: { type: String as () => EventType }
    },
    emits: ['updateData'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const templates = ref<any[]>()
      const docuSign = ref<IntegratedService | null>()

      const mailMotive = ref('')
      const mailBody = ref('')
      const expireAfter = ref('30')
      const expireWarn = ref('20')
      const reminderDelay = ref('3')
      const reminderFrequency = ref('5')
      const selectedTemplates = ref([])
      const sendDraftEnvelopesToDocusign = ref(true)
      const reminderEnabled = ref(true)
      const updateValue = (value: any) => {
        emit('updateData', 'templatesId', value)
      }
      const company = computed(
        () => store.getters['organizations/contextCompany']
      )

      const isDocusignExpired = computed(() =>
        moment().isSameOrAfter(moment(docuSign.value?.expirationTimestamp))
      )

      const isDocusignExpireSoon = computed(() =>
        moment()
          .add(3, 'hours')
          .isAfter(moment(docuSign.value?.expirationTimestamp))
      )
      const handleSearch = () => {
        //
      }
      const getTemplates = () => {
        store.dispatch('organizations/GET_TEMPLATES', {
          companyId: route.params.id,
          filter: { eventTypes: [props.eventType] },
          onSuccess: (resp: any) => {
            const temps: DocAutoTemplate[] = resp.data

            const formatted = (temps ?? []).map((e: any) => ({
              ...e,
              name: e.label
            }))

            templates.value = [...formatted]
          },
          onError: () => {
            console.log()
          }
        })
      }

      const handleDraft = (value: any) => {
        emit('updateData', 'sendDraftEnvelopesToDocusign', value)
      }

      const handleReminderEnable = (value: any) => {
        reminderEnabled.value = value
        emit('updateData', 'reminderEnabled', reminderEnabled.value)
      }

      const updateExpireAfter = (value: any) => {
        expireAfter.value = value
        emit('updateData', 'expireAfter', expireAfter.value)
      }

      const updateExpireWarn = (value: any) => {
        expireWarn.value = value
        emit('updateData', 'expireWarn', expireWarn.value)
      }

      const updateReminderDelay = (value: any) => {
        reminderDelay.value = value
        emit('updateData', 'reminderDelay', reminderDelay.value)
      }

      const updateReminderFrequecy = (value: any) => {
        reminderFrequency.value = value
        emit('updateData', 'reminderFrequency', reminderFrequency.value)
      }

      const getServices = () => {
        if (!route.params?.id && !company.value?.id) return
        store.dispatch('organizations/GET_INTEGRATED_SERVICES', {
          companyId: route.params?.id ?? company.value?.id,
          onSuccess: (response: IntegratedService[]) => {
            docuSign.value = response.find(
              (x) => x.integrationType == IntegrationType.docusign
            )
          },
          onError: () => {
            ///
          }
        })
      }

      watch(
        () => props.eventType,
        () => {
          getTemplates()
          selectedTemplates.value = []
        }
      )

      watch(
        () => mailMotive.value,
        (value) => {
          emit('updateData', 'docAutomationEmailSubject', value)
        }
      )

      watch(
        () => mailBody.value,
        (value) => {
          emit('updateData', 'docAutomationEmailBody', value)
        }
      )

      watch(
        () => expireAfter.value,
        (value) => {
          updateExpireAfter(value)
        }
      )

      watch(
        () => expireWarn.value,
        (value) => {
          updateExpireWarn(value)
        }
      )

      watch(
        () => reminderDelay.value,
        (value) => {
          updateReminderDelay(value)
        }
      )

      watch(
        () => reminderFrequency.value,
        (value) => {
          updateReminderFrequecy(value)
        }
      )

      onMounted(() => {
        getTemplates()
        getServices()
        emit('updateData', 'sendDraftEnvelopesToDocusign', false)
        emit('updateData', 'reminderEnabled', reminderEnabled.value)
        emit('updateData', 'expireAfter', expireAfter.value)
        emit('updateData', 'expireWarn', expireWarn.value)
        emit('updateData', 'reminderDelay', reminderDelay.value)
        emit('updateData', 'reminderFrequency', reminderFrequency.value)
      })

      return {
        templates,
        mailMotive,
        expireWarn,
        isDocusignExpired,
        mailBody,
        reminderEnabled,
        expireAfter,
        reminderDelay,
        reminderFrequency,
        handleDraft,
        handleReminderEnable,
        sendDraftEnvelopesToDocusign,
        selectedTemplates,
        handleSearch,
        isDocusignExpireSoon,
        getServices,
        updateExpireAfter,
        updateReminderFrequecy,
        updateReminderDelay,
        updateExpireWarn,
        updateValue
      }
    }
  })
</script>
