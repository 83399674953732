
  import { Form, Field } from 'vee-validate'
  import { ElPopconfirm, ElTooltip } from 'element-plus'
  import TableGeneric from '@/components/Forms/Event/Generic/TableGeneric.vue'
  import InputGeneric from '@/components/Forms/Event/Generic/InputGeneric.vue'
  import DateGeneric from '@/components/Forms/Event/Generic/DateGeneric.vue'
  import BoolGeneric from '@/components/Forms/Event/Generic/BoolGeneric.vue'
  import SectionGeneric from '@/components/Forms/Event/Generic/SectionGeneric.vue'
  import CheckboxGeneric from '@/components/Forms/Event/Generic/CheckboxGeneric.vue'
  import ContactGeneric from '@/components/Forms/Event/Generic/ContactGeneric.vue'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import DropdownGeneric from '@/components/Forms/Event/Generic/DropdownGeneric.vue'
  import DropdownVesting from '@/components/Forms/DropDowns/DropdownVesting.vue'
  import DropdownFunctions from '@/components/Forms/DropDowns/DropdownFunctions.vue'
  import DropdownCommittee from '@/components/Forms/DropDowns/DropdownCommittee.vue'
  import EventFormFooter from '@/components/Forms/Event/EventFormFooter.vue'
  import DurationGeneric from '@/components/Forms/Event/Generic/DurationGeneric.vue'
  import StockGeneric from '@/components/Forms/Event/Generic/StockGeneric.vue'
  import DropdownCeilings from '@/components/Forms/DropDowns/DropdownCeilings.vue'
  import DropdownDelegations from '@/components/Forms/DropDowns/DropdownDelegations.vue'
  import DropdownActions from '@/components/Forms/DropDowns/DropdownActions.vue'
  import DropdownComposition from '@/components/Forms/DropDowns/DropdownComposition.vue'
  import DropdownAppointments from '@/components/Forms/DropDowns/DropdownAppointments.vue'
  import DropdownOptions from '@/components/Forms/DropDowns/DropdownOptions.vue'
  import DropdownProfitSharingPlans from '@/components/Forms/DropDowns/DropdownProfitSharingPlans.vue'
  import DropdownFundRaisingRounds from '@/components/Forms/DropDowns/DropdownFundRaisingRounds.vue'
  import DropdownStockCategories from '@/components/Forms/DropDowns/DropdownStockCategories.vue'
  import StockQuantity from '@/components/Forms/Event/Generic/StockQuantity.vue'
  import NestedComponent from './autoComponent.vue'
  import { Button, Input } from '@up.law/uplaw-ui'
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    toRefs,
    watch,
    onBeforeUnmount
  } from 'vue'
  import * as yup from 'yup'
  import { createYupSchema } from '@/utils'
  import {
    generateValidationsSingle,
    generateFormDefaultValue
  } from '@/utils/form'
  import catMocks from '@/components/Forms/Event/Generic/EventCategories'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import {
    formDataManipulation,
    tableFormManipulation
  } from '@/components/Forms/Event/formsComposable'
  import {
    Document,
    Delegation,
    Event,
    EventSubmitProps,
    Contact,
    CommitmentPart,
    ExerciseCalendar
  } from '@/services/api/models'
  import EmptyState from '@/components/EmptyState/index.vue'
  import {
    EventFormDefinition,
    EventFormPrimitive,
    EventType,
    OptionSunsetType,
    FieldWithChoice
  } from '@/types/event'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { formatNumber } from '@/utils/global'
  import Row from 'element-plus/es/components/row/src/row'
  import { OptionSunsetMode } from '@/types'
  import DocAutoApplyForm from '../docAutoApplyForm.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  export default defineComponent({
    name: 'FormWrapper',
    components: {
      Form,
      Field,
      UplInput: Input,
      ElPopconfirm,
      InputGeneric,
      CheckboxGeneric,
      DateGeneric,
      SectionGeneric,
      BoolGeneric,
      StockGeneric,
      TableGeneric,
      DropdownContacts,
      DropdownStocks,
      ContactGeneric,
      DropdownGeneric,
      EventFormFooter,
      DurationGeneric,
      DropdownVesting,
      DropdownCeilings,
      DropdownFunctions,
      DropdownCommittee,
      DropdownDelegations,
      StockQuantity,
      DropdownOptions,
      DropdownAppointments,
      DropdownActions,
      DropdownComposition,
      DropdownProfitSharingPlans,
      DropdownFundRaisingRounds,
      DropdownStockCategories,
      Button,
      NestedComponent,
      EmptyState,
      ElTooltip,
      Checkbox,
      DocAutoApplyForm
    },
    props: {
      event: { type: Object as () => Event, default: null },
      type: {
        type: String,
        default: 'doc',
        required: true
      },
      selectedCat: {
        type: String,
        default: ''
      },
      isDraft: {
        type: Boolean,
        default: false
      }
    },
    emits: ['update-category', 'loaded'],
    setup(props, { emit }) {
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )
      const { selectedCat } = toRefs(props)
      const rowsToAdd = ref('0')
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const isSubmitting = ref(false)
      const canDisplayForm = computed(() =>
        isEditing.value ? !!formData.value['id'] : !isEditing.value
      )
      const editionEventId = computed<string>(() => formData.value['id'])
      const noNominalValue = ref(false)
      const nominalValue = computed(
        () => store.getters['organizations/nominalValue']
      )
      const contacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const partsContact = ref<Contact[]>([])

      const currentEvent = computed<Event | null>(() => props.event)

      const canMakeDocAuto = computed(() =>
        [
          EventType.transfer.toString(),
          // EventType.optionExercise.toString(),
          EventType.optionIssuance.toString()
        ].includes(props.selectedCat)
      )

      const allDelegations = computed<Delegation[]>(
        () => store.getters['organizations/delegations']
      )
      const delegations = computed(() =>
        allDelegations.value.filter((item: Delegation) => {
          return (
            moment
              .utc(formData.value['issuanceDate'])
              .set('hour', 12)
              .isBefore(moment.utc(item.endDate).endOf('day')) &&
            moment
              .utc(formData.value['issuanceDate'])
              .set('hour', 12)
              .isAfter(moment.utc(item.date).startOf('day'))
          )
        })
      )
      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )

      const hasDelegations = computed<boolean>(() => {
        return (
          allDelegations.value.filter((item: Delegation) => {
            return moment
              .utc(formData.value['issuanceDate'])
              .endOf('day')
              .isBetween(
                moment.utc(item.date).startOf('day'),
                moment.utc(item.endDate).endOf('day')
              )
          }).length > 0
        )
      })
      const isEditing = computed<boolean>(
        () =>
          route.name?.toString() === 'company.eventUpdate' &&
          route.params?.eventId?.toString() !== null
      )

      const entry = computed<EventFormDefinition | undefined>(() =>
        catMocks.find(
          (item: EventFormDefinition) =>
            item.eventId ===
            (isEditing.value ? currentEvent.value?.type : selectedCat.value)
        )
      )

      const selectDocument = async (doc: Document) => {
        store.commit('ui/DISPLAY_PREVIEW', true)
        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }
      const isLinkedDocument = (id: string) => {
        return (currentEvent.value?.documents || [])
          ?.map((x: any) => x.id)
          .includes(id)
      }
      const initFormData = () =>
        (entry?.value?.primitives || []).reduce(
          (acc: any, item: EventFormPrimitive) => {
            if (item.table) {
              acc[item.tableId || ''] = []
              acc[item.tableId || ''].push(
                (item.rows || []).reduce(
                  (acc2: any, row: EventFormPrimitive) => {
                    if (!row.isCalculated) {
                      acc2[row.name] = row.props?.initialValue ?? null
                    }
                    return acc2
                  },
                  {}
                )
              )
            } else {
              if (!item.isCalculated && !item?.isUI) {
                acc[item.name] =
                  item.props?.initialValue || item.props?.value
                    ? item.props?.value ?? item.props?.initialValue
                    : item.type === 'array' || item.typeField === 'enum'
                    ? []
                    : null
              }
            }
            return acc
          },
          {}
        ) ?? {}

      const tableToDisplay = ref(`table-${entry.value?.name}`)

      const initFormEditionData = () => {
        if (currentEvent.value?.type?.toString() === EventType.delegation) {
          return {
            id: currentEvent.value.delegation?.id || null,
            label: currentEvent.value.delegation?.label,
            date: currentEvent.value.delegation?.date,
            endDate: currentEvent.value.delegation?.endDate,
            applyRevolving: currentEvent.value.delegation?.applyRevolving,
            commonCeilingId: currentEvent.value.delegation?.commonCeiling?.id,
            choice: 1,
            quantity: currentEvent.value.delegation?.quantity,
            categoryId: currentEvent.value.delegation?.isForCompositionAlias
              ? 'composition'
              : currentEvent.value.delegation?.stockCategory?.id ||
                currentEvent.value.delegation?.optionCategory?.id,
            optionCategoryId: currentEvent.value.delegation?.optionCategory?.id,
            stockCategoryId: currentEvent.value.delegation?.stockCategory?.id
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.commongCeiling
        ) {
          return {
            id: currentEvent.value.commonCeiling?.id || null,
            label: currentEvent.value.commonCeiling?.label,
            date: currentEvent.value.commonCeiling?.date,
            quantity: currentEvent.value.commonCeiling?.quantity
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.delegationSunset
        ) {
          return {
            id: currentEvent.value.delegationSunset?.id || null,
            date: currentEvent.value.delegationSunset?.date,
            initDelegationId:
              currentEvent.value.delegationSunset?.delegation.id,
            delegationId: currentEvent.value.delegationSunset?.delegation.id
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.financialEnding
        ) {
          return {
            id: currentEvent.value.financialEnding?.id || null,
            date: currentEvent.value.financialEnding?.date
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.accountApproval
        ) {
          return {
            id: currentEvent.value.accountApproval?.id || null,
            financialEndingDate:
              currentEvent.value.accountApproval?.financialEndingDate,
            turnover: currentEvent.value.accountApproval?.turnover,
            result: currentEvent.value.accountApproval?.result,
            earningsAllocation:
              currentEvent.value.accountApproval?.earningsAllocation,
            date: currentEvent.value.accountApproval?.date,
            amount: currentEvent.value.accountApproval?.amount,
            allocationType: currentEvent.value.accountApproval?.allocationType
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.commitment
        ) {
          return {
            id: currentEvent.value.commitment?.id || null,
            label: currentEvent.value.commitment?.label,
            date: currentEvent.value.commitment?.date,
            endDate: currentEvent.value.commitment?.endDate,
            type: currentEvent.value.commitment?.type,
            mode: currentEvent.value.commitment?.mode,
            'table-commitment':
              currentEvent.value.commitment?.commitmentParts.map(
                (x: CommitmentPart) => ({
                  id: x.id,
                  titles: x.compositionAlias?.id || x.stockAlias?.id,
                  contactId: x.contact.id,
                  quantity: x.quantity,
                  ownershipType: x.ownershipType
                })
              )
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.appointment
        ) {
          return {
            id: currentEvent.value.appointment?.id || null,
            options: { choiceValue: { id: 3, value: 'event' } },
            choice:
              currentEvent.value.appointment?.accountingExerciseYear !== null
                ? 3
                : currentEvent.value.appointment?.isUnlimited
                ? 4
                : 1,
            accountingExerciseYear:
              currentEvent.value.appointment?.accountingExerciseYear,
            contactId: currentEvent.value.appointment?.contact?.id,
            functionId: currentEvent.value.appointment?.function?.id,
            committeeId: currentEvent.value.appointment?.committee?.id,
            startDate: currentEvent.value.appointment?.startDate,
            endDate: currentEvent.value.appointment?.endDate
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.vestingAcceleration
        ) {
          return {
            id: currentEvent.value.vestingAcceleration?.id || null,
            adjustmentType:
              currentEvent.value.vestingAcceleration?.adjustmentType,
            choice:
              (currentEvent.value?.vestingAcceleration?.contacts || []).length >
              0
                ? 2
                : 1,
            startDate: currentEvent.value.vestingAcceleration?.startDate,
            contactsId: currentEvent.value.vestingAcceleration?.contacts?.map(
              (c) => c.id
            ),
            optionAliasId:
              currentEvent.value.vestingAcceleration?.optionAlias?.map(
                (o) => o.id
              ),
            percent: currentEvent.value.vestingAcceleration?.percent
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.vestingSuspension
        ) {
          return {
            id: currentEvent.value.vestingSuspension?.id || null,
            choice:
              (currentEvent.value?.vestingSuspension?.contacts || []).length > 0
                ? 2
                : 1,
            startDate: currentEvent.value.vestingSuspension?.startDate,
            contactsId: currentEvent.value.vestingSuspension?.contacts?.map(
              (c) => c.id
            ),
            optionAliasId:
              currentEvent.value.vestingSuspension?.optionAlias?.map(
                (o) => o.id
              ),
            endDate: currentEvent.value.vestingSuspension?.endDate
          }
        } else if (
          currentEvent.value?.type?.toString() ===
          EventType.exerciseDelayProrogation
        ) {
          return {
            id: currentEvent.value.exerciseDelayProrogation?.id || null,
            choice:
              (currentEvent.value?.exerciseDelayProrogation?.contacts || [])
                .length > 0
                ? 2
                : 1,
            startDate: currentEvent.value.exerciseDelayProrogation?.startDate,
            contactsId:
              currentEvent.value.exerciseDelayProrogation?.contacts?.map(
                (c) => c.id
              ),
            optionAliasId:
              currentEvent.value.exerciseDelayProrogation?.optionAlias?.map(
                (o) => o.id
              ),
            endDate: currentEvent.value.exerciseDelayProrogation?.endDate
          }
        } else if (
          currentEvent.value?.type?.toString() ===
          EventType.appointmentTermination
        ) {
          return {
            id: currentEvent.value.appointmentTermination?.id || null,
            appointmentId:
              currentEvent.value?.appointmentTermination?.appointment.id,
            endDate: currentEvent.value?.appointmentTermination?.endDate,
            reason: currentEvent.value?.appointmentTermination?.reason
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.nominalValue
        ) {
          return {
            id: currentEvent.value.nominalValue?.id || null,
            date: currentEvent.value?.nominalValue?.date,
            price: currentEvent.value?.nominalValue?.nominalValue,
            isConstantCapital:
              !!currentEvent.value?.nominalValue?.isConstantCapital,
            byParity: !!currentEvent.value?.nominalValue?.byParity
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.parityChange
        ) {
          return {
            id: currentEvent.value.parityChange?.id || null,
            date: currentEvent.value?.parityChange?.date,
            parity: currentEvent.value?.parityChange?.parity,
            optionAliasId: currentEvent.value?.parityChange?.optionAlias.id,
            parityType: currentEvent.value?.parityChange?.parityType
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.compositionIssuance
        ) {
          const eventParts =
            currentEvent.value?.compositionIssuance?.compositionIssuanceParts ??
            []
          store.commit('organizations/SET_COMPANY_CONTACTS', [
            ...contacts.value,
            ...eventParts.reduce((acc: any[], x) => {
              if (!contacts.value.map((c) => c.id).includes(x.contact.id))
                acc.push(x.contact)
              return acc
            }, [])
          ])
          partsContact.value = (
            currentEvent.value?.compositionIssuance?.compositionIssuanceParts ||
            []
          )?.map((x: any) => x.contact)
          if (currentEvent.value?.compositionIssuance?.delegation)
            store.commit('organizations/APPEND_DELEGATIONS', [
              currentEvent.value?.compositionIssuance?.delegation
            ])

          store.commit('organizations/SET_COMPANY_CONTACTS', [
            ...partsContact.value.filter(
              (x: any) => x && !contacts.value.map((c) => c.id).includes(x.id)
            ),
            ...contacts.value
          ])
          return {
            id: currentEvent.value.compositionIssuance?.id || null,
            titles:
              currentEvent.value?.compositionIssuance?.compositionAlias.id,
            issuanceDate: currentEvent.value?.compositionIssuance?.issuanceDate,
            financingRoundBool:
              !!currentEvent.value?.compositionIssuance?.financingRound?.id,
            financingRoundId:
              currentEvent.value?.compositionIssuance?.financingRound?.id,
            delegationBool:
              !!currentEvent.value?.compositionIssuance?.delegation?.id,
            delegationId:
              currentEvent.value?.compositionIssuance?.delegation?.id,
            initDelegationId:
              currentEvent.value?.compositionIssuance?.delegation?.id,
            'table-composition':
              currentEvent.value?.compositionIssuance?.compositionIssuanceParts?.map(
                (x: any) => ({
                  contactId: x.contact.id,
                  contactIdSelection: x.contact,
                  totalPrice: x.totalPrice,
                  quantity: x.quantity,
                  releasedAmount: x.releasedAmount || 0,
                  issuancePremiumPerAction: x.issuancePremiumPerAction,
                  totalIssuancePremium: x.totalIssuancePremium,
                  nominalIncrease: x.nominalIncrease,
                  unitPrice: x.unitPrice || x.totalPrice / x.quantity
                })
              )
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.stockIssuance
        ) {
          partsContact.value = (
            currentEvent.value?.stockIssuance?.stockIssuanceParts || []
          )?.map((x: any) => x.contact)
          if (currentEvent.value?.stockIssuance?.delegation)
            store.commit('organizations/APPEND_DELEGATIONS', [
              currentEvent.value?.stockIssuance?.delegation
            ])
          store.commit('organizations/SET_COMPANY_CONTACTS', [
            ...partsContact.value.filter(
              (x: any) => x && !contacts.value.map((c) => c.id).includes(x.id)
            ),
            ...contacts.value
          ])
          return {
            id: currentEvent.value.stockIssuance?.id || null,
            titles: currentEvent.value?.stockIssuance?.stockAlias.id,
            issuanceDate: currentEvent.value?.stockIssuance?.issuanceDate,
            financingRoundBool:
              !!currentEvent.value?.stockIssuance?.financingRound?.id,
            financingRoundId:
              currentEvent.value?.stockIssuance?.financingRound?.id,
            delegationBool: !!currentEvent.value?.stockIssuance?.delegation?.id,
            delegationId: currentEvent.value?.stockIssuance?.delegation?.id,
            initDelegationId: currentEvent.value?.stockIssuance?.delegation?.id,
            'table-primary':
              currentEvent.value?.stockIssuance?.stockIssuanceParts?.map(
                (x: any) => ({
                  contactIdSelection: x.contact,
                  contactId: x.contact.id,
                  totalPrice: x.totalPrice,
                  quantity: x.quantity,
                  releasedAmount: x.releasedAmount || 0,
                  issuancePremiumPerAction: x.issuancePremiumPerAction,
                  totalIssuancePremium: x.totalIssuancePremium,
                  nominalIncrease: x.nominalIncrease,
                  unitPrice: x.unitPrice || x.totalPrice / x.quantity
                })
              )
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.optionIssuance
        ) {
          const eventParts =
            currentEvent.value?.optionIssuance?.optionIssuanceParts ?? []
          store.commit(
            'organizations/UPDATE_OPTIONS_STOCK_ALIAS',
            currentEvent.value?.optionIssuance?.optionAlias.id
              ? [currentEvent.value?.optionIssuance?.optionAlias]
              : []
          )
          if (currentEvent.value?.optionIssuance?.delegation)
            store.commit('organizations/APPEND_DELEGATIONS', [
              currentEvent.value?.compositionIssuance?.delegation
            ])

          partsContact.value = (
            currentEvent.value?.optionIssuance?.optionIssuanceParts || []
          )?.map((x: any) => x.contact)

          const parts = [
            ...(currentEvent.value?.optionIssuance?.optionIssuanceParts ?? [])
          ]?.map((x: any) => ({
            contactId: x.contact.id,
            contactIdSelection: x.contact,
            totalPrice: x.totalPrice || 0,
            quantity: x.quantity,
            releasedAmount: x.releasedAmount || 0,
            issuancePremiumPerAction: x.issuancePremiumPerAction,
            totalIssuancePremium: x.totalIssuancePremium,
            nominalIncrease: x.nominalIncrease,
            vestingStartDate: x.vestingStartDate,
            vestingId: x.vestingCalendar?.id,
            unitPrice: x.unitPrice || x.totalPrice / x.quantity
          }))

          const data: any = {
            id: currentEvent.value?.optionIssuance?.id || null,
            titles: currentEvent.value?.optionIssuance?.exercisePrice
              ? `${
                  currentEvent.value?.optionIssuance?.optionAlias.id
                }@_${formatNumber(
                  currentEvent.value?.optionIssuance?.exercisePrice
                )}@_`
              : currentEvent.value?.optionIssuance?.optionAlias.id,
            profitSharingPlanBool:
              !!currentEvent.value.optionIssuance?.profitSharingPlan?.id,
            profitSharingPlanId:
              currentEvent.value.optionIssuance?.profitSharingPlan?.id,
            exercisePrice: currentEvent.value?.optionIssuance?.exercisePrice,
            issuanceDate: currentEvent.value?.optionIssuance?.issuanceDate,
            financingRoundBool:
              !!currentEvent.value?.optionIssuance?.financingRound?.id,
            financingRoundId:
              currentEvent.value?.optionIssuance?.financingRound?.id,
            delegationBool:
              !!currentEvent.value?.optionIssuance?.delegation?.id,
            delegationId: currentEvent.value?.optionIssuance?.delegation?.id,
            initDelegationId: currentEvent.value?.optionIssuance?.delegation?.id
          }

          data['table-options'] = parts

          return data
        } else if (
          currentEvent.value?.type?.toString() === EventType.optionSunset
        ) {
          const parts = (
            currentEvent.value?.optionSunsets?.optionSunsetParts || []
          ).map((x) => {
            if (currentEvent.value?.optionSunsets?.mode == OptionSunsetMode.all)
              store.dispatch('organizations/GET_CONTACT_EXERCISE_CALENDARS', {
                companyId: route.params.id,
                filter: {
                  contactId: x.exerciseCalendar?.contact?.id,
                  date:
                    currentEvent.value?.optionSunsets?.date || moment().toDate()
                }
              })
            return {
              id: x.id,
              sunsetVestedQuantity: x.exercisableQuantity,
              sunsetNotVestedQuantity: x.nonVestedQuantity,
              exerciseCalendarId: x.exerciseCalendar.id,
              optionAliasId: `${
                x.exerciseCalendar.optionAlias.id
              }@_${formatNumber(
                x.exerciseCalendar.optionAlias.exercisePrices[0]
              )}@_${x.exerciseCalendar.id}`,
              contactId: x.exerciseCalendar?.contact?.id,
              motive: x.motive,
              nonVestedDate: x.nonVestedDate,
              oldExerciseCalendarId: x.exerciseCalendar.id,
              exercisableBalanceDate: x.exercisableBalanceDate
            }
          })

          return {
            mode: currentEvent.value.optionSunsets?.mode,
            type: currentEvent.value.optionSunsets?.mode,
            id: currentEvent.value.optionSunsets?.id || null,
            date: currentEvent.value?.optionSunsets?.date,
            'table-lapseOptions': parts
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.transfer
        ) {
          const eventParts = currentEvent.value?.transfer?.transferParts ?? []

          store.commit(
            'organizations/UPDATE_OPTIONS_STOCK_ALIAS',
            eventParts.map((x) => x.optionAlias)
          )
          partsContact.value = (
            currentEvent.value?.transfer?.transferParts || []
          )?.map((x: any) => x.contact)

          return {
            id: currentEvent.value?.transfer?.id || null,
            transferDate: currentEvent.value?.transfer?.transferDate,
            reason: currentEvent.value?.transfer?.reason,
            'table-transfer': currentEvent.value?.transfer?.transferParts?.map(
              (x: any) => ({
                sellerId: x.seller.id,
                sellerIdSelection: x.seller,
                transfereeId: x.transferee.id,
                transfereeIdSelection: x.transferee,
                ownershipType: x.ownershipType,
                stockAliasId: `${
                  x.stockAlias?.id ||
                  x.optionAlias?.id ||
                  x.compositionAlias?.id
                }${x?.commitment?.id ? `@_${x?.commitment?.id}` : ''}`,
                totalPrice: x.totalAmount,
                quantity: x.quantity,
                unitPrice: x.unitPrice || x.totalAmount / x.quantity
              })
            )
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.conversion
        ) {
          partsContact.value = (
            currentEvent.value?.conversion?.conversionParts || []
          )?.map((x: any) => x.contact)

          store.commit('organizations/SET_COMPANY_CONTACTS', [
            ...partsContact.value.filter(
              (x: any) => x && !contacts.value.map((c) => c.id).includes(x.id)
            ),
            ...contacts.value
          ])
          return {
            id: currentEvent.value?.conversion?.id || null,
            conversionDate: currentEvent.value?.conversion?.conversionDate,
            'table-conversion':
              currentEvent.value?.conversion?.conversionParts?.map(
                (x: any) => ({
                  contactId: x.contact.id,
                  contactIdSelection: x.contact,
                  fromTitles:
                    x?.fromOptionAlias?.id ||
                    x?.fromCompositionAlias?.id ||
                    x?.fromStockAlias?.id,
                  toTitles:
                    x?.toOptionAlias?.id ||
                    x?.toCompositionAlias?.id ||
                    x?.toStockAlias?.id,
                  quantity: x.quantity,
                  parity: x.parity,
                  entryDate: x?.entryDate
                })
              )
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.capitalReduction
        ) {
          partsContact.value = (
            currentEvent.value?.capitalReduction?.capitalReductionParts || []
          )?.map((x: any) => x.contact)

          store.commit('organizations/SET_COMPANY_CONTACTS', [
            ...partsContact.value.filter(
              (x: any) => x && !contacts.value.map((c) => c.id).includes(x.id)
            ),
            ...contacts.value
          ])
          return {
            id: currentEvent.value?.capitalReduction?.id || null,
            cancelDate: currentEvent.value?.capitalReduction?.issuanceDate,
            reason: currentEvent.value?.capitalReduction?.reason,
            'table-cancelled':
              currentEvent.value?.capitalReduction?.capitalReductionParts?.map(
                (x: any) => ({
                  subscriber: x.contact.id,
                  titles:
                    x?.stockAlias?.id ||
                    x?.optionAlias?.id ||
                    x?.compositionAlias.id,
                  quantity: x.quantity,
                  capitalReduction: null,
                  totalPrice: x.totalPrice,
                  entryDate: x?.entryDate
                })
              )
          }
        } else if (
          currentEvent.value?.type?.toString() === EventType.optionExercise
        ) {
          partsContact.value = (
            currentEvent.value?.optionExercise?.optionExerciseParts || []
          )?.map((x: any) => x.contact)

          store.commit('organizations/SET_COMPANY_CONTACTS', [
            ...partsContact.value.filter(
              (x: any) => x && !contacts.value.map((c) => c.id).includes(x.id)
            ),
            ...contacts.value
          ])
          currentEvent.value?.optionExercise?.optionExerciseParts?.map(
            (x: any, index: number) => {
              if (x?.optionAlias)
                store.commit(
                  'organizations/UPDATE_CONTACT_EXERCISE_CALENDARS',
                  {
                    contactId: x.contact.id,
                    data: [
                      { id: x.exerciseCalendar.id, optionAlias: x?.optionAlias }
                    ]
                  }
                )

              let primitive: any = entry.value?.primitives
                ?.find((x: any) => x.tableId === 'table-exercise')
                ?.rows?.find((x: any) => x.name == 'optionAliasId')
            }
          )
          return {
            id: currentEvent.value?.optionExercise?.id || null,
            issuanceDate: currentEvent.value?.optionExercise?.issuanceDate,
            'table-exercise':
              currentEvent.value?.optionExercise?.optionExerciseParts?.map(
                (x: any) => ({
                  contactIdSelection: x.contact,
                  contactId: x.contact.id,
                  optionAliasId: `${x.optionAlias.id}@_${formatNumber(
                    x.unitPrice
                  )}@_${x.exerciseCalendar.id}`,
                  exercisePrice: x.unitPrice,
                  totalPrice: x.unitPrice * x.issuedQuantity,
                  exerciseCalendarId: x.exerciseCalendar.id,
                  quantity: x.quantity,
                  issuedQuantity: x.issuedQuantity,
                  issuancePremiumPerAction: x.issuancePremiumPerAction,
                  totalIssuancePremium: x.totalIssuancePremium,
                  nominalIncrease: x.nominalIncrease,
                  unitPrice: x.unitPrice,
                  entryDate: currentEvent.value?.optionExercise?.issuanceDate
                })
              )
          }
        }
        return {}
      }

      const documents = computed<Document[]>(
        () => store.getters['organizations/attachedFiles']
      )
      //Future payload for the API
      const formData = ref(
        isEditing.value ? initFormEditionData() : initFormData()
      )

      const fieldsToValidate = computed(() => {
        return (
          (entry.value?.primitives || []).reduce(
            (acc: any, item: EventFormPrimitive) => {
              if (item.table && item.tableId === tableToDisplay.value) {
                // validate only displyed table
                if (item.table) {
                  const rows: EventFormPrimitive[] = []

                  ;(item.rows || []).forEach((row: EventFormPrimitive) => {
                    if (!row.isCalculated) {
                      return rows.push({ ...row })
                    }
                  })

                  acc.push({
                    fieldId: item.tableId,
                    name: item.tableId,
                    type: 'array',
                    typeField: 'array',
                    rows
                  })
                }
              } else {
                if (!item.table && !item.isCalculated && !item.isUI) {
                  acc.push(item)
                }
              }

              return acc
            },
            []
          ) ?? []
        )
      })

      //Generate validations
      const formFields = computed(() =>
        generateValidationsSingle(fieldsToValidate.value)
      )

      const validateSchema = computed(() => {
        return yup.object().shape(formFields.value.reduce(createYupSchema, {}))
      })

      const initialValues = ref(
        isEditing.value
          ? initFormEditionData()
          : generateFormDefaultValue(fieldsToValidate.value)
      )

      const getNeeds = (needed: string) => {
        return (
          (entry.value?.primitives || []).reduce(
            (acc: any, item: EventFormPrimitive) => {
              if (item.needs === needed) {
                acc.push(item)
              }
              return acc
            },
            []
          ) ?? []
        )
      }

      const removeDocument = (docId: string) => {
        const docs = documents.value.filter((x: any) => x.id !== docId)
        store.commit('organizations/ATTACH_FILES_TO_EVENT', { files: docs })
      }

      const removeLinkedDocument = (doc: Document) => {
        store.dispatch('organizations/REMOVE_EVENT_DOCUMENT', {
          companyId: route.params.id,
          eventId: route.params.eventId,
          documentId: doc.id,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('extraction.secondLevel.events.documentContext'),
              message
            })
          },
          onSuccess: () => {
            removeDocument(doc.id)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('extraction.secondLevel.events.documentContext'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }

      const updateFormData = async (data: any) => {
        if (data.component === 'dropdown-stock-categories') {
          const existsValue = !!formData.value['optionCategoryId']
          delete formData.value['optionCategoryId']
          delete formData.value['stockCategoryId']
          delete formData.value['compositionCategoryId']
          if (data.dataType) {
            formData.value[`${data.dataType}CategoryId`] = data.value
          } else formData.value[data.name] = data.value
          if (data?.initChildrenOnChange) {
            getNeeds(data.fieldId)
              .filter((n: any) => n.type === 'boolean')
              .forEach((elm: any) => {
                formData.value[elm.name] =
                  !!formData.value['optionCategoryId'] && existsValue
              })
          }
        } else if (data.component === 'date-generic') {
          let endDatePrimitive: any = (entry.value?.primitives || []).find(
            (x: EventFormPrimitive) => x.name === 'endDate'
          )

          if (
            formData.value.endDate &&
            endDatePrimitive &&
            endDatePrimitive?.props?.needs === data.name &&
            formData.value['durationOptions']
          ) {
            formData.value[endDatePrimitive.name] = moment(data.value)
              .endOf('day')
              .add(
                formData.value['durationOptions'].quantity || 0,
                formData.value['durationOptions'].unit || 'months'
              )
              .toISOString()
          }
        }
        if (data.component === 'contact-generic') {
          if (entry.value?.name === 'lapseOptions') {
            if (data.value) {
              store.dispatch('organizations/GET_CONTACT_EXERCISE_CALENDARS', {
                companyId: route.params.id,
                filter: {
                  contactId: data.value,
                  date: formData.value?.date
                    ? moment.utc(formData.value.date).toDate()
                    : moment().toDate()
                }
              })
            }

            const prim = entry.value?.primitives.map((p: any) => {
              if (p.name == 'optionAliasId') {
                return {
                  ...p,
                  props: {
                    ...p?.props,
                    optionData: { index: -1, value: data.value }
                  }
                }
              } else return p
            })
            entry.value.primitives = prim

            await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
              companyId: route.params.id,
              filter: {
                limit: 300,
                optionAliasId: formData.value.optionAliasId
                  ? [formData.value.optionAliasId?.split('@_')[0]]
                  : [],
                contactId: [data.value]
              }
            })
            await store.dispatch('organizations/GET_CAPTABLE', {
              companyId: route.params.id,
              filter: {
                optionAliasId: formData.value.optionAliasId
                  ? [formData.value.optionAliasId?.split('@_')[0]]
                  : [],
                contactId: [data.value]
              }
            })
          }
          formData.value[data.name] = data.value
        } else if (data.component === 'duration-generic') {
          // component emits data.value which is equal to Now + Duration
          // We look at the diff between now and duration in days
          // and we deduce the real end date from the start date which is another field
          // by adding the diff days
          // formData.value[data.name] =
          // data?.options?.quantity === 0
          //   ? moment.utc(data.value).format('yyyy-MM-DD').toString()
          //   : moment.utc(formData.value[data.needs])
          //       .add(data?.options?.quantity || 0, data.options.unit)
          //       .format('yyyy-MM-DD')
          formData.value[data.name] = data.value

          // Edge case for nomination
          if (data.fieldId === 'nominationEndDate' && data.options) {
            if (
              data.options &&
              data.options.choiceValue?.value === 'duration'
            ) {
              console.log('data', data)

              formData.value.accountingExerciseYear = null
              formData.value.endDate = null
              formData.value.isUnlimited = false

              if (data.options.unit == 'days')
                formData.value.options = parseInt(data.options.quantity)
              else if (['years', 'months'].includes(data.options.unit)) {
                const days = moment
                  .utc(formData.value.startDate)
                  .add(data.options.quantity, data.options.unit)
                  .diff(formData.value.startDate, 'days')
                console.log('days', days)
                formData.value.durationInDays = days
              }
              formData.value[data.name] = moment
                .utc(formData.value[data.needs])
                .startOf('day')
                .add(parseInt(data.options.quantity) ?? 0, data.options.unit)
                .toISOString()
            } else if (
              data.options &&
              data.options.choiceValue?.value === 'event'
            ) {
              formData.value.accountingExerciseYear = data.options.year
              formData.value.endDate = null
              formData.value.isUnlimited = false
              formData.value.durationInDays = null
            } else if (
              data.options &&
              data.options.choiceValue?.value === 'unlimited'
            ) {
              formData.value['isUnlimited'] = data.value === 'unlimited'
              formData.value.accountingExerciseYear = null
              formData.value.endDate = null
              formData.value.durationInDays = null
            }
            // Edge case for vesting suspension and vesting prorogation
          } else if (
            data.fieldId === 'susEndDate' ||
            data.fieldId === 'proEndDate'
          ) {
            if (
              data.options &&
              data.options.choiceValue?.value === 'duration'
            ) {
              formData.value.timeUnit = data.options.unit
              formData.value.timeQuantity = data.options.quantity
            }
          } else {
            if (
              data.options &&
              data.options.choiceValue?.value === 'duration'
            ) {
              formData.value[data.name] = moment
                .utc(formData.value[data.needs])
                .endOf('day')
                .add(data.options.quantity || 0, data.options.unit || 'months')
                .toISOString()
            }
          }
          formData.value['durationOptions'] = data.options
        } else if (data.component === 'dropdown-exercisePrice') {
          formData.value[data.name] = data.value
        } else if (data.component === 'dropdown-options') {
          if (Array.isArray(data.value)) {
            data.value = data.value.map((x: string) => {
              const [id, p] = x.split('@_')
              return id
            })
          } else {
            const [stockId, price] = data.value.split('@_')

            if (isOption(stockId) && entry.value?.name === 'options') {
              //when stock is option update unitPrice , re-calulate totalPrice  and disable unitPrice field
              tableToDisplay.value = 'table-options'

              const arr = formData.value[tableToDisplay.value].map(
                (x: any) => ({
                  ...x,
                  unitPrice: (x.totalPrice / x.quantity)?.toString() || 0,
                  totalPrice:
                    x.totalPrice || (x.quantity || 0) * (x.unitPrice || 0)
                })
              )
              formData.value = {
                ...formData.value,
                exercisePrice: price,
                optionExercisePrice: price,
                [tableToDisplay.value]: arr
              }
            } else if (entry.value?.name === 'lapseOptions') {
              store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
                companyId: route.params.id,
                filter: {
                  limit: 300,
                  date: formData.value?.date
                    ? moment.utc(formData.value.date).toDate()
                    : new Date().toISOString(),
                  contactId: formData.value.contactId
                    ? [formData.value.contactId]
                    : [],
                  optionAliasId: [stockId]
                }
              })
              store.dispatch('organizations/GET_CAPTABLE', {
                companyId: route.params.id,
                filter: {
                  date: formData.value?.date
                    ? moment.utc(formData.value.date).toDate()
                    : new Date().toISOString(),
                  contactId: formData.value.contactId
                    ? [formData.value.contactId]
                    : [],
                  optionAliasId: [stockId]
                }
              })
            }
          }
          formData.value[data.name] = data.value
        } else if (data.component === 'dropdown-generic') {
          if (entry.value?.name === 'socialAppro') {
            formData.value.allocationType = null
            formData.value[data.name] = data.value
          } else if (
            data.name === 'isQuantityForExercisable' &&
            data.value === OptionSunsetType.Percent
          ) {
            formData.value['sunsetedExercisablePercentage'] = 100
          } else formData.value[data.name] = data.value
        } else if (data.component === 'dropdown-stocks') {
          if (['primary', 'options'].includes(entry.value?.name || '')) {
            const [stockId, price] = data.value.split('@_')

            if (isOption(stockId)) {
              //when stock is option update unitPrice , re-calulate totalPrice  and disable unitPrice field
              tableToDisplay.value = 'table-options'

              const arr = formData.value[tableToDisplay.value].map(
                (x: any) => ({
                  ...x,
                  unitPrice: parseFloat(price),
                  totalPrice: (x.quantity || 0) * parseFloat(price)
                })
              )

              const unitPriceField = (entry.value?.primitives || [])
                .find((x: any) => x.tableId == 'table-options')
                ?.rows?.find((x: any) => x.name === 'unitPrice')

              if (unitPriceField && price) {
                unitPriceField.props = {
                  ...unitPriceField?.props,
                  disabled: true
                }
              }

              formData.value = {
                ...formData.value,
                exercisePrice: price,
                [tableToDisplay.value]: arr
              }
            } else if (isComposition(data.value)) {
              tableToDisplay.value = 'table-composition'
            } else {
              tableToDisplay.value = 'table-primary'
            }
          } else {
            tableToDisplay.value = `table-${entry.value?.name}`
          }

          formData.value[data.name] = data.value
        } else if (data.component === 'dropdown-delegations') {
          const delegation = delegations.value.find(
            (item: any) => item.id === data.value
          )
          let stockPrimitive = (entry.value?.primitives || []).find(
            (x: EventFormPrimitive) => x.name === 'titles'
          )

          if (delegation) {
            if (stockPrimitive) {
              stockPrimitive.props = {
                ...stockPrimitive?.props,
                categoryId:
                  delegation?.stockCategory?.id ||
                  delegation?.optionCategory?.id ||
                  null,
                disabled: false
              }
            }
          } else {
            if (stockPrimitive) {
              stockPrimitive.props = {
                ...stockPrimitive?.props,
                disabled: false,
                categoryId: null
              }
            }
          }

          formData.value[data.name] = data.value
        } else if (data.component === 'dropdown-functions') {
          formData.value[data.name] = data.value
        } else if (data.name) {
          formData.value[data.name] = data.value
        }
        if (
          data.name === 'profitSharingPlanBool' &&
          formData.value[data.name] === false
        ) {
          formData.value['profitSharingPlanId'] = null
        }

        if (
          data.name === 'financingRoundBool' &&
          formData.value[data.name] === false
        ) {
          formData.value['financingRoundId'] = null
        }
        if (
          data.name === 'delegationBool' &&
          formData.value[data.name] === false
        ) {
          const stockPrimitive = (entry.value?.primitives || []).find(
            (x: any) => x.name == 'titles'
          )

          formData.value['delegationId'] = null
          if (stockPrimitive)
            stockPrimitive.props = {
              ...stockPrimitive?.props,
              value: '',
              disabled: false
            }
        }

        if (['choice', 'isQuantityForExercisable'].includes(data.name)) {
          const choices = data.props.choices.filter(
            (x: FieldWithChoice) => x.id !== data.value
          )
          choices.forEach((element: FieldWithChoice) => {
            formData.value[element.field] = element.defaultValue
          })
        }

        //Update entry date in event associated array
        if (
          [
            'date',
            'issuanceDate',
            'transferDate',
            'cancelDate',
            'conversionDate'
          ].includes(data.name)
        ) {
          ;(formData.value[tableToDisplay.value] || []).forEach(
            (x: any) => (x.entryDate = data.value)
          )

          const delegPrimitive: any = (entry.value?.primitives || []).find(
            (x: any) => x.name == 'delegationId'
          )

          const entryDatePrimitive: any = (entry.value?.primitives || []).find(
            (x: any) => x.name == 'entryDate'
          )

          if (entryDatePrimitive?.name)
            formData.value[entryDatePrimitive.name] = data.value

          const stockPrimitive = (entry.value?.primitives || []).find(
            (x: any) => x.name == 'titles'
          )

          if (formData.value['initDelegationId'])
            formData.value['delegationId'] = formData.value['initDelegationId']
          else formData.value['delegationId'] = null
          if (delegPrimitive)
            delegPrimitive.props = {
              ...delegPrimitive?.props,
              endDate: data.value,
              value: formData.value['initDelegationId']
            }

          if (stockPrimitive)
            stockPrimitive.props = {
              ...stockPrimitive?.props,
              disabled: false
            }

          const value = await store.dispatch(
            'organizations/COMPUTE_NOMINAL_VALUE',
            { date: data.value, setAsGlobal: true }
          )

          noNominalValue.value = value === undefined
          // delete formData.value['initDelegationId']
          tableKey.value++
        }

        // Updated object to send to the api
        console.log('FormData', formData.value)
      }
      const updateTable1 = ({ tableId, value }: any) => {
        formData.value[tableId] = [...value]
      }
      const removeRow = ({ rowIndex, tableId }: any) => {
        formData.value[tableId].splice(rowIndex, 1)
      }

      const { tableKey, updateTable, addRows, duplicateRow } =
        tableFormManipulation(formData.value)

      const { submitForm, isOption, isComposition } =
        formDataManipulation(formData)

      const eventCreated = () => {
        isSubmitting.value = false
        emit('update-category', '')
      }
      const onCreationError = (error: any) => {
        isSubmitting.value = false
      }
      const validateEventForm = (entryName: string, withRedirect = false) => {
        isSubmitting.value = true
        let params: EventSubmitProps = {
          entryName,
          withRedirect,
          eventId: editionEventId.value,
          eventCreated,
          onCreationError
        }
        if (isEditing.value) {
          params = {
            entryName,
            withRedirect,
            action: 'EDIT',
            eventId: route.params?.eventId?.toString(),
            eventCreated,
            onCreationError
          }
        }
        submitForm(params)
      }
      const handleForm = async (values: any, resetForm: any) => {
        resetForm()
        formData.value.reason = 0
      }

      const getDelegations = (companyId = route.params.id) => {
        const filter: any = {}
        if (formData.value['issuanceDate'])
          filter.usableAtDate = formData.value['issuanceDate']
        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId,
          filter,
          onSuccess: (delegs: any[]) => {
            formData.value['delegationBool'] =
              delegs.length > 0 && !isEditing.value
          }
        })
      }

      onBeforeMount(async () => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0
          }
        })
      })

      watch(
        () => formData.value['issuanceDate'],
        () => {
          if (
            entry.value?.primitives.find((x: any) => x.name == 'delegationBool')
          ) {
            getDelegations()
          }
        }
      )

      const mountedAttachedDocuments = () => {
        store.commit('organizations/ATTACH_FILES_TO_EVENT', {
          files: props.event?.documents || []
        })
      }

      onMounted(() => {
        mountedAttachedDocuments()
      })

      onBeforeUnmount(() => {
        formData.value = {}
      })

      watch(
        () => props.event,
        () => {
          mountedAttachedDocuments()
        }
      )

      watch(
        () => props.selectedCat,
        (cat: string) => {
          tableToDisplay.value = `table-${entry.value?.name}`
          formData.value = isEditing.value
            ? initFormEditionData()
            : initFormData()
          store.commit('toolbox/SET_EVENT_TABLE_COLUMNS', [])
          tableKey.value++
        }
      )

      watch([() => entry.value, () => isEditing.value], ([val, val1]: any) => {
        const data = initFormEditionData()
        const defaultValue = generateFormDefaultValue(fieldsToValidate.value)
        initialValues.value = isEditing.value ? data : defaultValue
        formData.value = isEditing.value ? data : defaultValue

        tableToDisplay.value = `table-${val?.name}`
      })

      watch(
        () => props.isDraft,
        (val: boolean) => {
          formData.value.isDraft = val
        }
      )

      watch(
        () => canDisplayForm.value,
        (val, old) => {
          if (!old && val) {
            emit('loaded', val)
          }
        }
      )

      return {
        entry,
        tableKey,
        nominalValue,
        rowsToAdd,
        formData,
        tableToDisplay,
        isOption,
        isEditing,
        formFields,
        canMakeDocAuto,
        delegations,
        documents,
        validateSchema,
        currentDocument,
        initialValues,
        isSubmitting,
        currentEvent,
        editionEventId,
        canDisplayForm,
        updateFormData,
        isLinkedDocument,
        selectDocument,
        updateTable,
        updateTable1,
        getNeeds,
        addRows,
        removeRow,
        duplicateRow,
        submitForm,
        handleForm,
        removeDocument,
        validateEventForm,
        removeLinkedDocument,
        hasDelegations
      }
    }
  })
