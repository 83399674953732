import i18n from '@/services/lang'
import { EventFormDefinition, EventType } from '@/types/event'
import { AppointmentTerminationReason } from '@/utils/constants'
import { PARITYTYPE } from '@/types/forms'
import {
  EndOfContractReasons,
  OptionSunsetMode,
  CommitmentTypeEnum
} from '@/types/forms'
import { VestingAdjustment } from '@/types/forms'
import {
  CoOwnershipType,
  CommitmentMode,
  TransferReason
} from '@/services/api/models'

/*
DOCUMENTATION
SUBCAT:
{
  eventId: uid of the category
  name: unique name of the category
  primitives: FIELDS
}

FIELD:
{
  fieldId: unique id for field
  name: name of the field
  label: translated label of the field
  component: the component used
  type: type of the validation
  typeField: type of the field (used for input to set type="number" or type="text"
  hint: placeholder for the field
  grid: tailwind class used to grid placement
  min: example of a validation rule, any rule can be used
  isNeeded: set to true if this field is needed to display another field
  isUI: if set to true, the element will not appear in the final payload (Example: used on a bool with isNeeded, the bool value is irrelevant for the API, but the need value is)
  needs: fieldId of the needed element
  needsValue: value of the needed element for this one to be shown
  table: set to true if you want to display a table
  tableId: unique id for the table, ALWAYS name it: table-${name_of_the_event}
  rows: elements to display in a table, table must be true
  custom: set true to display the component in a data table
  customDefault: set true to display component in default slot
  isCalculated: Value is calculated and doesnt used a specific component. It will not be sent to the final payload
  props: any props can be passed here
},
 */
export default [
  {
    eventId: EventType.delegationSunset,
    name: 'delegationSunset',
    primitives: [
      {
        fieldId: '1134434435',
        name: 'date',
        label: i18n.global.t('events.elements.startDate'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-start-1 col-span-3',
        isMandatory: true,
        props: {
          label: true
        }
      },
      {
        fieldId: '1456768',
        name: 'delegationId',
        label: i18n.global.t('events.elements.delegation'),
        component: 'dropdown-delegations',
        type: 'string',
        typeField: 'text',
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        hint: 'Choose something',
        props: {
          openDirection: 'bottom',
          type: '',
          endDate: ''
        }
      }
    ]
  },
  {
    eventId: EventType.capitalReduction,
    name: 'cancelled',
    primitives: [
      {
        fieldId: '1055637373',
        name: 'cancelDate',
        label: i18n.global.t('events.elements.cancelDate'),
        component: 'date-generic',
        type: 'date',
        hint: i18n.global.t('events.elements.cancelDate'),
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        props: {
          label: true
        }
      },
      {
        fieldId: '105563eeree7373',
        name: 'reason',
        label: i18n.global.t('events.elements.reason'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.reason'),
        grid: 'col-start-1 col-span-3',
        isMandatory: true,
        props: {
          classes: 'mt-0',
          choices: [
            {
              id: 'LOSSES',
              name: i18n.global.t('events.elements.loss'),
              value: 'loss'
            },
            {
              id: 'NOT_LOSSES',
              name: i18n.global.t('events.elements.not_loss'),
              value: 'not_loss'
            }
          ]
        }
      },
      {
        fieldId: '22222',
        table: true,
        tableId: 'table-cancelled',
        grid: 'col-start-1 col-span-6',
        label: i18n.global.t('events.labels.tableHeader'),
        min: 1,
        rows: [
          {
            fieldId: '14ewewe',
            name: 'subscriber',
            label: i18n.global.t('events.elements.subscriber'),
            component: 'contact-generic',
            type: 'string',
            hint: i18n.global.t('events.elements.subscriber'),
            custom: true,
            isMandatory: true,
            customDefault: true,
            props: {
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: '15fdhf',
            name: 'titles',
            label: i18n.global.t('events.elements.titles'),
            component: 'stock-generic',
            type: 'string',
            typeField: 'enum',
            hint: i18n.global.t('events.elements.titles'),
            custom: true,
            customDefault: true,
            props: {
              openDirection: 'bottom',
              filterResults: false,
              optionPerPrice: false
            }
          },
          {
            fieldId: '16flkgjpjgg',
            name: 'quantity',
            label: i18n.global.t('events.elements.quantity'),
            component: 'input-generic',
            type: 'number',
            typeField: 'number',
            min: 0,
            width: '5%',
            hint: i18n.global.t('events.elements.quantity'),
            custom: true,
            customDefault: true,
            props: { type: 'number' }
          },
          {
            fieldId: '1780560565',
            name: 'totalPrice',
            label: i18n.global.t('events.elements.total'),
            component: 'input-generic',
            type: 'number',
            typeField: 'float',
            hint: i18n.global.t('events.elements.total'),
            width: '5%',
            min: 0,
            custom: true,
            customDefault: true,
            isCalculable: false,
            props: {
              type: 'number',
              step: 0.01
            }
          },
          {
            fieldId: '19fjdhsldsp',
            name: 'capitalReduction',
            label: i18n.global.t('events.elements.capitalReduction'),
            component: null,
            type: 'number',
            typeField: 'float',
            hint: i18n.global.t('events.elements.capitalReduction'),
            width: '5%',
            custom: true,
            isFilterable: true,
            isCalculated: true
          },
          {
            fieldId: '1055637978843',
            name: 'entryDate',
            label: i18n.global.t('events.elements.entryDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.entryDate'),
            width: '15%',
            grid: '',
            isFilterable: true,
            custom: true,
            customDefault: true,
            props: {
              label: false
            }
          }
        ]
      }
    ]
  },
  {
    eventId: 'dededededed8788',
    name: 'reduction',
    primitives: [
      {
        fieldId: 'reduction-date',
        name: 'issuanceDate',
        label: i18n.global.t('events.elements.emissionDate'),
        component: 'date-generic',
        type: 'date',
        hint: "Quelle est la date d'emission",
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        isNeeded: true,
        props: {
          registerDateDepends: true,
          boolFieldId: '13-isEqualToRegisterDate',
          label: true
        }
      },
      {
        fieldId: 'reduction-modality',
        name: 'terms',
        label: i18n.global.t('events.elements.terms'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        grid: 'col-start-1 col-span-3',
        classes: '',
        hint: i18n.global.t('events.elements.choice'),
        isMandatory: true,
        props: {
          choices: [
            {
              id: 'REDUCTION',
              name: i18n.global.t('events.elements.faceValueReduction'),
              value: 'grantDate'
            },
            {
              id: 'CANCELLATION',
              name: i18n.global.t('events.elements.actionCancellation'),
              value: 'employmentDate'
            }
          ]
        }
      },
      {
        fieldId: 'reduction-reason',
        name: 'reason',
        label: i18n.global.t('events.elements.reason'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.reason'),
        grid: 'col-start-1 col-span-3',
        classes: '',
        isMandatory: true,
        props: {
          classes: 'mt-0',
          choices: [
            {
              id: 'LOSSES',
              name: i18n.global.t('events.elements.loss'),
              value: 'loss'
            },
            {
              id: 'NOT_LOSSES',
              name: i18n.global.t('events.elements.not_loss'),
              value: 'not_loss'
            }
          ]
        }
      },
      {
        fieldId: 'reduction-action',
        table: true,
        tableId: 'table-reduction',
        grid: 'col-start-1 col-span-6',
        label: i18n.global.t('events.labels.tableHeader'),
        rows: [
          {
            fieldId: '14-reduction',
            name: 'contactId',
            label: i18n.global.t('events.elements.subscriber'),
            component: 'contact-generic',
            type: 'string',
            typeField: 'text',
            hint: 'Subscriber',
            width: '15%',
            custom: true,
            customDefault: true,
            isMandatory: true,
            props: {
              openDirection: 'bottom',
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: '15-titles-reduction',
            name: 'titles',
            label: i18n.global.t('events.elements.titles'),
            component: 'stock-generic',
            type: 'string',
            typeField: 'enum',
            hint: 'titles',
            custom: true,
            customDefault: true,
            grid: 'col-start-1 col-span-3',
            isMandatory: true,
            props: {
              openDirection: 'bottom',
              label: false,
              disabled: false,
              classes: 'flex items-center h-10 grid-col-12 col-start-1'
            }
          },
          {
            fieldId: '16-reduction',
            name: 'quantity',
            label: i18n.global.t('events.elements.quantity'),
            component: 'input-generic',
            type: 'number',
            width: '5%',
            hint: i18n.global.t('events.elements.quantity'),
            custom: true,
            customDefault: true,
            props: { type: 'number' }
          },

          {
            fieldId: '17-primary-form',
            name: 'totalPrice',
            label: i18n.global.t('events.elements.total'),
            component: 'input-generic',
            type: 'number',
            width: '5%',
            typeField: 'float',
            hint: i18n.global.t('events.elements.total'),
            custom: true,
            customDefault: true,
            isCalculable: true,
            props: {
              step: 0.01,
              min: 0
            }
          },
          {
            fieldId: '19-unit-price-red',
            name: 'capitalReduction',
            label: i18n.global.t('events.elements.capitalReduction'),
            component: null,
            type: 'number',
            typeField: 'float',
            hint: i18n.global.t('events.elements.capitalReduction'),
            custom: true,
            isCalculated: true
          },
          {
            fieldId: '1055637978843',
            name: 'entryDate',
            label: i18n.global.t('events.elements.entryDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.entryDate'),
            grid: '',
            width: '15%',
            isFilterable: true,
            custom: true,
            customDefault: true,
            props: {
              label: false
            }
          }
        ]
      }
    ]
  },

  {
    eventId: EventType.conversion,
    name: 'conversion',
    primitives: [
      {
        fieldId: 'conversion-date',
        name: 'conversionDate',
        label: i18n.global.t('events.labels.conversionDate'),
        component: 'date-generic',
        type: 'date',
        hint: "Quelle est la date d'emission",
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        isNeeded: true,
        props: {
          registerDateDepends: true,
          boolFieldId: '13-isEqualToRegisterDate',
          label: true
        }
      },

      {
        fieldId: 'conversion-table',
        table: true,
        tableId: 'table-conversion',
        grid: 'col-start-1 col-span-6',
        label: i18n.global.t('events.labels.tableHeader'),
        rows: [
          {
            fieldId: '14-contact-id',
            name: 'contactId',
            label: i18n.global.t('events.elements.subscriber'),
            component: 'contact-generic',
            type: 'string',
            typeField: 'text',
            hint: 'Subscriber',
            width: '15%',
            custom: true,
            customDefault: true,
            isMandatory: true,
            props: {
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: '15-titles-conversion',
            name: 'fromTitles',
            label: i18n.global.t('events.elements.titles'),
            component: 'stock-generic',
            type: 'string',
            typeField: 'enum',
            hint: 'titles',
            custom: true,
            customDefault: true,
            grid: 'col-start-1 col-span-3',
            isMandatory: true,
            props: {
              openDirection: 'bottom',
              label: false,
              disabled: false,
              optionPerPrice: false,
              classes: 'flex items-center h-11 grid-col-12 col-start-1'
            }
          },
          {
            fieldId: '16-titles-conversion',
            name: 'toTitles',
            label: i18n.global.t('events.elements.titles'),
            component: 'stock-generic',
            type: 'string',
            typeField: 'enum',
            hint: 'titles',
            custom: true,
            customDefault: true,
            grid: 'col-start-1 col-span-3',
            isMandatory: true,
            props: {
              openDirection: 'bottom',
              label: false,
              disabled: false,
              optionPerPrice: false,
              classes: 'flex items-center h-11 grid-col-12 col-start-1'
            }
          },
          {
            fieldId: '16-conversion-quantity',
            name: 'quantity',
            label: i18n.global.t('events.elements.quantity'),
            component: 'input-generic',
            type: 'number',
            width: '5%',
            hint: i18n.global.t('events.elements.quantity'),
            custom: true,
            customDefault: true,
            props: { min: 0 }
          },
          {
            fieldId: '16-conversion-parity',
            name: 'parity',
            label: i18n.global.t('events.elements.parity'),
            component: 'input-generic',
            type: 'number',
            width: '5%',
            hint: i18n.global.t('events.elements.parity'),
            custom: true,
            customDefault: true,
            props: { min: 0 }
          },

          {
            fieldId: '1055637978843',
            name: 'entryDate',
            label: i18n.global.t('events.elements.entryDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.entryDate'),
            grid: '',
            width: '15%',
            isFilterable: true,
            custom: true,
            customDefault: true,
            props: {
              label: false
            }
          }
        ]
      }
    ]
  },
  {
    eventId: EventType.optionSunset,
    name: 'lapseOptions',
    primitives: [
      {
        fieldId: 'laspe-eventDate',
        name: 'date',
        label: i18n.global.t('events.elements.cancelDate'),
        component: 'date-generic',
        type: 'date',
        hint: i18n.global.t('events.elements.cancelDate'),
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        props: {
          label: true
        }
      },
      {
        fieldId: 'sunset-mode',
        name: 'mode',
        isMandatory: true,
        label: i18n.global.t('events.elements.sunsetType'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'text',
        isNeeded: true,

        grid: 'col-start-1 col-span-3',
        hint: i18n.global.t('events.elements.sunsetType'),
        props: {
          label: true,
          classes: '',
          value: OptionSunsetMode.all,
          choices: [OptionSunsetMode.all, OptionSunsetMode.partial].map(
            (x) => ({
              name: i18n.global.t(`optionSunsetMode.${x}`),
              value: x,
              id: x
            })
          )
        }
      },
      {
        fieldId: 'sunset-type',
        name: 'type',
        isMandatory: false,
        label: i18n.global.t('events.elements.quantityType'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'text',
        grid: 'col-start-1 col-span-3',
        hint: i18n.global.t('events.elements.quantityType'),
        needs: 'sunset-mode',
        needsValue: OptionSunsetMode.all,
        props: {
          mode: 'single',
          label: true,
          classes: '',
          value: OptionSunsetMode.all,
          choices: [
            {
              name: i18n.global.t(`optionSunsetMode.ALL_Q`),
              value: OptionSunsetMode.all,
              id: OptionSunsetMode.all
            },
            ...[OptionSunsetMode.vested, OptionSunsetMode.notVested].map(
              (x) => ({
                name: i18n.global.t(`optionSunsetMode.${x}`),
                value: x,
                id: x
              })
            )
          ]
        }
      },
      {
        fieldId: '2345-table-option-sunsets',
        table: true,
        tableId: 'table-lapseOptions',
        grid: 'col-start-1 col-span-6',
        label: i18n.global.t('events.labels.tableHeader'),
        rows: [
          {
            fieldId: '14',
            name: 'contactId',
            label: i18n.global.t('events.elements.subscriber'),
            component: 'contact-generic',
            type: 'string',
            typeField: 'text',
            hint: 'Subscriber',
            width: '10%',
            custom: true,
            customDefault: true,
            isMandatory: true,
            props: {
              classes: '',
              onlyOptionUsers: false,
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: '15-titles',
            name: 'optionAliasId',
            label: i18n.global.t('events.elements.option'),
            component: 'dropdown-options',
            type: 'string',
            typeField: 'text',
            hint: 'optionId',
            isMandatory: true,
            custom: true,
            customDefault: true,
            props: {
              classes: '',
              openDirection: 'bottom',
              dataFromExtra: true,
              categoryId: null,
              displayAll: false,
              mode: 'single',
              label: true,
              disabled: false,
              optionPerPrice: true,
              canAddNew: false
            }
          },
          {
            fieldId: 'sunset-motive',
            name: 'motive',
            custom: true,
            customDefault: true,
            isMandatory: true,
            label: i18n.global.t('events.elements.motive'),
            component: 'dropdown-generic',
            type: 'string',
            typeField: 'enum',
            hint: i18n.global.t('events.elements.motive'),
            props: {
              label: false,
              classes: '',
              options: Object.values(EndOfContractReasons).map((x) => ({
                name: i18n.global.t(`SunsetMotives.${x}`),
                value: x,
                id: x
              }))
            }
          },
          {
            fieldId: 'laspe-exercisableBalanceDate',
            name: 'exercisableBalanceDate',
            label: i18n.global.t('events.elements.exercisableBalanceDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.exercisableBalanceDate'),
            isMandatory: true,
            custom: true,
            customDefault: true,
            props: {
              label: false
            }
          },
          {
            fieldId: 'laspe-nonVestedDate',
            name: 'nonVestedDate',
            label: i18n.global.t('events.elements.nonVestedDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.nonVestedDate'),
            isMandatory: true,
            custom: true,
            customDefault: true,
            props: {
              label: false
            }
          },
          {
            fieldId: 'laspe-exercisableBalanceQuantity',
            name: 'sunsetVestedQuantity',
            isUI: false,
            label: i18n.global.t('events.elements.sunsetVestedQuantity'),
            component: 'input-generic',
            type: 'number',
            hint: i18n.global.t('events.elements.sunsetVestedQuantity'),
            custom: true,
            customDefault: true,
            isCalculated: false,
            isFilterable: false,
            grid: 'col-start-1 col-span-3',
            props: {
              disabled: (form: any) => form.mode !== OptionSunsetMode.partial,
              label: false
            }
          },
          {
            fieldId: 'laspe-nonVestedQuantity',
            name: 'sunsetNotVestedQuantity',
            isUI: true,
            label: i18n.global.t('events.elements.sunsetNotVestedQuantity'),
            component: 'input-generic',
            type: 'number',
            hint: i18n.global.t('events.elements.sunsetNotVestedQuantity'),
            custom: true,
            customDefault: true,
            isCalculated: false,
            isFilterable: false,
            props: {
              disabled: (form: any) => form.mode !== OptionSunsetMode.partial,
              label: false
            }
          }
        ]
      }
    ]
  },
  {
    eventId: EventType.stockIssuance,
    name: 'primary',
    primitives: [
      {
        fieldId: '10',
        name: 'issuanceDate',
        label: i18n.global.t('events.elements.emissionDate'),
        component: 'date-generic',
        type: 'date',
        hint: "Quelle est la date d'emission",
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        isNeeded: true,
        props: {
          registerDateDepends: true,
          boolFieldId: '13-isEqualToRegisterDate',
          label: true
        }
      },
      {
        fieldId: '13',
        name: 'delegationBool',
        label: i18n.global.t('events.elements.delegation-bool'),
        component: 'checkbox-generic',
        type: 'boolean',
        grid: 'col-start-1 col-span-3 mt-0 min-h-10',
        classes: '',
        isNeeded: true,
        hint: 'Utilisation Delegation',
        props: { classes: 'mt-0' }
      },
      {
        fieldId: '1456768',
        name: 'delegationId',
        label: i18n.global.t('events.elements.delegation'),
        component: 'dropdown-delegations',
        type: 'string',
        typeField: 'text',
        needs: '13',
        isNeeded: true,
        needsValue: true,
        hint: 'Choose something',
        props: {
          classes: 'col-start-1 grid-col-12',
          openDirection: 'bottom',
          type: 'stockAlias',
          endDate: ''
        }
      },
      {
        fieldId: '15-titles',
        name: 'titles',
        label: i18n.global.t('events.elements.stock'),
        component: 'dropdown-actions',
        type: 'string',
        typeField: 'enum',
        hint: 'titles',
        grid: 'col-start-1 col-span-3',
        isMandatory: true,
        props: {
          openDirection: 'bottom',
          categoryId: null,
          label: true,
          disabled: false,
          optionPerPrice: false,
          classes: 'flex items-center h-10 grid-col-12 col-start-1'
        }
      },
      {
        fieldId: '16-primary-fund-raising-round-bool',
        name: 'financingRoundBool',
        label: i18n.global.t('events.elements.useFundRaisingRound'),
        component: 'checkbox-generic',
        type: 'boolean',
        grid: 'col-start-1 col-span-3 mt-0 min-h-10',
        classes: '',
        isNeeded: true,
        props: { classes: 'mt-0' }
      },

      {
        fieldId: '166-fund-raising-round',
        name: 'financingRoundId',
        label: i18n.global.t('events.elements.fundRaisingRound'),
        component: 'dropdown-fund-raising-rounds',
        type: 'string',
        typeField: 'text',
        needs: '16-primary-fund-raising-round-bool',
        isNeeded: true,
        needsValue: true,
        hint: 'Choose something',
        props: {
          classes: 'col-start-1 grid-col-12',
          openDirection: 'bottom',
          endDate: ''
        }
      },

      {
        fieldId: 'greighrehbrbkr',
        name: i18n.global.t('events.elements.choice'),
        label: i18n.global.t('events.elements.choice'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.choice'),
        needs: '15fiwgefhirgjrht',
        needsValue: true,
        isNeeded: true,
        isUI: true,
        props: {
          choices: [
            {
              id: 1,
              name: i18n.global.t('events.elements.grantDate'),
              value: 'grantDate'
            },
            {
              id: 2,
              name: i18n.global.t('events.elements.employmentDate'),
              value: 'employmentDate'
            },
            {
              id: 3,
              name: i18n.global.t('events.elements.freeDate'),
              value: 'date'
            }
          ]
        }
      },
      //TODO: Specific component when we'll get grant date and employment date
      {
        fieldId: '1dewqrwkrjgho4ghjwohiqgiyi34ng1',
        name: 'startVesting',
        label: i18n.global.t('events.elements.startVesting'),
        component: 'date-generic',
        type: 'date',
        hint: "Quelle est la date d'inscription",
        grid: 'col-span-3',
        needs: '15fiwgefhirgjrht',
        needsValue: true,
        props: {
          label: true
        }
      },
      {
        fieldId: '22222',
        table: true,
        tableId: 'table-primary',
        grid: 'col-start-1 col-span-6',
        needs: 'titles',
        label: i18n.global.t('events.labels.tableHeader'),
        rows: [
          {
            fieldId: '14',
            name: 'contactId',
            label: i18n.global.t('events.elements.subscriber'),
            component: 'contact-generic',
            type: 'string',
            typeField: 'text',
            hint: 'Subscriber',
            width: '10%',
            custom: true,
            customDefault: true,
            isMandatory: true,
            props: {
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: '16',
            name: 'quantity',
            label: i18n.global.t('events.elements.quantity'),
            component: 'input-generic',
            type: 'number',
            width: '5%',
            isMandatory: true,
            hint: i18n.global.t('events.elements.quantity'),
            custom: true,
            customDefault: true
          },
          {
            fieldId: '19-unit-price',
            name: 'unitPrice',
            label: i18n.global.t('events.elements.unitPrice'),
            component: 'input-generic',
            labelInfo: i18n.global.t('events.tooltips.subscriptionPrice'),
            type: 'number',
            typeField: 'float',
            width: '5%',
            hint: i18n.global.t('events.elements.unitPrice'),
            custom: true,
            isMandatory: true,
            customDefault: true,
            isCalculable: true,
            props: {
              step: 0.01
            }
          },
          {
            fieldId: '17-primary-form',
            name: 'totalPrice',
            label: i18n.global.t('events.elements.total'),
            component: 'input-generic',
            type: 'number',
            width: '5%',
            typeField: 'float',
            hint: i18n.global.t('events.elements.total'),
            custom: true,
            customDefault: true,
            isCalculable: true,
            isMandatory: true,
            props: {
              step: 0.01,
              min: 0
            }
          },
          {
            fieldId: '18-primary-form',
            name: 'releasedAmount',
            label: i18n.global.t('events.elements.totalFreed'),
            component: 'input-generic',
            type: 'number',
            typeField: 'float',
            width: '5%',
            hint: i18n.global.t('events.elements.totalFreed'),
            custom: true,
            customDefault: true,
            isCalculable: true,
            props: {
              step: 0.01
            }
          },

          {
            fieldId: '20',
            name: 'issuancePremiumPerAction',
            label: i18n.global.t('events.elements.emitPerAction'),
            component: null,
            type: 'number',
            typeField: 'float',
            width: '5%',
            hint: i18n.global.t('events.elements.emitPerAction'),
            custom: true,
            isCalculated: true,
            isFilterable: true
          },
          {
            fieldId: '21',
            name: 'totalIssuancePremium',
            label: i18n.global.t('events.elements.emitTotal'),
            component: null,
            type: 'number',
            typeField: 'float',
            width: '5%',
            hint: i18n.global.t('events.elements.emitTotal'),
            custom: true,
            isCalculated: true,
            isFilterable: true
          },

          {
            fieldId: '22',
            name: 'nominalIncrease',
            label: i18n.global.t('events.elements.augNominal'),
            component: null,
            type: 'number',
            typeField: 'float',
            width: '5%',
            hint: 'Nominal Augmentation',
            custom: true,
            isCalculated: true,
            isFilterable: true
          },
          {
            fieldId: '1055637978843',
            name: 'entryDate',
            label: i18n.global.t('events.elements.entryDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.entryDate'),
            grid: '',
            width: '15%',
            isFilterable: true,
            custom: true,
            customDefault: true,
            props: {
              label: false
            }
          }
        ]
      }
    ]
  },
  {
    eventId: EventType.optionIssuance,
    name: 'options',
    primitives: [
      {
        fieldId: '10',
        name: 'issuanceDate',
        label: i18n.global.t('events.elements.emissionDate'),
        component: 'date-generic',
        type: 'date',
        hint: "Quelle est la date d'emission",
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        isNeeded: true,
        props: {
          registerDateDepends: true,
          boolFieldId: '13-isEqualToRegisterDate',
          label: true
        }
      },
      {
        fieldId: '13',
        name: 'delegationBool',
        label: i18n.global.t('events.elements.delegation-bool'),
        component: 'checkbox-generic',
        type: 'boolean',
        grid: 'col-start-1 col-span-3 mt-0 min-h-10',
        isNeeded: true,
        classes: '',
        hint: 'Utilisation Delegation',
        props: { classes: '' }
      },
      {
        fieldId: '1456768',
        name: 'delegationId',
        label: i18n.global.t('events.elements.delegation'),
        component: 'dropdown-delegations',
        type: 'string',
        typeField: 'text',
        needs: '13',
        isNeeded: true,
        needsValue: true,
        hint: 'Choose something',
        props: {
          classes: 'col-start-1 grid-col-12',
          openDirection: 'bottom',
          type: 'optionAlias'
        }
      },
      {
        fieldId: '15-titles',
        name: 'titles',
        label: i18n.global.t('events.elements.option'),
        component: 'dropdown-options',
        type: 'string',
        typeField: 'text',
        hint: 'titles',
        grid: 'col-start-1 col-span-3',
        isMandatory: true,
        props: {
          openDirection: 'bottom',
          categoryId: null,
          label: true,
          displayAll: true,
          disabled: false,
          optionPerPrice: true,
          classes: 'flex items-center h-10 grid-col-12 col-start-1'
        }
      },
      {
        fieldId: 'option-exercisePrice',
        name: 'exercisePrice',
        label: i18n.global.t('events.elements.startVesting'),
        component: 'text', //non existant component
        type: 'string',
        typeField: 'text',
        hint: "Quelle est la date d'inscription",
        grid: 'col-span-3',
        isMandatory: false,
        props: {
          label: true
        }
      },
      {
        fieldId: '16-profit-sharing-bool',
        name: 'profitSharingPlanBool',
        label: i18n.global.t('events.elements.useProfitSharingPlan'),
        component: 'checkbox-generic',
        type: 'boolean',
        grid: 'col-start-1 col-span-3 mt-0 min-h-10',
        classes: '',
        isNeeded: true,
        props: { classes: 'mt-0' }
      },

      {
        fieldId: '14567dededed68',
        name: 'profitSharingPlanId',
        label: i18n.global.t('events.elements.profitSharingPlan'),
        component: 'DropdownProfitSharingPlans',
        type: 'string',
        typeField: 'text',
        needs: '16-profit-sharing-bool',
        isNeeded: true,
        needsValue: true,
        hint: 'Choose something',
        props: {
          classes: 'col-start-1 grid-col-12',
          openDirection: 'bottom',
          endDate: ''
        }
      },
      {
        fieldId: '16-options-fund-raising-round-bool',
        name: 'financingRoundBool',
        label: i18n.global.t('events.elements.useFundRaisingRound'),
        component: 'checkbox-generic',
        type: 'boolean',
        grid: 'col-start-1 col-span-3 mt-0 min-h-10',
        classes: '',
        isNeeded: true,
        props: { classes: 'mt-0' }
      },
      {
        fieldId: '166-fund-raising-round',
        name: 'financingRoundId',
        label: i18n.global.t('events.elements.fundRaisingRound'),
        component: 'dropdown-fund-raising-rounds',
        type: 'string',
        typeField: 'text',
        needs: '16-options-fund-raising-round-bool',
        isNeeded: true,
        needsValue: true,
        hint: 'Choose something',
        props: {
          classes: 'col-start-1 grid-col-12',
          openDirection: 'bottom',
          endDate: ''
        }
      },

      {
        fieldId: '222eeee2442422',
        table: true,
        tableId: 'table-options',
        grid: 'col-start-1 col-span-6',
        label: i18n.global.t('events.labels.tableHeader'),
        needs: 'titles',
        rows: [
          {
            fieldId: '14234-contact-primary-options',
            name: 'contactId',
            label: i18n.global.t('events.elements.subscriber'),
            component: 'contact-generic',
            type: 'string',
            typeField: 'text',
            hint: 'Subscriber',
            custom: true,
            width: '10%',
            customDefault: true,
            isMandatory: true,
            props: {
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: '1vbsdigbsrngrsg6',
            name: 'quantity',
            label: i18n.global.t('events.elements.quantity'),
            component: 'input-generic',
            type: 'number',
            hint: i18n.global.t('events.elements.quantity'),
            custom: true,
            min: 1,
            isMandatory: true,
            customDefault: true
          },
          {
            fieldId: '4559-option-unit-price',
            name: 'unitPrice',
            label: i18n.global.t('events.elements.subPrice'),
            component: 'input-generic',
            type: 'number',
            typeField: 'float',
            labelInfo: i18n.global.t('events.tooltips.subscriptionPrice'),
            hint: i18n.global.t('events.elements.unitPrice'),
            custom: true,
            isCalculable: true,
            customDefault: true,
            props: {}
          },
          {
            fieldId: '1feygfeu7',
            name: 'totalPrice',
            label: i18n.global.t('events.elements.total'),
            component: 'input-generic',
            type: 'number',
            typeField: 'float',
            hint: 'Total',
            isCalculable: true,
            custom: true,
            customDefault: true,
            props: {
              step: 0.01
            }
          },
          {
            fieldId: '1dweiuhoignweg8',
            name: 'releasedAmount',
            label: i18n.global.t('events.elements.totalFreed'),
            component: 'input-generic',
            type: 'number',
            typeField: 'float',
            hint: i18n.global.t('events.elements.totalFreed'),
            isCalculable: true,
            custom: true,
            customDefault: true,
            props: {
              step: 0.01
            }
          },

          {
            fieldId: '2dqidbnwqlkdfm0',
            name: 'exercisePrice',
            label: i18n.global.t('events.elements.exercisePrice'),
            component: null,
            type: 'number',
            typeField: 'float',
            hint: i18n.global.t('events.elements.exercisePrice'),
            custom: true,
            isFilterable: false,
            isCalculated: true
          },

          {
            fieldId: '142ewfewfewfwefww3454',
            name: 'vestingId',
            label: i18n.global.t('events.elements.vesting'),
            component: 'dropdown-vesting',
            type: 'string',
            typeField: 'text',
            hint: 'Vesting',
            width: '15%',
            custom: true,
            isMandatory: true,
            customDefault: true,
            props: {
              openDirection: 'bottom'
            }
          },

          {
            fieldId: 'sfiyegwr8erhgeripogjrehjrphjtr',
            name: 'vestingStartDate',
            label: i18n.global.t('events.elements.startVesting'),
            component: 'date-generic',
            type: 'date',
            width: '10%',
            custom: true,
            customDefault: true,
            isMandatory: true,
            props: {
              label: false
            }
          },

          {
            fieldId: '10-register-date-opt',
            name: 'entryDate',
            label: i18n.global.t('events.elements.entryDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.entryDate'),
            grid: '',
            isFilterable: true,
            custom: true,
            customDefault: true,
            props: {
              label: false
            }
          }
        ]
      }
    ]
  },
  {
    eventId: EventType.compositionIssuance,
    name: 'composition',
    primitives: [
      {
        fieldId: '10',
        name: 'issuanceDate',
        label: i18n.global.t('events.elements.emissionDate'),
        component: 'date-generic',
        type: 'date',
        hint: "Quelle est la date d'emission",
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        isNeeded: true,
        props: {
          registerDateDepends: true,
          boolFieldId: '13-isEqualToRegisterDate',
          label: true
        }
      },
      {
        fieldId: '13',
        name: 'delegationBool',
        label: i18n.global.t('events.elements.delegation-bool'),
        component: 'checkbox-generic',
        type: 'boolean',
        grid: 'col-start-1 col-span-3 mt-0 min-h-10',
        isNeeded: true,
        hint: 'Utilisation Delegation',
        props: { classes: 'mt-0' }
      },
      {
        fieldId: '1456768',
        name: 'delegationId',
        label: i18n.global.t('events.elements.delegation'),
        component: 'dropdown-delegations',
        type: 'string',
        typeField: 'text',
        needs: '13',
        isNeeded: true,
        needsValue: true,
        hint: 'Choose something',
        props: {
          type: 'compositionAlias',
          classes: 'col-start-1 grid-col-12',
          openDirection: 'bottom'
        }
      },
      {
        fieldId: '15-titles',
        name: 'titles',
        label: i18n.global.t('events.elements.titles'),
        component: 'dropdown-composition',
        type: 'string',
        typeField: 'text',
        hint: 'titles',
        grid: 'col-start-1 col-span-3',
        isMandatory: true,
        props: {
          openDirection: 'bottom',
          categoryId: null,
          label: true,
          disabled: false,
          optionPerPrice: false,
          classes: 'flex items-center h-10 grid-col-12 col-start-1'
        }
      },
      {
        fieldId: '16-composition-fund-raising-round-bool',
        name: 'financingRoundBool',
        label: i18n.global.t('events.elements.useFundRaisingRound'),
        component: 'checkbox-generic',
        type: 'boolean',
        grid: 'col-start-1 col-span-3 mt-0 min-h-10',
        classes: '',
        isNeeded: true,
        props: { classes: 'mt-0' }
      },

      {
        fieldId: '166-fund-raising-round',
        name: 'financingRoundId',
        label: i18n.global.t('events.elements.fundRaisingRound'),
        component: 'dropdown-fund-raising-rounds',
        type: 'string',
        typeField: 'text',
        needs: '16-composition-fund-raising-round-bool',
        isNeeded: true,
        needsValue: true,
        hint: 'Choose something',
        props: {
          classes: 'col-start-1 grid-col-12',
          openDirection: 'bottom',
          endDate: ''
        }
      },
      {
        fieldId: '322222',
        table: true,
        tableId: 'table-composition',
        grid: 'col-start-1 col-span-6',
        label: i18n.global.t('events.labels.tableHeader'),
        needs: 'titles',
        rows: [
          {
            fieldId: '1456666',
            name: 'contactId',
            label: i18n.global.t('events.elements.subscriber'),
            component: 'contact-generic',
            type: 'string',
            typeField: 'text',
            hint: 'Subscriber',
            custom: true,
            customDefault: true,
            isMandatory: true,
            props: {
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: '1646564767',
            name: 'quantity',
            label: i18n.global.t('events.elements.quantity'),
            component: 'input-generic',
            type: 'number',
            hint: i18n.global.t('events.elements.quantity'),
            custom: true,
            customDefault: true
          },
          {
            fieldId: 'composition-unit-price-44',
            name: 'unitPrice',
            label: i18n.global.t('events.elements.unitPrice'),
            component: 'input-generic',
            labelInfo: i18n.global.t('events.tooltips.subscriptionPrice'),
            type: 'number',
            typeField: 'float',
            hint: i18n.global.t('events.elements.unitPrice'),
            custom: true,
            isCalculable: true,
            customDefault: true
          },
          {
            fieldId: '1vhkukyewqr7',
            name: 'totalPrice',
            label: i18n.global.t('events.elements.total'),
            component: 'input-generic',
            type: 'number',
            typeField: 'float',
            hint: 'Total',
            custom: true,
            customDefault: true,
            isCalculable: true,
            props: {
              step: 0.01
            }
          },
          {
            fieldId: '1gtrutilas8',
            name: 'releasedAmount',
            label: i18n.global.t('events.elements.totalFreed'),
            component: 'input-generic',
            type: 'number',
            typeField: 'float',
            hint: 'Total freed',
            custom: true,
            customDefault: true,
            isCalculable: true,
            props: {
              step: 0.01
            }
          },

          {
            fieldId: '2greiguhnaodjq0',
            name: 'issuancePremiumPerAction',
            label: i18n.global.t('events.elements.emitPerAction'),
            component: null,
            type: 'number',
            typeField: 'float',
            hint: 'Emit per action',
            custom: true,
            isCalculated: true,
            isFilterable: true
          },
          {
            fieldId: '234-total-issuance-rem-comp-primary',
            name: 'totalIssuancePremium',
            label: i18n.global.t('events.elements.emitTotal'),
            component: null,
            type: 'number',
            typeField: 'float',
            hint: 'Emit total',
            custom: true,
            isCalculated: true,
            isFilterable: true
          },
          {
            fieldId: '235-total-nominal-increase-comp-primary',
            name: 'nominalIncrease',
            label: i18n.global.t('events.elements.augNominal'),
            component: null,
            type: 'number',
            typeField: 'float',
            hint: i18n.global.t('events.elements.augNominal'),
            isFilterable: true,
            custom: true,
            isCalculated: true
          },
          {
            fieldId: '10-register-date-comp',
            name: 'entryDate',
            label: i18n.global.t('events.elements.entryDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.entryDate'),
            // isMandatory: true,
            grid: '',
            isFilterable: true,
            custom: true,
            customDefault: true,
            props: {
              label: false
            }
          }
        ]
      }
    ]
  },
  {
    eventId: EventType.appointmentTermination,
    name: 'officeTermination',
    primitives: [
      {
        fieldId: '1-o-t',
        name: 'appointmentId',
        label: i18n.global.t('events.labels.appointment'),
        component: 'dropdown-appointments',
        type: 'string',
        typeField: 'text',
        hint: i18n.global.t('events.labels.appointmentPlaceholder'),
        width: '15%',
        custom: true,
        customDefault: true,
        isMandatory: true,
        props: {
          allExcludesId: { value: [], currentIndex: -1 },
          openDirection: 'bottom'
        }
      },
      {
        fieldId: '3-o-t',
        name: 'endDate',
        label: i18n.global.t('events.elements.endDate'),
        component: 'date-generic',
        type: 'date',
        hint: "Quelle est la date d'emission",
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        props: {
          label: true
        }
      },
      {
        fieldId: '5-o-t',
        name: 'reason',
        label: i18n.global.t('events.elements.reason'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.reasonChoise'),
        grid: 'col-start-1 col-span-3',
        classes: 'mt-1',
        isMandatory: true,
        props: {
          choices: Object.values(AppointmentTerminationReason).map((x) => ({
            name: i18n.global.t(`appointmentTerminationReasons.${x}`),
            value: x,
            id: x,
            class: `tag-reason tag-reason-${x.toLowerCase()}`
          }))
        }
      }
    ]
  },
  {
    eventId: EventType.optionExercise,
    name: 'exercise',
    primitives: [
      {
        fieldId: '10',
        name: 'issuanceDate',
        label: i18n.global.t('events.elements.exerciseDate'),
        component: 'date-generic',
        type: 'date',
        hint: "Quelle est la date d'emission",
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        isNeeded: true,
        props: {
          registerDateDepends: true,
          label: true
        }
      },
      {
        fieldId: '222233',
        table: true,
        tableId: 'table-exercise',
        grid: 'col-start-1 col-span-6',
        label: i18n.global.t('events.labels.tableHeader'),
        rows: [
          {
            fieldId: '14',
            name: 'contactId',
            label: i18n.global.t('events.elements.subscriber'),
            component: 'contact-generic',
            type: 'string',
            typeField: 'text',
            hint: 'Subscriber',
            width: '15%',
            custom: true,
            customDefault: true,
            isMandatory: true,
            props: {
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: 'exercise-option-22',
            name: 'optionAliasId',
            label: i18n.global.t('events.elements.options'),
            component: 'dropdown-options',
            type: 'string',
            typeField: 'enum',
            hint: 'titles',
            needs: 'odqyfwemweofgnng',
            needsValue: 1,
            custom: true,
            customDefault: true,
            isMandatory: true,
            width: '15%',
            props: {
              dataFromExtra: true,
              openDirection: 'bottom',
              displayAll: false,
              mode: 'single',
              canAddNew: false
            }
          },
          {
            fieldId: '16',
            name: 'quantity',
            label: i18n.global.t('events.elements.quantity'),
            component: 'input-generic',
            type: 'number',
            width: '5%',
            hint: i18n.global.t('events.elements.quantity'),
            custom: true,
            customDefault: true
          },
          {
            fieldId: '17-primary-form',
            name: 'issuedQuantity',
            label: i18n.global.t('events.elements.total'),
            component: 'input-generic',
            type: 'number',
            width: '5%',
            // labelInfo: i18n.global.t('events.tooltips.issuedQuantity'),
            hint: i18n.global.t('events.elements.issuedQuantity'),
            custom: true,
            customDefault: true,
            isCalculable: true,
            props: {
              step: 1,
              min: 0
            }
          },
          {
            fieldId: '19-unit-price',
            name: 'unitPrice',
            label: i18n.global.t('events.elements.unitPrice'),
            component: 'input-generic',
            type: 'number',
            labelInfo: i18n.global.t('events.tooltips.subscriptionPrice'),
            typeField: 'float',
            hint: i18n.global.t('events.elements.unitPrice'),
            custom: true,
            customDefault: true,
            isCalculable: true,
            props: {
              step: 0.01,
              disabled: true
            }
          },
          {
            fieldId: '17-primary-form',
            name: 'totalPrice',
            label: i18n.global.t('events.elements.total'),
            component: 'input-generic',
            type: 'number',
            width: '5%',
            typeField: 'float',
            hint: i18n.global.t('events.elements.total'),
            custom: true,
            customDefault: true,
            isCalculable: true,
            props: {
              step: 0.01,
              min: 0
            }
          },

          {
            fieldId: '20',
            name: 'issuancePremiumPerAction',
            label: i18n.global.t('events.elements.emitPerAction'),
            component: null,
            type: 'number',
            typeField: 'float',
            width: '5%',
            hint: i18n.global.t('events.elements.emitPerAction'),
            custom: true,
            isCalculated: true,
            isFilterable: true
          },
          {
            fieldId: '21',
            name: 'totalIssuancePremium',
            label: i18n.global.t('events.elements.emitTotal'),
            component: null,
            type: 'number',
            typeField: 'float',
            hint: i18n.global.t('events.elements.emitTotal'),
            custom: true,
            isCalculated: true,
            isFilterable: true
          },

          {
            fieldId: '22',
            name: 'nominalIncrease',
            label: i18n.global.t('events.elements.augNominal'),
            component: null,
            type: 'number',
            typeField: 'float',
            hint: 'Nominal Augmentation',
            custom: true,
            isCalculated: true,
            isFilterable: true
          },
          {
            fieldId: '1055637978843',
            name: 'entryDate',
            label: i18n.global.t('events.elements.entryDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.entryDate'),
            grid: '',
            width: '15%',
            isFilterable: true,
            custom: true,
            customDefault: true,
            props: {
              label: false
            }
          }
        ]
      }
    ]
  },
  {
    eventId: EventType.transfer,
    name: 'transfer',
    primitives: [
      {
        fieldId: '10009988',
        name: 'transferDate',
        label: i18n.global.t('events.elements.transferDate'),
        component: 'date-generic',
        type: 'date',
        hint: 'Cession Date',
        isMandatory: true,
        grid: 'col-span-3',
        props: {
          label: true
        }
      },
      {
        fieldId: 'transfert-reason-67774',
        name: 'reason',
        label: i18n.global.t('events.elements.motive'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.motive'),
        isNeeded: false,
        custom: true,
        customDefault: true,
        grid: '',
        classes: 'col-start-1 col-span-2',
        props: {
          classes: '',
          initialValue: TransferReason.transfer,
          choices: [
            ...Object.values(TransferReason).map((x) => ({
              id: x,
              name: i18n.global.t(`TransferReason.${x}`),
              field: 'reason',
              defaultValue: null,
              value: x
            }))
          ]
        }
      },
      {
        fieldId: '22222',
        table: true,
        tableId: 'table-transfer',
        grid: 'col-start-1 col-span-6',
        label: i18n.global.t('events.labels.tableHeader'),
        rows: [
          {
            fieldId: '458-transfer-seller',
            name: 'sellerId',
            label: i18n.global.t('events.elements.sellerId'),
            component: 'contact-generic',
            type: 'string',
            typeField: 'text',
            hint: 'Subscribe',
            custom: true,
            customDefault: true,
            isMandatory: true,
            props: {
              canAddNew: false,
              excludesId: { value: [], index: -1 },
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: '1433-transfer-subscriber',
            name: 'transfereeId',
            label: i18n.global.t('events.elements.transfereeId'),
            component: 'contact-generic',
            type: 'string',
            typeField: 'text',
            hint: 'Subscriber',
            custom: true,
            customDefault: true,
            isMandatory: true,
            props: {
              excludesId: { value: [], index: -1 }
            }
          },
          {
            fieldId: '15-transfer-stockid',
            name: 'stockAliasId',
            label: i18n.global.t('events.elements.titles'),
            component: 'stock-generic',
            hint: 'titles',
            type: 'string',
            typeField: 'text',
            custom: true,
            customDefault: true,
            props: {
              openDirection: 'bottom',
              optionPerPrice: false
            }
          },
          {
            fieldId: '16',
            name: 'quantity',
            label: i18n.global.t('events.elements.quantity'),
            component: 'input-generic',
            type: 'number',
            hint: i18n.global.t('events.elements.quantity'),
            custom: true,
            customDefault: true
          },
          {
            fieldId: '19',
            name: 'unitPrice',
            label: i18n.global.t('events.elements.unitPrice'),
            component: 'input-generic',
            labelInfo: i18n.global.t('events.tooltips.subscriptionPrice'),
            type: 'number',
            typeField: 'float',
            hint: 'Unit price',
            isNullable: true,
            custom: true,
            customDefault: true
          },
          {
            fieldId: '17',
            name: 'totalPrice',
            label: i18n.global.t('events.elements.total'),
            component: 'input-generic',
            type: 'number',
            typeField: 'float',
            hint: i18n.global.t('events.elements.total'),
            custom: true,
            customDefault: true,
            isCalculable: true,
            props: {
              step: 0.01
            }
          },
          {
            fieldId: 'transfert-ownership-67774',
            name: 'ownershipType',
            label: i18n.global.t('events.elements.ownershipType'),
            component: 'dropdown-generic',
            type: 'string',
            typeField: 'enum',
            hint: i18n.global.t('events.elements.ownershipType'),
            isNeeded: false,
            custom: true,
            customDefault: true,
            grid: '',
            classes: '',
            props: {
              classes: '',
              initialValue: CoOwnershipType.fullOwnership,
              options: [
                ...Object.values(CoOwnershipType).map((x) => ({
                  id: x,
                  name: i18n.global.t(`CoOwnershipType.${x}`),
                  field: 'ownershipType',
                  defaultValue: null,
                  value: x
                }))
              ]
            }
          },

          {
            fieldId: '1444-transfer',
            name: 'entryDate',
            label: i18n.global.t('events.elements.entryDate'),
            component: 'date-generic',
            type: 'date',
            hint: i18n.global.t('events.elements.entryDate'),
            grid: '',
            custom: true,
            customDefault: true,
            isFilterable: true,
            props: {
              label: false
            }
          }
        ]
      }
    ]
  },
  {
    eventId: EventType.nominalValue,
    name: 'nominal',
    primitives: [
      {
        fieldId: '11344',
        name: 'date',
        label: i18n.global.t('events.elements.date'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-span-3',
        isMandatory: true,
        props: {
          label: true
        }
      },

      {
        fieldId: '12243777',
        name: 'price',
        label: i18n.global.t('events.elements.price'),
        component: 'input-generic',
        type: 'number',
        typeField: 'number',
        isMandatory: true,
        classes: '',
        grid: 'col-start-1 col-span-3 ',
        hint: i18n.global.t('events.elements.price'),
        props: {
          label: true
        }
      },
      {
        fieldId: '13',
        name: 'byParity',
        label: i18n.global.t('events.elements.byParity'),
        component: 'checkbox-generic',
        type: 'boolean',
        grid: 'col-start-1 col-span-3 mt-0 min-h-10',
        classes: '',
        props: { classes: 'mt-0' }
      },
      {
        fieldId: '14',
        name: 'isConstantCapital',
        label: i18n.global.t('events.elements.isConstantCapital'),
        component: 'checkbox-generic',
        type: 'boolean',
        grid: 'col-start-1 col-span-3 mt-0 min-h-10',
        classes: '',
        props: { classes: 'mt-0' }
      }
    ]
  },
  {
    eventId: EventType.commongCeiling,
    name: 'commonCeiling',
    primitives: [
      {
        fieldId: '1780fwifbwegf560565',
        name: 'label',
        label: i18n.global.t('events.elements.label'),
        component: 'input-generic',
        type: 'string',
        typeField: 'text',
        isMandatory: true,
        hint: i18n.global.t('events.elements.label'),
        props: {
          label: true
        }
      },
      {
        fieldId: '50946869054-',
        name: 'date',
        label: i18n.global.t('events.elements.date'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-start-1 col-span-3',
        isMandatory: true,
        props: {
          label: true
        }
      },
      {
        fieldId: '124545353487564356',
        name: 'quantity',
        label: i18n.global.t('events.elements.quantity'),
        component: 'input-generic',
        type: 'number',
        classes: '',
        typeField: 'number',
        isMandatory: true,
        hint: i18n.global.t('events.elements.quantity'),
        props: {
          label: true,
          classes: 'h-11',
          class: 'h-11'
        }
      }
    ]
  },
  {
    eventId: EventType.delegation,
    name: 'delegation',
    primitives: [
      {
        fieldId: 'label-sifygerhoi4gj34955',
        name: 'label',
        label: i18n.global.t('events.elements.label'),
        component: 'input-generic',
        type: 'string',
        typeField: 'text',
        isMandatory: true,
        classes: 'col-start-1 col-span-3',
        hint: i18n.global.t('events.elements.label'),
        props: {
          label: true
        }
      },
      {
        fieldId: '15fdhdwdwdf',
        name: 'categoryId',
        label: i18n.global.t('events.elements.stockCategory'),
        component: 'dropdown-stock-categories',
        type: 'string',
        typeField: 'text',
        isNeeded: true,
        hint: 'titles',
        grid: 'col-start-1 col-span-3',
        isMandatory: true,
        custom: true,
        initChildrenOnChange: true,
        customDefault: true,
        props: {
          openDirection: 'bottom'
        }
      },
      {
        fieldId: '11-apply-revolving-bool',
        name: 'applyRevolving',
        label: i18n.global.t('events.elements.applyRevolving'),
        component: 'checkbox-generic',
        type: 'boolean',
        needs: '15fdhdwdwdf',
        needsValue: (value: string) => value === 'option',
        classes: 'col-start-1 col-span-3 mt-0 min-h-10',
        props: {
          classes: 'mt-4 col-start-1 col-span-3 min-h-10'
        }
      },
      {
        fieldId: '1134434435',
        name: 'date',
        label: i18n.global.t('events.elements.startDate'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-start-1 col-span-3',
        isMandatory: true,
        props: {
          label: true
        }
      },
      {
        fieldId: '12243723423477',
        name: 'endDate',
        label: i18n.global.t('events.elements.endDate'),
        component: 'duration-generic',
        type: 'date',
        isMandatory: true,
        hint: 'Date',
        grid: 'col-start-1 col-span-3',
        props: {
          needs: 'date'
        }
      },
      {
        fieldId: '1456fwefkrejgrfwelfnenegnergr768',
        name: 'commonCeilingId',
        label: i18n.global.t('events.elements.ceiling'),
        component: 'dropdown-ceilings',
        type: 'string',
        typeField: 'text',
        grid: 'col-start-1 col-span-3',
        hint: i18n.global.t('events.elements.ceiling'),
        props: {
          openDirection: 'bottom'
        }
      },
      {
        fieldId: '11-delegation-max-quantity-field',
        name: 'quantity',
        label: i18n.global.t('events.elements.maxQuantity'),
        component: 'input-generic',
        type: 'string',
        typeField: 'text',
        classes: '',
        requiredIf: [
          'commonCeilingId',
          (val: string) => val == null || val == ''
        ],
        grid: 'col-start-1 col-span-3',
        hint: i18n.global.t('events.elements.maxQuantity'),
        props: {
          label: true
        }
      }
    ]
  },
  {
    eventId: EventType.vestingSuspension,
    name: 'suspension',
    primitives: [
      {
        fieldId: '11344girteggonr34435',
        name: 'startDate',
        label: i18n.global.t('events.elements.startDate'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-span-3',
        isMandatory: true,
        props: { label: true }
      },
      {
        fieldId: 'susEndDate',
        name: 'endDate',
        label: i18n.global.t('events.elements.endDate'),
        component: 'duration-generic',
        type: 'date',
        hint: i18n.global.t('events.elements.endDatePlaceholder'),
        grid: 'col-start-1 col-span-3',
        props: {
          needs: 'startDate',
          label: true
        }
      },
      {
        fieldId: 'suspension-type-value',
        name: 'choice',
        label: i18n.global.t('events.elements.suspensionType'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.suspensionType'),
        isNeeded: true,
        grid: 'col-start-1 col-span-3',
        classes: '',
        props: {
          classes: '',
          choices: [
            {
              id: 1,
              name: i18n.global.t('events.elements.stocks'),
              field: 'optionAliasId',
              defaultValue: null,
              value: 'stocks'
            },
            {
              id: 2,
              name: i18n.global.t('events.elements.contacts'),
              field: 'contactsId',
              defaultValue: null,
              value: 'contact'
            }
          ]
        }
      },
      {
        fieldId: 'optionAliasId-suspension',
        name: 'optionAliasId',
        label: i18n.global.t('events.elements.titles'),
        component: 'dropdown-options',
        type: 'array',
        typeField: 'enum',
        hint: 'titles',
        needs: 'suspension-type-value',
        needsValue: 1,
        props: {
          openDirection: 'bottom',
          optionPerPrice: false,
          mode: 'tags'
        }
      },
      {
        fieldId: 'contactsId-fdoqwfhwfmwdedegpg378493kdeiiide',
        name: 'contactsId',
        label: i18n.global.t('events.elements.contacts'),
        component: 'contact-generic',
        type: 'array',
        typeField: 'enum',
        hint: 'Contact',
        needs: 'suspension-type-value',
        needsValue: 2,
        props: {
          classes: 'grid-4',
          mode: 'tags',
          openDirection: 'bottom'
        }
      }
    ]
  },
  {
    eventId: EventType.vestingAcceleration,
    name: 'acceleration',
    primitives: [
      {
        fieldId: 'gfgerigbergioernv',
        name: 'startDate',
        label: i18n.global.t('events.elements.effectDate'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-span-3',
        isMandatory: true,
        props: { label: true }
      },
      {
        fieldId: 'acceleration-type-value',
        name: 'choice',
        label: i18n.global.t('events.elements.suspensionType'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'string',
        hint: i18n.global.t('events.elements.choice'),
        isNeeded: true,
        grid: 'col-start-1 col-span-3',
        class: '',
        props: {
          label: true,
          choices: [
            {
              id: 1,
              name: i18n.global.t('events.elements.stocks'),
              field: 'optionAliasId',
              defaultValue: null,
              value: 'stocks'
            },
            {
              id: 2,
              name: i18n.global.t('events.elements.contact'),
              field: 'contactsId',
              defaultValue: null,
              value: 'contact'
            }
          ]
        }
      },
      {
        fieldId: 'fdoqw98787fhwfmwgpg378493',
        name: 'optionAliasId',
        label: i18n.global.t('events.elements.titles'),
        component: 'dropdown-options',
        type: 'array',
        typeField: 'enum',
        hint: 'titles',
        needs: 'acceleration-type-value',
        needsValue: 1,
        props: {
          label: true,
          canAddNew: false,
          openDirection: 'bottom',
          mode: 'tags',
          optionPerPrice: false
        }
      },
      {
        fieldId: 'vesting-acceleration-contact-field-13',
        name: 'contactsId',
        label: i18n.global.t('events.elements.contacts'),
        component: 'contact-generic',
        type: 'array',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.contacts'),
        needs: 'acceleration-type-value',
        needsValue: 2,
        isNeeded: true,
        props: {
          classes: 'grid-4',
          label: true,
          openDirection: 'bottom',
          mode: 'tags'
        }
      },
      {
        fieldId: 'fdoqw98787fhwfmwgpg378493',
        name: 'optionAliasId',
        label: i18n.global.t('events.elements.titles'),
        component: 'dropdown-options',
        type: 'array',
        typeField: 'enum',
        hint: 'titles',
        needs: 'vesting-acceleration-contact-field-13',
        needsValue: 'string',
        props: {
          label: true,
          canAddNew: false,
          openDirection: 'bottom',
          mode: 'tags',
          optionPerPrice: false
        }
      },
      {
        fieldId: '16-percent-acceleration',
        name: 'percent',
        label: i18n.global.t('events.elements.percent'),
        component: 'input-generic',
        type: 'number',
        typeField: 'text',
        grid: 'col-start-1 col-span-3',
        min: 0,
        isMandatory: true,
        max: 100,
        hint: i18n.global.t('events.elements.percent'),
        props: { label: true }
      },
      {
        fieldId: '111-vestung-adjustType',
        name: 'adjustmentType',
        label: i18n.global.t('events.elements.accelerationType'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'text',
        hint: i18n.global.t('events.elements.accelerationType'),
        classes: 'mt-1',
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        props: {
          choices: [
            {
              id: VestingAdjustment.top,
              name: i18n.global.t(`VestingAdjustment.${VestingAdjustment.top}`)
            },
            {
              id: VestingAdjustment.bottom,
              name: i18n.global.t(
                `VestingAdjustment.${VestingAdjustment.bottom}`
              )
            },
            {
              id: VestingAdjustment.uniform,
              name: i18n.global.t(
                `VestingAdjustment.${VestingAdjustment.uniform}`
              )
            }
          ]
        }
      }
    ]
  },
  {
    eventId: EventType.exerciseDelayProrogation,
    name: 'prorogation',
    primitives: [
      {
        fieldId: '18565jgfieebggonr34435',
        name: 'startDate',
        label: i18n.global.t('events.elements.effectDate'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-span-3',
        isMandatory: true,
        props: {
          label: true
        }
      },
      {
        fieldId: 'prorogation-type-value',
        name: 'choice',
        label: i18n.global.t('events.elements.suspensionType'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        grid: 'col-start-1 col-span-3',
        class: '',
        hint: i18n.global.t('events.elements.choice'),
        isNeeded: true,
        props: {
          choices: [
            {
              id: 1,
              name: i18n.global.t('events.elements.stocks'),
              field: 'optionAliasId',
              defaultValue: null,
              value: 'stocks'
            },
            {
              id: 2,
              name: i18n.global.t('events.elements.contact'),
              field: 'contactsId',
              defaultValue: null,
              value: 'contact'
            }
          ]
        }
      },
      {
        fieldId: 'fdoqwfhwuyguwbfoiwenffmwgpg378493',
        name: 'optionAliasId',
        label: i18n.global.t('events.elements.titles'),
        component: 'dropdown-options',
        type: 'array',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.titles'),
        needs: 'prorogation-type-value',
        needsValue: 1,
        classes: 'w-full',
        props: {
          openDirection: 'bottom',
          mode: 'tags',
          optionPerPrice: false
        }
      },
      {
        fieldId: 'contactsId-008958jbffdoqwfhwfmwgpg378493',
        name: 'contactsId',
        label: i18n.global.t('events.elements.contacts'),
        component: 'contact-generic',
        type: 'array',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.contacts'),
        needs: 'prorogation-type-value',
        needsValue: 2,
        classes: '',
        class: 'w-full',
        props: {
          label: true,
          openDirection: 'bottom',
          optionPerPrice: false,
          mode: 'tags'
        }
      },
      {
        fieldId: 'proEndDate',
        name: 'endDate',
        label: i18n.global.t('events.elements.endDate'),
        component: 'duration-generic',
        type: 'date',
        grid: 'col-start-1 col-span-3',
        class: '',
        hint: i18n.global.t('events.elements.endDatePlaceholder'),
        props: {
          needs: 'startDate'
        }
      }
    ]
  },
  {
    eventId: EventType.appointment,
    name: 'nomination',
    primitives: [
      {
        fieldId: '1-nomination-contactId',
        name: 'contactId',
        label: i18n.global.t('events.elements.contact'),
        component: 'contact-generic',
        type: 'string',
        typeField: 'text',
        isMandatory: true,
        hint: i18n.global.t('events.elements.contact'),
        props: {
          label: true,
          classes: '',
          openDirection: 'bottom'
        }
      },
      {
        fieldId: '2-nomination-functionId',
        name: 'functionId',
        label: i18n.global.t('events.elements.quality'),
        component: 'dropdown-functions',
        type: 'string',
        typeField: 'text',
        hint: 'Quality',
        grid: 'col-start-1 col-span-6',
        classes: 'col-start-1 col-span-6',
        isNeeded: true,
        isMandatory: true,
        props: {
          openDirection: 'bottom',
          inline: false,
          label: true
        }
      },
      {
        fieldId: '3-nomination-committeeId',
        name: 'committeeId',
        label: i18n.global.t('events.elements.committee'),
        component: 'dropdown-committee',
        type: 'string',
        typeField: 'text',
        needs: '2-nomination-functionId',
        needsValue: true,
        props: {
          openDirection: 'bottom',
          label: true
        }
      },
      {
        fieldId: '4-nomination-startDate',
        name: 'startDate',
        label: i18n.global.t('events.elements.startDate'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-start-1 col-span-3',
        props: {
          label: true
        }
      },
      {
        fieldId: 'nominationEndDate',
        name: 'endDate',
        label: i18n.global.t('events.elements.endMandate'),
        component: 'duration-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-start-1 col-span-3',
        props: {
          label: true,
          needs: 'startDate',
          byEvent: true,
          hasUnlimited: true
        }
      }
    ]
  },
  {
    eventId: EventType.financialEnding,
    name: 'socialEnd',
    primitives: [
      {
        fieldId: '1-socialEnd-date',
        name: 'date',
        label: i18n.global.t('events.elements.endDate'),
        component: 'input-generic',
        type: 'string',
        hint: i18n.global.t('events.elements.dayMonth'),
        props: {
          label: true,
          hint: i18n.global.t('events.elements.dayMonth')
        }
      }
    ]
  },
  {
    eventId: EventType.accountApproval,
    name: 'socialAppro',
    primitives: [
      {
        fieldId: 'date-social-appr',
        name: 'date',
        label: i18n.global.t('events.elements.date'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        props: {
          label: true
        }
      },
      {
        fieldId: 'financialEndingDate-socialAppro',
        name: 'financialEndingDate',
        label: i18n.global.t('events.elements.exercise'),
        component: 'date-generic',
        type: 'date',
        grid: 'col-start-1 col-span-3',
        hint: i18n.global.t('events.elements.exercise'),
        isMandatory: true,
        props: {
          label: true
        }
      },
      {
        fieldId: 'minMembers-fewugfwfbiwof',
        name: 'turnover',
        label: i18n.global.t('events.elements.turnover'),
        component: 'input-generic',
        type: 'number',
        grid: 'col-start-1 col-span-3',
        typeField: 'number',
        hint: i18n.global.t('events.elements.turnoverValue'),
        props: {
          label: true
        }
      },
      {
        fieldId: 'minMembers-CEGSDFEWIGFHWG',
        name: 'result',
        label: i18n.global.t('events.elements.result'),
        component: 'input-generic',
        type: 'number',
        typeField: 'number',
        isMandatory: true,
        hint: i18n.global.t('events.elements.resultValue'),
        props: {
          label: true
        }
      },
      {
        fieldId: 'earningsAllocation-appro',
        name: 'earningsAllocation',
        label: i18n.global.t('events.elements.earningsAllocation'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'string',
        hint: i18n.global.t('events.elements.choice'),
        isNeeded: true,
        isMandatory: true,
        props: {
          choices: [
            {
              id: 'RETAINED_EARNINGS',
              name: i18n.global.t('events.elements.report'),
              value: 'RETAINED_EARNINGS'
            },
            {
              id: 'DIVIDENDS',
              name: i18n.global.t('events.elements.dividendes'),
              value: 'DIVIDENDS'
            },
            {
              id: 'RESERVE',
              name: i18n.global.t('events.elements.reserve'),
              value: 'RESERVE'
            }
          ]
        }
      },
      {
        fieldId: 'dividendes-amount',
        name: 'amount',
        label: i18n.global.t('events.elements.amount'),
        component: 'input-generic',
        type: 'number',
        typeField: 'number',
        hint: i18n.global.t('events.elements.amount'),
        needs: 'earningsAllocation-appro',
        needsValue: 'DIVIDENDS',
        props: {
          label: true
        }
      },
      {
        fieldId: 'retained-earning-amount',
        name: 'amount',
        label: i18n.global.t('events.elements.amount'),
        component: 'input-generic',
        type: 'number',
        typeField: 'number',
        hint: i18n.global.t('events.elements.amount'),
        needs: 'earningsAllocation-appro',
        needsValue: 'RETAINED_EARNINGS',
        props: {
          label: true
        }
      },
      {
        fieldId: 'reserve-affectation-type-appro',
        name: 'allocationType',
        label: i18n.global.t('events.elements.allocationType'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'string',
        hint: i18n.global.t('events.elements.choice'),
        isNeeded: true,
        needs: 'earningsAllocation-appro',
        needsValue: 'RESERVE',
        props: {
          choices: [
            {
              id: 'LEGAL_RESERVE',
              name: i18n.global.t('events.elements.legalReserve'),
              value: 'LEGAL_RESERVE'
            },
            {
              id: 'STATUTORY_RESERVES',
              name: i18n.global.t('events.elements.statutoryReserves'),
              value: 'STATUTORY_RESERVES'
            },
            {
              id: 'OTHER_RESERVE',
              name: i18n.global.t('events.elements.otherReserve'),
              value: 'OTHER_RESERVE'
            }
          ]
        }
      },
      {
        fieldId: 'retained-earning-amount',
        name: 'amount',
        label: i18n.global.t('events.elements.amount'),
        component: 'input-generic',
        type: 'number',
        typeField: 'number',
        hint: i18n.global.t('events.elements.amount'),
        needs: 'reserve-affectation-type-appro',
        needsValue: 'string',
        props: {
          label: true
        }
      }
    ]
  },
  {
    eventId: EventType.commitment,
    name: 'commitment',
    primitives: [
      {
        fieldId: '1134434435',
        name: 'date',
        label: i18n.global.t('events.elements.date'),
        component: 'date-generic',
        type: 'date',
        hint: 'Date',
        grid: 'col-start-1 col-span-3',
        isMandatory: true,
        props: {
          label: true
        }
      },
      {
        fieldId: '12243723423477',
        name: 'endDate',
        label: i18n.global.t('events.elements.endDate'),
        component: 'date-generic',
        type: 'date',
        isMandatory: true,
        hint: 'Date',
        grid: 'col-start-1 col-span-3',
        props: {
          label: true
        }
      },
      {
        fieldId: 'label-sifygerhoi4gj34955',
        name: 'label',
        label: i18n.global.t('events.elements.label'),
        component: 'input-generic',
        type: 'string',
        typeField: 'text',
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        classes: 'col-start-1 col-span-3',
        hint: i18n.global.t('events.elements.label'),
        props: {
          label: true
        }
      },
      {
        fieldId: 'commitment-type',
        name: 'type',
        label: i18n.global.t('events.elements.type'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        hint: i18n.global.t('events.elements.type'),
        grid: 'col-start-1 col-span-3',
        classes: '',
        isMandatory: true,
        props: {
          classes: 'mt-0',
          choices: Object.values(CommitmentTypeEnum).map((x) => ({
            name: i18n.global.t(`CommitmentTypeEnum.${x}`),
            value: x,
            id: x
          }))
        }
      },
      {
        fieldId: 'commitment-mode',
        name: 'mode',
        label: i18n.global.t('events.elements.commitmentMode'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'text',
        hint: i18n.global.t('events.elements.commitmentMode'),
        grid: 'col-start-1 col-span-3',
        classes: '',
        isMandatory: true,
        props: {
          classes: 'mt-0',
          choices: Object.values(CommitmentMode).map((x) => ({
            name: i18n.global.t(`CommitmentMode.${x}`),
            id: x
          }))
        }
      },
      {
        fieldId: 'commitment-array',
        table: true,
        tableId: 'table-commitment',
        grid: 'col-start-1 col-span-6',
        label: i18n.global.t('events.labels.tableHeader'),
        min: 1,
        rows: [
          {
            fieldId: 'commitment-contact',
            name: 'contactId',
            label: i18n.global.t('events.elements.subscriber'),
            component: 'contact-generic',
            type: 'string',
            hint: i18n.global.t('events.elements.subscriber'),
            custom: true,
            isMandatory: true,
            customDefault: true,
            props: {
              allExcludesId: { value: [], currentIndex: -1 }
            }
          },
          {
            fieldId: 'commitment-titles',
            name: 'titles',
            label: i18n.global.t('events.elements.titles'),
            component: 'stock-generic',
            type: 'string',
            typeField: 'enum',
            hint: i18n.global.t('events.elements.titles'),
            custom: true,
            customDefault: true,
            props: {
              withoutOptions: true,
              openDirection: 'bottom',
              filterResults: false,
              optionPerPrice: false
            }
          },
          {
            fieldId: 'commitment-quantity',
            name: 'quantity',
            label: i18n.global.t('events.elements.quantity'),
            component: 'input-generic',
            type: 'number',
            typeField: 'number',
            min: 0,
            width: '5%',
            hint: i18n.global.t('events.elements.quantity'),
            custom: true,
            customDefault: true,
            props: { type: 'number' }
          },
          {
            fieldId: 'transfert-ownership-67774',
            name: 'ownershipType',
            label: i18n.global.t('events.elements.ownershipType'),
            component: 'dropdown-generic',
            type: 'string',
            typeField: 'enum',
            hint: i18n.global.t('events.elements.ownershipType'),
            isNeeded: false,
            custom: true,
            customDefault: true,
            grid: '',
            classes: '',
            props: {
              classes: '',
              initialValue: CoOwnershipType.fullOwnership,
              options: [
                ...Object.values(CoOwnershipType).map((x) => ({
                  id: x,
                  name: i18n.global.t(`CoOwnershipType.${x}`),
                  field: 'ownershipType',
                  defaultValue: null,
                  value: x
                }))
              ]
            }
          }
        ]
      }
    ]
  },
  {
    eventId: EventType.parityChange,
    name: 'parityChange',
    primitives: [
      {
        fieldId: '1055637373',
        name: 'date',
        label: i18n.global.t('events.elements.date'),
        component: 'date-generic',
        type: 'date',
        hint: i18n.global.t('events.elements.date'),
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        props: {
          label: true
        }
      },
      {
        fieldId: 'optionAliasId',
        name: 'optionAliasId',
        label: i18n.global.t('events.elements.titles'),
        component: 'dropdown-options',
        type: 'string',
        typeField: 'text',
        hint: 'titles',
        grid: 'col-start-1 col-span-3',
        classes: 'h-11',
        isMandatory: true,
        props: {
          openDirection: 'bottom',
          mode: 'single',
          canAddNew: false,
          optionPerPrice: false,
          classes: 'flex items-center h-11 grid-col-12 col-start-1'
        }
      },
      {
        fieldId: 'parity',
        name: 'parity',
        label: i18n.global.t('events.elements.parity'),
        component: 'input-generic',
        type: 'number',
        min: 0,
        typeField: 'number',
        isMandatory: true,
        grid: 'col-start-1 col-span-3',
        hint: i18n.global.t('events.elements.parity'),
        props: {
          label: true,
          classes: ''
        }
      },
      {
        fieldId: 'parity-type-field',
        name: 'parityType',
        label: i18n.global.t('events.elements.parityType'),
        component: 'dropdown-generic',
        type: 'string',
        typeField: 'enum',
        isMandatory: true,
        hint: i18n.global.t('events.elements.choice'),
        grid: 'col-start-1 col-span-3',
        props: {
          classes: '',
          choices: [
            {
              id: PARITYTYPE.VARIABLE,
              name: i18n.global.t(
                `global.${PARITYTYPE.VARIABLE.toLowerCase()}`
              ),
              value: PARITYTYPE.VARIABLE
            },
            {
              id: PARITYTYPE.FIXED,
              name: i18n.global.t(`global.${PARITYTYPE.FIXED.toLowerCase()}`),
              value: PARITYTYPE.FIXED
            }
          ]
        }
      }
    ]
  }
] as EventFormDefinition[]
