<template>
  <div>
    <h3 class="my-5 text-blackp text-left">
      {{ $t('contactBook.form.personalInformation') }}
    </h3>
    <Form
      v-if="newContact"
      v-slot="{ errors, meta, setFieldValue }"
      :validation-schema="schema"
      autocomplete="off"
      @submit="handleForm"
      @reset="handleReset"
    >
      <input id="profileImage" type="file" hidden name="profileImage" />
      <div :class="`flex ${topAlignClass} justify-between text-sm`">
        <div class="w-1/2 flex">
          <profile-avatar-form
            :profile-image="contact?.picture?.pictureURL"
            :contact-id="contact?.id"
            @file-changed="fileChanged"
          />
        </div>
        <div :class="`w-full flex ${topAlignClass ? 'mt-2' : ''}`">
          <div class="w-1/2 flex flex-col">
            <label
              class="text-xs text-secondary8 mb-1 text-left font-semibold"
              for="contactGroup"
              >{{ $t('contactBook.fields.group') }}</label
            >

            <Multiselect
              id="contactGroupsId"
              v-model="newContact.contactGroupsId"
              :value="newContact.contactGroupsId"
              :class="`w-full ${
                newContact.contactGroupsId.length > 0 ? 'min-' : ''
              }h-10`"
              mode="multiple"
              elementName="contactBook.groups"
              selectionText="global.actions.selected1"
              :tag-with-initials="false"
              name="contactGroupsId"
              :options="groups"
              :placeholder="$t('contactBook.placeholder.group')"
              :groups="false"
              @change="newContact.contactGroupsId = $event"
            />
          </div>
        </div>
      </div>

      <div class="w-full flex justify-start mt-4">
        <div :class="`w-1/4 flex flex-col`">
          <div class="flex">
            <label
              class="text-xs text-secondary8 mb-1 text-left font-semibold"
              for="name"
              >{{ $t('contactBook.fields.name') }}</label
            >
            <strong class="ml-1 text-error h-4">*</strong>
          </div>

          <Field v-slot="{ field }" v-model="newContact.name" name="name">
            <Input
              v-model="newContact.name"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="name"
              :placeholder="$t('contactBook.placeholder.name')"
            />
          </Field>
        </div>
        <div class="w-1/4 mr-2 ml-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="birthDate"
            >{{ $t('contactBook.fields.establishmentDate') }}</label
          >
          <UplDatePicker
            v-model="newContact.birthDate"
            class="hover:border-primary4 h-10 text-sm"
            :enable-time-picker="false"
            :placeholder="$t('contactBook.placeholder.establishmentDate')"
            close-on-scroll
            name="birthDate"
            @on-select="setFieldValue('birthDate', $event)"
          />
        </div>

        <div class="w-1/4 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="endDate"
            >{{ $t('contactBook.fields.endDate') }}</label
          >
          <UplDatePicker
            v-model="newContact.endDate"
            class="hover:border-primary4 h-10 text-sm"
            :enable-time-picker="false"
            :placeholder="$t('contactBook.placeholder.endDate')"
            close-on-scroll
            name="endDate"
            @on-select="setFieldValue('endDate', $event)"
          />
        </div>

        <div class="w-1/4">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="contact"
            >{{ $t('contactBook.fields.representativeContact') }}</label
          >
          <Field
            v-slot="{ field }"
            name="representativeContactId"
            v-model="newContact.representativeContactId"
          >
            <dropdown-contacts
              v-bind="field"
              :data="{
                name: 'contactId',
                placeholder: $t('contactBook.placeholder.representativeContact')
              }"
              open-direction="bottom"
              :filter-results="false"
              :model-value="newContact.representativeContactId"
              :value="newContact.representativeContactId"
              @update:modelValue="
                setFieldValue('representativeContactId', $event)
              "
            >
            </dropdown-contacts>
          </Field>
        </div>
      </div>

      <h3 class="my-5 text-blackp text-left">
        {{ $t('contactBook.fields.coOwnerPartsTitle') }}
      </h3>

      <div class="w-full flex flex-col mt-2">
        <div
          v-for="(co, index) in newContact.coOwnershipParts"
          :key="index"
          class="flex w-full"
        >
          <div class="w-1/4 mr-2">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold text-left"
              for="contact"
              >{{ $t('contactBook.fields.coheir') }}</label
            >
            <Field
              v-slot="{ field }"
              :name="`coOwnershipParts[${index}].contactId`"
              v-model="newContact.coOwnershipParts[index].contactId"
            >
              <dropdown-contacts
                :data="{ name: `coOwnershipParts[${index}].contactId` }"
                open-direction="bottom"
                :index="index"
                :filter-results="false"
                v-bind="field"
                :model-value="newContact.coOwnershipParts[index].contactId"
                :value="newContact.coOwnershipParts[index].contactId"
                @update:modelValue="($event) => updateContact(index, $event)"
              >
              </dropdown-contacts>
            </Field>
          </div>
          <div class="ml-1 w-1/4">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold text-left"
              for="fraction"
              >{{ $t('contactBook.fields.fraction') }}</label
            >
            <Field
              v-slot="{ field }"
              :name="`coOwnershipParts[${index}].fraction`"
              v-model="newContact.coOwnershipParts[index].fraction"
            >
              <Input
                v-bind="field"
                v-model="newContact.coOwnershipParts[index].fraction"
                :value="newContact.coOwnershipParts[index].fraction"
              />
            </Field>
          </div>

          <div class="flex items-center ml-2">
            <Button
              variant="custom"
              class="bg-error text-white font-semibold"
              @click="removeCoOwnership(index)"
              ><i class="la la-minus"></i
            ></Button>
          </div>
        </div>

        <div class="mt-3">
          <Button variant="primary" class="" @click="addCoOwnership"
            ><i class="la la-plus"></i
          ></Button>
        </div>
      </div>

      <FormActions
        v-if="
          $acl.role('admin') ||
          ($acl.permission('is-manager') &&
            $acl.permission('write-contacts')) ||
          $acl.permission('employee-writeContact')
        "
        :errors="errors"
        :meta="meta"
      />
    </Form>
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, computed, watch } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Form, Field } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import {
    CompanyModel,
    ContactStatus,
    CoOwnerShipContact,
    FamilySituationEnum,
    GenderEnum
  } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { CONTACTTYPE, CoOwnershipContactForm } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { coOwnershipSchema } from '@/utils/schema'
  import ProfileAvatarForm from './ProfileAvatarForm.vue'
  import { useRoute } from 'vue-router'
  import DropdownContacts from '../../DropDowns/DropdownContacts.vue'
  export default defineComponent({
    components: {
      Form,
      Field,
      Multiselect,
      Input,
      Button,
      DropdownContacts,
      UplDatePicker,
      FormActions,
      ProfileAvatarForm
    },
    props: {
      contact: {
        type: Object as () => CoOwnerShipContact | null | undefined,
        required: false,
        default: () => null
      },
      topAlignClass: { type: String, default: '' }
    },
    emits: ['submitForm', 'fileChanged', 'onCancel'],
    setup(props, { emit }) {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()

      const newValues = (): CoOwnershipContactForm => ({
        id: props.contact?.id,
        name: props.contact?.name || '',
        type: CONTACTTYPE.CO_OWNERSHIP,
        contactGroupsId:
          (props.contact?.contactGroups ?? []).map((x) => x.id) || [],
        contactStatusId: null,
        coOwnershipParts: (props.contact?.coOwnershipParts ?? []).map((x) => ({
          contactId: x.coheir?.id ?? '',
          fraction: x.fraction
        })),
        birthDate: props.contact?.birthDate
          ? props.contact?.birthDate.split('T')[0]
          : '' || null,
        endDate: props.contact?.endDate || null,
        representativeContactId: props.contact?.representativeContact?.id ?? '',
        parentsIds: props.contact?.parents?.map((x) => x.id) || []
      })

      const modalForm = computed(() => store.getters['organizations/form'])

      const newContact = ref<CoOwnershipContactForm>(newValues())
      const genders = ref(
        Object.values(GenderEnum).map((x) => ({
          name: t(`gender.${x}`),
          id: x
        }))
      )

      const allContacts = computed<any[]>(
        () => store.getters['organizations/contacts']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const contacts = computed(() =>
        allContacts.value.filter((x) => x.id !== route.params?.contactId)
      )
      const updateContact = (index: number, data: any) => {
        newContact.value.coOwnershipParts[index].contactId = data
      }
      const handleSearch = (e: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            type: CONTACTTYPE.INDIVIDUAL,
            name: e
          }
        })
      }

      const groups = computed<ContactStatus[]>(
        () => store.getters['organizations/groups']
      )

      const schema = computed(() => coOwnershipSchema)

      const handleForm = async (values: CoOwnershipContactForm) => {
        const form = {
          ...values,
          contactGroupsId: newContact.value.contactGroupsId,
          type: CONTACTTYPE.CO_OWNERSHIP,
          parentsIds: newContact.value.parentsIds
        }

        form.coOwnershipParts.map((x) => ({
          ...x,
          fraction: parseInt(x.fraction?.toString())
        }))
        emit('submitForm', form)
      }

      const addCoOwnership = () => {
        newContact.value.coOwnershipParts.push({ contactId: '', fraction: 0 })
      }

      const removeCoOwnership = (index: number) => {
        newContact.value.coOwnershipParts.splice(index, 1)

        console.log(newContact.value.coOwnershipParts)
      }
      const fileChanged = (file: File) => {
        emit('fileChanged', file)
      }

      const handleReset = () => {
        newContact.value = newValues()
        emit('onCancel')
      }

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newContact.value = newValues()
          return
        }
      })

      watch(props, (newProps) => {
        if (newProps.contact) {
          newContact.value = {
            ...newProps.contact,
            contactGroupsId: (newProps.contact.contactGroups || []).map(
              (x) => x.id
            ),
            coOwnershipParts: (newProps.contact?.coOwnershipParts ?? []).map(
              (x) => ({ contactId: x.coheir?.id ?? '', fraction: x.fraction })
            ),
            representativeContactId:
              newProps.contact?.representativeContact?.id ?? '',
            contactStatusId: newProps.contact.status?.id || '',
            parentsIds: (newProps.contact.parents || []).map((x) => x.id)
          }
        } else {
          newContact.value = newValues()
        }
      })

      return {
        newContact,
        genders,
        groups,
        schema,
        removeCoOwnership,
        handleForm,
        handleReset,
        handleSearch,
        updateContact,
        fileChanged,
        addCoOwnership,
        contacts
      }
    }
  })
</script>
