
  import { defineComponent, ref, watch } from 'vue'
  import PDFViewer from '@/components/PDFViewer/express.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { Document } from '@/services/api/models'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { truncText } from '@/utils'
  import DocumentUpdate from './DocumentUpdate.vue'
  import { ElPopover } from 'element-plus'

  export default defineComponent({
    name: 'DocPreview',
    components: {
      PDFViewer,
      ElPopover,
      EmptyState,
      DocumentUpdate
    },
    props: {
      document: {
        type: Object as () => Document,
        default: null
      },
      displayStyle: { type: String, default: '' }
    },
    emits: ['close-preview', 'update-current-page'],
    setup(props) {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const updateDisplay = ref(false)

      const fileLabel = ref('')
      const attachedFiles = computed<Document[]>(
        () => store.getters['organizations/attachedFiles']
      )
      const isAlreadyAttached = computed<boolean>(() =>
        attachedFiles.value.map((x) => x.id).includes(props.document?.id)
      )
      const fileReadUrl = computed(
        () =>
          (props.document?.documentVersions ?? [])?.find((x) => x.isMaster)
            ?.documentURL || ''
      )
      const downloadFile = (document: Document) => {
        window.open(
          document.documentVersions.find((x) => x.isMaster)?.documentURL,
          document.label || document.name || ''
        )
      }
      const documentUrl = computed(() => decodeURIComponent(fileReadUrl.value))
      const onEventRoute = () =>
        ['company.eventDetails', 'createEvent', 'company.eventUpdate'].includes(
          route.name?.toString() || ''
        )

      const attachDocumentToEvent = (docId: string) => {
        console.log(isAlreadyAttached.value, 'attachedFile')
        let remove = false
        let files = [...attachedFiles.value]
        let message = t('extraction.secondLevel.events.attachedFile')
        if (!attachedFiles.value.map((x) => x.id).includes(docId)) {
          files.push(props.document)
        } else {
          message = t('extraction.secondLevel.events.detachedFile')
          remove = true
          files = attachedFiles.value.filter(
            (doc) => doc.id !== props.document.id
          )
        }

        store.commit('organizations/ATTACH_FILES_TO_EVENT', {
          files,
          docId,
          remove
        })

        store.dispatch('toolbox/displayToast', {
          type: TYPE.INFO,
          context: t('extraction.secondLevel.events.context'),
          message
        })
      }

      const documentUpdated = () => {
        updateDisplay.value = false
      }

      const handleFilesSelection = (files: File[]) => {
        const [file] = files
        fileLabel.value = file.name.replace('.pdf', '')
      }

      watch(
        () => documentUrl.value,
        (val) => {
          console.log('documentUrl', val)
        }
      )
      return {
        documentUrl,
        isAlreadyAttached,
        moment,
        fileLabel,
        updateDisplay,
        onEventRoute,
        documentUpdated,
        downloadFile,
        truncText,
        attachDocumentToEvent,
        handleFilesSelection
      }
    },
    data() {
      return {
        toggleAudit: false
      }
    }
  })
