
  import { CompanyModel } from '@/services/api/models'
  import { DocumentResult } from '@/store/modules/Organization/models'
  import { computed, defineComponent } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import Loader from '../Loader/index.vue'
  export default defineComponent({
    components: { Loader },
    props: {
      filter: { type: String, default: '' },
      data: { type: Object as () => DocumentResult[], required: true }
    },
    emits: ['close'],
    setup(props, { emit }) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const searchResults = computed(() => props.data)

      const searching = computed(
        () => store.getters['organizations/searchingText']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const hideSearchResults = () => {
        emit('close')
      }

      const getCorrectText = (text: string) => {
        var result = text
          .match(/<mark>(.*?)<\/mark>/g)
          ?.map(function (val: string) {
            return val.replace(/<\/?mark>/g, '')
          })
        return result && result?.length > 0 ? result[0] : props.filter
      }

      const displayDocument = async (
        documentId: string,
        text: string,
        companyId: string | undefined
      ) => {
        store.commit('organizations/SET_TEXT_SEARCHED', text)

        if (route.name === 'companyDocuments') {
          router.push({
            name: 'companyDocuments',
            params: { documentId }
          })
        }
        store.commit('ui/DISPLAY_PREVIEW', true)

        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: isPortfolioRoute.value
            ? companyId
            : currentCompany.value.id,
          documentId
        })

        emit('close')
      }

      return {
        searchResults,
        searching,
        hideSearchResults,
        displayDocument,
        getCorrectText
      }
    }
  })
