import axios from '../axios'
import { company } from '../endpoints'

const createAlert = async (companyId: string, data: any) => {
  return await axios.post(company.alertsConf(companyId), data)
}

const editAlert = async (companyId: string, alertId: string, values: any) => {
  return await axios.put(company.alertConf(companyId, alertId), values)
}

const deleteAlert = async (companyId: string, alertId: string) => {
  return await axios.delete(company.alertConf(companyId, alertId))
}

const editAlertStatus = async (
  companyId: string,
  alertId: string,
  status: string
) => {
  return await axios.put(company.alertConf(companyId, alertId), { status })
}
const getAlerts = async (companyId: string, filter = {}) => {
  const response = await axios.get(company.alertsConf(companyId), {
    params: {
      ...filter
    }
  })
  return response
}
const getNotifications = async (
  companyId: string,

  filter = { offset: 0, limit: 20 }
) => {
  const response = await axios.get(company.alerts(companyId), {
    params: {
      ...filter,
      offset: filter?.offset || 0,
      limit: filter.limit
    }
  })
  return response
}

const getNotification = async (companyId: string, notificationId: string) => {
  const response = await axios.get(company.alert(companyId, notificationId), {})
  return response
}

const notificationSeen = async (companyId: string, notificationId: string) => {
  const response = await axios.put(company.alertSeen(companyId, notificationId))
  return response
}

export {
  getAlerts,
  createAlert,
  editAlert,
  editAlertStatus,
  deleteAlert,
  getNotifications,
  getNotification,
  notificationSeen
}
