
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import CapitalTab from '@/components/CompanyOverview/CapitalTab.vue'
  import FinancialsTab from '@/components/CompanyOverview/FinancialsTab.vue'
  import ESOPTab from '@/components/CompanyOverview/ESOPTab.vue'
  import ResultsTab from '@/components/CompanyOverview/ResultsTab.vue'
  import BodaccTab from '@/components/CompanyOverview/BodaccTab.vue'
  import CompanyInfos from '@/components/CompanyOverview/CompanyInfos.vue'
  import { CompanyOverviewExample } from '@/utils/test'
  import Tab from '@/components/Tab/index.vue'

  export default defineComponent({
    name: 'CompanyOverview',
    components: {
      Tab
    },
    setup() {
      const store = useStore()
      const companyImage = ref<null | string>()
      const tabList = [
        { key: 'infos', langKey: 'infos', component: CompanyInfos },
        { key: 'capital', langKey: 'capital', component: CapitalTab },
        { key: 'financials', langKey: 'financials', component: FinancialsTab },
        { key: 'esop', langKey: 'esop', component: ESOPTab },
        // { key: 'results', langKey: 'results', component: ResultsTab },
        { key: 'bodacc', langKey: 'bodacc', component: BodaccTab }
      ]

      const company = computed(
        () => store.getters['organizations/contextCompany']
      )

      const loadImage = async () => {
        const response = await store.dispatch(
          `organizations/LOAD_COMPANY_LOGO`,
          {
            companyId: company.value?.id
          }
        )
        if (response) companyImage.value = response
        else companyImage.value = null
      }

      onMounted(loadImage)

      watch(
        () => company.value,
        () => loadImage()
      )

      return {
        companyData: CompanyOverviewExample,
        tabList,
        company,
        companyImage
      }
    }
  })
