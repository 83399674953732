<template>
  <div class="event__actions">
    <div class="event__actions flex justify-between items-center mt-1">
      <div
        v-if="isPortfolio"
        class="h-16 flex items-center text text-blackp text-xl py-8"
      >
        <div
          class="w-10 h-10 rounded-full bg-cover bg-primary1"
          :style="`background-image: url(${
            companyImage ?? require('@/assets/images/profile/company.png')
          })`"
        ></div>
        <p class="pl-1 pr-2 text-sm">{{ item?.company?.name }}</p>
      </div>

      <div
        :class="`action__toggle flex  justify-center items-center ${
          $can.any(['write-audits', 'read-audits', 'write-events'])
            ? ''
            : 'max-w-min px-2'
        }`"
      >
        <div
          :class="`switch event__actions-btn ${
            $can.any(['write-audits', 'read-audits', 'write-events'])
              ? 'border-r '
              : 'w-2'
          } `"
          role="button"
          @click.stop="navigateToDocumentForm(item.id)"
        >
          <i :class="`bi-eye `" />
        </div>

        <el-popover class="shadow-md z-30" width="60%" placement="top-end">
          <template #reference>
            <div
              v-if="
                $acl.role('admin') ||
                ($acl.permission('is-manager') &&
                  ($acl.permission('read-audits') ||
                    $acl.permission('write-audits')))
              "
              :class="`switch event__actions-btn ${
                !isPortfolio && $can.any('write-events')
                  ? 'border-r'
                  : ($acl.role('admin') || $acl.permission('is-manager')) &&
                    $acl.permission('write-events')
                  ? 'border-r'
                  : ''
              }`"
              role="button"
            >
              <i
                :class="[
                  'align-self-center mx-auto bi-exclamation-triangle md',
                  { warning: false },
                  {
                    'text-warning': event?.eventAudits?.length > 0
                  }
                ]"
              />
            </div>
          </template>
          <template #default>
            <div class="text-xl">
              <el-tabs
                :model-value="currentTab"
                type="border-card"
                class="shadow-none h-72 overflow-scroll text-sm"
              >
                <el-tab-pane
                  v-if="event && $acl.permission('read-audits')"
                  name="audits"
                  :label="`${$t(
                    'extraction.firstlevel.state.labels.audits'
                  )} (${event?.eventAudits?.length || 0})`"
                >
                  <el-table
                    v-if="event?.eventAudits?.length > 0"
                    :data="event?.eventAudits"
                    fit
                    class="w-full"
                  >
                    <el-table-column
                      property="createdAt"
                      label="Date"
                      width="100"
                    >
                      <template #default="scope">
                        <div>
                          <span>{{
                            moment.utc(scope.row.date).format('L')
                          }}</span>
                        </div>
                      </template></el-table-column
                    >
                    <el-table-column width="300" property="name" label="Motive">
                      <template #default="scope">
                        <div v-if="scope.row.motive" class="flex flex-wrap">
                          <span
                            v-for="m in scope.row.motive.split(',')"
                            :key="m"
                            class="border border-warning text-warning rounded px-1 m-1"
                          >
                            {{ $t(`audits.labels.${m}`) }}
                          </span>
                        </div>

                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      property="comment"
                      label="Comment"
                    ></el-table-column>
                    <el-table-column label="Resolved ?">
                      <template #default="scope">
                        <span
                          :class="`border border-${
                            scope.row.processed ? 'success' : 'error'
                          } text-${
                            scope.row.processed ? 'success' : 'error'
                          } rounded px-2`"
                          >{{
                            $t(`confirm.${scope.row.processed ? 'yes' : 'no'}`)
                          }}</span
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane
                  v-if="$acl.permission('write-audits')"
                  name="newAudit"
                  :label="$t('extraction.firstlevel.state.labels.newAudit')"
                >
                  <Form @submit="handleAuditForm">
                    <h3 class="text-md text-secondary9">
                      {{ $t('extraction.firstlevel.state.labels.newAudit') }}
                    </h3>
                    <UplAuditForm
                      :reasons="Object.values(EventAuditAnomalies)"
                      :type="AuditType.EVENT"
                      @change="handleAuditFormChange"
                    />
                    <div class="flex justify-end text-xl mt-2">
                      <Button
                        type="submit"
                        variant="secondary"
                        class="text-xl"
                        :label="$t('global.actions.submit')"
                      ></Button>
                    </div>
                  </Form>
                </el-tab-pane>
              </el-tabs>
            </div>
          </template>
        </el-popover>

        <MoreActions
          v-if="
            ($acl.role('admin') || $acl.permission('is-manager')) &&
            $acl.permission('write-events')
          "
          :actions="menuActions"
          @on-click="handleAction"
        >
          <i
            class="bi bi-three-dots text-xl hover:text-primary5 text-secondary7"
          ></i>
        </MoreActions>
      </div>
      <div class="event__separator" />

      <div
        class="flex border border-color rounded cb-wrapper transition-all overflow-hidden items-center"
      >
        <div
          class="px-3 h-full flex justify-between items-center"
          role="button"
          :class="{ 'active-btn': currentSide === CardType.EVENT }"
          @click.stop="toggleSide(CardType.EVENT)"
        >
          <i
            :class="`bi-lightning  ${
              currentSide === CardType.EVENT ? 'text-white' : 'color-2'
            } `"
          />
        </div>
        <div
          class="h-full flex border-l border-color px-3 justify-between items-center"
          role="button"
          :class="{ 'active-btn': currentSide === CardType.DOCUMENT }"
          @click.stop="toggleSide(CardType.DOCUMENT)"
        >
          <i
            :class="`bi-file-earmark mr-1 ${
              countDocuments > 0 ? 'text-primary4' : ''
            } ${currentSide === CardType.DOCUMENT ? 'text-white' : 'color-2'} `"
          />
          <span
            v-if="countDocuments > 0"
            class="ml-1 p-1 py-0 bg-primary1 text-primary4 rounded-full text-sm"
            >{{ countDocuments }}</span
          >
        </div>
      </div>
    </div>
    <transition name="modal">
      <Modal v-if="canDelete" size="w-2/5" @close="canDelete = false">
        <template #header>
          <h3 class="text-md font-semibold text-error">
            {{ $t('events.labels.groupDeleteModalTextHeader') }}
          </h3>
        </template>

        <template #body>
          <p
            class="select-none"
            v-html="
              $tc('events.labels.groupDeleteModalText', {
                size: 1
              })
            "
          ></p>
        </template>
        <template #footer>
          <div class="flex text-xl justify-end mt-6 mb-3">
            <Button
              class="flex text-secondary9 rounded mr-2"
              variant="custom"
              :label="$t('account.accountCompanies.deletionModalCancel')"
              @click="canDelete = false"
            />
            <Button
              class="flex text-white bg-error rounded"
              variant="custom"
              :label="$t('account.accountCompanies.deletionModalText')"
              @click.prevent="submitDeletion"
            />
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, toRefs } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import { CompanyModel, Event } from '@/services/api/models'
  import { AuditForm, AuditType, EventAuditAnomalies } from '@/types/forms'
  import moment from 'moment'
  import { Form } from 'vee-validate'
  import UplAuditForm from '@/components/Forms/Audit/index.vue'
  import { useStore } from 'vuex'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { CardType } from '@/types/config'
  import Modal from '@/components/Modal/Modal.vue'

  import {
    ElTabs,
    ElTabPane,
    ElTableColumn,
    ElTable,
    ElPopover
  } from 'element-plus'
  import MoreActions from '@/components/ActionList/new.vue'
  import { EventType } from '@/types/event'
  export default defineComponent({
    name: 'EventActions',
    components: {
      Form,
      UplAuditForm,
      Button,
      MoreActions,
      ElTabs,
      ElTabPane,
      ElTableColumn,
      ElTable,
      Modal,
      ElPopover
    },
    props: {
      item: {
        type: Object as () => Event,
        required: true
      },
      side: {
        type: Number,
        default: CardType.EVENT
      },
      audit: {
        type: Boolean,
        default: false
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    emits: ['toggle-side', 'select-card', 'updated', 'removed'],
    setup(props, { emit }) {
      const { side, audit, item } = toRefs(props)
      const router = useRouter()
      const route = useRoute()
      const canDelete = ref(false)
      const itemId = computed(() => item.value.id)
      const event = computed(() => item.value)
      const currentSide = ref(side.value)
      const toggleAudit = ref(audit.value)
      const eventAudit = ref<AuditForm>()
      const store = useStore()
      const { t } = useI18n()

      const menuActions = computed(() => [
        {
          section: 1,
          name: t('global.edit'),
          icon: 'bi bi-pen',
          key: 'edit',
          type: ''
        },
        {
          section: 2,
          name: t('global.delete'),
          icon: 'bi bi-trash',
          iconClass: 'text-error',
          textClass: 'text-error',
          key: 'delete',
          type: ''
        }
      ])

      const companyImage = ref<null | string>()

      const currentTab = computed<string>(() =>
        (item.value?.eventAudits || [])?.length === 0 ? 'newAudit' : 'audits'
      )
      const isNotEditable = computed(
        () =>
          props.item?.type &&
          [
            EventType.optionSunset.toString(),
            EventType.optionExercise.toString(),
            EventType.transfer.toString(),
            EventType.stockIssuance.toString(),
            EventType.optionIssuance.toString(),
            EventType.compositionIssuance.toString()
          ].includes(props.item?.type.toString())
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const countDocuments = ref(0)

      const handleAction = (key: string) => {
        if (!props.item.id) return
        if (key === 'edit') editEvent(props.item.id)
        else if (key === 'delete') canDelete.value = true
      }
      const toggleSide = (what: CardType) => {
        currentSide.value = what
        emit('toggle-side', currentSide.value)
      }

      const selectCard = (e: boolean) => {
        emit('select-card', {
          check: e,
          id: itemId.value
        })
      }
      const removeEvent = (eventId: string) => {
        store.dispatch('organizations/DELETE_EVENT', {
          companyId: route.params.id,
          eventId,
          onSuccess: () => {
            canDelete.value = false
            emit('removed', eventId)
          }
        })
      }
      const editEvent = (eventId: string) => {
        let name = 'company.eventUpdate'
        if (isNotEditable.value) {
          name = 'company.eventDetails'
        }
        router.push({
          name,
          params: { id: route.params.id, eventId: eventId }
        })
      }

      const removeDocument = (e: any) => {
        store.dispatch('organizations/DELETE_EVENT', {
          companyId: currentCompany.value.id,
          eventId: event.value.id,
          onError: (error = null) => {
            const message = error || t(`events.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message
            })
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.deleteSuccess`)
            })
          }
        })
      }

      const handleAuditFormChange = (values: AuditForm) => {
        eventAudit.value = values
      }

      const submitDeletion = () => {
        removeEvent(props.item.id)
      }

      const navigateToDocumentForm = (id: string) => {
        router.push({
          name: props.isPortfolio
            ? 'portfolioView.event'
            : 'company.eventDetails',
          params: { id: route.params.id, eventId: id }
        })
      }

      const getEventDocuments = (eventId: string, page = 1) => {
        if (!eventId) return
        let module = 'organizations'
        if (props.isPortfolio) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT_DOCUMENTS`, {
          companyId: currentCompany.value?.id || route.params.id,
          eventId,
          page,
          limit: 1,
          onSuccess: (response: any) => {
            const { metadata } = response
            countDocuments.value = metadata.count
          },
          onError: () => {
            // isLoading.value = false
          }
        })
      }

      const handleAuditForm = () => {
        const formData = {
          motive: eventAudit.value?.motives?.join(','),
          processed: false,
          comment: eventAudit.value?.comment
        }
        store.dispatch('organizations/CREATE_AUDIT', {
          companyId: currentCompany.value.id,
          type: AuditType.EVENT,
          typeDataId: event.value.id,
          formData,
          onSuccess: () => {
            store.dispatch('organizations/GET_EVENTS', {
              companyId: currentCompany.value.id,
              filter: {}
            })
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('audits.events'),
              message: t(`audits.createSuccess`)
            })
          },
          onError: (error: string) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('audits.events'),
              message: error || t(`audits.createError`)
            })
          }
        })
      }

      const loadImage = async () => {
        if (!props.item?.company?.id) return
        if (store.getters['portfolio/companiesLogos'][props.item?.company?.id]) {
          companyImage.value =
            store.getters['portfolio/companiesLogos'][props.item?.company?.id]
        } else {
          const response = await store.dispatch(
            `organizations/LOAD_COMPANY_LOGO`,
            {
              companyId: props.item.company?.id
            }
          )
          if (response) {
            companyImage.value = response
            store.commit('portfolio/SET_COMPANIES_LOGOS', {
              companyId: props.item.company?.id,
              value: response
            })
          }
        }
      }

      onMounted(() => {
        if (props.isPortfolio) loadImage()
        getEventDocuments(props.item.id)
      })

      return {
        itemId,
        currentSide,
        toggleAudit,
        event,
        canDelete,
        EventAuditAnomalies,
        currentTab,
        CardType,
        menuActions,
        AuditType,
        handleAction,
        companyImage,
        countDocuments,
        toggleSide,
        editEvent,
        selectCard,
        submitDeletion,
        removeDocument,
        handleAuditFormChange,
        handleAuditForm,
        navigateToDocumentForm,
        moment
      }
    }
  })
</script>

<style lang="scss" scoped>
@import '@/scss/eventcard.scss';

.cb-wrapper {
  height: 28px;

  .active-btn {
    @apply bg-primary3;
  }
}

.border-color {
  border-color: #d0d2d6;
}

.color {
  color: #d0d2d6;
}

.color-2 {
  color: #abaeb2;
}
</style>
