
  import { Field } from 'vee-validate'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import moment from 'moment'

  interface DataInputDate {
    name: string
    label: string
    type: string
    id: string
    hint?: string
  }

  import { defineComponent, ref, watch, computed, toRefs, onMounted } from 'vue'
  export default defineComponent({
    name: 'DateGeneric',
    components: { Field, UplDatePicker },
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputDate,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: ''
        })
      },
      format: { type: String, default: 'dd/MM/yyyy' },
      value: {
        type: [String],
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: ''
      },
      inputClass: {
        type: String,
        default: ''
      },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      },
      label: {
        type: Boolean,
        default: false
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value } = toRefs(props)
      const formValue = ref<string>(value.value ?? new Date().toISOString())
      const fieldName = computed(() =>
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      )
      const newFormat =
        props.format.split('/').length === 2 ? 'MM-DD' : 'yyyy-MM-DD'

      // If initialvalue is null, update the model right away with todays date
      watch(formValue, () => {
        emit('onUpdated', {
          ...data.value,
          value: moment(formValue.value).format(newFormat.toUpperCase())
        })
      })

      watch(
        () => props.value,
        (val) => {
          formValue.value = val
        }
      )

      onMounted(() => {
        formValue.value = moment(formValue.value).toISOString()
        // If initialvalue is null, update the model right away with todays date
        emit('onUpdated', {
          ...data.value,
          value: moment(formValue.value).format(newFormat.toUpperCase())
        })
      })
      return { formValue, moment, fieldName }
    }
  })
