<template>
  <div class="w-full h-full">
    <form @submit.prevent="handleForm" @reset.prevent="handleReset">
      <div class="w-full mt-4">
        <div class="flex">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold"
            for="lastName"
            >{{ $t('docAutomation.fields.label') }}</label
          >
          <strong class="ml-1 text-error h-4">*</strong>
        </div>
        <Field v-slot="{ field }" v-model="formData.label" name="label">
          <Input
            v-model="formData.label"
            v-bind="field"
            class="h-10"
            :disabled="false"
            type="text"
            name="label"
            :placeholder="$t('docAutomation.placeholder.label')"
          />
        </Field>
      </div>

      <div class="w-full mt-4">
        <div class="flex">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold"
            for="lastName"
            >{{ $t('docAutomation.fields.eventType') }}</label
          >
          <strong class="ml-1 text-error h-4">*</strong>
        </div>
        <Field v-slot="{ field }" v-model="formData.eventType" name="eventType">
          <Multiselect
            v-bind="field"
            :value="formData.eventType"
            :options="eventTypes"
            :placeholder="$t('docAutomation.placeholder.eventType')"
          />
        </Field>
      </div>
      <div class="w-full mt-4">
        <div class="flex">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold"
            for="currency"
            >{{ $t('docAutomation.fields.numberFormat') }}</label
          >
          <strong class="ml-1 text-error h-4">*</strong>
        </div>
        <Field
          v-slot="{ field }"
          v-model="formData.numberFormat"
          name="numberFormat"
        >
          <Multiselect
            v-bind="field"
            :value="formData.numberFormat"
            :options="formats"
          />
        </Field>
      </div>
      <div class="w-full mt-4">
        <div class="flex">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold"
            for="currency"
            >{{ $t('docAutomation.fields.dateTimeFormat') }}</label
          >
          <strong class="ml-1 text-error h-4">*</strong>
        </div>
        <Field
          v-slot="{ field }"
          v-model="formData.dateTimeFormat"
          name="dateTimeFormat"
        >
          <Multiselect
            v-bind="field"
            :value="formData.dateTimeFormat"
            :options="dateTimeFormats"
          />
        </Field>
      </div>
      <div class="w-full mt-4">
        <div class="flex">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold"
            for="currency"
            >{{ $t('docAutomation.fields.currency') }}</label
          >
          <strong class="ml-1 text-error h-4">*</strong>
        </div>
        <Field v-slot="{ field }" v-model="formData.currency" name="currency">
          <Multiselect
            v-bind="field"
            :value="formData.currency"
            :options="currencies"
          />
        </Field>
      </div>

      <div class="w-full mt-4">
        <div class="flex flex-col w-full">
          <div class="flex">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold"
              for="currency"
              >{{ $t('docAutomation.fields.docType') }}</label
            >
            <strong class="ml-1 text-error h-4">*</strong>
          </div>
          <Field
            v-slot="{ field }"
            v-model="formData.documentSubcategoryId"
            name="documentSubcategoryId"
          >
            <multiselect
              v-model="formData.documentSubcategoryId"
              :value="formData.documentSubcategoryId"
              v-bind="field"
              mode="single"
              class="min-h-full"
              open-direction="bottom"
              :tag-with-initials="false"
              elementName="documents.categories"
              selectionText="global.actions.selected2"
              :options="documentCategories"
              :groups="true"
              group-options-field="docSubCategories"
              :placeholder="$t('docAutomation.placeholder.docType')"
              @change="updateFilters($event)"
            />
          </Field>
        </div>
      </div>
      <div class="w-full mt-5 bg-white p-2">
        <FileInput
          accept=".pdf, .doc,.docx"
          :multiple="false"
          @filesChanged="handleFilesSelection"
        >
          <div
            class="
              flex
              justify-start
              items-center
              rounded
              border border-secondary6
              p-2
              hover:bg-secondary2
              bg-secondary2
              cursor-pointer
            "
          >
            <i class="bi bi-file text-xl mr-2 text-secondary6 rotate-90"></i>
            <p class="text-secondary7">
              {{ $t('docAutomation.form.uploadFile') }}
            </p>
          </div>
        </FileInput>
      </div>

      <div class="mt-10" v-if="errorMessages.length">
        <div class="bg-danger p-5 rounded">
          <span class="font-medium text-danger9">
            {{ $t('docAutomation.apiResponse.upload_error') }}</span
          >
          <ul class="mt-3 ml-4 list-disc list-inside text-danger9">
            <li v-for="(err, id) in errorMessages" :key="id">
              {{ err.explanation }}
            </li>
          </ul>
        </div>
      </div>
      <FormActions
        :errors="errors"
        :meta="meta"
        :hasDeletionButton="selectedTemplate?.id != null"
        @delete="removeTemplate"
        @resetForm="handleReset"
      />
    </form>

    <div class="mt-20" v-if="selectedFile">
      <div class="bg-white rounded py-2 px-3 flex justify-between items-center">
        <h3>{{ selectedFile?.name }}</h3>
        <p>{{ moment(selectedFile.lastModified).format('LLL') }}</p>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Input } from '@up.law/uplaw-ui'
  import { Field, useForm } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import * as yup from 'yup'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import FileInput from '@/components/FileInput/index.vue'

  import {
    CurrencyEnum,
    DateTimeFormatEnum,
    DocAutoTemplate,
    DocAutoTemplateForm,
    NumberFormatEnum
  } from '@/store/modules/Organization/docAutoModel'
  import { EventType } from '@/types/event'
  import { useI18n } from 'vue-i18n'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { TemplateError } from '@/store/modules/Config'

  export default defineComponent({
    components: { Multiselect, Field, Input, FormActions, FileInput },
    props: { file: { type: Object, required: false } },
    emits: ['onUpdated', 'removeTemplate'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const route = useRoute()
      const store = useStore()
      const selectedFile = ref()
      const category = ref()
      const documentCategories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])
      const errorMessages = ref<TemplateError[]>([])

      const schema = computed(() =>
        yup.object({
          label: yup.string().required('labelRequired'),
          documentSubcategoryId: yup
            .string()
            .required('templateDocumentSubcategoryId'),
          eventType: yup.string().required('template_eventRequired'),
          currency: yup.string().required('eventRequired'),
          numberFormat: yup.string().required('eventRequired'),
          dateTimeFormat: yup.string().oneOf(Object.values(DateTimeFormatEnum))
        })
      )

      const selectedTemplate = computed<DocAutoTemplate | null>(
        () => store.getters['organizations/template']
      )
      const eventTypes = computed(() =>
        [
          EventType.transfer,
          EventType.optionIssuance,
          EventType.optionExercise
        ].map((x) => ({
          id: x,
          name: t(`events.labels.${x}`)
        }))
      )

      const dateTimeFormats = computed(() =>
        Object.values(DateTimeFormatEnum).map((x) => ({
          id: x,
          name: moment().format(`${x}`)
        }))
      )

      const currencies = computed(() =>
        Object.values(CurrencyEnum).map((x) => ({
          id: x,
          name: t(`currencies.${x}`)
        }))
      )

      const formats = computed(() =>
        Object.values(NumberFormatEnum).map((x) => ({
          id: x,
          name: `${t(`numberFormat.${x}`)} (${new Intl.NumberFormat(`${x}`, {
            style: 'currency',
            currency: t(`numberFormatDevise.${x}`)
          }).format(123456.789)})`
        }))
      )
      const formData = ref<DocAutoTemplateForm>({
        label: '',
        documentSubcategoryId: '',
        file: null,
        currency: CurrencyEnum.EUR,
        dateTimeFormat: DateTimeFormatEnum.LLLL,
        eventType: EventType.transfer,
        numberFormat: NumberFormatEnum.fr_FR
      })

      const {
        errors,
        meta,
        setErrors,
        setFieldError,
        setFieldValue,
        handleSubmit,
        resetForm
      } = useForm({
        validationSchema: schema,
        initialValues: formData.value
      })

      const handleReset = () => {
        resetForm()
      }

      const handleForm = handleSubmit((values) => {
        const companyId = route.params.id

        if (!selectedFile.value) {
          setFieldError('file', 'templateFileRequired')
          return
        }

        errorMessages.value = []
        let data = new FormData()
        const f: any = selectedFile.value
        data.append('label', values.label)
        data.append('currency', values.currency)
        data.append('dateTimeFormat', values.dateTimeFormat)
        data.append('eventType', values.eventType)
        data.append('documentSubcategoryId', values.documentSubcategoryId ?? '')
        data.append('numberFormat', values.numberFormat)
        data.append('file', f)

        store.dispatch('organizations/SAVE_TEMPLATE', {
          data,
          companyId: companyId,
          onSuccess: (templ: any) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.success_save`)
            })

            emit('onUpdated')
          },
          onError: (err: TemplateError[]) => {
            errorMessages.value = err
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.error_save`)
            })
          }
        })
      })

      const updateFilters = (e: any, filter: string) => {
        // store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
        //   companyId: route.params.id,
        //   filter: { label: e, offset: 0, limit: 10 }
        // })
      }

      const removeTemplate = () => {
        emit('removeTemplate', selectedTemplate.value?.id)
      }

      const handleFilesSelection = (files: any) => {
        const [file] = files

        if (file) {
          selectedFile.value = file
        }
      }

      watch(
        () => props.file,
        (val) => {
          const f: any = val
          setFieldValue('file', f)
          setErrors({ file: undefined })
        }
      )

      watch(
        () => selectedTemplate.value,
        (val) => {
          formData.value = {
            documentSubcategoryId: val?.documentSubcategory?.id,
            label: val?.label ?? '',
            currency: val?.currency ?? CurrencyEnum.EUR,
            numberFormat: val?.numberFormat ?? NumberFormatEnum.fr_FR,
            dateTimeFormat: val?.dateTimeFormat ?? DateTimeFormatEnum.LLLL,
            eventType: val?.eventType ?? EventType.transfer,
            file: null
          }
        }
      )

      onMounted(() => {
        formData.value = {
          label: selectedTemplate.value?.label ?? '',
          documentSubcategoryId:
            selectedTemplate.value?.documentSubcategory?.id,
          currency: selectedTemplate.value?.currency ?? CurrencyEnum.EUR,
          numberFormat:
            selectedTemplate.value?.numberFormat ?? NumberFormatEnum.fr_FR,
          dateTimeFormat:
            selectedTemplate.value?.dateTimeFormat ?? DateTimeFormatEnum.LLLL,
          eventType: selectedTemplate.value?.eventType ?? EventType.transfer,
          file: null
        }
      })

      return {
        formData,
        eventTypes,
        formats,
        currencies,
        dateTimeFormats,
        moment,
        errors,
        selectedTemplate,
        meta,
        selectedFile,
        schema,
        errorMessages,
        documentCategories,
        category,
        handleFilesSelection,
        handleReset,
        updateFilters,
        handleForm,
        removeTemplate
      }
    }
  })
</script>
