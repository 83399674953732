<template>
  <div class="flex flex-col relative w-full">
    <div class="flex w-full justify-between items-center">
      <!-- <p>{{ $t('documents.filter') }}</p> -->
      <p></p>
      <Button
        variant="custom"
        size="medium"
        :class="`rounded-sm flex justify-center items-center ${
          filtered ? 'bg-primary4' : 'bg-secondary4'
        }`"
        @click="isVisible = !isVisible"
      >
        <i
          :class="`bi bi-filter text-xl  ${
            filtered ? 'text-white' : 'text-primary5'
          }`"
        ></i>
      </Button>
    </div>

    <transition name="collapse">
      <div
        v-show="isVisible"
        class="mb-3"
        style="display: flex; gap: 8px; flex-direction: column"
      >
        <div class="form-group w-full text-left relative mt-2">
          <label class="text-secondary9 text-xs">{{
            $t('global.documentSearchIn')
          }}</label>
          <Field v-slot="{ field }" v-model="searchQuery" name="searchInput">
            <Input
              v-model="searchQuery"
              type="search"
              :disabled="false"
              no-error
              class="h-10 w-full tree-search"
              :placeholder="$t('documents.searchIn')"
              v-bind="field"
            />
          </Field>
          <div
            v-if="searchQuery"
            v-click-away="hideSearchResults"
            style="z-index: 999"
            class="upl-treeview__search__hints flex z-50 bg-white"
          >
            <SearchResult
              :filter="searchQuery"
              :data="searchResults"
              @close="initSearch"
            />
          </div>
        </div>

        <div class="form-group w-full text-left">
          <label class="text-secondary9 text-xs">{{
            $t('global.documentType')
          }}</label>
          <Field
            v-model="searchFilter.documentSubCategoriesId"
            name="documentSubCategoriesId"
          >
            <Multiselect
              :value="searchFilter.documentSubCategoriesId"
              :show-labels="true"
              mode="tags"
              :options="categories"
              group-options-field="docSubCategories"
              :placeholder="$t('documents.types')"
              :groups="true"
              @change="handleCategoryChange"
            >
            </Multiselect>
          </Field>
        </div>

        <div class="form-group w-full text-left">
          <label class="text-secondary9 text-xs">{{
            $t('global.contacts')
          }}</label>
          <Field v-model="searchFilter.contactsId" name="contactsId">
            <Multiselect
              :value="searchFilter.contactsId"
              :show-labels="true"
              mode="tags"
              :options="contacts"
              :placeholder="$t('documents.contacts')"
              :groups="false"
              @change="handleContactChange"
              @search-change="handleContactSearch"
            >
            </Multiselect>
          </Field>
        </div>

        <div class="flex w-full mt-1">
          <div
            class="
              form-group
              w-full
              text-left
              w-full
              pr-1
              flex flex-col
              justify-start
            "
          >
            <label class="text-secondary9 text-xs">{{
              $t('global.documentDate')
            }}</label>
            <UplDatePicker
              v-model="dateRange"
              class="hover:border-primary4 h-10"
              :enable-time-picker="false"
              range
              :placeholder="$t('documents.dateRange')"
              close-on-scroll
              @cleared="dateCleared"
            />
          </div>
        </div>

        <div class="flex justify-end items-center text-xl mt-2">
          <Button
            variant="custom"
            size="medium"
            :label="$t('documents.resetFilter')"
            class="
              rounded-sm
              text-secondary9
              flex
              bg-white
              justify-center
              items-center
              text-lg
              mr-2
            "
            @click="resetFilter"
          >
          </Button>
          <Button
            variant="custom"
            size="medium"
            :label="$t('documents.filterLabel')"
            class="
              rounded-sm
              bg-secondary4
              flex
              text-primary4
              justify-center
              items-center
              text-lg text-lg
              hover:bg-primary4 hover:text-white
            "
            @click="handleSearch"
          >
          </Button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Button, Input } from '@up.law/uplaw-ui'
  import SearchResult from '@/components/DocumentSearchResult/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { DocumentSearchProps } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { Contact } from '@/services/api/models'

  export default defineComponent({
    name: 'DocumentFilter',
    components: {
      Button,
      Input,
      SearchResult,
      Multiselect,
      Field,
      UplDatePicker
    },
    props: { visible: { type: Boolean } },
    emits: ['on-change'],
    setup(props, { emit }) {
      const searchFilter = ref<DocumentSearchProps>({
        documentSubCategoriesId: [],
        contactsId: [],
        startDate: '',
        endDate: ''
      })

      const store = useStore()
      const route = useRoute()
      const isVisible = ref(props.visible)

      const searchQuery = ref('')
      let selectedContacts: Contact[] = []
      const dateRange = ref([])
      const searchResults = computed(
        () => store.getters['organizations/searchResults']
      )
      const filtered = computed(
        () =>
          searchFilter.value.documentSubCategoriesId?.length !== 0 ||
          searchFilter.value.contactsId?.length !== 0 ||
          searchQuery.value != '' ||
          (dateRange.value && dateRange.value.length !== 0)
      )

      const categories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])

      const filteredContacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const handleSearch = () => {
        emit('on-change', searchFilter.value)
      }

      const resetFilter = () => {
        searchFilter.value.documentSubCategoriesId = []
        searchFilter.value.contactsId = []
        searchFilter.value.startDate = ''
        searchFilter.value.endDate = ''
        selectedContacts = []
        searchQuery.value = ''
        dateRange.value = []
        emit('on-change', searchFilter.value)
        isVisible.value = false
      }

      const uniqByKeepFirst = (a: any, key: any) => {
        let seen = new Set()
        return a.filter((item: any) => {
          let k = key(item)
          return seen.has(k) ? false : seen.add(k)
        })
      }

      const contacts = computed(() =>
        uniqByKeepFirst(
          [
            ...filteredContacts.value,
            ...selectedContacts.filter((c) =>
              searchFilter.value.contactsId?.includes(c.id)
            )
          ],
          (value: any) => value.id
        )
      )
      const handleCategoryChange = ($event: string[]) => {
        searchFilter.value.documentSubCategoriesId = $event
      }
      const handleContactChange = ($event: string[]) => {
        selectedContacts.push(
          ...filteredContacts.value.filter((c) => $event.includes(c.id))
        )
        searchFilter.value.contactsId = $event
      }

      const dateCleared = () => {
        const dateFilter = {
          startDate: '',
          endDate: ''
        }
        searchFilter.value = { ...searchFilter.value, ...dateFilter }
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0,
            name: search
          }
        })
      }
      const initSearch = () => {
        searchQuery.value = ''
      }
      const hideSearchResults = () => {
        console.log()
      }
      const handleContactSearch = ($event: string) => {
        getContacts($event)
      }

      const filterDocumentList = (search = '') => {
        store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: {
            search,
            limit: 300
          }
        })
      }

      watch(
        () => props.visible,
        (value: boolean) => {
          isVisible.value = value
        }
      )

      watch(dateRange, (newDateRange) => {
        const dateFilter = {
          startDate: newDateRange
            ? moment(newDateRange[0]).startOf('day').toISOString()
            : '',
          endDate: newDateRange
            ? moment(newDateRange[1]).endOf('day').toISOString()
            : ''
        }
        searchFilter.value = { ...searchFilter.value, ...dateFilter }
      })

      watch(searchQuery, (val) => {
        filterDocumentList(val)
        store.dispatch('organizations/SEARCH_IN_DOCUMENTS', {
          companyId: route.params.id,
          query: val
        })
      })

      watch(
        () => route.params.id,
        async () => {
          searchFilter.value = {
            documentSubCategoriesId: [],
            contactsId: [],
            startDate: '',
            endDate: ''
          }
        }
      )

      onMounted(() => {
        getContacts()
      })
      return {
        searchFilter,
        searchQuery,
        dateRange,
        categories,
        contacts,
        isVisible,
        searchResults,
        dateCleared,
        filtered,
        handleSearch,
        initSearch,
        handleContactChange,
        handleCategoryChange,
        handleContactSearch,
        resetFilter,
        hideSearchResults
      }
    }
  })
</script>
<style lang="scss">
  .tree-search .upl--form-container {
    border: 1px solid rgba(220, 223, 230, 1) !important;
    &:focus {
      border: 1px solid #8580f6 !important;
    }

    &:focus-within {
      border: 1px solid #8580f6 !important;
    }
    &:focus-visible {
      border: 1px solid #8580f6 !important;
    }
  }
  .collapse-enter-active,
  .collapse-leave-active {
    transition: max-height 0.5s ease-in-out;
    max-height: 200px;
    overflow: hidden;
  }
  .collapse-enter-from,
  .collapse-leave-to {
    max-height: 0;
  }
</style>
