<template>
  <div
    class="
      flex flex-col
      items-start
      px-12
      w-full
      h-full
      overflow-y-scroll
      relative
    "
  >
    <h3 class="text text-blackp text-2xl pt-8">{{ $t('menu.captable') }}</h3>
    <div class="captable-filters my-5">
      <div class="filters md:w-5/6 lg:w-5/6 xl:w-4/5">
        <div class="filter w-3/4 xl:w-1/4 lg:w-1/4">
          <Datepicker
            v-model="picked"
            class="rounded text-xs h-10"
            placeholder="--/--/----"
          />
        </div>
        <div class="filter">
          <multiselect
            v-model="selectedContact"
            :options="contacts"
            :value="selectedContact"
            :groups="false"
            name="contacts"
            class="h-10"
            elementName="contactBook.contacts"
            selectionText="global.actions.selected1"
            mode="multiple"
            :has-label="false"
            :filter-results="false"
            open-direction="bottom"
            placeholder="Contacts"
            @search-change="handleContactSearch"
            @change="updateContact"
          />
        </div>
        <div class="filter">
          <multiselect
            v-model="selectedGroup"
            :options="companyGroups"
            :groups="false"
            name="groups"
            :has-label="false"
            :value="selectedGroup"
            :placeholder="$t('contactSearch.group')"
            open-direction="bottom"
            classes="h-10"
            @change="updateGroup"
          />
        </div>
        <div class="filter">
          <dropdown-stocks
            v-model="selectedStocks"
            :data="{ name: 'stocks' }"
            :has-label="false"
            :can-add-new="false"
            open-direction="bottom"
            mode="multiple"
            class="min-h-10"
            :placeholder="$t('global.placeholder.stocks')"
            :value="selectedStocks"
            :option-per-price="false"
            @onUpdated="updateStock"
          />
        </div>
        <div class="w-1/4 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <Multiselect
            v-model="selectedDelegs"
            :value="selectedDelegs"
            :options="eventDelegations"
            mode="multiple"
            elementName="global.delegations"
            selectionText="global.actions.selected2"
            :placeholder="$t('global.placeholder.delegation')"
            name="selectedDelegs"
            @change="handleDelegationChange($event)"
            @search-change="handleDelegationSearch"
          />
        </div>
        <div class="w-1/4 lg:w-1/3 xl:w-1/3 min-h-10 mr-2 grow-0">
          <Field v-slot="{ field }" v-model="selectedStockGroup">
            <multiselect
              v-bind="field"
              v-model="selectedStockGroup"
              :options="companyStockGroups"
              :groups="false"
              name="selectedStockGroup"
              :has-label="false"
              :placeholder="$t('global.placeholder.stockGroups')"
              open-direction="bottom"
              classes="h-10"
              @change="updateStockGroup"
            />
          </Field>
        </div>
        <div class="rounded ml-2 w-2/5">
          <Checkbox
            v-show="false"
            v-model="includeDelegationsAvailableBalance"
            class="py-0"
            name="includeDelegationsAvailableBalance"
            :checked="includeDelegationsAvailableBalance"
            :label="$t('captableTag.includesDelegAvailableBalance')"
            @on-change="includeDelegationsAvailableBalance = $event"
          />
        </div>
      </div>
      <div class="filters-date flex-1 flex justify-between">
        <div class="flex justify-end w-full">
          <div class="h-10 flex w-full justify-end">
            <el-tooltip
              class="box-item"
              effect="dark"
              :content="$t('tooltips.columns')"
              placement="top"
            >
              <Filter
                class="relative w-14 mx-2 captable-filter"
                :options="availableColumns"
                :value="selectedColumns"
                @on-change="handleFilterSelected"
              >
                <template #option="{ option, checked }">
                  <Checkbox
                    size="w-5 h-5 mr-2"
                    :checked="checked"
                    :name="option.id"
                    @click.prevent.stop
                  />
                </template>
                <i class="bi bi-gear"></i>
              </Filter>
            </el-tooltip>
            <DownloadButton
              v-if="!isDownloading"
              class="mr-2 h-full"
              :has-children="true"
              :on-click="download"
            ></DownloadButton>

            <el-tooltip
              v-else-if="isDownloading"
              class="box-item"
              effect="dark"
              :content="$t('tooltips.downloading')"
              placement="top"
            >
              <div
                class="
                  border
                  cursor-not-allowed
                  rounded-3
                  bg-white
                  border-secondary6
                  px-3
                  flex
                  items-center
                "
              >
                <i
                  class="bi bi-arrow-clockwise text-xl text-secondary8 rotating"
                ></i>
              </div>
            </el-tooltip>

            <el-tooltip
              class="box-item"
              effect="dark"
              :content="$t('tooltips.resetFilter')"
              placement="top"
              ><Button
                variant="custom"
                class="rounded-sm dwnld-btn h-10 ml-2 bg-white"
                @click="resetFilters"
              >
                <i class="bi bi-filter text-2xl"></i> </Button
            ></el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isLoading"
      class="flex w-full justify-center items-center relative"
    >
      <Loader />
    </div>
    <div
      v-else-if="captable && captable.lines.length !== 0"
      id="captable-table"
      class="captable-table mt-2 pb-5"
    >
      <DataTable
        :rows="captable.lines"
        thead-class="sticky top-0"
        :columns="columns"
        text-size="text-sm"
        :has-checkbox="false"
        container-class="w-full"
        cell-padding="p-0"
        :pagination="pagination"
        @onPageChange="onPageChange"
      >
        <template #associates="{ row }">
          <div class="flex items-center justify-start">
            <router-link
              v-if="row?.contactId"
              class="cursor-pointer"
              :to="{
                name: 'company.directory',
                params: { id: $route.params.id, contactId: row.contactId }
              }"
            >
              <ContactBadge
                :use-picture="false"
                :contact="{
                  id: row?.contactId,
                  name: row.contactName,
                  stocksCount: 0,
                  documentsCount: 0
                }"
                class="flat light"
                type="light"
              />
            </router-link>

            <div v-else>
              <ContactBadge
                v-if="selectedDelegs"
                :use-picture="false"
                :contact="{
                  id: 'row?.contactId',
                  name: $t('contactBook.availanleDelegationBalance'),
                  stocksCount: 0,
                  documentsCount: 0
                }"
                class="flat light"
                type="light"
              />
            </div>
          </div>
        </template>
        <template #groups="{ row }">
          <p
            v-for="(group, index) in row.groups"
            :key="index"
            class="text-left pl-1"
          >
            {{ group }}
          </p>
        </template>
        <template #stocks="{ row }">
          <div class="flex flex-wrap">
            <div
              v-for="(stock, sIndex) in row.stocks"
              :key="sIndex"
              class="px-1"
            >
              <el-tooltip
                v-if="stock.name"
                class="box-item text-xs"
                effect="dark"
                placement="top"
              >
                <template #content>
                  <div
                    v-if="stock.ownershipType != CoOwnershipType.usufruct"
                    class="p-1 text-white"
                  >
                    <h3 class="text-white text-sm">
                      NFD:
                      <span class="text-sm"
                        >({{ formatNumber(stock.quantityNFD) }})
                        {{ formatNumber(stock.percentageNFD) }} %</span
                      >
                    </h3>
                    <h3 class="text-white text-sm">
                      FD:
                      <span class="text-sm"
                        >({{ formatNumber(stock.quantityFD) }})
                        {{ formatNumber(stock.percentageFD) }} %</span
                      >
                    </h3>
                  </div>
                  <div v-else>
                    <h3 class="text-white text-sm">
                      {{ $t('captableTag.quantity') }}:
                      <span class="text-sm"
                        >{{ formatNumber(stock.nonDilutiveQuantity) }}
                      </span>
                    </h3>
                  </div>
                </template>
                <Tag
                  :id="stock.id"
                  :value="stock.name"
                  :text="`${stock.name.toUpperCase()} ${
                    stock.ownershipType &&
                    stock.ownershipType !== CoOwnershipType.fullOwnership
                      ? `(${$t(`CoOwnershipType.${stock.ownershipType}`)})`
                      : ``
                  }`"
                  :with-initials="false"
                  :with-remove="false"
                  :color="rgbToHex(stock.colorCode)"
                />
              </el-tooltip>
              <p v-else class="text-left">-</p>
            </div>
          </div>
        </template>
        <template #nfd="{ row }">
          <Number :value="row.totalNFD" :mode="NumberType.quantity" />
        </template>
        <template #fd="{ row }">
          <Number :value="row.totalFD" :mode="NumberType.quantity" />
        </template>
        <template #funding="{ row }">
          <Number :value="row.amount" :mode="NumberType.price" />
        </template>
        <template #fd_p="{ row }">
          <Number :value="row.percentageFD" :mode="NumberType.percentage" />
        </template>
        <template #fd_p_u="{ row }">
          <Number :value="row.percentageAllFD" :mode="NumberType.percentage" />
        </template>
        <template #nfd_p_u="{ row }">
          <Number :value="row.percentageAllNFD" :mode="NumberType.percentage" />
        </template>
        <template #nfd_p="{ row }">
          <Number :value="row.percentageNFD" :mode="NumberType.percentage" />
        </template>
        <template #total>
          <tr>
            <td :colspan="`${selectedColumns.includes('groups') ? 3 : 2}`">
              <p class="text-right font-bold py-3 px-2 text-primary8">Total</p>
            </td>
            <td v-if="selectedColumns.includes('funding')">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="captable.totalAmount"
                :mode="NumberType.price"
              />
            </td>
            <td>
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="captable.totalNFD"
                :mode="NumberType.quantity"
              />
            </td>
            <td v-if="isFiltered">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="captable.percentageNFD"
                :mode="NumberType.percentage"
              />
            </td>
            <td>
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="captable.percentageAllNFD"
                :mode="NumberType.percentage"
              />
            </td>
            <td>
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="captable.totalFD"
                :mode="NumberType.quantity"
              />
            </td>
            <td v-if="isFiltered">
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="captable.percentageFD"
                :mode="NumberType.percentage"
              />
            </td>
            <td>
              <Number
                classes="text-left font-bold py-3 px-2 text-primary8"
                :value="captable.percentageAllFD"
                :mode="NumberType.percentage"
              />
            </td>
          </tr>
        </template>
      </DataTable>

      <div class="py-3 px-2 flex justify-start">
        <p class="">
          {{ $t('global.lineCount', { count: pagination.total }) }}
        </p>
      </div>
    </div>
    <div v-else class="flex justify-center items-center w-full mt-4">
      <EmptyState
        class="d-block mx-auto datatable"
        :title="$t('global.noResult')"
        :subtitle="$t('global.selectFilters')"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute } from 'vue-router'
  import Datepicker from '@/components/DatePicker/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import {
    Contact,
    ContactStatus,
    CoOwnershipType,
    Delegation
  } from '@/services/api/models'
  import EmptyState from '@/components/EmptyState/index.vue'
  import moment from 'moment'
  import { formatNumber, rgbToHex } from '@/utils/global'
  import { useI18n } from 'vue-i18n'
  import { STOCKTYPE, NumberType, MAINACTIONS, Captable } from '@/types'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Checkbox from '@/components/Checkbox/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { ElTooltip } from 'element-plus'
  import Number from '@/components/Number/index.vue'
  import { CompanyModel } from '@/services/api/models'
  import { Field } from 'vee-validate'
  export default defineComponent({
    name: 'CompanyCaptable',
    components: {
      DataTable,
      Datepicker,
      Multiselect,
      Tag,
      Field,
      DownloadButton,
      Button,
      Checkbox,
      Filter,
      EmptyState,
      Number,
      ContactBadge,
      DropdownStocks,
      Loader,
      ElTooltip
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const route = useRoute()
      const selectedContact = ref<string[]>([])
      const selectedGroup = ref('')
      const selectedStockGroup = ref('')
      const selectedStocks = ref<string[]>([])
      const selectedDelegs = ref<string[]>([])
      const picked = ref<any>(new Date())
      const selectedColumns = ref<string[]>([])
      const isDownloading = ref(false)

      const captableColumns = computed(() => [
        {
          label: t(`datatable.column.associates`),
          field: 'associates',
          custom: true,
          order: 1,
          selected: true,
          sortBy: 'contactName',
          type: 'text'
        },
        {
          label: t(`datatable.column.groups`),
          field: 'groups',
          custom: true,
          filterable: true,
          order: 2,
          selected: true,
          sortable: true,
          type: 'text',
          width: '8%'
        },
        {
          label: t(`datatable.column.stocks`),
          field: 'stocks',
          custom: true,
          selected: true,
          order: 3,
          sortable: true,
          type: 'text'
        },
        {
          label: t(`datatable.column.funding`),
          field: 'funding',
          custom: true,
          filterable: true,
          selected: true,
          order: 4,
          sortBy: 'amount',
          type: 'number'
        },
        {
          label: t(`datatable.column.nfd`),
          field: 'nfd',
          custom: true,
          order: 5,
          selected: true,
          sortBy: 'totalNFD',
          type: 'number'
        },
        {
          label: t(`datatable.column.nfd_p`),
          field: 'nfd_p',
          custom: true,
          order: 6,
          selected: false,
          sortable: true,
          type: 'number'
        },
        {
          label: t(`datatable.column.nfd_p_u`),
          field: 'nfd_p_u',
          custom: true,
          order: 7,
          selected: true,
          sortable: true,
          type: 'number'
        },
        {
          label: t(`datatable.column.fd`),
          field: 'fd',
          custom: true,
          selected: true,
          order: 8,
          sortBy: 'totalFD',
          type: 'number'
        },
        {
          label: t(`datatable.column.fd_p`),
          field: 'fd_p',
          order: 9,
          selected: false,
          custom: true,
          sortable: true,
          type: 'text'
        },
        {
          label: t(`datatable.column.fd_p_u`),
          field: 'fd_p_u',
          custom: true,
          order: 10,
          selected: true,
          sortable: true,
          type: 'text'
        }
      ])
      const filter = ref<any>({
        compositionAliasesId: [],
        optionAliasesId: [],
        stockAliasesId: [],
        delegationsId: []
      })

      const includeDelegationsAvailableBalance = ref(false)

      const isFiltered = computed(
        () =>
          selectedStocks.value.length > 0 ||
          selectedContact.value ||
          selectedGroup.value ||
          selectedStockGroup.value ||
          selectedDelegs.value.length
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const contacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const isLoading = ref(false)
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const pagination = computed(
        () => store.getters['organizations/captablePagination']
      )
      const onPageChange = (page: number) => {
        getCaptable(page)
      }
      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )

      const companyStockGroups = computed(
        () => store.getters['organizations/stockGroups']
      )

      const captable = computed<Captable>(
        () => store.getters['organizations/captable']
      )
      const groups = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )

      const delegations = computed(
        () => store.getters['organizations/delegations']
      )

      const eventDelegations = computed<Delegation[]>(() =>
        delegations.value.filter((item: Delegation) => {
          return moment
            .utc(picked.value)
            .endOf('day')
            .isBetween(
              moment.utc(item.date).startOf('day'),
              moment.utc(item.endDate).endOf('day')
            )
        })
      )

      const allColumns = computed(() =>
        isFiltered.value
          ? captableColumns.value
          : captableColumns.value.filter((c) => c.selected)
      )
      const existingColumns = computed(() =>
        allColumns.value.filter((x) => !x.filterable)
      )

      selectedColumns.value = []
      const columns = computed(() =>
        [
          ...allColumns.value.filter((x) =>
            selectedColumns.value.includes(x.field)
          ),
          ...existingColumns.value
        ].sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      const handleFilterSelected = (value: string[]) => {
        selectedColumns.value = value
      }

      const resetFilters = () => {
        filter.value = {
          compositionAliasesId: [],
          optionAliasesId: [],
          stockAliasesId: [],
          contactId: [],
          delegationsId: []
        }
        picked.value = new Date().toISOString()
        selectedStocks.value = []
        selectedContact.value = []
        selectedGroup.value = ''
        selectedStockGroup.value = ''
        selectedDelegs.value = []
      }

      const updateStock = ({ value }: any) => {
        if (!value) {
          delete filter.value.optionAliasesId
          delete filter.value.stockAliasesId
          delete filter.value.compositionAliasesId
          getCaptable()
          return
        }
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        value.forEach((v: string) => {
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === v)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })

        selectedStocks.value = value
      }
      const updateGroup = (group: any) => {
        if (group === '') delete filter.value.contactGroupId
        else filter.value.contactGroupId = group

        selectedGroup.value = group
      }

      const updateStockGroup = (stockGroup: any) => {
        if (stockGroup === '') delete filter.value.stockGroupId
        else filter.value.stockGroupId = stockGroup

        selectedGroup.value = stockGroup
      }

      const updateContact = (contactId: string[]) => {
        selectedContact.value = contactId
        filter.value.contactId = contactId
      }

      const handleDelegationChange = (delegationsId: string[]) => {
        selectedDelegs.value = delegationsId
        filter.value.delegationsId = delegationsId
      }

      const download = (key: MAINACTIONS) => {
        isDownloading.value = true
        const companyId = route.params.id
        const data: any = { ...filter.value, date: picked.value }
        if (includeDelegationsAvailableBalance.value)
          data.includeDelegationsAvailableBalance = true
        if (key === MAINACTIONS.CONTACTS_RECORD_XLS) {
          store.dispatch('organizations/EXPORT_COMPANY_CAPTABLE', {
            companyId,
            filter: data,
            name: 'Captable',
            onSuccess: () => {
              isDownloading.value = false
            },
            onError: () => {
              isDownloading.value = false
            }
          })
        } else {
          store.dispatch('organizations/EXPORT_COMPANY_CAPTABLE_PDF', {
            companyId,
            filter: data,
            name: 'Captable',
            onSuccess: () => {
              isDownloading.value = false
            },
            onError: () => {
              isDownloading.value = false
            }
          })
        }
      }

      const getStocks = (search?: string) => {
        const filter: any = {}
        if (search) filter.name = search
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: route.params.id,
          filter
        })
      }

      const handleContactSearch = (search: string) => {
        getContacts(search)
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 100,
            offset: 0,
            name: search
          }
        })
      }

      const getCaptable = async (page = 1, companyId = route.params.id) => {
        const data: any = { ...filter.value, date: picked.value }
        if (includeDelegationsAvailableBalance.value)
          data.includeDelegationsAvailableBalance = true
        else delete data.includeDelegationsAvailableBalance
        await store.dispatch('organizations/GET_CAPTABLE', {
          companyId,
          filter: data,
          page,
          onSuccess: () => {
            isLoading.value = false
          }
        })
      }

      const handleDelegationSearch = (search = '') => {
        store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)
        store.dispatch('organizations/GET_CAPTABLE_DELEGATIONS', {
          companyId: route.params.id,
          filter: { search }
        })
      }
      const getDelegations = (date = '') => {
        const filter: any = {}
        if (date) filter.usableAtDate = moment(picked.value).toISOString()
        store.dispatch('organizations/GET_CAPTABLE_DELEGATIONS', {
          companyId: route.params.id,
          filter: { ...filter }
        })
      }

      const getStockGroups = () => {
        store.dispatch('organizations/GET_COMPANY_STOCKS_GROUPS', {
          companyId: route.params.id,
          filter: {}
        })
      }

      onBeforeMount(async () => {
        getStocks()
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 100,
            offset: 0
          }
        })
        getDelegations()
        getStockGroups()
      })

      onMounted(async () => {
        isLoading.value = true
        getCaptable()
      })

      onBeforeUnmount(() => {
        getDelegations()
        store.commit('organizations/SET_CAPTABLE', {
          metadata: { count: 0, currentPage: 1, lastPage: 1 },
          data: {
            lines: [],
            allFD: 0,
            allNFD: 0,
            percentageFD: 0,
            totalAmount: 0,
            totalFD: 0,
            totalNFD: 0
          }
        })
      })

      watch(
        [
          selectedStocks,
          selectedGroup,
          selectedStockGroup,
          () => selectedDelegs.value,
          () => selectedContact.value,
          () => includeDelegationsAvailableBalance.value
        ],
        () => {
          store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)
          getCaptable()
        }
      )

      watch(picked, (val: any) => {
        getDelegations(val)

        if (val) filter.value.date = moment(val).toISOString()
        else delete filter.value.date
        getCaptable()
      })

      watch(
        () => route.params.id,
        (val) => {
          filter.value = {}
          selectedGroup.value = ''
          selectedStockGroup.value = ''
          selectedContact.value = []
          selectedStocks.value = []
          selectedDelegs.value = []
          picked.value = new Date()
          getCaptable(1, val)
        }
      )

      return {
        picked,
        captable,
        groups,
        isDownloading,
        selectedStocks,
        selectedContact,
        selectedGroup,
        selectedStockGroup,
        contacts,
        columns,
        CoOwnershipType,
        isLoading,
        pagination,
        isFiltered,
        companyGroups,
        companyStockGroups,
        handleContactSearch,
        updateStock,
        updateContact,
        updateGroup,
        updateStockGroup,
        formatNumber,
        onPageChange,
        download,
        rgbToHex,
        includeDelegationsAvailableBalance,
        moment,
        // openStockDetails,
        availableColumns,
        handleFilterSelected,
        selectedColumns,
        resetFilters,
        currentCompany,
        NumberType,
        delegations,
        eventDelegations,
        handleDelegationSearch,
        handleDelegationChange,
        selectedDelegs,
        getStockGroups
      }
    }
  })
</script>

<style lang="scss">
  .captable-filters {
    @apply flex justify-between items-start w-full;

    .filters-date {
      .button-filter {
        &:hover img {
          filter: brightness(0) invert(1);
          -webkit-filter: brightness(0) invert(1);
        }
      }
    }

    .filters {
      @apply flex items-start;

      .filter {
        @apply w-1/4 mr-2 flex flex-col;
      }
    }
  }

  .captable-table {
    @apply flex flex-col w-full;

    .associates {
      color: black;
    }

    .contact-name {
      color: black;
    }
    .groups {
      white-space: nowrap;
    }
    .nfd,
    .fd {
      background-color: var(--color-primary-lower) !important;
    }

    .stocks {
      width: 30%;
    }
  }

  .sticky {
    background: #f5f7fa;
  }
</style>
