import axios from '../axios'
import { CompanyVesting } from '../models/company'
import { company } from '../endpoints'
import { VestingForm } from '@/types/forms'
import { CancelTokenSource } from 'axios'
let vestingsCancelToken: CancelTokenSource | null = null

const createVesting = async (companyId: string, formData: VestingForm) => {
  return await axios.post(company.createVesting(companyId), formData)
}

const updateVesting = async (
  companyId: string,
  vestingId: string,
  formData: VestingForm
) => {
  return await axios.put(company.singleVesting(companyId, vestingId), formData)
}
const getVestings = async (companyId: string, filter = {}) => {
  if (typeof vestingsCancelToken != undefined) {
    vestingsCancelToken?.cancel(
      'Operation (VESTINGS_GET) canceled due to new request.'
    )
  }
  vestingsCancelToken = axios.CancelToken.source()
  return await axios.get(company.createVesting(companyId), {
    cancelToken: vestingsCancelToken.token,
    params: { limit: 30, offset: 0, ...filter }
  })
}

const getVesting = async (
  companyId: string,
  id: string
): Promise<CompanyVesting> => {
  const { data } = await axios.get(company.singleVesting(companyId, id), {
    params: { limit: 30, offset: 0 }
  })
  return data
}

const removeVesting = async (companyId: string, id: string) => {
  return axios.delete(company.singleVesting(companyId, id))
}

export { createVesting, getVesting, getVestings, removeVesting, updateVesting }
