<template>
  <div class="container-fluid account p-0 h-full flex">
    <div
      class="
        account-leftPanel
        h-auto
        flex
        justify-start
        flex-col
        items-center
        border-r border-secondary3
      "
    >
      <h3 class="text uppercase text-left w-full text-secondary9">
        {{ $t('account.menu.label') }}
      </h3>
      <ul class="account-leftPanel-list w-full">
        <router-link :to="{ name: 'accountInformations' }">
          <li>{{ $t('account.menu.accountInformations') }}</li>
        </router-link>
        <router-link :to="{ name: 'accountPassword' }">
          <li>{{ $t('account.menu.accountAuthentifications') }}</li>
        </router-link>

        <router-link
          v-if="
            $can.any(['admin', 'is-manager', 'write-users', 'write-companies'])
          "
          :to="{ name: 'accountCompanies' }"
          :class="{
            'router-link-active router-link-exact-active':
              $route.name === 'accountCompanies.details'
          }"
        >
          <li>{{ $t('account.menu.accountCompanies') }}</li>
        </router-link>
        <router-link
          v-if="$can.any(['admin', 'is-manager', 'write-users', 'read-users'])"
          :to="{ name: 'accountUsers' }"
          :class="{
            'router-link-active router-link-exact-active':
              $route.name === 'accountUsers'
          }"
        >
          <li>{{ $t('account.menu.accountUsers') }}</li>
        </router-link>

        <router-link
          v-can.any="['admin', 'is-manager', 'write-alerts']"
          :to="{ name: 'accountAlertSetting' }"
          :class="{
            'router-link-active router-link-exact-active':
              $route.name === 'accountAlertSetting'
          }"
        >
          <li>{{ $t('account.menu.alerts') }}</li>
        </router-link>

        <!-- <router-link
          v-if="$can.any(['admin', 'is-manager'])"
          :to="{ name: 'technicalIntegration' }"
          :class="{
            'router-link-active router-link-exact-active':
              $route.name === 'technicalIntegration'
          }"
        >
          <li>{{ $t('account.menu.developer') }}</li>
        </router-link> -->

        <router-link
          v-if="$can.any(['admin', 'manager-diy'])"
          :to="{ name: 'integratedApps' }"
          :class="{
            'router-link-active router-link-exact-active': [
              'integratedApps',
              'integratedApp'
            ].includes($route.name)
          }"
        >
          <li>{{ $t('account.menu.integratedApps') }}</li>
        </router-link>
      </ul>
    </div>
    <div class="flex account__wrapper w-full pb-10">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'UplAccount'
  })
</script>

<style lang="scss">
  @import '@/scss/account.scss';
</style>
