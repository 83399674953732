<template>
  <div v-if="isLoading" class="flex justify-center items-center">
    <p>{{ $t('global.loadingPage') }}</p>
  </div>
  <div v-else>
    <component :is="Unauthorized" v-if="!canAccess"></component>
    <router-view v-else></router-view>
  </div>
</template>
<script lang="ts">
  import userCanSee from '@/middlewares/canSee'
  import { computed, defineComponent, ref, onMounted } from 'vue'
  import Unauthorized from '@/views/auth/unauthorized.vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'

  export default defineComponent({
    setup() {
      const route = useRoute()
      const store = useStore()
      const isLoading = ref(true)
      const canAccess = computed(() => userCanSee(route))

      onMounted(() => {
        store.dispatch(`auth/GET_USER_PROFILE`, {
          onError: async (data: any) => {
            //
          },
          onSuccess: () => {
            setTimeout(() => {
              isLoading.value = false
            }, 3500)
          }
        })
      })
      return { Unauthorized, canAccess, isLoading }
    }
  })
</script>
