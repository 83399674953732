
  import { computed, defineComponent, onBeforeUnmount, ref } from 'vue'
  import Tab from '@/components/Tab/index.vue'
  import OptionTab from './tabs/OptionsTab.vue'
  import HolderTab from './tabs/HolderTab.vue'
  import { useStore } from 'vuex'
  import { ExerciseCalendar } from '@/services/api/models'
  export default defineComponent({
    components: { Tab },
    setup() {
      const store = useStore()
      const tabList = [
        { key: 'holder', langKey: 'byHolder', component: HolderTab },
        { key: 'stock', langKey: 'byStock', component: OptionTab }
      ]
      const tabData = ref({
        stock: []
      })
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )

      onBeforeUnmount(() =>
        store.commit('organizations/SET_EXERCISE_CALENDARS', {
          ...exerciceCalendars,
          data: []
        })
      )
      return { tabData, tabList }
    }
  })
