
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Form, Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import {
    OptionStockTypeFormData,
    SampleStockTypeFormData,
    StockAliasFormProps,
    StockToggleFields
  } from '@/types'
  import { stockTypesData } from '@/utils'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { CompanyModel } from '@/services/api/models1'
  import SampleStockAliasForm from './SampleStockForm.vue'
  import OptionStockAliasForm from './OptionStockForm.vue'
  import CompositeStockAliasForm from './CompositeStockForm.vue'
  import ToggleFields from '@/components/Forms/Configurable/Stock/ToggleFields.vue'
  import {
    CompositionStockAlias,
    OptionStockAlias,
    OptionStockCategory,
    StockAlias,
    StockCategory
  } from '@/services/api/models'
  import { STOCKTYPE } from '@/types/config'
  import { PARITYTYPE, STOCKTYPESALIAS } from '@/types/forms'
  import {
    CREATE_STOCK,
    EDIT_STOCK_ALIAS
  } from '@/store/modules/Organization/models'
  import { ElPopconfirm } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'
  import Loader from '@/components/Loader/index.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import ColorPicker from '@/components/ColorPicker/index.vue'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: {
      Field,
      Form,
      Multiselect,
      ElPopconfirm,
      Btn: Button,
      Loader,
      SampleStockAliasForm,
      OptionStockAliasForm,
      CompositeStockAliasForm,
      ToggleFields,
      ColorPicker,
      Checkbox,
      Input
    },

    setup() {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const route = useRoute()
      const newStockType = ref<STOCKTYPE | null>()
      const stock = ref<any>()
      const selectedGroup = ref<string>()
      const colorCode = ref<string | null>()
      const canVote = ref<boolean>(true)
      const voteParity = ref<number | null>(1)
      const optionCategories = computed<OptionStockCategory[]>(
        () => store.getters['toolbox/optionCategories']
      )
      const isLoadingStock = computed(
        () => store.getters['organizations/isLoadingStock']
      )
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-stocks'))
      )
      const stockCategories = computed<OptionStockCategory[]>(
        () => store.getters['toolbox/stockCategories']
      )
      const currentStock = computed<
        StockAlias | CompositionStockAlias | OptionStockAlias
      >(() => store.getters['organizations/currentStockAlias'])

      const category = ref<OptionStockCategory | StockCategory>()
      const defaultToggleFields = () => ({
        inESOP: currentStock.value?.inESOP ?? category.value?.inESOP ?? true,
        inFD: currentStock.value?.inFD ?? category.value?.inFD ?? true,
        notInTransactionsRegister:
          currentStock.value?.notInTransactionsRegister ||
          STOCKTYPE.OPTION !== newStockType.value,
        inNFD:
          currentStock.value?.inNFD ??
          category.value?.inNFD ??
          newStockType.value === STOCKTYPE.OPTION,
        inValuation:
          currentStock.value?.inValuation ?? category.value?.inValuation ?? true
      })

      const defaultDisabledToggleFields = () => ({
        inESOP: category.value?.inESOPDisabled ?? false,
        inFD: category.value?.inFDDisabled ?? false,
        notInTransactionsRegister: false,
        inNFD:
          category.value?.inNFDDisabled ??
          newStockType.value === STOCKTYPE.OPTION,
        inValuation: category.value?.inValuationDisabled ?? false
      })

      const toggleFields = ref<StockToggleFields>(defaultToggleFields())

      const disabledToggleFields = ref<StockToggleFields>(
        defaultDisabledToggleFields()
      )

      const stockGroups = computed(
        () => store.getters['organizations/stockGroups']
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const persistStockAlias = (
        data: SampleStockTypeFormData | OptionStockTypeFormData,
        type: STOCKTYPESALIAS,
        edition = false
      ) => {
        let action = CREATE_STOCK

        const formData: StockAliasFormProps = {
          companyId: currentCompany.value.id,
          form: {
            ...data,
            ...toggleFields.value,
            groupId: selectedGroup.value,
            colorCode: colorCode.value || '#3b69da',
            canVote: canVote.value,
            voteParity: canVote.value ? voteParity.value : null
          },
          type
        }

        if (!formData.form.groupId) {
          delete formData.form.groupId
        }
        if (!formData.form.colorCode) {
          delete formData.form.colorCode
        }
        if (edition) {
          action = EDIT_STOCK_ALIAS
          formData['stockId'] = currentStock.value.id
        }

        if (newStockType.value !== STOCKTYPE.OPTION) {
          delete formData.form.notInTransactionsRegister
        }
        store.dispatch(`organizations/${action}`, {
          ...formData,
          onSuccess: () => {
            resetList()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('stockBook.form.title'),
              message: t(
                `stockBook.apiResponse.${edition ? 'edited' : 'created'}`
              )
            })
          },
          onError: (error = null) => {
            const message =
              error ||
              t(`stockBook.apiResponse.${edition ? 'edit' : 'create'}Error`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('stockBook.form.title'),
              message
            })
          }
        })
      }
      const stockTypes = ref(stockTypesData)

      const getGroups = (search = '') => {
        store.dispatch('organizations/GET_COMPANY_STOCKS_GROUPS', {
          companyId: currentCompany.value.id ?? route.params.id,
          filter: { search }
        })
      }

      const getStockCategories = () => {
        store.dispatch('toolbox/GET_STOCK_CATEGORIES', {
          companyId: currentCompany.value.id ?? route.params.id,
          filter: {}
        })
      }

      const getOptionsCategories = () => {
        store.dispatch('toolbox/GET_OPTION_CATEGORIES', {
          companyId: currentCompany.value.id ?? route.params.id,
          filter: {}
        })
      }

      const resetList = () => {
        newStockType.value = null
        selectedGroup.value = ''
        colorCode.value = null
        canVote.value = true
        voteParity.value = 1
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: currentCompany.value.id ?? route.params.id
        })
        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: false,
          initCurrentForm: true
        })
        toggleFields.value = defaultToggleFields()
      }
      const stockForm = computed(() => store.getters['organizations/form'])

      const updateFields = (fields: StockToggleFields) => {
        toggleFields.value = fields
      }

      const deleteCompanyStock = () => {
        store.dispatch(`organizations/REMOVE_STOCK_ALIAS`, {
          companyId: currentCompany.value.id ?? route.params.id,
          stockId: currentStock.value.id,
          type:
            newStockType.value === STOCKTYPE.SAMPLE
              ? STOCKTYPESALIAS.STOCK
              : newStockType.value === STOCKTYPE.OPTION
              ? STOCKTYPESALIAS.OPTION
              : STOCKTYPESALIAS.COMPOSITION,
          onSuccess: () => {
            resetList()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('stockBook.form.title'),
              message: t('stockBook.apiResponse.deleted')
            })
          },
          onError: (error = null) => {
            const message = error || t('stockBook.apiResponse.deleteError')
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('stockBook.form.title'),
              message
            })
          }
        })
      }

      const applyChanges = (form: OptionStockTypeFormData) => {
        const canDisable =
          form.parityType === PARITYTYPE.VARIABLE && form.parity <= 0
        toggleFields.value.inFD = !canDisable
        disabledToggleFields.value.inFD = canDisable
        category.value = optionCategories.value.find(
          (x) => x.id === form.optionCategoryId
        )
      }

      const applyStockChanges = (form: SampleStockTypeFormData) => {
        category.value = stockCategories.value.find(
          (x) => x.id === form.stockCategoryId
        )
      }

      const handleToggle = (value: boolean) => {
        canVote.value = value
      }

      watch(stockForm, (newStockForm) => {
        if (newStockForm.initCurrentForm && !currentStock.value)
          newStockType.value = null
        else newStockType.value = newStockForm.stockType
      })

      watch([newStockType, category], () => {
        toggleFields.value = defaultToggleFields()
        disabledToggleFields.value = defaultDisabledToggleFields()
      })

      watch(
        () => currentStock.value,
        (newValue) => {
          stock.value = newValue
          newStockType.value = newValue?.type ?? null
          selectedGroup.value = newValue?.group?.id ?? ''
          colorCode.value = newValue?.colorCode ?? null
          canVote.value = newValue?.canVote ?? true
          voteParity.value = newValue?.voteParity ?? 1

          toggleFields.value = defaultToggleFields()
        }
      )

      onMounted(() => {
        getOptionsCategories()
        getStockCategories()
        getGroups()

        console.log('currentCompany', currentCompany.value)
      })

      return {
        stock,
        canDo,
        isLoadingStock,
        selectedGroup,
        colorCode,
        canVote,
        voteParity,
        stockGroups,
        stockTypes,
        newStockType,
        STOCKTYPE,
        toggleFields,
        currentStock,
        disabledToggleFields,
        persistStockAlias,
        updateFields,
        deleteCompanyStock,
        applyChanges,
        applyStockChanges,
        handleToggle
      }
    }
  })
