<template>
  <div class="flex w-full h-full">
    <div
      v-if="canDo"
      class="relative panel-left-display contacts-leftPanel-search"
    >
      <div class="upl-treeview__toggle z-50">
        <i
          v-show="showTreeview"
          class="bi-chevron-compact-left"
          @click="showTreeview = false"
        />
        <i
          v-show="!showTreeview"
          class="bi-chevron-compact-right"
          @click="showTreeview = true"
        />
      </div>

      <keep-alive>
        <DocumentTreeview
          ref="documentTreeview"
          :show-toggle="false"
          :class="`${!showTreeview ? 'hidden' : 'block pt-5'}`"
        />
      </keep-alive>
    </div>

    <div class="contacts-content w-full h-100 p-5 bg-main1 relative pb-20">
      <ContactForm @saved="createNewContact" />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    CompanyModel,
    ContactStatus,
    IndividualContact,
    InvestorContact,
    ProfessionalContact
  } from '@/services/api/models'
  import { sortObjectByKeys } from '@/utils'
  import { computed, defineComponent, ref, onMounted } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import ContactForm from './form.vue'
  import DocumentTreeview from '@/components/DocumentTree/index.vue'
  import { useAcl } from 'vue-simple-acl/src'
  export default defineComponent({
    components: {
      ContactForm,
      DocumentTreeview
    },

    setup(props, { emit }) {
      const store = useStore()
      const showTreeview = ref(true)
      const filter = ref<any>({
        name: '',
        contactStatusId: '',
        type: '',
        contactGroupId: ''
      })
      const documentDisplayed = ref(true)
      const isVisible = ref(false)
      const isLoadingData = ref(false)
      const router = useRouter()
      const route = useRoute()
      const contactStatusId = ref('')
      const contactType = ref('')

      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          acl.permission('is-manager') ||
          acl.permission('write-contacts')
      )
      const filtered = computed(
        () =>
          aliasesId.value?.length !== 0 ||
          contactType.value !== '' ||
          contactStatusId.value != '' ||
          filter.value.name != ''
      )
      const displayForm = ref(!!route.params.contactId)
      const groupsDisplay = ref<any>({})
      const aliasesId = ref([])
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const currentContact = computed<
        IndividualContact | ProfessionalContact | InvestorContact
      >(() => store.getters['organizations/contactDetails'])

      const allContactGroups = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )
      const allGroups = computed<
        (IndividualContact | ProfessionalContact | InvestorContact)[]
      >(() => store.getters['organizations/contactsResult'])
      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )
      const groups = computed(() => {
        const noGroupContacts = allGroups.value.filter(
          (c) => c.contactGroups?.length == 0
        )
        const map = new Map()
        if (noGroupContacts.length > 0) map.set('zz-other', noGroupContacts)
        allGroups.value
          .filter((c) => c.contactGroups?.length)
          .forEach((elm, i) => {
            elm?.contactGroups?.forEach((group) => {
              const collection = map.get(group.name)
              if (!collection) {
                map.set(group.name, [elm])
              } else {
                collection.push(elm)
              }
            })
          })

        return sortObjectByKeys(map)
      })
      const handleSelection = ({ selected }: any) => {
        filter.value = { ...filter.value, ...selected }
      }

      const createNewContact = (value: boolean) => {
        displayForm.value = !value ? value : canDo.value

        router.push({
          name: 'company.contact',
          params: { id: route.params.id }
        })
      }

      const handleUserSelection = (contact: any) => {
        displayForm.value = canDo.value
        router.push({
          name: 'company.contact',
          params: { id: route.params.id, contactId: contact?.id }
        })
      }

      const getContacts = () => {
        if (!currentCompany.value) return
        store.dispatch('organizations/FILTER_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: { ...filter.value, limit: 10, offset: 0 }
        })
      }

      const getLegalStatus = () => {
        store.dispatch('toolbox/GET_LEGAL_STATUS')
      }

      onMounted(getLegalStatus)
      return {
        showTreeview,
        canDo,
        documentDisplayed,
        currentContact,
        filtered,
        isVisible,
        filter,
        aliasesId,
        displayForm,
        handleSelection,
        getContacts,
        handleUserSelection,
        createNewContact
      }
    }
  })
</script>
<style lang="scss" scoped>
  @import '@/scss/contacts.scss';
</style>
