
  import {
    Commitment,
    CommitmentPart,
    CompanyModel
  } from '@/services/api/models'
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { formatNumber, rgbToHex } from '@/utils'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'
  import DataTable from '@/components/DataTable/index.vue'

  export default defineComponent({
    components: { ContactBadge, Tag, EmptyState, Loader, DataTable },
    setup() {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()
      const events = ref<CommitmentPart[]>()
      const isLoading = ref(false)
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const total = computed(() =>
        (events.value ?? [])?.reduce((acc, x) => (acc += x.quantity), 0)
      )
      const columns = computed(() => [
        {
          label: t('datatable.column.stock'),
          field: 'stock',
          custom: true
        },
        {
          label: t('datatable.column.label'),
          field: 'label',
          custom: true
        },
        {
          label: t('datatable.column.quantity'),
          field: 'quantity',
          custom: true
        },
        {
          label: t('datatable.column.commitmentType'),
          field: 'commitmentType',
          custom: true
        },
        {
          label: t('datatable.column.commitmentMode'),
          field: 'commitmentMode',
          custom: true
        },
        {
          label: t('datatable.column.ownershipType'),
          field: 'ownershipType',
          custom: true
        },
        {
          label: t('datatable.column.startDate'),
          field: 'startDate',
          custom: true
        },
        {
          label: t('datatable.column.endDate'),
          field: 'endDate',
          custom: true
        }
      ])
      const getEvents = (contactId = route.params.contactId) => {
        store.dispatch('organizations/GET_COMMITMENTS', {
          companyId: currentCompany.value?.id,
          filter: {
            contactsId: [contactId]
          },
          onSuccess: (data: any) => {
            events.value = [...data]
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }
      onMounted(() => {
        isLoading.value = true
        getEvents()
      })
      return {
        isLoading,
        events,
        columns,
        moment,
        total,
        currentCompany,
        rgbToHex,
        formatNumber
      }
    }
  })
