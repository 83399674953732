import { computed, reactive, watchEffect, Plugin, App } from 'vue'
import createAuth0Client, {
  Auth0Client,
  GetIdTokenClaimsOptions,
  GetTokenSilentlyOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
  PopupLoginOptions,
  RedirectLoginOptions,
  User
} from '@auth0/auth0-spa-js'
import { NavigationGuardWithThis } from 'vue-router'
import store from '@/store'
import { error } from 'loglevel'
import conf from '@/config'
import { GlobalConfig } from '@/types/config'
import { clearStorage, getFormStorage } from '@/services/Storage'
import { storageKeys } from '@/utils'
const config = conf as GlobalConfig

export interface Auth0PluginState {
  loading: boolean
  isAuthenticated: boolean
  user: User | undefined
  popupOpen: boolean
  error: any
}

interface Auth0PluginOptions {
  domain: string
  clientId: string
  audience: string
  redirectUri: string
  locale: string
  onRedirectCallback(appState: any): void
}

interface Auth0Plugin {
  init(options: Auth0PluginOptions): Promise<Plugin>
  routeGuard: NavigationGuardWithThis<undefined>
}
let client: Auth0Client

export const getInstance = (): Auth0Client => client

const state = reactive<Auth0PluginState>({
  loading: true,
  isAuthenticated: false,
  user: {},
  popupOpen: false,
  error: null
})

/**
 * Authenticates the user using a popup window
 *
 * @param {Object} o
 */
async function loginWithPopup(o: PopupLoginOptions) {
  state.popupOpen = true

  try {
    await client.loginWithPopup(o)
  } catch (e) {
    console.error(e)
  } finally {
    state.popupOpen = false
  }

  state.user = await client.getUser()
  state.isAuthenticated = true
}

/**
 * Handles the callback when logging in using a redirect
 *
 * @param {Object} o
 */
async function handleRedirectCallback() {
  state.loading = true
  try {
    await client.handleRedirectCallback()
    state.user = await client.getUser()
    state.isAuthenticated = true
  } catch (e) {
    state.error = e
  } finally {
    state.loading = false
  }
}

/**
 * Authenticates the user using the redirect method
 *
 * @param {Object} o
 */
function loginWithRedirect(o: RedirectLoginOptions) {
  return client.loginWithRedirect(o)
}

/**
 * Returns all the claims present in the ID token
 *
 * @param {Object} o
 */
function getIdTokenClaims(o: GetIdTokenClaimsOptions) {
  return client.getIdTokenClaims(o)
}

/**
 * Returns the access token. If the token is invalid or missing,
 * a new one is retrieved
 *
 * @param {Object} o
 */
function getTokenSilently(o: GetTokenSilentlyOptions) {
  return client.getTokenSilently(o)
}

/**
 * Gets the access token using a popup window
 *
 * @param {Object} o
 */
function getTokenWithPopup(o: GetTokenWithPopupOptions) {
  return client.getTokenWithPopup(o)
}

/**
 * Logs the user out and removes their session on the authorization server
 *
 * @param {Object} o
 */
function logout(o: LogoutOptions) {
  clearStorage()
  return client.logout({ returnTo: window.location.origin })
}

function logoutWithoutRedirect(path: string) {
  clearStorage()
  return client.logout({ returnTo: path })
}

const authPlugin = {
  isAuthenticated: computed(() => state.isAuthenticated),
  loading: computed(() => state.loading),
  user: computed(() => state.user),
  getIdTokenClaims,
  getTokenSilently,
  getTokenWithPopup,
  handleRedirectCallback,
  loginWithRedirect,
  loginWithPopup,
  logout,
  logoutWithoutRedirect
}

/**
 * Authorization guard to protect routes in our app from unauthorized users
 *
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
const routeGuard: NavigationGuardWithThis<undefined> = (
  to,
  from,
  next: any
) => {
  const { isAuthenticated, loading, loginWithRedirect } = authPlugin

  const verify = () => {
    if (isAuthenticated.value) {
      if (to.name === 'connectLogin') {
        return next('/account')
      }

      return next()
    } else {
      const targetUrl = '/redirecting'

      loginWithRedirect({
        appState: { targetUrl },
        redirectMethod: 'replace'
      })
    }
  }

  // If loading has already finished, check our auth state using `fn()`
  if (!loading.value) {
    return verify()
  }

  // Watch for the loading property to change before we check isAuthenticated
  watchEffect(() => {
    if (loading.value === false) {
      return verify()
    }
  })
}

async function init(options: Auth0PluginOptions): Promise<Plugin> {
  const { onRedirectCallback, redirectUri = window.location.origin } = options
  client = await createAuth0Client({
    domain: options.domain,
    client_id: options.clientId,
    audience: options.audience,
    redirect_uri: redirectUri,
    ui_locales: options.locale
  })
  state.loading = true

  //get companyId from url or from storage
  let cmp =
    window.location.pathname.split('/').length >= 2
      ? window.location.pathname.split('/')[2]
      : getFormStorage(storageKeys.currentCompany)

  const urlParams = new URLSearchParams(window.location.search)

  if (
    urlParams.get('state') &&
    window.location.search.split('?next').length > 1
  ) {
    cmp = urlParams.get('state')
  }

  try {
    if (
      window.location.search.includes('code=') &&
      window.location.search.includes('state=')
    ) {
      const { appState } = await client.handleRedirectCallback()

      onRedirectCallback(appState)
    }
  } catch (e) {
    console.log('Error', e)

    state.error = e
    if (error) error(state?.error || '')
  } finally {
    await store.dispatch(`auth/GET_USER_PROFILE`, {
      onSuccess: async () => {
        state.isAuthenticated = await client.isAuthenticated()
        state.user = await client.getUser()
        store.commit('auth/SET_AUTH_PROFILE', state.user)

        store.dispatch(`organizations/LOAD_COMPANY`, { companyId: cmp })

        state.loading = false
      },
      onError: () => {
        state.loading = false
      }
    })
    // await store.dispatch(`organizations/GET_LOADER_COMPANIES`, {})
    // Initialize our internal authentication state
  }

  return {
    install: (app: App) => {
      app.provide('Auth', authPlugin)
    }
  }
}

export const Auth0: Auth0Plugin = {
  init,
  routeGuard
}
