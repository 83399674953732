<template>
  <div :class="` h-full`">
    <div :class="`flex justify-start items-center h-10 ${classes}`">
      <Field
        v-slot="{}"
        :name="data.name"
        :placeholder="data.hint"
        :value="formValue"
        class="flex flex-row-reverse"
      >
        <label
          v-if="leftLabel"
          class="text-sm font-semibold text-secondary8 text-left mr-2 mb-0"
          >{{ data.label }}</label
        >
        <Checkbox
          :name="data.name"
          :checked="formValue"
          :label="leftLabel ? '' : data.label"
          @onChange="formValue = $event"
        />
      </Field>
    </div>
    <div :class="`mt-${data?.isNeeded && formValue ? 4 : 0} transition-all`">
      <slot :name="data.name" :value="formValue" />
    </div>
  </div>
</template>

<script lang="ts">
  import { Field } from 'vee-validate'
  import Checkbox from '@/components/Checkbox/index.vue'
  interface DataInputBool {
    name: string
    type: string
    label: string
    id: string
    hint?: string
    isNeeded?: boolean
  }
  import { defineComponent, ref, watch, toRefs, onMounted } from 'vue'
  export default defineComponent({
    name: 'CheckboxGeneric',
    components: { Checkbox, Field },
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputBool,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: '',
          isNeeded: false
        })
      },
      value: {
        type: Boolean,
        default: null
      },
      leftLabel: {
        type: Boolean,
        default: false
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: ''
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value } = toRefs(props)
      const formValue = ref(value.value ?? false)
      watch(
        () => formValue.value,
        () => {
          emit('onUpdated', {
            ...data.value,
            value: formValue.value
          })
        }
      )
      onMounted(() => {
        // formValue.value = props.value
      })
      return { formValue }
    }
  })
</script>
