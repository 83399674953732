<template>
  <div class="px-12">
    <div class="flex py-6">
      <h3 class="text text-blackp text-xl">
        {{ $t('company.chart') }}
      </h3>
    </div>
    <div class="flex py-3">
      <div class="w-1/4 pr-2">
        <label class="text-left font-semibold text-secondary9" for="">{{
          $t('global.placeholder.orgDate')
        }}</label>
        <UplDatePicker
          v-model="datePicked"
          class="rounded h-10 text-xs mr-2"
          placeholder="--/--/----"
        />
      </div>

      <div class="w-1/4 pr-2">
        <div class="flex flex-col w-full">
          <label class="text-left font-semibold text-secondary9" for="">{{
            $t('global.placeholder.orgCompanies')
          }}</label>
          <div>
            <Multiselect
              v-model="companyIds"
              name="companyId"
              :value="companyIds"
              :searchable="true"
              class="w-full min-h-full"
              :show-labels="true"
              mode="multiple"
              :options="companyOptions"
              element-name="global.company"
              :placeholder="
                $t('account.accountInformations.form.company.placeholder')
              "
              :filter-results="false"
              :groups="false"
              label="name"
              @change="companyIds = $event"
              @search-change="searchCompanies"
            >
            </Multiselect>
          </div>
        </div>
      </div>
      <div class="w-1/4 pr-2">
        <label class="text-left font-semibold text-secondary9" for="sourceId">{{
          $t('global.placeholder.orgRoot')
        }}</label>

        <dropdown-company
          v-model="sourceId"
          :value="sourceId"
          :has-label="false"
          :has-defaut="false"
          :pageCount="100"
          mode="single"
          @on-updated="sourceId = $event"
        />
      </div>

      <div class="w-1/6">
        <div>
          <label
            class="text-left font-semibold text-secondary9"
            for="dilution"
            >{{ $t('global.placeholder.orgDilution') }}</label
          >
          <Multiselect
            id="dilution"
            v-model="dilution"
            :value="dilution"
            class="w-full"
            mode="single"
            name="dilution"
            :options="dilutionTypes"
            placeholder="Dilution"
            :groups="false"
            @change="dilution = $event"
          />
        </div>
      </div>
      <div v-if="isLoaded" class="w-1/6 flex items-end">
        <div class="mb-1 text-xl">
          <Button
            class="ml-3 border border-primary4"
            variant="tertiary"
            size="medium"
            type="reset"
            :disabled="false"
            :label="
              companyIds.length > 0
                ? $t('global.actions.init')
                : $t('global.actions.fillAll')
            "
            @click="initForm"
          />
        </div>
      </div>
    </div>
    <div class="flex text-2xl py-3">
      <!-- -->
      <Button
        :disabled="isDefault || isLoading"
        variant="primary"
        class="danger sm error"
        icon="bi-trash xs"
        :label="$t('global.actions.downloadXml')"
        @click="download"
      />
    </div>
    <div class="relative">
      <div
        v-if="isLoading"
        class="
          flex
          w-full
          h-full
          justify-center
          items-center
          relative
          shadow-md
          mt-5
          p-5
        "
      >
        <Loader />
      </div>

      <div
        v-if="isDefault && !isLoading"
        class="flex justify-center items-center"
      >
        <p class="text-xl py-10 text-primary3">
          {{ $t('portfolioView.chartWelcome') }}
        </p>
      </div>

      <div
        v-show="!isLoading"
        class="overflow-y-scroll h-full w-full shadow-md mt-5 p-5"
        ref="graphContainer"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'

  import factory from 'mxgraph'
  import { useStore } from 'vuex'
  import { TYPE } from 'vue-toastification'
  import { Company, OrganigramDilutionEnum } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'
  import { useAcl } from 'vue-simple-acl/src'
  import { Pagination } from '@/store/modules/Organization/models'

  const mx = factory({
    mxBasePath: ''
  })
  export default defineComponent({
    components: {
      Button,
      Multiselect,
      DropdownCompany,
      UplDatePicker,
      Loader
    },
    setup() {
      const acl = useAcl()
      const store = useStore()
      const { t } = useI18n()
      const companyIds = ref<string[]>([])

      const sourceId = ref<any>('')
      const datePicked = ref(moment.utc().toISOString())
      const dilution = ref(OrganigramDilutionEnum.inNFD)
      const isLoading = ref(false)
      const isLoaded = ref(false)
      const graphContainer: any = ref(null)
      let layout: any = null
      let xml: any = null
      let graph: any = null
      let model: any = null
      const cCompanies = ref<Company[]>([])
      const isDefault = ref(true)
      const selectedContainer = ref<Company[]>([])
      const companyOptions = ref<Company[]>([])
      const companies = computed<Company[]>(() =>
        [
          ...cCompanies.value,
          ...store.getters['organizations/loaderCompanies'],
          ...selectedContainer.value
        ].filter((x) => companyIds.value.includes(x.id))
      )
      const dilutionTypes = computed(() =>
        Object.values(OrganigramDilutionEnum).map((x) => ({
          name: t(`dilution.${x}`),
          id: x
        }))
      )
      const searchCompanies = (selected: any) => {
        if (selected) getCompanies(selected)
      }

      const download = () => {
        const name = `OrganizationalChart_${moment
          .utc(datePicked.value)
          .format('DD-MM-yyyy')}-Uplaw.drawio`
        var pom = document.createElement('a')
        var bb = new Blob([xml], { type: 'text/xml' })

        pom.setAttribute('href', window.URL.createObjectURL(bb))
        pom.setAttribute('download', name)

        pom.dataset.downloadurl = ['text/xml', pom.download, pom.href].join(':')
        pom.draggable = true
        pom.classList.add('dragout')

        pom.click()
      }

      const getCompanies = (text: string) => {
        store.dispatch('organizations/GET_LOADER_COMPANIES', {
          filter: { search: text },
          onSuccess: (data: Company[]) => {
            cCompanies.value = [...data].filter(
              (x) => !companyOptions.value.map((y) => y.id).includes(x.id)
            )
            const ids = [...data].map((x) => x.id)
            companyOptions.value = [
              ...cCompanies.value,
              ...companyOptions.value
            ].sort((a, b) => (ids.includes(a.id) ? -1 : 1))
          }
        })
      }
      const handleForm = () => {
        isLoading.value = true
        const cmpIds = [...companyIds.value]
        if (!cmpIds.includes(sourceId.value)) cmpIds.push(sourceId.value)
        let formData = {
          data: {
            date: datePicked.value || moment().toISOString(),
            topCompanyId: sourceId.value,
            companiesId: cmpIds,
            dilution: dilution.value,
            isDraft: false
          }
        }
        store.dispatch(`organizations/CREATE_COMPANY_ORGANIGRAM`, {
          ...formData,
          onError: (error: any) => {
            isLoading.value = false

            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: 'Organigramme',
              message:
                'Une erreur est survenue lors de la création de votre organigramme'
            })
          },
          onSuccess: (data: any) => {
            // mx.undoManager.clear()
            if (model) model.clear()

            if (graph)
              graph.removeCells(
                graph.getChildVertices(graph.getDefaultParent())
              )
            if (!data) return
            // initForm()
            // resetForm()
            buildChart(data)
            isLoading.value = false

            store.dispatch('toolbox/displayToast', {
              type: TYPE.DEFAULT,
              context: 'Organigramme',
              message: 'Organigramme créé avec succès'
            })
          }
        })
      }

      const initForm = () => {
        if (companyIds.value.length > 0) companyIds.value = []
        else initCompanies()
      }

      const buildChart = (chartData: any) => {
        isDefault.value = false
        graphContainer.value.innerHTML = ''
        let vx: any[] = []
        console.log('VERSION', mx.mxClient.VERSION)
        if (mx.mxClient.isBrowserSupported()) {
          // console.log('Yes! Yes!')
        }
        graph = new mx.mxGraph(graphContainer.value)
        model = graph.getModel()

        model.clear()
        graph.setPanning(true)
        var style = graph.getStylesheet().getDefaultVertexStyle()
        style[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_SWIMLANE
        style[mx.mxConstants.STYLE_VERTICAL_ALIGN] = 'middle'
        style[mx.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'white'
        style[mx.mxConstants.STYLE_FONTSIZE] = 20
        style[mx.mxConstants.STYLE_STARTSIZE] = 25
        style[mx.mxConstants.STYLE_HORIZONTAL] = false
        style[mx.mxConstants.STYLE_FONTCOLOR] = 'black'
        style[mx.mxConstants.STYLE_STROKECOLOR] = '#6482B9'
        style[mx.mxConstants.STYLE_FILLCOLOR] = 'lightblue'
        style[mx.mxConstants.STYLE_EDGE] = mx.mxEdgeStyle.ElbowConnector
        style[mx.mxConstants.STYLE_ENDARROW] = mx.mxConstants.ARROW_CLASSIC
        style[mx.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'none'
        style = mx.mxUtils.clone(style)
        style[mx.mxConstants.STYLE_SHAPE] = mx.mxConstants.SHAPE_RECTANGLE
        style[mx.mxConstants.STYLE_ROUNDED] = true
        style[mx.mxConstants.STYLE_HORIZONTAL] = true
        style[mx.mxConstants.STYLE_VERTICAL_ALIGN] = 'middle'
        delete style[mx.mxConstants.STYLE_STARTSIZE]
        style[mx.mxConstants.STYLE_LABEL_BACKGROUNDCOLOR] = 'none'
        style[mx.mxConstants.STYLE_FILLCOLOR] = 'lightblue'
        graph.getStylesheet().putCellStyle('process', style)
        new mx.mxSwimlaneManager(graph)

        graph.getStylesheet().getDefaultEdgeStyle()['curved'] = '4'
        // Use mouse right button for panning
        // Gets the default parent for inserting new cells. This
        // is normally the first child of the root (ie. layer 0).
        let root = null
        const parent = graph.getDefaultParent()
        model.beginUpdate()

        //If we want display only linked relations and linked companies
        let existingCompanies = (chartData.edges ?? [])
          .filter(
            (y: any) =>
              y.destination?.toLowerCase() !==
              chartData.root_company.company_name?.toLowerCase()
          )
          .flatMap((x: any) => [
            x.source?.toLowerCase(),
            x.destination?.toLowerCase()
          ])

        try {
          vx = chartData.vertexes
            // .filter((x: any) =>
            //   existingCompanies.includes(x.company_name?.toLowerCase())
            // )
            .map((v: any) =>
              graph.insertVertex(
                parent,
                'v1',
                v.company_name.toUpperCase(),
                300,
                40,
                v.company_name.length <= 11 ? 230 : v.company_name.length * 15,
                50,
                'process'
              )
            )
          root = vx.find(
            (v: any) =>
              v.value === chartData.root_company.company_name.toUpperCase()
          )

          let lineStyle = 'strokeColor=rgb(96,96,96);'
          chartData.edges.forEach((e: any) => {
            const s = vx.find(
              (x: any) => x.value.toUpperCase() === e.source.toUpperCase()
            )
            const d = vx.find(
              (x: any) => x.value.toUpperCase() === e.destination.toUpperCase()
            )

            graph.insertEdge(parent, null, `${e.percentage}`, s, d, lineStyle)
          })

          graph.isCellEditable = function () {
            return false
          }
          graph.isCellMovable = function (cell: any) {
            if (model.isEdge(cell)) {
              return false
            } else return true
          }
        } finally {
          model.endUpdate()
          layout = new mx.mxHierarchicalLayout(graph, 'north')
          var encoder = new mx.mxCodec()
          var result = encoder.encode(model)
          console.log(layout, graph.getDefaultParent(), parent)
          layout.parallelEdgeSpacing = 250
          layout.orientation = 'north'
          layout.forceConstant = 80

          xml = mx.mxUtils.getXml(result)
          layout.execute(parent, root)
        }
      }

      const loadCompanies = (offset: number) => {
        store.dispatch('organizations/GET_LOADER_COMPANIES', {
          filter: { search: '', limit: 100, offset, text: '' },
          onSuccess: (data: Company[], metadata: Pagination) => {
            companyOptions.value.push(...data)
            companyIds.value.push(...data.map((x) => x.id))

            if (
              acl.anyCan(['is-manager']) &&
              metadata.lastPage != metadata.currentPage
            ) {
              loadCompanies(companyOptions.value.length)
            } else {
              isLoaded.value = true
            }
          }
        })
      }

      const initCompanies = () => {
        loadCompanies(0)
      }

      watch(
        [() => datePicked.value, () => sourceId.value, () => dilution.value],
        ([date, source, dilution]) => {
          if (source && dilution) handleForm()
        }
      )

      watch(
        () => companyIds.value,
        (cmpIds) => {
          if (sourceId.value && dilution.value && Array.isArray(cmpIds))
            handleForm()
        }
      )

      onMounted(() => {
        initCompanies()
      })

      return {
        graphContainer,
        dilution,
        dilutionTypes,
        companyIds,
        searchCompanies,
        companies,
        isLoaded,
        companyOptions,
        isDefault,
        sourceId,
        isLoading,
        initForm,
        datePicked,
        handleForm,
        download
      }
    }
  })
</script>
