<template>
  <div class="h-full w-full bg-primary12 flex justify-center items-center">
    <Loader v-if="isLoading" />
    <div
      v-else-if="hasError"
      class="flex justify-between w-2/5 xl:w-1/4 rounded p-2 error-container"
    >
      <div class="w-1/5 flex justify-center items-center">
        <img class="my-1 w-16" src="@/assets/images/logo/uplaw_icon.png" />
      </div>
      <div class="p-2">
        <p class="text-lg text-primary5 font-bold">
          {{ $t('account.accountInformations.reset_password') }}
        </p>
        <span v-html="$t('account.accountInformations.expiredPage')"></span>
      </div>
      <div class="1/6 flex justify-center items-center">
        <span
          class="
            border
            px-2
            py-1
            rounded
            border-secondary6
            icon
            cursor-pointer
            mr-1
          "
          @click="gotoLogin()"
        >
          <i class="bi bi-box-arrow-in-up-right text-secondary6"></i>
        </span>
      </div>
    </div>
    <div
      v-else
      class="w-2/5 xl:w-1/4 h-3/4 flex flex-col items-center justify-center"
    >
      <img
        class="w-48 my-8"
        src="https://uploads-ssl.webflow.com/61f7c66c94334412498f2920/625f0340b2348b9acb3e5091_uplaw%20final%20(1).png"
      />

      <div class="w-full bg-white border-t-4 border-primary4 px-4 py-5">
        <p class="text-center font-bold text-2xl text-black text-primary5">
          {{ $t('account.accountInformations.reset_password') }}
        </p>
        <div class="flex justify-center items-center">
          <span class="my-3 px-2 border border-secondary3 rounded"
            >{{ $t('global.welcome') }}
            {{ `${user?.lastName || ''} ${user?.firstName}` }}</span
          >
        </div>

        <Form
          v-slot="{ errors }"
          ref="myForm"
          :validation-schema="schema"
          class="mt-5"
          @submit="handleForm"
        >
          <div>
            <label class="text-left" for="">{{
              $t('account.accountInformations.form.password.label')
            }}</label>
            <Field v-slot="{ field }" v-model="form.password" name="password">
              <Input
                v-model="form.password"
                v-bind="field"
                :error="
                  errors['password'] ? $t(`errors.${errors['password']}`) : ''
                "
                type="password"
                name="password"
                :placeholder="
                  $t('account.accountInformations.form.password.placeholder')
                "
              />
            </Field>
          </div>

          <div>
            <label class="text-left" for="">{{
              $t('account.accountInformations.form.password_confirm.label')
            }}</label>
            <Field
              v-slot="{ field }"
              v-model="form.confirmation"
              name="confirmation"
            >
              <Input
                v-model="form.confirmation"
                v-bind="field"
                :error="
                  errors['confirmation']
                    ? $t(`errors.${errors['confirmation']}`)
                    : ''
                "
                name="confirmation"
                type="password"
                :placeholder="
                  $t(
                    'account.accountInformations.form.password_confirm.placeholder'
                  )
                "
              />
            </Field>
          </div>

          <div class="text-2xl mt-5 flex justify-end items-end">
            <Button
              type="submit"
              variant="custom"
              :disabled="Object.keys(errors).length > 0"
              class="bg-primary4 text-white rounded hover:bg-primary5"
              :label="$t('global.actions.save')"
            ></Button>
          </div>
          <p class="text-center my-5 text-md text-black">
            {{ $t('account.accountInformations.password_after') }}
          </p>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, inject, onMounted, ref } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field, Form } from 'vee-validate'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import Loader from '@/components/Loader/index.vue'
  import { User } from '@/services/api/models'
  import * as yup from 'yup'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { getInstance } from '@/plugins/auth'
  import { GlobalConfig } from '@/types'
  import conf from '@/config'

  export default defineComponent({
    components: { Input, Field, Form, Button, Loader },
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()
      const Auth: any = inject('Auth')
      const form = ref({ password: '', confirmation: '' })
      const user = ref<User | null>(null)
      const token = computed(() => route.query?.token)
      const isLoading = ref(true)
      const hasError = ref(false)
      const email = computed(() =>
        Buffer.from(token.value?.toString() || '', 'base64').toString('binary')
      )
      const config = conf as GlobalConfig
      const schema = yup.object({
        password: yup.string().min(8).required('passwordRequired'),
        confirmation: yup
          .string()
          .oneOf([yup.ref('password')], 'passwordMissmatch')
      })
      const gotoLogin = () => {
        router.replace({ name: 'auth.connect' })
      }

      const handleForm = () => {
        isLoading.value = true
        store.dispatch('auth/SET_NEW_PASSWORD', {
          userId: user.value?.id,
          data: { ...form.value, token: token.value },
          onSuccess: () => {
            isLoading.value = false
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              message: t(
                `account.accountInformations.apiResponse.successPasswordEdition`
              ),
              context: t('account.context')
            })
            gotoLogin()
          },
          onError: (err: string) => {
            isLoading.value = false
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              message: err
                ? t(`account.accountInformations.apiResponse.${err}`)
                : t(
                    `account.accountInformations.apiResponse.errorPasswordEdition`
                  ),
              context: t('account.context')
            })
            // hasError.value = true
          }
        })
      }

      onMounted(async () => {
        const client = getInstance()
        if (await client.isAuthenticated()) {
          Auth.logoutWithoutRedirect(
            `${config.auth0Options.redirectUri}${route.fullPath}`
          )
        }

        store.dispatch('auth/VERIFY_USER_BY_TOKEN', {
          token: token.value,
          onSuccess: (response: any) => {
            hasError.value = !response.data
            user.value = response.data
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
            hasError.value = true
          }
        })
      })

      return {
        email,
        hasError,
        isLoading,
        schema,
        form,
        user,
        handleForm,
        gotoLogin
      }
    }
  })
</script>
<style lang="scss" scoped>
  .error-container {
    -webkit-box-shadow: 1px 2px 21px 7px rgba(133, 128, 246, 0.24);
    box-shadow: 1px 2px 21px 7px rgba(133, 128, 246, 0.24);
    .icon {
      &:hover {
        @apply border-primary4;
      }
      &:hover i {
        @apply text-primary4;
      }
    }
  }
</style>
