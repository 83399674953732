const auth0Options = {
  clientId: 'bTgYWpEhneSyKbuiQLvx6WbLx2tO7uaI',
  domain: 'auth.uplaw.fr'
}

const baseUrls = {
  development: {
    host: `https://app.up.law/`
  },
  production: {
    host: `https://back-service.up.law/`
  },
  staging: {
    host: `https://back-service.up.law/`
  }
}

const googleMap = {
  apiKey: 'AIzaSyDj3F7iUyFg45_rjD_V7_EDA3kIFi5c6wg'
}

const environement = `staging`

export { environement, baseUrls, auth0Options, googleMap }
