import { actions } from './actions'
import { mutations } from './mutations'
import { getters } from './getters'
import { Module } from 'vuex'
import { OrganizationState } from './models'
import { RootState } from '@/store'
import { STOCKTYPE } from '@/types/config'

const state: OrganizationState = {
  isLoadingStock: false,
  template: null,
  currentOperation: null,
  isLoadingVesting: false,
  operations: [],
  delegationsSunsets: [],
  delegationsSunsetsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  },
  companyGlobalData: null,
  esopData: null,
  fundingData: null,
  companyCapitalByGroup: [],
  profitSharingEvolution: [],
  companyCapitalByStockCategory: [],
  companyCapitalByEsopCategory: [],
  eventPartLinkedDocuments: {},
  currentPartContactId: null,
  contactsPicture: {},
  companiesLogo: {},
  usersProfilesImages: {},
  profitSharingPlans: [],
  fundRaisingRounds: [],
  currentFundRaisingRound: null,
  currentProfitSharingPlan: null,
  documentSearchResult: [],
  accountUsers: [],
  userAccountsPagination: { total: 0, lastPage: 1, currentPage: 1, limit: 10 },
  currency: '€',
  searching: false,
  searchedText: null,
  loadingUserData: false,
  loadingCompanyData: false,
  companies: [],
  loaderCompanies: [],
  documents: [],
  allDocuments: [],
  ceilings: [],
  delegations: [],
  committees: [],
  functions: [],
  nominations: [],
  transfers: [],
  commitments: [],
  vestingSuspension: [],
  vestingAcceleration: [],
  vestingDelayProrogation: [],
  approvals: [],
  financialEndings: [],
  primaryStockEmission: [],
  primaryOptionsEmission: [],
  primaryCompositionEmission: [],
  documentList: [],
  stockAliases: [],
  optionStockAliases: [],
  currentCommittee: null,
  currentStock: null,
  compositionStockAliases: [],
  exerciseCalendars: [],
  exerciseCalendarsTotal: null,
  vestingSyntheses: [],
  loadingEvents: false,
  event: null,
  loadingDocuments: false,
  gettingDocuments: false,
  contactExtraData: {},
  delegatedTransactions: [],
  templates: [],
  templatesPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 20
  },
  delegTransactionsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 20
  },
  exerciseCalendarPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 50
  },
  delegationsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 20
  },
  contactsResult: [], //used to keep contacts filtered listing while loading contacts in phoneBook page
  forms: {
    modalInit: false,
    initCurrentForm: false,
    stockType: STOCKTYPE.SAMPLE
  },
  documentsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 20
  },
  companiesPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 20
  },
  captablePagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 200
  },
  nominationsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  },
  committeesPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  },
  accountsPagination: { total: 0, lastPage: 1, currentPage: 1, limit: 20 },
  currentDocument: null,
  company: undefined,
  contextCompany: undefined,
  isLoading: false,
  companyContacts: [],
  companyStocks: [],
  vestings: [],
  currentVesting: null,
  currentCompanyUuid: null,
  contacts: [],
  groups: [],
  group: null,
  downloading: false,
  stockGroup: null,
  stockGroups: [],
  currentContact: null,
  currentOrganigram: null,
  contactDetails: null,
  contactAudits: [],
  eventAudits: [],
  accounts: [],
  documentAudits: [],
  attachedFiles: [],
  nominalValue: 1,
  nominalValues: [],
  conversions: [],
  transactions: [],
  capitalReductions: [],
  transactionsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 100
  },
  auditPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  },
  eventsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  },
  searchedEventsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  },
  searchingEvents: false,
  searchedEvents: [],
  captable: null,
  contactsList: null,
  corporateDocuments: [],
  corporateEvents: [],
  events: [],
  allEvents: [],
  bodac: [],
  alerts: [],
  notifications: [],
  alertsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  },
  contactListPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 30
  },
  exerciseCalendarSynthesisPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 20
  },
  notificationsPagination: {
    total: 0,
    lastPage: 1,
    currentPage: 1,
    limit: 10
  }
}

const module: Module<OrganizationState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
