<template>
  <div
    ref="root"
    :class="`w-full flex flex-col selector m-custom ${
      rootWidth && rootWidth > 170 ? 'full-adapt' : 'min-adapt'
    } ${classes}`"
    @click.stop.prevent
  >
    <Multiselect
      ref="multiselect"
      v-model="selectedValue"
      :model-value="selectedValue"
      :group-select="true"
      :searchable="true"
      :strict="false"
      :group-hide-empty="true"
      :clear-on-search="true"
      :group-options="groupOptionsField"
      value-prop="id"
      :track-by="label"
      :group-label="groupLabel"
      :label="label"
      :close-on-select="mode === 'single'"
      :caret="true"
      :open-direction="openDirection"
      :filterResults="filterResults"
      :placeholder="placeholder"
      :groups="groups"
      :options="options"
      :search-start="false"
      :disabled="disabled"
      :mode="mode"
      :hide-selected="false"
      @clearOnBlur="onBlur"
      @search-change="handleSearch"
      @change="valueChanged"
      @clear="onOptionChanged"
    >
      <template #[addActionSlot]>
        <slot :name="addActionSlot" />
      </template>

      <template #noresults>
        <span class="text-center p-3">{{ $t('select.noResult') }}</span>
      </template>
      <template #nooptions>
        <span class="text-center p-3">{{ $t('select.noOptions') }}</span>
      </template>
      <template #singlelabel="{ value: labelValue }">
        <slot name="singlelabel" :item="labelValue" />
      </template>
      <template #option="{ option }">
        <div
          class="w-full text-sm min-h-10 py-2 flex justify-between items-center"
        >
          <slot name="option" :option="option">
            {{ option[groupLabel] }}
          </slot>
        </div>
      </template>
      <template #tag="{ option, handleTagRemove }">
        <slot name="tag" :option="option" :handleTagRemove="handleTagRemove">
          <div class="">
            <Tag
              :text="option.name"
              :value="option.id"
              :with-initials="tagWithInitials"
              @on-remove="handleTagRemove(option, $event)"
            />
          </div>
        </slot>
      </template>

      <template #multiplelabel="{ values }">
        <slot name="multiplelabel" :values="values">
          <div
            v-if="values.length > 1"
            class="ml-3 multiselect-multiple-label inline-block"
          >
            {{ values.length }}
            {{ $tc(elementName, values.length) }}
            {{ $tc(selectionText, values.length) }}
          </div>
          <div
            v-if="values && values.length === 1"
            class="ml-3 multiselect-multiple-label"
          >
            {{ values[0].name }}
          </div>
        </slot>
      </template>
    </Multiselect>
    <div v-show="hasError" class="upl--error">
      {{ error ? error : '' }}
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onErrorCaptured,
    onMounted,
    reactive,
    ref,
    watch
  } from 'vue'
  import { Tag } from '@up.law/uplaw-ui'
  import Multiselect from '@vueform/multiselect'
  import { MULTISELECTMODE } from '@/types'

  export default defineComponent({
    name: 'UplMultiSelect',
    components: { Multiselect, Tag },
    props: {
      error: { type: String, required: false, default: null },
      options: {
        type: Object as () => Array<any>,
        required: true
      },
      groupOptionsField: { type: String, required: false, default: 'children' },
      groupLabel: { type: String, required: false, default: 'name' },
      label: { type: String, required: false, default: 'name' },
      elementName: {
        type: String,
        required: false,
        default: 'global.categorySelected'
      },
      selectionText: {
        type: String,
        required: false,
        default: 'global.actions.selected'
      },
      classes: { type: String, required: false, default: '' },
      placeholder: { type: String, required: false, default: 'Select' },
      openDirection: { type: String, required: false, default: 'bottom' },
      value: {
        type: [String, Object as () => Array<string>, Number],
        default: null
      },
      filterResults: { type: Boolean, required: false, default: true },
      tagWithInitials: { type: Boolean, required: false, default: true },
      groups: { type: Boolean, required: false, default: false },
      disabled: { type: Boolean, required: false, default: false },
      mode: { type: String, required: false, default: MULTISELECTMODE.SINGLE }
    },
    emits: ['change', 'open', 'search-change'],
    setup(props, { emit }) {
      const root = ref<HTMLDivElement | null>(null)
      props = reactive(props)

      const addActionSlot = computed(() =>
        props.mode === 'top' ? 'afterlist' : 'beforelist'
      )

      const isSearching = ref(false)

      onErrorCaptured((err) => {
        console.log(err.message)
      })

      const rootWidth = computed(() => root.value?.clientWidth)
      const searchStart = ref(false)
      const hasError = computed((): boolean => props.error !== null)
      const selectedValue = ref<string | string[] | number>(props.value)
      const onOptionChanged = () => {
        emit('change', props.mode === MULTISELECTMODE.SINGLE ? '' : [])
      }
      const valueChanged = (value: any) => {
        emit(
          'change',
          value || (props.mode === MULTISELECTMODE.SINGLE ? '' : [])
        )
      }
      const handleSearch = (value: string) => {
        isSearching.value = true
        // if (!value) return
        emit('search-change', value, true)
      }

      const onBlur = () => {
        isSearching.value = false
      }

      watch(props, (newProps) => {
        selectedValue.value = newProps.value
      })

      return {
        hasError,
        selectedValue,
        root,
        isSearching,
        searchStart,
        rootWidth,
        addActionSlot,
        onBlur,
        onOptionChanged,
        valueChanged,
        handleSearch
      }
    }
  })
</script>

<style lang="scss">
  .full-adapt {
    .multiselect-dropdown {
      width: calc(100% + 1px);
      overflow-x: hidden;
      // z-index: 5 !important;
    }
  }
  .min-adapt {
    .multiselect-dropdown {
      min-width: 170px;
      width: fit-content;
      // z-index: 5 !important;
    }
  }
  .m-custom {
    min-height: 2.5em;

    .multiselect-tag {
      @apply bg-primary6 #{!important};
    }
    .upl--error {
      @apply text-error;
      line-height: 1.5em;
      height: 1.5em;
      font-size: 0.8em;
      text-align: left;
    }
    .multiselect-dropdown {
      max-height: 20rem;
      //  box-shadow: 0 10px 10px #666;
      //-moz-box-shadow: 10px 10px 5px #888;
      // -webkit-box-shadow: 10px 10px 5px #888;
      //  box-shadow: 10px 10px 5px #888;
      @apply border border-secondary4  bg-white #{!important};

      .multiselect-options {
        max-height: fit-content;
        .multiselect-group {
          .multiselect-group-label {
            @apply h-10 #{!important};
          }
          .multiselect-group-label.is-selected {
            @apply bg-primary4 #{!important};
          }
        }
      }
    }
    .multiselect.is-open.is-active {
      @apply border-primary4 #{!important};
    }
    .multiselect {
      justify-content: start !important;
      height: 100% !important;
      min-width: 0;
      position: relative;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      box-shadow: none !important;
      @apply shadow-none border border-inputBorder box-border px-2 #{!important};

      .multiselect-tags {
        padding-left: 1.6em;
        margin-top: 0;
      }
      .multiselect-search {
        padding-left: 30px !important;
      }
      .multiselect-placeholder {
        margin-left: 20px;
        right: 0;
        padding-right: 20px !important;
        @apply text-secondary8 overflow-hidden #{!important};
      }
      .multiselect-multiple-label {
        display: flex;
        flex-flow: row wrap;
        position: relative;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
      }
      .multiselect-single-label {
        width: 100%;
        overflow: hidden;
        padding-left: 20px;
        color: #5f6165 !important;
        @apply text-sm;

        .multiselect-single-label-text {
          margin-left: 10px;
        }
      }
      .multiselect-clear-icon {
        display: none;
        @apply text-secondary7 #{!important};
      }
      .multiselect-caret {
        z-index: auto;
        margin-right: 14px;
        position: absolute;
        left: 0;
        margin-left: 10px;
        font-weight: 900;
        width: 1em;
        mask-image: url('../../scss/icons/chevron-bottom.svg') !important;
        @apply text-placeholder bg-placeholder #{!important};
      }

      .multiselect-clear {
        position: absolute;
        right: 1.5%;
        padding: 0 !important;
      }

      &:hover {
        .multiselect-clear-icon {
          display: inline-block;
          @apply text-secondary7 #{!important};
        }
      }
      .multiselect-tags {
        width: 100%;
        overflow: auto;
        .multiselect-tag {
          @apply whitespace-pre-line #{!important};
        }
      }
      ul.multiselect-options {
        border-right: none !important;

        .multiselect-option {
          @apply text-xs;
        }
      }
      ul.multiselect-group-options {
        border-right: none !important;
        .multiselect-option {
          padding-left: 2em !important;
          .is-pointed {
            @apply bg-secondary3 bg-secondary3 #{!important};
          }
        }
        .multiselect-option.is-selected {
          @apply bg-white #{!important};
        }
      }
      .multiselect-option.is-selected {
        position: relative;
        @apply text-blackp bg-white #{!important};
      }
      .multiselect-option.is-selected::before {
        content: '\2713';
        display: inline-block;
        vertical-align: middle;
        @apply right-0 text-xl absolute mr-2 text-primary4 font-semibold;
      }
    }
  }
</style>
