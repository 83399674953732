
  import { Input, Button } from '@up.law/uplaw-ui'
  import Box from '@/components/Box/index.vue'
  import { defineComponent, watch, ref, computed } from 'vue'
  import { useStore } from '@/store'
  import CompanyResult from '@/components/CompanySearchResultCard/index.vue'
  import NewCompanyFrom from './form.vue'
  import { useI18n } from 'vue-i18n'
  import { ElMessageBox } from 'element-plus'
  import { Form } from 'vee-validate'
  import { comapnySchema } from '@/utils/schema/contact'
  import { CompanyModelForm } from '@/types'
  import * as yup from 'yup'
  import { CompanyModel } from '@/services/api/models'
  import { useRouter } from 'vue-router'
  import { ElDialog, ElRadioGroup } from 'element-plus'

  export default defineComponent({
    name: 'CompanyAdd',
    components: {
      NewCompanyFrom,
      ElDialog,
      ElRadioGroup,
      Button,
      Form,
      Box,
      UplInput: Input,
      CompanyResult
    },
    props: {
      isVisible: {
        type: Boolean,
        required: true
      }
    },
    emits: ['onClose'],
    setup(props, { emit }) {
      const search = ref('')
      const router = useRouter()
      const canSubmit = ref(false)
      const { t } = useI18n()
      const nominalValue = ref(1)
      const newValues = (): CompanyModelForm => ({
        additionalAddress: null,
        commercialName: '',
        name: '',
        lastPricePerAction: '',
        country: '',
        contactCanSimulatePotentialGain: false,
        hideStocksTabForEmployees: false,
        hideTransactionsTabForEmployees: false,
        populateSimulationForm: false,
        includesActionsInSimulation: false,
        includeCommitmentsTabForEmployees: false,
        email: null,
        phoneNumber: '',
        address: '',
        postalCode: '',
        city: '',
        legalStatusId: '',
        siret: '',
        siren: '',
        tva: '',
        constitutedAt: '',
        capital: 0
      })
      const newContact = ref<CompanyModelForm>(newValues())
      const newCompany = ref<CompanyModelForm>(newValues())

      const store = useStore()
      const currentStep = ref<number>(0)
      let isModalVisible = ref(props.isVisible)
      const companyResult = computed(() => store.getters['auth/companies'])
      const userSelection = computed(() => store.getters['auth/company'])
      const selectedCompany = ref<string>('')

      const schemas = [
        yup.object({
          search: yup.string().optional()
        }),
        comapnySchema
      ]

      const currentSchema = computed(() => {
        return schemas[currentStep.value]
      })
      const beforeModalClose = () => {
        ElMessageBox.confirm(t('newCompany.leaveCompanyConfirmation'), '', {
          confirmButtonText: t('confirm.yes'),
          cancelButtonText: t('confirm.no')
        })
          .then(() => {
            store.commit('auth/SET_COMPANIES', [])
            currentStep.value = 0
            emit('onClose')
          })
          .catch((err) => {
            console.log('Error', err)
          })
      }

      const handleModalDisplay = (value: boolean) => {
        isModalVisible.value = value
      }

      const userEnterText = (event: any) => {
        if (event.target?.value && event.target?.value.length < 3) return
        store.dispatch('auth/searchCompanies', {
          query: event.target?.value,
          onError: () => {
            console.log('Error')
          },
          onSuccess: () => {
            console.log('Success')
          }
        })
      }
      const previousStep = () => {
        currentStep.value--
      }

      const loadCompanyInformations = async (companyId: string) => {
        store.dispatch(`organizations/GET_COMPANIES`, {}).then(() => {
          store
            .dispatch(`organizations/GET_LOADER_COMPANIES`, {
              filter: {}
            })
            .then(() => {
              //
            })
        })
      }

      const setCurrentCompany = async (company: CompanyModel) => {
        await loadCompanyInformations(company.id)
      }
      const savedCompany = (data: CompanyModel) => {
        if (data.id) setCurrentCompany(data)

        emit('onClose')

        currentStep.value = 0
        location.reload()
      }

      const nextStep = () => {
        if (currentStep.value === 0 && selectedCompany.value) {
          store.dispatch('auth/getCompanyInformations', {
            siret: selectedCompany.value
          })
        }

        if (currentStep.value >= 0 && currentStep.value <= 1)
          currentStep.value++
      }

      watch(search, (val) => {
        store.dispatch('auth/searchCompanies', {
          query: val,
          onError: () => {
            console.log('Error')
          },
          onSuccess: () => {
            console.log('Success')
          }
        })
      })

      watch(userSelection, (val) => {
        if (val) {
          newContact.value = { ...val }
          newContact.value.commercialName = val.name
        }
      })

      watch(
        () => props.isVisible,
        (val) => {
          isModalVisible.value = val
        }
      )

      return {
        search,
        savedCompany,
        newCompany,
        companyResult,
        isModalVisible,
        currentStep,
        canSubmit,
        userSelection,
        nextStep,
        previousStep,
        selectedCompany,
        beforeModalClose,
        userEnterText,
        currentSchema,
        newContact,
        nominalValue,
        handleModalDisplay
      }
    }
  })
