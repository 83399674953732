
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Form, Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import {
    OptionStockTypeFormData,
    PARITYTYPE,
    STOCKTYPE,
    STOCKTYPESALIAS
  } from '@/types'
  import { optionStockSchema } from '@/utils'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models1'
  import Multiselect from '@/components/Select/multiselect.vue'
  import FormActions from '@/components/Forms/ModalActions/index.vue'
  import { OptionStockAlias, StockAlias } from '@/services/api/models'
  import { CompositionStockAlias } from '@/services/api/models/company'
  import { useI18n } from 'vue-i18n'
  import {
    STOCK_PRIMARY,
    STOCK_TERTIARY
  } from '@/store/modules/Organization/models'
  import { useAcl } from 'vue-simple-acl/src'
  import { TimeUnit } from '@/types/config'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    components: {
      Input,
      Button,
      Form,
      Field,
      Multiselect,
      FormActions,
      ElTooltip
    },
    props: {
      initForm: {
        type: Boolean,
        required: false,
        default: false
      },
      values: {
        type: Object as () => OptionStockAlias,
        required: false,
        default: () => ({})
      }
    },
    emits: ['saveChanges', 'applyChanges'],

    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-stocks'))
      )

      const choiceUnit = computed(() =>
        Object.values(TimeUnit).map((x) => ({
          name: t(`events.elements.${x.toLowerCase()}s`),
          id: x
        }))
      )

      const resetForm = (): OptionStockTypeFormData => ({
        alias: '',
        optionCategoryId: '',
        underlayingStockId: '',
        underlayingCompositionId: '',
        parityType: PARITYTYPE.FIXED,
        parity: 1,
        inValuation: false,
        inNFD: false,
        inFD: false,
        inESOP: false,
        exercisePrices: [],
        notInTransactionsRegister: false,
        expirationNumberOfTimeUnits: 10,
        expirationTimeUnit: TimeUnit.YEARS
      })
      const parityTypes = ref([
        {
          id: 'FIXED',
          value: 'FIXE',
          name: t('global.fixed')
        },
        {
          id: 'VARIABLE',
          value: 'VARIABLE',
          name: t('global.variable')
        }
      ])
      const prices = ref<any[]>([])
      const newStock = ref<any>(props.values?.id ? props.values : resetForm())

      const newVal = props.values?.parity
        ? PARITYTYPE.VARIABLE
        : PARITYTYPE.FIXED

      newStock.value.parityType = newVal

      const parityTypeValue = ref(false)

      const currency = computed(() => store.getters['organizations/currency'])

      const simples = ref<StockAlias[]>([])
      const compositionStockAlias = ref<CompositionStockAlias[]>([])
      const stocks = computed(() => {
        return [
          {
            id: 1,
            name: t('companyStockType.SIMPLE'),
            label: t('companyStockType.SIMPLE'),
            value: 'stocks',
            group: simples.value
          },

          {
            id: 3,
            name: t('companyStockType.COMBINED'),
            label: t('companyStockType.COMBINED'),
            value: 'compositionStocks',
            group: compositionStockAlias.value
          }
        ]
      })
      const addPrice = () => {
        prices.value.push({ value: 0, edition: true })
      }
      const removePrice = (index: number) => {
        prices.value.splice(index, 1)
      }

      const handleStockChange = (id: string) => {
        newStock.value.underlayingStockId = id
      }

      const mustInitForm = computed(
        () => store.getters['organizations/form'].initCurrentForm
      )

      const updateParityType = (
        value: boolean,
        field: string,
        setFieldValue: (field: string, value: any) => void,
        setFieldError: (field: string, value: any) => void
      ) => {
        parityTypeValue.value = value
        const newVal = value ? PARITYTYPE.VARIABLE : PARITYTYPE.FIXED
        newStock.value.parityType = newVal

        setFieldValue('parityType', newVal)
        setFieldError('parityType', null)
        newStock.value.parityType = newVal
        newStock.value.exercisePrices = prices.value.map((x) => x.value)
        emit('applyChanges', newStock.value)
      }
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const stockCategories = computed(
        () => store.getters['toolbox/stockCategories']
      )
      const optionCategories = computed(
        () => store.getters['toolbox/optionCategories']
      )
      const schema = optionStockSchema

      const categoryChanged = (id: string) => {
        newStock.value.optionCategoryId = id
        newStock.value.parity = props.values?.parity || 1
        emit('applyChanges', newStock.value)
      }

      const parityTypeChanged = ($event: string) => {
        newStock.value.parityType = $event
        newStock.value.parity =
          newStock.value.parityType == PARITYTYPE.FIXED ? 1 : 0
        emit('applyChanges', newStock.value)
      }

      const handleForm = (formValues: OptionStockTypeFormData) => {
        const aliases = [...simples.value, ...compositionStockAlias.value]
        const alias = aliases.find(
          (x) => x.id === formValues.underlayingStockId
        )
        formValues.exercisePrices = [...prices.value].map((x) =>
          parseFloat(x.value.toString())
        )
        if (alias && alias?.type === STOCKTYPE.COMPOSITION) {
          formValues.underlayingStockId = null
          formValues.underlayingCompositionId = alias.id
        } else {
          newStock.value.exercisePrices = []

          formValues.underlayingStockId = alias?.id || null
          formValues.underlayingCompositionId = null
        }
        emit(
          'saveChanges',
          formValues,
          STOCKTYPESALIAS.OPTION,
          props.values && props.values.id
        )
      }

      const handleSearch = (search: string) => {
        getStockAlias(search)
        getCompositions(search)
      }

      const setData = (newProps: OptionStockAlias) => {
        parityTypeValue.value = newProps?.parityType === PARITYTYPE.VARIABLE
        newStock.value.optionCategoryId = newProps?.optionCategory?.id
        newStock.value.parityType = newProps?.parityType
        newStock.value.alias = newProps?.alias
        newStock.value.id = newProps?.id
        newStock.value.expirationNumberOfTimeUnits =
          newProps.expirationNumberOfTimeUnits
        newStock.value.expirationTimeUnit = newProps.expirationTimeUnit
        if (
          newProps?.underlayingStockAlias &&
          !simples.value
            .map((x) => x.id)
            .includes(newProps?.underlayingStockAlias?.id || '')
        ) {
          simples.value.push({
            ...newProps?.underlayingStockAlias,
            name: newProps?.underlayingStockAlias?.alias
          })
        }

        if (
          newProps?.underlayingCompositionAlias &&
          !compositionStockAlias.value
            .map((x) => x.id)
            .includes(newProps?.underlayingCompositionAlias?.id || '')
        ) {
          compositionStockAlias.value.push({
            ...newProps?.underlayingCompositionAlias,
            name: newProps?.underlayingCompositionAlias?.alias
          })
        }
        newStock.value.underlayingStockId =
          newProps?.underlayingStockAlias?.id ||
          newProps.underlayingCompositionAlias?.id ||
          null
        newStock.value.parity = parseFloat(newProps?.parity?.toString()) || 1.0
        newStock.value.inNFD = newProps?.inNFD || false
        newStock.value.inValuation = newProps?.inValuation || false
        newStock.value.inFD = newProps?.inFD || false
        newStock.value.inESOP = newProps?.inESOP || false
        prices.value = (newProps?.exercisePrices ?? []).map((x) => ({
          value: x,
          edition: false
        }))
      }

      const getStockAlias = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_STOCK_ALIAS', {
          companyId: currentCompany.value.id,
          filter,
          onSuccess: (data: StockAlias[]) => {
            simples.value = [...data].map((x) => ({
              ...x,
              name: x.alias.toUpperCase(),
              type: STOCKTYPE.SAMPLE,
              class: STOCK_PRIMARY
            }))
          }
        })
      }

      const getCompositions = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_COMPOSITION_STOCK_ALIAS', {
          companyId: currentCompany.value?.id,
          filter,
          onSuccess: (data: CompositionStockAlias[]) => {
            compositionStockAlias.value = [...data].map((x) => ({
              ...x,
              alias: x.alias.toUpperCase(),
              name: x.alias.toUpperCase(),
              type: STOCKTYPE.COMPOSITION,
              class: STOCK_TERTIARY
            }))
          }
        })
      }

      watch(mustInitForm, () => {
        newStock.value = resetForm()
      })

      watch(props, (newProps) => {
        setData(newProps.values)

        emit('applyChanges', newStock.value)
      })

      onMounted(() => {
        getStockAlias()

        getCompositions()

        setData(props.values)
      })

      return {
        schema,
        stockCategories,
        optionCategories,
        newStock,
        PARITYTYPE,
        parityTypeValue,
        stocks,
        parityTypes,
        prices,
        currency,
        canDo,
        choiceUnit,
        updateParityType,
        handleStockChange,
        handleForm,
        categoryChanged,
        addPrice,
        removePrice,
        handleSearch,
        parityTypeChanged
      }
    }
  })
