<template>
  <div>
    <div v-if="isDetailPage" :class="`${canEdit ? 'mt-14' : ''}`">
      <EditableHeader
        :fields="headerFields"
        :can-edit="canEditHeader"
        :is-editing="canEdit && !canEditHeader"
        @save="saveEventPrimaryInfo"
        @start-edition="canEditHeader = true"
        @cancel-edition="canEditHeader = false"
      />
    </div>
    <div class="my-4 mt-5 mb-1 flex justify-between items-center">
      <div v-if="isDetailPage" class="w-1/2">
        <div class="w-1/3">
          <dropdown-contacts
            v-model="selectedContacts"
            class="w-full"
            :placeholder="$tc('contactCard.filterPlaceholder')"
            mode="multiple"
            :filter-results="false"
            :can-add-new="false"
            open-direction="bottom"
            @update:modelValue="updateSelected"
          />
        </div>
      </div>
      <div
        v-if="canEdit"
        class="
          bg-primary1
          rounded
          p-2
          text-primary3
          cursor-pointer
          border border-primary2
        "
        @click="addNewLine()"
      >
        <span class="text-sm">{{ $t('events.actionList.newLine') }}</span>
      </div>
    </div>
    <editable
      v-if="!isLoadingParts"
      :grids="canEdit ? 11 : 9"
      :lines="lines"
      :columns="columns"
      :totalLine="totalLine"
      :can-delete-line="true"
      :can-edit-line="true"
      :filtered="{ partsId: filteredPartsId }"
      :display-total="!isOverLimit"
      @save-line="saveLine"
      @remove-part="removePart"
      @save-new-part="saveNewLine"
      @remove-new-line="removeNewLine"
      @reset-line="resetLine"
      @start-line-edition="startLineEdition"
    />
    <div v-else class="w-full flex justify-center items-center text-xs">
      <Loader></Loader>
    </div>

    <div v-if="isDetailPage" class="flex justify-end mt-5">
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        class="pr-0"
        :page-size="pagination.limit"
        :total="pagination.total"
        :current-page="pagination.currentPage"
        @current-change="handlePageSelection"
      >
      </el-pagination>
    </div>
    <div v-if="isOverLimit" class="flex justify-end items-end">
      <h5 class="py-3 px-2 text-md text-primary4">
        <router-link
          :to="{
            name: isPortfolio ? 'company.eventDetails' : 'company.eventDetails',
            params: { eventId: event?.id }
          }"
          >...{{ $t('global.seeMoreParts') }}</router-link
        >
      </h5>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onMounted, watch } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  import {
    CompanyModel,
    Event,
    EventPartsTotal,
    OptionSunset,
    OptionSunsetPart
  } from '@/services/api/models'
  import { useRoute } from 'vue-router'
  import { Pagination } from '@/store/modules/Organization/models'
  import Editable from './editable/index.vue'
  import { useI18n } from 'vue-i18n'
  import { EndOfContractReasons, NumberType, OptionSunsetMode } from '@/types'
  import { TYPE } from 'vue-toastification'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import { ElPagination } from 'element-plus'
  import Loader from '@/components/Loader/small.vue'
  import EditableHeader from './editable/header.vue'
  export default defineComponent({
    name: 'TransferCard',
    components: {
      DropdownContacts,
      Editable,
      Loader,
      EditableHeader,
      ElPagination
    },
    props: {
      event: {
        type: Object as () => Event | null,
        required: true
      },
      nominalValue: {
        type: Number,
        default: 0
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      isPortfolio: {
        type: Boolean,
        default: false
      },
      canEdit: { type: Boolean, default: false }
    },
    setup(props) {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()
      const total = ref<EventPartsTotal | null>(null)
      const selectedContacts = ref<string[]>([])
      const isLoadingParts = ref(true)
      const formData = ref<any>({
        date: props.event?.optionSunsets?.date,
        mode: props.event?.optionSunsets?.mode,
        type: props.event?.optionSunsets?.mode
      })
      const allParts = ref<OptionSunsetPart[]>([])

      const newLines = ref<any[]>([])
      const issuanceParts = ref<any[]>(
        props.event?.optionSunsets?.optionSunsetParts ?? []
      )
      const limit = computed<number>(
        () => store.getters['config/eventPartsLimit']
      )

      const newSavedHeader = ref<OptionSunset | null>(null)
      const pagination = ref<Pagination>({
        total: 0,
        currentPage: 1,
        lastPage: 1,
        limit: limit.value
      })

      const company = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const canEditHeader = ref(false)
      const isLocal = ref(false)

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const filteredPartsId = computed<string[]>(() =>
        ((props.filters as any)?.contactsId || []).flatMap((x: string) =>
          issuanceParts.value.filter((p) => p.contact.id == x).map((y) => y.id)
        )
      )

      const editedLinesData = ref<any[]>([])

      const totalLine = computed(() => {
        const v = [
          {
            name: 'Total',
            class: 'col-span-4 text-sm',
            key: 'total',
            width: '20%'
          },
          {
            name: '',
            key: 'quantity',
            value: '-',
            class: 'text-sm',
            width: '10%'
          },

          {
            name: '',
            key: 'issuedQuantity',
            value: '-',
            class: 'text-sm',
            width: '10%'
          },

          {
            name: '',
            key: 'unitPrice',
            value: '-',
            width: '10%',
            class: 'text-sm'
          },
          {
            name: '',
            key: 'total',
            value: `${formatNumber(total.value?.vestedQuantity ?? 0)}`,
            width: '10%',
            class: 'text-sm'
          },
          {
            name: '',
            key: 'entryDate',
            value: `${formatNumber(total.value?.notVestedQuantity ?? 0)}`,
            width: '10%',
            class: 'text-sm'
          }
        ]

        if (props.canEdit)
          v.push({
            name: '',
            key: 'actions',
            value: '',
            class: 'col-span-2',
            width: '20%'
          })

        return v
      })

      const data = computed(() => {
        const v = [
          {
            name: t('events.elements.contact'),
            id: 'contactId',
            width: '25%',
            class: 'text-left col-span-2',
            component: 'ClickableContactBadge',
            displayComponent: 'contact'
          },

          {
            name: t('events.elements.option'),
            width: '12.5%',
            id: 'stock',
            class: 'justify-start col-span-2',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.motive'),
            width: '12.5%',
            class: 'justify-end',
            id: 'motive',
            component: 'contact-generic'
          },

          {
            name: t('events.elements.nonVestedDate'),
            width: '12.5%',
            class: 'justify-end',
            id: 'nonVestedDate',
            component: 'contact-generic'
          },

          {
            name: t('events.elements.exercisableBalanceDate'),
            width: '12.5%',
            class: 'justify-end',
            id: 'exercisableBalanceDate',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.sunsetVestedQuantity'),
            width: '12.5%',
            class: 'justify-end',
            id: 'sunsetVestedQuantity',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.sunsetNotVestedQuantity'),
            width: '12.5%',
            class: 'justify-end',
            id: 'sunsetNotVestedQuantity',
            component: 'contact-generic'
          }
        ]

        if (props.canEdit)
          v.push({
            name: '',
            width: '22.22%',
            class: 'justify-end col-span-2',
            id: 'actions',
            component: 'actions',
            displayComponent: 'contact'
          })

        return v
      })

      const headerFields = computed(() => [
        {
          fieldId: 'date',
          class: 'justify-start',
          colName: t('events.elements.cancelDate'),
          component: 'p',
          editComponent: 'date-generic',
          componentProps: {
            class: 'text-blackp text-sm text-left',
            value: isLocal.value
              ? moment.utc(newSavedHeader.value?.date).format('l')
              : moment.utc(props.event?.optionSunsets?.date).format('l')
          },
          editComponentProps: {
            classes: 'mt-1.5 w-full',
            openDirection: 'bottom',
            value:
              newSavedHeader.value?.date ?? props.event?.optionSunsets?.date,
            data: { name: 'date', hint: '' },
            onUpdated: (data: any) => {
              formData.value['date'] = data.value
            }
          }
        },
        {
          fieldId: 'mode',
          class: 'justify-start',
          colName: t('events.elements.sunsetType'),
          component: 'p',
          editComponent: 'dropdown-generic',
          componentProps: {
            class: 'text-blackp text-sm text-left',
            value: t(
              `optionSunsetMode.${
                (newSavedHeader.value?.mode ?? formData.value['mode']) !==
                OptionSunsetMode.all
                  ? OptionSunsetMode.partial
                  : isLocal.value
                  ? newSavedHeader.value?.mode
                  : props.event?.optionSunsets?.mode
              }`
            )
          },
          editComponentProps: {
            classes: 'mt-1.5 w-full',
            openDirection: 'bottom',
            value:
              (newSavedHeader.value?.mode ?? formData.value['mode']) !==
              OptionSunsetMode.all
                ? OptionSunsetMode.partial
                : OptionSunsetMode.all,
            data: { name: 'type', hint: t('events.elements.sunsetType') },
            options: [OptionSunsetMode.all, OptionSunsetMode.partial].map(
              (x) => ({
                name: t(`optionSunsetMode.${x}`),
                value: x,
                id: x
              })
            ),
            onUpdated: (data: any) => {
              formData.value['mode'] = data.value
              formData.value['type'] =
                data.value == OptionSunsetMode.all ? '' : data.value
            }
          }
        },
        {
          fieldId: 'type',
          class: 'justify-start',
          colName: t('events.elements.quantityType'),
          component: 'p',
          editComponent:
            formData.value['mode'] == OptionSunsetMode.all
              ? 'p'
              : 'dropdown-generic',
          componentProps: {
            class: 'text-blackp text-sm text-left',
            value:
              (newSavedHeader.value?.mode ?? formData.value['mode']) ==
              OptionSunsetMode.all
                ? '-'
                : t(
                    `optionSunsetMode.${
                      isLocal.value
                        ? newSavedHeader.value?.mode
                        : props.event?.optionSunsets?.mode
                    }`
                  )
          },
          editComponentProps: {
            classes: 'mt-1.5 w-full',
            openDirection: 'bottom',
            value:
              (newSavedHeader.value?.mode ?? formData.value['mode']) ==
              OptionSunsetMode.all
                ? '-'
                : newSavedHeader.value?.type ?? formData.value['type'],
            data: { name: 'type', hint: t('events.elements.quantityType') },
            options: [
              {
                name: t(`optionSunsetMode.ALL_Q`),
                value: OptionSunsetMode.all,
                id: OptionSunsetMode.all
              },
              ...[OptionSunsetMode.vested, OptionSunsetMode.notVested].map(
                (x) => ({
                  name: t(`optionSunsetMode.${x}`),
                  value: x,
                  id: x
                })
              )
            ],
            onUpdated: (data: any) => {
              formData.value['type'] = data.value
            }
          }
        }
      ])

      const updateSelected = (ids: string[]) => {
        selectedContacts.value = ids
        getParts(props.event?.optionSunsets?.id, 1, ids)
        getTotal()
      }

      const startLineEdition = (partId: string, index: number) => {
        let part = issuanceParts.value.find((x) => x.id == partId)

        store.dispatch('organizations/GET_CONTACT_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: { contactId: part.contact?.id, date: moment().toDate() }
        })
      }

      const addNewLine = (fromPartId?: string) => {
        let newL = issuanceParts.value.find((x) => x.id == fromPartId)
        let index = newLines.value.length + 1
        while (newLines.value.find((x) => x.newIndex === index)) {
          index++
        }
        newLines.value.push(
          newL ?? {
            index,
            id: null,
            contact: null,
            unitPrice: 0,
            totalAmount: 0,
            quantity: 0,
            releasedAmount: 0,
            nominalIncrease: 0,
            totalIssuancePremium: 0,
            issuancePremiumPerAction: 0
          }
        )
      }

      const removeNewLine = (index: number) => {
        newLines.value = newLines.value.filter((x) => x.index != index)
      }

      const getTotal = () => {
        if (!props.event?.optionSunsets?.id) return
        store.dispatch('organizations/GET_OPTION_SUNSET_PARTS_TOTAL', {
          companyId: currentCompany.value.id,
          optionSunsetId: props.event?.optionSunsets?.id,
          filter: { contactsId: selectedContacts.value },
          onSuccess: (response: EventPartsTotal) => {
            total.value = response
          },
          onError: () => {
            //
          }
        })
      }

      const saveNewLine = (
        index: number,
        arrayIndex: number,
        onSuccess: any,
        onError: any
      ) => {
        const d = editedLinesData.value.find((e) => e.newIndex == index)
        if (!d) {
          onError()
          store.dispatch('toolbox/displayToast', {
            type: TYPE.ERROR,
            context: t('events.context'),
            message: t(`events.apiResponse.lineEditError`)
          })

          return
        }
        const upData = { ...d }
        for (const property in upData) {
          if (!upData[property]) delete upData[property]
        }

        store.dispatch('organizations/ADD_OPTION_SUNSET_PART', {
          companyId: currentCompany.value.id,
          optionSunsetId: props.event?.optionSunsets?.id,
          data: upData,
          onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response)
            issuanceParts.value.unshift({
              id: response.id,
              ...response,
              exerciseCalendarId: response.exerciseCalendar.id,
              stock: response.exerciseCalendar.optionAlias.id,
              contact: response.exerciseCalendar.contact,
              contactId: response.exerciseCalendar.contact.id,
              optionAlias: response.exerciseCalendar.optionAlias
            })
            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditSuccess`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditError`)
            })
          }
        })
      }

      const saveEventPrimaryInfo = (onSuccess: any, onError: any) => {
        const params = {
          optionSunsetId:
            formData.value?.optionSunsets?.id ?? props.event?.optionSunsets?.id,
          ...formData.value
        }

        delete params.type

        store.dispatch('organizations/UPDATE_OPTION_SUNSET_PRIMARY_INFO', {
          companyId: currentCompany.value.id,
          optionSunsetId: props.event?.optionSunsets?.id,
          data: params,
          onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response)

            newSavedHeader.value = {
              ...response
            }
            canEditHeader.value = false
            isLocal.value = true
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.primaryInfoEdited`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.primaryInfoError`)
            })
          }
        })
      }

      const saveLine = (partId: string, onSuccess: any, onError: any) => {
        const line = issuanceParts.value.find((e) => e.id == partId)
        const d = editedLinesData.value.find((e) => e.partId == partId)

        const upData = {
          contactId: line?.contact?.id,
          optionAliasId: line?.optionAlias?.id ?? d?.optionAliasId,
          ...d,
          ...line
        }

        const [stockId, price, exerciseCalendarId] = upData.stock?.split('@_')

        upData.optionAliasId = stockId
        upData.exerciseCalendarId = exerciseCalendarId

        delete upData.commitment
        delete upData.optionAlias
        delete upData.contact
        delete upData.exerciseCalendar
        delete upData.compositionAlias
        for (const property in upData) {
          if (!upData[property]) delete upData[property]
        }

        console.log('DattaForm', upData)

        store.dispatch('organizations/UPDATE_OPTION_SUNSET_PART', {
          companyId: currentCompany.value.id,
          optionSunsetId: props.event?.optionSunsets?.id,
          partId,
          data: upData,
          onSuccess: (response: any) => {
            const idx = issuanceParts.value.findIndex((x) => x.id == partId)
            issuanceParts.value[idx] = {
              ...response,
              stockId: response.exerciseCalendar.optionAlias.id,
              optionAlias: response.exerciseCalendar.optionAlias
            }
            if (onSuccess) onSuccess(response)
            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditSuccess`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditError`)
            })
          }
        })
      }

      const handleFieldEdition = (key: any, value: any, part: any) => {
        let lineIndex = -1
        if (!part?.id) {
          lineIndex = editedLinesData.value.findIndex(
            (e) => e.newIndex == part.index
          )
        } else {
          lineIndex = editedLinesData.value.findIndex(
            (e) => e.partId == part.id
          )
        }

        const elm = {
          ...part,
          [key]: value,
          partId: part.id,
          newIndex: part.index
        }
        if (lineIndex != -1) {
          editedLinesData.value[lineIndex] = { ...elm }
        } else
          editedLinesData.value.push({
            ...elm
          })
      }

      const columns = computed(() =>
        data.value.map((x) => ({
          name: x.name,
          width: x.width,
          class: x.class,
          id: x.id
        }))
      )

      const lines = computed(() =>
        [...newLines.value, ...issuanceParts.value].map((x, index) => ({
          selected: contactsId.value.includes(x.contact?.id),
          partId: x?.id,
          newIndex: x?.index,
          contactId: {
            class: 'justify-start',
            colName: 'contactId',
            component: 'ClickableContactBadge',
            editComponent: 'contact-generic',
            componentProps: {
              company: props.event?.company,
              class: 'p-0 text-xs overflow-hidden',
              details: false,
              type: 'light',
              contact: x?.contact
            },
            editComponentProps: {
              classes: ' w-full',
              details: false,
              type: 'light',
              value: x?.contactId,
              contact: x?.contact,
              data: { name: 'contactId', hint: '' },
              onUpdated: (data: any) => {
                if (x.contactId != data.value) {
                  x['stock'] = ''
                  // x.exerciseCalendarId = ''
                  // handleFieldEdition('stock', null, x)
                }

                x['contactId'] = data.value

                handleFieldEdition(data.name, data.value, x)

                store.dispatch('organizations/GET_CONTACT_EXERCISE_CALENDARS', {
                  companyId: route.params.id,
                  filter: {
                    contactId: data.value,
                    date: moment(
                      formData.value.date ?? newSavedHeader.value?.date
                    ).toISOString()
                  }
                })
              },
              lineData: {
                contactIdSelection: x?.contact
              }
            },
            displayComponent: '',
            partId: x?.id
          },
          stock: {
            class: 'justify-start',
            colName: 'stock',
            component: 'tag',
            editComponent: 'dropdown-options',
            componentProps: {
              class: 'w-full',
              parentClass: 'text-blackp text-sm text-left',
              withInitials: false,
              value: x?.stock,
              text: x?.optionAlias?.alias
            },
            editComponentProps: {
              optionData: {
                value: x?.contactId
              },
              lineData: {
                ...x,
                contactId: x?.contactId,
                stockIdSelection: x?.optionAlias
              },
              dataFromExtra: true,
              openDirection: 'bottom',
              displayAll: false,
              mode: 'single',
              canAddNew: false,
              classes: 'w-full',
              class: 'w-full',
              hasLabel: false,
              value: `${x?.optionAlias?.id}@_${formatNumber(
                x.exerciseCalendar?.optionIssuancePart.optionIssuance
                  .exercisePrice
              )}@_${x?.exerciseCalendar?.id ?? ''}`,
              data: { name: 'stock', hint: '' },
              onUpdated: async (data: any) => {
                if (data.component == 'dropdown-exercisePrice') {
                  //
                } else {
                  const [stock, exoPrice, exerciseCalendarId] =
                    data.value.split('@_')

                  x['exerciseCalendarId'] = exerciseCalendarId
                  x.optionAliasId = stock
                  x.stock = data.value

                  let calendar: any = await getCalendar(
                    exerciseCalendarId,
                    formData.value.date,
                    x.contactId,
                    stock
                  )

                  console.log('datatta', exerciseCalendarId, x)

                  if (
                    formData.value.mode == OptionSunsetMode.all ||
                    formData.value.mode == OptionSunsetMode.vested
                  ) {
                    x.sunsetVestedQuantity =
                      calendar?.vestedQuantity -
                      (calendar?.optionSunsetParts ?? []).reduce(
                        (acc: number, x: any) => (acc += x.exercisableQuantity),
                        0
                      ) -
                      (calendar?.optionExerciseParts ?? []).reduce(
                        (acc: number, x: any) => (acc += x.issuedQuantity),
                        0
                      )
                  }

                  if (
                    formData.value.mode == OptionSunsetMode.all ||
                    formData.value.mode == OptionSunsetMode.notVested
                  ) {
                    x.sunsetNotVestedQuantity =
                      (calendar?.notVestedQuantity ?? 0) -
                      (calendar?.optionSunsetParts ?? []).reduce(
                        (acc: number, x: any) => (acc += x.nonVestedQuantity),
                        0
                      )
                  }
                }
              },
              index: index,
              allData: formData.value
            },
            partId: x?.id
          },
          motive: {
            class: 'justify-start',
            colName: 'motive',
            component: 'p',
            editComponent: 'dropdown-generic',
            componentProps: {
              class: 'text-sm text-right',
              value: t(`SunsetMotives.${x.motive}`)
            },
            editComponentProps: {
              label: false,
              options: Object.values(EndOfContractReasons).map((x) => ({
                name: t(`SunsetMotives.${x}`),
                value: x,
                id: x
              })),
              data: { name: 'motive', hint: '' },
              classes: 'w-full justify-start',
              value: x.motive,
              onUpdated: (data: any) => {
                x['motive'] = data.value

                handleFieldEdition(data.name, data.value, x)
              }
            },

            displayComponent: '',
            partId: x?.id
          },
          nonVestedDate: {
            class: 'justify-start',
            colName: 'nonVestedDate',
            component: 'p',
            editComponent: 'date-generic',
            componentProps: {
              class: 'text-sm text-right',
              value: moment(x.nonVestedDate).format('l')
            },
            editComponentProps: {
              data: { name: 'nonVestedDate', hint: '' },
              classes: 'w-full',
              value: x.nonVestedDate,
              onUpdated: (data: any) => {
                x['nonVestedDate'] = data.value

                handleFieldEdition(data.name, data.value, x)
              }
            },
            displayComponent: '',
            partId: x?.id
          },
          exercisableBalanceDate: {
            class: 'justify-start',
            colName: 'exercisableBalanceDate',
            component: 'p',
            editComponent: 'date-generic',
            componentProps: {
              class: 'text-sm text-right',
              value: moment(x.exercisableBalanceDate).format('l')
            },
            editComponentProps: {
              data: { name: 'exercisableBalanceDate', hint: '' },
              classes: 'w-full',
              value: x.exercisableBalanceDate,
              onUpdated: (data: any) => {
                x['exercisableBalanceDate'] = data.value

                handleFieldEdition(data.name, data.value, x)
              }
            },

            displayComponent: '',
            partId: x?.id
          },
          sunsetVestedQuantity: {
            class: 'justify-start',
            colName: 'sunsetVestedQuantity',
            component: 'number',
            componentProps: {
              class: 'text-sm',
              value: x.exercisableQuantity,
              mode: NumberType.quantity
            },
            editComponent: 'input-generic',
            editComponentProps: {
              data: { name: 'total', hint: '' },
              classes: 'w-full',
              disabled:
                props.event?.optionSunsets?.mode != OptionSunsetMode.partial,
              value: x.exercisableQuantity,
              onUpdated: (data: any) => {
                x['exercisableQuantity'] = data.value

                handleFieldEdition(data.name, parseInt(data.value), x)
              }
            },
            displayComponent: '',
            partId: x.id
          },
          sunsetNotVestedQuantity: {
            class: 'justify-start',
            colName: 'sunsetNotVestedQuantity',
            component: 'number',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.nonVestedQuantity,
              mode: NumberType.quantity
            },
            editComponentProps: {
              data: { name: 'sunsetNotVestedQuantity', hint: '' },
              classes: 'w-full',
              disabled:
                props.event?.optionSunsets?.mode != OptionSunsetMode.partial,
              value: x.nonVestedQuantity,
              onUpdated: (data: any) => {
                x['nonVestedQuantity'] = data.value

                handleFieldEdition(data.name, parseInt(data.value), x)
              }
            },
            editComponent: 'input-generic',
            displayComponent: '',
            partId: x.id
          },
          actions: {
            class: 'justify-end',
            colName: 'stock',
            component: 'actions',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.vestingCalendar?.name || '-'
            },
            editComponent: 'dropdown-vesting',
            partId: x.id
          }
        }))
      )

      const getCalendar = async (
        exerciseCalendarId: string,
        date: string,
        contactId: string,
        optionAliasId: string
      ) => {
        if (!exerciseCalendarId || !contactId) return
        return await store.dispatch('organizations/GET_EXERCISE_CALENDAR', {
          companyId: route.params.id,
          filter: {
            date,
            // optionAliasId: [optionAliasId],
            contactId: [contactId]
          },
          exerciseCalendarId
        })
      }

      const isDetailPage = computed(() => route.name === 'company.eventDetails')

      const parts = computed<any[]>(
        () => props.event?.optionSunsets?.optionSunsetParts || []
      )

      const isOverLimit = computed(
        () => !isDetailPage.value && parts.value.length > 20
      )

      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const resetLine = (index: number, partId: string) => {
        const oldLine = allParts.value.find((x) => x.id == partId)
        issuanceParts.value[index] = { ...oldLine }
      }
      const handlePageSelection = (page: number) => {
        getParts(props.event?.optionSunsets?.id, page, [])
      }

      const removePart = (partId: string, onSuccess: any, onError: any) => {
        let id = props.event?.optionSunsets?.id
        store.dispatch(`organizations/DELETE_OPTION_SUNSET_PART`, {
          companyId: company.value?.id,
          optionSunsetId: id,
          partId,
          onSuccess: () => {
            if (onSuccess) onSuccess()
            issuanceParts.value = [
              ...issuanceParts.value.filter((x) => x.id !== partId)
            ]

            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineDeleteSuccess`)
            })
          },
          onError: () => {
            if (onError) onError()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineDeleteError`)
            })
          }
        })
      }

      const getParts = (
        id: string | undefined,
        page = 1,
        contactsId: string[]
      ) => {
        if (!id) return

        isLoadingParts.value = true
        store.dispatch('organizations/GET_OPTION_SUNSET_PARTS', {
          companyId: company.value?.id,
          optionSunsetId: id,
          page,
          filter: { contactsId },
          limit: isDetailPage.value ? 100 : null,
          onSuccess: (response: any, pageLimit = 0) => {
            const { data, metadata } = response
            pagination.value = {
              ...metadata,
              total: metadata?.count,
              limit: pageLimit
            }
            allParts.value = JSON.parse(JSON.stringify(data))

            data.forEach((p: OptionSunsetPart) => {
              store.commit('organizations/UPDATE_CONTACT_EXERCISE_CALENDARS', {
                contactId: p.exerciseCalendar.contact.id,
                data: [
                  {
                    id: p.exerciseCalendar.id,
                    optionAlias: p?.exerciseCalendar.optionAlias
                  }
                ]
              })
            })

            issuanceParts.value = [...data].map((x) => ({
              ...x,
              exerciseCalendarId: x.exerciseCalendar.id,
              stock: x.exerciseCalendar.optionAlias.id,
              contact: x.exerciseCalendar.contact,
              contactId: x.exerciseCalendar.contact.id,
              optionAlias: x.exerciseCalendar.optionAlias
              // exercisePrice:
              //   x.exerciseCalendar?.optionIssuancePart?.optionIssuance
              //     ?.exercisePrice,
              // motive: x.motive,
              // exerciseCalendarId: x.exerciseCalendar.id,
              // stockId: x.exerciseCalendar.optionAlias.id,
              // contact: x.exerciseCalendar.contact,
              // contactId: x.exerciseCalendar.contact.id,
              // exercisableQuantity: x.exercisableQuantity,
              // nonVestedQuantity: x.nonVestedQuantity,
              // nonVestedDate: x.nonVestedDate,
              // exercisableBalanceDate: x.exercisableBalanceDate,
              // optionAlias: x.exerciseCalendar.optionAlias
            }))
            isLoadingParts.value = false
          },
          onError: () => {
            isLoadingParts.value = false
          }
        })
      }

      watch(
        () => props.event,
        () => {
          getParts(props.event?.optionSunsets?.id, 1, [])
          getTotal()
        }
      )

      watch(
        () => props.canEdit,
        (val) => {
          canEditHeader.value = val
        }
      )

      onMounted(() => {
        getParts(props.event?.optionSunsets?.id, 1, [])
        getTotal()
      })

      return {
        lines,
        filteredPartsId,
        canEditHeader,
        totalLine,
        isDetailPage,
        selectedContacts,
        pagination,
        columns,
        headerFields,
        isLoadingParts,
        isOverLimit,
        updateSelected,
        removeNewLine,
        resetLine,
        saveEventPrimaryInfo,
        addNewLine,
        handlePageSelection,
        saveNewLine,
        startLineEdition,
        saveLine,
        removePart
      }
    }
  })
</script>
