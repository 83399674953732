<template>
  <div class="w-full pt-5">
    <div class="w-full flex flex-wrap justify-between">
      <data-boxes :data="informations" :elements="dataBoxes" type="capital" />
    </div>
    <div class="w-full mt-8 flex justify-between">
      <div class="" style="width: 49.25%">
        <!-- general informations -->
        <div
          class="
            w-full
            h-full
            chart-box
            flex-1
            p-8
            flex flex-col
            border border-secondary5
            rounded-lg
            bg-white
          "
        >
          <h3 class="flex items-start">
            {{ $t('overview.informations.title') }}
          </h3>
          <div class="mt-8 flex flex-row justify-between">
            <div class="flex flex-col items-start justify-between">
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.formType') }}
              </h4>
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.adresse') }}
              </h4>
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.rcs') }}
              </h4>
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.siret') }}
              </h4>
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.attachmentGreffe') }}
              </h4>
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.ape') }}
              </h4>
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.generalDirector') }}
              </h4>
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.president') }}
              </h4>
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.externalAuditor') }}
              </h4>
              <h4 class="element text-base text-left">
                {{ $t('overview.informations.alternateAuditor') }}
              </h4>
              <h4 v-if="manager" class="element text-base text-left">
                {{ $t('overview.informations.manager') }}
              </h4>
            </div>
            <div class="">
              <div class="flex flex-col items-start justify-between">
                <div>
                  <h4 v-if="company?.legalStatus">
                    {{ company?.legalStatus?.legalStatus }}
                  </h4>
                  <i v-else class="bi-dash" />
                </div>
                <div>
                  <h4 v-if="company?.address" class="">
                    {{ company?.address }} -
                    {{ company?.postcode }}
                    {{ company?.city }}
                  </h4>
                  <i v-else class="bi-dash" />
                </div>
                <div>
                  <h4 class="">
                    {{ company?.siren }}
                  </h4>
                </div>
                <div>
                  <h4 v-if="company?.siret" class="">
                    {{ company?.siret }}
                  </h4>
                  <i v-else class="bi-dash" />
                </div>
                <div>
                  <i class="bi-dash" />
                </div>
                <div>
                  <i class="bi-dash" />
                </div>

                <div>
                  <router-link
                    v-if="generalDirector"
                    class="cursor-pointer"
                    :to="{
                      name: 'company.directory',
                      params: {
                        id: $route.params.id,
                        contactId: generalDirector?.contact?.id
                      }
                    }"
                  >
                    <h4 class="hover:text-primary4">
                      {{ generalDirector?.contact?.name }}
                    </h4>
                  </router-link>
                  <i v-else class="bi-dash" />
                </div>

                <div>
                  <router-link
                    v-if="president"
                    class="cursor-pointer"
                    :to="{
                      name: 'company.directory',
                      params: {
                        id: $route.params.id,
                        contactId: president?.contact?.id
                      }
                    }"
                  >
                    <h4 class="hover:text-primary4">
                      {{ president?.contact?.name }}
                    </h4>
                  </router-link>
                  <i v-else class="bi-dash" />
                </div>

                <div>
                  <router-link
                    v-if="externalAuditor"
                    class="cursor-pointer"
                    :to="{
                      name: 'company.directory',
                      params: {
                        id: $route.params.id,
                        contactId: externalAuditor?.contact?.id
                      }
                    }"
                  >
                    <h4 class="hover:text-primary4">
                      {{ externalAuditor?.contact?.name }}
                    </h4>
                  </router-link>
                  <i v-else class="bi-dash" />
                </div>

                <div>
                  <router-link
                    v-if="alternateAuditor"
                    class="cursor-pointer"
                    :to="{
                      name: 'company.directory',
                      params: {
                        id: $route.params.id,
                        contactId: alternateAuditor?.contact?.id
                      }
                    }"
                  >
                    <h4 class="hover:text-primary4">
                      {{ alternateAuditor?.contact?.name }}
                    </h4>
                  </router-link>
                  <i v-else class="bi-dash" />
                </div>

                <div v-if="manager">
                  <router-link
                    class="cursor-pointer"
                    :to="{
                      name: 'company.directory',
                      params: {
                        id: $route.params.id,
                        contactId: manager?.contact?.id
                      }
                    }"
                  >
                    <h4 class="hover:text-primary4">
                      {{ manager?.contact?.name }}
                    </h4>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- -->
      </div>

      <div class="flex justify-end" style="width: 49.25%">
        <!-- documents list -->
        <div
          class="
            w-full
            h-full
            chart-box
            flex-1
            p-8
            flex flex-col
            border border-secondary5
            rounded-lg
            bg-white
          "
        >
          <h3 class="flex items-start">
            {{ $t('overview.informations.permanentDocuments') }}
          </h3>

          <data-table
            :columns="columns"
            :rows="importantDocuments"
            index-key="id"
          >
            <template #name="{ row }">
              <div>
                <LongText
                  :text="row.docSubCategory?.name"
                  tag="p"
                  classes="text-left"
                  :max-lenght="100"
                />
                <p class="text-secondary8">
                  {{ row.name }}
                </p>
              </div>
            </template>
            <template #actions="slotProps">
              <div class="flex justify-start">
                <Button
                  variant="secondary"
                  size="medium"
                  class="p-1 rounded-sm"
                  label=""
                  @click="handleClick(slotProps.row)"
                >
                  <i class="bi bi-eye text-xl"></i>
                </Button>
              </div>
            </template>
          </data-table>
        </div>
        <!-- version liste -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import DataBoxes from '@/components/CompanyOverview/DataBoxes.vue'
  import { CompanyOverviewExample } from '@/utils/test'
  import { useStore } from 'vuex'
  import {
    Appointment,
    CompanyGlobalStockData,
    CompanyModel
  } from '@/services/api/models'
  import { useRoute, useRouter } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import DataTable from '@/components/DataTable/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import LongText from '@/components/LongText/index.vue'
  import { dashboardSubCategoryList } from '@/utils'

  export default defineComponent({
    name: 'CompanyInfosTab',
    components: { DataBoxes, DataTable, Button, LongText },
    props: {},
    setup() {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t } = useI18n()
      const importantDocuments = ref<any>([])
      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData']
      )
      const loadingData = ref(false)

      const data = computed(() => CompanyOverviewExample['capital'])
      const companyGlobalData = computed<CompanyGlobalStockData | null>(
        () => store.getters['organizations/companyGlobalData']
      )

      const company = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const getMandates = () => {
        const filter: any = {}
        store.dispatch('organizations/GET_NOMINATIONS', {
          companyId: route.params.id,
          filter
        })
      }

      const mandates = computed<Appointment[]>(
        () => store.getters['organizations/nominations']
      )
      const president = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.president') &&
              !x?.appointmentTermination
          )[0]
      )
      const generalDirector = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.generalDirector') &&
              !x?.appointmentTermination
          )[0]
      )
      const manager = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.manager') &&
              !x?.appointmentTermination
          )[0]
      )
      const externalAuditor = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.externalAuditor') &&
              !x?.appointmentTermination
          )[0]
      )
      const alternateAuditor = computed(
        () =>
          mandates.value.filter(
            (x: any) =>
              x.function.name == t('overview.mandateSlugs.alternateAuditor') &&
              !x?.appointmentTermination
          )[0]
      )

      const columns = computed(() => [
        {
          label: t('documents.elements.name'),
          field: 'name',
          width: '40%',
          custom: true
        },
        {
          label: 'Actions',
          field: 'actions',
          width: '5%',
          custom: true
        }
      ])

      const getImportantDocuments = async () => {
        dashboardSubCategoryList.map(async (subCategoryId: string) => {
          await store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
            companyId: route.params.id,
            filter: {
              documentSubCategoriesId: [subCategoryId]
            },
            onSuccess: (result: any) => {
              if (result.data.length)
                importantDocuments.value.push(result?.data[0])
            }
          })
        })
      }

      const handleClick = (data: any) => {
        router.push({
          name: 'companyDocuments',
          params: {
            id: store.getters['organizations/contextCompany'].id,
            documentId: data.id
          }
        })
      }

      const informations = computed(() => ({
        socialCapital: {
          value: companyGlobalData.value?.shareCapital || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingData.value
        },
        nominalValue: {
          value: companyGlobalData.value?.nominalValue || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingData.value
        },
        nbNFD: {
          value: companyGlobalData.value?.totalNFD || 0,
          type: 'number',
          isLoading: loadingData.value
        },
        nbFD: {
          value: companyGlobalData.value?.totalFD || 0,
          type: 'number',
          isLoading: loadingData.value
        },
        emitPrime: {
          value: companyGlobalData.value?.totalIssuancePrimes || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingData.value
        },
        nbShareholders: {
          value: companyGlobalData.value?.numberOfShareHolders || 0,
          type: 'number',
          isLoading: loadingData.value
        }
      }))

      const dataBoxes = [
        {
          icon: 'bi-cash-stack',
          key: 'socialCapital'
        },
        {
          icon: 'bi-cash-stack',
          key: 'nominalValue'
        },
        {
          icon: 'bi-pie-chart-fill',
          key: 'nbNFD'
        },
        {
          icon: 'bi-pie-chart-fill',
          key: 'nbFD'
        },
        // {
        //   icon: 'bi-cash-stack',
        //   key: 'emitPrime'
        // },
        {
          icon: 'bi-people-fill',
          key: 'nbShareholders'
        }
      ]

      const getInformations = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_COMPANY_STOCKS_DATA', {
          companyId,
          onSuccess: () => {
            loadingData.value = false
          }
        })
      }

      const getData = (companyId = route.params.id) => {
        loadingData.value = true
        getInformations(companyId)
      }
      watch(
        () => route.params.id,
        (val) => {
          getData(val)
          getMandates()
          getImportantDocuments()
        }
      )

      onMounted(() => {
        getData()
        getMandates()
        getImportantDocuments()
      })

      return {
        data,
        loadingData,
        informations,
        dataBoxes,
        isLoading,
        company,
        president,
        generalDirector,
        manager,
        externalAuditor,
        alternateAuditor,
        columns,
        importantDocuments,
        handleClick
      }
    }
  })
</script>

<style lang="scss" scoped>
  .element {
    color: black;
  }
  .docu {
    &:hover {
      color: var(--color-primary);
    }
  }
</style>
