
  import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Button } from '@up.law/uplaw-ui'
  import { useRoute } from 'vue-router'
  import { TabPanel, TabPanels, TabGroup, TabList, Tab } from '@headlessui/vue'
  import DocumentsTab from './documentsTab.vue'
  import EventsTab from './eventsTab.vue'
  import Filters from './filters.vue'

  export default defineComponent({
    components: {
      Filters,
      TabPanel,
      TabPanels,
      TabGroup,
      TabList,
      Tab,
      Button
    },
    emits: ['on-selected'],
    setup(props, { emit }) {
      const count: any = ref({ document: 0, event: 0 })
      const store = useStore()
      const route = useRoute()
      const tabList = [
        {
          type: 'event',
          key: 'corporateEvents',
          langKey: 'corporateEvents',
          component: EventsTab
        },
        {
          type: 'document',
          key: 'corporateDocuments',
          langKey: 'corporateDocuments',
          component: DocumentsTab
        }
      ]

      const selectedTab = ref(0)
      const filters = ref<any>({ search: '' })
      const handleSelection = (data: any) => {
        console.log(data)
        if (data.type == 'document') selectedTab.value = 1
        else selectedTab.value = 0
        emit('on-selected', data.key)
      }
      const resetFilters = (tab: number) => {
        filters.value = {}
      }
      const updateFilters = async (e: any, type = 'document', field = '') => {
        if (field === 'search') {
          store.dispatch('portfolio/SEARCH_IN_DOCUMENTS', {
            companyIds: e?.companyIds,
            query: e.search
          })

          return
        }
        filters.value = { ...e }

        if (filters.value?.contactsId?.length) {
          filters.value['contactsId'] = filters.value?.contactsId.reduce(
            (acc: string[], x: string) => {
              const v = x.split('@_')
              acc.push(...v)
              return acc
            },
            []
          )
        }
        if (type == 'document') {
          await store.dispatch('portfolio/GET_DOCUMENTS', {
            filter: filters.value
          })
        } else {
          await store.dispatch('portfolio/GET_EVENTS', {
            isDraft: false,
            filter: { ...filters.value }
          })
        }
      }

      watch(
        () => route.query?.type,
        (val) => {
          if (val == 'event') selectedTab.value = 0
        }
      )

      onMounted(() => {
        if (route.query?.type == 'event') selectedTab.value = 0

        store.commit('ui/DISPLAY_PREVIEW', false)
      })

      return {
        tabList,
        selectedTab,
        filters,
        count,
        handleSelection,
        updateFilters,
        resetFilters
      }
    }
  })
