
  import { computed, defineComponent } from 'vue'
  import Modal from '@/components/Modal/index.vue'
  import { useStore } from 'vuex'
  import { MAINACTIONS } from '@/types'
  import StockFilter from '@/components/Forms/Configurable/Filters/stock.vue'
  import CommitteeFilter from '@/components/Forms/Configurable/Filters/committee.vue'
  import StockForm from '@/components/Forms/Configurable/Stock/index.vue'
  import VestingForm from '@/components/Forms/Configurable/Vesting/index.vue'
  import VestingFilter from '@/components/Forms/Configurable/Filters/vesting.vue'
  import ContactFilter from '@/components/Forms/Configurable/Filters/contact.vue'
  import ContactForm from '@/components/Forms/Configurable/Contact/index.vue'
  import ContactGroupForm from '@/components/Forms/Configurable/ContactGroup/index.vue'
  import ContactGroupFilter from '@/components/Forms/Configurable/Filters/contactGroup.vue'
  import StockGroupForm from '@/components/Forms/Configurable/StockGroup/index.vue'
  import StockGroupFilter from '@/components/Forms/Configurable/Filters/stockGroup.vue'
  import CommitteeForm from '@/components/Forms/Configurable/Committee/index.vue'
  import { useI18n } from 'vue-i18n'
  import FundraisingRoundFilter from '@/components/Forms/Configurable/Filters/fundraisingRound.vue'
  import FundraisingRound from '@/components/Forms/Configurable/FundraisingRound/index.vue'
  import ProfitSharingPlanFilter from '@/components/Forms/Configurable/Filters/profitSharingPlan.vue'
  import ProfitSharingPlan from '@/components/Forms/Configurable/ProfitSharingPlan/index.vue'
  import OperationFilter from '@/components/Forms/Configurable/Filters/operation.vue'
  import Operation from '@/components/Forms/Configurable/Operation/index.vue'
  import DocAutoTemplate from '@/components/Forms/Configurable/DocumentAutomation/index.vue'
  import ThirdPartyServices from '@/components/Forms/Configurable/thirdPartyServices/index.vue'
  import DocAutoTemplateFilter from '@/components/Forms/Configurable/Filters/documentAutomation.vue'
  import { useAcl } from 'vue-simple-acl/src'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: {
      Modal,
      StockForm,
      CommitteeForm,
      VestingForm,
      ContactForm,
      StockFilter,
      CommitteeFilter,
      VestingFilter,
      ContactFilter,
      ContactGroupFilter,
      ContactGroupForm,
      StockGroupForm,
      StockGroupFilter,
      FundraisingRoundFilter,
      FundraisingRound,
      ProfitSharingPlanFilter,
      ProfitSharingPlan,
      OperationFilter,
      Operation,
      DocAutoTemplateFilter,
      DocAutoTemplate,
      ThirdPartyServices
    },
    setup() {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()
      const acl = useAcl()
      const isModalOpen = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.STOCK
      )

      const displayOrganModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.COMMITTEE
      )

      const displayVestingModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.VESTING
      )

      const displayDocAutoModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.DOC_AUTOMATION
      )

      const canDisplayContactModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.CONTACT
      )
      const canDisplayGroupModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.GROUP
      )

      const canDisplayStockGroupModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.STOCKGROUP
      )

      const canDisplayProfitSharingModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] ===
            MAINACTIONS.PROFIT_SHARING_PLAN
      )

      const canDisplayFundraisingRoundModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.FUNDRAISING_ROUND
      )

      const canDisplayOperation = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.OPERATION
      )

      const canDisplayThirdPartyServices = computed(
        () =>
          (store.getters['ui/isModalOpen'] &&
            store.getters['ui/modalContentType'] ===
              MAINACTIONS.THIRD_PARTY_SERVICES) ||
          route.fullPath.split('?next').length > 1
      )

      const actions = computed(() => {
        const value = []

        if (acl.can.any(['read-stocks', 'write-stocks'])) {
          value.push({
            name: t('stockGroupBook.title'),
            icon: 'collection',
            type: 'list',
            key: MAINACTIONS.STOCKGROUP
          })

          value.push({
            name: t('stockBook.title'),
            icon: 'pie-chart',
            type: 'list',
            key: MAINACTIONS.STOCK
          })
        }

        if (acl.can.any(['read-vestings', 'write-vestings'])) {
          value.push({
            name: t('vestingBook.title'),
            icon: 'file-spreadsheet',
            type: 'list',
            key: MAINACTIONS.VESTING
          })
        }

        if (acl.can.any(['read-committees', 'write-committees'])) {
          value.push({
            name: t('committeeBook.title'),
            icon: 'diagram-2-fill',
            type: 'list',
            key: MAINACTIONS.COMMITTEE
          })
        }

        if (acl.can.any(['write-contacts'])) {
          value.push({
            name: t('groupBook.title'),
            icon: 'people-fill',
            type: 'list',
            key: MAINACTIONS.GROUP
          })
        }

        if (
          acl.can.any(['write-profitSharingPlan', 'read-profitSharingPlan'])
        ) {
          value.push({
            name: t('profitSharingPlanBook.title'),
            icon: 'cash-stack',
            type: 'list',
            key: MAINACTIONS.PROFIT_SHARING_PLAN
          })
        }

        if (acl.can.any(['write-fundRaisingRound', 'read-fundRaisingRound'])) {
          value.push({
            name: t('fundRaisingRoundBook.title'),
            icon: 'wallet2',
            type: 'list',
            key: MAINACTIONS.FUNDRAISING_ROUND
          })
        }

        if (acl.can.any(['write-operations', 'read-operations'])) {
          value.push({
            name: t('operationBook.title'),
            icon: 'calendar-range',
            type: 'list',
            key: MAINACTIONS.OPERATION
          })
        }

        return value
      })

      const openModal = (value: MAINACTIONS) => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: value
        })
      }

      return {
        actions,
        isModalOpen,
        displayOrganModal,
        displayVestingModal,
        canDisplayContactModal,
        canDisplayGroupModal,
        canDisplayStockGroupModal,
        canDisplayFundraisingRoundModal,
        canDisplayProfitSharingModal,
        canDisplayOperation,
        displayDocAutoModal,
        canDisplayThirdPartyServices,
        openModal
      }
    }
  })
