<template>
  <div>
    <h3 class="my-5 text-blackp text-left">
      {{ $t('contactBook.form.personalInformation') }}
    </h3>
    <Form
      v-slot="{ errors, meta }"
      :validation-schema="schema"
      :initial-values="newContact"
      @submit="handleForm"
      @reset="handleReset"
    >
      <input id="profileImage" type="file" hidden name="profileImage" />
      <div :class="`flex  ${topAlignClass} justify-between`">
        <div class="w-1/2 flex">
          <ProfileAvatarForm
            type="company"
            :profile-image="contact?.picture?.pictureURL"
            :contact-id="contact?.id"
            @file-changed="fileChanged"
          />
        </div>
        <div
          :class="` flex ${topAlignClass ? 'mt-2 w-1/3' : 'w-full'} flex-col`"
        >
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="contactGroup"
            >{{ $t('contactBook.fields.group') }}</label
          >

          <Multiselect
            id="contactGroupsId"
            v-model="newContact.contactGroupsId"
            :class="`w-full ${
              newContact.contactGroupsId.length > 0 ? 'min-' : ''
            }h-10`"
            elementName="contactBook.groups"
            selectionText="global.actions.selected1"
            :value="newContact.contactGroupsId"
            :tag-with-initials="false"
            mode="multiple"
            name="contactGroupsId"
            :options="groups"
            :placeholder="$t('contactBook.placeholder.group')"
            :groups="false"
            @change="newContact.contactGroupsId = $event"
          />
        </div>
      </div>
      <div class="w-full flex justify-between mt-3">
        <div class="w-1/3 mr-2">
          <div class="flex">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold text-left"
              for="legalStatus"
              >{{ $t('contactBook.fields.formType') }}</label
            >
          </div>
          <Field
            v-slot="{ field }"
            v-model="newContact.legalStatusId"
            name="legalStatusId"
          >
            <Multiselect
              id="legalStatusId"
              class="w-full h-10"
              v-bind="field"
              mode="single"
              :value="newContact.legalStatusId"
              name="legalStatusId"
              :options="investorLegalStatus"
              :placeholder="$t('contactBook.placeholder.formType')"
              :groups="false"
              @change="newContact.legalStatusId = $event"
            />
          </Field>
        </div>
        <div class="w-1/3 mr-2">
          <div class="flex">
            <label
              class="text-xs text-secondary8 mb-1 font-semibold text-left"
              for="name"
              >{{ $t('contactBook.fields.denomination') }}</label
            >
            <strong class="ml-1 text-error h-4">*</strong>
          </div>
          <Field v-slot="{ field }" v-model="newContact.name" name="name">
            <Input
              v-model="newContact.name"
              v-bind="field"
              :disabled="false"
              class="h-10"
              type="text"
              name="name"
              :placeholder="$t('contactBook.placeholder.denomination')"
            />
          </Field>
        </div>
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="representativeContactId"
            >{{ $t('contactBook.fields.representedCompany') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.representativeContactId"
            name="representativeContactId"
          >
            <Multiselect
              id="representativeContactId"
              class="w-full h-10"
              v-bind="field"
              mode="single"
              :filter-results="false"
              :value="newContact.representativeContactId"
              name="representativeContactId"
              :options="contacts"
              :placeholder="$t('contactBook.placeholder.representedCompany')"
              :groups="false"
              @change="newContact.representativeContactId = $event"
              @search-change="handleSearch"
            />
          </Field>
        </div>
      </div>

      <div></div>

      <h3 class="my-5 text-blackp text-left">
        {{ $t('contactBook.form.moreInformations') }}
      </h3>

      <div class="w-full flex justify-between mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="email"
            >{{ $t('contactBook.fields.email') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.email" name="email">
            <Input
              v-model="newContact.email"
              v-bind="field"
              :disabled="false"
              class="h-10"
              type="text"
              name="email"
              :placeholder="$t('contactBook.placeholder.email')"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="phoneNumber"
            >{{ $t('contactBook.fields.phoneNumber') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.phoneNumber"
            name="phoneNumber"
          >
            <Input
              v-model="newContact.phoneNumber"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="phoneNumber"
              :placeholder="$t('contactBook.placeholder.phoneNumber')"
            />
          </Field>
        </div>

        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="website"
            >{{ $t('contactBook.fields.website') }}</label
          >
          <Field v-slot="{ field }" v-model="newContact.website" name="website">
            <Input
              v-model="newContact.website"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="website"
              :placeholder="$t('contactBook.placeholder.website')"
            />
          </Field>
        </div>
      </div>
      <div class="w-full flex flex-start mt-2">
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="internalId1"
            >{{ $t('contactBook.fields.internalId1') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.internalId1"
            name="internalId1"
          >
            <Input
              id="internalId1"
              v-model="newContact.internalId1"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="internalId1"
              :placeholder="$t('contactBook.placeholder.internalId1')"
            />
          </Field>
        </div>
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="internalId2"
            >{{ $t('contactBook.fields.internalId2') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.internalId2"
            name="internalId2"
          >
            <Input
              id="internalId2"
              v-model="newContact.internalId2"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="internalId2"
              :placeholder="$t('contactBook.placeholder.internalId2')"
            />
          </Field>
        </div>
        <div class="w-1/3 mr-2">
          <label
            class="text-xs text-secondary8 mb-1 font-semibold text-left"
            for="mappingId"
            >{{ $t('contactBook.fields.mappingId') }}</label
          >
          <Field
            v-slot="{ field }"
            v-model="newContact.mappingId"
            name="mappingId"
          >
            <Input
              id="mappingId"
              v-model="newContact.mappingId"
              v-bind="field"
              class="h-10"
              :disabled="false"
              type="text"
              name="mappingId"
              :placeholder="$t('contactBook.placeholder.mappingId')"
            />
          </Field>
        </div>
      </div>
      <FormActions
        v-if="canDo"
        :errors="errors"
        :meta="meta"
        @resetForm="handleReset"
      />
    </Form>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Form, Field } from 'vee-validate'
  import { Input } from '@up.law/uplaw-ui'
  import Multiselect from '@/components/Select/multiselect.vue'
  import {
    CompanyGroup,
    ContactStatus,
    InvestorContact,
    LegalStatus,
    ProfessionalContact
  } from '@/services/api/models'
  import { useStore } from 'vuex'
  import { CONTACTTYPE, InvestorContactForm } from '@/types'
  import { CompanyModel } from '@/services/api/models1'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { investorSchema } from '@/utils'
  import ProfileAvatarForm from './ProfileAvatarForm.vue'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: {
      Form,
      Field,
      Input,
      Multiselect,
      FormActions,
      ProfileAvatarForm
    },
    props: {
      contact: {
        type: Object as () => InvestorContact | null,
        required: false,
        default: () => null
      },
      topAlignClass: { type: String, default: '' }
    },
    emits: ['submitForm', 'fileChanged', 'onCancel'],
    setup(props, { emit }) {
      const store = useStore()
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts')) ||
          acl.permission('employee-writeContact')
      )
      const newValues = (): InvestorContactForm => ({
        id: props.contact?.id,
        name: props.contact?.name || '',
        representativeContactId:
          props.contact?.representativeContact?.id || null,
        legalStatusId: props.contact?.legalStatus?.id || '',
        type: CONTACTTYPE.INVESTOR,
        contactGroupsId: props.contact?.contactGroups?.map((x) => x.id) || [],
        contactStatusId: null,
        website: props.contact?.website,
        email: props.contact?.email,
        phoneNumber: props.contact?.phoneNumber,
        internalId1: props.contact?.internalId1 || null,
        internalId2: props.contact?.internalId2 || null,
        mappingId: props.contact?.mappingId || null
      })
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const newContact = ref(newValues())
      const status = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )
      const modalForm = computed(() => store.getters['organizations/form'])

      const allContacts = computed<ProfessionalContact[]>(
        () => store.getters['organizations/contacts']
      )

      const contacts = computed(() =>
        allContacts.value.filter((x) => x.type === CONTACTTYPE.PROFESSIONAL)
      )

      const groups = computed<CompanyGroup[]>(
        () => store.getters['organizations/groups']
      )

      const legalStatus = computed<LegalStatus[]>(
        () => store.getters['toolbox/legalStatus']
      )
      const investorLegalStatus = computed<LegalStatus[]>(() =>
        legalStatus.value.filter((x) => x.contactType === CONTACTTYPE.INVESTOR)
      )
      const investorContactGroups = computed(() =>
        status.value.filter((x) => x.type === CONTACTTYPE.INVESTOR)
      )

      const schema = computed(() => investorSchema)

      const handleForm = async (values: InvestorContactForm) => {
        const form = {
          ...values,
          contactGroupsId: newContact.value.contactGroupsId,
          email: values.email === '' ? null : values.email,
          type: CONTACTTYPE.INVESTOR
        }
        emit('submitForm', form)
      }

      const handleSearch = (e: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            type: CONTACTTYPE.PROFESSIONAL,
            name: e,
            limit: 10
          }
        })
      }

      const handleReset = () => {
        newContact.value = newValues()
        emit('onCancel')
      }

      const fileChanged = (file: File) => {
        emit('fileChanged', file)
      }

      const groupChanged = (data: []) => {
        newContact.value.contactGroupsId = data
      }

      watch(props, (newProps) => {
        if (newProps.contact) {
          newContact.value = {
            ...newProps.contact,
            contactGroupsId:
              newProps.contact.contactGroups?.map((x) => x.id) ||
              newContact.value.contactGroupsId ||
              [],
            contactStatusId: newProps.contact.status?.id || '',
            legalStatusId: newProps.contact.legalStatus.id || '',
            representativeContactId:
              newProps.contact.representativeContact.id || '',
            website: props.contact?.website,
            email: props.contact?.email,
            phoneNumber: props.contact?.phoneNumber,
            internalId1: newProps.contact.internalId1 || '',
            internalId2: newProps.contact.internalId2 || '',
            mappingId: newProps.contact.mappingId || ''
          }
        } else {
          newContact.value = newValues()
        }
      })

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newContact.value = newValues()
          return
        }
      })

      onMounted(() => {
        handleSearch('')
      })

      return {
        newContact,
        canDo,
        investorContactGroups,
        groups,
        investorLegalStatus,
        contacts,
        schema,
        fileChanged,
        handleSearch,
        handleForm,
        handleReset,
        groupChanged
      }
    }
  })
</script>
