
  import { Commitment, Company } from '@/services/api/models'
  import { computed, defineComponent, onMounted } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { Tag } from '@up.law/uplaw-ui'
  import ContactBadge from '@/components/ContactBadge/index.vue'

  export default defineComponent({
    name: 'CommitmentCard',
    components: {
      Tag,
      ContactBadge
    },
    props: {
      company: {
        type: Object as () => Company | undefined,
        default: null
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      event: {
        type: Object as () => Commitment | undefined,
        default: null
      }
    },
    setup(props) {
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const totalQuantity = computed(() => {
        return (props.event?.commitmentParts || []).reduce(
          (sale: number, p: any) => (sale += p.quantity),
          0
        )
      })
      const parts = computed(() => props.event?.commitmentParts || [])

      return { moment, parts, contactsId, totalQuantity, formatNumber }
    }
  })
