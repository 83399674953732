
  import { defineComponent, ref, toRefs, computed, watch } from 'vue'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import { Field } from 'vee-validate'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    name: 'StockGeneric',
    components: { DropdownStocks, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        default: '',
        type: [String, Array]
      },
      mode: {
        default: 'single',
        type: String
      },
      openDirection: {
        type: String,
        default: 'top'
      },
      callback: {
        type: Function,
        default: () => ({})
      },
      withoutOptions: { type: Boolean, default: false },
      canAddNew: { type: Boolean, default: true },
      optionPerPrice: { type: Boolean, default: true },
      validationState: { type: String, default: '' },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      },
      stocksData: {
        type: Object,
        default: () => ({
          value: [],
          contactId: '',
          index: -1
        })
      },
      classes: {
        type: String,
        default: ''
      },
      label: {
        type: Boolean,
        default: false
      },
      grid: {
        type: Boolean,
        default: true
      },
      lineData: {
        type: Object,
        required: false
      },
      allData: {
        type: Object,
        required: false
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value } = toRefs(props)
      const store = useStore()
      const route = useRoute()
      const fieldKey = ref(0)

      const fieldName = computed(() =>
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name
      )
      let selectedStock = ref(props.value)

      const updateSelected = (val: any) => {
        selectedStock.value = val?.value

        emit('onUpdated', {
          ...data.value,
          value: val?.value
        })
        fieldKey.value++
      }

      watch(
        () => props.value,
        (val) => {
          selectedStock.value = val
        }
      )

      return { selectedStock, fieldName, fieldKey, updateSelected }
    }
  })
