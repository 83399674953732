<template>
  <div>
    <label
      v-if="hasLabel"
      class="capitalize text-xs font-semibold text-secondary8 text-left"
      >{{ data.label }}</label
    >

    <Multiselect
      v-model="stock"
      :value="stock"
      :name="fieldName"
      class="w-full min-h-full"
      :show-labels="true"
      :mode="mode"
      group-label="name"
      :options="stocks"
      elementName="stockBook.stocks"
      selectionText="global.actions.selected1"
      group-options-field="children"
      :placeholder="
        $t(`global.placeholder.${mode === 'single' ? 'stock' : 'stocks'}`)
      "
      :filterResults="false"
      :groups="true"
      label="name"
      :disabled="disabled"
      :open-direction="openDirection"
      @search-change="handleSearch"
      @change="updateValue"
    >
      <template #[addActionSlot]>
        <div v-if="canAddNew" class="">
          <div
            class="
              flex
              w-full
              h-12
              justify-start
              items-center
              text-md
              px-2
              text-secondary9
              hover:bg-primary1
            "
          >
            <i class="bi bi-plus text-xl text-secondary9"></i>
            <h4
              class="
                w-full
                h-full
                text-secondary9
                flex
                justify-start
                items-center
                text-md
              "
              @click="openModal"
            >
              {{ $t('events.elements.newStock') }}
            </h4>
          </div>
        </div>
      </template>
      <template #option="{ option }">
        <span v-if="option?.id != 0" class="w-full text-sm font-normal"
          >{{ option.name }}
        </span>
      </template>

      <template #tag="{ option, handleTagRemove }">
        <div class="">
          <Tag
            :text="option.name.toUpperCase()"
            :with-initials="false"
            :value="option.id"
            @on-remove.prevent.stop="handleTagRemove(option, $event)"
          />
        </div>
      </template>

      <template #noResults>
        <span>{{ $t('global.placeholder.noResult') }}</span>
      </template>
    </Multiselect>
    <slot :name="data.name" :value="stock" :isOption="isOption(formValue)" />
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    computed,
    ref,
    toRefs,
    onMounted,
    watch,
    onBeforeMount
  } from 'vue'
  import { Tag } from '@up.law/uplaw-ui'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { MAINACTIONS, STOCKTYPE } from '@/types'
  import {
    CompanyModel,
    CompositionStockAlias,
    ContactAvailableData,
    OptionStockAlias,
    StockAlias,
    StockGroup
  } from '@/services/api/models'
  import { formatNumber } from '@/utils'
  import { MULTISELECTMODE } from '@/types'

  export default defineComponent({
    name: 'DropdownStocks',
    components: { Multiselect, Tag },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      lineData: {
        type: Object,
        required: false
      },
      allData: {
        type: Object,
        required: false
      },
      filterResults: { type: Boolean, default: true },
      classItem: {
        type: String,
        default: 'w-full'
      },
      hasLabel: {
        default: false,
        type: Boolean
      },
      value: {
        default: '',
        type: [String, Array]
      },
      mode: {
        default: 'single',
        type: String
      },
      openDirection: {
        type: String,
        default: 'top'
      },
      callback: {
        type: Function,
        default: () => ({})
      },
      withoutOptions: { type: Boolean, default: false },
      canAddNew: { type: Boolean, default: true },
      optionPerPrice: { type: Boolean, default: true },
      validationState: { type: String, default: '' },
      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      },
      stocksData: {
        type: Object,
        default: () => ({
          value: [],
          contactId: '',
          index: -1
        })
      }
    },
    emits: ['onUpdated', 'search-change'],
    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const route = useRoute()
      const { data } = toRefs(props)

      const fieldName =
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name

      const addActionSlot = computed(() =>
        props.mode === 'top' ? 'beforelist' : 'afterlist'
      )
      const isSearching = ref(false)

      const formValue = ref('')
      const stock = ref<any>(props.value)

      const searchText = ref('')

      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed<OptionStockAlias[]>(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )

      const extraContactData = computed<ContactAvailableData>(
        () => store.getters[`organizations/contactExtraData`]
      )
      const group = computed<StockGroup>(
        () => store.getters['organizations/stockGroup']
      )
      const currency = computed(() => store.getters['organizations/currency'])

      const options = computed(() => {
        let values: any[] = []
        props.optionPerPrice
          ? optionStockAliases.value.map((op) => {
              if (op.exercisePrices)
                op.exercisePrices.forEach((price) => {
                  values.push({
                    id: `${op.id}@_${parseFloat(price.toString()).toFixed(2)}`,
                    name: `${op.alias} (${formatNumber(price)}${
                      currency.value
                    })`,
                    value: formatNumber(price)
                  })
                })
              else
                values.push({
                  id: op.id,
                  name: op.alias,
                  value: op.alias
                })
            })
          : (values = optionStockAliases.value)
        return values
      })

      const isOption = (id: string) => {
        return (
          optionStockAliases.value.find((item: any) => item.id === id) !==
          undefined
        )
      }

      const stocks = computed(() => {
        let value = [
          {
            id: 1,
            name: t('companyStockType.SIMPLE'),
            label: t('companyStockType.SIMPLE'),
            value: 'stocks',
            children: (props.stocksData.index === props.index &&
            props.stocksData.value
              ? (extraContactData.value[props.stocksData.value]?.stocks || [])
                  .filter((s: any) => s.type === 'STOCK')
                  .map((x: any) =>
                    stocksAliases.value.find((y: any) => y.id === x.id)
                  )
              : stocksAliases.value
            )
              .reduce((acc: any[], el: any) => {
                acc.push(el)
                if (props.lineData?.sellerId) {
                  const d = (el?.commitmentParts ?? [])
                    .filter(
                      (cP: any) => cP?.contact?.id == props.lineData?.sellerId
                    )
                    .map((c: any) => {
                      return {
                        stockGroup: el?.group,
                        type: el.type,
                        id: `${el.id}@_${c.commitment?.id}`,
                        alias: `${el.alias} (${c.commitment?.label})`
                      }
                    })
                    .reduce((list: any[], l: any) => {
                      if (!list.map((a) => a.id).includes(l.id)) list.push(l)
                      return list
                    }, [])

                  acc.push(...d)
                }

                return acc
              }, [])
              .filter(group.value ? () => ({}) : props.callback)
              .map((x: any) => ({
                name: x?.alias || x?.name,
                label: x?.alias || x?.name,
                id: x?.id,
                type: x.type,
                stockGroup: x?.group
              }))
          },
          {
            id: 3,
            name: t('companyStockType.COMBINED'),
            label: t('companyStockType.COMBINED'),
            value: 'compositionStocks',
            children: (props.stocksData.index === props.index &&
            props.stocksData.value
              ? (extraContactData.value[props.stocksData.value]?.stocks || [])
                  .filter((s: any) => s.type === 'COMPOSITION')
                  .map((x: any) =>
                    compositionStockAlias.value.find((y: any) => y.id === x.id)
                  )
              : compositionStockAlias.value
            )
              .reduce((acc: any[], el: any) => {
                acc.push(el)

                if (props.lineData?.sellerId) {
                  const d = (el?.commitmentParts ?? [])
                    .filter(
                      (cP: any) => cP?.contact?.id == props.lineData?.sellerId
                    )
                    .map((c: any) => ({
                      stockGroup: el?.group,
                      type: el.type,
                      id: `${el.id}@_${c.commitment?.id}`,
                      alias: `${el.alias} (${c.commitment?.label})`
                    }))
                    .reduce((list: any[], l: any) => {
                      if (!list.map((a) => a.id).includes(l.id)) list.push(l)
                      return list
                    }, [])
                  acc.push(...d)
                }

                return acc
              }, [])
              .filter(group.value ? () => ({}) : props.callback)
              .map((x: any) => ({
                name: x?.alias || x?.name,
                label: x?.alias || x?.name,
                type: x.type,
                id: x?.id,
                stockGroup: x?.group
              }))
          }
        ]

        if (!props.withoutOptions) {
          value.push({
            id: 2,
            name: t('companyStockType.COMPOSED'),
            label: t('companyStockType.COMPOSED'),
            value: 'optionStock',
            children: (props.stocksData.index === props.index &&
            props.stocksData.value
              ? (extraContactData.value[props.stocksData.value]?.stocks || [])
                  .filter((s: any) => s.type === 'OPTION')
                  .map((x: any) =>
                    optionStockAliases.value.find((y: any) => y.id === x.id)
                  )
              : options.value
            )

              .filter((x) => x !== undefined && x !== null)
              .map((x: any) => ({
                name: x?.alias || x?.name,
                label: x?.alias || x?.name,
                id: x.id,
                type: x.type,
                stockGroup: x.group
              }))
          })
        }

        return value.sort((a: any, b: any) => a.id - b.id)
      })

      watch(
        () => props.stocksData,
        (val) => {
          if (val.value && val.index === props.index) stock.value = ''
        }
      )

      const openModal = () => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.STOCK
        })
      }
      const getAliases = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search

        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: route.params?.id,
          filter: { ...filter }
        })
      }
      const handleSearch = (event: string) => {
        isSearching.value = event.length > 0

        getAliases(event)
      }

      const updateValue = (selected: string) => {
        stock.value = selected
        const opt = stocks.value
          .flatMap((x: any) => x?.children)
          .find((x) => x.id == selected)

        emit('onUpdated', {
          ...data.value,
          value: selected,
          type: opt?.type ?? STOCKTYPE.OPTION
        })
      }

      onBeforeMount(() => {
        if (props.lineData && props.lineData[`${data.value.name}Selection`]) {
          const n = props.lineData[`${data.value.name}Selection`]

          if (n.type == STOCKTYPE.OPTION) {
            optionStockAliases.value.push(n)
          } else if (n.type == STOCKTYPE.COMPOSITION) {
            compositionStockAlias.value.push(n)
          } else {
            stocksAliases.value.push(n)
          }
        }
      })

      onMounted(async () => {
        getAliases()
      })

      watch(
        () => props.value,
        (values) => {
          stock.value = values
        }
      )

      return {
        formValue,
        stock,
        searchText,
        addActionSlot,
        fieldName,
        isOption,
        stocks,
        openModal,
        updateValue,
        handleSearch
      }
    }
  })
</script>
