
  import { defineComponent, ref } from 'vue'
  import { Tag, Button } from '@up.law/uplaw-ui'
  import DropdownProfitSharingPlans from '@/components/Forms/DropDowns/DropdownProfitSharingPlans.vue'
  import DropdownActions from '@/components/Forms/DropDowns/DropdownActions.vue'
  import DropdownDelegations from '@/components/Forms/DropDowns/DropdownDelegations.vue'
  import DropdownFundRaisingRounds from '@/components/Forms/DropDowns/DropdownFundRaisingRounds.vue'
  import { ElTooltip } from 'element-plus'
  import DateGeneric from '@/components/Forms/Event/Generic/DateGeneric.vue'
  import DropdownOptions from '@/components/Forms/DropDowns/DropdownOptions.vue'
  import DropdownComposition from '@/components/Forms/DropDowns/DropdownComposition.vue'
  import DropdownGeneric from '@/components/Forms/DropDowns/DropdownGeneric.vue'
  export interface EditableHeaderData {
    fieldId: string
    class: string
    colName: string
    componentProps: any
    component: string
    editComponent: string
    editComponentProps: any
  }
  export default defineComponent({
    props: {
      canEdit: { type: Boolean, default: false },
      isEditing: { type: Boolean, default: false },
      fields: {
        type: Array as () => EditableHeaderData[],
        required: true
      },
      columnsPerLine: {
        type: Number,
        default: 4
      }
    },
    emits: ['save', 'startEdition', 'cancelEdition'],
    components: {
      Tag,
      Button,
      DateGeneric,
      ElTooltip,
      DropdownGeneric,
      DropdownProfitSharingPlans,
      DropdownFundRaisingRounds,
      DropdownDelegations,
      DropdownActions,
      DropdownComposition,
      DropdownOptions
    },
    setup(props, { emit }) {
      const isValidating = ref(false)
      const dataSaved = ref(false)

      const onSuccess = () => {
        //
        isValidating.value = false
      }
      const onError = () => {
        isValidating.value = false
      }
      const start = () => {
        emit('startEdition')
      }

      const cancelLine = () => {
        emit('cancelEdition', true)
      }

      const edit = () => {
        isValidating.value = true
        emit('save', onSuccess, onError)
      }

      return { isValidating, edit, start, cancelLine }
    }
  })
