<template>
  <div class="relative">
    <div
      class="absolute w-full h-full z-50 flex justify-center items-center"
      v-if="isLoading"
    >
      <eclipse-loader></eclipse-loader>
    </div>
    <div :class="containerClass" class="datatable">
      <table
        class="table-wrap items-center w-full bg-transparent border-collapse"
      >
        <thead :class="theadClass">
          <tr>
            <th v-if="hasCheckbox" class="data-table-header-cb bg-pink-800">
              <Checkbox
                class="flex justify-center items-center"
                :checked="selectAll"
                name="all"
                @onChange="selectAll = $event"
              />
            </th>
            <th
              v-for="(col, colIndex) in tableHeader"
              :key="colIndex"
              class="data-table-header bg-pink-800"
              :style="`width:${col.width}`"
            >
              <el-tooltip
                v-if="col.info"
                class="box-item"
                effect="dark"
                :content="col.info"
                placement="top"
              >
                <i class="bi bi-info-circle text-md ml-1"></i>
              </el-tooltip>

              <el-popover
                v-if="col.hasTooltip"
                placement="top"
                :width="col.tooltipWidth ? col.tooltipWidth : '15%'"
                trigger="hover"
              >
                <template #reference>
                  {{ col.label }}
                </template>
                <p
                  style="hyphens: none; word-break: keep-all"
                  class="font-normal text-secondary9 text-start text-sm"
                  v-html="col.tooltipText"
                ></p>
              </el-popover>
              <span v-else class="mx-1">
                {{ col.label }}
              </span>
              <span
                v-if="col.sortBy"
                :class="`ml-1 cursor-pointer sort-icon  ${
                  sortByColumns[col.sortBy] !== undefined
                    ? 'text-primary4'
                    : 'text-secondary8'
                }`"
                @click="sortBy(col.sortBy || '')"
                >&uarr;&darr;</span
              >
            </th>
          </tr>
        </thead>
        <slot v-if="customData" name="body"></slot>

        <tbody v-else :class="bodyClass">
          <tr
            v-for="(row, rowIndex) in data"
            :key="rowIndex"
            :class="`hover:bg-primary1 transition-transform transition-colors ${
              isSelected(row[indexKey]) ? 'bg-secondary2' : ''
            }`"
            :style="`background:${
              row.current === true
                ? currentColor
                : row.active
                ? activeColor
                : ''
            }`"
          >
            <td
              v-if="hasCheckbox"
              class="data-table-cell-cb text-md checkbox-container"
            >
              <Checkbox
                :checked="selectedRows.has(row[indexKey])"
                :name="row[indexKey]"
                @onChange="onSelected(row[indexKey])"
              />
            </td>
            <td
              v-for="(o, oIndex) in orders"
              :key="oIndex"
              :class="`
              data-table-cell
              border-l${hasCheckbox && o == '0' ? '-0' : ''}
              ${textSize}
              ${tableHeader[oIndex] && tableHeader[oIndex].custom ? '' : 'p-'}
              ${cellPadding}
              ${o}
            `"
            >
              <div
                v-if="
                  tableHeader[oIndex] &&
                  tableHeader[oIndex].custom &&
                  !tableHeader[oIndex].customDefault
                "
              >
                <slot
                  :name="tableHeader[oIndex].field"
                  :row="row"
                  :item="o"
                  :index="rowIndex"
                ></slot>
              </div>
              <div
                v-else-if="
                  tableHeader[oIndex] && tableHeader[oIndex].customDefault
                "
              >
                <slot name="default" :item="o" :index="rowIndex"></slot>
              </div>
              <div v-else class="text-blackp flex justify-start p-2">
                {{ row[o] }}
              </div>
            </td>
          </tr>
          <slot name="total"></slot>
          <tr v-if="hasFooter" :class="{ 'h-10': hasFooter }">
            <td
              class="w-full border-b border-r border-l border-secondary4"
              :colspan="columns.length"
            >
              <slot name="footer"></slot>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="pagination.lastPage > 1" class="flex justify-end mt-5">
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        class="pr-0"
        :page-size="pageSize"
        :total="pagination.total"
        :current-page="pagination.currentPage"
        @current-change="handlePageSelection"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script lang="ts">
  import { Pagination } from '@/store/modules/Organization/models'
  import { computed, defineComponent, reactive, ref, watch } from 'vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { ORDER_BY } from '@/utils/constants'
  import { ElPagination, ElTooltip, ElPopover } from 'element-plus'
  import EclipseLoader from '@/components/Loader/eclipse.vue'

  export interface Column {
    label: string
    field: string
    info?: string
    custom: boolean
    tooltipText?: string
    tooltipWidth?: string
    hasTooltip?: boolean
    customDefault?: boolean
    sortBy?: string
    sortFunction?: (a: any, b: any) => number
    type?: string
    width?: string
  }
  export default defineComponent({
    components: {
      Checkbox,
      EclipseLoader,
      ElPagination,
      ElTooltip,
      ElPopover
    },
    props: {
      // isLoading: { type: Boolean, required: false, default: false },
      indexKey: { type: String, required: false, default: 'id' },
      bodyClass: { type: String, required: false, default: 'bg-white' },
      lineClass: { type: String, required: false, default: 'bg-white' },
      textSize: { type: String, required: false, default: '' },
      paginationPageSize: { type: Number, required: false, default: 0 },
      containerClass: { type: String, required: false, default: 'relative' },
      theadClass: { type: String, required: false, default: 'relative' },
      cellPadding: { type: String, required: false, default: 'p' },
      currentColor: { type: String, required: false, default: '#0B9DA7' },
      activeColor: { type: String, required: false, default: '#C3FDFF' },
      loading: { type: Boolean, required: false, default: false },
      isFiltering: { type: Boolean, required: false, default: false },
      hasCheckbox: { type: Boolean, required: false, default: false },
      customData: { type: Boolean, required: false, default: false },
      checkedRows: { type: Object as () => string[] },
      onSelect: {
        type: Function,
        required: false,
        default: (values: any): void => {
          console.log('')
        }
      },
      columns: {
        type: Object as () => Column[],
        required: true
      },
      pagination: {
        type: Object as () => Pagination,
        required: false,
        default: () => ({ total: 0, currentPage: 1, lastPage: 1 })
      },
      rows: {
        type: Object as () => any[],
        required: true
      }
    },
    emits: [
      'onSort',
      'onSelect',
      'onPageChange',
      'update-table',
      'delete-action',
      'duplicate-action',
      'onSelect',
      'onFiltered'
    ],
    setup(props, { emit, slots }) {
      props = reactive(props)
      const orders = ref<string[]>(
        props.columns.map((x: any): string => x.field)
      )
      const hasFooter = computed(() => slots.footer !== undefined)
      const hasActions = computed(() => slots.actions !== undefined)
      const selectedRows = ref<Set<string>>(new Set(props.checkedRows || []))
      const selectAll = ref<boolean>(false)
      const isLoading = ref(props.isFiltering)
      const sortByColumns = ref<any>({})

      const pageSize = computed(
        () => props.pagination.limit || props.paginationPageSize
      )

      const onSelected = (value: string) => {
        if (selectedRows.value.has(value)) {
          selectedRows.value.delete(value)
        } else selectedRows.value.add(value)

        emit('onSelect', Array.from(selectedRows.value))
      }
      const isSelected = (value: string) => {
        return selectedRows.value.has(value)
      }
      const tableHeader = ref(props.columns)

      const data = ref(props.rows)
      const handlePageSelection = (page: number) => {
        selectedRows.value = new Set([])
        selectAll.value = false
        isLoading.value = true
        emit('onFiltered', true)
        emit('onPageChange', page)
      }

      const sortBy = (field: string) => {
        const type = tableHeader.value.find((x) => x.sortBy === field)?.type
        const func = tableHeader.value.find(
          (x) => x.sortBy === field
        )?.sortFunction

        data.value = [...data.value].sort((a, b) => {
          let value = 0
          if (sortByColumns.value[field] === undefined)
            sortByColumns.value[field] = ORDER_BY.DESC
          if (type === 'text') {
            if (sortByColumns.value[field] === ORDER_BY.DESC)
              return a[field].localeCompare(b[field])
            if (sortByColumns.value[field] === ORDER_BY.ASC)
              return b[field].localeCompare(a[field])
          } else if (type === 'function' && func) {
            if (sortByColumns.value[field] === ORDER_BY.DESC) return func(a, b)
            if (sortByColumns.value[field] === ORDER_BY.ASC) return func(b, a)
          } else {
            if (sortByColumns.value[field] === ORDER_BY.DESC) {
              value = 0
              if (a[field] > b[field]) {
                value = 1
              }
              if (b[field] > a[field]) {
                value = -1
              }
            }
            if (sortByColumns.value[field] === ORDER_BY.ASC) {
              value = 0
              if (a[field] > b[field]) {
                value = -1
              }
              if (b[field] > a[field]) {
                value = 1
              }
            }

            return value
          }
        })

        sortByColumns.value[field] =
          sortByColumns.value[field] === ORDER_BY.ASC
            ? ORDER_BY.DESC
            : ORDER_BY.ASC
      }
      watch(props, (newProps) => {
        tableHeader.value = newProps.columns
        orders.value = newProps.columns.map((x: any): string => x.field)
        selectedRows.value = new Set(newProps.checkedRows)
        data.value = newProps.rows
      })

      watch(
        () => data.value,
        () => {
          isLoading.value = false

          emit('onFiltered', false)
        }
      )

      watch(
        () => props.rows,
        () => {
          isLoading.value = false
        }
      )

      watch(
        () => props.isFiltering,
        (val) => {
          isLoading.value = val
        }
      )

      watch(selectAll, (isSelected) => {
        let rows: string[] = []
        if (isSelected) {
          rows = props.rows.map((row: any) => row[props.indexKey])
        }

        selectedRows.value = new Set(rows)

        emit('onSelect', Array.from(selectedRows.value))
      })
      return {
        tableHeader,
        selectedRows,
        hasFooter,
        hasActions,
        data,
        selectAll,
        orders,
        isLoading,
        sortByColumns,
        pageSize,
        onSelected,
        isSelected,
        sortBy,
        handlePageSelection
      }
    }
  })
</script>
<style lang="scss" scoped>
  .datatable {
    .loader {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .input-box:hover input ~ .checkmark {
      background-color: transparent;
    }
    .data-table-container {
      @apply w-full h-full bg-primary4 absolute z-50 flex justify-center items-center;
    }
    .data-table-header-cb {
      width: 5%;
      height: 60px;
      @apply px-4 border border-solid py-3 text-sm  border-l-0 border-r-0 border-t-0 border-b-3 whitespace-nowrap text-left text-primary8 border-secondary4 font-medium;
    }
    .data-table-header {
      @apply px-2 border-t-0 align-middle border border-solid py-4 text-xs border-l-0 border-r-0 border-b-4 text-left  border-secondary4 text-sm font-semibold;

      .sort-icon {
        font-size: 0.7em;
      }
    }
    .checkbox-container {
      max-width: 1%;
      width: 1%;
    }
    .data-table-cell-cb {
      @apply border-t-0 px-6 align-middle border-l-0 border-r-0 whitespace-nowrap p-0 border-b-3 border-secondary4;
      .data-table-cell-cb-label {
        @apply select-none relative cursor-pointer pr-6 -inset-y-2;
      }
    }
    .data-table-cell {
      word-break: break-word !important;
      height: 60px;
      max-height: 60px;
      padding: 5px;
      @apply border-t-0 align-middle  border-b-3 border-l-0 border-secondary4 border-r-0 text-left;
    }
    .data-table-cell-actions {
      width: 70px;
      min-width: 70px;
    }

    .input-box:hover input ~ .checkmark {
      background-color: transparent;
    }

    .input-box input:checked ~ .checkmark {
      @apply bg-white;
    }

    .checkmark:after {
      content: '';
      color: red;
      position: absolute;
      display: none;
    }
    .checkmark {
      @apply border;
      @apply border-secondary7;
      @apply rounded;
    }

    .input-box input:checked ~ .checkmark:after {
      display: block;
    }

    .input-box .checkmark:after {
      left: 9px;
      top: 6px;
      width: 5px;
      height: 10px;
      border: solid red;
      @apply border-secondary7;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .border-b-3 {
    border-bottom-width: 3px;
  }
</style>
