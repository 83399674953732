import { log, error } from 'loglevel'
import {
  getUserProfile,
  updateUserProfile,
  updateUserPassword,
  searchCompanies,
  getCompanyInformationsBySiret,
  getCompany,
  getManagedContacts,
  getUserRoles,
  getUserDataByToken,
  definePassword,
  resetUserPassword,
  setNewPassword,
  getUserData,
  saveIntegratedServiceData,
  createApplication,
  getApplications,
  deleteApplication,
  getApplication,
  editApplication
} from '@/services/api'
import {
  ActionTree,
  GetterTree,
  MutationTree,
  Module,
  ActionContext
} from 'vuex'
import { User } from '@auth0/auth0-spa-js'
import { RootState } from '@/store'
import { UserModel } from '@/services/api/models'
import {
  SET_COMPANIES,
  SET_COMPANY,
  GET_COMPANY,
  SET_AUTH_PROFILE,
  SET_CURRENT_LOCALE,
  GET_CURRENT_LOCALE,
  UPDATE_CURRENT_LOCALE,
  SET_CONTACTS,
  ASYNC_GET_USER_PROFILE,
  SAVE_INTEGRATED_SERVICE_DATA,
  CREATE_APPLICATION,
  GET_APPLICATIONS,
  DELETE_APPLICATION,
  GET_APPLICATION,
  EDIT_APPLICATION
} from './models'
import {
  GET_USER_PROFILE,
  SET_USER_PROFILE,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PROFILE,
  UPLOAD_PROFILE_PICTURE,
  UserState
} from './models'
import { getInstance } from '@/plugins/auth'
import { CompanyModel } from '@/services/api/models/company'
import i18n from '@/services/lang'
import {
  SET_IS_LOADING,
  GET_CONTACTS,
  SET_ROLES,
  GET_ROLES,
  VERIFY_USER_BY_TOKEN
} from './models'
import { Contact } from '@/services/api/models/company'
import { Role } from '@/services/api/models/user'
import { DEFINE_PASSWORD, RESET_PASSWORD, SET_NEW_PASSWORD } from './models'

const context = '[USER]:'
interface UpdateProfileArg {
  data: UserModel
  onSuccess: () => void
  onError: (err: string | unknown) => void
}

export interface PasswordArg {
  password: string
  confirmation: string
}
interface UpdatePasswordArg {
  userId?: string
  data: PasswordArg
  onSuccess: () => void
  onError: (err: string | unknown) => void
}

interface UploadPictureArg {
  data: FormData
  onSuccess: () => void
  onError: (err: string | unknown) => void
}

const state: UserState = {
  profile: undefined,
  isLoading: false,
  companies: [],
  contacts: [],
  company: null,
  companyUsers: [],
  auth0Profile: null,
  roles: [],
  currentLocale: navigator.language.split('-')[0]
}

const mutations: MutationTree<UserState> = {
  [SET_USER_PROFILE](actualState: UserState, user: UserModel) {
    actualState.profile = { ...user }
  },
  [SET_AUTH_PROFILE](actualState: UserState, user: User) {
    actualState.auth0Profile = { ...user }
  },
  [SET_COMPANIES](state, companies) {
    state.companies = [...companies]
  },

  [SET_CONTACTS](state, contacts: any) {
    state.contacts = [...contacts.data]
  },
  [SET_COMPANY](state, company: CompanyModel) {
    state.company = company
  },
  [SET_CURRENT_LOCALE](state: UserState, currentLocale: string) {
    state.currentLocale = currentLocale
  },
  [SET_IS_LOADING](state: UserState, isLoading: boolean) {
    state.isLoading = isLoading
  },
  [SET_ROLES](state: UserState, roles: Role[]) {
    state.roles = roles
  }
}

const actions: ActionTree<UserState, RootState> = {
  async searchCompanies(
    { commit }: ActionContext<UserState, RootState>,
    { query, onSuccess, onError }
  ) {
    try {
      const companies = await searchCompanies(query)
      commit(SET_COMPANIES, companies)
      onSuccess()
    } catch (error) {
      onError(error)
    }
  },

  async getCompanyInformations(
    { commit }: ActionContext<UserState, RootState>,
    { siret, onSuccess, onError }
  ) {
    try {
      const company = await getCompanyInformationsBySiret(siret)
      commit(SET_COMPANY, company)
      if (onSuccess) onSuccess()
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },

  async [GET_USER_PROFILE]({ commit }, { onSuccess, onError }) {
    try {
      commit(SET_IS_LOADING, true)
      const auth = await getInstance().getUser()
      const user = await getUserProfile(auth?.sub || '', { email: auth?.email })
      if (onError && !user) {
        onError()
        return
      }
      commit(SET_USER_PROFILE, user)
      commit('config/SET_APP_LANG', user?.lang, { root: true })
      if (onSuccess) onSuccess(user, auth)
    } catch (err) {
      console.log(err)
      if (onError) onError(err)
    } finally {
      commit(SET_IS_LOADING, false)
    }
  },

  async [ASYNC_GET_USER_PROFILE]() {
    const auth = await getInstance().getUser()

    return new Promise((resolve, reject) => {
      getUserData(auth?.sub || '')
        .then(({ data }: any) => {
          resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async [GET_COMPANY]({ commit }, { companyId }) {
    try {
      const company = await getCompany(companyId)
      commit(SET_COMPANY, company)
    } catch (error) {
      console.log(error)
    }
  },
  async [VERIFY_USER_BY_TOKEN]({ commit }, { token, onSuccess, onError }) {
    try {
      const data = await getUserDataByToken(token)
      console.log(data)

      onSuccess(data)
    } catch (error) {
      onError()
      console.log(error, 'error')
    }
  },

  async [GET_CONTACTS]({ commit }, { filter, onSuccess }) {
    try {
      const contacts = await getManagedContacts(filter)
      commit(SET_CONTACTS, contacts)
      if (onSuccess) onSuccess(contacts)
    } catch (error) {
      console.log(error)
    }
  },

  async [GET_ROLES]({ commit }, { companyId }) {
    try {
      const auth = await getInstance().getUser()
      const roles = await getUserRoles(companyId, auth?.sub || '')

      commit(SET_ROLES, roles)
    } catch (error) {
      console.log(error)
    }
  },

  async [GET_CURRENT_LOCALE]({ commit }) {
    try {
      const currentLocale = i18n.global.locale

      commit(SET_CURRENT_LOCALE, currentLocale)
    } catch (err) {
      console.log(err)
    }
  },

  async [UPDATE_CURRENT_LOCALE]({ dispatch }, { languageSelected }) {
    try {
      i18n.global.locale = languageSelected
      dispatch(GET_CURRENT_LOCALE)
    } catch (err) {
      console.log(err)
    }
  },

  async [UPDATE_USER_PROFILE](
    { dispatch },
    { data, onSuccess, onError }: UpdateProfileArg
  ) {
    try {
      const auth = await getInstance().getUser()
      await updateUserProfile(auth?.sub || '', data)
      dispatch(GET_USER_PROFILE, {})
      onSuccess()
    } catch (err) {
      error(err)
      onError(err)
    }
  },
  async [UPDATE_USER_PASSWORD](
    { dispatch },
    { data, onSuccess, onError }: UpdatePasswordArg
  ) {
    try {
      const auth = await getInstance().getUser()
      await updateUserPassword(auth?.sub || '', data)
      dispatch(GET_USER_PROFILE, {})
      onSuccess()
    } catch (err: any) {
      onError(err.data)
    }
  },

  async [DEFINE_PASSWORD](
    { dispatch },
    { userId, data, onSuccess, onError }: UpdatePasswordArg
  ) {
    try {
      await definePassword(userId || '', data)
      onSuccess()
    } catch (err: any) {
      onError(err?.data?.message)
    }
  },

  async [RESET_PASSWORD]({ dispatch }, { email, onSuccess, onError }) {
    try {
      await resetUserPassword(email)
      onSuccess()
    } catch (err: any) {
      onError(err?.data?.message)
    }
  },

  async [SET_NEW_PASSWORD]({ dispatch }, { userId, data, onSuccess, onError }) {
    try {
      await setNewPassword(userId, data)
      onSuccess()
    } catch (err: any) {
      onError(err.data?.errorCode)
    }
  },

  async [UPLOAD_PROFILE_PICTURE](
    { dispatch },
    { data, onSuccess, onError }: UploadPictureArg
  ) {
    try {
      // await changeImage(companyId,data,onProgress,data)
      dispatch(GET_USER_PROFILE)
      onSuccess()
    } catch (err) {
      error(err)
      onError(err)
    }
  },

  async [CREATE_APPLICATION](
    { commit }: ActionContext<UserState, RootState>,
    { data, onSuccess, onError }
  ) {
    try {
      await createApplication(data)
      onSuccess()
    } catch (error) {
      onError(error)
    }
  },

  async [EDIT_APPLICATION](
    { commit }: ActionContext<UserState, RootState>,
    { applicationId, data, onSuccess, onError }
  ) {
    try {
      await editApplication(applicationId, data)
      onSuccess()
    } catch (error: any) {
      onError(error.data)
    }
  },

  async [GET_APPLICATIONS](
    { commit }: ActionContext<UserState, RootState>,
    { filter, onSuccess, onError }
  ) {
    try {
      const response = await getApplications(filter)
      onSuccess(response)
    } catch (error) {
      onError(error)
    }
  },
  async [GET_APPLICATION](
    { commit }: ActionContext<UserState, RootState>,
    { applicationId, onSuccess, onError }
  ) {
    try {
      const response = await getApplication(applicationId)
      onSuccess(response)
    } catch (error) {
      onError(error)
    }
  },

  async [DELETE_APPLICATION](
    { commit }: ActionContext<UserState, RootState>,
    { applicationId, onSuccess, onError }
  ) {
    try {
      await deleteApplication(applicationId)
      onSuccess()
    } catch (error) {
      onError(error)
    }
  }
}
export type Getters = {
  profile(state: UserState): User | undefined
  isLoading(state: UserState): boolean
  currentLocale(state: UserState): string
}
const getters: GetterTree<UserState, RootState> & Getters = {
  profile: (state: UserState) => state.profile,
  auth: (state: UserState) => state.auth0Profile,
  isLoading: (state: UserState) => state.isLoading,
  companies: (state) => state.companies,
  company: (state) => state.company,
  currentLocale: (state) => state.currentLocale,
  roles: (state) => state.roles,
  contacts: (state) => state.contacts
}

const module: Module<UserState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default module
