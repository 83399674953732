import {
  getContactGroups,
  getDocCategories,
  getLegalStatus,
  getOptionCategories,
  getOrganCategories,
  getRoles,
  getStockCategories
} from '@/services/api'

import { RootState } from '@/store'
import { TYPE, useToast } from 'vue-toastification'
import {
  GET_STOCK_CATEGORIES,
  SET_STOCK_CATEGORIES,
  SET_OPTION_CATEGORIES,
  GET_OPTION_CATEGORIES,
  PDF_FIT
} from './models'
import {
  StockCategory,
  OptionStockCategory
} from '@/services/api/models/company'
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree
} from 'vuex'
import {
  ToolboxState,
  ToolboxMutations,
  ToolboxGetters,
  SET_COLORS,
  SET_COMPANY_COMMITTEES,
  SET_FORM_TYPES,
  SET_DOCUMENT_GROUP_TYPES,
  SET_EVENT_GROUPS,
  SET_STOCKS,
  SET_GROUPS,
  SET_COMPANIES,
  SET_DOCUMENT_CATEGORIES,
  SET_EVENTS_CATEGORIES
} from './models'
import { DocumentCategory, EventCategory } from '@/services/api/models'
import { EventSubCategory } from '@/services/api/models/events'
import { getEventCategories } from '@/services/api'
import {
  SET_ORGAN_CATEGORIES,
  GET_ORGAN_CATEGORIES,
  GET_LEGAL_STATUS,
  SET_LEGAL_STATUS,
  GET_CONTACT_GROUPS,
  SET_CONTACT_GROUPS
} from './models'
import { OrganCategory } from '@/services/api/models/company'
import { LegalStatus } from '@/services/api/models/company'
import ToastComponent from '@/components/Toast/index.vue'
import {
  ToastParam,
  SET_PDF_META,
  PDFMeta,
  SET_COMPANY,
  SET_ROLES,
  GET_ROLES
} from './models'
import { Role } from '@/services/api/models/user'
import { SET_EVENT_TABLE_COLUMNS } from './models'
import { canMakeAction } from '../../../middlewares/canSee'
import { RolePermission } from '../../../utils/permissions'
const context = `[TOOLBOX:]`

const state: ToolboxState = {
  eventSelectedColumns: [],
  formTypes: [],
  documentCategories: [],
  eventCategories: [],
  optionCategories: [],
  organCategories: [],
  roles: [],
  currentDocument: null,
  stockCategories: [],
  documentGroupTypes: [],
  eventGroups: [],
  groups: [],
  stocks: [],
  colors: [],
  profileAlias: [],
  companies: [],
  company: null,
  contactGroups: [],
  legalsStatus: [],
  pdfMeta: { page: 1, scale: 1.0, fit: PDF_FIT.WIDTH }
}

const mutations: MutationTree<ToolboxState> & ToolboxMutations = {
  [SET_GROUPS](state, groups) {
    state.groups = [...groups]
  },
  [SET_EVENT_TABLE_COLUMNS](state, eventSelectedColumns: string[]) {
    state.eventSelectedColumns = [...eventSelectedColumns]
  },
  [SET_DOCUMENT_CATEGORIES](state, categories: DocumentCategory[]) {
    state.documentCategories = categories
  },
  [SET_EVENTS_CATEGORIES](state, categories: EventCategory[]) {
    state.eventCategories = categories
  },
  [SET_COLORS](state, colors) {
    state.colors = [...colors]
  },
  [SET_COMPANIES](state, companies) {
    state.companies = [...companies]
  },
  [SET_COMPANY](state, company) {
    state.company = company
  },
  [SET_FORM_TYPES](state, committees) {
    state.formTypes = [...committees]
  },
  [SET_DOCUMENT_GROUP_TYPES](state, committees) {
    state.documentGroupTypes = [...committees]
  },
  [SET_EVENT_GROUPS](state, committees) {
    state.eventGroups = [...committees]
  },
  [SET_STOCKS](state, committees) {
    state.stocks = [...committees]
  },
  [SET_STOCK_CATEGORIES](state, categories: StockCategory[]) {
    state.stockCategories = [...categories]
  },
  [SET_OPTION_CATEGORIES](state, categories: OptionStockCategory[]) {
    state.optionCategories = [...categories]
  },
  [SET_ORGAN_CATEGORIES](state, categories: OrganCategory[]) {
    state.organCategories = [...categories]
  },
  [SET_ROLES](state, roles: Role[]) {
    state.roles = [...roles]
  },
  [SET_LEGAL_STATUS](state, status: LegalStatus[]) {
    state.legalsStatus = [...status].map((x) => ({ ...x, name: x.legalStatus }))
  },

  [SET_CONTACT_GROUPS](state, groups) {
    state.contactGroups = [...groups]
  },
  [SET_PDF_META](state, meta: PDFMeta) {
    state.pdfMeta = { ...state.pdfMeta, ...meta }
  }
}

const getters: GetterTree<ToolboxState, RootState> & ToolboxGetters = {
  groups: (state) => state.groups,
  companies: (state) => state.companies,
  documentCategories: (state) => state.documentCategories,
  eventCategories: (state) => state.eventCategories,
  eventSubCategories: (state) =>
    state.eventCategories.reduce(
      (acc: EventSubCategory[], item: EventCategory) => {
        acc = acc.concat(item.eventSubCategories)
        return acc
      },
      []
    ),
  getEventSubCategory: (state, getters) => (id: string) => {
    return getters.eventSubCategories.find(
      (item: EventCategory) => item.id === id
    )
  },
  currentDocument: (state) => state.currentDocument,
  stockCategories: (state) => state.stockCategories,
  optionCategories: (state) => state.optionCategories,
  organCategories: (state) => state.organCategories,
  contactStatus: (state) => state.contactGroups,
  legalStatus: (state) => state.legalsStatus,
  pdfMeta: (state) => state.pdfMeta,
  company: (state) => state.company,
  roles: (state) => state.roles,
  eventSelectedColumns: (state) => state.eventSelectedColumns
}

const actions: ActionTree<ToolboxState, RootState> = {
  displayToast(
    ctx: ActionContext<ToolboxState, RootState>,
    {
      type = TYPE.INFO,
      message,
      context = 'Uplaw Application',
      loader = false,
      loadingIcon = false
    }: ToastParam
  ) {
    const toast = useToast()
    if (type === TYPE.DEFAULT) type = TYPE.INFO
    if (!loader)
      toast[type]({ component: ToastComponent, props: { message, context } })
    else
      toast[type](message, {
        icon: `bi ${
          loadingIcon ? 'bi-arrow-clockwise animate-spin' : 'bi-check-lg'
        }  text-xl font-bold`,
        timeout: false
      })
  },

  async getDocumentCategories({
    commit
  }: ActionContext<ToolboxState, RootState>) {
    try {
      const response = await getDocCategories()
      commit(SET_DOCUMENT_CATEGORIES, response)
    } catch (error) {
      console.log(context, error)
    }
  },

  async [GET_ROLES](
    { commit }: ActionContext<ToolboxState, RootState>,
    { data, onSuccess, onError }
  ) {
    try {
      const response = await getRoles(data ?? {})
      commit(SET_ROLES, response)
      if (onSuccess) onSuccess(response)
    } catch (error) {
      console.log(context, error)
      if (onError) onError(error)
    }
  },

  async getEventCategories({ commit }: ActionContext<ToolboxState, RootState>) {
    try {
      const response = await getEventCategories()
      commit(SET_EVENTS_CATEGORIES, response)
    } catch (error) {
      console.log(context, error)
    }
  },

  async [GET_ORGAN_CATEGORIES]({
    commit
  }: ActionContext<ToolboxState, RootState>) {
    try {
      const categories = await getOrganCategories()
      commit(SET_ORGAN_CATEGORIES, categories)
    } catch (error) {
      console.log(context, error)
    }
  },

  async [GET_LEGAL_STATUS]({ commit }: ActionContext<ToolboxState, RootState>) {
    try {
      const status = await getLegalStatus()
      commit(SET_LEGAL_STATUS, status)
    } catch (error) {
      console.log(context, error)
    }
  },
  async [GET_CONTACT_GROUPS]({
    commit
  }: ActionContext<ToolboxState, RootState>) {
    try {
      if (
        !canMakeAction(RolePermission.readContacts) &&
        !canMakeAction(RolePermission.isManager)
      )
        return

      const groups = await getContactGroups()
      commit(SET_CONTACT_GROUPS, groups)
    } catch (error) {
      console.log(context, error)
    }
  },
  async [GET_OPTION_CATEGORIES]({
    commit
  }: ActionContext<ToolboxState, RootState>) {
    try {
      if (
        !canMakeAction(RolePermission.readStock) &&
        !canMakeAction(RolePermission.isManager)
      )
        return

      const categories = await getOptionCategories()
      commit(SET_OPTION_CATEGORIES, categories)
    } catch (error) {
      console.log(context, error)
    }
  },

  async [GET_STOCK_CATEGORIES]({
    commit
  }: ActionContext<ToolboxState, RootState>) {
    try {
      if (
        !canMakeAction(RolePermission.readStock) &&
        !canMakeAction(RolePermission.isManager)
      )
        return
      const categories = await getStockCategories()
      commit(SET_STOCK_CATEGORIES, categories)
    } catch (error) {
      console.log(context, error)
    }
  }
}

const module: Module<ToolboxState, RootState> = {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}

export default module
