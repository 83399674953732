export default {
  default: {},
  cancelled: {
    cancelDate: new Date(),
    reason: null,
    'table-cancelled': [
      {
        entryDate: null,
        quantity: 0,
        subscriber: null,
        totalPrice: 0,
        titles: null
      }
    ]
  }
}

export const eventEntryNameMapping: any = {
  nomination: 'NOMINATION',
  suspension: 'VESTING_SUSPENSION',
  acceleration: 'VESTING_ACCELERATION',
  prorogation: 'VESTING_PROROGATION',
  socialEnd: 'FINANCIAL_ENDING',
  exercise: 'OPTIONS_EXERCISE',
  optionLapse: 'OPTION_SUNSETS',
  officeTermination: 'APPOINTMENT_TERMINATION',
  nominal: 'NOMINAL_VALUE',
  conversion: 'CONVERSION',
  cancelled: 'CAPITAL_REDUCTION',
  commonCeiling: 'CEILING',
  delegation: 'DELEGATIONS',
  delegationSunset: 'DELEGATION_SUNSETS',
  parityChange: 'PARITY_CHANGES',
  transfer: 'TRANSFER',
  socialAppro: 'APPROVAL',
  primary: 'PRIMARY_STOCK',
  options: 'PRIMARY_OPTIONS',
  composition: 'PRIMARY_COMPOSITION',
  lapseOptions: 'OPTION_SUNSETS',
  commitment: 'COMMITMENT'
}
