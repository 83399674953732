
  import { computed, defineComponent, ref } from 'vue'
  import { EditableColumn, EditableLine } from './index.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import NumberCmp from '@/components/Number/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import { ElPopconfirm, ElTooltip } from 'element-plus'
  import InputGeneric from '@/components/Forms/Event/Generic/InputGeneric.vue'
  import DateGeneric from '@/components/Forms/Event/Generic/DateGeneric.vue'
  import DropdownVesting from '@/components/Forms/DropDowns/DropdownVesting.vue'
  import ContactGeneric from '@/components/Forms/Event/Generic/ContactGeneric.vue'
  import Loader from '@/components/Loader/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import DropdownGeneric from '@/components/Forms/DropDowns/DropdownGeneric.vue'
  import ClickableContactBadge from '@/components/ContactBadge/clickable.vue'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import DropdownOptions from '@/components/Forms/DropDowns/DropdownOptions.vue'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    components: {
      ContactBadge,
      number: NumberCmp,
      ContactGeneric,
      DateGeneric,
      ClickableContactBadge,
      DropdownGeneric,
      DropdownOptions,
      InputGeneric,
      Button,
      Tag,
      DropdownStocks,
      Loader,
      ElTooltip,
      DropdownVesting,
      ElPopconfirm
    },
    props: {
      line: {
        type: Object as () => EditableLine,
        required: true
      },
      columns: {
        type: Object as () => EditableColumn[]
      },
      withActions: { type: Boolean, required: true, default: true },
      index: { type: Number, required: true },
      partId: { type: String, required: true },
      grids: { type: Number, required: true },
      isEditing: { type: Boolean, required: true },
      canDelete: { type: Boolean, required: true, default: false },
      canEdit: { type: Boolean, required: true, default: false },
      isSelected: { type: Boolean, required: false, default: false }
    },
    emits: [
      'removeLine',
      'validateEdition',
      'startEdition',
      'cancelEdition',
      'saveNewLine',
      'removeNewLine',
      'docAutoActionsClick'
    ],
    setup(props, { emit }) {
      const data = computed<any>(() => props.line)

      const isSubmittingLine = ref(false)
      const columnsData = computed(() =>
        (props.columns ?? []).map((x) => ({
          name: x.name,
          w: x.width,
          class: x.class,
          id: x.id
        }))
      )

      const onActionClick = (colId: string, eventName: string) => {
        emit(
          'docAutoActionsClick',
          props.line[colId]?.componentProps.deleveryId,
          props.line.partId,
          eventName
        )
      }

      const cancelLine = () => {
        if (props.partId) emit('cancelEdition', props.partId, props.index)
        else emit('removeNewLine', props.line.newIndex)
      }
      const remove = () => {
        isSubmittingLine.value = true
        emit('removeLine', props.partId, props.index, afterRemove)
      }
      const afterRemove = () => {
        isSubmittingLine.value = false
      }
      const onResponse = (value: boolean) => {
        isSubmittingLine.value = !value
      }
      const edit = () => {
        isSubmittingLine.value = true
        if (props.partId)
          emit('validateEdition', props.partId, props.index, onResponse)
        else emit('saveNewLine', props.line.newIndex, props.index, onResponse)
      }
      const start = () => {
        emit('startEdition', props.partId, props.index)
      }

      return {
        columnsData,
        data,
        isSubmittingLine,
        cancelLine,
        start,
        onActionClick,
        remove,
        edit
      }
    }
  })
