<template>
  <div :class="`DropdownGeneric ${classes}`">
    <div>
      <Field
        v-slot="{ field }"
        v-model="formValue"
        :name="data.name"
        :placeholder="data.hint"
      >
        <label
          v-if="hasLabel"
          class="capitalize text-xs font-semibold text-secondary8 text-left"
          >{{ data.label }}</label
        >
        <Multiselect
          v-model="formValue"
          v-bind="field"
          :show-labels="true"
          :mode="mode"
          :options="choices"
          :group-options-field="groupBy"
          :placeholder="data.hint"
          :groups="group"
          :name="data.name"
          :tag-with-initials="false"
          class="w-full"
          @change="updateValue"
        ></Multiselect>
      </Field>
    </div>
    <div class="mt-3">
      <slot :name="data.name" :value="formValue" />
    </div>
  </div>
</template>

<script lang="ts">
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { defineComponent, onMounted, ref, toRefs } from 'vue'
  interface DataInputDD {
    name: string
    type: string
    label: string
    fieldId: string
    hint?: string
  }

  export default defineComponent({
    name: 'DropdownGeneric',
    components: { Multiselect, Field },
    inheritAttrs: false,
    props: {
      typeField: {
        type: String,
        default: 'text'
      },
      data: {
        type: Object as () => DataInputDD,
        default: () => ({
          name: '',
          type: '',
          label: '',
          fieldId: ''
        })
      },
      mode: {
        type: String,
        default: 'single'
      },

      value: {
        type: [String, Number, Array],
        default: null
      },
      error: {
        type: String,
        required: false,
        default: null
      },
      classes: {
        type: String,
        default: 'mt-4'
      },
      group: {
        default: false,
        type: Boolean
      },
      groupBy: {
        type: String,
        required: false,
        default: ''
      },
      choices: {
        default: () => [],
        type: Array
      },
      hasLabel: {
        default: true,
        type: Boolean
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const { data, value } = toRefs(props)
      const formValue = ref(value.value)

      const updateValue = (value: any) => {
        console.log(value)

        formValue.value = value

        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      }

      return { formValue, updateValue }
    }
  })
</script>
