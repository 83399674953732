
  import { computed, defineComponent, ref } from 'vue'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'
  import { useStore } from 'vuex'
  import { CompanyModel, GeneralMeetingType } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { ElTooltip } from 'element-plus'
  import Multiselect from '@/components/Select/multiselect.vue'
  export default defineComponent({
    components: { UplDatePicker, Button, ElTooltip, Multiselect, Loader },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const datePicked = ref(moment.utc().toISOString())
      const isLoading = ref(false)
      const isDownloading = ref(false)
      const selectedtype = ref()
      const generalMeetingTypes = computed(() =>
        Object.values(GeneralMeetingType).map((x) => ({
          id: x,
          name: t(`GeneralMeetingType.${x}`)
        }))
      )

      const download = () => {
        isDownloading.value = true
        store.dispatch('organizations/EXPORT_COMPANY_VOTING_RIGHT_PDF', {
          companyId: currentCompany.value?.id,
          filter: {
            date: datePicked.value,
            generalMeetingType: selectedtype.value
          },
          name: t('exports.vote'),
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      return {
        datePicked,
        selectedtype,
        isDownloading,
        isLoading,
        generalMeetingTypes,
        download
      }
    }
  })
