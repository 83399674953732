
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute, useRouter } from 'vue-router'
  import Multiselect from '@/components/Select/multiselect.vue'
  import {
    CoOwnershipType,
    Contact,
    ContactStatus,
    User
  } from '@/services/api/models'
  import EmptyState from '@/components/EmptyState/index.vue'
  import moment from 'moment'
  import { formatNumber, rgbToHex } from '@/utils/global'
  import { useI18n } from 'vue-i18n'
  import { STOCKTYPE, NumberType } from '@/types'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { ElTooltip } from 'element-plus'
  import { CompanyModel } from '@/services/api/models'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    name: 'ContactList',
    components: {
      DataTable,
      Multiselect,
      Tag,
      DownloadButton,
      Button,
      EmptyState,
      ContactBadge,
      DropdownStocks,
      Loader,
      ElTooltip,
      Filter,
      Checkbox
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const route = useRoute()
      const selectedContact = ref<string[]>([])
      const selectedGroup = ref('')
      const selectedStocks = ref<string[]>([])
      const selectedCategories = ref<string[]>([])
      const picked = ref<any>(new Date())
      const selectedColumns = ref<string[]>([])
      const isDownloading = ref(false)
      const acl = useAcl()
      const router = useRouter()

      const captableColumns = computed(() => [
        {
          label: t(`datatable.column.creationDate`),
          field: 'date',
          custom: true,
          order: 1,
          width: '10%'
        },
        {
          label: t(`datatable.column.associates`),
          field: 'associates',
          custom: true,
          order: 2,
          selected: true,
          sortBy: 'contactName',
          type: 'text',
          width: '15%'
        },
        {
          label: t(`datatable.column.groups`),
          field: 'groups',
          custom: true,
          filterable: false,
          order: 3,
          selected: true,
          sortable: true,
          type: 'text',
          width: '10%'
        },
        {
          label: t(`datatable.column.type`),
          field: 'type',
          custom: true,
          filterable: true,
          order: 4,
          selected: true,
          sortable: true,
          type: 'text',
          width: '10%'
        },

        {
          label: t(`datatable.column.email`),
          field: 'email',
          custom: true,
          filterable: false,
          order: 5,
          selected: true,
          sortable: true,
          type: 'text',
          width: '15%'
        },
        {
          label: t(`datatable.column.birthDate`),
          field: 'birthDate',
          custom: true,
          filterable: true,
          order: 6,
          selected: true,
          sortable: true,
          type: 'text',
          width: '10%'
        },
        {
          label: t(`datatable.column.phoneNumber`),
          field: 'phoneNumber',
          custom: true,
          filterable: true,
          order: 7,
          selected: true,
          sortable: true,
          type: 'text',
          width: '10%'
        },
        {
          label: t(`datatable.column.address`),
          field: 'address',
          custom: true,
          filterable: true,
          order: 8,
          selected: true,
          sortable: true,
          type: 'text',
          width: '15%'
        },
        {
          label: t(`datatable.column.stocks`),
          field: 'stocks',
          custom: true,
          selected: true,
          order: 9,
          sortable: true,
          type: 'text',
          width: '30%'
        }
      ])
      const filter = ref<any>({
        compositionAliasesId: [],
        optionAliasesId: [],
        stockAliasesId: []
      })

      const includeDelegationsAvailableBalance = ref(false)

      const isFiltered = computed(
        () =>
          selectedStocks.value.length > 0 ||
          selectedContact.value ||
          selectedGroup.value ||
          selectedCategories.value
      )

      const documentCategories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const user = computed<User>(() => store.getters['auth/profile'])

      const contacts = computed<Contact[]>(
        () => store.getters['organizations/contacts']
      )

      const isLoading = ref(false)
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const pagination = computed(
        () => store.getters['organizations/contactListPagination']
      )
      const onPageChange = (page: number) => {
        getContactsList(page)
      }
      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )

      const captable = computed(
        () => store.getters['organizations/contactsList']
      )
      const contactList = computed<any[]>(() =>
        captable.value.lines.map((x: any) => ({
          ...x,
          type: t(`contactType.${x.type}`)
        }))
      )

      const groups = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )

      const allColumns = computed(() =>
        isFiltered.value
          ? captableColumns.value
          : captableColumns.value.filter((c) => c.selected)
      )
      const existingColumns = computed(() =>
        allColumns.value.filter((x) => !x.filterable)
      )

      selectedColumns.value = []
      const columns = computed(() =>
        [
          ...allColumns.value.filter((x) =>
            selectedColumns.value.includes(x.field)
          ),
          ...existingColumns.value
        ].sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      const handleFilterSelected = (value: string[]) => {
        selectedColumns.value = value
      }

      const resetFilters = () => {
        filter.value = {
          compositionAliasesId: [],
          optionAliasesId: [],
          stockAliasesId: [],
          contactId: []
        }
        picked.value = new Date().toISOString()
        selectedStocks.value = []
        selectedContact.value = []
        selectedGroup.value = ''
        selectedCategories.value = []
      }

      const updateDocumentType = (documentTypeId: string[]) => {
        selectedCategories.value = documentTypeId
        filter.value.documentTypeId = documentTypeId
      }

      const updateStock = ({ value }: any) => {
        if (!value) {
          delete filter.value.optionAliasesId
          delete filter.value.stockAliasesId
          delete filter.value.compositionAliasesId
          getContactsList()
          return
        }
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        value.forEach((v: string) => {
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === v)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })

        selectedStocks.value = value
      }
      const updateGroup = (group: any) => {
        if (group === '') delete filter.value.contactGroupId
        else filter.value.contactGroupId = group

        selectedGroup.value = group
      }
      const updateContact = (contactId: string[]) => {
        selectedContact.value = contactId
        filter.value.contactId = contactId
      }

      const download = () => {
        isDownloading.value = true
        const companyId = route.params.id
        const data: any = { ...filter.value, date: picked.value }
        store.dispatch('organizations/EXPORT_ALL_CONTACTS', {
          companyId,
          filter: data,
          name: 'Contacts',
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const getStocks = (search?: string) => {
        const filter: any = {}
        if (search) filter.name = search
        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: route.params.id,
          filter
        })
      }

      const handleContactSearch = (search: string) => {
        getContacts(search)
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 30,
            offset: 0,
            name: search
          }
        })
      }

      const getContactsList = async (page = 1) => {
        const data: any = { ...filter.value, date: picked.value }
        await store.dispatch('organizations/GET_CONTACTS_LIST', {
          companyId: currentCompany.value?.id ?? route.params?.id,
          filter: data,
          page,
          onSuccess: () => {
            isLoading.value = false
          }
        })
      }

      const autoRedirect = () => {
        if (acl.notCan('read-contacts') && acl.can('self-contact')) {
          const account = user.value.companiesAccounts.find(
            (x) => x.companyId == route.params?.id
          )
          router.push({
            name: 'company.directory',
            params: {
              id: route.params.id,
              contactId: account?.contactId
            }
          })
        }
      }

      onBeforeMount(async () => {
        getStocks()
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 100,
            offset: 0
          }
        })
      })

      watch(
        [
          selectedStocks,
          selectedGroup,
          selectedCategories,
          () => selectedContact.value
        ],
        () => {
          store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)
          getContactsList()
        }
      )

      watch(picked, (val: any) => {
        if (val) filter.value.date = moment(val).toISOString()
        else delete filter.value.date
        getContactsList()
      })

      watch(
        () => route.params.id,
        (val) => {
          getContactsList(1)
        }
      )

      onMounted(() => {
        autoRedirect()
        isLoading.value = true
        getContactsList()
      })

      return {
        picked,
        captable,
        groups,
        isDownloading,
        selectedStocks,
        selectedContact,
        selectedGroup,
        selectedCategories,
        contacts,
        columns,
        isLoading,
        pagination,
        isFiltered,
        companyGroups,
        handleContactSearch,
        updateStock,
        updateContact,
        updateGroup,
        updateDocumentType,
        formatNumber,
        onPageChange,
        download,
        includeDelegationsAvailableBalance,
        moment,
        CoOwnershipType,
        availableColumns,
        handleFilterSelected,
        selectedColumns,
        resetFilters,
        currentCompany,
        NumberType,
        rgbToHex,
        contactList,
        documentCategories
      }
    }
  })
