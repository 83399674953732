
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'

  import TableLine from './line.vue'
  export interface EditableLineData {
    partId: string
    newIndex?: number
    class: string
    colName: string
    componentProps: any
    component: string
    editComponent: string
    editComponentProps: any
  }

  export interface EditableColumn {
    name: string
    class: string
    width: string
    id: string
  }

  export interface EditableTotalLine {
    name?: string
    value?: any
    class: string
    key: string
    width?: string
  }

  export interface EditableLine {
    [key: string]: EditableLineData | any
  }

  export default defineComponent({
    components: {
      TableLine
    },
    props: {
      filtered: { type: Object },
      lines: {
        type: Object as () => EditableLine[]
      },
      totalLine: {
        type: Object as () => EditableTotalLine[]
      },
      columns: {
        type: Object as () => EditableColumn[]
      },
      displayTotal: { type: Boolean, default: true },
      grids: { type: Number, required: true },
      canEditLine: { type: Boolean, default: false },
      canDeleteLine: { type: Boolean, default: false }
    },
    emits: [
      'removePart',
      'removeNewLine',
      'saveLine',
      'saveNewPart',
      'resetLine',
      'startLineEdition',
      'docAutoActionsClick'
    ],
    setup(props, { emit }) {
      const initialLines = ref<EditableLine[]>([])
      const filteredParts = computed(() => props.filtered?.partsId ?? [])
      const columnsData = computed(() =>
        (props.columns ?? []).map((x) => ({
          name: x.name,
          w: x.width,
          class: x.class,
          id: x.id
        }))
      )

      const editingParts = ref<string[]>([])
      const cancelEdition = (partId: string) => {
        const index = props.lines?.findIndex((x) => x.partId == partId) ?? -1
        editingParts.value.splice(editingParts.value.indexOf(partId), 1)
        emit('resetLine', index, partId) // reset old values
      }

      const handleActions = (a: any, b: any, c: any) => {
        emit('docAutoActionsClick', a, b, c)
      }

      const cancelLine = (index: number) => {
        emit('removeNewLine', index)
      }
      const removeLine = (partId: any, index: number, handleResponse: any) => {
        emit(
          'removePart',
          partId,
          (partId: string, data: any) => {
            handleResponse(true)
          },
          (partId: string, data: any) => {
            onError(partId, data, handleResponse)
          }
        )
      }
      const onLineSaved = (partId: string, data: any, handleResponse: any) => {
        editingParts.value.splice(editingParts.value.indexOf(partId), 1)
        handleResponse(true)
      }

      const onError = (partId: string, data: any, handleResponse: any) => {
        handleResponse(true)
      }

      const validateLine = (
        partId: string,
        index: number,
        handleResponse: any
      ) => {
        emit(
          'saveLine',
          partId,
          (partId: string, data: any) => {
            onLineSaved(partId, data, handleResponse)
          },
          (partId: string, data: any) => {
            onError(partId, data, handleResponse)
          }
        )
      }

      const validateNewLine = (
        index: number,
        arrayIndex: number,
        handleResponse: any
      ) => {
        emit(
          'saveNewPart',
          index,
          arrayIndex,
          (partId: string, data: any) => {
            onLineSaved(partId, data, handleResponse)
            cancelLine(index)
          },
          (partId: string, data: any) => {
            onError(partId, data, handleResponse)
          }
        )
      }

      const editLine = (partId: string, index: number) => {
        editingParts.value.push(partId)
        emit('startLineEdition', partId, index)
      }

      onMounted(() => {
        initialLines.value = [...(props.lines ?? [])]
      })

      return {
        columnsData,
        editingParts,
        filteredParts,
        initialLines,
        cancelEdition,
        validateLine,
        removeLine,
        validateNewLine,
        editLine,
        handleActions,
        cancelLine
      }
    }
  })
