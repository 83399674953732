<template>
  <div class="w-full h-full px-12 flex flex-col decisions overflow-y-scroll">
    <div class="flex">
      <h3 class="text text-blackp text-2xl pt-8">
        {{ $t('company.decisions_title') }}
      </h3>
    </div>

    <div class="flex mt-5">
      <div class="w-1/5 mr-2">
        <Field v-slot="{ field }" v-model="search" name="search">
          <Input
            name="search"
            v-bind="field"
            :placeholder="$t('global.placeholder.search')"
          >
            <template #left>
              <i class="bi bi-search"></i>
            </template>
          </Input>
        </Field>
      </div>
    </div>

    <div v-if="!loading" class="mt-2 text-sm">
      <data-table
        v-if="documents.length > 0 && decisionsCategories.length > 0"
        :columns="columns"
        :rows="documents"
        :pagination="pagination"
        @onPageChange="onPageChange"
      >
        <template #date="{ row }">
          <p v-if="row.signedAt" class="text-left">
            {{ moment.utc(row.signedAt).format('L') }}
          </p>
          <p v-else class="text-left">
            {{ moment.utc(row.registredAt).format('L') }}
          </p>
        </template>

        <template #type="{ row }">
          <p class="text-left">
            {{ row.docSubCategory?.name || '-' }}
          </p>
        </template>
        <template #actions="{ row }">
          <div class="flex p-1 justify-between">
            <Button
              v-if="row"
              variant="tertiary"
              size="medium"
              class="p-1 rounded-sm mx-1"
              label=""
              @click="editFile(row)"
              ><i class="bi bi-pen text-xl"></i
            ></Button>
            <Button
              variant="secondary"
              size="medium"
              class="p-1 rounded-sm mx-1"
              label=""
              @click="selectDocument(row)"
              ><i class="bi bi-eye text-xl"></i
            ></Button>
          </div>
        </template>
        <template #name="{ row }">
          <LongText
            :text="row?.label || row?.name"
            tag="p"
            classes="text-left"
            :max-lenght="100"
          />
        </template>
      </data-table>

      <div
        v-else
        class="
          flex
          justify-center
          items-center
          w-full
          mt-4
          transition transition-all
        "
      >
        <EmptyState
          class="d-block mx-auto datatable"
          :title="$t('global.noResult')"
          :subtitle="$t('global.noFilesOnRegister')"
        />
      </div>
    </div>
    <Loader v-else />
  </div>
</template>
<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { meetingColumns } from '@/utils/data'
  import DataTable from '@/components/DataTable/index.vue'
  import {
    DocumentCategory,
    Document as DocumentModel
  } from '@/services/api/models'
  import { DocumentMarkAs } from '@/types'
  import moment from 'moment'
  import LongText from '@/components/LongText/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    name: 'UplStockMovements',
    components: {
      Field,
      Input,
      Button,
      DataTable,
      LongText,
      EmptyState,
      Loader
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const search = ref('')
      const { t } = useI18n()
      const loading = ref(true)
      const documents = ref<DocumentModel[]>([])
      const categories = computed<DocumentCategory[]>(
        () => store.getters['toolbox/documentCategories']
      )

      const decisionsCategories = computed(() =>
        categories.value
          .map((x) => {
            return x.docSubCategories.filter(
              (y) => y.markAs === DocumentMarkAs.president_decisions
            )
          })
          .flat()
          .map((x) => x.id)
      )

      const pagination = computed(
        () => store.getters['organizations/documentsPagination']
      )
      const onPageChange = (page: number) => {
        store.commit('organizations/SET_DOCUMENTS_PAGINATION_PAGE', page)
        getDocuments()
      }
      const getDocuments = async (search = '') => {
        if (decisionsCategories.value.length == 0) {
          loading.value = false
          return
        }
        loading.value = true
        await store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: {
            search,
            documentSubCategoriesId: decisionsCategories.value
          },
          onSuccess: (result: any) => {
            documents.value = result.data
            loading.value = false
          }
        })
      }

      const selectDocument = async (doc: DocumentModel) => {
        store.commit('ui/DISPLAY_PREVIEW', true)

        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }

      const columns = computed(() => [
        {
          label: 'Date',
          field: 'date',
          custom: true
        },
        {
          label: t('documents.elements.decisionType'),
          field: 'type',
          custom: true
        },
        {
          label: 'Document',
          field: 'name',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          custom: true,
          width: '5%'
        }
      ])

      const editFile = (document: DocumentModel) => {
        router.push({
          name: 'companyDocuments',
          params: {
            id: route.params.id,
            documentId: document.id
          }
        })
      }
      const downloadableDocument = (row: DocumentModel) => {
        return row.documentVersions?.find((x) => x.isMaster)
      }

      watch(decisionsCategories, () => {
        getDocuments()
      })

      watch(search, (val) => {
        getDocuments(val)
      })

      onMounted(() => {
        store.commit('organizations/SET_DOCUMENTS_PAGINATION_PAGE', 1)
        getDocuments()
      })

      return {
        search,
        loading,
        documents,
        columns,
        moment,
        pagination,
        decisionsCategories,
        downloadableDocument,
        onPageChange,
        editFile,
        selectDocument
      }
    }
  })
</script>
<style lang="scss">
  .decisions {
    .download {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
</style>
