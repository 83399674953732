
  import { computed, defineComponent } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { ElPopconfirm } from 'element-plus'
  export default defineComponent({
    components: { Button, ElPopconfirm },
    props: {
      errors: {
        type: Object as () => Record<string, string>,
        required: true
      },
      meta: {
        type: Object as () => any,
        required: true
      },
      hasDeletionButton: { type: Boolean, default: false },
      hasSubmitButton: { type: Boolean, default: true },
      isSubmitting: { type: Boolean, default: false },
      submittingText: { type: String, default: null },
      submitLabel: {
        type: String,
        required: false,
        default: null
      }
    },
    emits: ['resetForm', 'delete'],
    setup(props, { emit }) {
      const store = useStore()
      const errorsKeys = computed(() => Object.keys(props.errors).slice(0, 3))

      const initStockForm = () => {
        emit('resetForm')
        store.dispatch('organizations/INIT_MODAL_FORM', {
          ...store.getters['organizations/form'],
          modalInit: false,
          initCurrentForm: true
        })
      }

      const remove = () => {
        emit('delete')
      }
      return { initStockForm, remove, errorsKeys }
    }
  })
