<template>
  <div class="extract-wrapper-firstlevel pt-4 px-8 relative overflow-hidden">
    <div class="flex justify-between relative mb-2">
      <div class="flex flex-col justify-start items-start">
        <h3 class="text-secondary9 font-bold">
          {{ $t('events.create.title') }}
        </h3>
        <span class="text-secondary9 font-normal text-sm">
          {{ $t('events.create.subtitle') }}
        </span>
      </div>
    </div>
    <div class="info-wrapper mt-3 m-0">
      <section class="grid grid-cols-6 gap-4">
        <div class="col-span-3">
          <label
            class="capitalize text-xs font-semibold text-secondary8 text-left"
            >{{ $t('events.elements.eventType') }}</label
          >
          <Multiselect
            :show-labels="true"
            mode="single"
            :value="selectedCat"
            :options="categories"
            group-options-field="group"
            :placeholder="$t('events.elements.eventType')"
            :groups="true"
            :filter-results="true"
            class="w-full h-10"
            @change="onOptionSelected"
          >
          </Multiselect>
        </div>

        <div v-if="selectedCat" class="flex justify-start items-end col-span-3">
          <label
            class="capitalize text-xs font-semibold text-secondary8 text-left"
          ></label>
          <div class="bg-secondary4 rounded px-2">
            <Checkbox
              v-model="isDraft"
              name="isDraft"
              :checked="isDraft"
              :label="$t('events.elements.isDraft')"
              @on-change="isDraft = $event"
            />
          </div>
        </div>
      </section>

      <section v-if="selectedCat !== ''" class="transition-all">
        <form-wrapper
          type="event"
          :selected-cat="selectedCat"
          :is-draft="isDraft"
          @update-category="onOptionSelected"
        />
      </section>
    </div>
  </div>
</template>

<script lang="ts">
  import { useStore } from 'vuex'
  import { useI18n } from 'vue-i18n'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { computed, defineComponent, ref } from 'vue'
  import FormWrapper from '@/components/Forms/Event/Generic/FormWrapper.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { NominalValue } from '@/services/api/models'
  import { EventType } from '@/types/event'
  export default defineComponent({
    name: 'EventForm',
    components: {
      FormWrapper,
      Multiselect,
      Checkbox
    },
    props: {
      uuid: {
        type: String,
        default: ''
      }
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()

      const isDraft = ref(false)

      const selectedCat = ref('')

      const onOptionSelected = (value: string) => {
        selectedCat.value = value
      }
      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )

      const mockCats = [
        {
          id: '10',
          name: t(`events.categories.stockOperations`),
          value: 'cancel',
          group: [
            {
              id: EventType.capitalReduction,
              name: t(`events.categories.cancel`),
              value: 'cancel'
            },
            {
              id: EventType.conversion,
              name: t(`events.categories.conversion`),
              value: 'conversion'
            },
            {
              id: EventType.parityChange,
              name: t(`events.categories.parityChange`),
              value: 'parityChange'
            },
            {
              id: EventType.optionSunset,
              name: t(`events.categories.lapseOptions`),
              value: 'lapseOptions'
            }
          ]
        },
        {
          id: '31',
          name: t(`events.categories.emit`),
          value: 'emit',
          group: [
            {
              id: EventType.stockIssuance,
              name: t(`events.categories.stockIssuance`),
              value: 'primary'
            },
            {
              id: EventType.optionIssuance,
              name: t(`events.categories.optionIssuance`),
              value: 'options'
            },
            {
              id: EventType.compositionIssuance,
              name: t(`events.categories.compositionIssuance`),
              value: 'composition'
            },
            {
              id: EventType.optionExercise,
              name: t(`events.categories.exercise`),
              value: 'exercise'
            }
          ]
        },
        {
          id: '40',
          name: t(`events.categories.transfer`),
          value: 'transfer',
          group: [
            {
              id: EventType.transfer,
              name: t(`events.categories.transfer`),
              value: 'transfer'
            }
          ]
        },
        {
          id: '50',
          name: t(`events.categories.nominal`),
          value: 'nominal',
          group: [
            {
              id: EventType.nominalValue,
              name: t(
                `events.categories.${
                  nominalValues.value.length
                    ? 'edit_nominal_value'
                    : 'fix_nominal_value'
                }`
              ),
              value: 'fix_nominal_value'
            }
          ]
        },
        {
          id: '60',
          name: t(`events.categories.delegation`),
          value: 'delegation',
          group: [
            {
              id: EventType.commongCeiling,
              name: t(`events.categories.commonCeiling`),
              value: 'commonCeiling'
            },
            {
              id: EventType.delegation,
              name: t(`events.categories.delegation`),
              value: 'delegation'
            },
            {
              id: EventType.delegationSunset,
              name: t(`events.categories.delegationSunset`),
              value: 'delegationSunset'
            }
          ]
        },
        {
          id: '70',
          name: t(`events.categories.vesting`),
          value: 'vesting',
          group: [
            {
              id: EventType.vestingSuspension,
              name: t(`events.categories.suspension`),
              value: 'suspension'
            },
            {
              id: EventType.vestingAcceleration,
              name: t(`events.categories.acceleration`),
              value: 'acceleration'
            },
            {
              id: EventType.exerciseDelayProrogation,
              name: t(`events.categories.prorogation`),
              value: 'prorogation'
            }
          ]
        },
        {
          id: '80',
          name: t(`events.categories.mandates`),
          value: 'nominal',
          group: [
            {
              id: EventType.appointment,
              name: t(`events.categories.nomination`),
              value: 'nomination'
            },
            {
              id: EventType.appointmentTermination,
              name: t(`events.categories.officeTermination`),
              value: 'appointmentTermination'
            }
          ]
        },
        {
          id: '10000',
          name: t(`events.categories.commitment`),
          value: 'commitment',
          group: [
            {
              id: EventType.commitment,
              name: t(`events.categories.commitment`),
              value: 'commitment'
            }
          ]
        },

        {
          id: '100',
          name: t(`events.categories.social`),
          value: 'social',
          group: [
            {
              id: EventType.financialEnding,
              name: t(`events.categories.socialEnd`),
              value: 'socialEnd'
            },
            {
              id: EventType.accountApproval,
              name: t(`events.categories.approbation`),
              value: 'socialAppro'
            }
          ]
        }
      ]
      const categories = computed(() => mockCats)

      return {
        selectedCat,
        categories,
        onOptionSelected,
        isDraft
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import 'firstLevel.scss';
</style>
