<template>
  <div class="filters w-full">
    <div class="flex items-end upl-treeview__body">
      <div class="flex flex-col w-3/5 mr-2 upl-treeview__search relative">
        <Field
          v-slot="{ field }"
          v-model="filtersData.search"
          name="search"
          class="relative"
        >
          <Input
            v-model="filtersData.search"
            v-bind="field"
            class="h-10 corporate-search"
            type="search"
            :no-error="true"
            name="search"
            :disabled="false"
            :placeholder="$t('global.placeholder.search')"
            @input="updateFilters($event, 'document', 'search')"
          />
        </Field>
        <div class="relative">
          <div
            v-if="filtersData.search"
            v-click-away="hideSearchResults"
            style="z-index: 999"
            class="upl-treeview__search__hints flex z-50 bg-white"
          >
            <SearchResult
              :filter="filtersData.search"
              :data="searchResults"
              @close="initSearch"
            />
          </div>
        </div>
      </div>
      <div class="flex flex-col w-2/5">
        <Datepicker
          v-model="picked"
          range
          :mask="$t('global.dateRangeMask')"
          class="rounded h-10 text-xs mr-2"
          :placeholder="$t('global.placeholder.dateRange')"
        />
      </div>

      <div class="flex flex-col w-2/5 mr-2">
        <multiselect
          v-model="operationsId"
          :value="operationsId"
          mode="multiple"
          class="min-h-full"
          open-direction="bottom"
          :tag-with-initials="false"
          elementName="global.operations"
          selectionText="global.actions.selected2"
          :options="operations"
          :placeholder="$t('global.placeholder.operations')"
          @change="updateFilters($event, 'operationsId')"
        />
      </div>

      <div class="w-1/5 mr-2">
        <div class="flex flex-col w-full">
          <multiselect
            v-model="draft"
            :value="draft"
            mode="multiple"
            class="min-h-full"
            open-direction="bottom"
            :tag-with-initials="false"
            :options="status"
            :groups="false"
            :placeholder="$t('events.draftStatus.placeholder')"
            @change="updateFilters($event, 'draft')"
          />
        </div>
      </div>

      <!-- <div class="h-10 mr-2" v-if="$can('admin')">
        <DownloadButton
          class="mr-2 h-full"
          :on-click="downloadList"
        ></DownloadButton>
      </div> -->
      <div class="h-10">
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="$t('tooltips.resetFilter')"
          placement="left"
        >
          <Button variant="custom" class="button-filter" @click="resetFilters">
            <i class="bi bi-filter text-2xl"></i> </Button
        ></el-tooltip>
      </div>
    </div>
    <div class="flex mt-4">
      <div class="w-1/4 mr-2">
        <div class="flex flex-col w-full">
          <multiselect
            v-model="categories"
            :value="categories"
            mode="multiple"
            class="min-h-full"
            open-direction="bottom"
            :tag-with-initials="false"
            elementName="documents.categories"
            selectionText="global.actions.selected2"
            :options="documentCategories"
            :groups="true"
            group-options-field="docSubCategories"
            :placeholder="$t('documents.types')"
            @change="updateFilters($event, 'documentSubCategoriesId')"
          />
        </div>
      </div>
      <div class="w-1/4 mr-2">
        <div class="flex flex-col w-full">
          <Multiselect
            :value="eventTypes"
            :show-labels="true"
            mode="multiple"
            :tag-with-initials="false"
            :options="eventCategories"
            group-options-field="group"
            :placeholder="$t('global.placeholder.eventTypes')"
            elementName="events.events"
            selectionText="global.actions.selected1"
            :groups="true"
            class="w-full h-full"
            @change="($event) => updateFilters($event, 'eventTypes')"
          >
          </Multiselect>
        </div>
      </div>
      <div class="w-1/4 mr-2">
        <div class="flex flex-col w-full">
          <dropdown-contacts
            v-model="contactsId"
            :elements="contacts"
            :filterResults="false"
            :placeholder="$t('global.placeholder.contacts')"
            mode="multiple"
            class="h-full"
            open-direction="bottom"
            :can-add-new="false"
            @update:modelValue="updateFilters($event, 'contactsId')"
          />
        </div>
      </div>
      <div class="w-1/4">
        <div class="flex flex-col w-full">
          <dropdown-stocks
            v-model="aliasesId"
            :has-label="false"
            :can-add-new="false"
            :option-per-price="false"
            open-direction="bottom"
            :data="{ name: 'stocks' }"
            :value="aliasesId"
            :placeholder="$t('global.placeholder.stocks')"
            mode="multiple"
            @on-updated="updateFilters($event, 'stocksId')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    toRefs,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import Datepicker from '@/components/DatePicker/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import moment from 'moment'
  import { Field } from 'vee-validate'
  import SearchResult from '@/components/DocumentSearchResult/index.vue'
  import { OptionStockAlias } from '@/services/api/models'
  import { ElTooltip } from 'element-plus'
  import { useI18n } from 'vue-i18n'
  import { EventType } from '@/types/event'
  export default defineComponent({
    name: 'FiltersCorporate',
    components: {
      Datepicker,

      SearchResult,
      DropdownStocks,
      DropdownContacts,
      Multiselect,
      Input,
      ElTooltip,
      Button,
      Field
    },
    props: {
      filters: {
        default: () => ({}),
        type: Object
      },
      type: {
        type: String,
        required: true
      }
    },
    emits: ['update-filters'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const search = ref('')
      const resultPopover = ref(null)
      const { t } = useI18n()
      const { filters } = toRefs(props)
      const filtersData = ref<any>(filters.value)
      const contactsId = ref([])
      const categories = ref([])
      const draft = ref([])
      const stockIds = ref<string[]>([])
      const aliasesId = ref<string[]>([])
      const optionIds = ref<string[]>([])
      const compositionIds = ref<string[]>([])
      const eventTypes = ref([])
      const operationsId = ref([])
      const documentCategories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])
      const picked = ref([])
      const contacts = computed(() => store.getters['organizations/contacts'])
      const organs = computed(() => store.getters['organizations/committees'])
      const eventCategories = computed(() => [
        {
          id: '10',
          name: t(`events.categories.stockOperations`),
          value: 'cancel',
          group: [
            {
              id: EventType.capitalReduction,
              name: t(`events.categories.capital`),
              value: 'capital'
            },
            {
              id: EventType.optionExercise,
              name: t(`events.categories.exercise`),
              value: 'exercise'
            },
            {
              id: EventType.conversion,
              name: t(`events.categories.conversion`),
              value: 'conversion'
            },
            {
              id: EventType.parityChange,
              name: t(`events.categories.parityChange`),
              value: 'parityChange'
            },
            {
              id: EventType.optionSunset,
              name: t(`events.categories.lapseOptions`),
              value: 'lapseOptions'
            }
          ]
        },
        {
          id: '31',
          name: t(`events.categories.emit`),
          value: 'emit',
          group: [
            {
              id: EventType.stockIssuance,
              name: t(`events.categories.primary`),
              value: 'primary'
            },
            {
              id: EventType.optionIssuance,
              name: t(`events.categories.option`),
              value: 'option'
            },
            {
              id: EventType.compositionIssuance,
              name: t(`events.categories.composition`),
              value: 'composition'
            }
          ]
        },
        {
          id: '40',
          name: t(`events.categories.transfer`),
          value: 'transfer',
          group: [
            {
              id: EventType.transfer,
              name: t(`events.categories.transfer`),
              value: 'transfer'
            }
          ]
        },
        {
          id: '50',
          name: t(`events.categories.nominal`),
          value: 'nominal',
          group: [
            {
              id: EventType.nominalValue,
              name: t(`events.categories.fix_nominal_value`),
              value: 'fix_nominal_value'
            }
          ]
        },
        {
          id: '60',
          name: t(`events.categories.delegation`),
          value: 'delegation',
          group: [
            {
              id: EventType.commongCeiling,
              name: t(`events.categories.commonCeiling`),
              value: 'commonCeiling'
            },
            {
              id: EventType.delegation,
              name: t(`events.categories.delegation`),
              value: 'delegation'
            },
            {
              id: EventType.delegationSunset,
              name: t(`events.categories.delegationSunset`),
              value: 'delegationSunset'
            }
          ]
        },
        {
          id: '70',
          name: t(`events.categories.vesting`),
          value: 'delegation',
          group: [
            {
              id: EventType.vestingSuspension,
              name: t(`events.categories.suspension`),
              value: 'suspension'
            },
            {
              id: EventType.vestingAcceleration,
              name: t(`events.categories.acceleration`),
              value: 'acceleration'
            },
            {
              id: EventType.exerciseDelayProrogation,
              name: t(`events.categories.prorogation`),
              value: 'prorogation'
            }
          ]
        },
        {
          id: '80',
          name: t(`events.categories.nomination`),
          value: 'nominal',
          group: [
            {
              id: EventType.appointment,
              name: t(`events.categories.nomination`),
              value: 'nomination'
            },
            {
              id: EventType.appointmentTermination,
              name: t(`events.categories.officeTermination`),
              value: 'officeTermination'
            }
          ]
        },

        {
          id: '100',
          name: t(`events.categories.others`),
          value: 'social',
          group: [
            {
              id: EventType.financialEnding,
              name: t(`events.categories.socialEnd`),
              value: 'socialEnd'
            },
            {
              id: EventType.accountApproval,
              name: t(`events.categories.approbation`),
              value: 'socialAppro'
            },
            {
              id: EventType.commitment,
              name: t(`events.categories.commitment`),
              value: 'commitment'
            }
          ]
        }
      ])
      const documents = computed(
        () => store.getters['organizations/corporateDocuments']
      )
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )

      const status = computed(() => [
        { id: true, name: t('events.draftStatus.isDraft') },
        { id: false, name: t('events.draftStatus.validated') }
      ])
      const optionStockAliases = computed<OptionStockAlias[]>(
        () => store.getters['organizations/optionStockAliases']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const searchResults = computed(
        () => store.getters['organizations/searchResults']
      )
      const operations = computed(
        () => store.getters['organizations/operations']
      )
      const resetFilters = () => {
        picked.value = []
        draft.value = []
        filtersData.value = {
          endDate: null,
          startDate: '1970-01-01'
        }
        aliasesId.value = []
        stockIds.value = []
        contactsId.value = []
        categories.value = []
        eventTypes.value = []
        operationsId.value = []

        emit('update-filters', filtersData.value, props.type)
      }
      const isOption = (id: string) => {
        return (
          optionStockAliases.value.find((item: any) => item.id === id) !==
          undefined
        )
      }
      const isDownloading = ref(false)

      const downloadList = () => {
        const companyId = route.params.id
        store.dispatch('organizations/exportHotjar', {
          name: `Caducités`,
          companyId,
          filter: filtersData.value,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }
      const isComposition = (id: string) => {
        return (
          compositionStockAlias.value.find((item: any) => item.id === id) !==
          undefined
        )
      }

      const updateFilters = (e: any, filter: string, field = '') => {
        if (filter === 'stocksId' && e.value) {
          optionIds.value = []
          compositionIds.value = []
          stockIds.value = []
          e.value.forEach((element: string) => {
            if (isOption(element)) {
              optionIds.value.push(element)
            } else if (isComposition(element)) {
              compositionIds.value.push(element)
            } else {
              stockIds.value.push(element)
            }
          })
          filtersData.value['optionAliasesId'] = optionIds.value
          filtersData.value['compositionAliasesId'] = compositionIds.value
          filtersData.value['stockAliasesId'] = stockIds.value
          aliasesId.value = e.value
        } else {
          filtersData.value[filter] = e
        }

        emit('update-filters', filtersData.value, props.type, field)
      }

      watch(picked, (newDateRange) => {
        let dateFilter: any = {
          endDate: null,
          startDate: '1970-01-01'
        }
        if (newDateRange && newDateRange.length > 0) {
          dateFilter = {
            startDate:
              newDateRange && newDateRange[0]
                ? moment.utc(newDateRange[0]).startOf('day').toISOString()
                : null,
            endDate:
              newDateRange && newDateRange[1]
                ? moment.utc(newDateRange[1]).endOf('day').toISOString()
                : null
          }
        }
        filtersData.value = { ...filtersData.value, ...dateFilter }
        emit('update-filters', filtersData.value, props.type)
      })

      const initSearch = () => {
        filtersData.value.search = null
      }
      const hideSearchResults = () => {
        filtersData.value.search = null
      }

      watch(
        () => filtersData.value.search,
        () => {
          // resultPopover.value?.click()
        }
      )

      onBeforeMount(() => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0
          }
        })
        store.dispatch('organizations/GET_COMPANY_COMMITTEES', {
          companyId: route.params.id
        })
        store.dispatch('organizations/GET_OPERATIONS', {
          companyId: route.params.id
        })

        store.dispatch('organizations/GET_ALL_STOCK', {
          companyId: route.params.id
        })
      })
      onMounted(() => {
        //
      })

      return {
        search,
        eventTypes,
        documentCategories,
        eventCategories,
        categories,
        contacts,
        contactsId,
        organs,
        documents,
        stockIds,
        aliasesId,
        picked,
        draft,
        filtersData,
        status,
        downloadList,
        updateFilters,
        resetFilters,
        hideSearchResults,
        searchResults,
        resultPopover,
        initSearch,
        operationsId,
        operations
      }
    }
  })
</script>

<style lang="scss">
  @import './style.scss';
</style>
