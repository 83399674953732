
  import { defineComponent } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  export default defineComponent({
    components: { Button },
    props: {
      errors: {
        type: Object as () => Partial<Record<string, string | undefined>>,
        required: true
      },
      meta: {
        type: Object as () => Record<string, boolean>,
        required: true
      },
      position: { type: String, default: 'absolute' }
    },
    emits: ['resetForm', 'onSubmit'],
    setup(props, { emit }) {
      const store = useStore()

      const initStockForm = () => {
        emit('resetForm')
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true
        })

        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
        store.commit('organizations/SET_CURRENT_VESTING', null)
      }
      return { initStockForm }
    }
  })
