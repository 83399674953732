
  import userCanSee from '@/middlewares/canSee'
  import { computed, defineComponent, ref, onMounted } from 'vue'
  import Unauthorized from '@/views/auth/unauthorized.vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'

  export default defineComponent({
    setup() {
      const route = useRoute()
      const store = useStore()
      const isLoading = ref(true)
      const canAccess = computed(() => userCanSee(route))

      onMounted(() => {
        store.dispatch(`auth/GET_USER_PROFILE`, {
          onError: async (data: any) => {
            //
          },
          onSuccess: () => {
            setTimeout(() => {
              isLoading.value = false
            }, 3500)
          }
        })
      })
      return { Unauthorized, canAccess, isLoading }
    }
  })
