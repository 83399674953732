<template>
  <div class="h-full flex flex-col items-start w-full overflow-scroll">
    <div class="flex px-12">
      <h3 class="text text-blackp text-xl py-8">
        {{ $t('company.corporateDocuments') }} ({{ count }})
      </h3>
    </div>
    <div class="h-full flex flex-col items-start w-full px-12">
      <filters type="document" @update-filters="updateFilters" />
      <DocumentsTab :filters="filters" @update-count="count = $event" />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import DocumentsTab from './documentsTab.vue'
  import Filters from './filtersCorporate.vue'

  export default defineComponent({
    components: {
      Filters,
      DocumentsTab
    },
    emits: ['on-selected'],
    setup() {
      const store = useStore()
      const route = useRoute()
      const count = ref(0)
      const selectedTab = ref(0)
      const filters = ref<any>({ search: '' })
      const resetFilters = (tab: number) => {
        filters.value = {}
      }
      const updateFilters = async (e: any, field = '') => {
        if (field === 'search') {
          store.dispatch('organizations/SEARCH_IN_DOCUMENTS', {
            query: e.search,
            companyId: route.params.id
          })

          return
        }

        store.commit('organizations/SET_DOCUMENTS_PAGINATION_PAGE', 1)

        filters.value = { ...e }

        if (filters.value?.contactsId?.length) {
          filters.value['contactsId'] = filters.value?.contactsId.reduce(
            (acc: string[], x: string) => {
              const v = x.split('@_')
              acc.push(...v)
              return acc
            },
            []
          )
        }
        await store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          filter: { ...filters.value, limit: 20, offset: 0 },
          companyId: route.params.id
        })
      }

      watch(
        () => route.query?.type,
        (val) => {
          if (val == 'event') selectedTab.value = 0
        }
      )

      onMounted(() => {
        if (route.query?.type == 'event') selectedTab.value = 0

        store.commit('ui/DISPLAY_PREVIEW', false)
      })

      return {
        selectedTab,
        filters,
        count,
        updateFilters,
        resetFilters
      }
    }
  })
</script>
<style lang="scss" scoped>
  @import './style.scss';
  .tabList {
    @apply px-12 w-full flex justify-start border-solid border-b border-secondary4 space-x-4;
    .tabBtn {
      padding: 0 24px;
      @apply pb-2;
      &.selected {
        @apply border-solid border-b-2 border-primary5 text-primary5;
        margin-bottom: -1px;
      }
      &.not-selected {
        @apply text-secondary9 hover:text-primary5;
      }
    }
  }
</style>
