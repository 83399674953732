
  import { defineComponent, computed, ref, onMounted, toRefs, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { useRoute } from 'vue-router'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { Delegation } from '@/services/api/models'
  import moment from 'moment'
  export default defineComponent({
    name: 'DropdownDelegations',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      allData: {
        default: () => ({}),
        type: Object
      },

      classItem: {
        type: String,
        default: 'w-full'
      },
      type: {
        type: String,
        default: null
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        default: '',
        type: String
      },
      endDate: {
        default: '',
        type: String
      },
      validationState: { type: String, default: '' },
      openDirection: {
        type: String,
        default: 'top'
      },
      grid: {
        default: true,
        type: Boolean
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()
      const { data } = toRefs(props)
      const route = useRoute()

      // allData will be useful for filter if delegation is selected for example
      const formValue = ref<any>(props.value)
      const searchText = ref('')
      const newDelegs = ref<Delegation[]>([])
      const selectedDelegs = ref<Delegation[]>([])
      const selectedDeleg = ref<Delegation | null>(null)
      const allDelegations = computed(
        () => store.getters['organizations/delegations']
      )
      const eventDelegations = computed(() => {
        return props.type === 'stockAlias'
          ? allDelegations.value.filter((x: Delegation) => x.stockCategory)
          : props.type === 'optionAlias'
          ? allDelegations.value.filter((x: Delegation) => x?.optionCategory)
          : props.type === 'compositionAlias'
          ? allDelegations.value.filter(
              (x: Delegation) => x.isForCompositionAlias
            )
          : allDelegations.value
      })
      const delegations = computed<Delegation[]>(() => {
        return props.type
          ? eventDelegations.value.filter((item: Delegation) => {
              return moment
                .utc(props.endDate)
                .endOf('day')
                .isBetween(
                  moment.utc(item.date).startOf('day'),
                  moment.utc(item.endDate).endOf('day')
                )
            })
          : store.getters['organizations/delegations'].filter(
              (item: Delegation) => {
                return moment
                  .utc(props.endDate)
                  .endOf('day')
                  .isBetween(
                    moment.utc(item.date).startOf('day'),
                    moment.utc(item.endDate).endOf('day')
                  )
              }
            )
      })

      const delegationOptions = computed(() =>
        [...delegations.value, ...newDelegs.value, ...selectedDelegs.value]
          .reduce((acc: any[], d: any) => {
            if (!acc.map((x: any) => x.id).includes(d.id)) acc.push(d)
            return acc
          }, [])
          .filter((d) =>
            moment
              .utc(props.endDate)
              .endOf('day')
              .isBetween(
                moment.utc(d.date).startOf('day'),
                moment.utc(d.endDate).endOf('day')
              )
          )
      )

      const updateValue = (value: any) => {
        formValue.value = value

        const selected = delegationOptions.value.find(
          (x: Delegation) => x.id == value
        )
        selectedDeleg.value = selected
        if (selected) selectedDelegs.value.push(selected)
        emit('onUpdated', {
          ...data.value,
          value: formValue.value
        })
      }

      const searchDelegations = (search = '') => {
        if (!search) {
          formValue.value = selectedDeleg.value?.id
        }
        const filter: any = { search: search }
        if (props.endDate) filter.usableAtDate = props.endDate
        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id,
          filter
        })
      }

      const getDelegation = (delegationId?: string) => {
        if (!delegationId) return
        store.dispatch('organizations/GET_DELEGATION', {
          companyId: route.params.id,
          delegationId,
          onSuccess: (deleg: Delegation) => {
            if (
              ![...delegations.value, ...newDelegs.value]
                .map((x) => x.id)
                .includes(deleg.id)
            )
              newDelegs.value.push({ ...deleg, name: deleg.label })
          },
          onError: () => {
            // console.log('');
          }
        })
      }

      watch(
        () => delegationOptions.value,
        (opts) => {
          if (
            !opts.map((x: any) => x.id).includes(props.value) ||
            !opts.map((x: any) => x.id).includes(formValue.value)
          ) {
            formValue.value = selectedDeleg.value?.id
          }
        }
      )

      watch(
        () => props.value,
        (val) => {
          formValue.value = val
        }
      )
      watch(
        () => props.endDate,
        (val) => {
          getDelegations()
        }
      )

      watch(
        () => delegationOptions.value,
        (val) => {
          if (
            formValue.value &&
            !val.map((x: Delegation) => x.id).includes(formValue.value)
          ) {
            updateValue('')
          }
        }
      )

      const getDelegations = () => {
        const filter: any = {}
        if (props.endDate) filter.usableAtDate = props.endDate

        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id,
          filter
        })
      }

      onMounted(() => {
        console.log('delegationId', props.value)

        getDelegations()
        if (
          props.value &&
          !delegations.value.map((x) => x.id).includes(props.value)
        )
          getDelegation(props.value)

        updateValue(props.value)
      })

      return {
        formValue,
        searchText,
        delegationOptions,
        newDelegs,
        updateValue,
        searchDelegations
      }
    }
  })
