
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import { DocAutoTemplate } from '@/store/modules/Organization/docAutoModel'
  import { useStore } from 'vuex'
  import { useAcl } from 'vue-simple-acl/src'
  import { groupItemBy, sortObjectByKeys } from '@/utils'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    components: { Button },
    setup() {
      const search = ref('')
      const store = useStore()
      const acl = useAcl()
      const route = useRoute()
      const currentTemplate = computed<DocAutoTemplate | null>(
        () => store.getters['organizations/template']
      )
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts'))
      )

      const templates = computed<DocAutoTemplate[]>(
        () => store.getters['organizations/templates']
      )

      const groupedTemplates = computed<Map<string, any>>(() =>
        sortObjectByKeys(
          groupItemBy(
            templates.value,
            (item: DocAutoTemplate) => item.eventType
          )
        )
      )

      const initStockForm = () => {
        store.dispatch('organizations/INIT_MODAL_FORM', {
          modalInit: true,
          initCurrentForm: true
        })
        store.commit('organizations/SET_CURRENT_TEMPLATE', null)
      }

      const handleSelect = (id: string) => {
        store.dispatch('organizations/GET_TEMPLATE', {
          companyId: route.params.id,
          templateId: id,
          onSuccess: () => {
            console.log()
          },
          onError: () => {
            console.log()
          }
        })
      }

      const getTemplates = () => {
        store.dispatch('organizations/GET_TEMPLATES', {
          companyId: route.params.id,
          onSuccess: () => {
            console.log()
          },
          onError: () => {
            console.log()
          }
        })
      }

      onMounted(() => {
        getTemplates()
      })

      return {
        search,
        groupedTemplates,
        canDo,
        currentTemplate,
        initStockForm,
        handleSelect
      }
    }
  })
