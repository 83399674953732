
  import { Button, Input } from '@up.law/uplaw-ui'
  import { computed, defineComponent, ref, onMounted, watch } from 'vue'
  import { log } from 'loglevel'
  import { Form, Field } from 'vee-validate'
  import { useStore } from 'vuex'
  import * as yup from 'yup'
  import ProfileAvatarForm from '@/components/Forms/Configurable/Contact/ProfileAvatarForm.vue'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import LangSelector from '@/components/LangSelector/index.vue'
  import { User } from '@/services/api/models'

  export default defineComponent({
    name: 'AccountInformations',

    components: {
      Button,
      Input,
      Form,
      Field,
      ProfileAvatarForm,
      LangSelector
    },
    setup() {
      const { t } = useI18n()
      const pictureBase64 = ref()
      const loadedFile = ref<File | null>()
      const imgPreview = ref()
      const loading = ref(false)
      const store = useStore()

      const schema = yup.object({
        firstName: yup.string().min(3).required(),
        lastName: yup.string().min(3).required(),
        email: yup.string().min(8).required().email(),
        lang: yup.string().required().oneOf(['fr', 'en'])
      })

      const currentLocale = computed(() => store.getters['config/lang'])
      const user = computed<User>(() => store.getters['auth/profile'])

      const languageSelected = ref(user.value.lang || currentLocale.value)

      const userProfilImage = computed(
        () => store.getters['organizations/usersProfilesImages'][user.value?.id]
      )

      const currentCompany = computed(
        () => store.getters['organizations/contextCompany']
      )
      const account = ref({
        email: '',
        lastName: '',
        firstName: '',
        lang: currentLocale.value
      })

      const loadImage = async () => {
        await store.dispatch(`organizations/LOAD_USER_PROFILE_IMAGE`, {
          userId: user.value.id,
          companyId: currentCompany.value?.id
        })
      }
      const switchLanguage = (lang: string) => {
        languageSelected.value = lang
      }

      const fileChanged = (file: File) => {
        loadedFile.value = file
      }

      const updateProfileImage = () => {
        if (!loadedFile.value) return
        const form = new FormData()
        form.append('fileName', loadedFile.value?.name)
        form.append('file', loadedFile.value)
        store.dispatch('organizations/UPDATE_USER_PROFILE_IMAGE', {
          companyId: currentCompany.value?.id,
          userId: user.value.id,
          form,
          onProgress: () => {
            console.log()
          },
          onSuccess: () => {
            loadedFile.value = null
            store.commit('organizations/SET_USER_PROFILE_IMAGE', {
              userId: user.value.id,
              url: null
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message: t(`contactBook.form.notSaved`)
            })
          }
        })
      }

      const previewImage = (event: Event) => {
        const target = event.target as HTMLInputElement
        const file: File = (target.files as FileList)[0]

        loadedFile.value = file

        const reader = new FileReader()
        reader.onloadend = () => {
          pictureBase64.value = reader.result
          imgPreview.value = reader.result
        }

        reader.readAsDataURL(file)
      }

      const updateInformations = () => {
        // console.log(values, errors)
      }

      const removeImage = () => {
        pictureBase64.value = ''
        imgPreview.value = null
      }

      const updateProfile = (values: any) => {
        updateProfileImage()
        store.dispatch('auth/UPDATE_USER_PROFILE', {
          data: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            lang: languageSelected.value
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.profileContext'),
              message: t(
                `account.accountInformations.apiResponse.successProfilEdition`
              )
            })
          },
          onError: () => {
            log('')
          }
        })
      }

      onMounted(() => {
        store.dispatch(`auth/GET_USER_PROFILE`, {})
      })

      watch(user, (val) => {
        if (val) {
          account.value = {
            lastName: val.lastName,
            firstName: val.firstName,
            email: val.email,
            lang: currentLocale.value
          }
        }
      })

      // watch(currentLocale, (locale) => {
      //   languageSelected.value = locale
      // })

      return {
        user,
        account,
        loading,
        schema,
        userProfilImage,
        languageSelected,
        previewImage,
        updateInformations,
        updateProfile,
        removeImage,
        fileChanged,
        switchLanguage
      }
    }
  })
