
  import { defineComponent, ref, onBeforeMount, inject } from 'vue'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import { User } from '../services/api/models/user'
  import { addToStorage, getFormStorage } from '@/services/Storage'
  import { storageKeys } from '@/utils'
  import { Company } from '@/services/api/models'
  import conf from '@/config'
  import { GlobalConfig } from '@/types'
  import { getInstance } from '@/plugins/auth'

  export default defineComponent({
    setup() {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const display = ref(false)
      const count = ref(10)
      const config = conf as GlobalConfig
      const Auth: any = inject('Auth')
      const email = ref<string | undefined>()

      const redirect = (user: User) => {
        let cmpId: string = route.params.id?.toString()

        if (route.fullPath.split('?next').length > 1) {
          cmpId = route.query.state?.toString() ?? ''
        }

        const company = cmpId || getFormStorage(storageKeys.currentCompany)

        if (company) buildCompany(company, user)
        else
          store.dispatch(`organizations/GET_LOADER_COMPANIES`, {
            filter: {},
            onSuccess: (data: Company[]) => {
              const [firstCmp] = data

              const companyId = company || firstCmp?.id

              buildCompany(companyId, user)
            },
            onError: () => {
              window.location.reload()
            }
          })
      }

      const buildCompany = (companyId: string, user: User) => {
        const key = getFormStorage(storageKeys.lastRoute)
        if (key?.toString().includes('portfolioView')) {
          return router.push({ name: key })
        }
        store.dispatch(`organizations/GET_SINGLE_COMPANY`, {
          companyId,
          onSuccess: (newComp: Company) => {
            store.commit(`organizations/SET_CONTEXT_COMPANY`, newComp)

            if (newComp?.id) {
              const permissions = [
                ...(user?.companiesAccounts || [])
                  .filter((x) => x.companyId === newComp.id)
                  ?.flatMap((cmp) => cmp.role.permissions.map((r) => r.slug)),
                ...(user?.roles || []).flatMap((r) =>
                  r.permissions.map((p) => p.slug)
                )
              ]
              if (permissions.includes('read:dashboard')) {
                return router.push({
                  path: `/company/${newComp.id}/dashboard`,
                  query: route.query
                })
              } else {
                return router.push(`/company/${newComp.id}/directory`)
              }
            } else return router.push('/account/informations')
          },
          onError: () => {
            let cmpId: string = route.params.id?.toString()
            const company = cmpId || getFormStorage(storageKeys.currentCompany)
            // store.dispatch(`organizations/LOAD_COMPANY`, { companyId: company })
          }
        })
      }
      onBeforeMount(async () => {
        const auth = await getInstance().getUser()
        email.value = auth?.email
        store.dispatch(`auth/GET_USER_PROFILE`, {
          onError: async (data: any) => {
            count.value = 10
            display.value = true
            setTimeout(() => {
              Auth.logout({ returnTo: config.auth0Options.redirectUri })
            }, 10000)
            setInterval(() => {
              if (count.value > 0) count.value--
            }, 1000)
          },
          onSuccess: (user: User) => {
            redirect(user)
          }
        })
      })

      return { display, count, email }
    }
  })
