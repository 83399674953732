import { Role } from '../models/user'
import axios from '../axios'
import { auth } from '../endpoints'

const getUserRoles = async (
  companyId: string,
  userId: string
): Promise<Role[]> => {
  const { data } = await axios.get(auth.roles(userId), {
    params: { companyId }
  })
  return data
}

const changeUserprofileImage = async (
  companyId: string,
  userId: string,
  form: FormData,
  onUploadProgress: (progress: ProgressEvent) => void
) => {
  const resp = await axios.post(auth.picture(companyId, userId), form, {
    onUploadProgress
  })
  return resp
}

const getUserProfileImage = async (companyId: string, userId: string) => {
  return await axios.get(auth.picture(companyId, userId))
}

const getUserDataByToken = async (token: string) => {
  const data = await axios.get(auth.getUserByToken(), { params: { token } })

  return data
}

const resetUserPassword = async (email: string) => {
  return await axios.put(auth.resetPassword(), { email })
}

const definePassword = async (userId: string, data: any) => {
  return await axios.put(auth.definePassord(userId), data)
}
const setNewPassword = async (userId: string, data: any) => {
  return await axios.put(auth.createNewPassord(userId), data)
}

export {
  getUserRoles,
  getUserProfileImage,
  changeUserprofileImage,
  getUserDataByToken,
  definePassword,
  resetUserPassword,
  setNewPassword
}
