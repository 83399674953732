import { CompanyModel, Role, UserModel } from '@/services/api/models'
import { User } from '@auth0/auth0-spa-js'
import { Contact } from '@/services/api/models/company'

export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const SET_AUTH_PROFILE = 'SET_AUTH_PROFILE'

export type UserState = {
  profile: UserModel | undefined
  auth0Profile: User | null
  isLoading: boolean
  companies: CompanyModel[]
  company: CompanyModel | null
  companyUsers: UserModel[]
  currentLocale: string
  contacts: Contact[]
  roles: Role[]
}
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const ASYNC_GET_USER_PROFILE = 'ASYNC_GET_USER_PROFILE'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD'
export const UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE'
export const SET_COMPANIES = 'SET_COMPANIES'
export const SET_COMPANY = 'SET_COMPANY'
export const GET_COMPANY = 'GET_COMPANY'
export const SET_CURRENT_LOCALE = 'SET_CURRENT_LOCALE'
export const GET_CURRENT_LOCALE = 'GET_CURRENT_LOCALE'
export const UPDATE_CURRENT_LOCALE = 'UPDATE_CURRENT_LOCALE'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_CONTACTS = 'SET_CONTACTS'
export const GET_CONTACTS = 'GET_CONTACTS'
export const GET_ROLES = 'GET_ROLES'
export const SET_ROLES = 'SET_ROLES'
export const LOAD_COMPANY = 'LOAD_COMPANY'
export const DEFINE_PASSWORD = 'DEFINE_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD'
export const VERIFY_USER_BY_TOKEN = 'VERIFY_USER_BY_TOKEN'
export const SAVE_INTEGRATED_SERVICE_DATA = 'SAVE_INTEGRATED_SERVICE_DATA'
export const DELETE_INTEGRATED_SERVICE = 'DELETE_INTEGRATED_SERVICE'
export const GET_INTEGRATED_SERVICES = 'GET_INTEGRATED_SERVICES'

export const GET_APPLICATIONS = 'GET_APPLICATIONS'
export const CREATE_APPLICATION = 'CREATE_APPLICATION'
export const EDIT_APPLICATION = 'EDIT_APPLICATION'
export const DELETE_APPLICATION = 'DELETE_APPLICATION'
export const GET_APPLICATION = 'GET_APPLICATION'
