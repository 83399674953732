<template>
  <div
    class="
      authentifications-wrapper
      container-fluid
      px-4
      py-5
      flex flex-col
      w-full
      justify-start
      items-start
    "
  >
    <h1 class="text-left text-secondary9">
      {{ $t('account.accountAuthentifications.baseline') }}
    </h1>
    <section
      class="py-4 mt-4 w-full flex flex-col items-start md:w-full lg:w-3/4"
    >
      <h3>{{ $t('account.accountAuthentifications.updatePassword') }}</h3>

      <!-- CURRENT PASSWORD -->
      <Form
        v-slot="{ errors, values, meta }"
        :validation-schema="schema"
        class="flex flex-col w-full mt-5"
      >
        <div class="flex w-full">
          <!-- NEW PASSWORD -->
          <div class="w-1/2 form-group pr-2 mr-3">
            <label class="text-left">{{
              $t('account.accountAuthentifications.form.newPassword.label')
            }}</label>
            <Field
              v-slot="{ field }"
              v-model="password.new"
              type="password"
              name="password"
            >
              <UplInput
                v-bind="field"
                type="password"
                :error="
                  errors && errors.password
                    ? $t(`errors.${errors.password}`)
                    : null
                "
                autocomplete="new-password"
                class="mw-100 w-100"
                :placeholder="
                  $t(
                    'account.accountAuthentifications.form.newPassword.placeholder'
                  )
                "
              />
            </Field>
          </div>

          <!-- CONFIRM PASSWORD -->
          <div class="w-1/2 form-group">
            <label class="text-left">{{
              $t(
                'account.accountAuthentifications.form.newPasswordConfirm.label'
              )
            }}</label>

            <Field
              v-slot="{ field }"
              v-model="password.confirm"
              type="password"
              name="confirmation"
              ><UplInput
                v-bind="field"
                autocomplete="new-password"
                :error="
                  errors && errors.passwordConfirmation
                    ? $t(`errors.${errors.passwordConfirmation}`)
                    : null
                "
                class="mw-100 w-100"
                name="confirmation"
                type="password"
                :placeholder="
                  $t(
                    'account.accountAuthentifications.form.newPasswordConfirm.placeholder'
                  )
                "
              />
            </Field>
          </div>
        </div>
        <div class="mt-7 text-xl w-full flex justify-end items-center">
          <Btn
            class=""
            :disabled="
              password.new !== password.confirm || !password.new || !meta.valid
            "
            name="fo_accountAuthentication_submit"
            :label="$t('account.accountAuthentifications.form.submit')"
            @click="updatePassword(values)"
          />
        </div>
      </Form>
    </section>

    <div class="flex justify-center items-center text-warning mt-10 w-full">
      <i class="bi bi-exclamation-triangle mr-3"></i>
      <h5 class="text-warning">
        {{ $t('account.accountInformations.passwordNotice') }}
      </h5>
    </div>
  </div>
</template>

<script lang="ts">
  import { Button, Input } from '@up.law/uplaw-ui'
  import { defineComponent, ref } from 'vue'
  import { useField, Form, Field } from 'vee-validate'
  import * as yup from 'yup'
  import { useStore } from '@/store'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { AxiosError } from 'axios'
  import { useRouter } from 'vue-router'
  export default defineComponent({
    name: 'AccountAuthentifications',
    components: { Btn: Button, UplInput: Input, Form, Field },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const router = useRouter()
      const schema = yup.object({
        password: yup.string().min(5),
        oldPassword: yup.string().min(5),
        passwordConfirmation: yup.string().min(4)
      })
      const password = ref({ new: '', confirm: '' })
      const {
        value: authCode,
        meta: authMeta,
        errorMessage: authCodeError
      } = useField('authCode', yup.string().length(4).required(), {
        validateOnValueUpdate: true
      })
      const updatePassword = (values: any) => {
        store.dispatch(`auth/UPDATE_USER_PASSWORD`, {
          data: values,
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.DEFAULT,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.successPasswordEdition`
              )
            })

            router.replace('/connect')
          },
          onError: (err: AxiosError) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(`auth0.${err.message}`)
            })
          }
        })
      }
      return {
        schema,
        authCode,
        authCodeError,
        authMeta,
        password,
        updatePassword
      }
    }
  })
</script>

<style scoped>
  .authentifications-wrapper {
    height: 100%;
    overflow-y: auto;
  }
</style>
