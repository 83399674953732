<template>
  <div class="account__users container-fluid p-5 w-full text-md mb-5">
    <div class="flex justify-between items-center">
      <h1 class="text-secondary9">
        {{ $t('account.accountUsers.context') }}
      </h1>
    </div>

    <div class="mt-5">
      <portfolio-users />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import PortfolioUsers from './tabs/portfolioUsers.vue'

  export default defineComponent({
    components: {
      PortfolioUsers
    },
    setup() {
      return {}
    }
  })
</script>
