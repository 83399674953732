
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import { useRoute } from 'vue-router'
  import { formatNumericalValue, rgbToHex } from '@/utils/global'
  import { CoOwnershipType, ContactGroup } from '@/services/api/models'
  import EmptyState from '@/components/EmptyState/index.vue'
  import StockDropdown from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { Field } from 'vee-validate'
  import moment from 'moment'
  import { Captable, CaptableLine, STOCKTYPE } from '@/types'
  import { formatNumber } from '@/utils'
  import { Tag, Button } from '@up.law/uplaw-ui'
  import { useI18n } from 'vue-i18n'
  import Loader from '@/components/Loader/index.vue'
  import { ElTooltip } from 'element-plus'
  import { useAcl } from 'vue-simple-acl/src'
  export default defineComponent({
    name: 'CompanyCaptable',
    components: {
      DataTable,
      Button,
      ElTooltip,
      StockDropdown,
      EmptyState,
      UplDatePicker,
      Field,
      Loader,
      Tag
    },
    setup() {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const search = ref('')
      const selectedContact = ref('')
      const selectedGroup = ref('')
      const selectedStock = ref('')
      const date = ref()
      const selectedStocks = ref([])
      const filter = ref<any>({})
      const contacts = computed(() => store.getters['organizations/contacts'])
      const contactId = ref(route.params.contactId)
      const optionAlias = computed(
        () => store.getters['organizations/optionStockAliases']
      )
      const isDownloading = ref(false)

      const isLoading = ref(false)
      const stocksAliases = computed(
        () => store.getters['organizations/stockAliases']
      )
      const optionStockAliases = computed(
        () => store.getters['organizations/optionStockAliases']
      )
      const currentContact = computed(
        () => store.getters['organizations/contactDetails']
      )

      const compositionStockAlias = computed(
        () => store.getters['organizations/compositionStockAlias']
      )
      const company = computed(
        () => store.getters['organizations/contextCompany']
      )
      const captable = ref<Captable>(store.getters['organizations/captable'])

      const totalPercentFD = computed(() =>
        captable.value?.lines.reduce((value, line: CaptableLine) => {
          value +=
            line.stocks.reduce((acc, stock) => (acc += stock.quantityFD), 0) /
            captable.value.allFD
          return value * 100
        }, 0)
      )

      const totalPercentNFD = computed(() =>
        captable.value?.lines.reduce((value, line: CaptableLine) => {
          value +=
            line.stocks.reduce((acc, stock) => (acc += stock.quantityNFD), 0) /
            captable.value.allNFD
          return value * 100
        }, 0)
      )

      const captableStocks = computed(() =>
        captable.value?.lines.reduce((stocks: any[], line: CaptableLine) => {
          stocks.push(...line.stocks)
          return stocks
        }, [])
      )

      const groups = computed<ContactGroup[]>(
        () => store.getters['toolbox/contactGroups']
      )

      const acl = useAcl()

      const pagination = computed(
        () => store.getters['organizations/captablePagination']
      )
      const onPageChange = (page: number) => {
        store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', page)
        getCaptable()
      }

      const columns = computed(() =>
        [
          {
            label: t('datatable.column.stocks'),
            field: 'stocks',
            custom: true
          },
          {
            label: t('datatable.column.funding'),
            field: 'funding',
            custom: true
          },
          { label: t('datatable.column.nfd'), field: 'nfd', custom: true },

          !acl.can.any(['employee'])
            ? {
                label: t('datatable.column.nfd_p'),
                field: 'nfd_p',
                custom: true
              }
            : null,
          { label: t('datatable.column.fd'), field: 'fd', custom: true },
          !acl.can.any(['employee'])
            ? { label: t('datatable.column.fd_p'), field: 'fd_p', custom: true }
            : null,
          {
            label: t('datatable.column.nonDilutiveQuantity'),
            field: 'nonDilutiveQuantity',
            custom: true
          }
        ].filter((x) => x != null)
      )

      const hasColumn = (columnName: string) => {
        return columns.value.map((x: any) => x.field).includes(columnName)
      }
      const dateCleared = () => {
        const dateFilter = {
          date: null
        }
        filter.value = { ...filter.value, ...dateFilter }
      }

      const downloadPdf = () => {
        isDownloading.value = true
        const companyId = route.params.id

        store.dispatch('organizations/EXPORT_ASSOCIATE_RECORD', {
          companyId,
          filter: { contactsId: [route.params.contactId] },
          name: `${t(`global.associateRecord`)}-${
            currentContact.value?.name
          }-${moment().format('L')}`,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      const onSelected = (value: any) => {
        console.log(value)
      }

      const updateStockFilter = ({ value }: any) => {
        filter.value.optionAliasesId = []
        filter.value.stockAliasesId = []
        filter.value.compositionAliasesId = []
        if (!value) {
          return
        }
        value.forEach((v: string) => {
          const [al] = v.split('@_')
          const stock = [
            ...optionStockAliases.value,
            ...compositionStockAlias.value,
            ...stocksAliases.value
          ].find((x) => x.id === al)

          if (stock?.type === STOCKTYPE.SAMPLE) {
            filter.value?.stockAliasesId?.push(v)
          } else if (stock?.type === STOCKTYPE.OPTION) {
            filter.value.optionAliasesId?.push(al)
          } else if (stock?.type === STOCKTYPE.COMPOSITION) {
            filter.value.compositionAliasesId?.push(v)
          }
        })
        selectedStocks.value = value
        store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)

        getCaptable()
      }

      const updateStock = (id: string) => {
        if (id === '') delete filter.value.optionAliasesId
        else filter.value.optionAliasesId = id
        selectedStock.value = id
      }
      const updateGroup = (group: any) => {
        if (group.value === '') delete filter.value.groupId
        else filter.value.groupId = group.value
        selectedGroup.value = group.value
      }
      const updateContact = (contactId: string) => {
        if (contactId === '') delete filter.value.contactId
        else filter.value.contactId = contactId
        selectedContact.value = contactId
      }

      const getCaptable = async (contactId = route.params.contactId) => {
        // isLoading.value = true
        await store.dispatch('organizations/GET_CAPTABLE', {
          companyId: route.params.id,
          filter: { ...filter.value, contactId: [contactId] },
          onSuccess: (data: Captable) => {
            isLoading.value = false
            captable.value = data
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      onBeforeMount(async () => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0
          }
        })
      })

      onBeforeMount(async () => {
        store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)
        await store.dispatch('organizations/GET_OPTION_STOCK_ALIAS', {
          companyId: route.params.id
        })
        getCaptable()
      })

      watch(
        () => route.params.contactId,
        (newContactId) => {
          store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)

          if (!newContactId) {
            store.commit('organizations/SET_CURRENT_CONTACT', null)
          } else {
            contactId.value = newContactId
          }
        }
      )

      watch(date, (newDateRange) => {
        store.commit('organizations/SET_TRANSACTIONS_PAGE', 1)
        store.commit('organizations/SET_CAPTABLE_PAGINATION_PAGE', 1)

        const dateFilter = {
          date: newDateRange
            ? moment(newDateRange).startOf('day').toISOString()
            : null
        }

        filter.value = { ...filter.value, ...dateFilter }

        getCaptable()
      })

      onMounted(() => {
        isLoading.value = true
      })

      return {
        search,
        date,
        captable,
        company,
        groups,
        isDownloading,
        selectedContact,
        contacts,
        selectedStocks,
        columns,
        pagination,
        isLoading,
        CoOwnershipType,
        captableStocks,
        totalPercentFD,
        totalPercentNFD,
        onSelected,
        updateStock,
        updateContact,
        updateGroup,
        formatNumericalValue,
        optionAlias,
        hasColumn,
        dateCleared,
        downloadPdf,
        updateStockFilter,
        onPageChange,
        formatNumber,
        rgbToHex
      }
    }
  })
