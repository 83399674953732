<template>
  <div>
    <div :class="`${canEdit ? 'mt-14' : ''}`">
      <EditableHeader
        :fields="headerFields"
        :can-edit="canEditHeader"
        :is-editing="canEdit && !canEditHeader"
        @save="saveEventPrimaryInfo"
        @start-edition="canEditHeader = true"
        @cancel-edition="canEditHeader = false"
      />
    </div>

    <div class="my-4 mt-5 mb-1 flex justify-between items-center">
      <div v-if="isDetailPage" class="w-1/2">
        <div class="w-1/3">
          <dropdown-contacts
            v-model="selectedContacts"
            class="w-full"
            :placeholder="$tc('contactCard.filterPlaceholder')"
            mode="multiple"
            :filter-results="false"
            :can-add-new="false"
            open-direction="bottom"
            @update:modelValue="updateSelected"
          />
        </div>
      </div>
      <div
        v-if="canEdit"
        class="
          bg-primary1
          rounded
          p-2
          text-primary3
          cursor-pointer
          border border-primary2
        "
        @click="addNewLine()"
      >
        <span class="text-sm">{{ $t('events.actionList.newLine') }}</span>
      </div>
    </div>
    <editable
      v-if="!isLoadingParts"
      :grids="canEdit ? 12 : 10"
      :lines="lines"
      :columns="columns"
      :total-line="totalLine"
      :can-delete-line="true"
      :can-edit-line="true"
      :display-total="!isOverLimit"
      :filtered="{ partsId: filteredPartsId }"
      @save-line="saveLine"
      @remove-part="removePart"
      @save-new-part="saveNewLine"
      @remove-new-line="removeNewLine"
      @reset-line="resetLine"
    />

    <div class="w-full flex justify-center items-center text-xs" v-else>
      <Loader></Loader>
    </div>

    <div v-if="isDetailPage" class="flex justify-end mt-5">
      <el-pagination
        background
        :hide-on-single-page="true"
        layout="prev, pager, next"
        class="pr-0"
        :page-size="pagination.limit"
        :total="pagination.total"
        :current-page="pagination.currentPage"
        @current-change="handlePageSelection"
      >
      </el-pagination>
    </div>
    <div v-if="isOverLimit" class="flex justify-end items-end">
      <h5 class="py-3 px-2 text-md text-primary4">
        <router-link
          :to="{
            name: isPortfolio ? 'company.eventDetails' : 'company.eventDetails',
            params: { eventId: event?.id }
          }"
          >...{{ $t('global.seeMoreParts') }}</router-link
        >
      </h5>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, computed, ref, onMounted, watch } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  import { isNumber } from 'lodash'
  import {
    CompanyModel,
    CompositionIssuance,
    Event,
    EventPartsTotal,
    NominalValue
  } from '@/services/api/models'
  import { useRoute, useRouter } from 'vue-router'
  import { Pagination } from '@/store/modules/Organization/models'
  import Editable from './editable/index.vue'
  import EditableHeader from './editable/header.vue'
  import { useI18n } from 'vue-i18n'
  import { CompositionIssuancePart, NumberType } from '@/types'
  import { TYPE } from 'vue-toastification'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import { ElPagination } from 'element-plus'
  import Loader from '@/components/Loader/small.vue'

  export default defineComponent({
    name: 'CompositionIssuanceCard',
    components: {
      EditableHeader,
      DropdownContacts,
      Editable,
      Loader,
      ElPagination
    },
    props: {
      event: {
        type: Object as () => Event | null,
        required: true
      },
      nominalValue: {
        type: Number,
        default: 0
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      isPortfolio: {
        type: Boolean,
        default: false
      },
      canEdit: { type: Boolean, default: false }
    },
    setup(props) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const { t } = useI18n()
      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const total = ref<EventPartsTotal | null>()
      const selectedContacts = ref<string[]>([])
      const isLoadingParts = ref(true)
      const formData = ref<any>({
        compositionAliasId:
          props.event?.compositionIssuance?.compositionAlias.id,
        delegationId: props.event?.compositionIssuance?.delegation?.id,
        issuanceDate: props.event?.compositionIssuance?.issuanceDate,
        financingRoundId: props.event?.compositionIssuance?.financingRound?.id
      })
      const newLines = ref<any[]>([])

      const issuanceParts = ref<any[]>(
        props.event?.compositionIssuance?.compositionIssuanceParts ?? []
      )
      const allParts = ref<CompositionIssuancePart[]>([])
      const limit = computed<number>(
        () => store.getters['config/eventPartsLimit']
      )

      const newSavedHeader = ref<CompositionIssuance | null>(null)
      const pagination = ref<Pagination>({
        total: 0,
        currentPage: 1,
        lastPage: 1,
        limit: limit.value
      })

      const filteredPartsId = computed<string[]>(() =>
        ((props.filters as any)?.contactsId || []).flatMap((x: string) =>
          issuanceParts.value.filter((p) => p.contact.id == x).map((y) => y.id)
        )
      )
      const company = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const canEditHeader = ref(false)
      const isLocal = ref(false)
      const nominalValues = computed<NominalValue[]>(
        () => store.getters['organizations/nominalValues']
      )
      const nominalValue = computed(() => {
        const sorted = [...nominalValues.value].sort(
          (a, b) => moment.utc(b.date).unix() - moment.utc(a.date).unix()
        )
        const [nv] = sorted.filter(
          (x) =>
            moment.utc(props.event?.date).unix() >= moment.utc(x.date).unix()
        )

        return nv?.nominalValue
      })

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const editedLinesData = ref<any[]>([])

      const totalLine = computed(() => {
        const v = [
          {
            name: 'Total',
            class: 'col-span-2 text-sm',
            key: 'total',
            width: '22.22%'
          },
          {
            name: '',
            key: 'quantity',
            class: 'text-sm',
            value: formatNumber(total.value?.quantity ?? 0),
            width: '11.11%'
          },
          {
            name: '',
            key: 'totalPrice',
            class: 'text-sm',
            value: `${formatNumber(total.value?.totalPrice ?? 0)} ${
              currency.value
            }`,
            width: '11.11%'
          },
          {
            name: '',
            key: 'unitPrice',
            value: '-',
            class: 'text-sm',
            width: '11.11%'
          },
          {
            name: '',
            key: 'releasedAmount',
            value: `${formatNumber(total.value?.releasedAmount ?? 0)} ${
              currency.value
            }`,
            width: '11.11%',
            class: 'text-sm'
          },

          {
            name: '',
            key: 'issuancePremiumPerAction',
            value: `${formatNumber(
              total.value?.issuancePremiumPerAction ?? 0
            )} ${currency.value}`,
            width: '11.11%',
            class: 'text-sm'
          },
          {
            name: '',
            key: 'totalIssuancePremium',
            value: `${formatNumber(total.value?.totalIssuancePremium ?? 0)} ${
              currency.value
            }`,
            width: '11.11%',
            class: 'text-sm'
          },
          {
            name: '',
            key: 'nominalIncrease',
            value: `${formatNumber(total.value?.nominalIncrease ?? 0)} ${
              currency.value
            }`,
            width: '11.11%',
            class: 'text-sm'
          },
          {
            name: '',
            key: 'entryDate',
            value: '-',
            width: '11.11%',
            class: 'text-sm'
          }
        ]

        if (props.canEdit)
          v.push({
            name: '',
            key: 'actions',
            value: '-',
            class: 'col-span-2',
            width: '22.22%'
          })

        return v
      })

      const data = computed(() => {
        const v = [
          {
            name: t('events.elements.contact'),
            id: 'contact',
            width: '22.22%',
            class: 'text-left col-span-2',
            component: 'ContactBadge',
            displayComponent: 'contact'
          },
          {
            name: t('events.elements.quantity'),
            width: '11.11%',
            id: 'quantity',
            class: 'text-left justify-end',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.totalPrice'),
            width: '11.11%',
            id: 'totalPrice',
            class: 'justify-end',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.unitPrice'),
            width: '11.11%',
            class: 'justify-end',
            id: 'unitPrice',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.releasedAmount'),
            width: '11.11%',
            id: 'releasedAmount',
            class: 'justify-end',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.issuancePremiumPerAction'),
            width: '11.11%',
            class: 'justify-end',
            id: 'issuancePremiumPerAction',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.totalIssuancePremium'),
            width: '11.11%',
            class: 'justify-end',
            id: 'totalIssuancePremium',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.nominalIncrease'),
            width: '11.11%',
            class: 'justify-end',
            id: 'nominalIncrease',
            component: 'contact-generic',
            displayComponent: 'contact'
          },
          {
            name: t('events.elements.entryDate'),
            width: '11.11%',
            class: 'justify-end',
            id: 'entryDate',
            component: 'contact-generic'
          }
        ]

        if (props.canEdit)
          v.push({
            name: '',
            width: '22.22%',
            class: 'justify-end col-span-2',
            id: 'actions',
            component: 'actions',
            displayComponent: 'contact'
          })

        return v
      })

      const updateSelected = (contactsId: string[]) => {
        selectedContacts.value = contactsId
        getParts(props.event?.compositionIssuance?.id, 1, contactsId)
        getTotal()
      }
      const addNewLine = (fromPartId?: string) => {
        let newL = issuanceParts.value.find((x) => x.id == fromPartId)
        let index = newLines.value.length + 1
        while (newLines.value.find((x) => x.newIndex === index)) {
          index++
        }
        newLines.value.push(
          newL ?? {
            index,
            id: null,
            contact: null,
            unitPrice: 0,
            totalPrice: 0,
            entryDate: new Date(),
            quantity: 0,
            releasedAmount: 0,
            nominalIncrease: 0,
            totalIssuancePremium: 0,
            issuancePremiumPerAction: 0
          }
        )
      }

      const removeNewLine = (index: number) => {
        newLines.value = newLines.value.filter((x) => x.index != index)
      }

      const saveNewLine = (
        index: number,
        arrayIndex: number,
        onSuccess: any,
        onError: any
      ) => {
        const d = editedLinesData.value.find((e) => e.newIndex == index)
        if (!d) {
          onError()
          store.dispatch('toolbox/displayToast', {
            type: TYPE.ERROR,
            context: t('events.context'),
            message: t(`events.apiResponse.lineEditError`)
          })

          return
        }

        store.dispatch('organizations/ADD_COMPOSITION_ISSUANCE_PART', {
          companyId: currentCompany.value.id,
          compositionIssuanceId: props.event?.compositionIssuance?.id,
          data: { ...d },
          onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response)
            getTotal()
            issuanceParts.value.unshift({
              id: response.id,
              entryDate: response.entryDate,
              contact: response.contact,
              unitPrice: response.unitPrice,
              totalPrice: response.totalPrice,
              quantity: response.quantity,
              releasedAmount: response.releasedAmount,
              nominalIncrease: response.nominalIncrease,
              totalIssuancePremium: response.totalIssuancePremium,
              issuancePremiumPerAction: response.issuancePremiumPerAction
            })
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditSuccess`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditError`)
            })
          }
        })
      }

      const saveEventPrimaryInfo = (onSuccess: any, onError: any) => {
        const data = { ...formData.value }
        for (const property in data) {
          if (!data[property]) delete data[property]
        }

        store.dispatch(
          'organizations/UPDATE_COMPOSITION_ISSUANCE_PRIMARY_INFO',
          {
            companyId: currentCompany.value.id,
            compositionIssuanceId: props.event?.compositionIssuance?.id,
            data: data,
            onSuccess: (response: any) => {
              if (onSuccess) onSuccess(response)
              newSavedHeader.value = { ...response }
              canEditHeader.value = false
              isLocal.value = true
              store.dispatch('toolbox/displayToast', {
                type: TYPE.SUCCESS,
                context: t('events.context'),
                message: t(`events.apiResponse.primaryInfoEdited`)
              })
            },
            onError: (err: any) => {
              if (onError) onError(err)
              store.dispatch('toolbox/displayToast', {
                type: TYPE.ERROR,
                context: t('events.context'),
                message: t(`events.apiResponse.primaryInfoError`)
              })
            }
          }
        )
      }

      const saveLine = (partId: string, onSuccess: any, onError: any) => {
        const line = issuanceParts.value.find((e) => e.id == partId)
        const d = editedLinesData.value.find((e) => e.partId == partId)

        store.dispatch('organizations/UPDATE_COMPOSITION_ISSUANCE_PART', {
          companyId: currentCompany.value.id,
          compositionIssuanceId: props.event?.compositionIssuance?.id,
          partId,
          data: {
            contactId: line?.contact?.id ?? d?.contact?.id,
            ...line,
            ...d
          },
          onSuccess: (response: any) => {
            const idx = issuanceParts.value.findIndex((x) => x.id == partId)
            issuanceParts.value[idx] = { ...response }
            if (onSuccess) onSuccess(response)
            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditSuccess`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditError`)
            })
          }
        })
      }

      const handleFieldEdition = (key: any, value: any, part: any) => {
        let lineIndex = -1
        if (!part?.id) {
          lineIndex = editedLinesData.value.findIndex(
            (e) => e.newIndex == part.index
          )
        } else {
          lineIndex = editedLinesData.value.findIndex(
            (e) => e.partId == part.id
          )
        }

        const elm = {
          ...part,
          [key]: value,
          partId: part.id,
          newIndex: part.index
        }
        if (lineIndex != -1) {
          editedLinesData.value[lineIndex] = { ...elm }
        } else
          editedLinesData.value.push({
            ...elm
          })
      }

      const columns = computed(() =>
        data.value.map((x) => ({
          name: x.name,
          width: x.width,
          class: x.class,
          id: x.id
        }))
      )

      const headerFields = computed(() => {
        const v = [
          {
            fieldId: 'stock',
            class: 'justify-start',
            colName: t('events.fieldsLabels.stock'),
            component: 'tag',
            editComponent: 'dropdown-composition',
            componentProps: {
              class: '',
              parentClass: 'text-blackp text-sm text-left',
              withInitials: false,
              value: isLocal.value
                ? newSavedHeader.value?.compositionAlias?.id
                : props.event?.compositionIssuance?.compositionAlias.id,
              text: isLocal.value
                ? newSavedHeader.value?.compositionAlias?.alias.toUpperCase()
                : props.event?.compositionIssuance?.compositionAlias.alias.toUpperCase()
            },
            editComponentProps: {
              classes: ' w-full',
              openDirection: 'bottom',
              value: isLocal.value
                ? newSavedHeader.value?.compositionAlias.id
                : props.event?.compositionIssuance?.compositionAlias.id,
              data: { name: 'compositionAliasId', placeholder: '' },
              onUpdated: (data: any) => {
                formData.value['compositionAliasId'] = data.value
              }
            }
          },
          {
            fieldId: 'delegation',
            class: 'justify-start',
            colName: t('events.fieldsLabels.delegation'),
            component: 'p',
            editComponent: 'dropdown-delegations',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: isLocal.value
                ? newSavedHeader.value?.delegation?.label
                : props.event?.compositionIssuance?.delegation?.label ?? '-'
            },
            editComponentProps: {
              classes: ' w-full',
              type: 'compositionAlias',
              endDate:
                formData.value['issuanceDate'] ??
                props.event?.compositionIssuance?.issuanceDate,
              openDirection: 'bottom',
              value: isLocal.value
                ? newSavedHeader.value?.delegation?.id
                : props.event?.compositionIssuance?.delegation?.id,
              data: { name: 'delegationId', placeholder: '' },
              onUpdated: (data: any) => {
                formData.value['delegationId'] = data.value
              }
            }
          },

          {
            fieldId: 'financingRound',
            class: 'justify-start',
            colName: t('events.fieldsLabels.financingRound'),
            component: 'p',
            editComponent: 'dropdown-fund-raising-rounds',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: isLocal.value
                ? newSavedHeader.value?.financingRound?.name
                : props.event?.compositionIssuance?.financingRound?.name ?? '-'
            },
            editComponentProps: {
              classes: ' w-full',
              openDirection: 'bottom',
              value: isLocal.value
                ? newSavedHeader.value?.financingRound?.id
                : props.event?.compositionIssuance?.financingRound?.id,
              data: { name: 'financingRoundId', placeholder: '' },
              onUpdated: (data: any) => {
                formData.value['financingRoundId'] = data.value
              }
            }
          }
        ]

        if (props.canEdit && canEditHeader.value) {
          v.push({
            fieldId: 'issuanceDate',
            class: 'justify-start',
            colName:
              props.canEdit && canEditHeader.value
                ? t('events.fieldsLabels.issuanceDate')
                : '-',
            component: 'p',
            editComponent: 'date-generic',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: '-'
            },
            editComponentProps: {
              classes: ' w-full mt-1.5',
              openDirection: 'bottom',
              value:
                newSavedHeader.value?.issuanceDate ??
                props.event?.compositionIssuance?.issuanceDate,
              data: { name: 'issuanceDate', placeholder: '' },
              onUpdated: (data: any) => {
                formData.value['issuanceDate'] = data.value
              }
            }
          })
        }

        return v
      })

      const lines = computed(() =>
        [...newLines.value, ...issuanceParts.value].map((x) => ({
          partId: x?.id,
          newIndex: x?.index,
          selected: contactsId.value.includes(x.contact?.id),
          contact: {
            class: 'justify-start',
            colName: 'contact',
            component: 'ClickableContactBadge',
            editComponent: 'contact-generic',
            componentProps: {
              company: props.event?.company,
              class: 'p-0 text-xs overflow-hidden',
              details: false,
              type: 'light',
              contact: x.contact
            },
            editComponentProps: {
              classes: ' w-full',
              details: false,
              type: 'light',
              value: x?.contact?.id,
              contact: x.contact,
              data: { name: 'contactId', placeholder: '' },
              onUpdated: (data: any) => {
                x.contactId = data.value
                handleFieldEdition(data.name, data.value, x)
              },
              lineData: {
                contactIdSelection: x.contact
              }
            },
            displayComponent: '',
            partId: x?.id
          },
          quantity: {
            class: 'justify-end',
            colName: 'quantity',
            component: 'number',
            editComponent: 'input-generic',
            componentProps: {
              class: 'text-sm text-right',
              value: x.quantity ?? 0,
              mode: NumberType.quantity
            },
            editComponentProps: {
              data: { name: 'quantity', placeholder: '' },
              classes: 'w-full',
              value: x.quantity,
              onUpdated: (data: any) => {
                x['quantity'] = !data.value ? '' : parseInt(data.value)
                x['issuancePremiumPerAction'] = x.unitPrice - nominalValue.value
                x['totalPrice'] =
                  data.value && x.unitPrice
                    ? parseInt(x.unitPrice) * parseInt(x.quantity)
                    : 0
                x['releasedAmount'] = x['totalPrice']
                x['totalIssuancePremium'] =
                  x.issuancePremiumPerAction * x.quantity
                x['nominalIncrease'] = parseInt(x.quantity) * nominalValue.value
                handleFieldEdition(data.name, parseInt(data.value), x)
              }
            },

            displayComponent: '',
            partId: x?.id
          },
          totalPrice: {
            class: 'justify-end',
            colName: 'totalPrice',
            component: 'number',
            componentProps: {
              class: 'text-sm',
              value: x.totalPrice,
              mode: NumberType.price
            },
            editComponent: 'input-generic',
            editComponentProps: {
              data: { name: 'totalPrice', placeholder: '' },
              classes: 'w-full',
              value: x.totalPrice,
              onUpdated: (data: any) => {
                x['unitPrice'] =
                  x.quantity && x.totalPrice && data.value
                    ? parseInt(data.value) / parseInt(x.quantity)
                    : 0
                x['issuancePremiumPerAction'] = x.unitPrice - nominalValue.value
                x['totalPrice'] = !data.value ? '' : parseInt(data.value)
                x['releasedAmount'] = x['totalPrice']
                x['totalIssuancePremium'] =
                  x.issuancePremiumPerAction * x.quantity
                handleFieldEdition(data.name, parseInt(data.value), x)
              }
            },
            displayComponent: '',
            partId: x.id
          },
          unitPrice: {
            class: 'justify-end',
            colName: 'unitPrice',
            component: 'number',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.unitPrice ?? calculateUnitPrice(x),
              mode: NumberType.price
            },
            editComponentProps: {
              data: { name: 'unitPrice', placeholder: '' },
              classes: 'w-full',
              value:
                x.unitPrice ??
                parseFloat(calculateUnitPrice(x)?.toString()).toFixed(2),
              onUpdated: (data: any) => {
                x['unitPrice'] = !data.value ? '' : parseFloat(data.value)
                x['totalPrice'] = !data.value
                  ? ''
                  : parseFloat(data.value) * x.quantity
                x['releasedAmount'] = x['totalPrice']
                x['issuancePremiumPerAction'] =
                  parseFloat(data.value) - nominalValue.value
                x['totalIssuancePremium'] =
                  x.issuancePremiumPerAction * x.quantity
                handleFieldEdition(data.name, parseFloat(data.value), x)
              }
            },
            editComponent: 'input-generic',
            displayComponent: '',
            partId: x.id
          },
          releasedAmount: {
            class: 'justify-end',
            colName: 'releasedAmount',
            component: 'number',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.releasedAmount,
              mode: NumberType.price
            },
            editComponent: 'input-generic',
            editComponentProps: {
              data: { name: 'releasedAmount', placeholder: '' },
              classes: 'w-full',
              value: x.releasedAmount,
              onUpdated: (data: any) => {
                handleFieldEdition(data.name, parseInt(data.value), x),
                  (x['releasedAmount'] = !data.value
                    ? ''
                    : parseInt(data.value))
              }
            },
            displayComponent: '',
            partId: x.id
          },
          issuancePremiumPerAction: {
            class: 'justify-end',
            colName: 'stock',
            component: 'number',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value:
                (x.unitPrice ?? calculateUnitPrice(x)) - nominalValue.value,
              mode: NumberType.price
            },
            displayComponent: '',
            partId: x.id
          },
          totalIssuancePremium: {
            class: 'justify-end',
            colName: 'stock',
            component: 'number',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value:
                ((x.unitPrice ?? calculateUnitPrice(x)) - nominalValue.value) *
                x.quantity,
              mode: NumberType.price
            },
            displayComponent: '',
            partId: x.id
          },
          nominalIncrease: {
            class: 'justify-end',
            colName: 'stock',
            component: 'number',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.quantity * nominalValue.value,
              mode: NumberType.price
            },
            displayComponent: '',
            partId: x.id
          },
          entryDate: {
            class: 'justify-end',
            colName: 'stock',
            component: 'p',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.entryDate ? moment.utc(x.entryDate).format('l') : '-'
            },
            editComponent: 'date-generic',
            editComponentProps: {
              data: { name: 'entryDate', placeholder: '' },
              classes: 'w-full',
              value: x.entryDate,
              onUpdated: (data: any) => {
                x.entryDate = data.value
                handleFieldEdition(data.name, data.value, x)
              }
            },
            partId: x.id
          },
          actions: {
            class: 'justify-end',
            colName: 'stock',
            component: 'actions',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.vestingCalendar?.name || '-'
            },
            editComponent: 'dropdown-vesting',
            partId: x.id
          }
        }))
      )
      const isLoading = ref(false)

      const isDetailPage = computed(() => route.name === 'company.eventDetails')

      const currency = computed(() => store.getters['organizations/currency'])

      const parts = computed<any[]>(
        () => props.event?.compositionIssuance?.compositionIssuanceParts || []
      )

      const isOverLimit = computed(
        () => !isDetailPage.value && parts.value.length > 20
      )

      const calculateUnitPrice = (row: any) => {
        if (!row.totalPrice && !row.quantity) return 0

        return row.totalPrice / row.quantity
      }

      const emitPerAction = (row: any) => {
        const unitPrice = calculateUnitPrice(row)

        if (!isNumber(unitPrice)) {
          return '-'
        }

        return Math.abs(unitPrice - nominalValue.value)
      }

      const handlePageSelection = (page: number) => {
        getParts(props.event?.compositionIssuance?.id, page, [])
      }
      const resetLine = (index: number, partId: string) => {
        const oldLine = allParts.value.find((x) => x.id == partId)
        issuanceParts.value[index] = { ...oldLine }
      }
      const getTotal = () => {
        if (!props.event?.compositionIssuance?.id) return
        store.dispatch('organizations/GET_COMPOSITION_ISSUANCE_PARTS_TOTAL', {
          companyId: currentCompany.value.id,
          issuanceId: props.event?.compositionIssuance?.id,
          filter: { contactsId: selectedContacts.value },
          onSuccess: (response: EventPartsTotal) => {
            total.value = response
          },
          onError: () => {
            //
          }
        })
      }
      const removePart = (partId: string, onSuccess: any, onError: any) => {
        let id = props.event?.compositionIssuance?.id
        store.dispatch(`organizations/REMOVE_COMPOSITION_ISSUANCE_PART`, {
          companyId: company.value?.id,
          compositionIssuanceId: id,
          partId,
          onSuccess: () => {
            if (onSuccess) onSuccess()
            getTotal()
            issuanceParts.value = [
              ...issuanceParts.value.filter((x) => x.id !== partId)
            ]

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineDeleteSuccess`)
            })
          },
          onError: () => {
            if (onError) onError()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineDeleteError`)
            })
          }
        })
      }

      const getParts = (
        id: string | undefined,
        page = 1,
        contactsId: string[]
      ) => {
        if (!id) return

        isLoadingParts.value = true
        store.dispatch('organizations/GET_COMPOSITION_ISSUANCE_PARTS', {
          companyId: company.value?.id,
          compositionIssuanceId: id,
          page,
          filter: { contactsId },
          limit: isDetailPage.value ? 100 : null,
          onSuccess: (response: any, pageLimit = 0) => {
            const { data, metadata } = response
            pagination.value = {
              ...metadata,
              total: metadata?.count,
              limit: pageLimit
            }
            allParts.value = JSON.parse(JSON.stringify(data))
            issuanceParts.value = [...data]
            isLoadingParts.value = false
          },
          onError: () => {
            isLoadingParts.value = false
          }
        })
      }

      watch(
        () => props.event,
        () => {
          getParts(props.event?.compositionIssuance?.id, 1, [])
          getTotal()
        }
      )

      watch(
        () => props.canEdit,
        (val) => {
          canEditHeader.value = val
        }
      )

      onMounted(() => {
        getParts(props.event?.compositionIssuance?.id, 1, [])
        getTotal()
      })

      return {
        lines,
        headerFields,
        canEditHeader,
        totalLine,
        isDetailPage,
        selectedContacts,
        pagination,
        columns,
        filteredPartsId,
        isLoadingParts,
        isOverLimit,
        updateSelected,
        resetLine,
        removeNewLine,
        saveEventPrimaryInfo,
        addNewLine,
        handlePageSelection,
        saveNewLine,
        saveLine,
        removePart
      }
    }
  })
</script>
