
  import { computed, defineComponent, ref } from 'vue'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'
  import { useStore } from 'vuex'
  import { CompanyModel } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    components: { UplDatePicker, Button, ElTooltip, Loader },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const datePicked = ref(moment.utc().toISOString())
      const isLoading = ref(false)
      const isDownloading = ref(false)

      const download = () => {
        isDownloading.value = true
        store.dispatch('organizations/EXPORT_COMPANY_COMMITMENTS_EXCEL', {
          companyId: currentCompany.value?.id,
          filter: { date: datePicked.value },
          name: t('exports.commitment'),
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      return { datePicked, isDownloading, isLoading, download }
    }
  })
