
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Input } from '@up.law/uplaw-ui'
  import { Field, useForm } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import * as yup from 'yup'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import FileInput from '@/components/FileInput/index.vue'

  import {
    CurrencyEnum,
    DateTimeFormatEnum,
    DocAutoTemplate,
    DocAutoTemplateForm,
    NumberFormatEnum
  } from '@/store/modules/Organization/docAutoModel'
  import { EventType } from '@/types/event'
  import { useI18n } from 'vue-i18n'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { TemplateError } from '@/store/modules/Config'

  export default defineComponent({
    components: { Multiselect, Field, Input, FormActions, FileInput },
    props: { file: { type: Object, required: false } },
    emits: ['onUpdated', 'removeTemplate'],
    setup(props, { emit }) {
      const { t } = useI18n()
      const route = useRoute()
      const store = useStore()
      const selectedFile = ref()
      const category = ref()
      const documentCategories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])
      const errorMessages = ref<TemplateError[]>([])

      const schema = computed(() =>
        yup.object({
          label: yup.string().required('labelRequired'),
          documentSubcategoryId: yup
            .string()
            .required('templateDocumentSubcategoryId'),
          eventType: yup.string().required('template_eventRequired'),
          currency: yup.string().required('eventRequired'),
          numberFormat: yup.string().required('eventRequired'),
          dateTimeFormat: yup.string().oneOf(Object.values(DateTimeFormatEnum))
        })
      )

      const selectedTemplate = computed<DocAutoTemplate | null>(
        () => store.getters['organizations/template']
      )
      const eventTypes = computed(() =>
        [
          EventType.transfer,
          EventType.optionIssuance,
          EventType.optionExercise
        ].map((x) => ({
          id: x,
          name: t(`events.labels.${x}`)
        }))
      )

      const dateTimeFormats = computed(() =>
        Object.values(DateTimeFormatEnum).map((x) => ({
          id: x,
          name: moment().format(`${x}`)
        }))
      )

      const currencies = computed(() =>
        Object.values(CurrencyEnum).map((x) => ({
          id: x,
          name: t(`currencies.${x}`)
        }))
      )

      const formats = computed(() =>
        Object.values(NumberFormatEnum).map((x) => ({
          id: x,
          name: `${t(`numberFormat.${x}`)} (${new Intl.NumberFormat(`${x}`, {
            style: 'currency',
            currency: t(`numberFormatDevise.${x}`)
          }).format(123456.789)})`
        }))
      )
      const formData = ref<DocAutoTemplateForm>({
        label: '',
        documentSubcategoryId: '',
        file: null,
        currency: CurrencyEnum.EUR,
        dateTimeFormat: DateTimeFormatEnum.LLLL,
        eventType: EventType.transfer,
        numberFormat: NumberFormatEnum.fr_FR
      })

      const {
        errors,
        meta,
        setErrors,
        setFieldError,
        setFieldValue,
        handleSubmit,
        resetForm
      } = useForm({
        validationSchema: schema,
        initialValues: formData.value
      })

      const handleReset = () => {
        resetForm()
      }

      const handleForm = handleSubmit((values) => {
        const companyId = route.params.id

        if (!selectedFile.value) {
          setFieldError('file', 'templateFileRequired')
          return
        }

        errorMessages.value = []
        let data = new FormData()
        const f: any = selectedFile.value
        data.append('label', values.label)
        data.append('currency', values.currency)
        data.append('dateTimeFormat', values.dateTimeFormat)
        data.append('eventType', values.eventType)
        data.append('documentSubcategoryId', values.documentSubcategoryId ?? '')
        data.append('numberFormat', values.numberFormat)
        data.append('file', f)

        store.dispatch('organizations/SAVE_TEMPLATE', {
          data,
          companyId: companyId,
          onSuccess: (templ: any) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.success_save`)
            })

            emit('onUpdated')
          },
          onError: (err: TemplateError[]) => {
            errorMessages.value = err
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.error_save`)
            })
          }
        })
      })

      const updateFilters = (e: any, filter: string) => {
        // store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
        //   companyId: route.params.id,
        //   filter: { label: e, offset: 0, limit: 10 }
        // })
      }

      const removeTemplate = () => {
        emit('removeTemplate', selectedTemplate.value?.id)
      }

      const handleFilesSelection = (files: any) => {
        const [file] = files

        if (file) {
          selectedFile.value = file
        }
      }

      watch(
        () => props.file,
        (val) => {
          const f: any = val
          setFieldValue('file', f)
          setErrors({ file: undefined })
        }
      )

      watch(
        () => selectedTemplate.value,
        (val) => {
          formData.value = {
            documentSubcategoryId: val?.documentSubcategory?.id,
            label: val?.label ?? '',
            currency: val?.currency ?? CurrencyEnum.EUR,
            numberFormat: val?.numberFormat ?? NumberFormatEnum.fr_FR,
            dateTimeFormat: val?.dateTimeFormat ?? DateTimeFormatEnum.LLLL,
            eventType: val?.eventType ?? EventType.transfer,
            file: null
          }
        }
      )

      onMounted(() => {
        formData.value = {
          label: selectedTemplate.value?.label ?? '',
          documentSubcategoryId:
            selectedTemplate.value?.documentSubcategory?.id,
          currency: selectedTemplate.value?.currency ?? CurrencyEnum.EUR,
          numberFormat:
            selectedTemplate.value?.numberFormat ?? NumberFormatEnum.fr_FR,
          dateTimeFormat:
            selectedTemplate.value?.dateTimeFormat ?? DateTimeFormatEnum.LLLL,
          eventType: selectedTemplate.value?.eventType ?? EventType.transfer,
          file: null
        }
      })

      return {
        formData,
        eventTypes,
        formats,
        currencies,
        dateTimeFormats,
        moment,
        errors,
        selectedTemplate,
        meta,
        selectedFile,
        schema,
        errorMessages,
        documentCategories,
        category,
        handleFilesSelection,
        handleReset,
        updateFilters,
        handleForm,
        removeTemplate
      }
    }
  })
