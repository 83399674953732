<template>
  <div>
    <label
      v-if="hasLabel"
      class="capitalize text-xs font-semibold text-secondary8 text-left"
      >{{ data.label }}</label
    >
    <Field
      v-slot="{ field }"
      v-model="option"
      :name="fieldName"
      :placeholder="data.hint"
    >
      <Multiselect
        v-model="option"
        v-bind="field"
        :name="fieldName"
        :value="option"
        class="w-full min-h-full"
        :show-labels="true"
        :mode="mode"
        :options="stocks"
        :placeholder="
          $t(`events.elements.${mode === 'single' ? 'stock' : 'stocks'}`)
        "
        :groups="false"
        label="name"
        :disabled="disabled"
        :filterResults="filterResults"
        :open-direction="openDirection"
        @search-change="handleSearch"
        @change="updateValue"
      >
        <template #[addActionSlot]>
          <div v-if="canAddNew" class="">
            <div
              class="
                flex
                w-full
                h-12
                justify-start
                items-center
                text-md
                px-2
                text-secondary9
                hover:bg-primary1
              "
            >
              <i class="bi bi-plus text-xl text-secondary9"></i>
              <h4
                class="
                  w-full
                  h-full
                  text-secondary9
                  flex
                  justify-start
                  items-center
                  text-md
                "
                @click="openModal"
              >
                {{ $t('events.elements.newStock') }}
              </h4>
            </div>
          </div>
        </template>
        <template #option="{ option: opt }">
          <span v-if="opt?.id != 0" class="w-full text-sm"
            >{{ opt.name }}
          </span>
        </template>
        <template #noOptions>
          <!-- <span v-if="false"> Il n'y a pas de sélection disponible </span> -->
          <!-- <span v-else>Commencez à taper pour rechercher...</span> -->
        </template>

        <template #noResults>
          <span>{{ $t('global.placeholder.noResult') }}</span>
        </template>
      </Multiselect>
    </Field>
    <slot :name="data.name" :value="true" />
  </div>
</template>

<script lang="ts">
  import {
    CompanyModel,
    ContactAvailableData,
    StockAlias
  } from '@/services/api/models'
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { Field } from 'vee-validate'
  import { MAINACTIONS } from '@/types'

  export default defineComponent({
    name: 'DropdownActions',
    components: { Multiselect, Field },
    props: {
      disabled: Boolean,
      data: {
        type: Object,
        default: () => ({
          name: '',
          type: '',
          fieldId: ''
        })
      },
      filterResults: { type: Boolean, default: true },
      classItem: {
        type: String,
        default: 'w-full'
      },
      hasLabel: {
        default: true,
        type: Boolean
      },
      value: {
        default: '',
        type: [String, Array]
      },
      mode: {
        default: 'single',
        type: String
      },
      openDirection: {
        type: String,
        default: 'bottom'
      },
      callback: {
        type: Function,
        default: () => ({})
      },
      canAddNew: { type: Boolean, default: true },
      validationState: { type: String, default: '' },
      categoryId: { type: String, default: null },

      tableId: {
        type: String,
        default: null
      },
      index: {
        type: Number,
        default: null
      }
    },
    emits: ['onUpdated'],
    setup(props, { emit }) {
      const store = useStore()

      const { t } = useI18n()
      const route = useRoute()
      const { data } = toRefs(props)
      const searchText = ref('')
      const fieldName =
        props.tableId && props.index !== -1
          ? `${props.tableId}[${props.index}].${data.value.name}`
          : data.value.name

      const addActionSlot = computed(() =>
        props.mode === 'top' ? 'afterlist' : 'beforelist'
      )

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const stocks = computed<StockAlias[]>(() =>
        props.categoryId
          ? store.getters[`organizations/stockAliases`].filter(
              (x: StockAlias) => x.stockCategory?.id === props.categoryId
            )
          : store.getters[`organizations/stockAliases`]
      )

      const option = ref<any>(props.value)

      const openModal = () => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.STOCK
        })
      }

      const updateValue = (selected: string) => {
        option.value = selected
        emit('onUpdated', {
          ...data.value,
          value: selected
        })
      }

      const handleSearch = (search: string) => {
        getStocks(search)
      }
      const getStocks = (search?: string) => {
        const filter: any = {}
        if (search) filter.alias = search
        store.dispatch('organizations/GET_STOCK_ALIAS', {
          companyId: currentCompany.value?.id,
          filter
        })
      }

      onMounted(() => {
        getStocks()
      })

      watch(props, (prop) => {
        option.value = prop.value
      })

      return {
        option,
        fieldName,
        addActionSlot,
        searchText,
        stocks,
        handleSearch,
        updateValue,
        openModal
      }
    }
  })
</script>
