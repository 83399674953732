
  import {
    computed,
    defineComponent,
    onBeforeMount,
    onMounted,
    ref,
    watch
  } from 'vue'
  import { Tag, Filter, Button } from '@up.law/uplaw-ui'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DataTable from '@/components/DataTable/index.vue'
  import moment from 'moment'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import {
    Contact,
    ExerciseCalendar,
    OptionStockAlias,
    VestingSynthesis
  } from '@/services/api/models'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { formatNumber, rgbToHex, ucFirst } from '@/utils'
  import { useI18n } from 'vue-i18n'
  import EmptyState from '@/components/EmptyState/index.vue'
  import DatePicker from '@/components/DatePicker/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import Number from '@/components/Number/index.vue'
  import { NumberType } from '@/types'
  import { ElTooltip } from 'element-plus'
  import DropdownOptions from '@/components/Forms/DropDowns/DropdownOptions.vue'
  import DropdownVesting from '@/components/Forms/DropDowns/DropdownVesting.vue'
  export default defineComponent({
    components: {
      DataTable,
      Tag,
      ContactBadge,
      EmptyState,
      Multiselect,
      DatePicker,
      Filter,
      Button,
      ElTooltip,
      DropdownOptions,
      Loader,
      DownloadButton,
      Number,
      DropdownVesting
    },
    setup() {
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const selectedColumns = ref<string[]>([])
      const isFiltering = ref(false)
      const filter = ref<any>({
        vestingId: '',
        contactId: [],
        delegationId: [],
        optionAliasId: [],
        contactGroupId: '',
        date: new Date().toISOString()
      })

      const isFiltered = computed(
        () =>
          filter.value?.vestingId ||
          filter.value?.delegationId?.length ||
          filter.value?.contactId?.length ||
          filter.value?.contactGroupId ||
          filter.value?.optionAliasId?.length ||
          moment.utc(filter.value.date).endOf('day').unix() !==
            moment.utc().endOf('day').unix()
      )
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )
      const pagination = computed(
        () => store.getters['organizations/exerciseCalendarPagination']
      )
      const exerciceCalendarsTotal = computed<VestingSynthesis>(
        () => store.getters['organizations/exerciseCalendarsTotal']
      )
      const currency = computed(() => store.getters['organizations/currency'])
      const delegations = computed(
        () => store.getters['organizations/delegations']
      )

      const loading = ref(true)

      const companyGroups = computed(
        () => store.getters['organizations/groups']
      )

      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData'] && loading
      )

      const optionAliases = ref<OptionStockAlias[]>([])

      const contacts = computed<Contact[]>(() =>
        store.getters['organizations/contacts'].map((x: any) => ({
          ...x,
          name: ucFirst(x.name)
        }))
      )

      const total = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.optionIssuancePart.quantity),
          0
        )
      )

      const exercisableQuantity = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += c.vestedQuantity), 0)
      )

      const ceil = computed(() => {
        return exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.exercisableBalance),
          0
        )
      })

      const obsoleteQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.numberOfSunsetedOptions),
          0
        )
      )
      const exercisedQuantity = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) => (acc += c.exercisedQuantity),
          0
        )
      )

      const heldBalance = computed(
        () => total.value - exercisableQuantity.value
      )

      const exercisePrice = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              c.optionIssuancePart.unitPrice ||
              c.optionIssuancePart.totalPrice / c.optionIssuancePart.quantity),
          0
        )
      )

      const lastStockPrice = computed(() =>
        exerciceCalendars.value.reduce((acc, c) => (acc += c.lastStockPrice), 0)
      )
      const portentialGainAtDate = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc += c.portentialGainAtDate < 0 ? 0 : c.portentialGainAtDate),
          0
        )
      )

      const totalExercisePrice = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              (c.optionIssuancePart.unitPrice ||
                c.optionIssuancePart.totalPrice /
                  c.optionIssuancePart.quantity) *
              c.optionIssuancePart.quantity),
          0
        )
      )
      const potentialGain100Percent = computed(() =>
        exerciceCalendars.value.reduce(
          (acc, c) =>
            (acc +=
              c.potentialGain100Percent < 0 ? 0 : c.potentialGain100Percent),
          0
        )
      )

      const allColumns = ref([
        {
          label: t('events.fieldsLabels.stock'),
          field: 'stock',
          custom: true,
          tooltipText: t('events.tooltips.stock'),
          tooltipWidth: '12%',
          hasTooltip: true,
          order: 2
        },
        {
          label: t('events.fieldsLabels.contact'),
          field: 'user',
          custom: true,
          order: 1,
          sortBy: 'contact',
          tooltipText: t('events.tooltips.contact'),
          tooltipWidth: '12%',
          hasTooltip: true,
          sortFunction: (a: any, b: any) => {
            return a?.contact.name.localeCompare(b.contact.name)
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.vestingStartDate'),
          field: 'vestingStartDate',
          custom: true,
          order: 3,
          tooltipText: t('events.tooltips.vestingStartDate'),
          tooltipWidth: '17%',
          hasTooltip: true,
          sortBy: 'vestingStartDate',
          sortFunction: (a: any, b: any) => {
            return (
              moment
                .utc(a.exercisePeriods[a.exercisePeriods.length - 1].startDate)
                .unix() -
              moment
                .utc(b.exercisePeriods[b.exercisePeriods.length - 1].startDate)
                .unix()
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.vestingEndDate'),
          field: 'vestingEndDate',
          custom: true,
          filterable: true,
          selected: true,
          order: 4,
          tooltipText: t('events.tooltips.vestingEndDate'),
          tooltipWidth: '17%',
          hasTooltip: true,
          sortBy: 'vestingEndDate',
          sortFunction: (a: any, b: any) => {
            return (
              moment
                .utc(a.exercisePeriods[a.exercisePeriods.length - 1].endDate)
                .unix() -
              moment
                .utc(b.exercisePeriods[b.exercisePeriods.length - 1].endDate)
                .unix()
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.delegation'),
          field: 'delegation',
          custom: true,
          tooltipText: t('events.tooltips.delegation'),
          tooltipWidth: '12%',
          hasTooltip: true,
          order: 4.5,
          filterable: true
        },
        {
          label: t('events.fieldsLabels.assignedQuantity'),
          field: 'allocatedQuantity',
          custom: true,
          order: 6,
          sortBy: 'allocatedQuantity',
          tooltipText: t('events.tooltips.assignedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true,
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart.quantity - b?.optionIssuancePart.quantity
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.exercisableQuantity'),
          field: 'exercisableQuantity',
          custom: true,
          filterable: true,
          order: 9,
          selected: false,
          tooltipText: t('events.tooltips.vestedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          sortBy: 'exercisableQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.exercisableQuantity - b?.exercisableQuantity
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.balance'),
          field: 'ceil',
          custom: true,
          filterable: true,
          order: 10,
          selected: true,
          sortBy: 'ceil',
          tooltipText: t('events.tooltips.exercisableBalance'),
          tooltipWidth: '20%',
          hasTooltip: true,
          sortFunction: (a: any, b: any) => {
            return a?.exercisableBalance - b?.exercisableBalance
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.lapsedQuantity'),
          field: 'lapsedQuantity',
          custom: true,
          filterable: true,
          order: 8,
          selected: true,
          tooltipText: t('events.tooltips.lapsedQuantity'),
          tooltipWidth: '17%',
          hasTooltip: true,
          sortBy: 'lapsedQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.numberOfSunsetedOptions - b?.numberOfSunsetedOptions
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.exercisedQuantity'),
          field: 'exercisedQuantity',
          custom: true,
          filterable: true,
          order: 7,
          selected: true,
          tooltipText: t('events.tooltips.exercisedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          sortBy: 'exercisedQuantity',
          sortFunction: (a: any, b: any) => {
            return a?.exercisedQuantity - b?.exercisedQuantity
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.heldBalance'),
          field: 'heldBalance',
          custom: true,
          filterable: true,
          order: 8,
          selected: false,
          tooltipText: t('events.tooltips.notVestedQuantity'),
          tooltipWidth: '20%',
          hasTooltip: true,
          sortBy: 'heldBalance',
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart.quantity -
              a?.vestedQuantity -
              (b?.optionIssuancePart.quantity - b?.vestedQuantity)
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.exercisePrice'),
          field: 'exercisePrice',
          custom: true,
          filterable: true,
          order: 5,
          selected: true,
          tooltipText: t('events.tooltips.exercisePrice'),
          tooltipWidth: '12%',
          hasTooltip: true,
          sortBy: 'exercisePrice',
          sortFunction: (a: any, b: any) => {
            return (
              a?.optionIssuancePart?.optionIssuance?.exercisePrice -
              b?.optionIssuancePart?.optionIssuance?.exercisePrice
            )
          },
          type: 'function'
        },
        {
          label: t('events.fieldsLabels.totalExercisePrice'),
          field: 'totalExercisePrice',
          custom: true,
          filterable: true,
          order: 12.5,
          tooltipText: t('events.tooltips.totalExercisePrice'),
          tooltipWidth: '17%',
          hasTooltip: true,
          selected: false
        },
        {
          label: t('events.fieldsLabels.lastStockPrice'),
          field: 'lastActionPrice',
          custom: true,
          filterable: true,
          tooltipText: t('events.tooltips.lastStockPrice'),
          tooltipWidth: '20%',
          hasTooltip: true,
          order: 12
        },
        {
          label: t('events.fieldsLabels.potentialPv'),
          field: 'pv',
          custom: true,
          filterable: true,
          tooltipText: t('events.tooltips.potentialPv'),
          tooltipWidth: '17%',
          hasTooltip: true,
          order: 13
        },
        // {
        //   label: t('events.fieldsLabels.vestedPv'),
        //   field: 'pv_vested',
        //   custom: true,
        //   filterable: true,
        //   order: 14
        // },
        {
          label: t('events.fieldsLabels.vestingPlan'),
          field: 'vesting',
          custom: true,
          filterable: true,
          tooltipText: t('events.tooltips.vestingPlan'),
          tooltipWidth: '17%',
          hasTooltip: true,
          order: 15
        },
        {
          label: t('datatable.column.event'),
          field: 'eventName',
          tooltipText: t('events.tooltips.event'),
          tooltipWidth: '20%',
          hasTooltip: true,
          custom: true,

          order: 15,
          filterable: true,
          selected: true
        }
      ])
      const existingColumns = ref(allColumns.value.filter((x) => !x.filterable))
      const columns = ref(
        allColumns.value
          .filter((x) => !x.filterable || x.selected)
          .sort((a: any, b: any) => a.order - b.order)
      )
      const availableColumns = ref(
        allColumns.value
          .filter((x) => x.filterable || x.selected)
          .map((x) => ({ id: x.field, value: x.label }))
      )

      selectedColumns.value = allColumns.value
        .filter((x) => x.selected)
        .map((x) => x.field)

      const handleFilterSelected = (value: string[]) => {
        columns.value = [
          ...existingColumns.value,
          ...allColumns.value.filter((x) => value.includes(x.field))
        ].sort((a: any, b: any) => a.order - b.order)

        selectedColumns.value = value
      }
      const gotoEvent = (eventId: string) => {
        router.push({
          name: 'company.eventDetails',
          params: { id: route.params.id, eventId }
        })
      }
      const getVesting = async () => {
        await store.dispatch('organizations/GET_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: {
            esopOnly: true,
            ...filter.value
          }
        })

        loading.value = false
      }

      const getTotal = () => {
        if (!isFiltered.value) return
        store.dispatch('organizations/GET_EXERCISE_CALENDARS_TOTAL', {
          companyId: route.params.id,
          filter: {
            esopOnly: true,
            ...filter.value
          }
        })
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_EXERCISE_CALENDARS_PAGE', page)
        getVesting()
      }

      const isDownloading = ref(false)
      const resetFilters = () => {
        filter.value = {
          vestingId: '',
          contactId: [],
          delegationId: [],
          optionAliasId: [],
          contactGroupId: '',
          date: new Date().toISOString()
        }
      }

      const downloadHotjar = () => {
        const companyId = route.params.id
        store.dispatch('organizations/exportHotjar', {
          name: `Caducités`,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }
      const download = () => {
        isDownloading.value = true

        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_EXERCISE_CALENDARS', {
          name: `${t('exports.shareholding')} `,
          companyId,
          filter: filter.value,
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }
      const getOrder = (field: string) =>
        columns.value.find((x) => x.field === field)?.order || 0

      const getStocks = (search?: string) => {
        const filter: any = {}
        if (search) filter.name = search
        store.dispatch('organizations/GET_OPTION_STOCK_ALIAS', {
          companyId: route.params.id,
          filter,
          onSuccess: (data: OptionStockAlias[]) => {
            optionAliases.value = [...data].map((x) => ({
              ...x,
              name: x.alias
            }))
          }
        })
      }

      const updateFIltering = (value: boolean) => {
        isFiltering.value = value
      }
      const handleOptionChange = (data: any, field: string) => {
        isFiltering.value = true
        filter.value[field] = data
        getTotal()
        onPageChange(1)
      }

      watch(
        () => route.params.id,
        () => {
          getTotal()
          onPageChange(1)
        }
      )

      watch(
        () => filter.value.date,
        (val) => {
          onPageChange(1)
          filter.value.date = val || new Date().toISOString()
          getTotal()
        }
      )

      const handleContactSearch = (search: string) => {
        getContacts(search)
      }

      const getContacts = async (search = '') => {
        await store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: route.params.id,
          filter: {
            limit: 10,
            offset: 0,
            name: search
          }
        })
      }

      const handleDelegationSearch = (search = '') => {
        store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)
        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id,
          filter: { search }
        })
      }
      const getDelegations = () => {
        store.dispatch('organizations/GET_DELEGATIONS', {
          companyId: route.params.id,
          filter: {}
        })
      }

      onMounted(async () => {
        store.commit('organizations/SET_DELEGATION_PAGINATION_PAGE', 1)

        getDelegations()
        getTotal()

        onPageChange(1)

        getStocks()

        getContacts()
      })

      onBeforeMount(() => (loading.value = true))

      return {
        currency,
        contacts,
        columns,
        isFiltered,
        availableColumns,
        exerciceCalendars,
        exercisableQuantity,
        obsoleteQuantity,
        ceil,
        pagination,
        selectedColumns,
        delegations,
        heldBalance,
        exercisedQuantity,
        lastStockPrice,
        exercisePrice,
        portentialGainAtDate,
        potentialGain100Percent,
        total,
        downloadHotjar,
        isDownloading,
        optionAliases,
        filter,
        isLoading,
        loading,
        moment,
        companyGroups,
        totalExercisePrice,
        NumberType,
        exerciceCalendarsTotal,
        isFiltering,
        resetFilters,
        getStocks,
        formatNumber,
        download,
        onPageChange,
        handleFilterSelected,
        getOrder,
        gotoEvent,
        handleOptionChange,
        updateFIltering,
        handleDelegationSearch,
        handleContactSearch,
        rgbToHex
      }
    }
  })
