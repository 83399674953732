
  import { mediumScreenBreakpointWidth } from '@/utils'
  import { defineComponent, computed, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'UplModal',
    props: {
      isOpen: {
        type: Boolean,
        required: true
      },
      onModalClose: {
        type: Function,
        required: false
      }
    },
    setup(props) {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const hideSidebar = ref(false)
      const fitPageSize = ref<boolean>(
        window.innerWidth <= mediumScreenBreakpointWidth
      )
      const toggleSidebar = () => (hideSidebar.value = !hideSidebar.value)
      const isModalOpen = computed(() => props.isOpen || false)

      const toggleModal = (display: boolean) => {
        const q = { ...route.query }
        delete q.next
        router.push({ name: route.name ?? '', query: { ...q } })
        hideSidebar.value = false
        fitPageSize.value = window.innerWidth <= mediumScreenBreakpointWidth
        store.commit('organizations/SET_CURRENT_CONTACT', null)
        store.commit('organizations/SET_CURRENT_VESTING', null)
        store.commit('organizations/SET_CURRENT_COMMITTEE', null)
        store.commit('organizations/SET_PROFIT_SHARING_PLAN', null)
        store.commit('organizations/SET_FUND_RAISING_ROUND', null)
        store.commit('organizations/SET_CURRENT_STOCK', { data: null })
        store.dispatch('ui/toggleModal', { display })
      }

      const fitPage = () => {
        fitPageSize.value = !fitPageSize.value
      }

      return {
        isModalOpen,
        hideSidebar,
        fitPageSize,
        toggleSidebar,
        toggleModal,
        fitPage
      }
    }
  })
