<template>
  <div class="grid grid-cols-6 gap-4 mb-4 flex items-end">
    <div
      class="col-start-1 col-end-3 text-left text-xl font-bold text-secondary9"
    >
      {{ primitive.label }}
    </div>
    <div class="col-end-7 col-span-2 flex justify-end">
      <Filter
        class="relative"
        :options="availableColumns"
        :value="selectedColumns"
        @onChange="handleFilterSelected"
      >
        <template #option="{ option, checked }" @click.prevent.stop="">
          <Checkbox
            size="w-5 h-5 mr-2"
            :checked="checked"
            :name="option.id"
            @click.prevent.stop
          />
        </template>
        <i class="bi bi-sliders"></i>
      </Filter>
    </div>
  </div>

  <!-- TODO:overflow-x-scroll is not working nonewith dropdowns -->
  <data-table
    v-if="primitive.rows.length"
    key="tableKey"
    container-class="overflow-y-none"
    :has-checkbox="false"
    :columns="columns"
    :rows="formData"
    index-key="id"
    text-size=""
    cell-padding=""
    @delete-action="deleteAction"
    @duplicate-action="duplicateAction"
  >
    <template #default="{ item, index }">
      <component
        :is="getTableEntry(primitive.tableId, item).component"
        v-if="!getTableEntry(primitive.tableId, item).isCalculated"
        v-model="formData[index][item]"
        :all-data="allData"
        :line-data="formData[index]"
        :data="getTableEntry(primitive.tableId, item)"
        :value="formData[index][item]"
        :index="index"
        :has-label="false"
        :type-field="getTableEntry(primitive.tableId, item).type"
        :error="errors[getTableEntry(primitive.tableId, item).name]"
        :table-id="primitive.tableId"
        class="w-full"
        v-bind="getTableEntry(primitive.tableId, item).props"
        :disabled="
          typeof getTableEntry(primitive.tableId, item).props?.disabled ==
          'function'
            ? getTableEntry(primitive.tableId, item).props?.disabled(allData)
            : getTableEntry(primitive.tableId, item).props?.disabled ?? false
        "
        @on-updated="updateFormData($event, index)"
      />
    </template>
    <template #unitPrice="{ row }">
      <p class="text-left px-2">
        {{ unitPrice(row) }}
        <span v-if="unitPrice(row) != '-'">{{ currency }}</span>
      </p>
    </template>
    <template #sunsetNotVestedQuantity="{ row }">
      <p class="text-left px-2">
        {{ row.sunsetNotVestedQuantity || '-' }}
      </p>
    </template>
    <template #sunsetVestedQuantity="{ row }">
      <p class="text-left px-2">
        {{ row.sunsetVestedQuantity || '-' }}
      </p>
    </template>

    <template #issuancePremiumPerAction="{ row }">
      <p v-if="emitPerAction(row) !== '-'" class="text-left px-2">
        {{ formatNumber(emitPerAction(row)) }}
        <span v-if="emitPerAction(row) != '-'">{{ currency }}</span>
      </p>
      <p v-else class="text-left px-2">-</p>
    </template>
    <template #totalIssuancePremium="{ row }">
      <p class="text-left px-2"></p>
      {{ formatNumber(emitTotal(row)) }}
      <span v-if="emitTotal(row) != '-'">{{ currency }}</span>
    </template>
    <template #nominalIncrease="{ row }">
      <p class="text-left px-2">
        {{ formatNumber(augNominal(row)) }}
        <span v-if="augNominal(row) != '-'">{{ currency }}</span>
      </p>
    </template>
    <template #capitalReduction="{ row }">
      <p class="text-left px-2">
        {{ formatNumber(capitalReduction(row)) }}
        <span v-if="capitalReduction(row) != '-'">{{ currency }}</span>
      </p>
    </template>
    <template #exercisePrice="{ row }">
      <p class="text-left px-2">
        {{ formatNumber(exercisePrice(row)) || '-' }}
        <span v-if="exercisePrice(row) != '-'">{{ currency }}</span>
      </p>
    </template>
    <template #total>
      <tr class="data-table-cell text-sm">
        <td
          :colspan="`${startColspan()}`"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
            h-10
          "
        >
          <p class="text-right font-semibold pr-1 text-primary8">Total</p>
        </td>
        <td
          v-if="hasColumn('quantity')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ formatNumber(totalQuantity()) }}
          </p>
        </td>
        <td
          v-if="hasColumn('issuedQuantity')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ formatNumber(totalIssuedQuantity()) }}
          </p>
        </td>
        <td
          v-show="hasColumn('unitPrice')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">-</p>
        </td>
        <td
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p
            v-if="globalTotal()"
            class="text-left pl-1 font-semibold text-primary8"
          >
            {{ formatNumber(globalTotal()) }}
            <span>{{ currency }}</span>
          </p>
          <p v-else class="text-left pl-1 font-semibold text-primary8">-</p>
        </td>
        <td
          v-show="hasColumn('releasedAmount')"
          class="
            border border-r-0 border-l-0 border-t border-b border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ formatNumber(globalReleased().toFixed(2)) }}
            <span>{{ currency }}</span>
          </p>
        </td>
        <td
          v-show="hasColumn('exercisePrice')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ formatNumber(globalExercisePrice().toFixed(2)) }}
            <span>{{ currency }}</span>
          </p>
        </td>
        <td
          v-show="hasColumn('issuancePremiumPerAction')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ formatNumber(globalIssuancePerAction()) }}
            <span>{{ currency }}</span>
          </p>
        </td>
        <td
          v-show="hasColumn('totalIssuancePremium')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ formatNumber(globalEmit()) }}
            <span>{{ currency }}</span>
          </p>
        </td>
        <td
          v-show="hasColumn('nominalIncrease')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ formatNumber(globalNominalIncrease()) }}
            <span>{{ currency }}</span>
          </p>
        </td>
        <td
          v-show="hasColumn('capitalReduction')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ formatNumber(globalReduction()) }} <span>{{ currency }}</span>
          </p>
        </td>
        <td
          v-show="hasColumn('entryDate')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        ></td>
        <td
          :colspan="`${colspan()}`"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        ></td>
        <td
          v-show="hasColumn('sunsetVestedQuantity')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ totalVested() }}
          </p>
        </td>
        <td
          v-show="hasColumn('sunsetNotVestedQuantity')"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        >
          <p class="text-left pl-1 font-semibold text-primary8">
            {{ totalNonVested() }}
          </p>
        </td>

        <td
          v-show="hasColumn('motive')"
          :colspan="`${colspan()}`"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        ></td>
        <td
          v-show="hasColumn('ownershipType')"
          :colspan="`${colspan()}`"
          class="
            border-r-0 border-l-0 border-t border-b border border-secondary4
          "
        ></td>
      </tr>
    </template>
    <template #footer>
      <div class="p-2 flex flex-column items-center pt-5">
        <div class="mr-2">
          {{ $t('events.table.add') }}
        </div>
        <Field v-slot="{ field }" v-model="rowsToAdd" name="rowsToAdd"
          ><upl-input
            v-model="rowsToAdd"
            v-bind="field"
            class="flex w-12"
            type="number"
            :placeholder="$t('events.table.add')"
            name="rowsToAdd"
            @input="rowValueChanged"
          />
        </Field>

        <div class="mx-2">
          {{ $t('events.table.rows') }}
        </div>
        <Button
          class="btn-add ml-4 px-2 h-8 flex items-center justify-center"
          variant="secondary"
          :label="$t('events.table.add')"
          :disabled="parseInt(rowsToAdd) <= 0"
          @click="addRows"
        >
          <i class="bi bi-plus text-lg"></i>
        </Button>
      </div>
    </template>
    <template #actions="{ index, row }">
      <div class="slot-action">
        <div class="data-table-actions">
          <el-popover
            placement="left"
            :title="$t('events.table.dupe')"
            :width="200"
          >
            <div class="flex items-center">
              <Field
                v-slot="{ field }"
                v-model="timesToDuplicate"
                name="timesToDuplicate"
              >
                <upl-input
                  v-model="timesToDuplicate"
                  v-bind="field"
                  class="flex w-12"
                  type="number"
                  :min="0"
                  :placeholder="$t('events.table.add')"
                  name="rowsToAdd"
                  @input.prevent="inputValueChanged"
                />
              </Field>

              <div class="mx-2">
                {{ $t('events.table.rows') }}
              </div>
              <Button
                class="ml-2 w-12 h-8 flex items-center justify-center"
                variant="secondary"
                :label="$t('events.table.ok')"
                :disabled="parseInt(timesToDuplicate) <= 0"
                @click="duplicateAction(index, row)"
              />
            </div>
            <template #reference>
              <div class="data-table-action">
                <i class="bi bi-front"></i>
              </div>
            </template>
          </el-popover>
          <div class="data-table-action" @click="deleteAction(index)">
            <i
              :class="`bi bi-trash-fill ${
                data.length > 1 ? 'text-error' : 'text-placeholder'
              }`"
            ></i>
          </div>
        </div>
      </div>
    </template>
  </data-table>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, toRefs, watch } from 'vue'
  import { Button, Input, Filter } from '@up.law/uplaw-ui'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import DataTable from '@/components/DataTable/index.vue'
  import DateGeneric from '@/components/Forms/Event/Generic/DateGeneric.vue'
  import InputGeneric from '@/components/Forms/Event/Generic/InputGeneric.vue'
  import ContactGeneric from '@/components/Forms/Event/Generic/ContactGeneric.vue'
  import StockGeneric from '@/components/Forms/Event/Generic/StockGeneric.vue'
  import DropdownStocks from '@/components/Forms/DropDowns/DropdownStocks.vue'
  import DropdownOptions from '@/components/Forms/DropDowns/DropdownOptions.vue'
  import DropdownActions from '@/components/Forms/DropDowns/DropdownActions.vue'
  import DropdownVesting from '@/components/Forms/DropDowns/DropdownVesting.vue'
  import DropdownGeneric from '@/components/Forms/DropDowns/DropdownGeneric.vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { formatNumber } from '@/utils'
  import { EventFormPrimitive } from '@/types/event'
  import { useRoute } from 'vue-router'
  import moment from 'moment'
  import { Field } from 'vee-validate'
  import { ElPopover } from 'element-plus'
  import { ExerciseCalendar } from '@/services/api/models'
  import { OptionSunsetMode } from '@/types'

  export default defineComponent({
    name: 'TableGeneric',
    components: {
      DataTable,
      DropdownGeneric,
      DateGeneric,
      InputGeneric,
      ContactGeneric,
      DropdownStocks,
      DropdownVesting,
      DropdownOptions,
      StockGeneric,
      DropdownActions,
      UplInput: Input,
      Button,
      Field,
      Checkbox,
      Filter,
      ElPopover
    },
    inheritAttrs: false,
    props: {
      // The actual data, must be an array of object
      data: {
        default: () => [],
        type: Array
      },
      allData: {
        default: () => ({}),
        type: Object
      },
      // The primitive from the configuration. Used to get correct component and props
      primitive: {
        default: () => {
          return {
            tableId: 'table1',
            rows: []
          }
        },
        type: Object
      },
      // vee-validate errors
      errors: {
        default: () => {
          return {}
        },
        type: Object
      }
    },
    emits: ['update-table', 'delete-action', 'duplicate-action', 'add-rows'],
    setup(props, { emit }) {
      const { primitive, data } = toRefs(props)

      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const formData = ref<any[]>(props.data)
      const rowsToAdd = ref('1')
      const tableKey = ref(0)
      const timesToDuplicate = ref('1')
      const nominalValue = computed<number>(
        () => store.getters['organizations/nominalValue']
      )
      const exerciceCalendars = computed<ExerciseCalendar[]>(
        () => store.getters['organizations/exerciseCalendars']
      )
      const selectedColumns = computed<string[]>(
        () => store.getters['toolbox/eventSelectedColumns']
      )

      const currency = computed(() => store.getters['organizations/currency'])

      const availableColumns = ref(
        primitive.value.rows
          .filter((x: any) => x.isFilterable)
          .map((x: any) => ({ id: x.name, value: x.label }))
      )

      const inputValueChanged = (value: any) => {
        timesToDuplicate.value = value.target.value
      }

      const rowValueChanged = (value: any) => {
        rowsToAdd.value = value.target.value
      }
      const hasColumn = (columnName: string) => {
        return columns.value.map((x: any) => x.field).includes(columnName)
      }

      const handleFilterSelected = (values: any[]) => {
        store.commit('toolbox/SET_EVENT_TABLE_COLUMNS', [...values])

        columns.value = tableColumns(props.primitive.rows)
      }

      //Utils function to generate columns
      const tableColumns = (rows: any) => {
        let cols = rows.reduce((acc: any, item: any) => {
          acc.push({
            label: t(`events.elements.${item.name}`),
            info: item.labelInfo,
            field: item.name,
            width: `${95 / rows.length}%`,
            display: item.isFilterable
              ? selectedColumns.value.includes(item.name)
              : true,
            sortable: item.sortable ?? true,
            custom: item.custom ?? false,
            customDefault: item.customDefault ?? false
          })
          return acc
        }, [])

        cols = cols.filter((x: any) => x.display)

        cols.push({
          label: t(`events.elements.actions`),
          field: 'actions',
          width: '5%',
          sortable: false,
          custom: true
        })
        return cols
      }

      const columns = ref(tableColumns(props.primitive.rows))
      const colspan = () => {
        let value = 1
        if (hasColumn('vestingId')) value++
        if (hasColumn('vestingStartDate')) value++
        // if (hasColumn('motive')) value++
        // if (hasColumn('entryDate')) value++

        return value
      }

      const startColspan = () => {
        let value = 0
        if (hasColumn('motive')) value++
        if (hasColumn('optionAliasId')) value++
        if (hasColumn('subscriber')) value++
        if (hasColumn('contactId')) value++
        if (hasColumn('sellerId')) value++
        if (hasColumn('transfereeId')) value++
        if (hasColumn('stockAliasId')) value++
        if (hasColumn('titles')) value++
        if (hasColumn('fromTitles')) value++
        if (hasColumn('toTitles')) value++
        return value
      }

      const getTableEntry = (name: string, fieldName: string) => {
        const row =
          primitive.value.rows.find(
            (item: EventFormPrimitive) => item.name === fieldName
          ) ?? {}

        return row
      }

      const getTableEntryData = (
        name: string,
        fieldName: string,
        index: number
      ) => {
        const row =
          primitive.value.rows.find(
            (item: EventFormPrimitive) => item.name === fieldName
          ) ?? {}
        const sended = row.name

        const rowName = `${primitive.value.tableId[index].contactId}`

        return row
      }

      const globalReleased = () => {
        return formData.value.reduce(
          (acc: number, line: any) =>
            (acc += parseFloat(line.releasedAmount || 0)),
          0
        )
      }

      const globalEmit = () => {
        return formData.value.reduce(
          (acc: number, line: any) =>
            (acc +=
              (parseFloat(line.totalPrice) / parseInt(line.quantity || 0) -
                nominalValue.value) *
              parseInt(line.quantity || 0)),
          0
        )
      }

      const globalIssuancePerAction = () => {
        return formData.value.reduce(
          (acc: number, line: any) =>
            (acc +=
              parseFloat(line.totalPrice) / parseInt(line.quantity || 1) -
              nominalValue.value),
          0
        )
      }
      const globalNominalIncrease = () => {
        return Math.abs(
          formData.value.reduce(
            (acc: number, line: any) =>
              (acc += parseInt(line.quantity || 0) * nominalValue.value),
            0
          )
        )
      }

      const globalUnitPrice = () => {
        let value = 0
        if (globalTotal() > 0 && totalQuantity() > 0) {
          value = globalTotal() / totalQuantity()
        }
        return value.toFixed(4)
      }

      const totalQuantity = () => {
        return formData.value.reduce(
          (acc: number, line: any) => (acc += parseInt(line.quantity || 0)),
          0
        )
      }

      const totalVested = () => {
        return formData.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.sunsetVestedQuantity || 0)),
          0
        )
      }

      const totalNonVested = () => {
        return formData.value.reduce((acc: number, line: any) => {
          return (acc += parseInt(line.sunsetNotVestedQuantity || 0))
        }, 0)
      }

      const totalIssuedQuantity = () => {
        return formData.value.reduce(
          (acc: number, line: any) =>
            (acc += parseInt(line.issuedQuantity || 0)),
          0
        )
      }

      const globalTotal = () => {
        return Math.abs(
          formData.value.reduce(
            (acc: number, line: any) =>
              (acc += parseFloat(line.totalPrice || 0)),
            0
          )
        )
      }

      const globalReduction = () => {
        return Math.abs(
          formData.value.reduce(
            (acc: number, line: any) =>
              (acc += parseInt(line.quantity || 0) * nominalValue.value),
            0
          )
        )
      }

      const unitPrice: any = (row: any) => {
        const val = parseFloat(row.totalPrice) / parseInt(row.quantity)
        return val ? Math.abs(val).toFixed(4) : '-'
      }

      const capitalReduction: any = (row: any) => {
        const val = nominalValue.value * parseInt(row.quantity)
        return val ? Math.abs(val).toFixed(4) : '-'
      }

      const exercisePrice: any = (row: any) => {
        const price =
          props.allData.exercisePrice ||
          props.allData.optionExercisePrice ||
          '0'
        const val = parseFloat(price) * parseInt(row.quantity)
        return val ? Math.abs(val).toFixed(4) : '-'
      }

      const globalExercisePrice: any = () => {
        return Math.abs(
          formData.value.reduce(
            (acc: number, line: any) =>
              (acc += parseFloat(exercisePrice(line))),
            0
          )
        )
      }

      const emitPerAction = (row: any) => {
        if (unitPrice(row) === '-') {
          return '-'
        }
        return unitPrice(row) - nominalValue.value
      }
      const emitTotal = (row: any) => {
        const ePerAction: number | string = emitPerAction(row)

        if (ePerAction === '-' || !row.quantity) {
          return '-'
        }

        return parseFloat(ePerAction.toString()) * row.quantity
      }

      const augNominal = (row: any) => {
        if (!nominalValue.value) {
          return '-'
        }
        return (nominalValue.value * row.quantity).toFixed(4)
      }

      const updateFormData = async (val: any, index: number) => {
        let form = [...formData.value]
        const totalField = props.primitive.rows.find(
          (x: any) => x.name === 'totalPrice'
        )
        const isCalculable = totalField && totalField.isCalculable
        formData.value[index][val.name] = val.value
        if (val.parity) formData.value[index]['parity'] = val.parity

        if (
          hasColumn('issuedQuantity') &&
          formData.value[index]['parity'] &&
          val.name !== 'issuedQuantity' &&
          ['optionAliasId', 'quantity'].includes(val.name)
        ) {
          formData.value[index]['issuedQuantity'] =
            (formData.value[index]['parity'] || 0) *
            (formData.value[index]['quantity'] || 0)
          formData.value[index].totalPrice =
            parseInt(formData.value[index]['issuedQuantity']) *
            formData.value[index].unitPrice
        }

        if (val.name == 'issuedQuantity') {
          formData.value[index].totalPrice =
            parseInt(formData.value[index]['issuedQuantity']) *
            formData.value[index].unitPrice
        }
        if (formData.value[index].quantity !== null) {
          if (val.name === 'unitPrice' && isCalculable) {
            const total = (val.value * formData.value[index].quantity).toFixed(
              4
            )
            formData.value[index].totalPrice = total
            formData.value[index].releasedAmount = total
          } else if (val.name === 'totalPrice') {
            formData.value[index].unitPrice = (
              val.value /
              (hasColumn('issuedQuantity')
                ? formData.value[index].issuedQuantity
                : formData.value[index].quantity || 1)
            ).toFixed(4)
            formData.value[index].releasedAmount = val.value
          } else if (
            val.name === 'quantity' &&
            isCalculable &&
            !hasColumn('issuedQuantity')
          ) {
            const total = (val.value * (form[index].unitPrice || 0)).toFixed(4)
            formData.value[index].totalPrice = total
            formData.value[index].releasedAmount = total
          }
        }

        if (val.name === 'contactId' || val.name === 'subscriber') {
          const primitive = props.primitive.rows.find(
            (x: any) => x.name == val.name
          )
          primitive.props.allExcludesId = {
            currentIndex: index,
            value: [...formData.value].map((x: any) => x[val.name])
          }
        }

        if (hasColumn('exercisePrice')) {
          formData.value[index].exercisePrice =
            parseFloat(props.allData.exercisePrice) *
            parseInt(formData.value[index]?.quantity || '0')
        }
        if (
          ['table-exercise', 'table-lapseOptions'].includes(
            primitive.value.tableId
          )
        ) {
          if (val.name === 'contactId') {
            formData.value[index].optionAliasId = ''

            if (val.value) {
              store.dispatch('organizations/GET_CONTACT_EXERCISE_CALENDARS', {
                companyId: route.params.id,
                filter: {
                  contactId: val.value,
                  date: props.allData['date'] || moment().toDate()
                }
              })
            }

            const primitive = props.primitive.rows.find(
              (x: any) => x.name == 'optionAliasId'
            )

            primitive.props = {
              ...primitive?.props,
              optionData: { index, value: val.value }
            }
          }

          if (val.name === 'optionAliasId') {
            const [, price, exerciseId] = val.value?.split('@_')

            formData.value[index].unitPrice = parseFloat(
              price?.replace(',', '.') || 0
            )
            let unitPrice = parseFloat(price || '0')
            if (['table-exercise'].includes(primitive.value.tableId)) {
              unitPrice = parseFloat(formData.value[index].unitPrice)
            }
            formData.value[index].totalPrice = (
              unitPrice *
              (formData.value[index]?.issuedQuantity ||
                formData.value[index].quantity ||
                0)
            ).toFixed(4)
            formData.value[index].exerciseCalendarId = exerciseId
          }
        }

        if (
          ['table-lapseOptions'].includes(primitive.value.tableId) &&
          ['optionAliasId', 'motive'].includes(val.name)
        ) {
          const [optionId] = formData.value[index].optionAliasId?.split('@_')

          const exerciseCalendarId = formData.value[index].exerciseCalendarId
          const motive = formData.value[index].motive
          let calendar: any = await getCalendar(
            exerciseCalendarId,
            props.allData.date,
            formData.value[index].contactId,
            optionId
          )

          if (props.allData.mode == OptionSunsetMode.all) {
            formData.value[index].sunsetVestedQuantity =
              calendar?.vestedQuantity -
              (calendar.optionSunsetParts ?? []).reduce(
                (acc: number, x: any) => (acc += x.exercisableQuantity),
                0
              ) -
              (calendar.optionExerciseParts ?? []).reduce(
                (acc: number, x: any) => (acc += x.issuedQuantity),
                0
              )
            formData.value[index].sunsetNotVestedQuantity =
              (calendar?.notVestedQuantity ?? 0) -
              (calendar.optionSunsetParts ?? []).reduce(
                (acc: number, x: any) => (acc += x.nonVestedQuantity),
                0
              )
          }

          if (motive && calendar?.vestingCalendar) {
            const behaviour: any =
              calendar?.vestingCalendar?.endOfContractBehaviors[motive]
            formData.value[index].nonVestedDate = moment(
              props.allData.date
            ).add(
              behaviour?.notVestedPeriod?.value || 0,
              behaviour?.notVestedPeriod?.timeUnit?.toLowerCase()
            )
            formData.value[index].exercisableBalanceDate = moment(
              props.allData.date
            ).add(
              behaviour?.vestedPeriod?.value || 0,
              behaviour?.vestedPeriod?.timeUnit?.toLowerCase()
            )
          }
        }
        if (['table-conversion'].includes(primitive.value.tableId)) {
          if (val.name === 'contactId') {
            formData.value[index].formTitles = ''

            if (val.value) {
              store.dispatch('organizations/GET_CAPTABLE', {
                companyId: route.params.id,
                filter: {
                  contactId: [val.value],
                  date: props.allData?.conversionDate ?? moment().toDate()
                }
              })
            }

            const primitive = props.primitive.rows.find(
              (x: any) => x.name == 'fromTitles'
            )

            primitive.props = {
              ...primitive?.props,
              stocksData: { index, contactId: val.value, value: val.value }
            }
          }
        }
        if (primitive.value.tableId === 'table-transfer') {
          if (val.name === 'sellerId') {
            const primitive = props.primitive.rows.find(
              (x: any) => x.name == 'transfereeId'
            )
            primitive.props.excludesId = {
              index,
              value: val.value ? [val.value] : []
            }
          } else if (val.name === 'transfereeId') {
            const primitive = props.primitive.rows.find(
              (x: any) => x.name == 'sellerId'
            )
            primitive.props.excludesId = {
              index,
              value: val.value ? [val.value] : []
            }
          } else formData.value[index][val.name] = val.value
        }

        emit('update-table', {
          tableId: primitive.value.tableId,
          value: formData.value
        })
      }

      const deleteAction = (rowIndex: number) => {
        if (data.value.length > 1) {
          emit('delete-action', {
            tableId: primitive.value.tableId,
            rowIndex
          })
        }
      }
      const duplicateAction = (rowIndex: number, row: any) => {
        const toDuplicate = { ...row }
        if (row.sellerId) toDuplicate.sellerId = null
        if (row.fromTitles) toDuplicate.fromTitles = null
        if (row.contactId) toDuplicate.contactId = null
        if (row.transfereeId) toDuplicate.transfereeId = null
        if (row.subscriber) toDuplicate.subscriber = null
        if (row.sunsetNotVestedQuantity) toDuplicate.sunsetNotVestedQuantity = 0
        if (row.sunsetVestedQuantity) toDuplicate.sunsetVestedQuantity = 0

        duplicateRow({
          tableId: primitive.value.tableId,
          rowIndex,
          row: toDuplicate,
          times: timesToDuplicate.value
        })
        timesToDuplicate.value = '1'
      }

      const addRowsAction = (e: any) => {
        const countPerRefresh = 10
        const lastEntry = formData.value[formData.value.length - 1]
        const row = { ...lastEntry }
        if (row.sellerId) row.sellerId = null
        if (row.fromTitles) row.fromTitles = null
        if (row.contactId) row.contactId = null
        // if (row.transfereeId) row.transfereeId = null
        if (row.subscriber) row.subscriber = null
        for (let i = 0; i < e.nb; i++) {
          // Clone the entry to kill the ref, otherwise all entries will have
          // the same value and be reactive as one
          formData.value.push({ ...row })
          if (i % countPerRefresh == 0) tableKey.value++
        }
        rowsToAdd.value = '1'
        tableKey.value++
      }

      const addRows = () => {
        addRowsAction({
          nb: parseInt(rowsToAdd.value)
        })
      }
      const insert = (arr: any[], index: number, data: any) => {
        arr.splice(index, 0, data)
      }
      const duplicateRow = (e: any) => {
        const countPerRefresh = 10

        for (let i = 0; i < parseInt(e.times); i++) {
          const start = parseInt(e.rowIndex) + i + 1
          insert(formData.value, start, { ...e.row })
          if (i % countPerRefresh == 0) tableKey.value++
        }

        tableKey.value++
      }

      const getCalendar = async (
        exerciseCalendarId: string,
        date: string,
        contactId: string,
        optionAliasId: string
      ) => {
        if (!exerciseCalendarId || !contactId) return
        return await store.dispatch('organizations/GET_EXERCISE_CALENDAR', {
          companyId: route.params.id,
          filter: {
            date,
            // optionAliasId: [optionAliasId],
            contactId: [contactId]
          },
          exerciseCalendarId
        })
      }
      watch(
        () => props.data,
        (rows) => {
          formData.value = rows

          tableKey.value++
        }
      )

      watch(props, () => {
        emit('update-table', {
          tableId: primitive.value.tableId,
          value: formData.value
        })
        tableKey.value++
      })

      return {
        availableColumns,
        columns,
        selectedColumns,
        formData,
        rowsToAdd,
        nominalValue,
        tableKey,
        timesToDuplicate,
        unitPrice,
        currency,
        totalNonVested,
        totalVested,
        emitPerAction,
        globalIssuancePerAction,
        emitTotal,
        globalEmit,
        augNominal,
        globalNominalIncrease,
        capitalReduction,
        exercisePrice,
        deleteAction,
        globalExercisePrice,
        duplicateAction,
        totalQuantity,
        totalIssuedQuantity,
        globalReduction,
        globalTotal,
        globalReleased,
        globalUnitPrice,
        addRows,
        handleFilterSelected,
        formatNumber,
        hasColumn,
        colspan,
        startColspan,
        getTableEntryData,
        tableColumns,
        getTableEntry,
        inputValueChanged,
        rowValueChanged,
        updateFormData
      }
    }
  })
</script>

<style lang="scss">
  .slot-action {
    .data-table-actions {
      @apply flex items-center justify-center mx-2;
      .data-table-action {
        @apply cursor-pointer hover:text-opacity-50;
        &:hover {
          opacity: 0.5;
        }
        &:not(:first-child) {
          @apply ml-4;
        }
      }
    }
  }
  .btn-add {
    display: flex !important;
  }
</style>
