import { Document } from './document'
import { Event, OptionSunset, OptionSunsetPart } from './events'
import { PARITYTYPE, TransferParts } from '@/types'
import {
  VESTINGVALUEUNIT,
  VestingPlanning,
  VestingAdjustment,
  CommitmentTypeEnum,
  OptionExercisePart
} from '@/types/forms'
import { IntegrationType, STOCKTYPE } from '@/types/config'
import { CONTACTTYPE } from '@/types/forms'
import { Role } from './user'
import { CommonCeiling } from './events'
import { PostContractBehavior, CapitalReductionReasonEnum } from '@/types/forms'
import { AppointmentTerminationReason } from '@/utils'
import { EventType } from '@/types/event'
import { TimeUnit } from '../../../types/config'
import { User } from 'vue-simple-acl'

export interface Exercice {
  timestamp: string
  quantity: number
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface NominalValue {
  id: string
  nominalValue: number
  isConstantCapital: boolean
  byParity: boolean
  date: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface ParityChange {
  id: string
  date: string
  optionAlias: OptionStockAlias
  parity: number
  parityType: PARITYTYPE
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface CompanyContact {
  uid: string
  name: string
  siren: string
  siret: string
  commercialName: string
  denomination: string
  immatriculation: string //registration
  address: string
  additionalAddress: string
  formType: string
  creationDate: Date
  postalCode: string
  city: string
  country: string
  nationality: string
}

export interface CompanyModel {
  name: string
  denomination?: string
  address: string
  postalCode: string
  additionalAddress?: string
  commercialName?: string
  country: string
  city: string
  id: string
  email?: string
  website?: string
  constitutedAt: string
  siren: string
  siret: string
  rcsCity?: string
  hideTransactionsTabForEmployees: boolean
  hideStocksTabForEmployees: boolean
  contactCanSimulatePotentialGain: boolean
  populateSimulationForm?: boolean
  includeCommitmentsTabForEmployees: boolean
  capital: number
  birthDate: string
  phoneNumber: string
  legalStatus: LegalStatus
  picture?: Picture
  tva: string
  ape: string
  isActive: boolean
  createdAt: Date
  updatedAt: Date
}

export enum ThirdPartyApplicationStatus {
  sandbox = 'SANDBOX',
  production = 'PRODUCTION'
}

export interface ThirdPartyApplication {
  id: string
  name: string
  description: string
  lastTokenDate?: string
  clientSecret: string
  clientId: string
  owner: User
  status: ThirdPartyApplicationStatus
  companies: Company[]
}

export interface CompanyGlobalStockData {
  nominalValue: number
  totalFD: number
  totalNFD: number
  shareCapital: number
  totalIssuancePrimes: number
  numberOfShareHolders: number
}

export interface CompanyCapitalByGroup {
  value: number
  groupName: string
}

export interface CompanyCapitalByStockCategory {
  value: number
  categoryName: string
  categoryId: string
}

export interface CompanyProfitSharingItem {
  exercisableQuantity: number
  nonExercisableBalance: number
  exercisableBalance: number
  exercisedQuantity: number
  numberOfSunsetedOptions: number
  profitSharingPlanName: string
  profitSharingPlanId: string
}

export interface FundingData {
  raisedAmount: number
  lastStockPrice: number
  financingRoundAmount: number
}

export interface EsopData {
  sunsetedQuantity: number
  delegationAvailableBalance: number
  vestedQuantity: number
  assignedQuantity: number
  exercisedQuantity: number
  numberOfBeneficiaries: number
  exercisableBalance: number
}

export interface UserModel {
  firstName: string
  lastName: string
  email: string
  name?: string
  phoneNumber: string
  pictureUrl: string
  companies: CompanyModel[]
  lang: string
  id: string
}

export interface UserAccount {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  pictureUrl: string
  roles: Role[]
}

export interface AssociateAccount {
  order: number
  contact: Contact
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface CompanyCommittee {
  denomination: string
  creationDate: string
  minMembers: number
  maxMembers: number
  documentsId: string[]
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface CompanyGroup {
  name: string
  id: string
  colorCode: string
  contacts: Contact[]
  description: string | null
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface OrganCategory {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  version: number
  name: string
  description: string
}

export interface Company {
  name: string
  isActive: boolean
  contacts: Contact[]
  documents: Document[]
  picture?: Picture
  events: Event[]
  siret: string
  siren: string
  vat: string
  pictureUrl?: string
  id: string
  email?: string
  commercialName?: string
  phoneNumber?: string
  constitutedAt?: string
  createdAt: string
  contactGroups: ContactGroup[]
  representedContacts: Contact[]
  legalStatus: LegalStatus
  address?: string
  postalCode?: string
  city?: string
  additionalAddress?: string
  closeAt?: string
  closed: false
  updatedAt: string
  deletedAt: string
  version: number
}

export interface Contact {
  company: Company
  company_id: string
  associateAccount: AssociateAccount
  name: string
  id: string
  lastName: string
  firstName: string
  documentsCount: number
  stocksCount: number
  type: CONTACTTYPE
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
  picture?: Picture
}

export enum IntegrationStatus {
  credentialsExpired = 'CREDENTIALS_EXPIRED',
  notLinkedYet = 'NOT_LINKED_YET',
  linked = 'LINKED'
}

export interface IntegratedService {
  integrationType: IntegrationType
  id: string
  status: IntegrationStatus
  expirationTimestamp: string
}

export interface StockCategory {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  version: number
  name: string
  description: string
  inESOP: boolean
  inESOPDisabled: boolean
  inValuation: boolean
  inValuationDisabled: boolean
  inNFD: boolean
  inFD: boolean
  inNFDDisabled: boolean
  inFDDisabled: boolean
}

export interface StockAlias {
  id: string
  alias: string
  stockCategoryId: string
  simulationOnly: boolean
  totalIssued: number
  version: number
  name?: string
  type?: STOCKTYPE
  inValuation: boolean
  inNFD: boolean
  inFD: boolean
  inESOP: boolean
  commitmentParts: CommitmentPart[]
  notInTransactionsRegister: undefined
  group?: StockGroup
  class?: string
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  stockCategory: StockCategory
  colorCode: string
  canVote: boolean
  voteParity: number
}
export interface CompositionUnderlayingOption {
  id: string
  underlayingOption: OptionStockAlias
  parity: number
}
export interface CompositionStockAlias {
  id: string
  alias: string
  stockCategoryId: string
  simulationOnly: boolean
  version: number
  name?: string
  type?: STOCKTYPE
  class?: string
  inValuation: boolean
  inNFD: boolean
  notInTransactionsRegister: undefined
  commitmentParts: CommitmentPart[]
  inFD: boolean
  inESOP: boolean
  group?: StockGroup
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  stockCategory: StockCategory
  mainStockAlias: StockAlias
  underlayingOptions: Array<CompositionUnderlayingOption>
  colorCode: string
  canVote: boolean
  voteParity: number
}

export interface OptionStockAlias {
  id: string
  name?: string
  alias: string
  optionCategoryId: string
  underlayingStockId: string
  parityType: PARITYTYPE
  parity: number
  class?: string
  type?: STOCKTYPE
  simulationOnly: boolean
  version: number
  inValuation: boolean
  inNFD: boolean
  inFD: boolean
  inESOP: boolean
  group?: StockGroup
  createdAt: Date
  updatedAt: Date
  deletedAt: Date | null
  optionCategory: OptionStockCategory
  underlayingStockAlias?: StockAlias
  notInTransactionsRegister: boolean
  underlayingCompositionAlias?: CompositionStockAlias
  exercisePrices: number[]
  exerciseId?: string
  expirationTimeUnit: TimeUnit
  expirationNumberOfTimeUnits: number
  colorCode: string
  canVote: boolean
  voteParity: number
}

export interface OptionStockCategory {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string | null
  version: number
  name: string
  description: string
  inESOP: boolean
  inESOPDisabled: boolean
  inValuation: boolean
  inValuationDisabled: boolean
  inNFD: boolean
  inFD: boolean
  inNFDDisabled: boolean
  inFDDisabled: boolean
}

export interface CompanyVesting {
  id: string
  name: string
  vestingValueUnit: VESTINGVALUEUNIT
  fractionReport: boolean
  hasObjective: boolean
  vestingPeriods: VestingPlanning[]
  endOfContractBehaviors: PostContractBehavior
  optionIssuanceParts: OptionIssuancePart[]
  exerciseCalendars: ExerciseCalendar[]
  createdAt: string
  updatedAt: string
  deletedAt: string | null
}

export interface LegalStatus {
  id?: string
  name?: string
  legalStatus: string
  contactType: CONTACTTYPE
}

export interface ContactStatus {
  id?: string
  name: string
  type: CONTACTTYPE
}

export interface ContactGroup {
  id: string
  name: string
  colorCode: string
  countContacts: number
  description: CONTACTTYPE
}

export enum CoOwnershipType {
  bareProperty = 'BARE_PROPERTY',
  usufruct = 'USUFRUCT',
  fullOwnership = 'FULL_OWNERSHIP'
}

export enum GeneralMeetingType {
  age = 'AGE',
  ago = 'AGO'
  // ago_without_margin_affectation = 'AGO_WITHOUT_MARGIN_AFFECTATION',
  // ago_only_margin_affectation = 'AGO_ONLY_MARGIN_AFFECTATION'
}

export enum CommitmentMode {
  collective = 'COLLECTIVE_COMMITMENT',
  individual = 'INDIVIDUAL_COMMITMENT',
  other = 'OTHER'
}

export enum TransferReason {
  donationSharing = 'DONATION_SHARING', // Donation partage
  succession = 'SUCCESSION', // Succession
  transfer = 'TRANSFER', // Cession
  jointOwnershipTermination = 'JOINT_OWNERSHIP_TERMINATION' // Levée d'indivision
}

export enum GenderEnum {
  mr = 'MR',
  mrs = 'MRS',
  miss = 'MISS',
  other = 'OTHER'
}

export enum FamilySituationEnum {
  single = 'SINGLE',
  married = 'MARRIED',
  divorced = 'DIVORCED',
  other = 'OTHER',
  widower = 'WIDOWER'
}

export enum MaritalAgreementEnum {
  separationAsToProperty = 'SEPARATION_AS_TO_PROPERTY',
  communityOfAssets = 'COMMUNITY_OF_ASSETS',
  universalCommunity = 'UNIVERSAL_COMMUNITY',
  jointOwnership = 'JOINT_OWNERSHIP'
}

export interface Picture {
  fileName: string
  pictureIdentifier: string
  pictureURL: string
}

export interface ContactCardProps {
  name: string
  lastName?: string
  firstName?: string
  id: string
  picture?: Picture
  pictureIdentifier?: string
  documentsCount: number
  stocksCount: number
}

export interface IndividualContact {
  id: string
  type: CONTACTTYPE
  contactGroupId: string
  name: string
  firstName: string
  lastName: string
  birthDate: string
  birthPlace: string
  email?: string
  phoneNumber?: string
  website?: string
  familyBranch1?: string
  familyBranch2?: string
  address: string
  contactGroups: ContactGroup[]
  representativeContact: Contact
  picture: Picture
  status?: ContactStatus
  additionalAddress: string
  postcode?: string
  city: string
  country: string
  gender: GenderEnum
  familySituation: FamilySituationEnum
  maritalAgreement: MaritalAgreementEnum
  nationality: string
  documentsCount: number
  stocksCount: number
  internalId1?: string
  internalId2?: string
  mappingId?: string
  parents?: Contact[]
  iban?: string
  bic?: string
}

export interface CoOwner {
  id: string
  coheir: Contact
  fraction: number
}
export interface CoOwnerShipContact {
  id: string
  type: CONTACTTYPE
  contactGroupId: string
  name: string
  birthDate: string
  endDate: string
  birthPlace: string
  email?: string
  phoneNumber?: string
  coOwnershipParts: CoOwner[]
  website?: string
  address: string
  contactGroups: ContactGroup[]
  picture: Picture
  status?: ContactStatus
  additionalAddress: string
  representativeContact?: Contact
  postcode?: string
  city: string
  country: string
  gender: GenderEnum
  familySituation: FamilySituationEnum
  nationality: string
  documentsCount: number
  stocksCount: number
  internalId1?: string
  internalId2?: string
  mappingId?: string
  parents?: Contact[]
}

export interface InvestorContact {
  id?: string
  name: string
  representativeContact:
    | IndividualContact
    | ProfessionalContact
    | InvestorContact
  legalStatus: LegalStatus
  email?: string
  contactGroups?: ContactGroup[]
  status?: ContactStatus
  picture: Picture
  phoneNumber?: string
  website?: string
  type: CONTACTTYPE
  documentsCount: number
  stocksCount: number
  internalId1?: string
  internalId2?: string
  mappingId?: string
}

export interface ProfessionalContact {
  id?: string
  name: string
  tradeName: string
  representativeContact:
    | IndividualContact
    | ProfessionalContact
    | InvestorContact
  legalStatus: LegalStatus
  siret: string
  siren: string
  ape: string
  tva: string
  picture: Picture
  email?: string
  phoneNumber?: string
  birthDate: string
  website?: string
  type: CONTACTTYPE
  address: string
  postcode: string
  contactGroups?: ContactGroup[]
  city: string
  country: string
  nationality: string
  additionalAddress?: string
  status?: ContactStatus
  documentsCount: number
  stocksCount: number
  internalId1?: string
  internalId2?: string
  mappingId?: string
}

export interface ContactAudit {
  comment: string
  id: string
  motive: string
  processed: boolean
  contact: Contact
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}
export interface AppointmentTermination {
  appointment: Appointment
  id: string
  reason: AppointmentTerminationReason
  endDate: string
  event: Event
}
export interface Appointment {
  startDate: string
  endDate?: string
  durationInDays?: number
  accountingExerciseYear?: string
  contact: Contact
  eventType: string
  function: UpFunction
  committee: Committee
  company: Company
  id: string
  isUnlimited: boolean
  appointmentTermination: AppointmentTermination | null
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}
export interface CommitmentPart {
  id: string
  compositionAlias?: CompositionStockAlias
  stockAlias?: StockAlias
  contact: Contact
  quantity: number
  commitment?: Commitment
  ownershipType: CoOwnershipType
}
export interface Commitment {
  id: string
  date: string
  endDate: string
  label: string
  mode: CommitmentMode
  type: CommitmentTypeEnum
  commitmentParts: CommitmentPart[]
  documents?: Document[]
}
export interface CapitalReductionPart {
  id: string
  contact: Contact
  stockAlias?: StockAlias
  optionAlias?: OptionStockAlias
  compositionAlias?: CompositionStockAlias
  quantity: number
  totalPrice: number
  documents?: Document[]
  entryDate: string
}
export interface CapitalReduction {
  id: string
  reason: CapitalReductionReasonEnum
  issuanceDate: string
  capitalReductionParts: CapitalReductionPart[]
}

export interface UpFunction {
  name: string
  appointments: Appointment[]
  isRelatedToCommittee: boolean
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface Committee {
  creationDate: string
  denomination: string
  name: string
  label: string
  maxMembers: number
  minMembers: number
  company: Company
  appointments: Appointment[]
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface Transfer {
  transferDate: string
  reason: TransferReason
  transferParts: TransferParts[]
  company: Company
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}
export interface OptionIssuance {
  id: string
  issuanceDate: string
  entryDate: string
  delegation?: Delegation
  profitSharingPlan?: ProfitSharingPlan
  optionAlias: OptionStockAlias
  exercisePrice: number
  financingRound?: FundRaisingRound
  optionIssuanceParts: OptionIssuancePart[]
  company: Company
}
export interface StockIssuancePart {
  id: string
  stockIssuance: StockIssuance
  contact: Contact
  quantity: number
  releasedAmount: number
  unitPrice: number
  totalPrice: number
  documents?: Document[]
  issuancePremiumPerAction: number
  totalIssuancePremium: number
  nominalIncrease: number
}

export interface StockIssuance {
  id: string
  issuanceDate: string
  entryDate: string
  delegation?: Delegation
  financingRound?: FundRaisingRound
  profitSharingPlan?: ProfitSharingPlan
  stockAlias: StockAlias
  stockIssuanceParts?: StockIssuancePart[]
}

export interface CompositionIssuance {
  id: string
  issuanceDate: string
  delegation?: Delegation
  financingRound?: FundRaisingRound
  compositionAlias: StockAlias
  compositionIssuanceParts?: CompositionIssuancePart[]
}

export interface CompositionIssuancePart {
  id: string
  compositionIssuance: CompositionIssuance
  contact: Contact
  quantity: number
  releasedAmount: number
  totalPrice: number
  unitPrice: number
  documents?: Document[]
  issuancePremiumPerAction: number
  totalIssuancePremium: number
  nominalIncrease: number
  entryDate: string
}

export interface OptionIssuancePart {
  id: string
  optionIssuance: OptionIssuance
  contact: Contact

  quantity: number
  releasedAmount: number
  unitPrice: number
  totalPrice: number
  documents?: Document[]
  issuancePremiumPerAction: number
  totalIssuancePremium: number
  nominalIncrease: number
  vestingCalendar: CompanyVesting
  vestingStartDate: string
}

export interface Delegation {
  date: string
  endDate: string
  stockAlias: StockAlias
  optionAlias: OptionStockAlias
  compositionAlias: CompositionStockAlias
  commonCeiling: CommonCeiling
  stockCategory?: StockCategory
  optionCategory?: OptionStockCategory
  isForCompositionAlias: boolean
  applyRevolving: boolean
  company: Company
  label: string
  name?: string
  quantity: number
  eventType: string
  documents: Document[]
  delegationSunset?: DelegationSunset
  stockIssuances: StockIssuance[]
  optionIssuances: OptionIssuance[]
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  version: number
}

export interface DelegationSunset {
  id: string
  date: string
  delegationId: string
  delegation: Delegation
  event: Event
}

export interface DelegationTransaction {
  associateAccount: AssociateAccount
  compositionAlias?: CompositionStockAlias
  compositionIssuancePart?: CompositionIssuancePart
  optionAlias?: OptionStockAlias
  optionIssuancePart?: OptionIssuancePart
  stockAlias?: StockAlias
  stockIssuancePart?: StockIssuancePart
  eventType: EventType
  quantity: number
}
export interface VestingDelayProrogation {
  startDate: string
  contacts: Contact[]
  optionAlias: OptionStockAlias[]
  endDate: string
  timeUnit: string
  timeQuantity: number
  id: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  version: number
}

export interface VestingAcceleration {
  startDate: string
  contacts?: Contact[]
  optionAlias?: OptionStockAlias[]
  percent: number
  adjustmentType: VestingAdjustment
  company: Company
  id: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  version: number
}

export interface VestingSuspension {
  company: Company
  startDate: string
  contacts?: Contact[]
  optionAlias?: OptionStockAlias[]
  endDate?: string
  timeUnit?: string
  timeQuantity?: number
  id: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  version: number
}

export enum TransactionType {
  DEBIT = 'DEBIT',
  CREADIT = 'CREDIT'
}
export interface Transaction {
  transactionType: TransactionType
  quantity: number
  stockAlias: StockAlias | null
  optionAlias: OptionStockAlias | null
  compositionAlias: CompositionStockAlias | null
  associateAccount: AssociateAccount
  optionIssuancePart: OptionIssuancePart
  stockIssuancePart: StockIssuancePart
  id: string
  eventType: EventType
  eventId: string
  date: string
  version: number
  createdAt: Date
  updatedAt: Date
}

export interface FinancialEnding {
  date: string
  event?: Event
  company: Company
  id: string
  createdAt: string
  updatedAt: string
  deletedAt?: string
  version: number
}

export interface FundRaisingRound {
  id: string
  name: string
  colorCode: string
  description?: string
  totalAmount: number
  stockIssuance?: StockIssuance[]
  optionIssuances?: OptionIssuance[]
  compositionIssuance?: CompositionIssuance[]
  createdAt: string
}

export interface Operation {
  id: string
  name: string
  colorCode: string
  description?: string
}

export interface ProfitSharingPlan {
  id: string
  name: string
  colorCode: string
  description?: string
  optionIssuances?: OptionIssuance[]
}

export interface StockGroup {
  id: string
  name: string
  colorCode: string
  description?: string
  stockAliases?: StockAlias[]
  optionAliases?: OptionStockAlias[]
  compositionAliases?: CompositionStockAlias[]
}
export interface VestingPeriodAcceleration {
  quantity: number
  period: ExercicePeriod
  acceleration: VestingAcceleration
  createdAt: string
}
export interface ExercicePeriod {
  id: string
  startDate: string
  endDate: string
  createdAt: string
  quantity: number
  reportedQuantity: number
  vestingPeriod: VestingPlanning
  periodAccelerations: VestingPeriodAcceleration[]
  cumulativeQuantity: number
  isVestedPeriod: boolean
  isLastVestedPeriod: boolean
}
export interface SunsetQuantity {
  optionSunset: OptionSunset
  sunsetExercisableQuantity: number
  sunsetNonExercisableQuantity: number
  exerciseCalendar: ExerciseCalendar
}
export interface ExerciseCalendar {
  id: string
  exercisePeriods: ExercicePeriod[]
  contact: Contact
  optionIssuancePart: OptionIssuancePart
  optionSunsetParts?: OptionSunsetPart[]
  optionExerciseParts?: OptionExercisePart[]
  optionAlias: OptionStockAlias
  vestingCalendar: CompanyVesting
  sunsetQuantities: SunsetQuantity[]
  createdAt: string
  exercisableQuantity: number
  exercisableBalance: number
  exercisedQuantity: number
  vestedQuantity: number
  notVestedQuantity?: number
  obsoleteQuantity: number
  numberOfSunsetedOptions: number
  lastStockPrice: number
  potentialGain100Percent: number
  portentialGainAtDate: number
}

export interface UserStock {
  id: string
  name: string
  quantityFD: number
  quantityNFD: number
  type: string
}

export interface ContactData {
  options: OptionStockAlias[]
  stocks: UserStock[]
}

export interface ContactAvailableData {
  [name: string]: ContactData
}

export interface VestingSynthesis {
  assignedAmount?: number
  assignedQuantity: number
  exercisableQuantity: number
  exercisePrice: number
  exercisedQuantity: number
  lastStockPrice: number
  exercisableBalance: number
  numberOfSunsetedOptions: number
  optionAlias: string
  optionAliasId: string
  vestedQuantity: number
  nonVestedQuantity?: number
  potentialGain100Percent: number
  portentialGainAtDate: number
}

export interface CompanyOrganigram {
  date: string
  companiesId: string[]
  topCompanyId: string
  dilution: OrganigramDilutionEnum
}

export enum OrganigramDilutionEnum {
  inNFD = 'NFD',
  inFD = 'FD'
}
