
  import { defineComponent, computed, toRefs, ref, watch, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import DataTable from '@/components/DataTable/index.vue'
  import EventActions from '@/components/Event/EventActions.vue'
  import Delegation from './types/delegation.vue'
  import moment from 'moment'
  import Ceiling from './types/ceiling.vue'
  import Transfer from './types/transfer.vue'
  import Nomination from './types/nomination.vue'
  import Reduction from './types/reduction.vue'
  import Vesting from './types/vesting.vue'
  import Acceleration from './types/acceleration.vue'
  import OptionExercise from './types/exercise.vue'
  import NominalValue from './types/nominalValue.vue'
  import FinancialEnding from './types/finacialEnding.vue'
  import StockIssuance from './types/stockIssuance.vue'
  import OptionIssuance from './types/optionIssuance.vue'
  import CompositionIssuance from './types/compositionIssuance.vue'
  import AppointmentTermination from './types/appointmentTermination.vue'
  import Conversion from './types/conversion.vue'
  import ParityChange from './types/parityChange.vue'
  import Sunset from './types/sunset.vue'
  import Approval from './types/approval.vue'
  import DelegationSunset from './types/delegationSunset.vue'
  import Commitment from '@/components/Event/types/commitment.vue'
  import { CardType } from '@/types/config'
  import EventDocumentsCard from './EventDocumentsCard.vue'
  import { Event } from '@/services/api/models'
  import { EventType } from '@/types/event'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import Loader from '../Loader/index.vue'
  export default defineComponent({
    name: 'EventCard',
    components: {
      EventActions,
      Acceleration,
      Delegation,
      DelegationSunset,
      Sunset,
      OptionIssuance,
      StockIssuance,
      ParityChange,
      Ceiling,
      Transfer,
      Nomination,
      Vesting,
      Approval,
      Reduction,
      OptionExercise,
      NominalValue,
      FinancialEnding,
      EventDocumentsCard,
      DataTable,
      AppointmentTermination,
      Conversion,
      Loader,
      Commitment,
      CompositionIssuance
    },
    props: {
      event: {
        type: Object as () => Event,
        default: () => ({})
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      collapsed: {
        type: Boolean,
        default: false
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    emits: ['select-card', 'collapse', 'updated'],
    setup(props, { emit }) {
      const { event } = toRefs(props)
      const currentEvent = ref(props.event)
      const defaultFilters = computed(() => props.filters)
      const isRemoved = ref(false)
      const isLoading = ref(false)
      const { t } = useI18n()
      const store = useStore()
      const route = useRoute()
      const displayed = ref(!props.collapsed)
      const currentSide = ref(CardType.EVENT)
      const toggleAudit = ref(false)
      const isSelected = ref(false)
      const nominalValue = ref(0)

      const collapseCard = () => {
        emit('collapse', !displayed.value)
      }
      // TODO: WTF IS THIS???????? Reduce on an empty array??????
      const elements = computed<any>(() => {
        const els = [].reduce((acc: any, item: any) => {
          acc = acc.concat(
            item.filter((item: any) => !item?.isScalar ?? true) ?? []
          )
          return acc
        }, [])
        return [
          ...new Map(
            els.map((item: any) => [JSON.stringify(item), item])
          ).values()
        ]
      })
      // TODO: WTF IS THIS???????? Reduce on an empty array??????
      const scalarElements = computed(() => {
        return [].reduce((acc: any, item: any) => {
          acc.push(item.filter((item: any) => item.isScalar) ?? [])
          return acc
        }, [])
      })

      const tableColumns = (item: any) => {
        return item.reduce((acc: any, item: any) => {
          acc.push({
            label: t(`events.elements.${item.name}`),
            field: item.name,
            width: item.width ?? '10%',
            sortable: item.sortable ?? true,
            custom: item.custom ?? false,
            customDefault: item.customDefault ?? false
          })
          return acc
        }, [])
      }

      const getValue = (value: string | undefined) => {
        return value && value.charAt(0) === '{'
          ? JSON.parse(value)
          : value ?? ''
      }

      const parseValue = (val: string | undefined) => {
        const value = getValue(val)
        return typeof value === 'object'
          ? value.name ||
              (typeof value.key === 'string' ? value.key : value.key.name)
          : value
          ? value
          : '-'
      }

      const data = computed(() => {
        return scalarElements.value.reduce((acc: any, item: any) => {
          const el = item.reduce((acc2: any, item2: any) => {
            acc2[item2.name] = parseValue(item2.value)
            return acc2
          }, {})
          acc.push(el)
          return acc
        }, [])
      })

      const getEvent = () => {
        let module = 'organizations'
        if (props.isPortfolio) module = 'portfolio'
        isLoading.value = true
        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId: props.event?.id,
          type: props.event?.type,
          onSuccess: (data: Event) => {
            isLoading.value = false
            event.value = { ...data }
            currentEvent.value = { ...data }
          },
          onError: (error: any) => {
            isLoading.value = false
          }
        })
      }

      const toggleSide = (what: CardType) => {
        currentSide.value = what
      }

      const selectCard = (data: { check: boolean; id: string }) => {
        isSelected.value = data.check
        emit('select-card', data)
      }

      const onRemoved = (id: string) => {
        isRemoved.value = true
      }

      const calculateNominalValue = async () => {
        const value = await store.dispatch(
          'organizations/COMPUTE_NOMINAL_VALUE',
          { date: event.value?.date, setAsGlobal: false }
        )
        if (value?.nominalValue) nominalValue.value = value?.nominalValue
      }

      watch(
        () => props.collapsed,
        (val) => {
          displayed.value = !val
        }
      )

      watch(
        () => displayed.value,
        (val) => {
          if (val) {
            getEvent()
          }
        }
      )

      onMounted(async () => {
        isLoading.value = true
        calculateNominalValue()
        if (!props.collapsed) {
          getEvent()
        }

        isLoading.value = false
      })

      return {
        displayed,
        collapseCard,
        currentSide,
        toggleAudit,
        isSelected,
        elements,
        isLoading,
        scalarElements,
        currentEvent,
        nominalValue,
        tableColumns,
        parseValue,
        data,
        isRemoved,
        defaultFilters,
        toggleSide,
        selectCard,
        moment,
        onRemoved,
        CardType,
        EventType
      }
    }
  })
