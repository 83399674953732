
  import { defineComponent, onMounted, ref } from 'vue'
  import { Button, Input } from '@up.law/uplaw-ui'
  import Modal from '@/components/Modal/Modal.vue'
  import { Field } from 'vee-validate'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'
  import { useStore } from 'vuex'
  import { ThirdPartyApplication } from '@/services/api/models'
  import { ElPopconfirm } from 'element-plus'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  export default defineComponent({
    components: {
      DropdownCompany,
      Button,
      Modal,
      Input,
      Field,
      ElPopconfirm
    },
    setup() {
      const store = useStore()
      const { t } = useI18n()
      const showModal = ref(false)
      const newApp = ref({ name: '', companyIds: [], description: '' })
      const availableApps = ref<ThirdPartyApplication[]>([])

      const isLoading = ref(true)
      const showEdition = ref(false)
      const handleDisplay = () => {
        showModal.value = !showModal.value
      }

      const selectedApp = ref<any>(null)

      const handleEditionDisplay = () => {
        showEdition.value = !showEdition.value
      }

      const startEdition = (app: any) => {
        selectedApp.value = {
          ...app,
          companyIds: app.companies.map((x: any) => x.id)
        }

        delete selectedApp.value.companies

        console.log(selectedApp.value)

        handleEditionDisplay()
      }
      const createApplication = () => {
        store.dispatch('auth/CREATE_APPLICATION', {
          data: newApp.value,
          onSuccess: () => {
            handleDisplay()
            getApps()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_creation')
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t('account.applications.error_creation')
            })
          }
        })
      }

      const editApplication = () => {
        store.dispatch('auth/EDIT_APPLICATION', {
          applicationId: selectedApp.value.id,
          data: selectedApp.value,
          onSuccess: () => {
            handleEditionDisplay()
            selectedApp.value = null
            getApps()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_edition')
            })
          },
          onError: (err: any) => {
            let message = 'error_edition'
            if (err.statusCode == 403) message = 'error_403_app_edition'
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t(`account.applications.${message}`)
            })
          }
        })
      }

      const getApps = () => {
        store.dispatch('auth/GET_APPLICATIONS', {
          filter: { companyIds: [] },
          onSuccess: (data: ThirdPartyApplication[]) => {
            isLoading.value = false

            availableApps.value = [...data]
          },
          onError: () => {
            //
          }
        })
      }

      const deleteApp = (appId: string) => {
        store.dispatch('auth/DELETE_APPLICATION', {
          applicationId: appId,
          onSuccess: () => {
            getApps()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_deletion')
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t('account.applications.error_deletion')
            })
          }
        })
      }

      onMounted(() => {
        getApps()
      })

      return {
        showModal,
        newApp,
        availableApps,
        isLoading,
        showEdition,
        selectedApp,
        startEdition,
        createApplication,
        handleDisplay,
        deleteApp,
        editApplication,
        handleEditionDisplay
      }
    }
  })
