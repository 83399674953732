
  import { computed, defineComponent, onMounted, ref, toRefs } from 'vue'
  import { Button } from '@up.law/uplaw-ui'
  import { CompanyModel, Document } from '@/services/api/models'
  import { AuditForm, AuditType, DocumentAuditAnomalies } from '@/types/forms'
  import moment from 'moment'
  import { Form } from 'vee-validate'
  import UplAuditForm from '@/components/Forms/Audit/index.vue'
  import { useStore } from 'vuex'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import { CardType } from '@/types/config'
  import {
    ElTabs,
    ElTabPane,
    ElTableColumn,
    ElTable,
    ElPopover,
    ElPopconfirm
  } from 'element-plus'

  export default defineComponent({
    name: 'DocumentActions',
    components: {
      Form,
      UplAuditForm,
      Button,
      ElTabs,
      ElTabPane,
      ElTableColumn,
      ElPopconfirm,
      ElPopover,
      ElTable
    },
    props: {
      item: {
        type: Object as () => Document,
        required: true
      },
      side: {
        type: Number,
        default: CardType.EVENT
      },
      audit: {
        type: Boolean,
        default: false
      },
      isPortfolio: {
        type: Boolean,
        default: false
      }
    },
    emits: ['toggle-side', 'select-card'],
    setup(props, { emit }) {
      const { side, audit, item } = toRefs(props)
      const router = useRouter()
      const route = useRoute()
      const itemId = computed(() => item.value.id)
      const document = computed(() => item.value)
      const currentSide = ref(side.value)
      const toggleAudit = ref(audit.value)
      const documentAudit = ref<AuditForm>()
      const store = useStore()
      const { t } = useI18n()
      const currentTab = computed<string>(() =>
        (item.value?.docAudits || [])?.length === 0 ? 'newAudit' : 'audits'
      )

      const companyImage = ref<null | string>()

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const toggleSide = (what: CardType) => {
        currentSide.value = what
        emit('toggle-side', currentSide.value)
      }

      const selectCard = (e: boolean) => {
        emit('select-card', {
          check: e,
          id: itemId.value
        })
      }

      const removeDocument = (e: any) => {
        store.dispatch('organizations/DELETE_COMPANY_DOCUMENT', {
          companyId: currentCompany.value.id,
          documentId: document.value.id,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('documents.context'),
              message
            })
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('documents.context'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }

      const handleAuditFormChange = (values: AuditForm) => {
        documentAudit.value = values
      }

      const navigateToDocumentForm = (id: string, companyId?: string) => {
        router.push({
          name: 'companyDocuments',
          params: { id: route.params.id || companyId, documentId: id }
        })
      }

      const handleAuditForm = () => {
        const formData = {
          motive: documentAudit.value?.motives?.join(','),
          processed: false,
          comment: documentAudit.value?.comment
        }
        store.dispatch('organizations/CREATE_AUDIT', {
          companyId: currentCompany.value.id,
          type: AuditType.DOCUMENT,
          typeDataId: document.value.id,
          formData,
          onSuccess: () => {
            store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
              companyId: currentCompany.value.id,
              filter: {}
            })
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('audits.documents'),
              message: t(`audits.editSuccess`)
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('audits.documents'),
              message: t(`audits.editError`)
            })
          }
        })
      }

      const loadImage = async () => {
        if (!props.item?.company?.id) return
        if (
          store.getters['portfolio/companiesLogos'][props.item?.company?.id]
        ) {
          companyImage.value =
            store.getters['portfolio/companiesLogos'][props.item?.company?.id]
        } else {
          const response = await store.dispatch(
            `organizations/LOAD_COMPANY_LOGO`,
            {
              companyId: props.item.company?.id
            }
          )
          if (response) {
            companyImage.value = response
            store.commit('portfolio/SET_COMPANIES_LOGOS', {
              companyId: props.item.company?.id,
              value: response
            })
          }
        }
      }

      onMounted(() => {
        if (props.isPortfolio) loadImage()
      })

      return {
        itemId,
        currentSide,
        toggleAudit,
        document,
        DocumentAuditAnomalies,
        currentTab,
        CardType,
        companyImage,
        AuditType,
        toggleSide,
        selectCard,
        removeDocument,
        handleAuditFormChange,
        handleAuditForm,
        navigateToDocumentForm,
        moment
      }
    }
  })
