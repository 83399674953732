<template>
  <div class="w-full" style="padding-bottom: 200px">
    <div class="flex w-full px-5">
      <el-steps :active="currentStep" class="w-full my-5">
        <el-step :title="$t('tabs.templatesDefinition')" />
        <el-step :title="$t('tabs.configTemplate')" @click="handleConfigStep" />
        <el-step :title="$t('tabs.testTemplate')" />
      </el-steps>
    </div>
    <div class="w-full h-full flex">
      <div :class="`${currentStep > 0 ? 'w-3/4' : 'w-3/5'} h-full`">
        <TemplateImport
          v-if="currentStep == 0"
          @on-updated="currentStep = 1"
          @remove-template="removeTemplate"
        />
        <TemplateConfig
          v-else-if="currentStep == 1"
          @on-updated="onUpdated"
          :template-data="
            updatedTemplate?.id ? updatedTemplate : selectedTemplate
          "
          :eventType="
            (updatedTemplate?.id ? updatedTemplate : selectedTemplate)
              ?.eventType
          "
          @remove-template="removeTemplate"
        />
        <Test
          v-else-if="currentStep == 2"
          :parts="parts"
          :template-data="selectedTemplate"
          :eventType="selectedTemplate?.eventType"
          @remove-template="removeTemplate"
          @update-link="updatePdfLink"
          @startTest="startTest"
        />
      </div>
      <div
        v-if="currentStep == 2"
        :class="`${
          currentStep > 0 ? 'w-2/3' : 'w-1/2'
        }  flex justify-end h-full`"
      >
        <div class="w-11/12 h-full">
          <div class="bg-secondary1 rounded h-full">
            <div class="flex flex-col items-between h-full">
              <div
                class="h-full flex flex-grow justify-center items-center w-full"
              >
                <div class="h-full w-full flex justify-center items-center">
                  <div v-if="isLoadingFile"><Loader /></div>
                  <div v-else-if="!isLoadingFile" class="w-full h-full p-3">
                    <div
                      v-if="displayMessage"
                      class="w-full flex items-center justify-center"
                    >
                      <p class="text-md">
                        {{ $t('docAutomation.form.templateMessage') }}
                      </p>
                    </div>
                    <PDFViewer
                      v-else
                      :page="0"
                      searched-string="t"
                      :url="docLink ?? selectedTemplate?.documentURL"
                      :document-id="docLink ?? selectedTemplate?.documentURL"
                      @updateCurrentPage="$emit('update-current-page', $event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { ElStep, ElSteps } from 'element-plus'
  import TemplateImport from './importTemplate.vue'
  import TemplateConfig from './config.vue'
  import Test from './testTemplate.vue'
  import { DocAutoTemplate } from '@/store/modules/Organization/docAutoModel'
  import { useStore } from 'vuex'
  import PDFViewer from '@/components/PDFViewer/express.vue'
  import { useRoute } from 'vue-router'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import Loader from '@/components/Loader/index.vue'

  export default defineComponent({
    components: {
      PDFViewer,
      ElStep,
      TemplateImport,
      TemplateConfig,
      Test,
      Loader,
      ElSteps
    },
    setup() {
      const { t } = useI18n()
      const selectedFile = ref(null)
      const docLink = ref<string | undefined>('')
      const parts = ref<any[]>([])
      const store = useStore()
      const route = useRoute()
      const displayMessage = ref(true)
      const isLoadingFile = ref(false)

      const currentStep = ref(0)

      const updatedTemplate = ref<DocAutoTemplate | null>()

      const selectedTemplate = computed<DocAutoTemplate | null>(
        () => store.getters['organizations/template']
      )

      const startTest = (value: boolean) => {
        isLoadingFile.value = value
      }

      const handleEvent = (param: string) => {
        removeTemplate(param)
      }

      const updatePdfLink = (link: any) => {
        docLink.value = link
        displayMessage.value = false
        isLoadingFile.value = false
      }

      const onUpdated = (newTemplate: any) => {
        updatedTemplate.value = newTemplate
        currentStep.value = 2
        displayMessage.value = true
      }

      const removeTemplate = (id: string) => {
        store.dispatch('organizations/REMOVE_TEMPLATE', {
          companyId: route.params.id,
          templateId: id,
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.success_delete`)
            })

            currentStep.value = 0
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('docAutomation.form.title'),
              message: t(`docAutomation.apiResponse.error_delete`)
            })
          }
        })
      }

      const handleConfigStep = () => {
        if (selectedTemplate.value?.id) currentStep.value = 1
      }

      const getEvents = () => {
        if (!selectedTemplate.value) return
        store.dispatch('organizations/GET_EVENTS_PARTS', {
          companyId: route.params.id,
          filter: { eventTypes: [selectedTemplate.value?.eventType] },
          onSuccess: (response: any) => {
            console.log(response)

            parts.value = [...(response ?? [])]
          }
        })
      }

      watch(
        () => selectedTemplate.value,
        (val) => {
          getEvents()

          if (val?.templateFields.some((x) => x.eventField == null))
            currentStep.value = 1
          else if (!val) currentStep.value = 0
          else currentStep.value = 2
          updatedTemplate.value = null
          docLink.value = val?.documentURL
        }
      )

      return {
        selectedFile,
        currentStep,
        parts,
        docLink,
        updatedTemplate,
        displayMessage,
        isLoadingFile,
        selectedTemplate,
        removeTemplate,
        handleEvent,
        startTest,
        onUpdated,
        handleConfigStep,
        updatePdfLink
      }
    }
  })
</script>
