
  import { defineComponent, ref, computed, watch, onMounted } from 'vue'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { Form, Field, useForm } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { useStore } from 'vuex'
  import { Company, ContactStatus, LegalStatus } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { CompanyModelForm, CONTACTTYPE } from '@/types'
  import UplDatePicker from '@/components/DatePicker/index.vue'
  import { CompanyModel } from '@/services/api/models1'
  import ProfileAvatarForm from '@/components/Forms/Configurable/Contact/ProfileAvatarForm.vue'
  import { comapnySchema } from '@/utils/schema/contact'
  import { TYPE, useToast } from 'vue-toastification'
  import AddressInput from '@/components/Address/index.vue'
  import { AddressData } from '@/types/config'
  import Checkbox from '@/components/Checkbox/index.vue'

  export default defineComponent({
    components: {
      Form,
      Checkbox,
      Field,
      Multiselect,
      Button,
      Input,
      UplDatePicker,
      ProfileAvatarForm,
      AddressInput
    },
    props: {
      canSubmit: {
        type: Boolean,
        required: true
      },
      mode: {
        type: String,
        required: false,
        default: 'create'
      },
      company: {
        type: Object as () => CompanyModelForm,
        required: false,
        default: () => ({})
      }
    },
    emits: ['submitForm', 'saved', 'previousStep', 'toggleModal'],
    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const toast = useToast()
      const fileToUpload = ref<File | null>(null)
      const getLegalStatus = () => {
        store.dispatch('toolbox/GET_LEGAL_STATUS')
      }
      const companiesLogos = computed(
        () => store.getters['organizations/companiesLogo']
      )
      const { validate } = useForm()

      const canSubmitForm = ref(props.canSubmit)

      const newContact = ref<CompanyModelForm>(
        props.company || {
          email: '',
          constitutedAt: '',
          phoneNumber: '',
          website: '',
          address: null,
          additionalAddress: '',
          country: '',
          lastPricePerAction: '',
          city: '',
          rcsCity: '',
          hideStocksTabForEmployees: false,
          hideTransactionsTabForEmployees: false,
          contactCanSimulatePotentialGain: false,
          populateSimulationForm: false,
          includesActionsInSimulation: false,
          postalCode: '',
          name: '',
          siren: '',
          tva: null,
          siret: '',
          commercialName: '',
          legalStatusId: null
        }
      )
      const companyLogoUrl = computed(
        () => companiesLogos.value[newContact.value?.id || '']
      )

      const contacts = computed<CompanyModel[]>(
        () => store.getters['organizations/contacts']
      )

      const legalStatus = computed<LegalStatus[]>(
        () => store.getters['toolbox/legalStatus']
      )
      const professionalLegalStatus = computed<LegalStatus[]>(() =>
        legalStatus.value.filter(
          (x) => x.contactType === CONTACTTYPE.PROFESSIONAL
        )
      )

      const contactGroups = computed<ContactStatus[]>(
        () => store.getters['toolbox/statusGroups']
      )
      const status = computed(() =>
        contactGroups.value.filter((x) => x.type === CONTACTTYPE.PROFESSIONAL)
      )
      const schema = comapnySchema

      const handleForm = async (formData: CompanyModelForm) => {
        let action = 'CREATE_NEW_COMPANY'

        let formProps = {
          data: {
            ...newContact.value,
            phoneNumber: formData.phoneNumber || '',
            legalStatusId: formData.legalStatusId || null,
            lastPricePerAction:
              parseFloat(formData?.lastPricePerAction ?? '') || null
          },
          companyId: ''
        }

        if (props.mode === 'edit') {
          action = 'UPDATE_COMPANY'
          formProps.companyId = props.company.id ?? ''
        } else {
          displayNotification()
        }
        updateProfileImage()
        await store.dispatch(`organizations/${action}`, {
          ...formProps,
          onError: (error = null) => {
            toast.clear()
            const message = error
              ? t(`newCompany.apiResponse.${error}`)
              : t(
                  `newCompany.apiResponse.${
                    props.mode === 'edit' ? 'edit' : 'create'
                  }Error`
                )
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('newCompany.title'),
              message
            })
          },
          onSuccess: (data: Company) => {
            emit('toggleModal', false)
            toast.clear()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('newCompany.title'),
              message: t(
                `newCompany.apiResponse.${
                  props.mode === 'edit' ? 'edited' : 'created'
                }`
              ),
              loader: true,
              loadingIcon: false
            })
            handleReset()
            setTimeout(() => {
              toast.clear()
            }, 5000)
            emit('saved', data)
          }
        })
      }

      const updateProfileImage = () => {
        if (!fileToUpload.value) return
        const form = new FormData()
        form.append('fileName', fileToUpload.value?.name)
        form.append('file', fileToUpload.value)
        store.dispatch('organizations/UPDATE_COMPANY_LOGO', {
          companyId: props.company.id,
          form,
          onProgress: () => {
            console.log()
          },
          onSuccess: () => {
            fileToUpload.value = null
            store.commit('organizations/SET_COMPANY_LOGO', {
              companyId: props.company.id,
              url: null
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('contactBook.form.title'),
              message: t(`contactBook.form.notSaved`)
            })
          }
        })
      }

      const displayNotification = () => {
        store.dispatch('toolbox/displayToast', {
          type: TYPE.INFO,
          context: t('newCompany.title'),
          message: t('newCompany.loadingAdditionalData'),
          loader: true,
          loadingIcon: true
        })
      }

      const onAddressInput = ($event: AddressData) => {
        newContact.value.postalCode = $event.postalCode
        newContact.value.city = $event.city
        newContact.value.country = $event.country
        newContact.value.address = $event.address
      }

      const handleReset = () => {
        // newContact.value = newValues()
      }

      const fileChanged = (file: File) => {
        fileToUpload.value = file
      }

      watch(
        () => props.canSubmit,
        (canSubmit) => {
          if (canSubmitForm.value) validate()
          canSubmitForm.value = false
        }
      )

      watch(props, (newProps) => {
        if (newProps.company) {
          newContact.value = { ...newContact.value, ...newProps.company }
          newContact.value.commercialName = newProps.company?.name
          ;(newContact.value.constitutedAt = newProps.company.constitutedAt
            ? newProps.company.constitutedAt?.split('T')[0]
            : ''),
            (newContact.value.legalStatusId = newProps.company.legalStatusId)
        }
      })

      onMounted(() => {
        getLegalStatus()
      })

      return {
        newContact,
        status,
        contacts,
        schema,
        companyLogoUrl,
        professionalLegalStatus,
        handleForm,
        handleReset,
        onAddressInput,
        fileChanged
      }
    }
  })
