<template>
  <div>
    <div v-if="isLoading">
      <Loader></Loader>
    </div>
    <div v-else class="captable-table mt-2">
      <DataTable
        v-if="events && events.length !== 0"
        :rows="events"
        :columns="columns"
        text-size="text-sm"
        :has-checkbox="false"
        container-class="w-full"
        cell-padding="p-0"
        ><template #contacts="{ row }">
          <div class="flex items-center justify-start">
            <router-link
              v-for="(contact, cIndex) in row.contacts"
              :key="cIndex"
              class="
                cursor-pointer
                bg-primary1 bg-opacity-50
                px-2
                py-1
                rounded-xl
                mr-3
              "
              :to="{
                name: 'company.directory',
                params: { id: $route.params.id, contactId: contact.id }
              }"
            >
              <ContactBadge
                :use-picture="true"
                :contact="{
                  id: contact.id,
                  name: contact.name,
                  picture: contact.picture,
                  stocksCount: 0,
                  documentsCount: 0
                }"
                class="flat light"
                type="light"
              />
            </router-link>
          </div>
        </template>
        <template #stock="{ row }">
          <div class="flex">
            <Tag
              v-if="row.stockAlias"
              :value="row.stockAlias.alias"
              :text="`${row.stockAlias.alias.toUpperCase()}`"
              :with-initials="false"
              :with-remove="false"
              :color="rgbToHex(row.stockAlias.colorCode)"
            />
            <Tag
              v-else-if="row.compositionAlias"
              :value="row.compositionAlias.alias"
              :text="`${row.compositionAlias.alias.toUpperCase()}`"
              :with-initials="false"
              :with-remove="false"
              :color="rgbToHex(row.compositionAlias.colorCode)"
            />
            <p v-else class="text-left">-</p>
          </div>
        </template>
        <template #label="{ row }">
          <p :class="`text-left px-2 `">
            {{ row.commitment?.label }}
          </p>
        </template>
        <template #quantity="{ row }">
          <p :class="`text-left px-2  ${row.quantity < 0 ? 'text-error' : ''}`">
            {{ formatNumber(row.quantity) || 0 }}
          </p>
        </template>

        <template #ownershipType="{ row }">
          <p :class="`text-left px-2`">
            {{ $t(`CoOwnershipTypeSlugs.${row?.ownershipType}`) }}
          </p>
        </template>

        <template #startDate="{ row }">
          <p :class="`text-left px-2`" v-if="row.commitment?.date">
            {{ moment(row.commitment?.date).format('LL') }}
          </p>
          <p v-else :class="`text-left px-2`">-</p>
        </template>
        <template #endDate="{ row }">
          <p :class="`text-left px-2`" v-if="row.commitment?.endDate">
            {{ moment(row.commitment?.endDate).format('LL') }}
          </p>
          <p v-else :class="`text-left px-2`">-</p>
        </template>
        <template #commitmentType="{ row }">
          <p :class="`text-left px-2`">
            {{ $t(`CommitmentTypeEnum.${row.commitment?.type}`) }}
          </p></template
        >
        <template #commitmentMode="{ row }">
          <p :class="`text-left px-2`">
            {{
              row.commitment?.mode
                ? $t(`CommitmentMode.${row.commitment?.mode}`)
                : '-'
            }}
          </p></template
        >

        <template #total>
          <tr>
            <td class="col-span-2" colspan="2">
              <p class="text-right font-bold text-primary8 py-3 px-2">Total</p>
            </td>
            <td>
              <p :class="`text-left font-bold text-primary8 py-3 px-2`">
                {{ formatNumber(total) }}
              </p>
            </td>

            <td>
              <p :class="`text-left font-bold text-primary8 py-3 px-2`">-</p>
            </td>
            <td>
              <p :class="`text-left font-bold text-primary8 py-3 px-2`">-</p>
            </td>
            <td>
              <p :class="`text-left font-bold text-primary8 py-3 px-2`">-</p>
            </td>
            <td>
              <p :class="`text-left font-bold text-primary8 py-3 px-2`">-</p>
            </td>
            <td>
              <p :class="`text-left font-bold text-primary8 py-3 px-2`">-</p>
            </td>
          </tr>
        </template>
      </DataTable>
      <div v-else class="flex justify-center items-center w-full mt-4">
        <EmptyState
          class="d-block mx-auto datatable"
          :title="$t('global.noResult')"
          subtitle=""
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import {
    Commitment,
    CommitmentPart,
    CompanyModel
  } from '@/services/api/models'
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { formatNumber, rgbToHex } from '@/utils'
  import moment from 'moment'
  import Loader from '@/components/Loader/index.vue'
  import DataTable from '@/components/DataTable/index.vue'

  export default defineComponent({
    components: { ContactBadge, Tag, EmptyState, Loader, DataTable },
    setup() {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()
      const events = ref<CommitmentPart[]>()
      const isLoading = ref(false)
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const total = computed(() =>
        (events.value ?? [])?.reduce((acc, x) => (acc += x.quantity), 0)
      )
      const columns = computed(() => [
        {
          label: t('datatable.column.stock'),
          field: 'stock',
          custom: true
        },
        {
          label: t('datatable.column.label'),
          field: 'label',
          custom: true
        },
        {
          label: t('datatable.column.quantity'),
          field: 'quantity',
          custom: true
        },
        {
          label: t('datatable.column.commitmentType'),
          field: 'commitmentType',
          custom: true
        },
        {
          label: t('datatable.column.commitmentMode'),
          field: 'commitmentMode',
          custom: true
        },
        {
          label: t('datatable.column.ownershipType'),
          field: 'ownershipType',
          custom: true
        },
        {
          label: t('datatable.column.startDate'),
          field: 'startDate',
          custom: true
        },
        {
          label: t('datatable.column.endDate'),
          field: 'endDate',
          custom: true
        }
      ])
      const getEvents = (contactId = route.params.contactId) => {
        store.dispatch('organizations/GET_COMMITMENTS', {
          companyId: currentCompany.value?.id,
          filter: {
            contactsId: [contactId]
          },
          onSuccess: (data: any) => {
            events.value = [...data]
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }
      onMounted(() => {
        isLoading.value = true
        getEvents()
      })
      return {
        isLoading,
        events,
        columns,
        moment,
        total,
        currentCompany,
        rgbToHex,
        formatNumber
      }
    }
  })
</script>
