
  import Folder from './folder.vue'
  import {
    computed,
    defineComponent,
    ref,
    watch,
    onMounted,
    onBeforeUnmount
  } from 'vue'
  import {
    statusFilterExtractionContactsKeys,
    statusFilterOptionsKeys
  } from '@/utils/global'
  import {
    Document,
    Document as DocumentModel,
    DocumentCategory,
    DocumentSubCategory
  } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { useStore } from '@/store'
  import { useRoute, useRouter } from 'vue-router'
  import { groupItemBy } from '@/utils'
  import { DocumentSearchProps, SelectionType } from '@/types'
  import moment from 'moment'
  import { CompanyModel } from '@/services/api/models1'
  import Filter from './filter.vue'
  import { ElTooltip } from 'element-plus'
  import ContactsTree from '@/components/ContactsTree/index.vue'

  export default defineComponent({
    components: {
      Folder,
      Filter,
      ElTooltip,
      ContactsTree
    },
    props: {
      showToggle: { type: Boolean, default: true }
    },
    emits: ['onDocumentError'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const showTreeview = ref(true)
      const showFilter = ref(true)
      const { t } = useI18n()
      const fileList = ref<DocumentModel[]>([])
      const filesLength = ref(fileList.value.length)
      const defaultDocumentType = ref<string[]>([])
      const groupTypes = ref([])
      const statusFilter = ref('')
      const documentDisplayed = ref(true)
      const sortedByType = ref(SelectionType.CATEGORY)
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const loadedDocument = ref<Document | null>()
      const documentsFromStore = computed<Document[]>(
        () => store.getters['organizations/documents']
      )
      const searchResults = computed(
        () => store.getters['organizations/searchResults']
      )
      const searchFilter = ref<DocumentSearchProps>({
        documentSubCategoriesId: []
      })

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const searchQuery = ref('')
      const dateRange = ref([])
      const pagination = computed(
        () => store.state.organizations.documentsPagination
      )
      const currentDocument = computed<DocumentModel | null>(
        () => store.getters['organizations/currentDocument']
      )
      const files = computed({
        get: () => store.state.organizations.documents,
        set: (val) => {
          fileList.value = val
        }
      })
      const hideSearchResults = () => {
        console.log()
      }
      const loadMoreFiles = (categoryName: string) => {
        let ids: string[] = []

        let category = categories.value
          .flatMap((y: DocumentCategory) => y.docSubCategories)
          .find((x) => x.name === categoryName)
        if (!category)
          ids = categories.value
            .find((cat: DocumentCategory) => cat.name === categoryName)
            .docSubCategories.map((x: DocumentSubCategory) => x.id)
        else ids = [category.id]

        store.commit(
          'organizations/SET_DOCUMENTS_PAGINATION_PAGE',
          pagination.value.currentPage + 1
        )

        const offset = documentsFromStore.value.filter((d) =>
          ids.includes(d.docSubCategory.id)
        ).length

        let module = 'organizations/LOAD_COMPANY_DOCUMENTS'
        if (isPortfolioRoute.value) module = 'portfolio/GET_DOCUMENTS'
        store.dispatch(`${module}`, {
          companyId: route.params.id,
          filter: {
            ...searchFilter.value,
            documentSubCategoriesId: ids,
            offset,
            limit: 300
          }
        })
      }

      const initSearch = () => {
        searchQuery.value = ''
      }
      const toggleListingMode = (value: number) => {
        sortedByType.value = value
      }

      const selectDocument = async (doc: DocumentModel) => {
        if (route.name === 'companyDocuments') {
          router.push({
            name: 'companyDocuments',
            params: { documentId: doc.id }
          })
        }
        store.commit('ui/DISPLAY_PREVIEW', true)

        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: currentCompany.value.id,
          documentId: doc.id
        })
      }
      const searchDocuments = (filter: DocumentSearchProps) => {
        searchFilter.value = filter
        getFiles()
      }

      const loadFiles = () => {
        let module = 'organizations/LOAD_COMPANY_DOCUMENTS'

        if (isPortfolioRoute.value) module = 'portfolio/GET_DOCUMENTS'
        store.dispatch(`${module}`, {
          companyId: route.params.id,
          filter: {
            ...searchFilter.value,
            offset: documentsFromStore.value.length,
            limit: 300
          },

          onSuccess: () => console.log(),
          onError: () => console.log()
        })
      }

      const updateData = (status = sortedByType.value) => {
        let groupedDocuments = groupItemBy(
          fileList.value,
          (doc: DocumentModel) => doc.docSubCategory?.docCategory?.name
        )
        if (SelectionType.DATE === status) {
          groupedDocuments = groupItemBy(
            fileList.value.sort(
              (a, b) =>
                moment.utc(b.registredAt).unix() - moment(a.registredAt).unix()
            ),
            (doc: DocumentModel) =>
              doc.signedAt ? moment.utc(doc.signedAt).format('L') : doc.signedAt
          )

          documents.value = new Map<string, Map<string, DocumentModel[]>>(
            Array.from(groupedDocuments).map(([key, value]) => [
              key,
              groupItemBy(value, (doc) => doc.docSubCategory.docCategory.name)
            ])
          )
          documentByCategory.value = groupedDocuments
        }

        if (SelectionType.CATEGORY === status) {
          documents.value = new Map<string, Map<string, DocumentModel[]>>(
            Array.from(groupedDocuments).map(([key, value]) => [
              key,
              groupItemBy(value, (doc) => doc?.docSubCategory?.name)
            ])
          )
        }
        documentByCategory.value = groupedDocuments
      }

      const categories = computed(() => [
        ...store.getters['toolbox/documentCategories']
      ])

      const documentByCategory = ref<Map<string, DocumentModel[]>>(
        groupItemBy(
          fileList.value,
          (doc) => doc.docSubCategory?.docCategory?.name
        )
      )

      const getFiles = async () => {
        await store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: { ...searchFilter.value, limit: 300 }
        })
      }

      const getDocs = async () => {
        let module = 'organizations/GET_COMPANY_DOCUMENTS'
        if (isPortfolioRoute.value) module = 'portfolio/GET_DOCUMENTS'
        await store.dispatch(`${module}`, {
          companyId: route.params.id,
          filter: { limit: 300 },
          onSuccess: () => {
            const document = documentsFromStore.value.find(
              (x: any) => x.id === route.params.documentId
            )
            if (route.params.documentId && document && currentDocument.value) {
              loadedDocument.value = { ...currentDocument.value, ...document }
            }
          },
          onError: () => {
            emit('onDocumentError', {
              message: t('error')
            })
          }
        })
      }

      const documents = ref(
        new Map<string, Map<string, DocumentModel[]>>(
          Array.from(
            groupItemBy(
              fileList.value,
              (doc) => doc.docSubCategory?.docCategory?.name
            )
          ).map(([key, value]) => [
            key,
            groupItemBy(value, (doc) => doc?.docSubCategory?.name)
          ])
        )
      )

      const statusFilterOptions = computed(
        () =>
          (statusFilterOptionsKeys as Array<string>).map((x: string) => ({
            name: t(`document.state.${x}`),
            id: x.toLowerCase(),
            value: x.toLowerCase()
          })) || []
      )
      const auditStatusOptions = ref(
        (statusFilterExtractionContactsKeys as Array<string>).map(
          (x: string) => ({
            name: t(`document.state.${x}`),
            id: x.toLowerCase(),
            value: x.toLowerCase()
          })
        )
      )
      const onOptionSelected = (subCategories: string[]) => {
        searchFilter.value = {
          ...searchFilter.value,
          documentSubCategoriesId: subCategories
        }
      }

      const dateCleared = () => {
        const dateFilter = {
          startDate: '',
          endDate: ''
        }
        searchFilter.value = { ...searchFilter.value, ...dateFilter }
      }

      const handleUserSelection = (contact: any) => {
        router.push({
          name: 'company.contact',
          params: { id: route.params.id, contactId: contact?.id }
        })
      }

      watch(files, (list) => {
        fileList.value = list
      })

      watch(fileList, (filesValues) => {
        filesLength.value = filesValues.length
        fileList.value = filesValues
        updateData()
      })

      watch(searchFilter, (val) => {
        getFiles()
      })

      watch(sortedByType, (val) => {
        updateData(val)
      })

      watch(searchQuery, (val) => {
        store.dispatch('organizations/SEARCH_IN_DOCUMENTS', {
          companyId: route.params.id,
          query: val
        })
      })

      onBeforeUnmount(() => {
        store.dispatch('organizations/SEARCH_IN_DOCUMENTS', {
          companyId: route.params.id,
          query: ''
        })
      })

      onMounted(() => {
        getDocs()
        store.commit('ui/DISPLAY_PREVIEW', route.params.documentId !== null)
      })

      return {
        showTreeview,
        searchQuery,
        sortedByType,
        statusFilter,
        groupTypes,
        loadedDocument,
        defaultDocumentType,
        statusFilterOptions,
        categories,
        auditStatusOptions,
        documents,
        filesLength,
        documentDisplayed,
        dateRange,
        handleUserSelection,
        documentByCategory,
        SelectionType,
        currentDocument,
        pagination,
        showFilter,
        searchResults,
        toggleListingMode,
        onOptionSelected,
        initSearch,
        selectDocument,
        loadMoreFiles,
        loadFiles,
        dateCleared,
        searchDocuments,
        hideSearchResults
      }
    }
  })
