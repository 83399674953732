<template>
  <Datepicker
    v-model="date"
    class="upl-datepicker min-h-10"
    text-input
    :name="name"
    :text-input-options="{ enterSubmit: false }"
    :clearable="false"
    :format="dateFormat"
    :locale="currentLocale"
    :is24="true"
    :utc="true"
    :auto-apply="true"
    :preview-format="$t('dateFormat')"
    :action-row-component="actionRow"
    calendar-cell-class-name="dp-custom-cell"
    @cleared="date = ''"
    @update:modelValue="handleDate"
  />
</template>

<script lang="ts">
  import {
    computed,
    ref,
    defineAsyncComponent,
    defineComponent,
    watch,
    onMounted
  } from 'vue'
  import i18n from '@/services/lang'
  import Datepicker from '@vuepic/vue-datepicker'
  import '@vuepic/vue-datepicker/dist/main.css'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { useI18n } from 'vue-i18n'

  const ActionRow = defineAsyncComponent(() => import('./actionRow.vue'))

  export default defineComponent({
    components: { Datepicker },
    props: {
      format: {
        type: String,
        default: i18n.global.t('format')
      },
      value: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: 'date'
      },
      mask: {
        type: String,
        default: i18n.global.t('global.dateMask')
      }
    },
    emits: ['onSelect'],
    setup(props, { emit }) {
      const store = useStore()
      const { t } = useI18n()
      const date = ref(props.value ? props.value?.toString().split('T')[0] : '')
      const actionRow = computed(() => ActionRow)
      const currentLocale = computed(() => store.getters['config/lang'])
      const dateFormat = computed(() => t('format'))
      const handleDate = (modelData: any) => {
        date.value = moment(modelData).set('hour', 13).toISOString()

        emit('onSelect', date.value)
      }
      watch(
        () => props.value,
        (val: string) => {
          date.value = val ? val?.toString().split('T')[0] : ''
        }
      )

      return {
        date,
        dateFormat,
        currentLocale,
        actionRow,
        handleDate
      }
    }
  })
</script>
<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .dp__theme_light {
    --dp-background-color: #ffffff;
    --dp-text-color: #212121;
    --dp-hover-color: #f3f3f3;
    --dp-hover-text-color: #212121;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #8580f6;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #76d275;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
  }
  .upl-datepicker {
    // height: 100%;
    height: 36px;
    min-height: 2.3em;

    div {
      height: 100%;

      .dp__input_wrap {
        height: 100%;
      }
    }

    .dp-custom-cell {
      @apply rounded-full text-xs;
    }
    .dp__active_date {
      background: red !important;
    }
    .dp__action_buttons {
      @apply text-sm;
    }
    input {
      @apply border text-sm border-inputBorder text-secondary9  py-3 h-full;
      color: #5f6165 !important;

      input::-webkit-input-placeholder {
        @apply text-sm text-secondary9;
      }

      ::-moz-placeholder {
        @apply text-sm text-secondary9;
      }

      :-ms-input-placeholder {
        /** notice that ie has only a single colon) */
        @apply text-sm text-secondary9;
      }

      ::-webkit-input-placeholder {
        @apply text-sm text-secondary9;
      }

      ::placeholder {
        @apply text-sm text-secondary9;
      }
      &::placeholder {
        @apply text-sm text-secondary9;
      }
      &:hover {
        @apply border-secondary4;
      }
      &:focus {
        @apply border-primary4;
      }
    }
  }
</style>
