
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Field } from 'vee-validate'
  import { ElTabs, ElTabPane } from 'element-plus'
  import Loader from '@/components/Loader/small.vue'
  import { useStore } from 'vuex'
  import { ThirdPartyApplication } from '@/services/api/models'
  import moment from 'moment'
  import Modal from '@/components/Modal/Modal.vue'
  import conf from '@/config'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'

  import { UseClipboard } from '@vueuse/components'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { GlobalConfig } from '@/types'
  import Prism from 'vue-prism-component'
  import 'prismjs/components/prism-markup-templating'
  import 'prismjs/components/prism-php'
  import 'prismjs/components/prism-aspnet'
  import 'prismjs/components/prism-java'
  import 'prismjs/components/prism-python'
  import 'prismjs/components/prism-json'
  import { useI18n } from 'vue-i18n'
  import { TYPE } from 'vue-toastification'
  export default defineComponent({
    components: {
      Loader,
      DropdownCompany,
      UseClipboard,
      Button,
      Prism,
      Modal,
      ElTabs,
      ElTabPane,
      Field,
      Input
    },
    setup() {
      const canDelete = ref(false)
      const appDeleteName = ref('')
      const H = ref<any>(null)
      const value = `var axios = require("axios").default;

var options = {
  method: 'POST',
  url: '{yourHost}/applications/{appId}/oauth/token',
  headers: {'content-type': 'application/x-www-form-urlencoded'},
  data: new URLSearchParams({
    client_secret: 'YOUR_APP_SECRET',
  })
};

axios.request(options).then(function (response) {
  console.log(response.data);
}).catch(function (error) {
  console.error(error);
});`
      const codeAsp = `var client = new RestClient("{yourHost}/applications/{appId}/oauth/token");
var request = new RestRequest(Method.POST);
request.AddHeader("content-type", "application/x-www-form-urlencoded");
request.AddParameter("application/x-www-form-urlencoded", "client_secret=YOUR_APP_SECRET", ParameterType.RequestBody);
IRestResponse response = client.Execute(request);`
      const codeCurl = `curl --request POST
  --url '{yourHost}/applications/{appId}/oauth/token'
  --header 'content-type: application/x-www-form-urlencoded'
  --data client_secret=YOUR_APP_SECRET 
`

      const codePhp = `$curl = curl_init();

curl_setopt_array($curl, [
  CURLOPT_URL => "{yourHost}/applications/{appId}/oauth/token",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => "client_secret=YOUR_APP_SECRET",
  CURLOPT_HTTPHEADER => [
    "content-type: application/x-www-form-urlencoded"
  ],
]);

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #:" . $err;
} else {
  echo $response;
}`

      const codeJava = `HttpResponse<String> response = Unirest.post("{yourHost}/applications/{appId}/oauth/token")
  .header("content-type", "application/x-www-form-urlencoded")
  .body("client_secret=YOUR_APP_SECRET")
  .asString();`

      const codePython = `import http.client

conn = http.client.HTTPSConnection("")

payload = "client_secret=YOUR_APP_SECRET"

headers = { 'content-type': "application/x-www-form-urlencoded" }

conn.request("POST", "{yourHost}/applications/{appId}/oauth/token", payload, headers)

res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))`
      const response200 = `{
  "success": true,
  "access_token":"eyJz93a...k4laUWw",
  "token_type":"Bearer",
  "expiration":86400
}`

      const responseError = `{
  "success": false,
  "statusCode": 404,
  "message":"Application not found",
}`
      const config = conf as GlobalConfig
      const { t } = useI18n()
      const code = ref(`const foo = 'bar';`)
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const showEdition = ref(false)
      const domainUrl = computed(() => config.urls.apiHost)
      const isLoading = ref(true)
      const thirdPartyApp = ref<ThirdPartyApplication>()
      const compareCompanieText = computed(() =>
        t('account.accountCompanies.deltionCompanyName', {
          name: thirdPartyApp.value?.name
        })
      )
      const selectedApp = ref<any>(null)

      const handleEditionDisplay = () => {
        showEdition.value = !showEdition.value
      }

      const startEdition = () => {
        selectedApp.value = {
          ...thirdPartyApp.value,
          companyIds: thirdPartyApp.value?.companies.map((x: any) => x.id)
        }

        delete selectedApp.value.companies

        handleEditionDisplay()
      }
      const displayModal = () => {
        appDeleteName.value = ''
        canDelete.value = true
      }

      const closeModal = () => {
        appDeleteName.value = ''
        canDelete.value = false
      }

      const submitDeletion = () => {
        if (
          appDeleteName.value &&
          appDeleteName.value === compareCompanieText.value
        ) {
          deleteApp()
          appDeleteName.value = ''
          closeModal()
        }
      }

      const getApp = () => {
        store.dispatch('auth/GET_APPLICATION', {
          applicationId: route.params.appId,
          onSuccess: (result: ThirdPartyApplication) => {
            thirdPartyApp.value = result
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const editApplication = () => {
        store.dispatch('auth/EDIT_APPLICATION', {
          applicationId: selectedApp.value.id,
          data: selectedApp.value,
          onSuccess: () => {
            handleEditionDisplay()
            selectedApp.value = null
            getApp()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_edition')
            })
          },
          onError: (err: any) => {
            let message = 'error_edition'
            if (err.statusCode == 403) message = 'error_403_app_edition'
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t(`account.applications.${message}`)
            })
          }
        })
      }

      const deleteApp = () => {
        store.dispatch('auth/DELETE_APPLICATION', {
          applicationId: thirdPartyApp.value?.id,
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_deletion')
            })
            router.push({ name: 'integratedApps' })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t('account.applications.error_deletion')
            })
          }
        })
      }

      onMounted(() => {
        getApp()
      })

      return {
        codePhp,
        codeCurl,
        thirdPartyApp,
        code,
        codePython,
        closeModal,
        codeAsp,
        canDelete,
        value,
        codeJava,
        appDeleteName,
        isLoading,
        response200,
        responseError,
        showEdition,
        domainUrl,
        selectedApp,
        handleEditionDisplay,
        moment,
        editApplication,
        startEdition,
        displayModal,
        submitDeletion
      }
    }
  })
