import { company, toolbox } from './endpoints'
import axios from './axios'
import {
  Document,
  CommonCeiling,
  UpFunction,
  Committee,
  Appointment,
  Transfer,
  Delegation,
  VestingSuspension,
  VestingAcceleration,
  VestingDelayProrogation,
  FinancialEnding,
  CompanyCommittee,
  Commitment
} from './models'
import {
  STOCKTYPESALIAS,
  SampleStockTypeFormData,
  CompositeStockTypeFormData,
  OptionStockTypeFormData,
  CommonCeilingFormData,
  DelegationFormData,
  AppointmentFormData,
  TransferFormData,
  CommiteeFormData,
  VestingSuspensionFormData,
  VestingAccelerationFormData,
  VestingProrogationFormData,
  StockIssuanceFormData,
  OptionIssuanceFormData,
  FinancialEndingFormData,
  CompositionIssuanceFormData,
  CommitmentFormData
} from '@/types/forms'
import {
  StockAlias,
  OptionStockAlias,
  CompositionStockAlias,
  CompanyVesting
} from './models/company'
import { DocumentSearchProps } from '@/types/file'
import { VestingForm } from '@/types/forms'
import { DocumentContactsForm } from '@/types'
import { Pagination } from '@/store/modules/Organization/models'
import { CompanyModelForm } from '@/types'
import { Captable } from '../../types/company'
import { CancelTokenSource } from 'axios'
import {
  AuditForm,
  AuditType,
  AuditGetType,
  DocumentEditForm
} from '../../types/forms'
import {
  InvestorContact,
  IndividualContact,
  ProfessionalContact,
  CompanyModel
} from './models/company'

interface OnboardingResp {
  status: string
  createdAt: string | null
}

let companiesCancelToken: CancelTokenSource | null = null

const searchCompanies = (query: string): Promise<CompanyModel[]> => {
  return axios.get(toolbox.searchCompanies(query))
}

const getCompanies = async (
  offset = 0,
  limit = 20
): Promise<CompanyModel[]> => {
  if (typeof companiesCancelToken != undefined) {
    companiesCancelToken?.cancel(
      'Operation (GET_COMPANIES) canceled due to new request.'
    )
  }
  companiesCancelToken = axios.CancelToken.source()
  const response = await axios.get(company.list(), {
    cancelToken: companiesCancelToken.token,
    params: { offset, limit }
  })
  return response.data
}

const getCaptable = async (
  companyId: string,
  filter: any,
  offset = 0,
  limit = 10
): Promise<Captable[]> => {
  return await axios.get(company.getCaptable(companyId), {
    params: { offset, limit, ...filter }
  })
}

const getContactsList = async (
  companyId: string,
  filter: any,
  offset = 0,
  limit = 10
): Promise<any> => {
  const response = await axios.get(company.contactsList(companyId), {
    params: { offset, limit, ...filter }
  })
  return response.data
}

const getCompanyInformationsBySiret = async (
  siret: string
): Promise<CompanyModel> => {
  const { data } = await axios.get(toolbox.companyInformations(siret))
  return data
}

const getCompanyDocuments = async (
  companyId: string,
  filter: DocumentSearchProps,
  page = 1,
  limit = 10,
  offset = 0
): Promise<Document[]> => {
  let params = {
    limit,
    offset: offset > 0 ? offset : (page - 1 < 0 ? 0 : page - 1) * 10
  }
  params = { ...params, ...filter }

  return await axios.get(company.documents(companyId), { params })
}

const searchInDocuments = async (
  companyId: string,
  query: string
): Promise<Document[]> => {
  return await axios.post(company.searchInDocuments(companyId), { query })
}

const getSingleCompanyDocuments = async (
  companyId: string,
  documentId: string
): Promise<Document[]> => {
  return await await axios.get(company.getDocument(companyId, documentId))
}

const createStock = async (
  formData: any,
  companyId: string,
  type: STOCKTYPESALIAS
) => {
  return await axios.post(company.stockCreation(companyId, type), formData)
}

const editStock = async (
  formData:
    | SampleStockTypeFormData
    | CompositeStockTypeFormData
    | OptionStockTypeFormData,
  companyId: string,
  type: STOCKTYPESALIAS,
  stockId: string
) => {
  return await axios.put(
    company.singleStockAlias(companyId, type, stockId),
    formData
  )
}

const deleteStock = async (
  companyId: string,
  type: STOCKTYPESALIAS,
  stockId: string
) => {
  return await axios.delete(company.singleStockAlias(companyId, type, stockId))
}

const getStockAlias = async (
  companyId: string,
  type: STOCKTYPESALIAS
): Promise<StockAlias[]> => {
  const { data } = await axios.get(company.getStockAlias(companyId, type), {
    params: { limit: 30, offset: 0 }
  })
  return data
}

const getSingleStockAlias = async (
  companyId: string,
  type: STOCKTYPESALIAS,
  stockId: string
): Promise<StockAlias | OptionStockAlias | CompositionStockAlias> => {
  const { data } = await axios.get(
    company.singleStockAlias(companyId, type, stockId),
    { params: { limit: 30, offset: 0 } }
  )
  return data
}

const getCompanyOrgans = async (
  companyId: string
): Promise<CompanyCommittee[]> => {
  const { data } = await axios.get(company.getCommittees(companyId), {
    params: { limit: 30, offset: 0 }
  })
  return data
}

const createCommittee = async (
  companyId: string,
  data: CommiteeFormData
): Promise<Committee> => {
  return await axios.post(company.getCommittees(companyId), data)
}

const getCompanyCommittes = async (
  companyId: string
): Promise<CompanyCommittee[]> => {
  const { data } = await axios.get(company.getCommittees(companyId), {
    params: { limit: 30, offset: 0 }
  })
  return data
}

const getEvents = async (
  companyId: string,
  isDraft: boolean,
  startDate: string,
  endDate: string,
  offset = 0,
  limit = 30
): Promise<any[]> => {
  return await axios.get(company.getEvents(companyId), {
    params: {
      isDraft,
      startDate,
      endDate,
      offset,
      limit
    }
  })
}

const createCommitment = async (
  companyId: string,
  data: CommitmentFormData
): Promise<Commitment> => {
  return await axios.post(company.getCommitment(companyId), data)
}

const getCommitment = async (companyId: string): Promise<Commitment[]> => {
  return await axios.get(company.getCommitment(companyId))
}

const createVestingSuspension = async (
  companyId: string,
  data: VestingSuspensionFormData
): Promise<VestingSuspension> => {
  return await axios.post(company.getVestingSuspension(companyId), data)
}

const getVestingSuspension = async (
  companyId: string
): Promise<VestingSuspension[]> => {
  return await axios.get(company.getVestingSuspension(companyId))
}

const createVestingAcceleration = async (
  companyId: string,
  data: VestingAccelerationFormData
): Promise<VestingAcceleration> => {
  return await axios.post(company.getVestingAcceleration(companyId), data)
}

const getVestingAcceleration = async (
  companyId: string
): Promise<VestingAcceleration[]> => {
  return await axios.get(company.getVestingAcceleration(companyId))
}

const createVestingProrogation = async (
  companyId: string,
  data: VestingProrogationFormData
): Promise<VestingDelayProrogation> => {
  return await axios.post(company.getVestingProrogation(companyId), data)
}

const getVestingProrogation = async (
  companyId: string
): Promise<VestingDelayProrogation[]> => {
  return await axios.get(company.getVestingProrogation(companyId))
}

const getCompanyCeilings = async (
  companyId: string
): Promise<CommonCeiling[]> => {
  return await axios.get(company.getCeilings(companyId))
}

const getCompanyFunctions = async (): Promise<UpFunction[]> => {
  return await axios.get(company.getFunctions())
}

const getCompanyAppointments = async (
  companyId: string
): Promise<Appointment[]> => {
  return await axios.get(company.getNominations(companyId))
}

const createAppointment = async (
  companyId: string,
  data: AppointmentFormData
): Promise<Appointment> => {
  return await axios.post(company.getNominations(companyId), data)
}

const getCompanyTransfers = async (companyId: string): Promise<Transfer[]> => {
  return await axios.get(company.getTransfers(companyId))
}

const createTransfer = async (
  companyId: string,
  data: TransferFormData
): Promise<Transfer> => {
  return await axios.post(company.getTransfers(companyId), data)
}

const createCeiling = async (
  companyId: string,
  data: CommonCeilingFormData
): Promise<CommonCeiling> => {
  return await axios.post(company.getCeilings(companyId), data)
}

const getDelegations = async (companyId: string): Promise<Delegation[]> => {
  return await axios.get(company.getDelegations(companyId))
}

const createDelegation = async (
  companyId: string,
  data: DelegationFormData
): Promise<Delegation> => {
  return await axios.post(company.getDelegations(companyId), data)
}

//TODO: fix api and create interfaces
const createPrimaryStockEmission = async (
  companyId: string,
  data: StockIssuanceFormData
): Promise<any> => {
  return await axios.post(company.getPrimaryStocks(companyId), data)
}
const createPrimaryOptionsEmission = async (
  companyId: string,
  data: OptionIssuanceFormData
): Promise<any> => {
  return await axios.post(company.getPrimaryOptions(companyId), data)
}
const createPrimaryCompositionEmission = async (
  companyId: string,
  data: CompositionIssuanceFormData
): Promise<any> => {
  return await axios.post(company.getPrimaryComposition(companyId), data)
}
const getPrimaryStockEmission = async (companyId: string): Promise<any> => {
  return await axios.get(company.getPrimaryStocks(companyId))
}
const getPrimaryOptionsEmission = async (companyId: string): Promise<any> => {
  return await axios.get(company.getPrimaryOptions(companyId))
}
const getPrimaryCompositionEmission = async (
  companyId: string
): Promise<any> => {
  return await axios.get(company.getPrimaryComposition(companyId))
}
const createFinancialEnding = async (
  companyId: string,
  data: FinancialEndingFormData
): Promise<FinancialEnding> => {
  return await axios.post(company.getFinancialEnding(companyId), data)
}

const createVesting = async (companyId: string, formData: VestingForm) => {
  return await axios.post(company.createVesting(companyId), formData)
}

const getVestings = async (companyId: string) => {
  return await axios.get(company.createVesting(companyId), {
    params: { limit: 30, offset: 0 }
  })
}
const getContacts = async (
  companyId: string,
  filter = { limit: 30, offset: 0 }
) => {
  const params = { ...filter }
  const { data } = await axios.get(company.contacts(companyId), { params })
  return data
}
const getContact = async (companyId: string, contactId: string) => {
  const { data } = await axios.get(company.singleContact(companyId, contactId))
  return data
}

const createContact = async (
  companyId: string,
  values: InvestorContact | IndividualContact | ProfessionalContact
): Promise<InvestorContact | IndividualContact | ProfessionalContact> => {
  const { data } = await axios.post(company.contacts(companyId), values)
  return data
}

const updateContact = async (
  companyId: string,
  contactId: string,
  values: InvestorContact | IndividualContact | ProfessionalContact
) => {
  return await axios.put(company.singleContact(companyId, contactId), values)
}

const deleteContact = async (companyId: string, contactId: string) => {
  return await axios.delete(company.singleContact(companyId, contactId))
}
const getVesting = async (
  companyId: string,
  id: string
): Promise<CompanyVesting> => {
  const { data } = await axios.get(company.singleVesting(companyId, id), {
    params: { limit: 30, offset: 0 }
  })
  return data
}

const removeVesting = async (companyId: string, id: string) => {
  return axios.delete(company.singleVesting(companyId, id))
}

const onboardingCompany = async (
  companyData: CompanyModel
): Promise<OnboardingResp> => {
  const { data } = await axios.post(company.onboarding(), companyData)
  return data
}

const uploadFiles = async (
  form: FormData,
  companyId: string,
  onUploadProgress: (progress: ProgressEvent) => void
) => {
  const resp = await axios.post(company.uploadFiles(companyId), form, {
    onUploadProgress
  })
  return resp
}

const createAudit = async (
  companyId: string,
  type: AuditType,
  typeDataId: string,
  values: AuditForm
) => {
  return await (
    await axios.post(company.audits(companyId, type, typeDataId), values)
  ).data
}

const editAudit = async (
  companyId: string,
  type: AuditType,
  typeDataId: string,
  auditId: string,
  values: AuditForm
) => {
  return await axios.put(
    company.audit(companyId, type, typeDataId, auditId),
    values
  )
}

const getAudits = async (
  companyId: string,
  type: AuditGetType,
  pagination: Pagination
) => {
  const { data } = await axios.get(company.getAuditByType(companyId, type), {
    params: {
      limit: pagination.limit,
      offset:
        pagination.currentPage <= 1
          ? 0
          : pagination.limit * pagination.currentPage
    }
  })
  return data
}

const editDocument = async (
  companyId: string,
  documentId: string,
  values: DocumentEditForm
) => {
  return await axios.put(company.getDocument(companyId, documentId), values)
}

const deleteDocument = async (companyId: string, documentId: string) => {
  return await axios.delete(company.getDocument(companyId, documentId))
}

const attachContactToDocument = async (
  companyId: string,
  documentId: string,
  values: DocumentContactsForm
) => {
  return await axios.post(
    company.documentContacts(companyId, documentId),
    values
  )
}

const createNewCompany = async (data: CompanyModelForm) => {
  return await (
    await axios.post(company.create(), data)
  ).data
}

const updateCompany = async (id: string, data: CompanyModelForm) => {
  return await axios.put(company.get(id), data)
}
const addNominalValue = async (id: string, data: any) => {
  return await axios.post(company.nominalValue(id), data)
}
const getNominalValue = async (id: string) => {
  return await (
    await axios.get(company.nominalValue(id))
  ).data
}
const getCompany = async (id: string) => {
  return await (
    await axios.get(company.get(id))
  ).data
}
const deleteCompany = async (id: string) => {
  return await axios.delete(company.get(id))
}

const getCompanyUserAccounts = async (
  companyId: string,
  page = 1,
  search = null
) => {
  const params: any = { limit: 10, offset: page === 1 ? 0 : page * 10 }
  if (search) params.search = search
  const { data } = await axios.get(company.users(companyId), {
    params
  })
  return data
}

const getTransactions = async (companyId: string, filter: any, page = 1) => {
  const params: any = {
    limit: 100,
    offset: page === 1 ? 0 : page * 10,
    ...filter
  }
  const response = await axios.get(company.getTransactions(companyId), {
    params
  })

  return response
}

export {
  searchCompanies,
  getCompanyInformationsBySiret,
  onboardingCompany,
  uploadFiles,
  editDocument,
  deleteDocument,
  attachContactToDocument,
  getCompanies,
  searchInDocuments,
  getCompanyDocuments,
  getSingleCompanyDocuments,
  createStock,
  getCompanyOrgans,
  getStockAlias,
  editStock,
  deleteStock,
  getSingleStockAlias,
  createVesting,
  getVestings,
  getVesting,
  removeVesting,
  getContacts,
  createContact,
  getContact,
  updateContact,
  deleteContact,
  createAudit,
  getAudits,
  editAudit,
  getCompanyCeilings,
  createCommittee,
  getCompanyCommittes,
  createNewCompany,
  deleteCompany,
  updateCompany,
  getCompany,
  getCompanyUserAccounts,
  createCeiling,
  getDelegations,
  createDelegation,
  createPrimaryStockEmission,
  createPrimaryOptionsEmission,
  createPrimaryCompositionEmission,
  getCompanyFunctions,
  getCompanyAppointments,
  createAppointment,
  getCompanyTransfers,
  createTransfer,
  createVestingSuspension,
  getVestingSuspension,
  createVestingAcceleration,
  getVestingAcceleration,
  createVestingProrogation,
  getVestingProrogation,
  addNominalValue,
  getNominalValue,
  getTransactions,
  getEvents,
  getPrimaryStockEmission,
  getPrimaryOptionsEmission,
  getPrimaryCompositionEmission,
  getCaptable,
  createFinancialEnding,
  getContactsList,
  createCommitment,
  getCommitment
}
