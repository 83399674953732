
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import DataTable from '@/components/DataTable/index.vue'
  import NewInvitationForm from '@/components/Forms/Account/invitationForm.vue'
  import EditInvitationForm from '@/components/Forms/Account/edit.vue'
  import { InvitationForm } from '@/types'
  import { useStore } from 'vuex'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import Modal from '@/components/Modal/Modal.vue'
  import { Company, User, UserAccount } from '@/services/api/models'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import { useRoute, useRouter } from 'vue-router'
  import EmptyState from '@/components/EmptyState/index.vue'
  import { CompanyAccount, Role } from '@/services/api/models/user'
  import Loader from '@/components/Loader/index.vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'
  import MenuDropdown from '@/components/MenuDropdown/index.vue'
  import * as yup from 'yup'
  import { ElPopover, ElTableColumn, ElTable } from 'element-plus'

  export default defineComponent({
    components: {
      Field,
      Input,
      Button,
      MenuDropdown,
      DataTable,
      NewInvitationForm,
      ContactBadge,
      Modal,
      ElTableColumn,
      ElTable,
      ElPopover,
      EmptyState,
      EditInvitationForm,
      Loader,
      Multiselect,
      DropdownCompany
    },
    setup() {
      const search = ref('')
      const store = useStore()
      const route = useRoute()
      const router = useRouter()
      const { t, te } = useI18n()
      const isEditing = ref(false)
      const isSubmittingEdition = ref(false)
      const showDetails = ref(false)
      const canRemove = ref(false)

      const roleId = ref()
      const companyId = ref()
      const isLoadingUsers = ref(false)
      const currentUserCompanyAccounts = ref<CompanyAccount[]>([])
      const displayedUser = ref<User | null>(null)
      const isRefreshing = ref(false)
      const canDelete = ref(false)
      const isMakingAction = ref(false)
      const tableColumns = computed(() => [
        {
          label: t('datatable.column.user'),
          field: 'user',
          custom: true
        },

        {
          label: t('datatable.column.company'),
          field: 'roles',
          custom: true,
          width: '30%'
        },

        {
          label: 'Connection',
          field: 'connection',
          custom: true,
          width: '20%'
        },

        {
          label: '',
          field: 'actions',
          custom: true,
          width: '3%'
        }
      ])
      const getFirstCompany = (accounts: CompanyAccount[]) => {
        const [first] = accounts.filter((x) => x.company)
        return first
      }
      const getOtherLinkedCompanyAccounts = (
        accounts: CompanyAccount[],
        first: CompanyAccount
      ) => {
        if (first?.id) return accounts.filter((x) => x.id != first.id)
        else return accounts
      }

      const buildLines = (rows: CompanyAccount[]) => {
        return rows.map((x) => ({
          companyName: x.company?.name ?? '-',
          roleName: x.role.name ?? '-',
          contactName: x.contact?.name ?? '-'
        }))
      }

      const detailsTableColumns = computed(() => [
        {
          label: t('datatable.column.company'),
          field: 'company',
          custom: true
        },

        {
          label: t('datatable.column.role'),
          field: 'role',
          custom: true
        },

        {
          label: t('datatable.column.contact'),
          field: 'contact',
          custom: true
        }
      ])

      const actions = computed(() => [
        {
          name: t('global.actions.editUser'),
          icon: 'pencil',
          type: 'list',
          section: 1,
          key: 'edit'
        },
        {
          name: t('global.actions.sendNewInvite'),
          icon: 'envelope-fill',
          type: 'list',
          section: 1,
          key: 'invite'
        },
        {
          name: t('global.actions.deleteUser'),
          icon: 'trash',
          type: 'list',
          section: 2,
          key: 'delete'
        }
      ])
      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData']
      )

      const userRoles = computed<Role[]>(() => store.getters['auth/roles'])

      const roleSearch = (value: string) => {
        store.dispatch('toolbox/GET_ROLES', {
          companyId: route.params.id,
          data: { search: value },
          onSuccess: (data: Role[]) => {
            allRoles.value = [...data]
          },
          onError: () => {
            //
          }
        })
      }

      const highestPriority = computed(() =>
        Math.max(...(userRoles.value || []).map((x) => x.priority))
      )
      const authRole = computed<Role>(() =>
        (userRoles.value || []).reduce((prev: Role, current: Role) =>
          current.priority > prev.priority ? current : prev
        )
      )

      const allRoles = ref<Role[]>([])

      const roles = computed(() =>
        [...allRoles.value]
          .filter(
            (x) =>
              x.priority >=
              (![0, 2].includes(highestPriority.value)
                ? highestPriority.value + 1
                : highestPriority.value)
          )
          .sort((a, b) => a.priority - b.priority)
          .map((r) => ({
            ...r,
            name: te(`roles.${r.slug}`) ? t(`roles.${r.slug}`) : r.name
          }))
      )

      const currentCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )
      const auth = computed<UserAccount>(() => store.getters['auth/profile'])
      const accounts = computed(
        () => store.getters['organizations/accountUsers']
      )
      const pagination = computed(
        () => store.getters['organizations/accountsPagination']
      )
      const companies = computed<Company[]>(() => [
        ...store.getters['organizations/loaderCompanies']
      ])

      const schema = yup.object().shape({
        uuid: yup.string().uuid()
      })

      const isValid = computed(() =>
        schema.isValidSync({ uuid: companyId.value })
      )

      const countInvitees = ref(1)
      const columns = ref(tableColumns)
      const invitees = ref<any[]>([])
      const currentUser = ref<InvitationForm>({
        lastName: '',
        firstName: '',
        email: '',
        accounts: [],
        roles: '',
        contactId: ''
      })

      const isSearchingCompany = ref(false)

      const isAllUsersListingPage = computed(
        () => route.name === 'accountUsers'
      )
      const userRemove = (user: User) => {
        displayedUser.value = { ...user }
        canRemove.value = true
      }
      const handleCompanyChange = (id: any) => {
        companyId.value = id

        getUsers(1)
      }

      const roleChanged = (id: string) => {
        roleId.value = id
        getUsers()
      }
      const handleAction = ($event: string, user: User) => {
        if ($event == 'invite') {
          sendInvitationMail(user)
        } else if ($event == 'edit') {
          editUser(user)
        } else if ($event == 'delete') {
          userRemove(user)
        }
      }
      const getUserDetails = (row: User) => {
        displayedUser.value = { ...row }

        currentUserCompanyAccounts.value = row.companiesAccounts
        showDetails.value = true
      }

      const removeInvitation = (index: number) => {
        invitees.value.splice(index, 1)
      }

      const getContact = (row: User) => {
        return row.companiesAccounts.find(
          (x: CompanyAccount) => x.contactId !== null
        )
      }
      const addInvitees = () => {
        for (let index = 0; index < Number(countInvitees.value); index++) {
          invitees.value.push({})
        }
      }
      const handleChange = (index: number, values: any) => {
        invitees.value[index] = values
      }

      const goToContact = async (account: CompanyAccount) => {
        store.commit(`organizations/SET_CONTEXT_COMPANY`, account.company)

        router.push({
          name: 'company.directory',
          params: {
            id: account.company?.id,
            contactId: account.contact?.id
          }
        })
      }
      const getRoles = () => {
        store.dispatch('toolbox/GET_ROLES', {
          companyId: route.params.id,
          onSuccess: (data: Role[]) => {
            allRoles.value = [...data]
          },
          onError: () => {
            //
          }
        })
      }

      const closeRemoveModal = () => {
        canRemove.value = false
      }

      const editUser = (data: User) => {
        const user: any = {}
        user.email = data.email
        user.id = data.id
        user.lang = data.lang
        user.connectionThroughSSO = data.connectionThroughSSO ?? false
        user.firstName = data.firstName
        user.lastName = data.lastName
        user.roles = (data.roles || []).map((x) => x.id || x)
        user.accounts = data.companiesAccounts.map((x) => ({
          id: x.id,
          companyId: x.companyId,
          roleId: x.roleId,
          contactId: x?.contactId
        }))
        currentUser.value = { ...user }
        isSubmittingEdition.value = false

        isEditing.value = true
      }

      const closeModal = () => {
        isRefreshing.value = true
        getUsers()

        isEditing.value = false
      }

      const closeDetailsModal = () => {
        showDetails.value = false
        currentUserCompanyAccounts.value = []
        displayedUser.value = null
      }

      const handleAccountEdit = (values: InvitationForm) => {
        const data: any = { ...values }

        if (!values.contactId) delete data.contactId

        if (!values.companyId) delete data.companyId
        isSubmittingEdition.value = true
        store.dispatch('organizations/EDIT_USER_ACCOUNT', {
          userId: currentUser.value.id,
          form: data,
          companyId: currentCompany.value.id,
          onError: () => {
            isSubmittingEdition.value = false

            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(`account.accountInformations.apiResponse.error`)
            })
          },
          onSuccess: () => {
            getUsers()
            isSubmittingEdition.value = false

            isEditing.value = false

            store.dispatch('toolbox/displayToast', {
              type: TYPE.INFO,
              context: t('account.context'),
              message: t(`account.accountInformations.apiResponse.success`)
            })

            getUsers()
          }
        })
      }

      const submitDeletion = () => {
        removeUser(displayedUser.value)
      }
      const removeUser = (user: any) => {
        store.dispatch('organizations/DELETE_USER_ACCOUNT', {
          userId: user.id,
          companyId: currentCompany.value.id,
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.deletionError`
              )
            })
          },
          onSuccess: () => {
            getUsers()
            closeRemoveModal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.deletionSuccess`
              )
            })
          }
        })
      }
      const hasValidInvidations = () => {
        const fields = ['email', 'lastName', 'firstName', 'roles']

        return invitees.value.every((elm) => {
          return fields.every((f) => elm[f] && elm[f] !== '')
        })
      }

      const canEdit = (roles: Role[]) => {
        const userPriority = !(roles || []).map((x) => x.priority).length
          ? 9
          : Math.max(...(roles || []).map((x) => x.priority))
        const priority = auth.value?.roles
          ? Math.max(...auth.value?.roles?.map((x) => x.priority))
          : 9

        return [0, 2].includes(priority)
          ? priority <= userPriority
          : priority < userPriority
      }

      const sendInvitationMail = (user: User) => {
        store.dispatch('organizations/SEND_INVITATION_MAIL', {
          userId: user.id,
          companyId: currentCompany.value.id,
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(`account.accountInformations.apiResponse.error`)
            })
          },
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.INFO,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.successInvited`
              )
            })
          }
        })
      }

      const sendInvitation = (values: InvitationForm) => {
        const data: any = { ...values }
        data.roles = [values.roles]
        const selectedRole = allRoles.value.find((x) => x.id === values.roles)
        if ((selectedRole?.priority || 0) > 1) {
          data.accounts = [
            {
              contactId: values.contactId,
              companyId: values.companyId,
              roleId: values.roles
            }
          ]

          data.roles = []
          delete data.contactId
          delete data.companyId
        } else {
          delete data.contactId
          if (!data.companyId) delete data.companyId
        }
        isMakingAction.value = true
        store.dispatch('organizations/CREATE_USER_ACCOUNT', {
          form: data,
          companyId: currentCompany.value.id,
          onError: (message: string) => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message:
                message ||
                `${values.email}: ${t(
                  `account.accountInformations.apiResponse.creationFailed`
                )}`
            })
            isMakingAction.value = false
          },
          onSuccess: () => {
            invitees.value = invitees.value.filter(
              (x) => x.email !== values.email
            )
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.context'),
              message: `${values.email}: ${t(
                `account.accountInformations.apiResponse.creationSuccess`
              )}`
            })
            isMakingAction.value = false

            getUsers()
          }
        })
      }

      const onPageChange = (page: number) => {
        store.commit('organizations/SET_ACCOUNTS_PAGINATION_PAGE', page)

        isRefreshing.value = true
        getUsers()
      }
      const updateCompany = (value: string) => {
        isSearchingCompany.value = false
        companyId.value = value
      }

      const getUsers = (page = 1) => {
        if (isSearchingCompany.value) return
        const filter: any = { limit: 20, offset: (page - 1) * 20 }
        if (search.value) filter.search = search.value
        if (roleId.value) filter.roleId = roleId.value
        filter.companyIds = companyId.value ? [companyId.value] : []

        store.dispatch('organizations/GET_ACCOUNT_USERS', {
          filter,
          onSuccess: () => {
            isRefreshing.value = false
          },
          onError: () => {
            isRefreshing.value = false
          }
        })
      }

      const handleInvitations = async () => {
        const data = [...invitees.value]
        await Promise.all(
          data.map((user) =>
            sendInvitation({ ...user, [user.connection ?? 'normal']: true })
          )
        )
        getUsers()
      }

      watch([search], () => {
        onPageChange(1)
      })

      onMounted(() => {
        getUsers()
        getRoles()
      })

      return {
        search,
        columns,
        isLoadingUsers,
        roleId,
        canDelete,
        countInvitees,
        invitees,
        accounts,
        isEditing,
        currentUser,
        pagination,
        isRefreshing,
        isLoading,
        actions,
        isMakingAction,
        isSubmittingEdition,
        isAllUsersListingPage,
        editUser,
        roleChanged,
        canEdit,
        buildLines,
        handleAction,
        closeModal,
        getOtherLinkedCompanyAccounts,
        closeDetailsModal,
        goToContact,
        onPageChange,
        getContact,
        addInvitees,
        removeInvitation,
        handleChange,
        userRemove,
        canRemove,
        submitDeletion,
        closeRemoveModal,
        companyId,
        roleSearch,
        handleInvitations,
        companies,
        handleAccountEdit,
        removeUser,
        sendInvitationMail,
        roles,
        displayedUser,
        currentUserCompanyAccounts,
        auth,
        detailsTableColumns,
        getUserDetails,
        showDetails,
        handleCompanyChange,
        updateCompany,
        getFirstCompany,
        hasValidInvidations
      }
    }
  })
