<template>
  <div class="mt-4">
    <div
      v-if="isLoading"
      class="w-full h-full flex justify-center items-center"
    >
      <Loader />
    </div>
    <div v-else>
      <h4 class="mt-3 mb-2 text-left font-semibold text-secondary9">
        {{
          $t(`events.elements.${hasParts ? 'commonDocuments' : 'documents'}`)
        }}
        ({{ eventDocuments.length }} / {{ pagination.total }})
      </h4>
      <div class="flex flex-col justify-start">
        <data-table
          v-if="eventDocuments.length > 0"
          :columns="columns"
          :rows="eventDocuments"
          :pagination="pagination"
          @on-page-change="handlePageChange"
          index-key="id"
        >
          <template #name="{ row }">
            <h5 class="font-normal text-sm">{{ row?.docSubCategory?.name }}</h5>
            <p class="font-normal text-sm">{{ row.name || row.label }}</p>
          </template>
          <template #actions="slotProps">
            <div class="flex justify-end">
              <Button
                variant="secondary"
                size="medium"
                class="p-1 rounded-sm view-btn mr-2"
                label=""
                @click="handleDocumentView(slotProps.row)"
              >
                <i class="bi bi-eye text-xl"></i>
              </Button>
              <el-popconfirm
                :confirm-button-text="$t('confirm.yes')"
                :cancel-button-text="$t('confirm.no')"
                :title="$t('global.confirmDelete')"
                @confirm="removeLinkedDocument(slotProps.row)"
              >
                <template #reference>
                  <Button
                    variant="secondary"
                    size="medium"
                    class="p-1 rounded-sm action-btn"
                  >
                    <i class="bi bi-trash text-xl"></i>
                  </Button>
                </template>
              </el-popconfirm>
            </div>
          </template>
        </data-table>
        <div v-else class="flex">
          <h5>{{ $t('documents.noEventDoc') }}</h5>
        </div>
      </div>

      <div v-if="hasParts">
        <h4 class="mt-3 mb-2 text-left font-semibold text-secondary9 mt-5">
          {{ $t('events.elements.subscriberDocuments') }}
        </h4>
        <event-part-linked-documents
          :event="event"
          :only-display="false"
          @file-deleted="fileDeleted"
        />
      </div>

      <div class="selected-documents">
        <div v-if="documents.length > 0">
          <h5 class="text-left my-3">
            {{ $tc('global.eventAttachedFiles', { size: documents.length }) }}
          </h5>
          <div
            v-for="(doc, index) in documents"
            :key="doc.id"
            :class="`
                flex
                justify-between
                items-center cursor-pointer
                border-t  ${
                  currentDocument?.id === doc.id ? 'border-l-4' : 'border-l'
                } border-r border-secondary5 ${
              index === documents.length - 1 ? 'border-b' : ''
            }`"
            @click="selectDocument(doc)"
          >
            <h5 class="w-3/5 text-left p-2">
              {{ doc.label || doc.name }}
            </h5>
            <div class="border-l border-secondary5 p-2">
              <Button
                variant="custom"
                class="text-error"
                @click.stop="removeDocument(doc.id)"
              >
                <i class="bi bi-trash text-xl"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="filesSelected" class="flex justify-end pt-3 text-xl">
        <Button
          variant="secondary"
          class="flex items-center"
          :label="$t('global.actions.attachFiles')"
          @click.stop="saveFiles"
        >
          <i class="bi bi-check text-xl"></i>
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import EventPartLinkedDocuments from '@/components/Event/EventPartLinkedDocuments.vue'
  import { useStore } from 'vuex'
  import {
    EventPartDocuments,
    Pagination
  } from '@/store/modules/Organization/models'
  import { TYPE } from 'vue-toastification'
  import { useRoute } from 'vue-router'
  import { CompanyModel, Document, Event } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import Loader from '@/components/Loader/index.vue'
  import { Button } from '@up.law/uplaw-ui'
  import DataTable from '@/components/DataTable/index.vue'
  import { ElPopconfirm } from 'element-plus'
  export default defineComponent({
    components: {
      EventPartLinkedDocuments,
      Loader,
      ElPopconfirm,
      Button,
      DataTable
    },
    emits: ['on-updated'],
    setup(props, { emit }) {
      const store = useStore()
      const isLoading = ref(false)
      const { t } = useI18n()
      const event = ref<Event | null>(null)
      const route = useRoute()
      const eventPartLinkedDocuments = computed<EventPartDocuments>(
        () => store.getters['organizations/eventPartLinkedDocuments']
      )
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )

      const documents = computed<Document[]>(
        () => store.getters['organizations/attachedFiles']
      )
      const currentDocument = computed<Document | null>(
        () => store.getters['organizations/currentDocument']
      )
      const eventDocuments = ref<any>([])
      const allParts = computed<any[]>(
        () =>
          event.value?.optionIssuance?.optionIssuanceParts ||
          event.value?.stockIssuance?.stockIssuanceParts ||
          event.value?.conversion?.conversionParts ||
          event.value?.compositionIssuance?.compositionIssuanceParts ||
          event.value?.optionExercise?.optionExerciseParts ||
          event.value?.transfer?.transferParts ||
          event.value?.capitalReduction?.capitalReductionParts ||
          []
      )

      const limit = computed<number>(
        () => store.getters['config/eventDocumentsLimit']
      )
      const pagination = ref<Pagination>({
        total: 0,
        currentPage: 1,
        lastPage: 1,
        limit: limit.value
      })

      const hasParts = computed(
        () => event.value?.type && allParts.value.length
      )

      const isPortfolioRoute = computed(() =>
        route.name?.toString().includes('portfolioView')
      )

      const documentColumns = computed(() => [
        {
          label: t(`datatable.column.category`),
          field: 'category',
          custom: true,
          width: '15%'
        },
        {
          label: t(`datatable.column.name`),
          field: 'name',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          custom: true,
          width: '10%'
        }
      ])

      const columns = computed(() => [
        {
          label: t('documents.elements.name'),
          field: 'name',
          width: '40%',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          width: '5%',
          custom: true
        }
      ])

      const handleDocumentView = async (doc: Document) => {
        if (currentDocument?.value?.id === doc.id) return
        store.commit('ui/DISPLAY_PREVIEW', true)
        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }

      const removeLinkedDocument = (doc: Document) => {
        store.dispatch('organizations/REMOVE_EVENT_DOCUMENT', {
          companyId: route.params.id,
          eventId: route.params.eventId,
          documentId: doc.id,
          onError: (error = null) => {
            const message = error || t(`documents.apiResponse.deleteError`)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('extraction.secondLevel.events.documentContext'),
              message
            })
          },
          onSuccess: () => {
            fileDeleted()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('extraction.secondLevel.events.documentContext'),
              message: t(`documents.apiResponse.deleteSuccess`)
            })
          }
        })
      }
      const removeDocument = (docId: string) => {
        const docs = documents.value.filter((x) => x.id !== docId)
        store.commit('organizations/ATTACH_FILES_TO_EVENT', { files: docs })
      }
      const selectDocument = async (doc: Document) => {
        store.commit('ui/DISPLAY_PREVIEW', true)
        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }

      const saveFiles = () => {
        attachFilesToEvent()
        Promise.all(
          Object.keys(eventPartLinkedDocuments.value).map((x) =>
            attachFilesToPart(x)
          )
        )
      }

      const attachFilesToPart = (partId: string) => {
        const ids = (
          allParts.value.find((x) => x.id === partId)?.documents || []
        )?.map((d: any) => d.id)

        store.dispatch('organizations/ATTACH_DOCUMENTS_TO_EVENT_PART', {
          type: event.value?.type,
          eventTypeId:
            event.value?.optionExercise?.id ||
            event.value?.optionIssuance?.id ||
            event.value?.stockIssuance?.id ||
            event.value?.compositionIssuance?.id ||
            event.value?.transfer?.id ||
            event.value?.capitalReduction?.id ||
            event.value?.conversion?.id,
          partId,
          companyId: route.params.id,
          eventId: route.params.eventId,
          data: {
            documentIds: [
              ...ids,
              ...eventPartLinkedDocuments.value[partId].map((x) => x.id)
            ]
          },
          onSuccess: () => {
            store.commit('organizations/INIT_PART_DOCUMENTS', partId)
            fileDeleted()
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('extraction.secondLevel.events.documentContext'),
              message: t('extraction.secondLevel.events.documentsUpdateError')
            })
          }
        })
      }
      const fileDeleted = () => {
        emit('on-updated')
        getEventDocuments()
      }
      const attachFilesToEvent = () => {
        const ids = documents.value.map((x) => x.id)
        if (ids.length == 0) return
        store.dispatch('organizations/UPDATE_EVENT_DOCUMENTS', {
          companyId: route.params.id,
          eventId: route.params.eventId,
          data: { documentIds: ids },
          onSuccess: () => {
            store.commit('organizations/ATTACH_FILES_TO_EVENT', { files: [] })
            store.dispatch('toolbox/displayToast', {
              type: TYPE.INFO,
              context: t('extraction.secondLevel.events.documentContext'),
              message: t('extraction.secondLevel.events.documentsUpdated')
            })
            fileDeleted()
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('extraction.secondLevel.events.documentContext'),
              message: t('extraction.secondLevel.events.documentsUpdateError')
            })
          }
        })
      }

      const getEvent = (eventId = route.params.eventId) => {
        isLoading.value = true
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT`, {
          companyId: route.params.id,
          eventId,
          onSuccess: async (data: Event | null) => {
            event.value = data
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }
      const getEventDocuments = (eventId = route.params.eventId, page = 1) => {
        if (!eventId) return
        let module = 'organizations'
        if (isPortfolioRoute.value) module = 'portfolio'
        store.dispatch(`${module}/GET_EVENT_DOCUMENTS`, {
          companyId: currentCompany.value.id,
          eventId,
          page,
          onSuccess: (response: any) => {
            const { data, metadata } = response

            eventDocuments.value = [...data]

            pagination.value = {
              ...metadata,
              total: metadata.count,
              limit: limit.value
            }
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const handlePageChange = (page: number) => {
        getEventDocuments(route.params.eventIdd, page)
      }
      const filesSelected = computed(
        () =>
          documents.value.length ||
          Object.values(eventPartLinkedDocuments.value).reduce(
            (docs: Document[], x: Document[]) => {
              docs.push(...x)
              return docs
            },
            []
          ).length
      )

      const isIncluded = (documents: Document[], id: string) => {
        return (documents || []).map((x) => x.id).includes(id)
      }

      onMounted(() => {
        store.commit('organizations/INIT_PART_DOCUMENTS', null)
        getEventDocuments()
        getEvent()
      })

      return {
        removeLinkedDocument,
        saveFiles,
        removeDocument,
        selectDocument,
        fileDeleted,
        handleDocumentView,
        handlePageChange,
        pagination,
        documents,
        eventDocuments,
        columns,
        currentDocument,
        hasParts,
        event,
        filesSelected,
        isLoading,
        isIncluded,
        documentColumns
      }
    }
  })
</script>
