<template>
  <div class="w-full h-full flex justify-center items-start px-5 mt-6">
    <div v-if="isLoading">
      <Loader></Loader>
    </div>
    <div v-else class="w-full flex flex-col">
      <div class="mb-2">
        <Button
          :label="$t('global.actions.edit')"
          variant="custom"
          class="bg-secondary4 bg-opacity-25 text-primary3"
          @click="startEdition()"
        />
      </div>
      <div class="w-full flex">
        <div class="w-5/12 bg-white rounded flex flex-col px-3 py-2">
          <div class="flex flex-col items-start mb-2">
            <p class="uppercase text-secondary7 text-sm font-semibold">
              {{ $t('account.applications.api_host') }}
            </p>

            <div class="mt-2 mb-2 flex justify-between w-full bg-secondary3">
              <span class="px-3 py-2 rounded font-medium">{{ domainUrl }}</span>
              <UseClipboard v-slot="{ copy, copied }" :source="domainUrl">
                <Button
                  variant="custom"
                  style="border-radius: 2px !important"
                  class="text-xl bg-success bg-opacity-25"
                  @click="copy()"
                >
                  <i
                    :class="`text-xl text-success la la-${
                      copied ? 'check' : 'copy'
                    }`"
                  ></i>
                </Button>
              </UseClipboard>
            </div>
          </div>
          <div class="flex flex-col items-start mb-2">
            <p class="uppercase text-secondary7 text-sm font-semibold">
              {{ $t('account.applications.app_name') }}
            </p>

            <div class="mt-2 flex justify-between w-full bg-secondary3">
              <span class="px-3 py-2 rounded font-medium">
                {{ thirdPartyApp?.name }}
              </span>
              <UseClipboard
                v-slot="{ copy, copied }"
                :source="thirdPartyApp?.name"
              >
                <Button
                  variant="custom"
                  style="border-radius: 2px !important"
                  class="text-xl bg-primary3 bg-opacity-25"
                  @click="copy()"
                >
                  <i
                    :class="`text-xl text-primary3 la la-${
                      copied ? 'check' : 'copy'
                    }`"
                  ></i>
                </Button>
              </UseClipboard>
            </div>
          </div>

          <div class="flex flex-col items-start mb-2 mt-3">
            <p class="uppercase text-secondary7 text-sm font-semibold">
              {{ $t('account.applications.app_id') }}
            </p>

            <div class="mt-2 flex justify-between w-full bg-secondary3">
              <span
                class="px-3 py-2 rounded bg-secondary3 font-medium text-left"
                >{{ thirdPartyApp?.id }}</span
              >
              <UseClipboard
                v-slot="{ copy, copied }"
                :source="thirdPartyApp?.id"
              >
                <Button
                  variant="custom"
                  style="border-radius: 2px !important"
                  class="text-xl bg-primary3 bg-opacity-25"
                  @click="copy()"
                >
                  <i
                    :class="`text-xl text-primary3 la la-${
                      copied ? 'check' : 'copy'
                    }`"
                  ></i>
                </Button>
              </UseClipboard>
            </div>
          </div>

          <!-- <div class="flex flex-col items-start mb-2 mt-3">
            <p class="uppercase text-secondary7 text-sm font-semibold">
              Application identifier
            </p>
  
            <div class="mt-2 flex justify-between w-full bg-secondary3">
              <span class="px-3 py-2 rounded bg-secondary3 font-medium text-left">
                {{ thirdPartyApp.clientId }}</span
              >
              <UseClipboard
                v-slot="{ copy, copied }"
                :source="thirdPartyApp.clientId"
              >
                <Button
                  variant="custom"
                  style="border-radius: 2px !important"
                  class="text-xl bg-primary3 bg-opacity-25"
                  @click="copy()"
                >
                  <i
                    :class="`text-xl text-primary3 la la-${
                      copied ? 'check' : 'copy'
                    }`"
                  ></i>
                </Button>
              </UseClipboard>
            </div>
          </div>-->

          <div class="flex flex-col items-start mb-2 mt-3">
            <p class="uppercase text-secondary7 text-sm font-semibold">
              {{ $t('account.applications.app_secret') }}
            </p>

            <div class="mt-2 flex justify-between w-full bg-secondary3">
              <span
                class="
                  px-3
                  py-2
                  rounded
                  bg-secondary3
                  font-medium
                  text-left
                  w-full
                "
                >•••••••••••••••••••••••••••••••••••••••••••</span
              >
              <UseClipboard
                v-slot="{ copy, copied }"
                :source="thirdPartyApp?.clientSecret"
              >
                <Button
                  variant="custom"
                  style="border-radius: 2px !important"
                  class="text-xl bg-primary3 bg-opacity-25"
                  @click="copy()"
                >
                  <i
                    :class="`text-xl text-primary3 la la-${
                      copied ? 'check' : 'copy'
                    }`"
                  ></i>
                </Button>
              </UseClipboard>
            </div>
          </div>

          <div class="flex flex-col items-start mb-2 mt-3">
            <p class="uppercase text-secondary7 text-sm font-semibold">
              {{ $t('account.applications.app_created') }}
            </p>

            <div class="mt-1 flex justify-between w-full">
              <span
                v-if="thirdPartyApp?.owner"
                class="py-2 rounded font-medium text-left"
              >
                {{
                  thirdPartyApp?.owner.lastName +
                  ' ' +
                  thirdPartyApp?.owner.firstName
                }}
              </span>
              <span
                v-else
                class="px-3 py-2 rounded bg-secondary3 font-medium text-left"
                >-</span
              >
            </div>
          </div>

          <div class="flex flex-col items-start mb-2 mt-3">
            <p class="uppercase text-secondary7 text-sm font-semibold">
              {{ $t('account.applications.app_last_token_date') }}
            </p>

            <div class="mt- flex justify-between w-full">
              <span
                v-if="thirdPartyApp?.lastTokenDate"
                class="py-2 rounded font-medium text-left"
                >{{ moment(thirdPartyApp?.lastTokenDate).format('LLL') }}</span
              >
              <span
                v-else
                class="px-3 py-2 rounded bg-secondary3 font-medium text-left"
                >-</span
              >
            </div>
          </div>

          <div class="flex flex-col items-start mb-2 mt-3">
            <p class="uppercase text-secondary7 text-sm font-semibold">
              {{ $t('account.applications.attached_companies') }}
            </p>

            <div class="mt- flex justify-between w-full">
              <span
                v-if="thirdPartyApp?.companies?.length"
                class="py-2 rounded font-medium text-left"
              >
                {{ thirdPartyApp?.companies.map((x) => x.name).join(', ') }}
              </span>
              <span
                v-else
                class="px-3 py-2 rounded bg-secondary3 font-medium text-left"
                >-</span
              >
            </div>
          </div>

          <div
            class="
              mt-20
              bg-error bg-opacity-25
              flex
              items-center
              px-3
              py-5
              rounded
            "
          >
            <div class="w-3/4 flex flex-col items-start">
              <h3 class="font-bold text-sm text-error my-3">
                {{ $t('account.applications.delete_app_label') }}
              </h3>

              <p class="text-error font-medium text-xs text-left">
                {{ $t('account.applications.delete_app_description') }}
              </p>
            </div>
            <div class="w-1/4 flex justify-end">
              <Button
                variant="custom"
                :label="$t('global.actions.delete')"
                style="border-radius: 2px !important"
                class="text-xl bg-error text-white"
                @click="displayModal()"
              ></Button>
            </div>
          </div>
        </div>
        <div class="w-7/12 justify-end flex shrink" style="min-height: 0">
          <div class="w-11/12 rounded">
            <h3 class="text-secondary8 text-sm my-2 text-left">
              {{ $t('account.applications.expl_access_token_get') }}
            </h3>
            <el-tabs type="">
              <el-tab-pane label="cURL">
                <prism language="markup" :inline="false">{{ codeCurl }}</prism>
              </el-tab-pane>
              <el-tab-pane label="C#">
                <prism language="aspnet">{{ codeAsp }}</prism>
              </el-tab-pane>
              <el-tab-pane label="Php">
                <prism language="php">{{ codePhp }}</prism>
              </el-tab-pane>
              <el-tab-pane label="Node.Js">
                <prism language="javascript" :inline="false">{{ value }}</prism>
              </el-tab-pane>
              <el-tab-pane label="Java">
                <prism language="java" :inline="false">{{ codeJava }}</prism>
              </el-tab-pane>
              <el-tab-pane label="Python">
                <prism language="python" :inline="false">{{
                  codePython
                }}</prism>
              </el-tab-pane>
            </el-tabs>

            <div class="mt-3">
              <h3 class="text-secondary8 text-sm my-2 text-left">
                {{ $t('account.applications.expl_access_token_get_success') }}
              </h3>

              <prism language="json" :inline="false">{{ response200 }}</prism>
            </div>
            <div class="mt-3">
              <h3 class="text-secondary8 text-sm my-2 text-left">
                {{ $t('account.applications.expl_access_token_get_error') }}
              </h3>

              <prism language="json" :inline="false">{{ responseError }}</prism>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="modal">
      <Modal v-if="canDelete" size="w-2/5" @close="canDelete = false">
        <template #header>
          <h3 class="text-md font-semibold text-error">
            {{
              $t('account.accountCompanies.appModalTextHeader', {
                name: thirdPartyApp?.name
              })
            }}
          </h3>
        </template>

        <template #body>
          <p
            class="select-none"
            v-html="
              $t('account.accountCompanies.appDeleteModalText', {
                name: thirdPartyApp?.name
              })
            "
          ></p>

          <div class="w-full mt-4">
            <Field
              v-slot="{ field }"
              v-model="appDeleteName"
              name="appDeleteName"
            >
              <Input
                v-model="appDeleteName"
                v-bind="field"
                :placeholder="$t('account.accountCompanies.deltionPlaceholder')"
                name="appDeleteName"
              />
            </Field>
          </div>
        </template>
        <template #footer>
          <div class="flex text-xl justify-end">
            <Button
              class="flex text-white bg-primary rounded mr-2"
              variant="custom"
              :label="$t('account.accountCompanies.deletionModalCancel')"
              @click="closeModal"
            />
            <Button
              class="flex text-white bg-error rounded"
              variant="custom"
              :label="$t('account.accountCompanies.deletionModalText')"
              @click.prevent="submitDeletion"
            />
          </div>
        </template>
      </Modal>
    </transition>

    <transition name="modal">
      <Modal
        v-if="showEdition"
        size="w-1/5 md:w-1/3"
        @close="handleEditionDisplay"
      >
        <template #header>
          <h3 class="text-md font-semibold">
            {{ $t('account.accountInformations.integration_update') }}
          </h3>
        </template>

        <template #body>
          <div class>
            <div class="w-full mr-2">
              <label
                class="text-xs text-left text-secondary8 font-semibold mt-0"
                for="siret"
                >{{ $t('contactBook.fields.name') }}</label
              >
              <Field v-slot="{ field }" v-model="selectedApp.name" name="name">
                <Input
                  v-model="selectedApp.name"
                  v-bind="field"
                  class
                  :disabled="false"
                  type="text"
                  name="Nom"
                  :placeholder="$t('contactBook.placeholder.name')"
                />
              </Field>
            </div>

            <div class="w-full">
              <label
                class="text-xs text-left text-secondary8 font-semibold mt-0"
                for="companyIds"
                >{{ $t('global.company', { count: 2 }) }}</label
              >
              <Field
                v-slot="{ field }"
                v-model="selectedApp.companyIds"
                name="companyIds"
              >
                <DropdownCompany
                  v-model="selectedApp.companyIds"
                  mode="multiple"
                  v-bind="field"
                  :value="selectedApp.companyIds"
                  @on-updated="($event) => (selectedApp.companyIds = $event)"
                />
              </Field>
            </div>

            <div class="w-full mt-3">
              <label
                class="text-xs text-left text-secondary8 font-semibold mt-0"
                for="description"
                >{{ $t('groupBook.form.description') }}</label
              >
              <Field
                v-slot="{ field }"
                v-model="selectedApp.description"
                name="description"
              >
                <div class="group-color-block">
                  <textarea
                    v-bind="field"
                    placeholder="Description"
                    class="
                      resize-none
                      focus:border-primary3
                      rounded
                      border-secondary1 border
                      w-full
                      p-3
                    "
                    name="description"
                  ></textarea>
                </div>
              </Field>
            </div>
          </div>
        </template>
        <template #footer
          ><div class="mt-5 flex justify-end">
            <Button
              :label="$t('global.actions.save')"
              variant="primary"
              @click="editApplication"
            />
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, onMounted, ref } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Field } from 'vee-validate'
  import { ElTabs, ElTabPane } from 'element-plus'
  import Loader from '@/components/Loader/small.vue'
  import { useStore } from 'vuex'
  import { ThirdPartyApplication } from '@/services/api/models'
  import moment from 'moment'
  import Modal from '@/components/Modal/Modal.vue'
  import conf from '@/config'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'

  import { UseClipboard } from '@vueuse/components'
  import { Button, Input } from '@up.law/uplaw-ui'
  import { GlobalConfig } from '@/types'
  import Prism from 'vue-prism-component'
  import 'prismjs/components/prism-markup-templating'
  import 'prismjs/components/prism-php'
  import 'prismjs/components/prism-aspnet'
  import 'prismjs/components/prism-java'
  import 'prismjs/components/prism-python'
  import 'prismjs/components/prism-json'
  import { useI18n } from 'vue-i18n'
  import { TYPE } from 'vue-toastification'
  export default defineComponent({
    components: {
      Loader,
      DropdownCompany,
      UseClipboard,
      Button,
      Prism,
      Modal,
      ElTabs,
      ElTabPane,
      Field,
      Input
    },
    setup() {
      const canDelete = ref(false)
      const appDeleteName = ref('')
      const H = ref<any>(null)
      const value = `var axios = require("axios").default;

var options = {
  method: 'POST',
  url: '{yourHost}/applications/{appId}/oauth/token',
  headers: {'content-type': 'application/x-www-form-urlencoded'},
  data: new URLSearchParams({
    client_secret: 'YOUR_APP_SECRET',
  })
};

axios.request(options).then(function (response) {
  console.log(response.data);
}).catch(function (error) {
  console.error(error);
});`
      const codeAsp = `var client = new RestClient("{yourHost}/applications/{appId}/oauth/token");
var request = new RestRequest(Method.POST);
request.AddHeader("content-type", "application/x-www-form-urlencoded");
request.AddParameter("application/x-www-form-urlencoded", "client_secret=YOUR_APP_SECRET", ParameterType.RequestBody);
IRestResponse response = client.Execute(request);`
      const codeCurl = `curl --request POST
  --url '{yourHost}/applications/{appId}/oauth/token'
  --header 'content-type: application/x-www-form-urlencoded'
  --data client_secret=YOUR_APP_SECRET 
`

      const codePhp = `$curl = curl_init();

curl_setopt_array($curl, [
  CURLOPT_URL => "{yourHost}/applications/{appId}/oauth/token",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "POST",
  CURLOPT_POSTFIELDS => "client_secret=YOUR_APP_SECRET",
  CURLOPT_HTTPHEADER => [
    "content-type: application/x-www-form-urlencoded"
  ],
]);

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #:" . $err;
} else {
  echo $response;
}`

      const codeJava = `HttpResponse<String> response = Unirest.post("{yourHost}/applications/{appId}/oauth/token")
  .header("content-type", "application/x-www-form-urlencoded")
  .body("client_secret=YOUR_APP_SECRET")
  .asString();`

      const codePython = `import http.client

conn = http.client.HTTPSConnection("")

payload = "client_secret=YOUR_APP_SECRET"

headers = { 'content-type': "application/x-www-form-urlencoded" }

conn.request("POST", "{yourHost}/applications/{appId}/oauth/token", payload, headers)

res = conn.getresponse()
data = res.read()

print(data.decode("utf-8"))`
      const response200 = `{
  "success": true,
  "access_token":"eyJz93a...k4laUWw",
  "token_type":"Bearer",
  "expiration":86400
}`

      const responseError = `{
  "success": false,
  "statusCode": 404,
  "message":"Application not found",
}`
      const config = conf as GlobalConfig
      const { t } = useI18n()
      const code = ref(`const foo = 'bar';`)
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const showEdition = ref(false)
      const domainUrl = computed(() => config.urls.apiHost)
      const isLoading = ref(true)
      const thirdPartyApp = ref<ThirdPartyApplication>()
      const compareCompanieText = computed(() =>
        t('account.accountCompanies.deltionCompanyName', {
          name: thirdPartyApp.value?.name
        })
      )
      const selectedApp = ref<any>(null)

      const handleEditionDisplay = () => {
        showEdition.value = !showEdition.value
      }

      const startEdition = () => {
        selectedApp.value = {
          ...thirdPartyApp.value,
          companyIds: thirdPartyApp.value?.companies.map((x: any) => x.id)
        }

        delete selectedApp.value.companies

        handleEditionDisplay()
      }
      const displayModal = () => {
        appDeleteName.value = ''
        canDelete.value = true
      }

      const closeModal = () => {
        appDeleteName.value = ''
        canDelete.value = false
      }

      const submitDeletion = () => {
        if (
          appDeleteName.value &&
          appDeleteName.value === compareCompanieText.value
        ) {
          deleteApp()
          appDeleteName.value = ''
          closeModal()
        }
      }

      const getApp = () => {
        store.dispatch('auth/GET_APPLICATION', {
          applicationId: route.params.appId,
          onSuccess: (result: ThirdPartyApplication) => {
            thirdPartyApp.value = result
            isLoading.value = false
          },
          onError: () => {
            isLoading.value = false
          }
        })
      }

      const editApplication = () => {
        store.dispatch('auth/EDIT_APPLICATION', {
          applicationId: selectedApp.value.id,
          data: selectedApp.value,
          onSuccess: () => {
            handleEditionDisplay()
            selectedApp.value = null
            getApp()

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_edition')
            })
          },
          onError: (err: any) => {
            let message = 'error_edition'
            if (err.statusCode == 403) message = 'error_403_app_edition'
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t(`account.applications.${message}`)
            })
          }
        })
      }

      const deleteApp = () => {
        store.dispatch('auth/DELETE_APPLICATION', {
          applicationId: thirdPartyApp.value?.id,
          onSuccess: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.applications.context'),
              message: t('account.applications.success_deletion')
            })
            router.push({ name: 'integratedApps' })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.applications.context'),
              message: t('account.applications.error_deletion')
            })
          }
        })
      }

      onMounted(() => {
        getApp()
      })

      return {
        codePhp,
        codeCurl,
        thirdPartyApp,
        code,
        codePython,
        closeModal,
        codeAsp,
        canDelete,
        value,
        codeJava,
        appDeleteName,
        isLoading,
        response200,
        responseError,
        showEdition,
        domainUrl,
        selectedApp,
        handleEditionDisplay,
        moment,
        editApplication,
        startEdition,
        displayModal,
        submitDeletion
      }
    }
  })
</script>
