<template>
  <router-link
    v-if="(company || $route.params?.id) && contact?.id"
    class="cursor-pointer"
    target="_blank"
    :to="{
      name: 'company.directory',
      params: { id: company.id ?? $route.params.id, contactId: contact.id }
    }"
  >
    <ContactBadge
      :contact="contact"
      class="flat light"
      :use-picture="usePicture"
      :details="details"
      :initials-only="initialsOnly"
      :mode="mode"
      :size="size"
      :block-type="blockType"
      type="light"
    />
  </router-link>
  <ContactBadge
    v-else
    :contact="contact"
    class="flat light"
    :use-picture="usePicture"
    :details="details"
    :initials-only="initialsOnly"
    :mode="mode"
    :size="size"
    :block-type="blockType"
    type="light"
  />
</template>

<script lang="ts">
  import ContactBadge from './index.vue'
  import { defineComponent, computed, onMounted } from 'vue'
  import { Company, ContactCardProps } from '@/services/api/models'

  export default defineComponent({
    components: { ContactBadge },
    props: {
      usePicture: {
        type: Boolean,
        default: true
      },
      contact: {
        type: Object as () => ContactCardProps,
        required: false,
        default: () => ({})
      },
      company: {
        type: Object as () => Company,
        required: false,
        default: () => ({})
      },
      details: {
        type: Boolean,
        required: false,
        default: false
      },
      initialsOnly: {
        type: Boolean,
        required: false,
        default: false
      },
      type: {
        type: String,
        required: false,
        default: 'primary'
      },
      mode: {
        type: String,
        required: false,
        default: 'contact'
      },
      size: {
        type: String,
        required: false,
        default: '45' //size in em
      },
      blockType: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup() {
      return {}
    }
  })
</script>
