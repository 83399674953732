
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field, Form, FieldArray } from 'vee-validate'
  import Multiselect from '@/components/Select/multiselect.vue'
  import { InvitationForm } from '@/types'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { useRoute } from 'vue-router'
  import * as yup from 'yup'
  import FormActions from '@/components/Forms/ModalActions/index.vue'
  import { Contact, Role } from '@/services/api/models'
  import { useI18n } from 'vue-i18n'
  import { TYPE } from 'vue-toastification'
  import DropdownCompany from '@/components/Forms/DropDowns/DropdownCompany.vue'
  import DropdownGeneric from '@/components/Forms/DropDowns/DropdownGeneric.vue'
  import LangSelector from '@/components/LangSelector/index.vue'

  export default defineComponent({
    components: {
      Multiselect,
      Field,
      Form,
      Input,
      LangSelector,
      Button,
      FormActions,
      FieldArray,
      DropdownCompany,
      DropdownGeneric
    },
    props: {
      companyId: {
        type: String,
        required: false,
        default: null
      },
      account: { type: Object as () => InvitationForm, required: true }
    },
    emits: ['editAccount', 'onClose'],
    setup(props, { emit }) {
      const store = useStore()
      const route = useRoute()
      const { t, te } = useI18n()

      const companyContacts = ref<any[]>([])
      const currentLocale = computed(() => store.getters['config/lang'])

      const currentUser = ref<any>({
        ...props.account,
        lang: props.account.lang ?? currentLocale.value,
        roles: props.account.roles,
        accounts: props.account.accounts
          ? props.account.accounts
          : [
              {
                contactId: props.account?.contactId ?? '',
                roleId: props.account?.roles,
                companyId: props.account?.companyId ?? props.companyId ?? ''
              }
            ]
      })

      const userRoles = computed(() => store.getters['auth/roles'])
      const isUserGlobalRoute = computed(() => route.name === 'accountUsers')

      const highestPriority = computed(() =>
        Math.max(...(userRoles.value || []).map((x: Role) => x.priority))
      )

      const handleContact = ($event: any, index: number) => {
        currentUser.value.accounts[index].contactId = $event?.value
      }
      const addRows = (push: any) => {
        currentUser.value.accounts?.push({
          companyId: props.companyId || '',
          roleId: '',
          contactId: ''
        })
        push({
          companyId: props.companyId || '',
          roleId: '',
          contactId: ''
        })
      }

      const removeRow = (
        index: number,
        id: string,
        companyId: string,
        remove: any,
        replace: any
      ) => {
        if (id) removeCompanyUserProfile(id, companyId, index, remove, replace)
        else {
          remove(index)
          const elms = [...currentUser.value.accounts].filter(
            (x, i) => i !== index
          )
          currentUser.value.accounts = elms
        }
      }
      const allRoles = computed<Role[]>(() => store.getters['toolbox/roles'])
      const companyRoles = computed(() =>
        allRoles.value.filter((r) => r.priority > 1)
      )
      const roles = computed(() =>
        allRoles.value
          .filter(
            (x) =>
              x.priority >=
              (![0, 2].includes(highestPriority.value)
                ? highestPriority.value + 1
                : highestPriority.value)
          )
          .filter((role) =>
            props.companyId
              ? role.priority > 1
              : [0, 1].includes(highestPriority.value)
              ? role.priority <= 1
              : role.priority > 1 && role.priority <= 7
          )
          .sort((a, b) => a.priority - b.priority)
          .map((r) => ({
            ...r,
            name: te(`roles.${r.slug}`) ? t(`roles.${r.slug}`) : r.name
          }))
      )

      const handleContactSearch = (value: string) => {
        getContacts(value)
      }

      const allContacts = computed(() =>
        contacts.value.reduce((acc: any[], x: any) => {
          if (!acc.map((a: any) => a.id).includes(x.id)) acc.push(x)
          return acc
        }, [])
      )

      const contacts = ref<Contact[]>([])

      const schema = computed(() =>
        yup.object().shape({
          email: yup.string().email().required(),
          firstName: yup.string().required(),
          lastName: yup.string().required(),
          roles: yup.array(yup.string()).nullable().default([]),
          accounts: yup
            .array()
            .of(
              yup.object().shape({
                contactId: yup.string().when('roleId', {
                  is: (value: string) => {
                    return allRoles.value
                      .filter((r) => r.isContactRequired)
                      .map((x) => x.id)
                      .includes(value)
                  },
                  then: (schema) => schema.required('roleMustHaveContact'),
                  otherwise: (schema) => schema.optional().nullable()
                }),
                companyId: yup.string().required('companyRequired'),
                roleId: yup.string().required('roleRequired')
              })
            )
            .default([])
        })
      )

      const getContacts = (search = '') => {
        store.dispatch('auth/GET_CONTACTS', {
          filter: {
            limit: 20,
            offset: 0,
            search
          },
          onSuccess: (response: any) => {
            contacts.value = [...contacts.value, ...(response.data ?? [])]

            handleSelectedContacts()
          }
        })
      }

      const getRoles = () => {
        store.dispatch('toolbox/GET_ROLES', {
          companyId: route.params.id
        })
      }

      const handleSelectedContacts = async () => {
        await Promise.all(
          props.account.accounts.map(async (a) => {
            if (contacts.value.map((x) => x.id).includes(a.contactId)) return

            store.dispatch('organizations/GET_COMPANY_CONTACT', {
              companyId: a.companyId,
              contactId: a.contactId,
              onSuccess: (cont: Contact) => {
                contacts.value = [cont, ...contacts.value]
              }
            })
          })
        )
      }

      const removeCompanyUserProfile = (
        accountId: string,
        companyId: string,
        index: number,
        removeLine: any,
        replace: any
      ) => {
        store.dispatch('organizations/DELETE_USER_COMPANY_PROFILE', {
          userId: currentUser.value.id,
          companyAccountId: accountId,
          companyId,
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.userCompanyAccountDeletionError`
              )
            })
          },
          onSuccess: () => {
            const accounts = (currentUser.value?.accounts ?? []).filter(
              (x: any, i: number) => x.id != accountId
            )

            currentUser.value.accounts = accounts
            replace(accounts)

            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('account.context'),
              message: t(
                `account.accountInformations.apiResponse.userCompanyAccountDeletionSuccess`
              )
            })
          }
        })
      }

      const handleCompanySelection = ($event: any, i: number) => {
        currentUser.value.accounts[i].companyId = $event
        currentUser.value.accounts[i].contactId = ''

        if (!$event) return
        store.dispatch('auth/GET_CONTACTS', {
          filter: {
            limit: 20,
            offset: 0,
            companyId: $event
          },
          onSuccess: (response: any) => {
            contacts.value = [...contacts.value, ...(response.data ?? [])]
          }
        })
      }

      const contactSearch = (text: string, companyId: string) => {
        store.dispatch('auth/GET_CONTACTS', {
          filter: {
            companyId: companyId,
            limit: 20,
            offset: 0,
            search: text
          },
          onSuccess: (response: any) => {
            contacts.value = [...contacts.value, ...(response.data ?? [])]
          }
        })
      }

      const handleForm = (values: InvitationForm) => {
        emit('editAccount', values)
      }

      watch(props, (acc: any) => {
        currentUser.value = acc.account
      })

      onMounted(() => {
        getContacts()
        getRoles()
      })

      return {
        currentUser,
        isUserGlobalRoute,
        highestPriority,
        roles,
        allContacts,
        schema,
        companyRoles,
        handleContactSearch,
        handleContact,
        addRows,
        removeCompanyUserProfile,
        removeRow,
        contactSearch,
        companyContacts,
        contacts,
        handleCompanySelection,
        handleForm
      }
    }
  })
