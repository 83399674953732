
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Field } from 'vee-validate'
  import { Input, Button } from '@up.law/uplaw-ui'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from 'vue-router'
  import DataTable from '@/components/DataTable/index.vue'
  import {
    DocumentCategory,
    Document as DocumentModel
  } from '@/services/api/models'
  import { DocumentMarkAs } from '@/types'
  import moment from 'moment'
  import LongText from '@/components/LongText/index.vue'
  import EmptyState from '@/components/EmptyState/index.vue'
  import Loader from '@/components/Loader/index.vue'
  import { useI18n } from 'vue-i18n'
  import DownloadButton from '@/components/DownloadButton/index.vue'
  import { ElTooltip } from 'element-plus'
  export default defineComponent({
    name: 'UplStockMovements',
    components: {
      Field,
      Input,
      Button,
      DataTable,
      LongText,
      EmptyState,
      Loader,
      ElTooltip,
      DownloadButton
    },
    setup() {
      const route = useRoute()
      const store = useStore()
      const search = ref('')
      const loading = ref(true)
      const router = useRouter()
      const documents = ref<Document[]>([])
      const { t } = useI18n()
      const categories = computed<DocumentCategory[]>(
        () => store.getters['toolbox/documentCategories']
      )
      const isDownloading = ref(false)

      const assembliesCategories = computed(() =>
        categories.value
          .map((x) => {
            return x.docSubCategories.filter(
              (y) => y.markAs === DocumentMarkAs.assemblies
            )
          })
          .flat()
          .map((x) => x.id)
      )

      const pagination = computed(
        () => store.getters['organizations/documentsPagination']
      )
      const onPageChange = (page: number) => {
        store.commit('organizations/SET_DOCUMENTS_PAGINATION_PAGE', page)
        getDocuments()
      }
      const getDocuments = async (search = '') => {
        if (assembliesCategories.value.length == 0) {
          loading.value = false
          return
        }
        loading.value = true
        await store.dispatch('organizations/GET_COMPANY_DOCUMENTS', {
          companyId: route.params.id,
          filter: {
            search,
            documentSubCategoriesId: assembliesCategories.value
          },
          onSuccess: (result: any) => {
            documents.value = result.data
            loading.value = false
          }
        })
      }
      const selectDocument = async (doc: DocumentModel) => {
        store.commit('ui/DISPLAY_PREVIEW', true)
        store.commit('ui/COLLAPSE_DOC_PREVIEW', true)

        await store.dispatch('organizations/GET_COMPANY_SINGLE_DOCUMENT', {
          companyId: route.params.id,
          documentId: doc.id
        })
      }
      const columns = computed(() => [
        {
          label: 'Date',
          field: 'date',
          custom: true
        },
        {
          label: t('documents.elements.decisionType'),
          field: 'type',
          custom: true
        },
        {
          label: 'Document',
          field: 'name',
          custom: true
        },
        {
          label: '',
          field: 'actions',
          custom: true,
          width: '5%'
        }
      ])

      const downloadableDocument = (row: DocumentModel) => {
        return row.documentVersions?.find((x) => x.isMaster)
      }

      const editFile = (document: DocumentModel) => {
        router.push({
          name: 'companyDocuments',
          params: {
            id: route.params.id,
            documentId: document.id
          }
        })
      }

      const download = () => {
        isDownloading.value = true

        const companyId = route.params.id
        store.dispatch('organizations/EXPORT_COMPANY_GENERAL_MEETINGS_PDF', {
          name: `${t('exports.generalMeetings')} `,
          companyId,
          filter: {
            search,
            documentSubCategoriesId: assembliesCategories.value
          },
          onSuccess: () => {
            isDownloading.value = false
          },
          onError: () => {
            isDownloading.value = false
          }
        })
      }

      watch(assembliesCategories, () => {
        getDocuments()
      })

      watch(search, (val) => {
        getDocuments(val)
      })

      onMounted(() => {
        store.commit('organizations/SET_DOCUMENTS_PAGINATION_PAGE', 1)
        getDocuments()
      })

      return {
        search,
        loading,
        documents,
        columns,
        download,
        moment,
        isDownloading,
        pagination,
        assembliesCategories,
        downloadableDocument,
        selectDocument,
        editFile,
        onPageChange
      }
    }
  })
