<template>
  <div class="account__companies container-fluid px-4 py-5 w-full text-sm">
    <CompanyCreatorModal
      :is-visible="outerVisible"
      @on-close="outerVisible = false"
    />
    <EditCompanyForm
      :is-visible="canEdit"
      :company="company"
      @hide="hideEdit"
    />
    <div class="text-xl flex justify-between items-center">
      <h1 class="text-secondary9">
        {{ $t('account.accountCompanies.context') }}
      </h1>
      <Button
        v-if="$can.any(['admin', 'write-companies'])"
        icon="bi-plus"
        :label="$t('account.accountCompanies.buttons.addCompany')"
        data-toggle="modal"
        @click.prevent="handleNewCompany"
      />
    </div>
    <div
      v-can.any="['admin', 'write-companies', 'read-companies']"
      class="filters"
    >
      <div class="w-1/3">
        <Field v-slot="{ field }" v-model="search" name="search">
          <Input
            v-model="search"
            v-bind="field"
            :placeholder="$t('account.accountCompanies.searchPlaceholder')"
            name="search"
          />
        </Field>
      </div>
    </div>
    <div class="mt-5">
      <data-table
        :columns="columns"
        :rows="companies"
        :pagination="pagination"
        current-color="#C3FDFF"
        @onPageChange="onPageChange"
      >
        <template #createdAt="{ row }">
          <span>{{ moment.utc(row.createdAt).format('L') }}</span>
        </template>
        <template #status="{ row }">
          <span
            :class="`px-1  rounded border  border-${
              row.isActive ? 'success' : 'warning'
            } text-${row.isActive ? 'success' : 'warning'}`"
            >{{ $t(`confirm.${row.isActive ? 'yes' : 'no'}`) }}</span
          >
        </template>
        <template #constituedAt="{ row }">
          <span v-if="row.constitutedAt">
            {{ moment.utc(row.constitutedAt).format('L') }}
          </span>
          <span v-else>--/--/--</span>
        </template>
        <template #actions="{ row }">
          <div class="flex">
            <Button
              class="
                transition
                rounded
                text-secondary8
                hover:bg-secondary8 hover:text-white
              "
              size="medium"
              variant="custom"
              @click="editCompany(row)"
            >
              <i class="bi bi-pencil text-lg" />
            </Button>
            <el-tooltip
              v-can.any="['admin', 'read-companies']"
              class="box-item"
              effect="dark"
              :content="$t('account.accountCompanies.changeContext')"
              placement="bottom"
            >
              <Button
                class="
                  rounded-sm
                  text-primary5
                  hover:bg-primary5 hover:text-white
                  mr-2
                  transition
                "
                size="medium"
                variant="custom"
                @click="changeCurrentContext(row)"
              >
                <i class="bi bi-capslock-fill text-lg" />
              </Button>
            </el-tooltip>

            <Button
              v-can="['admin']"
              class="
                transition
                rounded
                text-error
                hover:bg-error hover:text-white
              "
              size="medium"
              variant="custom"
              @click="displayModal(row)"
            >
              <i class="bi bi-trash text-lg" />
            </Button>
          </div>
        </template>
      </data-table>
    </div>
    <transition name="modal">
      <Modal
        v-if="canDelete && currentCompany"
        size="w-2/5"
        @close="canDelete = false"
      >
        <template #header>
          <h3 class="text-md font-semibold text-error">
            {{
              $t('account.accountCompanies.deleteModalTextHeader', {
                name: currentCompany.name
              })
            }}
          </h3>
        </template>

        <template #body>
          <p
            class="select-none"
            v-html="
              $t('account.accountCompanies.deleteModalText', {
                name: toDeleteName
              })
            "
          ></p>

          <div class="w-full mt-4">
            <Field
              v-slot="{ field }"
              v-model="companyDeleteName"
              name="companyDeleteName"
            >
              <Input
                v-model="companyDeleteName"
                v-bind="field"
                :placeholder="$t('account.accountCompanies.deltionPlaceholder')"
                name="companyDeleteName"
              />
            </Field>
          </div>
        </template>
        <template #footer>
          <div class="flex text-xl justify-end">
            <Button
              class="flex text-white bg-primary rounded mr-2"
              variant="custom"
              :label="$t('account.accountCompanies.deletionModalCancel')"
              @click="closeModal"
            />
            <Button
              class="flex text-white bg-error rounded"
              variant="custom"
              :label="$t('account.accountCompanies.deletionModalText')"
              @click.prevent="submitDeletion"
            />
          </div>
        </template>
      </Modal>
    </transition>
  </div>
</template>

<script lang="ts">
  import { Button, Input } from '@up.law/uplaw-ui'
  import { Field } from 'vee-validate'
  import CompanyCreatorModal from '@/components/CompanyCreator/index.vue'
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import DataTable from '@/components/DataTable/index.vue'
  import { useStore } from 'vuex'
  import moment from 'moment'
  import { TYPE } from 'vue-toastification'
  import { useI18n } from 'vue-i18n'
  import EditCompanyForm from '@/components/CompanyCreator/edit.vue'
  import { useRoute, useRouter } from 'vue-router'
  import { Company, CompanyModel } from '@/services/api/models'
  import Modal from '@/components/Modal/Modal.vue'
  import { comnpaniesListColumns } from '@/utils/data'
  import { ElTooltip } from 'element-plus'

  export default defineComponent({
    name: 'AccountCompanies',
    components: {
      EditCompanyForm,
      CompanyCreatorModal,
      Button,
      Input,
      DataTable,
      Modal,
      ElTooltip,
      Field
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      const route = useRoute()
      const { t } = useI18n()
      const search = ref<string>('')
      let outerVisible = ref(false)
      const canEdit = ref(false)
      const canDelete = ref(false)
      const actualCompany = computed<Company>(
        () => store.getters['organizations/contextCompany']
      )
      const currentCompany = ref<CompanyModel | null>(null)
      const companyDeleteName = ref('')
      const toDeleteName = computed(() =>
        currentCompany.value?.name.replaceAll(/\s/g, '').toLowerCase()
      )
      const compareCompanieText = computed(() =>
        t('account.accountCompanies.deltionCompanyName', {
          name: toDeleteName.value
        })
      )

      const companies = computed(() =>
        store.getters['organizations/companies'].map((x: any) => ({
          ...x,
          current: actualCompany.value?.id === x.id
        }))
      )
      const pagination = computed(
        () => store.getters['organizations/companiesPagination']
      )
      const company = computed(() => store.getters['auth/company'])

      const getCompanies = () => {
        store.dispatch('organizations/GET_COMPANIES', {
          filter: { search: search.value }
        })
      }
      const onPageChange = (page: number) => {
        store.commit('organizations/SET_COMPANIES_PAGINATION_PAGE', page)
        getCompanies()
      }

      const columns = comnpaniesListColumns

      const hideEdit = (value: boolean) => {
        canEdit.value = value
      }
      const editCompany = async (company: Company) => {
        await store.dispatch('auth/GET_COMPANY', { companyId: company.id })
        canEdit.value = true
      }

      const displayModal = (cmp: CompanyModel) => {
        companyDeleteName.value = ''
        currentCompany.value = { ...cmp }
        canDelete.value = true
      }

      const closeModal = () => {
        companyDeleteName.value = ''
        currentCompany.value = null
        canDelete.value = false
      }

      const deleteCompany = (companyId: string) => {
        store.dispatch('organizations/REMOVE_COMPANY', {
          companyId,
          onSuccess: () => {
            if (actualCompany.value.id == companyId) {
              store.commit(`organizations/SET_COMPANY`, null)
            }
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('newCompany.title'),
              message: t('newCompany.apiResponse.deleted')
            })
          },
          onError: () => {
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('newCompany.title'),
              message: t(`newCompany.apiResponse.deletionError`)
            })
          }
        })
      }

      const loadCompanyInformations = async () => {
        store
          .dispatch(`organizations/GET_LOADER_COMPANIES`, {})
          .then(async () => {
            await store.dispatch(`organizations/GET_COMPANIES`, {}).then(() => {
              store.dispatch(`organizations/INIT_ORGANIZATION`, {
                companyId: route.params.id
              })
            })
          })
      }

      const submitDeletion = async () => {
        if (
          currentCompany.value &&
          currentCompany.value.id &&
          companyDeleteName.value === compareCompanieText.value
        ) {
          await deleteCompany(currentCompany.value.id)

          companyDeleteName.value = ''
          closeModal()
        }
      }

      const changeCurrentContext = async (cmp: CompanyModel) => {
        if (cmp && cmp.id) {
          store.commit(`organizations/SET_COMPANY`, cmp)
          router.push({
            name: route.name || undefined,
            params: { ...route.params, id: cmp.id }
          })
          await loadCompanyInformations()
          search.value = ''
          store.dispatch('toolbox/displayToast', {
            type: TYPE.DEFAULT,
            context: t('account.accountCompanies.context'),
            message: t('account.accountCompanies.updateContext', {
              name: cmp.name
            })
          })
        }
      }

      const handleNewCompany = () => {
        store.commit('auth/SET_COMPANY', null)
        outerVisible.value = true
      }

      watch(search, () => {
        store.commit('organizations/SET_COMPANIES_PAGINATION_PAGE', 1)
        getCompanies()
      })

      onMounted(() => {
        getCompanies()
      })

      return {
        search,
        outerVisible,
        columns,
        companies,
        company,
        canEdit,
        pagination,
        canDelete,
        currentCompany,
        toDeleteName,
        companyDeleteName,
        editCompany,
        hideEdit,
        handleNewCompany,
        onPageChange,
        displayModal,
        submitDeletion,
        changeCurrentContext,
        closeModal,
        moment
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import '@/scss/companies.scss';
</style>
