<template>
  <div class="h-full flex flex-col items-start w-full overflow-scroll">
    <div class="flex px-12">
      <h3 class="text text-blackp text-xl py-8">
        {{ $t('company.corporate') }}
      </h3>
    </div>
    <div class="h-full flex flex-col items-start w-full">
      <TabGroup
        :selected-index="selectedTab"
        as="div"
        class="w-full h-full"
        @change="resetFilters"
      >
        <TabList class="tabList">
          <Tab
            v-for="tab in tabList"
            :key="tab.key"
            v-slot="{ selected }"
            as="div"
          >
            <button
              :class="['tabBtn', selected ? 'selected' : 'not-selected']"
              @click="handleSelection(tab)"
            >
              {{ $t(`tabs.${tab.langKey}`) }} ({{ count[tab.type] }})
            </button>
          </Tab>
        </TabList>
        <TabPanels class="mt-2 w-full h-full">
          <TabPanel v-for="tab in tabList" :key="tab.key" class="h-full">
            <div class="w-full h-full p-8 px-12">
              <component
                :is="tab.component"
                :type="tab.type"
                :filters="filters"
                @update-count="count[$event.type] = $event.value"
              >
                <template #filters>
                  <filters :type="tab.type" @update-filters="updateFilters" />
                </template>
              </component>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { Button } from '@up.law/uplaw-ui'
  import { useRoute } from 'vue-router'
  import { TabPanel, TabPanels, TabGroup, TabList, Tab } from '@headlessui/vue'
  import DocumentsTab from './documentsTab.vue'
  import EventsTab from './eventsTab.vue'
  import Filters from './filters.vue'

  export default defineComponent({
    components: {
      Filters,
      TabPanel,
      TabPanels,
      TabGroup,
      TabList,
      Tab,
      Button
    },
    emits: ['on-selected'],
    setup(props, { emit }) {
      const count: any = ref({ document: 0, event: 0 })
      const store = useStore()
      const route = useRoute()
      const tabList = [
        {
          type: 'event',
          key: 'corporateEvents',
          langKey: 'corporateEvents',
          component: EventsTab
        },
        {
          type: 'document',
          key: 'corporateDocuments',
          langKey: 'corporateDocuments',
          component: DocumentsTab
        }
      ]

      const selectedTab = ref(0)
      const filters = ref<any>({ search: '' })
      const handleSelection = (data: any) => {
        console.log(data)
        if (data.type == 'document') selectedTab.value = 1
        else selectedTab.value = 0
        emit('on-selected', data.key)
      }
      const resetFilters = (tab: number) => {
        filters.value = {}
      }
      const updateFilters = async (e: any, type = 'document', field = '') => {
        if (field === 'search') {
          store.dispatch('portfolio/SEARCH_IN_DOCUMENTS', {
            companyIds: e?.companyIds,
            query: e.search
          })

          return
        }
        filters.value = { ...e }

        if (filters.value?.contactsId?.length) {
          filters.value['contactsId'] = filters.value?.contactsId.reduce(
            (acc: string[], x: string) => {
              const v = x.split('@_')
              acc.push(...v)
              return acc
            },
            []
          )
        }
        if (type == 'document') {
          await store.dispatch('portfolio/GET_DOCUMENTS', {
            filter: filters.value
          })
        } else {
          await store.dispatch('portfolio/GET_EVENTS', {
            isDraft: false,
            filter: { ...filters.value }
          })
        }
      }

      watch(
        () => route.query?.type,
        (val) => {
          if (val == 'event') selectedTab.value = 0
        }
      )

      onMounted(() => {
        if (route.query?.type == 'event') selectedTab.value = 0

        store.commit('ui/DISPLAY_PREVIEW', false)
      })

      return {
        tabList,
        selectedTab,
        filters,
        count,
        handleSelection,
        updateFilters,
        resetFilters
      }
    }
  })
</script>
<style lang="scss" scoped>
  @import './style.scss';
  .tabList {
    @apply px-12 w-full flex justify-start border-solid border-b border-secondary4 space-x-4;
    .tabBtn {
      padding: 0 24px;
      @apply pb-2;
      &.selected {
        @apply border-solid border-b-2 border-primary5 text-primary5;
        margin-bottom: -1px;
      }
      &.not-selected {
        @apply text-secondary9 hover:text-primary5;
      }
    }
  }
</style>
