
  import { Pagination } from '@/store/modules/Organization/models'
  import { computed, defineComponent, reactive, ref, watch } from 'vue'
  import Checkbox from '@/components/Checkbox/index.vue'
  import { ORDER_BY } from '@/utils/constants'
  import { ElPagination, ElTooltip, ElPopover } from 'element-plus'
  import EclipseLoader from '@/components/Loader/eclipse.vue'

  export interface Column {
    label: string
    field: string
    info?: string
    custom: boolean
    tooltipText?: string
    tooltipWidth?: string
    hasTooltip?: boolean
    customDefault?: boolean
    sortBy?: string
    sortFunction?: (a: any, b: any) => number
    type?: string
    width?: string
  }
  export default defineComponent({
    components: {
      Checkbox,
      EclipseLoader,
      ElPagination,
      ElTooltip,
      ElPopover
    },
    props: {
      // isLoading: { type: Boolean, required: false, default: false },
      indexKey: { type: String, required: false, default: 'id' },
      bodyClass: { type: String, required: false, default: 'bg-white' },
      lineClass: { type: String, required: false, default: 'bg-white' },
      textSize: { type: String, required: false, default: '' },
      paginationPageSize: { type: Number, required: false, default: 0 },
      containerClass: { type: String, required: false, default: 'relative' },
      theadClass: { type: String, required: false, default: 'relative' },
      cellPadding: { type: String, required: false, default: 'p' },
      currentColor: { type: String, required: false, default: '#0B9DA7' },
      activeColor: { type: String, required: false, default: '#C3FDFF' },
      loading: { type: Boolean, required: false, default: false },
      isFiltering: { type: Boolean, required: false, default: false },
      hasCheckbox: { type: Boolean, required: false, default: false },
      customData: { type: Boolean, required: false, default: false },
      checkedRows: { type: Object as () => string[] },
      onSelect: {
        type: Function,
        required: false,
        default: (values: any): void => {
          console.log('')
        }
      },
      columns: {
        type: Object as () => Column[],
        required: true
      },
      pagination: {
        type: Object as () => Pagination,
        required: false,
        default: () => ({ total: 0, currentPage: 1, lastPage: 1 })
      },
      rows: {
        type: Object as () => any[],
        required: true
      }
    },
    emits: [
      'onSort',
      'onSelect',
      'onPageChange',
      'update-table',
      'delete-action',
      'duplicate-action',
      'onSelect',
      'onFiltered'
    ],
    setup(props, { emit, slots }) {
      props = reactive(props)
      const orders = ref<string[]>(
        props.columns.map((x: any): string => x.field)
      )
      const hasFooter = computed(() => slots.footer !== undefined)
      const hasActions = computed(() => slots.actions !== undefined)
      const selectedRows = ref<Set<string>>(new Set(props.checkedRows || []))
      const selectAll = ref<boolean>(false)
      const isLoading = ref(props.isFiltering)
      const sortByColumns = ref<any>({})

      const pageSize = computed(
        () => props.pagination.limit || props.paginationPageSize
      )

      const onSelected = (value: string) => {
        if (selectedRows.value.has(value)) {
          selectedRows.value.delete(value)
        } else selectedRows.value.add(value)

        emit('onSelect', Array.from(selectedRows.value))
      }
      const isSelected = (value: string) => {
        return selectedRows.value.has(value)
      }
      const tableHeader = ref(props.columns)

      const data = ref(props.rows)
      const handlePageSelection = (page: number) => {
        selectedRows.value = new Set([])
        selectAll.value = false
        isLoading.value = true
        emit('onFiltered', true)
        emit('onPageChange', page)
      }

      const sortBy = (field: string) => {
        const type = tableHeader.value.find((x) => x.sortBy === field)?.type
        const func = tableHeader.value.find(
          (x) => x.sortBy === field
        )?.sortFunction

        data.value = [...data.value].sort((a, b) => {
          let value = 0
          if (sortByColumns.value[field] === undefined)
            sortByColumns.value[field] = ORDER_BY.DESC
          if (type === 'text') {
            if (sortByColumns.value[field] === ORDER_BY.DESC)
              return a[field].localeCompare(b[field])
            if (sortByColumns.value[field] === ORDER_BY.ASC)
              return b[field].localeCompare(a[field])
          } else if (type === 'function' && func) {
            if (sortByColumns.value[field] === ORDER_BY.DESC) return func(a, b)
            if (sortByColumns.value[field] === ORDER_BY.ASC) return func(b, a)
          } else {
            if (sortByColumns.value[field] === ORDER_BY.DESC) {
              value = 0
              if (a[field] > b[field]) {
                value = 1
              }
              if (b[field] > a[field]) {
                value = -1
              }
            }
            if (sortByColumns.value[field] === ORDER_BY.ASC) {
              value = 0
              if (a[field] > b[field]) {
                value = -1
              }
              if (b[field] > a[field]) {
                value = 1
              }
            }

            return value
          }
        })

        sortByColumns.value[field] =
          sortByColumns.value[field] === ORDER_BY.ASC
            ? ORDER_BY.DESC
            : ORDER_BY.ASC
      }
      watch(props, (newProps) => {
        tableHeader.value = newProps.columns
        orders.value = newProps.columns.map((x: any): string => x.field)
        selectedRows.value = new Set(newProps.checkedRows)
        data.value = newProps.rows
      })

      watch(
        () => data.value,
        () => {
          isLoading.value = false

          emit('onFiltered', false)
        }
      )

      watch(
        () => props.rows,
        () => {
          isLoading.value = false
        }
      )

      watch(
        () => props.isFiltering,
        (val) => {
          isLoading.value = val
        }
      )

      watch(selectAll, (isSelected) => {
        let rows: string[] = []
        if (isSelected) {
          rows = props.rows.map((row: any) => row[props.indexKey])
        }

        selectedRows.value = new Set(rows)

        emit('onSelect', Array.from(selectedRows.value))
      })
      return {
        tableHeader,
        selectedRows,
        hasFooter,
        hasActions,
        data,
        selectAll,
        orders,
        isLoading,
        sortByColumns,
        pageSize,
        onSelected,
        isSelected,
        sortBy,
        handlePageSelection
      }
    }
  })
