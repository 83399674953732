
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import DataBoxes from '@/components/CompanyOverview/DataBoxes.vue'
  import UplChart from '@/components/Graphs/UplChart.vue'
  import { CompanyOverviewExample } from '@/utils/test'
  import { useStore } from 'vuex'
  import {
    CompanyCapitalByGroup,
    CompanyCapitalByStockCategory,
    CompanyGlobalStockData,
    CompanyGroup
  } from '@/services/api/models'
  import { useRoute } from 'vue-router'
  import { useI18n } from 'vue-i18n'

  export default defineComponent({
    name: 'SocialCapitalTab',
    components: { DataBoxes, UplChart },
    props: {},
    setup() {
      const store = useStore()
      const route = useRoute()
      const { t } = useI18n()
      const isLoading = computed(
        () => store.getters['organizations/isLoadingCompanyData']
      )
      const loadingData = ref(false)
      const byGroupLoading = ref(true)
      const byCategoryLoading = ref(true)

      const data = computed(() => CompanyOverviewExample['capital'])

      const companyGlobalData = computed<CompanyGlobalStockData | null>(
        () => store.getters['organizations/companyGlobalData']
      )

      const capitalByGroup = computed<CompanyCapitalByGroup[]>(
        () => store.getters['organizations/companyCapitalByGroup']
      )

      const groups = computed<CompanyGroup[]>(
        () => store.getters['organizations/groups']
      )

      const formattedCapByGroup = computed(() =>
        capitalByGroup.value
          .map((x) =>
            x.groupName === 'zz-other'
              ? { ...x, groupName: t(`global.zz-others`) }
              : { ...x }
          )
          .sort((a, b) => b.groupName.localeCompare(a.groupName))
          .sort((a, b) => b.value - a.value)
      )

      const capitalByStockCategory = computed<CompanyCapitalByStockCategory[]>(
        () => store.getters['organizations/companyCapitalByStockCategory']
      )
      const formattedCapStockGrouped = computed(() =>
        capitalByStockCategory.value

          .map((x) =>
            x.categoryId === 'composition'
              ? { ...x, categoryName: t(`events.fieldsLabels.compositions`) }
              : { ...x }
          )
          .sort((a, b) => b.value - a.value)
      )
      const groupDatasets = computed(() => [
        {
          data: formattedCapByGroup.value.map((x) => x.value),
          backgroundColor: [
            '#8580F6',
            '#5c6dac',
            '#C3FDFF',
            '#aa80f6',
            '#5aa4ac',
            ...groups.value.map((x) => x.colorCode)
          ]
        }
      ])

      const categoryDatasets = computed(() => [
        {
          data: formattedCapStockGrouped.value.map((x) => x.value),
          hoverBorderColor: [
            '#8580F6',
            '#5c6dac',
            '#C3FDFF',
            '#aa80f6',
            '#5aa4ac',
            ...groups.value.map((x) => x.colorCode)
          ],
          backgroundColor: [
            '#8580F6',
            '#5c6dac',
            '#C3FDFF',
            '#aa80f6',
            '#5aa4ac',
            ...groups.value.map((x) => x.colorCode)
          ]
        }
      ])
      const groupLabels = computed(() =>
        formattedCapByGroup.value.map((x) => x.groupName)
      )

      const categoryLabels = computed(() =>
        formattedCapStockGrouped.value.map((x) => x.categoryName)
      )
      const informations = computed(() => ({
        socialCapital: {
          value: companyGlobalData.value?.shareCapital || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingData.value
        },
        nominalValue: {
          value: companyGlobalData.value?.nominalValue || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingData.value
        },
        nbNFD: {
          value: companyGlobalData.value?.totalNFD || 0,
          type: 'number',
          isLoading: loadingData.value
        },
        nbFD: {
          value: companyGlobalData.value?.totalFD || 0,
          type: 'number',
          isLoading: loadingData.value
        },
        emitPrime: {
          value: companyGlobalData.value?.totalIssuancePrimes || 0,
          type: 'currency',
          currency: 'EUR',
          isLoading: loadingData.value
        },
        nbShareholders: {
          value: companyGlobalData.value?.numberOfShareHolders || 0,
          type: 'number',
          isLoading: loadingData.value
        }
      }))

      const dataBoxes = [
        {
          icon: 'bi-cash-stack',
          key: 'socialCapital'
        },
        {
          icon: 'bi-cash-stack',
          key: 'nominalValue'
        },
        {
          icon: 'bi-pie-chart-fill',
          key: 'nbNFD'
        },
        {
          icon: 'bi-pie-chart-fill',
          key: 'nbFD'
        },
        // {
        //   icon: 'bi-cash-stack',
        //   key: 'emitPrime'
        // },
        {
          icon: 'bi-people-fill',
          key: 'nbShareholders'
        }
      ]

      const getInformations = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_COMPANY_STOCKS_DATA', {
          companyId,
          onSuccess: () => {
            loadingData.value = false
          }
        })
      }

      const getCapitalByGroup = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_COMPANY_CAPITAL_BY_GROUP', {
          companyId,
          onSuccess: () => {
            byGroupLoading.value = false
          },
          onError: () => {
            byGroupLoading.value = false
          }
        })
      }

      const getCapitalByStockCategory = (companyId = route.params.id) => {
        store.dispatch('organizations/GET_COMPANY_CAPITAL_BY_STOCK_CATEGORY', {
          companyId,
          onSuccess: () => {
            byCategoryLoading.value = false
          },
          onError: () => {
            byCategoryLoading.value = false
          }
        })
      }

      const getData = (companyId = route.params.id) => {
        loadingData.value = true
        getInformations(companyId)
        getCapitalByStockCategory(companyId)
        getCapitalByGroup(companyId)
      }
      watch(
        () => route.params.id,
        (val) => {
          getData(val)
        }
      )
      onMounted(() => {
        getData()
      })

      return {
        data,
        loadingData,
        informations,
        dataBoxes,
        isLoading,
        byCategoryLoading,
        byGroupLoading,
        categoryDatasets,
        categoryLabels,
        groupDatasets,
        groupLabels
      }
    }
  })
