
  import { computed, defineComponent, onMounted, ref, watch } from 'vue'
  import { Form, Field } from 'vee-validate'
  import { Input } from '@up.law/uplaw-ui'
  import Multiselect from '@/components/Select/multiselect.vue'
  import {
    CompanyGroup,
    ContactStatus,
    InvestorContact,
    LegalStatus,
    ProfessionalContact
  } from '@/services/api/models'
  import { useStore } from 'vuex'
  import { CONTACTTYPE, InvestorContactForm } from '@/types'
  import { CompanyModel } from '@/services/api/models1'
  import FormActions from '@/components/Forms/Fields/ModalActions.vue'
  import { investorSchema } from '@/utils'
  import ProfileAvatarForm from './ProfileAvatarForm.vue'
  import { useAcl } from 'vue-simple-acl/src'

  export default defineComponent({
    components: {
      Form,
      Field,
      Input,
      Multiselect,
      FormActions,
      ProfileAvatarForm
    },
    props: {
      contact: {
        type: Object as () => InvestorContact | null,
        required: false,
        default: () => null
      },
      topAlignClass: { type: String, default: '' }
    },
    emits: ['submitForm', 'fileChanged', 'onCancel'],
    setup(props, { emit }) {
      const store = useStore()
      const acl = useAcl()
      const canDo = computed(
        () =>
          acl.role('admin') ||
          (acl.permission('is-manager') && acl.permission('write-contacts')) ||
          acl.permission('employee-writeContact')
      )
      const newValues = (): InvestorContactForm => ({
        id: props.contact?.id,
        name: props.contact?.name || '',
        representativeContactId:
          props.contact?.representativeContact?.id || null,
        legalStatusId: props.contact?.legalStatus?.id || '',
        type: CONTACTTYPE.INVESTOR,
        contactGroupsId: props.contact?.contactGroups?.map((x) => x.id) || [],
        contactStatusId: null,
        website: props.contact?.website,
        email: props.contact?.email,
        phoneNumber: props.contact?.phoneNumber,
        internalId1: props.contact?.internalId1 || null,
        internalId2: props.contact?.internalId2 || null,
        mappingId: props.contact?.mappingId || null
      })
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const newContact = ref(newValues())
      const status = computed<ContactStatus[]>(
        () => store.getters['toolbox/contactStatus']
      )
      const modalForm = computed(() => store.getters['organizations/form'])

      const allContacts = computed<ProfessionalContact[]>(
        () => store.getters['organizations/contacts']
      )

      const contacts = computed(() =>
        allContacts.value.filter((x) => x.type === CONTACTTYPE.PROFESSIONAL)
      )

      const groups = computed<CompanyGroup[]>(
        () => store.getters['organizations/groups']
      )

      const legalStatus = computed<LegalStatus[]>(
        () => store.getters['toolbox/legalStatus']
      )
      const investorLegalStatus = computed<LegalStatus[]>(() =>
        legalStatus.value.filter((x) => x.contactType === CONTACTTYPE.INVESTOR)
      )
      const investorContactGroups = computed(() =>
        status.value.filter((x) => x.type === CONTACTTYPE.INVESTOR)
      )

      const schema = computed(() => investorSchema)

      const handleForm = async (values: InvestorContactForm) => {
        const form = {
          ...values,
          contactGroupsId: newContact.value.contactGroupsId,
          email: values.email === '' ? null : values.email,
          type: CONTACTTYPE.INVESTOR
        }
        emit('submitForm', form)
      }

      const handleSearch = (e: string) => {
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            type: CONTACTTYPE.PROFESSIONAL,
            name: e,
            limit: 10
          }
        })
      }

      const handleReset = () => {
        newContact.value = newValues()
        emit('onCancel')
      }

      const fileChanged = (file: File) => {
        emit('fileChanged', file)
      }

      const groupChanged = (data: []) => {
        newContact.value.contactGroupsId = data
      }

      watch(props, (newProps) => {
        if (newProps.contact) {
          newContact.value = {
            ...newProps.contact,
            contactGroupsId:
              newProps.contact.contactGroups?.map((x) => x.id) ||
              newContact.value.contactGroupsId ||
              [],
            contactStatusId: newProps.contact.status?.id || '',
            legalStatusId: newProps.contact.legalStatus.id || '',
            representativeContactId:
              newProps.contact.representativeContact.id || '',
            website: props.contact?.website,
            email: props.contact?.email,
            phoneNumber: props.contact?.phoneNumber,
            internalId1: newProps.contact.internalId1 || '',
            internalId2: newProps.contact.internalId2 || '',
            mappingId: newProps.contact.mappingId || ''
          }
        } else {
          newContact.value = newValues()
        }
      })

      watch(modalForm, (value) => {
        if (value.modalInit) {
          newContact.value = newValues()
          return
        }
      })

      onMounted(() => {
        handleSearch('')
      })

      return {
        newContact,
        canDo,
        investorContactGroups,
        groups,
        investorLegalStatus,
        contacts,
        schema,
        fileChanged,
        handleSearch,
        handleForm,
        handleReset,
        groupChanged
      }
    }
  })
