
  import { defineComponent, computed, ref, onMounted, watch } from 'vue'
  import moment from 'moment'
  import { formatNumber } from '@/utils'
  import { useStore } from 'vuex'
  import { CompanyModel, Event, EventPartsTotal } from '@/services/api/models'
  import { useRoute } from 'vue-router'
  import { Pagination } from '@/store/modules/Organization/models'
  import Editable from './editable/index.vue'
  import { useI18n } from 'vue-i18n'
  import { NumberType, OptionExercise, OptionExercisePart } from '@/types'
  import { TYPE } from 'vue-toastification'
  import DropdownContacts from '@/components/Forms/DropDowns/DropdownContacts.vue'
  import { ElPagination } from 'element-plus'
  import Loader from '@/components/Loader/small.vue'
  import EditableHeader from './editable/header.vue'
  import { DeliveryStatus } from '@/types/vesting'
  export default defineComponent({
    name: 'TransferCard',
    components: {
      DropdownContacts,
      Editable,
      Loader,
      EditableHeader,
      ElPagination
    },
    props: {
      event: {
        type: Object as () => Event | null,
        required: true
      },
      nominalValue: {
        type: Number,
        default: 0
      },
      filters: {
        type: Object as () => any,
        default: () => ({})
      },
      isPortfolio: {
        type: Boolean,
        default: false
      },
      canEdit: { type: Boolean, default: false }
    },
    setup(props) {
      const route = useRoute()
      const store = useStore()
      const { t } = useI18n()
      const total = ref<EventPartsTotal | null>(null)
      const selectedContacts = ref<string[]>([])
      const isLoadingParts = ref(true)
      const formData = ref<any>({
        issuanceDate: props.event?.optionExercise?.issuanceDate
      })
      const allParts = ref<OptionExercisePart[]>([])

      const newLines = ref<any[]>([])
      const issuanceParts = ref<any[]>(
        props.event?.optionExercise?.optionExerciseParts ?? []
      )
      const limit = computed<number>(
        () => store.getters['config/eventPartsLimit']
      )
      const newSavedHeader = ref<OptionExercise | null>(null)
      const pagination = ref<Pagination>({
        total: 0,
        currentPage: 1,
        lastPage: 1,
        limit: limit.value
      })
      const currency = computed(() => store.getters['organizations/currency'])

      const company = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const canEditHeader = ref(false)
      const isLocal = ref(false)

      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const filteredPartsId = computed<string[]>(() =>
        ((props.filters as any)?.contactsId || []).flatMap((x: string) =>
          issuanceParts.value.filter((p) => p.contact.id == x).map((y) => y.id)
        )
      )

      const editedLinesData = ref<any[]>([])

      const totalLine = computed(() => {
        const v = [
          {
            name: 'Total',
            class: 'col-span-4 text-sm',
            key: 'total',
            width: '20%'
          },
          {
            name: '',
            key: 'quantity',
            value: formatNumber(total.value?.quantity ?? 0),
            class: 'text-sm',
            width: '10%'
          },

          {
            name: '',
            key: 'issuedQuantity',
            value: formatNumber(total.value?.issuedQuantity ?? 0),
            class: 'text-sm',
            width: '10%'
          },

          {
            name: '',
            key: 'unitPrice',
            value: '-',
            width: '10%',
            class: 'text-sm'
          },
          {
            name: '',
            key: 'total',
            value: `${formatNumber(total.value?.totalPrice ?? 0)}${
              currency.value
            }`,
            width: '10%',
            class: 'text-sm'
          },
          {
            name: '',
            key: 'entryDate',
            value: '-',
            width: '10%',
            class: 'text-sm'
          }
        ]

        if (props.event?.applyDocumentAutomation) {
          v.push({
            name: t('docAutomation.fields.status'),
            width: '10%',
            class: 'justify-end col-span-2',
            key: 'deliveryStatus',
            value: '-'
          })
          v.push({
            name: t('docAutomation.fields.docautoAction'),
            width: '10%',
            value: '-',
            class: 'justify-end col-span-2',
            key: 'automationActions'
          })
        }

        if (props.canEdit)
          v.push({
            name: '',
            key: 'actions',
            value: '-',
            class: 'col-span-2',
            width: '20%'
          })

        return v
      })

      const data = computed(() => {
        const v = [
          {
            name: t('events.elements.contact'),
            id: 'contactId',
            width: '25%',
            class: 'text-left col-span-2',
            component: 'ClickableContactBadge',
            displayComponent: 'contact'
          },

          {
            name: t('events.elements.option'),
            width: '12.5%',
            id: 'stock',
            class: 'justify-start col-span-2',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.quantity'),
            width: '12.5%',
            class: 'justify-end',
            id: 'quantity',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.issuedQuantity'),
            width: '12.5%',
            class: 'justify-end',
            id: 'issuedQuantity',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.unitPrice'),
            width: '12.5%',
            id: 'unitPrice',
            class: 'justify-end',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.totalAmount'),
            width: '12.5%',
            class: 'justify-end',
            id: 'total',
            component: 'contact-generic'
          },
          {
            name: t('events.elements.entryDate'),
            width: '12.5%',
            class: 'justify-end',
            id: 'entryDate',
            component: 'contact-generic'
          }
          // {
          //   name: t('events.elements.entryDate'),
          //   width: '11.11%',
          //   class: 'justify-end',
          //   id: 'entryDate',
          //   component: 'contact-generic',
          //   displayComponent: 'contact'
          // }
        ]

        if (props.event?.applyDocumentAutomation) {
          v.push({
            name: t('docAutomation.fields.status'),
            width: '10%',
            class: 'justify-end col-span-2',
            id: 'deliveryStatus',
            component: 'p',
            displayComponent: 'p'
          })
          v.push({
            name: t('docAutomation.fields.docautoAction'),
            width: '10%',
            class: 'justify-end col-span-2',
            id: 'automationActions',
            component: 'actions',
            displayComponent: 'contact'
          })
        }

        if (props.canEdit)
          v.push({
            name: '',
            width: '22.22%',
            class: 'justify-end col-span-2',
            id: 'actions',
            component: 'actions',
            displayComponent: 'contact'
          })

        return v
      })

      const headerFields = computed(() => [
        {
          fieldId: 'issuanceDate',
          class: 'justify-start',
          colName: t('events.fieldsLabels.issuanceDate'),
          component: 'p',
          editComponent: 'date-generic',
          componentProps: {
            class: 'text-blackp text-sm text-left',
            value: isLocal.value
              ? moment.utc(newSavedHeader.value?.issuanceDate).format('l')
              : moment
                  .utc(props.event?.optionExercise?.issuanceDate)
                  .format('l')
          },
          editComponentProps: {
            classes: 'mt-1.5 w-1/5',
            openDirection: 'bottom',
            value:
              newSavedHeader.value?.issuanceDate ??
              props.event?.optionExercise?.issuanceDate,
            data: { name: 'issuanceDate', placeholder: '' },
            onUpdated: (data: any) => {
              formData.value['issuanceDate'] = data.value
            }
          }
        }
      ])

      const updateSelected = (ids: string[]) => {
        selectedContacts.value = ids
        getParts(props.event?.optionExercise?.id, 1, ids)
        getTotal()
      }

      const startLineEdition = (partId: string, index: number) => {
        let part = issuanceParts.value.find((x) => x.id == partId)

        store.dispatch('organizations/GET_CONTACT_EXERCISE_CALENDARS', {
          companyId: route.params.id,
          filter: { contactId: part.contact?.id, date: moment().toDate() }
        })
      }

      const addNewLine = (fromPartId?: string) => {
        let newL = issuanceParts.value.find((x) => x.id == fromPartId)
        let index = newLines.value.length + 1
        while (newLines.value.find((x) => x.newIndex === index)) {
          index++
        }
        newLines.value.push(
          newL ?? {
            index,
            id: null,
            contact: null,
            unitPrice: 0,
            totalAmount: 0,
            quantity: 0,
            releasedAmount: 0,
            nominalIncrease: 0,
            totalIssuancePremium: 0,
            issuancePremiumPerAction: 0
          }
        )
      }

      const removeNewLine = (index: number) => {
        newLines.value = newLines.value.filter((x) => x.index != index)
      }

      const getTotal = () => {
        if (!props.event?.optionExercise?.id) return
        store.dispatch('organizations/GET_OPTION_EXERCISE_PARTS_TOTAL', {
          companyId: currentCompany.value.id,
          optionExerciseId: props.event?.optionExercise?.id,
          filter: { contactsId: selectedContacts.value },
          onSuccess: (response: EventPartsTotal) => {
            total.value = response
          },
          onError: () => {
            //
          }
        })
      }

      const saveNewLine = (
        index: number,
        arrayIndex: number,
        onSuccess: any,
        onError: any
      ) => {
        const d = editedLinesData.value.find((e) => e.newIndex == index)
        if (!d) {
          onError()
          store.dispatch('toolbox/displayToast', {
            type: TYPE.ERROR,
            context: t('events.context'),
            message: t(`events.apiResponse.lineEditError`)
          })

          return
        }
        const upData = { ...d }
        for (const property in upData) {
          if (!upData[property]) delete upData[property]
        }

        upData['unitPrice'] = parseFloat(upData.unitPrice.replace(',', '.'))

        store.dispatch('organizations/ADD_OPTION_EXERCISE_PART', {
          companyId: currentCompany.value.id,
          optionExerciseId: props.event?.optionExercise?.id,
          data: upData,
          onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response)
            issuanceParts.value.unshift({
              id: response.id,
              contact: response.contact,
              optionAlias: response.optionAlias,
              unitPrice: response.unitPrice,
              totalAmount: response.totalAmount,
              total: response.totalAmount,
              quantity: response.quantity,
              entryDate: response.entryDate,
              issuedQuantity: response.issuedQuantity,
              releasedAmount: response.releasedAmount,
              nominalIncrease: response.nominalIncrease,
              totalIssuancePremium: response.totalIssuancePremium,
              issuancePremiumPerAction: response.issuancePremiumPerAction
            })
            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditSuccess`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditError`)
            })
          }
        })
      }

      const saveEventPrimaryInfo = (onSuccess: any, onError: any) => {
        const data = {
          optionExerciseId: formData.value?.optionExercise?.id,
          sellerId: formData.value?.sellerId?.id,
          ...formData.value
        }
        for (const property in data) {
          if (!data[property]) delete data[property]
        }

        store.dispatch('organizations/UPDATE_OPTION_EXERCISE_PRIMARY_INFO', {
          companyId: currentCompany.value.id,
          optionExerciseId: props.event?.optionExercise?.id,
          data: data,
          onSuccess: (response: any) => {
            if (onSuccess) onSuccess(response)

            newSavedHeader.value = { ...response }
            canEditHeader.value = false
            isLocal.value = true
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.primaryInfoEdited`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.primaryInfoError`)
            })
          }
        })
      }

      const saveLine = (partId: string, onSuccess: any, onError: any) => {
        const line = issuanceParts.value.find((e) => e.id == partId)
        const d = editedLinesData.value.find((e) => e.partId == partId)

        const upData = {
          contactId: line?.contact?.id,
          optionAliasId: line?.optionAlias?.id ?? d?.optionAliasId,
          ...d,
          ...line
        }

        const [stockId, price, exerciseCalendarId] = upData.stock.split('@_')

        upData.optionAliasId = stockId
        upData.unitPrice = parseFloat(price.replace(',', '.'))
        upData.exerciseCalendarId = exerciseCalendarId

        delete upData.commitment
        delete upData.optionAlias
        delete upData.contact
        delete upData.exerciseCalendar
        delete upData.compositionAlias
        for (const property in upData) {
          if (!upData[property]) delete upData[property]
        }

        store.dispatch('organizations/UPDATE_OPTION_EXERCISE_PART', {
          companyId: currentCompany.value.id,
          optionExerciseId: props.event?.optionExercise?.id,
          partId,
          data: upData,
          onSuccess: (response: any) => {
            const idx = issuanceParts.value.findIndex((x) => x.id == partId)
            issuanceParts.value[idx] = { ...response }
            if (onSuccess) onSuccess(response)
            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditSuccess`)
            })
          },
          onError: (err: any) => {
            if (onError) onError(err)
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineEditError`)
            })
          }
        })
      }

      const handleFieldEdition = (key: any, value: any, part: any) => {
        let lineIndex = -1
        if (!part?.id) {
          lineIndex = editedLinesData.value.findIndex(
            (e) => e.newIndex == part.index
          )
        } else {
          lineIndex = editedLinesData.value.findIndex(
            (e) => e.partId == part.id
          )
        }

        const elm = {
          ...part,
          [key]: value,
          partId: part.id,
          newIndex: part.index
        }
        if (lineIndex != -1) {
          editedLinesData.value[lineIndex] = { ...elm }
        } else
          editedLinesData.value.push({
            ...elm
          })
      }

      const columns = computed(() =>
        data.value.map((x) => ({
          name: x.name,
          width: x.width,
          class: x.class,
          id: x.id
        }))
      )

      const lines = computed(() =>
        [...newLines.value, ...issuanceParts.value].map((x, index) => ({
          selected: contactsId.value.includes(x.contact?.id),
          partId: x?.id,
          newIndex: x?.index,
          contactId: {
            class: 'justify-start',
            colName: 'contactId',
            component: 'ClickableContactBadge',
            editComponent: 'contact-generic',
            componentProps: {
              company: props.event?.company,
              class: 'p-0 text-xs overflow-hidden',
              details: false,
              type: 'light',
              contact: x.contact
            },
            editComponentProps: {
              classes: ' w-full',
              details: false,
              type: 'light',
              value: x?.contact?.id,
              contact: x.contact,
              data: { name: 'contactId', hint: '' },
              onUpdated: (data: any) => {
                x.contactId = data.value

                handleFieldEdition(data.name, data.value, x)

                store.dispatch('organizations/GET_CONTACT_EXERCISE_CALENDARS', {
                  companyId: route.params.id,
                  filter: { contactId: data.value, date: moment().toDate() }
                })
              },
              lineData: {
                contactIdSelection: x.contact
              }
            },
            displayComponent: '',
            partId: x?.id
          },
          stock: {
            class: 'justify-start',
            colName: 'stock',
            component: 'tag',
            editComponent: 'dropdown-options',
            componentProps: {
              class: 'w-full',
              parentClass: 'text-blackp text-sm text-left',
              withInitials: false,
              value: x.optionAlias?.id,
              text: x.optionAlias?.alias
            },
            editComponentProps: {
              optionData: {
                value: x?.contact?.id ?? x.contactId
              },
              lineData: {
                ...x,
                contactId: x?.contact?.id ?? x.contactId,
                stockIdSelection: x.optionAlias
              },
              dataFromExtra: true,
              openDirection: 'bottom',
              displayAll: false,
              mode: 'single',
              canAddNew: false,
              classes: 'w-full',
              class: 'w-full',
              hasLabel: false,
              value: `${x.optionAlias?.id}@_${formatNumber(
                x.exerciseCalendar?.optionIssuancePart.optionIssuance
                  .exercisePrice
              )}@_${x?.exerciseCalendar?.id ?? ''}`,
              data: { name: 'stockId', hint: '' },
              onUpdated: (data: any) => {
                if (data.component == 'dropdown-exercisePrice') {
                  //
                } else {
                  const [stock, exoPrice, exerciseCalendarId] =
                    data.value.split('@_')
                  x.unitPrice = formatNumber(exoPrice)
                  x.total =
                    parseFloat(x.unitPrice.replace(',', '.')) * x.issuedQuantity
                  x.exerciseCalendarId = exerciseCalendarId
                  x.optionAliasId = stock
                  x.stock = data.value
                  formData.value['optionAliasId'] = stock
                }
              },
              index: index,
              allData: formData.value
            },
            partId: x?.id
          },
          quantity: {
            class: 'justify-end',
            colName: 'quantity',
            component: 'number',
            editComponent: 'input-generic',
            componentProps: {
              class: 'text-sm text-right',
              value: x.quantity ?? 0,
              mode: NumberType.quantity
            },
            editComponentProps: {
              data: { name: 'quantity', hint: '' },
              classes: 'w-full',
              value: x.quantity,
              onUpdated: (data: any) => {
                x['quantity'] = !data.value ? '' : parseInt(data.value)

                handleFieldEdition(data.name, parseInt(data.value), x)
              }
            },

            displayComponent: '',
            partId: x?.id
          },
          issuedQuantity: {
            class: 'justify-end',
            colName: 'quantity',
            component: 'number',
            editComponent: 'input-generic',
            componentProps: {
              class: 'text-sm text-right',
              value: x.issuedQuantity ?? 0,
              mode: NumberType.quantity
            },
            editComponentProps: {
              data: { name: 'issuedQuantity', hint: '' },
              classes: 'w-full',
              value: x.issuedQuantity,
              onUpdated: (data: any) => {
                x['issuedQuantity'] = !data.value ? '' : parseInt(data.value)
                x['total'] = !data.value
                  ? ''
                  : data.value
                  ? parseFloat(x.unitPrice.replace(',', '.')) *
                    parseInt(data.value)
                  : 0
                handleFieldEdition(data.name, parseInt(data.value), x)
              }
            },

            displayComponent: '',
            partId: x?.id
          },
          total: {
            class: 'justify-end',
            colName: 'totalAmount',
            component: 'number',
            componentProps: {
              class: 'text-sm',
              value: x.unitPrice * x.issuedQuantity,
              mode: NumberType.price
            },
            editComponent: 'input-generic',
            editComponentProps: {
              data: { name: 'total', hint: '' },
              classes: 'w-full',
              value: x.total,
              onUpdated: (data: any) => {
                x['unitPrice'] =
                  x.quantity && x.total && data.value
                    ? parseInt(data.value) / parseInt(x.issuedQuantity)
                    : 0
                x['total'] = !data.value ? '' : parseInt(data.value)

                handleFieldEdition(data.name, parseInt(data.value), x)
              }
            },
            displayComponent: '',
            partId: x.id
          },
          unitPrice: {
            class: 'justify-end',
            colName: 'unitPrice',
            component: 'number',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.unitPrice ?? calculateUnitPrice(x),
              mode: NumberType.price
            },
            editComponentProps: {
              data: { name: 'unitPrice', hint: '' },
              classes: 'w-full',
              disabled: true,
              value:
                x.unitPrice ??
                parseFloat(calculateUnitPrice(x)?.toString()).toFixed(2),
              onUpdated: (data: any) => {
                x['unitPrice'] = !data.value ? '' : parseFloat(data.value)
                x['total'] = !data.value
                  ? ''
                  : parseFloat(data.value) ?? 0 * x.quantity
                handleFieldEdition(data.name, parseFloat(data.value), x)
              }
            },
            editComponent: 'input-generic',
            displayComponent: '',
            partId: x.id
          },
          entryDate: {
            class: 'justify-end',
            colName: 'stock',
            component: 'p',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.entryDate ? moment.utc(x.entryDate).format('l') : '-'
            },
            editComponent: 'date-generic',
            editComponentProps: {
              data: { name: 'entryDate', hint: '' },
              classes: 'w-full',
              value: x.entryDate,
              onUpdated: (data: any) => {
                x.entryDate = data.value
                handleFieldEdition(data.name, data.value, x)
              }
            },
            displayComponent: '',
            partId: x.id
          },
          deliveryStatus: {
            class: 'justify-end',
            colName: 'stock',
            component: 'p',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.deliveries
                ? t(`docAutomation.status.${x.deliveries[0]?.status}`)
                : '-'
            },
            editComponent: 'p',
            editComponentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.deliveries
                ? t(`docAutomation.status.${x.deliveries[0]?.status}`)
                : ''
            },
            partId: x.id
          },
          automationActions: {
            class: 'justify-end',
            colName: 'stock',
            component: 'automationActions',
            componentProps: {
              deleveryId: x.deliveries ? x.deliveries[0]?.id : null,
              class: 'text-blackp text-sm text-left',
              disabled:
                !x.deliveries ||
                x.deliveries[0]?.status != DeliveryStatus.completed,
              actions: [
                ...(x.deliveries
                  ? [
                      [DeliveryStatus.completed].includes(
                        x.deliveries[0]?.status
                      )
                        ? 'download'
                        : ''
                    ]
                  : [])
              ].filter((x) => x)
            },
            editComponent: 'p',
            partId: x.id
          },

          actions: {
            class: 'justify-end',
            colName: 'stock',
            component: 'actions',
            componentProps: {
              class: 'text-blackp text-sm text-left',
              value: x.vestingCalendar?.name || '-'
            },
            editComponent: 'dropdown-vesting',
            partId: x.id
          }
        }))
      )

      const isDetailPage = computed(() => route.name === 'company.eventDetails')

      const parts = computed<any[]>(
        () => props.event?.optionExercise?.optionExerciseParts || []
      )

      const isOverLimit = computed(
        () => !isDetailPage.value && parts.value.length > 20
      )

      const calculateUnitPrice = (row: any) => {
        if (!row.totalAmount && !row.quantity) return 0

        return row.totalAmount / row.quantity
      }

      const contactsId = computed<string[]>(
        () => (props.filters as any)?.contactsId || []
      )
      const resetLine = (index: number, partId: string) => {
        const oldLine = allParts.value.find((x) => x.id == partId)
        issuanceParts.value[index] = { ...oldLine }
      }
      const handlePageSelection = (page: number) => {
        getParts(props.event?.optionExercise?.id, page, [])
      }

      const removePart = (partId: string, onSuccess: any, onError: any) => {
        let id = props.event?.optionExercise?.id
        store.dispatch(`organizations/DELETE_OPTION_EXERCISE_PART`, {
          companyId: company.value?.id,
          optionExerciseId: id,
          partId,
          onSuccess: () => {
            if (onSuccess) onSuccess()
            issuanceParts.value = [
              ...issuanceParts.value.filter((x) => x.id !== partId)
            ]

            getTotal()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.SUCCESS,
              context: t('events.context'),
              message: t(`events.apiResponse.lineDeleteSuccess`)
            })
          },
          onError: () => {
            if (onError) onError()
            store.dispatch('toolbox/displayToast', {
              type: TYPE.ERROR,
              context: t('events.context'),
              message: t(`events.apiResponse.lineDeleteError`)
            })
          }
        })
      }

      const getParts = (
        id: string | undefined,
        page = 1,
        contactsId: string[]
      ) => {
        if (!id) return

        isLoadingParts.value = true
        store.dispatch('organizations/GET_OPTION_EXERCISE_PARTS', {
          companyId: company.value?.id,
          optionExerciseId: id,
          page,
          filter: { contactsId },
          limit: isDetailPage.value ? 100 : null,
          onSuccess: (response: any, pageLimit = 0) => {
            const { data, metadata } = response
            pagination.value = {
              ...metadata,
              total: metadata?.count,
              limit: pageLimit
            }
            allParts.value = JSON.parse(JSON.stringify(data))

            data.forEach((p: OptionExercisePart) => {
              store.commit('organizations/UPDATE_CONTACT_EXERCISE_CALENDARS', {
                contactId: p.contact.id,
                data: [
                  { id: p.exerciseCalendar.id, optionAlias: p?.optionAlias }
                ]
              })
            })

            issuanceParts.value = [...data]
            isLoadingParts.value = false
          },
          onError: () => {
            isLoadingParts.value = false
          }
        })
      }

      watch(
        () => props.event,
        () => {
          getParts(props.event?.optionExercise?.id, 1, [])
          getTotal()
        }
      )

      watch(
        () => props.canEdit,
        (val) => {
          canEditHeader.value = val
        }
      )

      onMounted(() => {
        getParts(props.event?.optionExercise?.id, 1, [])
        getTotal()
      })

      return {
        lines,
        filteredPartsId,
        canEditHeader,
        totalLine,
        isDetailPage,
        selectedContacts,
        pagination,
        columns,
        headerFields,
        isLoadingParts,
        isOverLimit,
        updateSelected,
        removeNewLine,
        resetLine,
        saveEventPrimaryInfo,
        addNewLine,
        handlePageSelection,
        saveNewLine,
        startLineEdition,
        saveLine,
        removePart
      }
    }
  })
