
  import {
    defineComponent,
    computed,
    ref,
    toRefs,
    watch,
    onMounted,
    onBeforeMount
  } from 'vue'
  import Multiselect from '@/components/Select/multiselect.vue'
  import ContactBadge from '@/components/ContactBadge/index.vue'
  import { Tag } from '@up.law/uplaw-ui'
  import { MAINACTIONS, MULTISELECTMODE } from '@/types'
  import { useStore } from 'vuex'
  import { CompanyModel, Contact } from '@/services/api/models'
  import { useRoute } from 'vue-router'

  export default defineComponent({
    name: 'DropdownContacts',
    components: {
      Multiselect,
      ContactBadge,
      Tag
    },
    props: {
      elements: {
        type: Array as () => Contact[],
        default: () => [],
        required: false
      },
      disabled: Boolean,
      modelValue: {
        required: true,
        type: [Array, Number, String, Object as () => null]
      },
      mode: {
        required: false,
        type: String,
        default: MULTISELECTMODE.SINGLE
      },
      classItem: {
        type: String,
        default: 'w-full'
      },
      placeholder: {
        type: String,
        default: ''
      },
      filterResults: { type: Boolean, default: true },
      canAddNew: { type: Boolean, default: true },
      globalDisplay: { type: Boolean, default: true },
      openDirection: {
        type: String,
        default: 'top'
      },
      validationState: { type: String, default: '' }
    },
    emits: ['update:modelValue', 'search-change'],
    setup(props, { emit }) {
      const { modelValue } = toRefs(props)
      const store = useStore()
      const route = useRoute()
      const searchText = ref('')
      const currentCompany = computed<CompanyModel>(
        () => store.getters['organizations/contextCompany']
      )
      const dataContacts = ref<any[]>([])
      const contactsId = ref<any>(
        props.mode === MULTISELECTMODE.SINGLE
          ? [props.modelValue]
          : props.modelValue
      )

      const selectedContacts = ref<any[]>([])

      const defaultContacts = ref<Contact[]>([])

      const contactBase = computed<Contact[]>(() => [...(props.elements ?? [])])

      const contacts = computed<any[]>(() =>
        [
          ...(props.globalDisplay
            ? [...dataContacts.value]
            : contactBase.value),
          ...selectedContacts.value,
          ...defaultContacts.value
        ].reduce((acc: any[], user: any) => {
          if (!acc.map((x) => x.id).includes(user.id))
            acc.push({
              id: user.id,
              name: user.name,
              value: user.id,
              type: user.type
            })
          return acc
        }, [])
      )

      const addActionSlot = computed(() =>
        props.mode === 'top' ? 'beforelist' : 'afterlist'
      )

      const value = ref<any>(modelValue.value)

      const updateValue = (val: any, update = true) => {
        value.value = val
        if (props.mode === MULTISELECTMODE.SINGLE) {
          const ct: Contact | null = contacts.value.find((x) => x.id === val)
          if (ct) selectedContacts.value.push(ct)
        } else {
          selectedContacts.value = [
            ...contacts.value,
            ...props.elements
          ].filter((x) => val.includes(x.id))

          val.forEach((element: string) => {
            const ct: Contact | null = contacts.value.find(
              (x) => x.id === element
            )
            if (ct && !selectedContacts.value.map((x) => x.id).includes(ct.id))
              selectedContacts.value.push(ct)
          })
        }

        if (update) {
          value.value = val
          emit('update:modelValue', val)
        }
      }

      const getFullname = (option: any) => {
        return `${option.fullname}`
      }

      const canDisplayContactModal = computed(
        () =>
          store.getters['ui/isModalOpen'] &&
          store.getters['ui/modalContentType'] === MAINACTIONS.CONTACT
      )

      const openModal = () => {
        store.dispatch('ui/toggleModal', {
          display: true,
          contentType: MAINACTIONS.CONTACT
        })
      }

      const getContacts = async (search = '') => {
        if (!currentCompany.value) return
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value.id,
          filter: {
            limit: 10,
            offset: 0,
            name: search
          },
          onSuccess: (result: any[]) => {
            dataContacts.value = [...result]
          }
        })
      }

      const getContact = async (id: any) => {
        if (!id || id?.split('-').length <= 2 || Array.isArray(id)) return

        store.dispatch('organizations/GET_COMPANY_CONTACT', {
          companyId: currentCompany.value?.id || route.params.id,
          contactId: id,
          onSuccess: (contact: Contact) => {
            if (!defaultContacts.value.map((c) => c.id).includes(contact?.id))
              defaultContacts.value.push(contact)
          },
          onError: (err: any) => {
            console.log('err0=>', err)
          }
        })
      }

      const handleSearch = (e: string) => {
        if (props.filterResults || !props.globalDisplay) {
          emit('search-change', e)
          return
        }
        store.dispatch('organizations/GET_COMPANY_CONTACTS', {
          companyId: currentCompany.value?.id,
          filter: {
            name: e,
            limit: 10
          },
          onSuccess: (result: any[]) => {
            dataContacts.value = [...result]
          }
        })
      }

      const handleContactDisplay = () => {
        contactsId.value =
          props.mode === MULTISELECTMODE.SINGLE
            ? [props.modelValue]
            : props.modelValue

        contactsId.value.forEach((id: string) => {
          const selection = [...contacts.value, ...props.elements].find(
            (x) => x.id == id
          )
          if (selection) selectedContacts.value.push(selection)
        })

        const notExistedContacts = contactsId.value.filter(
          (id: string) =>
            ![...contacts.value, ...props.elements]
              .map((x) => x.id)
              .includes(id)
        )
        Promise.all(notExistedContacts.map((cId: string) => getContact(cId)))
      }

      watch(
        () => modelValue.value,
        (newV: any) => {
          handleContactDisplay()
          updateValue(newV, false)
          value.value = props.mode === MULTISELECTMODE.SINGLE ? newV : [...newV]
        }
      )

      onBeforeMount(() => {
        console.log(
          'onBeforeMount global => ',
          props.globalDisplay,
          props.mode == MULTISELECTMODE.SINGLE
            ? !props.modelValue
            : Array.isArray(props.modelValue) && props.modelValue.length == 0
        )

        if (
          props.globalDisplay &&
          (props.mode == MULTISELECTMODE.SINGLE
            ? !props.modelValue
            : Array.isArray(props.modelValue) && props.modelValue.length == 0)
        )
          getContacts()
      })

      onMounted(() => {
        handleContactDisplay()
        // value.value = props.modelValue
        updateValue(props.modelValue, false)
      })

      return {
        value,
        addActionSlot,
        contacts,
        searchText,
        canDisplayContactModal,
        updateValue,
        handleSearch,
        getFullname,
        openModal
      }
    }
  })
